import React, { useCallback } from 'react';
import { GenericBaseModule } from './module/generic-base-module';

export function HeartlandPayments(props) {
  const {
    strategyInstance,
    strategies,
    params: { text }
  } = props;

  const onClick = useCallback((url) => {
    window.open(url).focus();
    
    strategyInstance.executeStrategy(strategies, 'postWindowOpen');
  },
  [strategyInstance, strategies]);

  return (
    <GenericBaseModule onClick={onClick} {...props}>
      <div>{text}</div>
    </GenericBaseModule>
  );
}
