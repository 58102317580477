import { Step, Stepper } from '@material-ui/core';
import * as appSelectors from 'app/state/app/app-selectors';
import isUndefined from 'lodash/isUndefined';
import React from 'react';
import { connect, useSelector } from 'react-redux';
import styled from 'styled-components';

import { QontoStepIcon } from './module/step-icon';
import * as ss from './vertical-stepper-styles';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  width: ${(props) => props.width};
`;

const StyledStepper = styled(Stepper)`
  padding-left: 110px;
`;

const ChildrenWrapper = styled.div`
  max-width: 800px;
  width: 100%;
  flex: 4;
`;

function VerticalStepperComponent(props) {
  const { steps, activeStep, hidden, className } = props;

  const theme = useSelector(appSelectors.theme);

  return (
    <Wrapper className={className} width={props.width}>
      <ChildrenWrapper>{props.children}</ChildrenWrapper>

      {/*
        isUndefined is used here, because the first step evaluates to "0"
          which is falsy. However, we want the stepper to display, so instead
          we only hide the stepper when the "step" is not found in the "stepsConfig"
          of the JSON OR if the "hidden" prop is explicitly set
      */}
      <ss.StepperWrapper hidden={isUndefined(activeStep) || hidden}>
        <StyledStepper
          activeStep={activeStep}
          orientation="vertical"
          connector={<ss.QontoConnector />}
          data-testid="vertical-stepper"
        >
          {steps
            ?.filter((label) => !!label)
            .map((label) => (
              <Step key={label}>
                <ss.StepperLabel
                  StepIconComponent={QontoStepIcon}
                  fontSize={theme?.stepperFontSize}
                  activeStep={activeStep}
                >
                  {label}
                </ss.StepperLabel>
              </Step>
            ))}
        </StyledStepper>
      </ss.StepperWrapper>
    </Wrapper>
  );
}

function mapStateToProps(state) {
  return {
    steps: appSelectors.steps(state),
  };
}

export const VerticalStepper = connect(mapStateToProps)(
  VerticalStepperComponent,
);
