import * as appSelectors from 'app/state/app/app-selectors';
import { withVisibility } from 'configurable-form/components/utils/with-visibility';
import { evaluateCondition } from 'configurable-form/configurable-form-utils';
import { Navigation } from 'configurable-form/shared/navigation';
import { useFormikContext } from 'formik';
import isEmpty from 'lodash/isEmpty';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { captureExceptionWithContext } from 'utils/sentry-functions';
import { usePropertyLookup } from '../hooks/usePropertyLookup';
import { BasicError } from './basic-error';
import { formatErrorMessage } from './shared';

function PropertyLookupNavigationComponent(props) {
  const {
    parcelNumber,
    selectedAddressAPN,
    serverHasMatchedAProperty,
    executeStrategy,
    allowMultipleLicenses,
    skipMultipleLicensesNotice,
    additionalErrorMessageText,
    continueCondition,
    skipValidationOnContinue,
  } = props;

  const {
    values: formikValues = {},
    validateForm,
    setFieldTouched,
  } = useFormikContext();

  const cityInfo = useSelector(appSelectors.cityInfo);

  const [loading, setLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState(undefined);

  const {
    queryForMatchingProperty,
  } = usePropertyLookup();

  const queryProperty = useCallback(async ({ apn, unitNumber }) => {
    setLoading(true);
    setErrorMessage(undefined);

    try {
      return await queryForMatchingProperty({ apn, unitNumber });
    } catch (err) {
      captureExceptionWithContext(err, { parcelNumber, unitNumber });

      const lookupErrorMessage = formatErrorMessage(err?.response?.data?.Message, additionalErrorMessageText);
      if (lookupErrorMessage) setErrorMessage(lookupErrorMessage);
    } finally {
      setLoading(false);
    }
  }, [parcelNumber, additionalErrorMessageText, queryForMatchingProperty]);

  const isFormikStateValid = useCallback(async () => {
    const formValid = await validateForm();
    Object.keys(formValid).map((key) => setFieldTouched(key));

    return isEmpty(formValid);
  }, [setFieldTouched, validateForm]);

  const handleContinue = useCallback(async () => {
    if (skipValidationOnContinue) {
      executeStrategy('onContinueClick');
      return;
    }

    const formikStateNotValid = !(await isFormikStateValid());

    const continueConditionMet = evaluateCondition(
      formikValues,
      continueCondition
    );

    if (!parcelNumber || formikStateNotValid || !continueConditionMet) return;

    /**
     * We use the parcel number from the response of "/apn/" (stored via
     * "handlePropertyData" of "usePropertyLookup"), since Formik states
     * may not be storing the APN value.
     */
    const data = await queryProperty({ apn: parcelNumber, unitNumber: formikValues.unitNumber });

    setLoading(true);

    const firstRegistrationForMatchedProperty = !data.licenses.length;

    if (
      firstRegistrationForMatchedProperty ||
      (allowMultipleLicenses && skipMultipleLicensesNotice)
    ) {
      executeStrategy('onContinueClick');
    } else if (!allowMultipleLicenses && data.licenses.length) {
      const restrictedToOneError = `
      A ${cityInfo.certificateVerbiage} has already been issued for this address.
      Please contact <a href={${`mailto:${cityInfo.contactEmail}`}}>${cityInfo.contactEmail}</a> for assistance.
      `;

      setErrorMessage(restrictedToOneError);
      setLoading(false);
    } else {
      props.setStep();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowMultipleLicenses, continueCondition, executeStrategy, formikValues, isFormikStateValid, parcelNumber, props, queryProperty, skipMultipleLicensesNotice]);

  const handleContinueClick = useCallback(async () => {
    if (serverHasMatchedAProperty) return await handleContinue();
    return await queryProperty({ apn: selectedAddressAPN, unitNumber: formikValues.unitNumber });
  }, [formikValues.unitNumber, handleContinue, queryProperty, selectedAddressAPN, serverHasMatchedAProperty]);

  useEffect(() => {
    setErrorMessage(undefined);
  }, [parcelNumber, formikValues.unitNumber]);

  useEffect(() => {
    (async () => await isFormikStateValid())();
  }, [isFormikStateValid]);

  return (
    <div>
      {/* Error message returned to the client to render by the server */}
      <BasicError message={errorMessage} />

      <Navigation
        continueButtonType="button"
        onContinueClick={handleContinueClick}
        loading={props.loading || loading}
      />
    </div>
  )
}

export const PropertyLookupNavigation = withVisibility(PropertyLookupNavigationComponent);
