import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import * as appSelectors from 'app/state/app/app-selectors';
import * as cs from 'common/common-styles';
import { parseHTMLString } from 'utils/utility-functions';
import styled from 'styled-components';

const Header = styled(cs.HeaderText)`
  margin-top: 40px;
`;

const Input = styled(cs.StyledInput)`
  width: 200px;
`;

const Div = styled.div`
  margin-top: 20px;
`;

const DEFAULT_PARCEL_LOOKUP_LINK_TEXT = 'Lookup APN on county website';

export function APNInput(props) {
  const { parcelNumber, setParcelNumber, disabled } = props;

  const cityInfo = useSelector(appSelectors.cityInfo);
  const { search: config } = useSelector(appSelectors.renderConfig);

  const onChange = useCallback((apn) => {
    const parsedAPN = String(apn).replaceAll(/[\W_]+/g, '');
    setParcelNumber(parsedAPN);
  }, [setParcelNumber]);

  const apnInputSubtext = useMemo(() => parseHTMLString(config?.apnInputSubtext), [config?.apnInputSubtext]);

  return (
    <div>
      <Header>{config?.apnInputLabel}</Header>

      <Input value={parcelNumber} onChange={onChange} maxLength={20} data-cy="apn-input" disabled={disabled} />

      <Div>
        {apnInputSubtext && <div>{apnInputSubtext}</div>}
        {cityInfo?.parcelLookupWebsite && (
          <cs.Link href={cityInfo.parcelLookupWebsite} target="_blank" rel="noreferrer">
            {cityInfo.parcelLookupLinkText || DEFAULT_PARCEL_LOOKUP_LINK_TEXT}
          </cs.Link>
        )}
      </Div>
    </div>
  );
}
