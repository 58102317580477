import React, { useMemo } from 'react';
import isBoolean from 'lodash/isBoolean';
import isString from 'lodash/isString';
import styled from 'styled-components';
import ErrorIcon from '@material-ui/icons/Error';
import { parseHTMLString } from 'utils/utility-functions';

const NoticeBoxContainer = styled.div`
  display: flex;
  gap: 1rem;
  border: ${(props) => props?.$type === Type.WARNING ? '2px solid red' : '2px solid #8ed4f6'};
  margin-bottom: 20px;
  padding: 1rem;
  background: ${(props) => props?.$type === Type.WARNING  ? 'none' : '#c4e8fb'};
  border-radius: 5px;
  align-items: center;
  font-weight: ${(props) => props?.$type === Type.WARNING ? 'bold' : 'normal'};
`;


const getDevModeText = (certificateVerbiage) => `The system is currently under development.
Please refrain from attempting to register for a ${certificateVerbiage} until the full release.
Failure to comply may result in complications relating to your registration in the future.
`;

export const Type = {
  INFORMATIVE: 'informative',
  WARNING: 'warning',
}

const NoticeIcon = styled(ErrorIcon)`
  && {
    font-size: 4rem;
  }
  color: ${(props) => props?.$type === Type.WARNING ? 'red' : '#8ed4f6'};
`;

export function NoticeBox(props) {
  const { isDevelopmentalNotice, text, certificateVerbiage, hideDevelopmentalNotice = false, type } = props;

  const html = useMemo(() => {
    const devModeText = getDevModeText(certificateVerbiage);

    const useDevNotice = isBoolean(hideDevelopmentalNotice)
      ? !hideDevelopmentalNotice
      : isString(hideDevelopmentalNotice) && hideDevelopmentalNotice === 'False';

    if (isDevelopmentalNotice && useDevNotice) return parseHTMLString(devModeText);
    return parseHTMLString(text);
  }, [certificateVerbiage, hideDevelopmentalNotice, isDevelopmentalNotice, text]);

  if (!html) return null;

  return (
    <NoticeBoxContainer $isDevelopmentalNotice={isDevelopmentalNotice} $type={type}>
      <NoticeIcon $isDevelopmentalNotice={isDevelopmentalNotice} $type={type} />
      <div>{html}</div>
    </NoticeBoxContainer>
  );
}
