import { useCallback, useEffect, useState } from 'react';
import { useField } from 'formik';
import { createYupSchema } from "utils/yup-schema-creator";

export function useInputValidation({ validation }) {
  /**
   * Formik does not seem to react when the `validate` function passed to `useField`
   * changes. That leaves us with two options - one is to reconstruct the Yup schema
   * every time the `validate` function runs. Another is to keep Yup schema in a ref
   * so that we can keep the (referentially) same `validate` function but still enable
   * it to check the most up-to-date Yup schema, as, in this case, it is the ref that's
   * captured in the closure, but the schema inside it is not.
   *
   * Then, to make sure the schema is updated when the validation prop is changed,
   * we have a useEffect that runs on every change to props.validation and updates the Yup
   * schema. This also requires for the parent component to memoize the validation prop for this
   * to be an effective optimization.
   * @type {React.MutableRefObject<undefined>}
   */
  const [yupSchema, setYupSchema] = useState();

  const validate = useCallback(async (value) => {
    if (!yupSchema) return;

    try {
      await yupSchema.validate(value);
    } catch (err) {
      return err.errors;
    }
  }, [yupSchema]);

  useEffect(() => {
    if (!validation) return;

    setYupSchema(createYupSchema(validation));
  }, [validation]);

  return validate;
}

export function useInput(props) {
  const validate = useInputValidation({ validation: props?.validation });
  const [field, meta, helpers] = useField({ type: props.type, name: props.name, validate });

  return { field, meta, helpers };
}
