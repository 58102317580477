import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import styled from 'styled-components/macro';
import * as cs from 'common/common-styles';
import { AccordionItem } from './accordion-item';
import { TemplatedAccordion } from './templated-accordion';
import { withVisibility } from '../utils/with-visibility';


const StyledAccordion = styled(Accordion)`
  ${cs.greyBackground}
`;

const StyledAccordionSummary = styled(AccordionSummary)`
  ${cs.greyBackground}
`;

const ACCORDION_VARIANTS = {
  default: AccordionItem,
  template: TemplatedAccordion
}

// See "AccordionItem" of "types/fields/component-props/question-answer-accordion.ts"
function QuestionAnswerAccordionComponent({ additionalCSS, header, data }) {
  return (
    <StyledAccordion css={additionalCSS}>
      <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>{header}</StyledAccordionSummary>

      <cs.AccordionDetail>
        {data.map((el, _idx) => {
          const Accordion = ACCORDION_VARIANTS[el?.variant ?? 'default'];
          return <Accordion key={`QAA-${_idx}`} questionNum={_idx} {...el} />;
        })}
      </cs.AccordionDetail>
    </StyledAccordion>
  );
}

export const QuestionAnswerAccordion = withVisibility(QuestionAnswerAccordionComponent);

