import { createSelector } from '@reduxjs/toolkit';
import get from 'lodash/get';
import { ROOT_PATH } from 'routes/constants';
import { strategyFactory } from 'strategies/strategy-utils';

export const app = (state) => state.app;

export const appState = createSelector(
  (state) => state,
  (state) => state,
);

export const stats = createSelector(
  app,
  (appState) => appState?.stats,
);

export const isMultiPropertyTOTEnabled = createSelector(
  stats,
  (stats) => Boolean(stats?.app?.enableMultiPropertyTOT)
);

export const isBulkTOTUploadEnabled = createSelector(
  stats,
  (stats) => Boolean(stats?.app?.enableBulkTOTUpload)
);

export const translations = createSelector(
  app,
  (appState) => appState?.translations,
);

// Selectors for root level within stats object

export const metaContent = createSelector(
  stats,
  (statsData) => statsData?.metaContent,
);

export const flows = createSelector(
  stats,
  (statsData) => {
    const { alternateFlowMapping, alternateFlows } = statsData || {};
    if (alternateFlows) {
      let flowsToReturn = {
        ...alternateFlows,
        ...(statsData?.flows || {}),
      }
      if (alternateFlowMapping) {
        const overriddenFlowsByFlowKey = Object.keys(alternateFlowMapping)
          .reduce((acc, flowKey) => ({
            ...acc,
            [flowKey]: alternateFlows[alternateFlowMapping[flowKey]]
          }), {});

        flowsToReturn = {
          ...flowsToReturn,
          ...overriddenFlowsByFlowKey
        }
      }

      return flowsToReturn;
    }

    return statsData?.flows
  },
);

export const maintenanceMode = createSelector(
  stats,
  (statsData) => statsData?.maintenanceMode,
);

export const homePageAlternate = createSelector(
  stats,
  (statsData) => statsData?.homePageAlternate,
);

export const statsApp = createSelector(
  stats,
  (statsData) => statsData?.app ?? {},
);

export const useCustomerPortal = createSelector(
  statsApp,
  (app) => app.type === 'portal',
);

export const getFromPortalStats = (path) => {
  return createSelector(
    stats,
    (statsData) => get(statsData.portal, path),
  )
};

export const rootPath = createSelector(
  stats,
  (statsData) => statsData?.app?.rootPath || ROOT_PATH,
);

export const theme = createSelector(
  stats,
  (statsData) => statsData?.app?.theme,
);

export const homePageBanner = createSelector(
  stats,
  (statsData) => statsData?.app?.banner,
);

export const globalACHLimit = createSelector(
  stats,
  (statsData) => statsData?.app?.achLimit ? Number(statsData?.app?.achLimit): undefined,
);

export const applyLicenseACHLimit = createSelector(
  stats,
  (statsData) => statsData?.flows?.applyLicense?.achLimit,
);

export const payTotACHLimit = createSelector(
  stats,
  (statsData) => statsData?.flows?.payTot?.achLimit,
);

// Selectors for "app" key within stats object

export const activeFlow = createSelector(
  app,
  (app) => app.activeFlow,
);

export const strPermitNumber = createSelector(
  app,
  (appState) => appState.strPermitNumber,
);

export const parcelNumber = createSelector(
  app,
  (appState) => appState.parcelNumber,
);

export const addressOfRentalProperty = createSelector(
  app,
  (appState) => appState.addressOfRentalProperty,
);

export const strategyInstance = createSelector(
  activeFlow,
  (af) => strategyFactory(af),
);

export const activePaymentMethodTab = createSelector(
  app,
  (appState) => appState.activePaymentMethodTab,
);

export const visitedFirstPageInFlow = createSelector(
  app,
  (appState) => appState.visitedFirstPageInFlow,
);

export const pageBanner = createSelector(
  flows,
  activeFlow,
  homePageBanner,
  (flows, activeFlow, homePageBanner) => flows && (flows[activeFlow]?.banner ?? homePageBanner),
);

export const totName = createSelector(
  stats,
  (stats) => stats?.app?.totName || "TOT"
)

// Selectors for "cityInfo" key within stats object

export const cityInfo = createSelector(
  stats,
  (statsData) => statsData?.cityInfo,
);

export const place = createSelector(
  cityInfo,
  (cityInfoData) => cityInfoData?.place,
);

export const certificateVerbiage = createSelector(
  cityInfo,
  (cityInfoData) => cityInfoData?.certificateVerbiage,
);

export const renderConfig = createSelector(
  flows,
  activeFlow,
  (flows, activeFlow) => flows && (flows[activeFlow]?.renderConfig ?? {} )
);

const stepsConfig = createSelector(
  flows,
  activeFlow,
  (flows, activeFlow) => flows && (flows[activeFlow]?.stepsConfig.filter(step => !!step.step) ?? [])
)

export const steps = createSelector(
  stepsConfig,
  (sc) => sc?.map(({ step }) => step),
);

export const stepPositions = createSelector(
  stepsConfig,
  (sc) => {
    const result = {};
    sc?.forEach(({ id }, i) => { result[id] = i; });
    return result;
  },
);

export const dynamicQuarterSelectedPeriodType = createSelector(
  app,
  appState => appState.dynamicQuarterSelectedPeriodType
);

export const enableEditingAllFields = createSelector(
  app,
  appState => appState?.enableEditingAllFields
);
