import { Step, Stepper } from '@material-ui/core';
import * as appSelectors from 'app/state/app/app-selectors';
import { PortalPageContainer } from 'common/portal/styles';
import {
  StepperWrapper as BaseStepperWrapper,
  QontoConnector,
  StepperLabel,
} from 'common/stepper/vertical-stepper-styles';
import isUndefined from 'lodash/isUndefined';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { QontoStepIcon } from './module/step-icon';

const StepperWrapper = styled(BaseStepperWrapper)`
  width: 300px;
  max-width: 300px;
`;

const FullWidth = styled.div`
  width: 100%;
`;

/**
 * This is basically the same as "VerticalStepperComponent", but
 * with some minor tweaks to how much space the content takes up
 * and extensibility of the stepper itself.
 */
export function PortalPageStepper(props) {
  const { activeStep, hidden } = props;

  const steps = useSelector(appSelectors.steps);
  const theme = useSelector(appSelectors.theme);

  return (
    <PortalPageContainer>
      <FullWidth>{props.children}</FullWidth>
      <StepperWrapper hidden={isUndefined(activeStep) || hidden}>
        <Stepper
          activeStep={activeStep}
          orientation="vertical"
          connector={<QontoConnector />}
        >
          {steps
            ?.filter((label) => !!label)
            .map((label) => (
              <Step key={label}>
                <StepperLabel
                  StepIconComponent={QontoStepIcon}
                  fontSize={theme?.stepperFontSize}
                  activeStep={activeStep}
                >
                  {label}
                </StepperLabel>
              </Step>
            ))}
        </Stepper>
      </StepperWrapper>
    </PortalPageContainer>
  );
}
