import MUITableCell from '@material-ui/core/TableCell';
import MUITextField from '@material-ui/core/TextField';
import styled from 'styled-components';

export const TableCell = styled(MUITableCell).attrs({
  component: 'div',
})`
  &&& {
    // Override default MUI TableCell behavior
    font-size: 14px;
    padding: 0;
    border: none;
  }
`;

export const Div = styled.div`
  margin-bottom: 20px;
`;

export const TextField = styled(MUITextField).attrs({
  variant: 'outlined',
  placeholder: 'Search',
})`
  &&& {
    width: 350px;

    .MuiOutlinedInput-input {
      padding: 14px 10px;
    }
  }
`;

const DEFAULT_TABLE_HEIGHT = 400;

export const TableDiv = styled.div`
  width: ${(props) => props.width ?? '100%'};

  height: ${(props) => {
    if (props.height || props.altTableGridHeight)
      return props.height || props.altTableGridHeight;

    const dataBasedHeight =
      props.headerHeight + props.rowHeight * props.rowCount;

    return `${Math.min(dataBasedHeight, DEFAULT_TABLE_HEIGHT)}px`;
  }};

  /* This is the give space for the "DataTableTotals" as "AutoSizer" sizing weird for stacked HTML elements contained within */
  margin-bottom: ${(props) =>
    props.includesTotalRowParameters ? '60px' : null};

  & .ReactVirtualized__Table__Grid {
    height: ${(props) => {
      if (props.altTableGridHeight) return props.altTableGridHeight;

      const defaultHeight = DEFAULT_TABLE_HEIGHT - props.headerHeight;
      const scrollableViewTotalHeight = props.rowCount * props.rowHeight;
      if (scrollableViewTotalHeight < defaultHeight)
        return 'min-content !important;';
    }};
  }

  /* Specifically targets the data rows to use alternating colors for even and odd numbered rows. */
  & .ReactVirtualized__Grid__innerScrollContainer {
    .ReactVirtualized__Table__row:nth-child(odd) {
      background-color: #f0f0f0;
    }
  }

  & .ReactVirtualized__Table__headerRow,
  .ReactVirtualized__Table__row {
    /* background-color: #f0f0f0; */
    padding: 0 15px !important;

    display: grid;
    grid-auto-flow: column;
    gap: 10px;

    /* If no widths are provided in the columns config, then this will auto-size all columns to take up the space evenly */
    grid-auto-columns: 1fr;
    grid-template-columns: ${(props) => props.columnWidths};
  }

  & .ReactVirtualized__Table__headerColumn,
  .ReactVirtualized__Table__rowColumn {
    display: flex;
    align-items: center;
  }
`;
