import React from 'react';
import { Image } from 'semantic-ui-react';
import * as ps from './payment-styles';

export function PaymentImage() {
  return (
    <ps.ImageContainer>
      <Image src="/assets/tot-pay-payment.svg" />
    </ps.ImageContainer>
  );
}
