import React, { useMemo } from 'react';
import { useFormikContext } from 'formik';
import get from 'lodash/get';
import { BoldText, TextWithDivider } from './shared';
import { parseCustomerConfig } from 'utils/utility-functions';

import { useTranslations } from 'utils/translations';

export function AccordionItemGeneric({ formikQuestionID, formikAnswerID, questionOveride, answerOverride, useTranslationsForAnswer }) {
  const { values } = useFormikContext();

  const translations = useTranslations();

  const formikValues = useMemo(() => parseCustomerConfig(values), [values]);

  const question = useMemo(
    () =>
      questionOveride || get(formikValues, formikQuestionID),
    [formikQuestionID, questionOveride, formikValues]
  );

  const answer = useMemo(() => {
    let result = answerOverride || get(formikValues, formikAnswerID)
    if (useTranslationsForAnswer) {
      let converted = translations?.[result]?.default ?? result;
      result = converted;
    }

    return result

  }, [formikAnswerID, answerOverride, formikValues, useTranslationsForAnswer, translations]);

  return (
    <div>
      <BoldText>{question}</BoldText>
      <TextWithDivider>{answer}</TextWithDivider>
    </div>
  );
}
