import CircularProgress from '@material-ui/core/CircularProgress';
import {
  PERMIT_VERBIAGE,
  PERMIT_VERBIAGE_TRANSLATION_KEY,
} from 'common/constants';
import get from 'lodash/get';
import React from 'react';
import { useTranslations } from 'utils/translations';

import { DateHighlight } from './date-highlight';
import { HighlightedDataSection } from './highlighted-permit-data';
import { PermitIdHighlight } from './permit-id-highlight';
import { StatusHighlight } from './status-highlight';

/**
 * This component contains the logic for displaying the highlighted permit information.
 *
 * The data comes from the "/reportingHistoryByApplicationNumber" API.
 *
 * The data and language used on the screen is dependent of whether an item is a permit
 * or a permit application.
 */
export function PermitDataHighlights(props) {
  const {
    data, // This is the data of the permit on screen (active permit data)
    fetchInflight,
    failedLicenseFetch,
  } = props;

  const translations = useTranslations();

  // Only approved applications will have a license number.
  const isPermitApplication = !get(data, 'certificateInfo.licenseNumber');

  const permitVerbiage =
    translations?.[PERMIT_VERBIAGE_TRANSLATION_KEY]?.default ?? PERMIT_VERBIAGE;

  if (failedLicenseFetch) return null;

  return (
    <HighlightedDataSection fetchInflight={fetchInflight}>
      {fetchInflight && <CircularProgress size={20} />}

      {!fetchInflight && data && (
        <>
          <DateHighlight
            isPermitApplication={isPermitApplication}
            permitVerbiage={permitVerbiage}
            applicationDate={get(data, 'certificateInfo.applicationDate')}
            expirationDate={get(data, 'certificateInfo.expirationDate')}
          />

          <StatusHighlight
            isPermitApplication={isPermitApplication}
            permitVerbiage={permitVerbiage}
            status={get(data, 'status')}
          />

          <PermitIdHighlight
            isPermitApplication={isPermitApplication}
            permitVerbiage={permitVerbiage}
            applicationNumber={get(data, 'certificateInfo.applicationNumber')}
            licenseNumber={get(data, 'certificateInfo.licenseNumber')}
          />
        </>
      )}
    </HighlightedDataSection>
  );
}
