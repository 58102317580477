import React from 'react';
import { parseHTMLString } from 'utils/utility-functions';
import { htmlRendererTestId } from 'test/constants';

/**
 * Sample Config Keys:
 *
 *  component: HTMLRenderer
 *    Required - string
 *    The name of the component that will be used to render
 *
 *  id:
 *    Required - string
 *    String for the React prop "key"
 *
 *  htmlString:
 *    Required - string
 *    Formik uses this as the key to store the values with in a dictionary
 */
export function HTMLRenderer({ htmlString }) {
  return (<div data-testid={htmlRendererTestId}>{parseHTMLString(htmlString)}</div>);
}
