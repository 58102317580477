import React from 'react';
import styled from "styled-components/macro";
import { Image } from 'semantic-ui-react';

const ImageWrapper = styled.div`
  max-width: 737px;
  width:100%;
  height: 400px;
  object-fit: contain;
`;

export function PageImage(props) {
  const { svg, style, additionalCSS } = props;

  return (
    <ImageWrapper css={additionalCSS} style={style}>
      <Image src={`/assets/${svg}.svg`} />
    </ImageWrapper>
  );
}
