import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import * as networkSelectors from 'app/state/network/network-selectors';
import { convertFeeDictToArray, convertNumberToCurrency } from 'utils/utility-functions';
import { RowRender, TableLayout } from "./fees-table-components";
import { addVectors } from "../../pages/pay-flow/report-revenue-page/utils";
import get from "lodash/get";

/**
 * ! This component relies on the information received from the "calculateTot" endpoint
 * ! to be stored in the Redux store via the "payTotActions.setCalculatedTOTData" action
 * ! Highly coupled with the ReportRevenue component
 *
 * Sample Config Keys:
 *
 *  component: FeesTable
 *    Required - string
 *    The name of the component that will be used to render
 *
 *  id:
 *    Required - string
 *    String for the React prop "key"
 *
 *  tableHeaders:
 *    Required - array of strings
 *    The text that will be displayed as the column names of the table
 *
 *  tableDataKeys:
 *    Required - array of strings
 *    The keys that will be used to extract the relevant information from the
 *      response payload
 *      The shape of the data is expected to be the same as in the "calculateTot" endpoint in Postman
 *        str_registration_generic -> Calculate TOT
 */
export function FeesTable(props) {
  const { tableHeaders = [], tableDataKeys = [], additionalCSS } = props;

  const paymentFees = useSelector(networkSelectors.paymentFees);

  const tableDataRows = useMemo(() => {
    return paymentFees?.paymentPeriods?.map((data) => (
      convertFeeDictToArray(data, tableDataKeys, 'name')
    ))
  }, [paymentFees?.paymentPeriods, tableDataKeys]);

  const aggregateDataValues = useMemo(() => {
    return paymentFees?.paymentPeriods?.map(p => tableDataKeys.map(k => get(p, k)))
      .reduce((acc, data) => addVectors(data, acc), [])
  }, [paymentFees?.paymentPeriods, tableDataKeys]);


  const parsedTableBodyData = useMemo(
    () => {
      const preCalculatedSummaryRowValues = convertFeeDictToArray(paymentFees?.summary, tableDataKeys);
      return preCalculatedSummaryRowValues.map((value, i) => {
        if (!value && i > 0) {
          return convertNumberToCurrency(aggregateDataValues[i-1]);
        }
        return value;
      })
    },
    [aggregateDataValues, paymentFees?.summary, tableDataKeys]
  );

  return (
    <TableLayout css={additionalCSS}>
      {/* Render the headers row first */}
      <RowRender forceBolding={true} data={tableHeaders} />
      {tableDataRows.map(feeData => (
        <RowRender separator key={feeData[0]} data={feeData} />
      ))}

      <RowRender separator forceBolding={true} data={parsedTableBodyData} />
    </TableLayout>
  );
}
