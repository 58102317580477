import { MenuItem } from '@material-ui/core';
import * as loginSelectors from 'app/state/login/login-selectors';
import { usePortalAuth } from 'login/hooks/use-portal-auth';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { ROOT_PATH } from 'routes/constants';
import { history } from 'routes/history';

import { userContextMenuStyles } from '../styles';

export function UserLogout(props) {
  const classes = userContextMenuStyles();

  const { userSignOut } = usePortalAuth();

  const isUserCognitoAuthenticated = useSelector(
    loginSelectors.isUserCognitoAuthenticated,
  );

  const handleSignOut = useCallback(async () => {
    if (!isUserCognitoAuthenticated) return;
    props.handleClose();

    await userSignOut();
    history.push(ROOT_PATH);
  }, [isUserCognitoAuthenticated, props, userSignOut]);

  return (
    <MenuItem className={classes.menuItem} onClick={handleSignOut}>
      Logout
    </MenuItem>
  );
}
