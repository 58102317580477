import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { connect, useSelector } from 'react-redux';
import { Router } from 'react-router';
import { Auth } from 'aws-amplify';
import * as Sentry from '@sentry/react';
import * as appSelectors from 'app/state/app/app-selectors';
import { appActions } from 'app/state/app/app-slice';
import * as errorSelector from 'app/state/error/error-selectors';
import { errorActions } from 'app/state/error/error-slice';
import { loginActions } from 'app/state/login/login-slice';
import { PageBanner } from 'common/banner/page-banner';
import { APP_TYPE_PORTAL } from 'common/constants';
import { USER_AUTH_SUCCESSFUL_MESSAGE } from 'login/hooks/use-portal-auth';
import { ErrorHandler } from 'pages';
import Routes from 'routes';
import { history, domainlessPortalPlaceName } from 'routes/history';
import { BaseStrategy } from 'strategies/base-strategy';
import { captureExceptionWithContext } from 'utils/sentry-functions';
import { strRegistrationApiSender } from 'utils/str-registration-api-sender';
import { checkCognitoUserForCustomerPortal } from 'utils/utility-functions';
import appSettings from './app-settings.json';
import ScrollToTop from 'common/scroll-to-top';
import { IndexPageLoading } from "./pages/IndexPageLoading";
import { StaticRedirect } from "./pages/StaticRedirect";
import { isVfpr } from 'utils/constants';
import { GlobalStyles } from "./globalStyles";
import { authSender } from 'utils/auth-sender';
import { staticRedirects } from 'utils/static-redirects';

function MetaContent() {
  const metaContent = useSelector(appSelectors.metaContent);

  const favicon = useMemo(() => {
    if (isVfpr) {
      return '/favicon-foreclosures.ico';
    } else {
      return '/favicon-rentalscape.ico';
    }
  }, []);

  return (
    <Helmet>
      <title>{metaContent?.title ?? 'Short term rental application'}</title>
        <meta name='description' content={metaContent?.description ?? 'Deckard STR management system'} />
        <link rel="icon" href={favicon} />
    </Helmet>
  )
}

function cityOriginFactory() {
  const location = document.location.hostname;
  if (location.includes('.deckard')) {
    return location;
  }
  return appSettings.devCityOrigin;
}

const originUrl = cityOriginFactory();

class App extends React.Component{
  static getDerivedStateFromError() {
    return { errorState: true };
  }

  constructor(props) {
    super(props);

    this.state = {
      errorState: false,
      isAuthenticatingCurrentUser: false,
    };

    this.clearAppErrors = this.clearAppErrors.bind(this);
  }

  componentDidCatch(error, errorInfo) {
    captureExceptionWithContext(error, errorInfo);
  }

  async componentDidMount() {
    /**
     * Checks if the current logged in user is a valid user for the
     * Customer Portal and allows them entry if so.
     *
     * This runs at the start of the app, so that we automatically log people
     * back into the app if their sessions are still valid.
     *
     * Terminate the function if the user is either using a system that is not
     * operating under the Customer Portal variant or when the user is attempting
     * to enter the admin flows via Rentalscape.
     *
     *
     */
    async function restoreUserSession(stats) {
      const isAppPortalType = stats.app.type === APP_TYPE_PORTAL;
      const queryParams = new URLSearchParams(window.location?.search);
      const rentalscapeLoginToken = queryParams.get('operatorLoginToken');

      /**
       * We do not attempt to recover user session information for admin
       * users coming from Rentalscape or non customer-portal users.
       */
      if (!isAppPortalType || rentalscapeLoginToken) return;

      this.setState((prev) => ({ ...prev, isAuthenticatingCurrentUser: true }));

      try {
        const cognitoUser = await Auth.currentAuthenticatedUser();

        if (!checkCognitoUserForCustomerPortal(stats.cityInfo.place, cognitoUser.username)) {
          return await Auth.signOut();
        }

        const userAuthStatus = cognitoUser.authenticationFlowType;
        if (userAuthStatus === USER_AUTH_SUCCESSFUL_MESSAGE) {
          const { data } = await authSender.get(
            `/getUserByEmailAuth/${cognitoUser.attributes.email}`,
          );

          this.props.setUserAccountDetails(data);
          this.props.setCognitoUserDetails(cognitoUser);
        }

        this.setState((prev) => ({ ...prev, isAuthenticatingCurrentUser: false }));
      } catch (err) {
        this.setState((prev) => ({ ...prev, isAuthenticatingCurrentUser: false }));
        window.localStorage.clear();
      }
    }

    async function fetchAppData(setStats, setTranslations) {
      const stats = await strRegistrationApiSender.getStats(originUrl);

      try {
        const translations = (await strRegistrationApiSender.getV2('/translations')).data;
        setTranslations(translations);
      }
      catch(e) {
        captureExceptionWithContext(e, { translationEndpointFailed: true });
      }

      await restoreUserSession.bind(this)(stats);

      // This is mandatory for using strategies. We must set the strategy data here so that
      // we don't need to pass any data when getting the singleton instances
      BaseStrategy.setStrategiesDataFromFlowsArray(stats.flows);
      setStats(stats);
    }

    try {
      if (!this.props.place) {
        await fetchAppData.bind(this)(this.props.setStats, this.props.setTranslations);
      }
    } catch (err) {
      // console.error(err);
      Sentry.captureException(err);
      // flows needs to be truthy to get past the `isLoading` check.
      this.props.setStats({ flows: {} })
      this.props.setAltErrorString(`Could not load data for ${originUrl}`)
      this.setState((prev) => ({ ...prev, errorState: true }));
    }
  }

  clearAppErrors() {
    this.setState((prev) => ({ ...prev, errorState: false }));
  }

  render() {
    const isLoading = !this.props.flows || this.isAuthenticatingCurrentUser;
    const isProd = appSettings.env === 'prod';
    const haveRedirect = domainlessPortalPlaceName in staticRedirects;

    if (isProd && haveRedirect) {
      const destUrl = staticRedirects[domainlessPortalPlaceName];
      return <StaticRedirect destUrl={destUrl} />
    } else if (isLoading) {
      return <IndexPageLoading />
    }

    return (
      <Router history={history}>
        <GlobalStyles />
        <ScrollToTop/>
        <MetaContent />
        <ErrorHandler
          criticalError={this.state.errorState}
          clearError={this.clearAppErrors}
          hasErrored={this.props.hasErrored}
        >
          {!this.props.appConfig?.redirect && <PageBanner />}
          <Routes />
        </ErrorHandler>
      </Router>
    );
  }
}

function mapStateToProps(state) {
  return {
    place: appSelectors.place(state),
    appConfig: appSelectors.statsApp(state),
    flows: appSelectors.flows(state),
    hasErrored: errorSelector.hasErrored(state),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    displayErrorModal: () => {
      dispatch(errorActions.setHasErrored(true));
    },
    setStats: (stats) => {
      dispatch(appActions.serverReturnedStats({ stats }));
    },
    setTranslations: (translations) => {
      dispatch(appActions.serverReturnedTranslations({ translations }));
    },
    setUserAccountDetails: (user) => {
      dispatch(loginActions.setUserAccountDetails(user));
    },
    setCognitoUserDetails: (cognitoUser) => {
      dispatch(
        loginActions.setCognitoUserDetails({
          isUserCognitoAuthenticated: cognitoUser?.signInUserSession?.isValid(),
          email: cognitoUser?.attributes?.email,
          username: cognitoUser?.username,
        }),
      );
    },
    setAltErrorString: (str) => {
      dispatch(errorActions.setAlternateErrorString(str));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
