import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import * as appSelector from 'app/state/app/app-selectors';
import * as cs from 'common/common-styles'
import { ZERO } from './constants';

const defaultType = 'string';
const listType = 'list';
const mapType = 'map';
const fileType = 'files';

const FieldLabel = styled(cs.FieldLabel)`
  font-size: ${(props) => props?.fontSize || '14px'};
  font-weight: bold;
`;

const StyledP = styled.p`
  font-weight: ${(props) => (props.bold ? 'bold' : 'normal')};
  font-size: ${(props) => props?.fontSize || null};
  min-height: 19px;
  max-width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

// Only non-list items can be bolded
function StringValueRender({ bold, fontSize, label, content, dataCy }) {
  if (!content) return null;

  return (
    <div>
      <FieldLabel fontSize={fontSize}>{label}</FieldLabel>
      <StyledP fontSize={fontSize} bold={bold} data-cy={dataCy}>{content}</StyledP>
    </div>
  );
}

function ListValueRender({ label, content, dictKey, fontSize }) {
  const hasItems = useMemo(() => (
    content.reduce((acc, cur) => acc + (dictKey ? cur[dictKey].length : cur.length), ZERO)
  ), [content, dictKey]);

  if (!hasItems) return null;

  return (
    <div>
      <FieldLabel fontSize={fontSize}>{label}</FieldLabel>
      {dictKey ? (
        content.map((item, i) => (
          Boolean(item[dictKey]?.length) && <StyledP fontSize={fontSize} key={`${i}${item[dictKey]}`}>{item[dictKey]}</StyledP>))
      ) : (
        content.map((item, i) => Boolean(item?.length) && <StyledP fontSize={fontSize} key={`${i}${item}`}>{item}</StyledP>)
      )}
    </div>
  );
}

function MapValueRender({ label, content, map, fontSize }) {
  if (!map[content]) return null;

  return (
    <div>
      <FieldLabel fontSize={fontSize}>{label}</FieldLabel>
      <StyledP fontSize={fontSize}>{map[content]}</StyledP>
    </div>
  )
}

function FileValueRender({ label, content, fontSize }) {
  return (
    <div>
      <FieldLabel fontSize={fontSize}>{label}</FieldLabel>
      {content.map(({ name }, idx) => <StyledP fontSize={fontSize} key={`file-${name}-${idx}`}>{name}</StyledP>)}
    </div>
  );
}

function rendererSelector(type) {
  switch (type) {
    case listType:
      return ListValueRender;
    case fileType:
      return FileValueRender;
    case mapType:
      return MapValueRender;
    default:
      return StringValueRender;
  }
}

export function FieldItem(props) {
  const { type = defaultType, hasChanged = false, dataCy, ...other } = props;

  const theme = useSelector(appSelector.theme);

  const Component = useMemo(() => rendererSelector(type), [type]);

  return (
    <cs.FieldWrapper>
      <Component bold={hasChanged} fontSize={theme?.form?.confirmationTextFontSize} dataCy={dataCy} {...other} />
    </cs.FieldWrapper>
  );
}
