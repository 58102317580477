import { BaseStrategy } from "./base-strategy";

export class CustomRouteStrategy extends BaseStrategy {
  static instance = null;

  static getInstance() {
    if (CustomRouteStrategy.instance) {
      return CustomRouteStrategy.instance;
    }
    CustomRouteStrategy.instance = new CustomRouteStrategy();
    return CustomRouteStrategy.instance;
  }
}

