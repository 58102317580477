export const ROOT_PATH = '/';

export const HELP_PATH = '/help';

export const CARD_CONNECT_PATH = '/card-connect';

// Top Level paths for each Flow
export const FLOW_PATHS = {
  applyStrLicense: '/apply-str-license',

  payTot: '/pay-tot',

  updateCertificate: '/update-certificate',

  renewal: '/renewal',
  bulkRenewal: '/bulk-renewal',

  waitlisted: '/waitlisted-apply',
};

// These license modes have to match a route parameter
export const findLicenseModes = {
  APN: 'apn',
  EMAIL: 'email',
  LICENSE_NUMBER: 'license_number',
}

export const findLicenseParams = {
  // this param will be used for determining which input field to display in the license lookup page
  findLicenseMode: ':findLicenseMode',
};

export const DEFAULT_FIND_LICENSE_MODE = findLicenseModes.APN;

export const APPLY_STR_PATHS = {
  requirements: `${FLOW_PATHS.applyStrLicense}/requirements`,
  property: `${FLOW_PATHS.applyStrLicense}/property`,
  propertyDetails: `${FLOW_PATHS.applyStrLicense}/property-details`,
  licenseHolder: `${FLOW_PATHS.applyStrLicense}/license-holder`,
  parking: `${FLOW_PATHS.applyStrLicense}/parking`,
  authorizedAgent: `${FLOW_PATHS.applyStrLicense}/authorized-agent`,
  documents: `${FLOW_PATHS.applyStrLicense}/documents`,
  confirmation: `${FLOW_PATHS.applyStrLicense}/confirmation`,
  payment: `${FLOW_PATHS.applyStrLicense}/payment`,
  success: `${FLOW_PATHS.applyStrLicense}/success`,
  listings: `${FLOW_PATHS.applyStrLicense}/listings`
};

export const PAY_TOT_PATHS = {
  property: `${FLOW_PATHS.payTot}/property/${findLicenseParams.findLicenseMode}`,
  findLicense: `${FLOW_PATHS.payTot}/find-license/${findLicenseParams.findLicenseMode}`,
  reportRevenue: `${FLOW_PATHS.payTot}/report-revenue`,
  confirmation: `${FLOW_PATHS.payTot}/confirmation`,
  payment: `${FLOW_PATHS.payTot}/payment`,
  success: `${FLOW_PATHS.payTot}/success`,
};

export const UPDATE_CERTIFICATE_PATHS = {
  property: `${FLOW_PATHS.updateCertificate}/find-license/${findLicenseParams.findLicenseMode}`,
  describeChanges: `${FLOW_PATHS.updateCertificate}/describe-changes`,
  success: `${FLOW_PATHS.updateCertificate}/success`,
}

export const RENEWAL_PATHS = {
  search: `${FLOW_PATHS.renewal}/find-license/${findLicenseParams.findLicenseMode}`,
  payment: `${FLOW_PATHS.renewal}/payment`,
  success: `${FLOW_PATHS.renewal}/success`,
};

export const WAITLISTED_APPLY_PATHS = {
  waitlist: `${FLOW_PATHS.waitlisted}/waitlist`,
};

export const BULK_RENEWAL_PATHS = {
  selectProperties: `${FLOW_PATHS.bulkRenewal}/properties`,
  confirmation: `${FLOW_PATHS.bulkRenewal}/confirmation`,
};

export const NEXT_PAGE_PATH = '/next-page-path';
export const PREVIOUS_PAGE_PATH = '/previous-page-path';

// ------------------------------------------------------
// Portal
// ------------------------------------------------------

export const PORTAL_ACCOUNT_CREATION_SLUGS = {
  email: 'email',
  account: 'account',
};

export const PORTAL_PATHS = {
  // Account Creation Routes
  createAccount: '/create-account/',
  createAccountSlugged: '/create-account/:slug',
  createAccountEmail: `/create-account/${PORTAL_ACCOUNT_CREATION_SLUGS.email}`,
  createAccountAccount: `/create-account/${PORTAL_ACCOUNT_CREATION_SLUGS.account}`,

  // Dashboard Routes
  dashboard: '/dashboard',
  dashboardPermitsList: '/dashboard/permits',
  dashboardSinglePermit: '/dashboard/permit',
  dashboardSinglePermitSlugged: '/dashboard/permit/:applicationNumber',
  dashboardUpdateUserDetails: '/dashboard/account-update',
};
