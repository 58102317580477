import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const Banner = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: white;
  z-index: 999;
  display: flex;
  justify-content: space-between;
  height: 50px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

export const BannerSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LogoContainer = styled(Link)`
  display: flex;
  align-items: center;
  height: 100%;
  cursor: pointer;

  :hover {
    background: rgba(0, 0, 0, 0.03);
    color: rgba(0, 0, 0, 0.95);
  }
`;

export const CityLogo = styled.img`
  width: ${({ width }) => `${width ?? 30}px;`};
  margin: 0 20px;
`;

const textBase = css`
  display: flex;
  align-items: center;
  height: 100%;
  font-family: 'Lato';
  font-weight: bold;
  font-size: ${(props) => props?.fontSize || '14px'};
  line-height: 1.29;
  color: #000000;
`;

export const HeaderLabel = styled.div`
  ${textBase}
`;

export const ClickableHeader = styled(Link)`
  ${textBase}

  :active, :hover, :focus {
    color: #000000;
    text-decoration: none;
  }
`;
