import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { appActions } from 'app/state/app/app-slice';
import * as appSelectors from 'app/state/app/app-selectors';
import { paymentMethodType } from 'common/constants';
import { PaymentTabText, Tab, TabItem } from './tab-styles';
import styled from 'styled-components';

export const SVGwrap = styled.img`
  height: 15px;
  margin-top:-1px;
  margin-bottom: -2px;
`
const STRIPE_SVG = 'https://js.stripe.com/v3/fingerprinted/img/card-ce24697297bd3c6a00fdd2fb6f760f0d.svg';

export function CCTab() {
  const dispatch = useDispatch();

  const activePaymentMethodTab = useSelector(appSelectors.activePaymentMethodTab);

  const isTabActive = useMemo(() => activePaymentMethodTab === paymentMethodType.CREDIT_CARD, [activePaymentMethodTab]);

  const onClick = useCallback(() => {
    dispatch(appActions.changePaymentMethodTab({ newTab: paymentMethodType.CREDIT_CARD }));
  }, [dispatch]);

  return (
    <Tab isActive={isTabActive} onClick={onClick} data-cy="cc-payment-method">
      <TabItem>
        <SVGwrap src={STRIPE_SVG} alt='Credit card' />
      </TabItem>

      <TabItem>
        <PaymentTabText>Credit or Debit Card</PaymentTabText>
      </TabItem>
    </Tab>
  );
}
