import { useMemo } from "react";
import first from "lodash/first";
import { calcDaysInPeriod } from "../../utils";

export function useDaysInPeriod(taxableActivities) {
  return useMemo(() => {
    const { dateStart, dateEnd } = first(taxableActivities);
    return calcDaysInPeriod(dateStart, dateEnd) + 1; // Add one as this function is not inclusive of the last date
  }, [taxableActivities]);
}
