import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const ThankYouWrapper = styled.div`
  width: 254px;
  height: 60px;
  font-family: Lato;
  font-size: 50px;
  line-height: 1.2;
  color: #5c5d5d;
`;

export const SubmittedSuccessfullyParagraph = styled.div`
  max-width: ${(props) => props.useCustomerPortal ? undefined : '800px'};
  font-family: Lato;
  font-size: 18px;
  line-height: 1.56;
  color: #5c5d5d;
`;

