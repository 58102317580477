import { errorActions } from 'app/state/error/error-slice';
import * as filesSelectors from 'app/state/files/files-selectors';
import { filesActions } from 'app/state/files/files-slice';
import { ErrorView } from 'common/error-view';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { ROOT_PATH } from 'routes/constants';
import { history } from 'routes/history';
import { filesManager } from 'utils/files-manager';

export function ErrorHandler(props) {
  const {
    criticalError,
    clearError,
    children,
    hasErrored,
  } = props;

  const dispatch = useDispatch();

  const hasAnyFileUploadFailed = useSelector(filesSelectors.hasAnyFileUploadFailed);

  const closeModal = useCallback(() => {
    dispatch(errorActions.resetErrorStates());

    if (hasAnyFileUploadFailed) { // Forcefully make the user redo all the file uploads
      dispatch(filesActions.reset());
      filesManager.clearProofs();
      history.push('documents');
    }
  }, [dispatch, hasAnyFileUploadFailed]);

  const goToRootPath = useCallback(() => {
    if (criticalError) clearError();

    closeModal();
    history.push(ROOT_PATH);
  }, [clearError, closeModal, criticalError]);

  const displayErrorModal = useMemo(() => criticalError || hasErrored, [criticalError, hasErrored]);

  return (
    <>
      {(displayErrorModal) && (
        <ErrorView
          visible={displayErrorModal}
          handleReturnClick={goToRootPath}
          handleDismissModal={!criticalError && closeModal}
        />
      )}
      {!criticalError && children}
    </>
  );
}
