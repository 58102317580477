import { makeStyles } from '@material-ui/core';
import styled from 'styled-components';

export const userContextMenuStyles = makeStyles(() => ({
  popper: {
    left: '5px !important',
  },
  menuList: {
    width: '100%',
    background: 'white',
    padding: '0 !important',
    borderLeft: '1px solid rgba(0,0,0,0.1)',
    borderBottom: '1px solid rgba(0,0,0,0.1)',
    borderBottomLeftRadius: '10px',
  },
  menuItem: {
    justifyContent: 'end',
  },
}));

export const UserMenuDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 20px;
    height: auto;
  }
`;
