import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ConfigurableForm } from 'configurable-form';
import * as fileSelectors from 'app/state/files/files-selectors';
import * as payloadSelectors from 'app/state/payload/payload-selectors';
import { evaluateCondition } from './configurable-form-utils';

const singularUpload = 'FileUpload';
const multipleUpload = 'MultiFileUpload';

/**
 * This is a special Form component to extend functionality of the base
 *  "ConfigurableForm" component for the documents upload page as Formik
 *  does not support file uploads, so we need to write special logic
 */
export function ConfigurableUploadForm(props) {
  const uploadedDocumentTypesMap = useSelector(fileSelectors.uploadedDocumentTypesMap);
  const formikSnapshot = useSelector(payloadSelectors.formikSnapshot);

  /**
   * Per flow there is an array "configurablePages", which contains the configuration
   *  to determine how the flow should render it's configurable components
   *
   * Within this "configurablePages" array are objects, which represents each
   *  page of the flow which is being rendered via the configurable components
   *  Will be referred to as "Page(s)"
   *
   * Within each "Page" is the configuration required to render the view
   *  Will be referred to as "Page Component(s)"
   *
   * Each "Page Component" contains a "fields" array, which contains the configuration
   *  that determines the individual components to be render on the view along with it's
   *  corresponding logic, functionality, etc
   *  Will be referred to as "Field Component(s)"
   *
   *  This function determines the list of required files by mapping through the
   *    "Field Components" and for each "FileUpload" component, it will take the
   *    path where the document should be stored to be inserted into a list which
   *    will indicate which files are required and which are not to make sure that
   *    the user has upload all the relevant files before being able to move onto
   *    the next step
   */
  const requiredDocTypes = useMemo(() => {
    const fileUploadConfigs = [];
    props.pageConfig.fields.forEach((config) => {
      if (config.component === singularUpload) fileUploadConfigs.push(config);
      else if (config.component === multipleUpload) {
        // The parent config is also spread as there is information that is required in later parsing
        config.components.forEach((el) => { fileUploadConfigs.push({ ...el, ...config }); })
      }
    });

    // Iterate through all the components the config is telling to be rendered on the screen
    const list = fileUploadConfigs.map((componentConfig) => {
      if (!componentConfig?.required) return null;

      const isDocFormikRequired = evaluateCondition(formikSnapshot, componentConfig?.required?.condition);
      if (!isDocFormikRequired) return null;

      /**
       * Return a "key" used to check if a specific document has been uploaded
       *  via the file count recorded in Redux
       */
      return componentConfig?.storageKey ?? componentConfig?.fileKey;
    });

    return list.filter(type => type);
  }, [formikSnapshot, props.pageConfig.fields]);

  /**
   * Checks if all required documents are stored in the FileManager class or not
   * If not, then this will forcibly disable the continue button
   */
  const forceDisable = useMemo(() => (
    Boolean(requiredDocTypes.length) &&
    !requiredDocTypes.every((type) => Boolean(uploadedDocumentTypesMap[type]))
  ), [requiredDocTypes, uploadedDocumentTypesMap]);

  return <ConfigurableForm forceDisable={forceDisable} {...props} />;
}
