import React, { useMemo, useCallback } from 'react';
import { ReportingHistoryAccordionPeriodComponent } from './reporting-history-accordion-period';
import { convertStringToCurrency } from 'utils/utility-functions';
import { PaymentMethodLabel } from 'common/constants';
import isNil from 'lodash/isNil';

export function ReportingHistoryAccordionComponent(props) {

  const {
    salesLabel,
    deductionsLabel,
    totalLabel,
    taxTypeBreakdownMap,
    providerMap,
    filteredData,
    applicationNumber,
    totPayable
  } = props;

  const initializeLicenseValues = useCallback(() => {
    return {
      taxableReceipts: [],
      salesAndDeductions: {
        taxableReceiptsProviderValues: [],
        salesProviderValues: [],
        deductionsProviderValues: [],
        salesLabel: salesLabel || "Lodging Gross Sales",
        deductionsLabel: deductionsLabel || "Allowable Deductions",
        totalLabel: totalLabel || "Taxable Receipts"
      },
      totalPaymentDueProviderValues: [],
      allProvidersSet: new Set()
    };
  }, [salesLabel, deductionsLabel, totalLabel])

  const addProvider = useCallback((providerKey, allProvidersSet) => {
    allProvidersSet.add({
      id: providerKey,
      label: providerMap[providerKey] || providerKey
    });
  }, [providerMap])

  const addTaxableReceiptsProviderValue = (providerKey, salesAndDeductions, value) => {
    let taxableReceiptsForProvider = convertStringToCurrency(value);
    salesAndDeductions.taxableReceiptsProviderValues.push({
      providerID: providerKey,
      value: taxableReceiptsForProvider
    })
  }

  const addSalesProviderValue = (providerKey, salesAndDeductions, value) => {
    let salesForProvider = convertStringToCurrency(value);
    salesAndDeductions.salesProviderValues.push({
      providerID: providerKey,
      value: salesForProvider
    })
  }

  const addDeductionsProviderValue = (providerKey, salesAndDeductions, value) => {
    let deductionsForProvider = convertStringToCurrency(value);
    salesAndDeductions.deductionsProviderValues.push({
      providerID: providerKey,
      value: deductionsForProvider
    })
  }

  const convertTaxTypeBreakdown = useCallback((licenseTaxableReceiptsForType, taxType, activity, provider) => {
    const amountDueForType = activity.taxTypes[taxType.key][taxType.totalKey];
    const providerValue = convertStringToCurrency(amountDueForType);
    licenseTaxableReceiptsForType.primaryItem.providerValues.push({ providerID: provider, value: providerValue });

    taxType.breakdown.forEach(component => {
      let breakdownMatch = licenseTaxableReceiptsForType.breakdown.find(obj => obj.key === component.key);
      if (!breakdownMatch) {
        breakdownMatch = {
          key: component.key,
          label: component?.name,
          infoText: "", // we don't want to show the info icons on reproting history page, because they may not reflect the numbers at the time the tax was paid
          providerValues: []
        };
        licenseTaxableReceiptsForType.breakdown.push(breakdownMatch);
      }
      const breakdownValue = activity.taxTypes[taxType.key][component.key];

      if(!isNil(breakdownValue)){
        breakdownMatch.providerValues.push({ providerID: provider, value: convertStringToCurrency(breakdownValue) });
      }
    });
  }, []);


  // Convert the data into a suitable format for the tax accordion components
  const convertedDataGroupedByPeriod = useMemo(() => {
    if (!Array.isArray(filteredData) || !filteredData.length) {
      return [];
    }

    const sortedData = filteredData.sort((a, b) => new Date(b.endDate) - new Date(a.endDate));

    return sortedData.reduce((acc, item) => {

      let licenses = [];
      if ((item.payments.length)) {
        licenses = item.payments.map((payment) => {
          let { taxableReceipts, salesAndDeductions, totalPaymentDueProviderValues, allProvidersSet } = initializeLicenseValues();

          payment.taxableActivities.forEach((activity) => {
            const provider = activity.provider;
            addProvider(provider, allProvidersSet);
            addSalesProviderValue(provider, salesAndDeductions, activity.totalRevenue);
            addDeductionsProviderValue(provider, salesAndDeductions, activity.allowableDeductions);
            addTaxableReceiptsProviderValue(provider, salesAndDeductions, activity.taxableReceipts);

            let totalDueForProvider = 0;

            taxTypeBreakdownMap.forEach((taxType) => {
              let resultTaxObj = activity.taxTypes[taxType.key];
              if (resultTaxObj) {
                let licenseTaxableReceiptsForType = taxableReceipts.find((rec) => rec.key === taxType);

                if (!licenseTaxableReceiptsForType) {
                  licenseTaxableReceiptsForType = {
                    primaryItem: {
                      key: taxType.key,
                      label: taxType.name,
                      infoText: taxType.infoText || "",
                      providerValues: []
                    },
                    breakdown: []
                  };
                  taxableReceipts.push(licenseTaxableReceiptsForType);
                }

                convertTaxTypeBreakdown(licenseTaxableReceiptsForType, taxType, activity, provider);
                totalDueForProvider += parseFloat(resultTaxObj[taxType.totalKey]);
              }
            });

            totalPaymentDueProviderValues.push({
              providerID: provider,
              value: convertStringToCurrency(totalDueForProvider)
            });
          });

          return {
            uniqueKey: `licencePeriodTaxPayment_${payment.paymentId}`,
            headerData: {
              paymentDateLabel: item.label,
              startDate: item.startDate,
              endDate: item.endDate,
              datePaid: payment.paymentDate,
              reporter: payment.reporter,
              daysAvailable: payment.daysAvailable,
              daysOccupied: payment.daysOccupied,
              totalTaxableReceipts: payment.taxableReceipts,
              paymentAmount: payment.paymentTotal,
              paymentProvider: PaymentMethodLabel[payment.paymentProvider] || payment.paymentProvider,
            },
            detailData: {
              providers: Array.from(allProvidersSet),
              salesAndDeductions: salesAndDeductions,
              taxableReceipts: taxableReceipts,
              totalPaymentDue: {
                total: payment.paymentTotal,
                providerValues: totalPaymentDueProviderValues
              },
              periodLabel: item.label
            }
          };
        });
      }
      else {
        licenses.push({
          uniqueKey: `licencePeriodTaxPayment_${item.startDate}_${item.endDate}`,
          headerData: {
            paymentDateLabel: item.label,
            startDate: item.startDate,
            endDate: item.endDate,
          },
        });
      }

      return acc.concat(licenses);
    }, []);
  }, [filteredData, taxTypeBreakdownMap, addProvider, convertTaxTypeBreakdown, initializeLicenseValues]);


  return (
    <>
      {
        convertedDataGroupedByPeriod.map((licensePayment) => (
          <ReportingHistoryAccordionPeriodComponent
            key={licensePayment.uniqueKey}
            data={licensePayment}
            applicationNumber={applicationNumber}
            totPayable={totPayable}
          >
          </ReportingHistoryAccordionPeriodComponent>
        ))
      }
    </>);
}

