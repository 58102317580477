import React, { useCallback, useMemo } from 'react';
import { useLicensesInReport } from "../utils";
import { groupBy, capitalize } from 'lodash'
import { useDispatch, useSelector } from "react-redux";
import { appActions } from 'app/state/app/app-slice';
import * as appSelectors from "app/state/app/app-selectors";
import { StyledTab, StyledTabs } from "common/styled-tabs";

export function DynamicQuarterPeriodTypeSelectionTabs({ resetTaxablePeriods, disabled }) {
  const allAvailableLicenses = useLicensesInReport({ disablePeriodTypeFilter: true });
  const licensesByPaymentPeriodType = useMemo(() => {
    return groupBy(allAvailableLicenses, 'totPaymentPeriod');
  }, [allAvailableLicenses]);

  const dispatch = useDispatch();

  const selectPeriodType = useCallback((periodType) => {
    dispatch(appActions.setDynamicQuarterSelectedPeriodType(periodType))
    resetTaxablePeriods({ dynamicQuarterSelectedPeriodType: periodType })
  }, [dispatch, resetTaxablePeriods])

  const dynamicQuarterSelectedPeriodType = useSelector(appSelectors.dynamicQuarterSelectedPeriodType);

  if (Object.values(licensesByPaymentPeriodType).filter(licenses => licenses.length > 0).length < 2) {
    return null;
  }

  return (
    <StyledTabs
      indicatorColor="primary"
      value={dynamicQuarterSelectedPeriodType}
      onChange={(e, periodType) => selectPeriodType(periodType)}
    >
      {Object.keys(licensesByPaymentPeriodType).map(paymentPeriodType => (
        <StyledTab
          key={paymentPeriodType}
          label={`${capitalize(paymentPeriodType)} (${licensesByPaymentPeriodType[paymentPeriodType].length})`}
          value={paymentPeriodType}
          disabled={disabled}
        />))}
    </StyledTabs>
  )

}
