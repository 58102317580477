import * as appSelectors from 'app/state/app/app-selectors';
import { filesActions } from 'app/state/files/files-slice';
// import { loginActions } from 'app/state/login/login-slice';
import { networkActions } from 'app/state/network/network-slice';
import { payTotActions } from 'app/state/pay-tot/pay-tot-slice';
import { payloadActions } from 'app/state/payload/payload-slice';
import React, { useCallback, useEffect } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { filesManager } from 'utils/files-manager';

/**
 * The difference between these wrappers and the ones in "entry-exit-wrapper.jsx"
 * is that these are meant to wrap an entire flow. This is meant to put the state
 * of the flow into a pristine/workable condition, so that continual entry and
 * exits are possible without weird UI glitches due to an unexpected working state.
 */
export function PortalFlowWrapper(WrappedComponent) {
  return function InternalPortalFlowEntry(props) {
    const dispatch = useDispatch();

    const useCustomerPortal = useSelector(appSelectors.useCustomerPortal);

    const generalTriggers = useCallback(() => {
      filesManager.clearProofs();

      batch(() => {
        dispatch(filesActions.reset());
        dispatch(networkActions.reset());
        dispatch(payloadActions.resetFlow());
        dispatch(payTotActions.resetFlow());
      });
    }, [dispatch]);

    // const entryTriggers = useCallback(() => {
    //   generalTriggers();
    // }, [generalTriggers]);

    const exitTriggers = useCallback(() => {
      generalTriggers();
    }, [generalTriggers]);

    useEffect(() => {
      if (!useCustomerPortal) return;

      // entryTriggers();
      return exitTriggers;
    }, [dispatch, exitTriggers, useCustomerPortal]);

    return <WrappedComponent {...props} />;
  };
}
