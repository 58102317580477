export class RetrieveLicenseByApplicationNumber extends Error {
  constructor(message) {
    super(message);
    this.name = 'RetrieveLicenseByApplicationNumber';
  }
}

export class UpdateFlowEntryError extends Error {
  constructor(message) {
    super(message);
    this.name = 'UpdateFlowEntryError';
  }
}

export class SingleRenewalFlowEntryError extends Error {
  constructor(message) {
    super(message);
    this.name = 'SingleRenewalFlowEntryError';
  }
}

export class SingleRenewalFlowAltEntryError extends Error {
  constructor(message) {
    super(message);
    this.name = 'SingleRenewalFlowAltEntryError';
  }
}

export class PermitActionabilityAPIError extends Error {
  constructor(message) {
    super(message);
    this.name = 'PermitActionabilityAPIError';
  }
}

export class CalculateMultiPropertyTotStrategyError extends Error {
  constructor(message) {
    super(message);
    this.name = 'CalculateMultiPropertyTotStrategyError';
  }
}

