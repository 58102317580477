import React from 'react';
import { parseHTMLString } from 'utils/utility-functions';

export function TextRender({ component: Component, text }) {
  if (Array.isArray(text)) {
    return (
      <>
        {text.map((textSection, idx) => (
          <Component key={idx}>{parseHTMLString(textSection)}</Component>
        ))}
      </>
    );
  }

  return <Component>{parseHTMLString(text)}</Component>;
}
