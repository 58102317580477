import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import styled, { css } from 'styled-components';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as Sentry from '@sentry/react';
import * as appSelector from 'app/state/app/app-selectors';
import { errorActions } from 'app/state/error/error-slice';

const navigationButtonBase = css`
  height: 36px;
  border-radius: 5px;
  text-transform: none;
`;

const textBase = css`
  font-family: 'Lato';
  font-size: 14px;
  letter-spacing: normal;
`;

export const WhiteButton = styled(Button)`
  &&& {
    ${navigationButtonBase}
    width: ${(props) => props.width || '100px'};
    border: solid 1px #dfe3e9;

    .MuiButton-label {
      ${textBase}
      font-size: ${props => props.fontSize || null};
      color: #5c5d5d;
    }
  }
`;

export const BlackButton = styled(Button)`
  &&& {
    ${navigationButtonBase}
    width: ${(props) => props.width || '200px'};
    background-color: ${(props) => props.disabled ? 'rgb(153,154,154)' : '#1b1c1d'};

    .MuiButton-label {
      ${textBase}
      font-size: ${props => props.fontSize || null};
      color: #ffffff;
      font-weight: bold;
    }
  }
`;

export function BackButton(props) {
  const { label = 'Back', width, onClick, isDisabled } = props;

  const history = useHistory();

  const theme = useSelector(appSelector.theme);

  const handleBackClick = useCallback(() => {
    if (!onClick) history.goBack();
    else onClick();
  }, [history, onClick])

  return (
    <WhiteButton
      onClick={handleBackClick}
      disabled={isDisabled}
      width={width}
      fontSize={theme?.form?.buttonTextFontSize}
    >
      {label}
    </WhiteButton>
  );
}

export function ContinueButton(props) {
  const {
    type,
    label = 'Continue',
    onClick = () => {},
    isDisabled,
    loading,
    width,
  } = props;

  const dispatch = useDispatch();

  const theme = useSelector(appSelector.theme);

  const handleContinueClick = useCallback(async () => {
    try {
      await onClick();
    } catch (err) {
      console.error(err);
      Sentry.captureException(err);
      dispatch(errorActions.setHasErrored(true));
    }
  }, [dispatch, onClick]);

  const buttonDisabled = isDisabled || loading;
  const cySelector = buttonDisabled ? 'submit-button-disabled' : 'submit-button';

  return (
    <BlackButton
      type={type || 'submit'}
      onClick={handleContinueClick}
      disabled={isDisabled || loading}
      width={width}
      fontSize={theme?.form?.buttonTextFontSize}
      data-cy={cySelector}
    >
      {loading ? <CircularProgress size={20} /> : label}
    </BlackButton>
  );
}
