import * as appSelector from 'app/state/app/app-selectors';
import * as errorSelectors from 'app/state/error/error-selectors';
import * as cs from 'common/common-styles';
import { BackButton, ContinueButton } from 'common/navigation-buttons';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { parseHTMLString } from 'utils/utility-functions';
import { EmailList } from './email-list';

dayjs.extend(utc);

function Error() {
  /**
   * The hierarchy of error messages displayed should be in the following order:
   *  1. "alternateErrorString"
   *    This first as it a capturing the context of something we are specifically on the look out for.
   *    e.g. Failed document uploads
   *  2. "cityInfo.customErrorMessage"
   *    This one is more of a general error message for the user in a specific jurisdiction.
   *    e.g. Placer
   *  3. standard error message
   */
  const alternateErrorString = useSelector(errorSelectors.alternateErrorString);
  const cityInfo = useSelector(appSelector.cityInfo);

  if (alternateErrorString) return parseHTMLString(alternateErrorString);
  if (cityInfo?.customErrorMessage) return parseHTMLString(cityInfo.customErrorMessage);

  return (
    <>
      There was an unexpected server error. Please try again later.
      <br />
      If the problem persists, please contact us at&nbsp;
      <EmailList emails={cityInfo?.contactEmail} />
    </>
  );
}

const SomethingWentWrong = styled(cs.ErrorHeader).attrs({
  size: 'huge'
})`
  font-size: 50px;
`;

export function ErrorView(props) {
  const {
    visible,
    dismissButtonLabel = 'Try again',
    handleDismissModal,
    returnButtonLabel = 'Return to main menu',
    handleReturnClick,
  } = props;

  return (
    <cs.WhiteModal hideBackdrop open={visible}>
      <cs.ViewContainer>
        <SomethingWentWrong>Something went wrong.</SomethingWentWrong>

        <cs.ErrorHeader>
          <p>{dayjs.utc().format()}</p>
          <p>{window.location.href}</p>
        </cs.ErrorHeader>

        <cs.ErrorHeader>
          <Error />
        </cs.ErrorHeader>

        <cs.TightButtonWrapper>
          {/* This button is only visible when the user has reached a manageable error state. */}
          {handleDismissModal && <ContinueButton label={dismissButtonLabel} onClick={handleDismissModal} />}

          <BackButton width="200px" label={returnButtonLabel} onClick={handleReturnClick} />
        </cs.TightButtonWrapper>

        <img src="/assets/error-page.svg" alt="Multiple homes together" />
      </cs.ViewContainer>
    </cs.WhiteModal>
  );
}
