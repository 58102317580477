import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import get from 'lodash/get';
import template from 'lodash/template';
import 'styled-components/macro';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup as MUIRadioGroup,
} from '@material-ui/core';
import * as appSelectors from 'app/state/app/app-selectors';
import * as networkSelectors from 'app/state/network/network-selectors';
import * as cs from 'common/common-styles';
import { DEFAULT_FORM_VALUE as emptyString } from 'common/constants';
import { radioGroupTestId } from 'test/constants';
import { parseHTMLString } from 'utils/utility-functions';
import { useDisabled } from './input-field';
import { FormikError } from './inputs/shared-components';
import { useTextField } from './use-text-field';
import { useInput } from './utils/use-input';
import { withVisibility } from './utils/with-visibility';

// See "RadioGroupSubComponent" within "fieldProps.ts"
function RadioFieldComponent(props) {
  const RadioComponent = props.disabled ? Radio : cs.BlackRadio;

  const { values } = useFormikContext();

  const theme = useSelector(appSelectors.theme);

  const memoizedLabel = useMemo(() => {
    if (!props?.templateKeys) return parseHTMLString(props.label);

    const templateOptions = {};
    // Add one to each index as it's easier to read/interpret in the config setup
    props.templateKeys.forEach((key, idx) => { templateOptions[`template_${idx + 1}`] = get(values, key) });

    const templatedString = template(props.label)(templateOptions);
    return parseHTMLString(templatedString);
  }, [props.label, props.templateKeys, values]);

  return (
    <FormControlLabel
      name={props.name}
      value={props.value}
      control={<RadioComponent required={!props.suppressRequired} disabled={props.disabled} data-cy={props.dataCy}/>}
      label={
        <cs.BoldedLabel
          fontSize={theme?.form?.pageTextFontSize}
          bold={props.radioValue === props.value}
        >{memoizedLabel}</cs.BoldedLabel>
      }
      css={props.additionalCSS}
    />
  );
}

const RadioField = withVisibility(RadioFieldComponent);

// See "RadioGroupProps" within "fieldProps.ts"
function RadioGroupComponent(props) {
  const { textFieldName, components } = props;

  const originalLicenseInfo = useSelector(networkSelectors.originalLicenseInfo);
  const disabled = useDisabled(props, originalLicenseInfo);
  const enableEditingAllFields = useSelector(appSelectors.enableEditingAllFields);

  const { field: formikField, meta } = useInput(props);

  const field = useTextField(formikField, textFieldName, components);

  return (
    <FormControl
      component="fieldset"
      disabled={disabled}
      data-testid={radioGroupTestId}
      data-cy={props.dataCy}
    >
      <MUIRadioGroup
        css={props.additionalCSS}
        aria-label={props.ariaLabel}
        /**
         * Spread "field" before "value" prop or else React will complain about the component
         * switching from an uncontrolled component to a controlled component
         */
        {...field}
        value={field.value || emptyString}
      >
        {components.filter(({ id }) => !!id).map(({ id, name, disabled: radioDisabled, ...other }) => (
          <RadioField
            key={id}
            name={field.name}
            value={name}
            radioValue={field.value}
            disabled={disabled || (radioDisabled && !enableEditingAllFields)}
            suppressRequired={props.suppressRequired}
            {...other}
          />
        ))}
      </MUIRadioGroup>

      <FormikError {...meta} />
    </FormControl>
  );
}

export const RadioGroup = withVisibility(RadioGroupComponent);
