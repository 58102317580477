import * as appSelectors from 'app/state/app/app-selectors';
import { useField } from 'formik';
import get from 'lodash/get';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  formatProperty,
  getLicenseReportFormikPath,
  getPeriodDisplayName,
  useLicensesInReportMap,
} from '../../utils';
import { Container } from '../revenue-inputs-styles';
import { RemovePeriodButton } from './remove-period-button';
import { FlexRow, Header } from './styles';
import { TOTMultipleLicensePaymentReport } from './tot-multiple-license-payment-report';
import { TOTSingleLicensePaymentReport } from './tot-single-license-payment-report';

function TOTPaymentPeriodHeader({ period, additionalHeaderContent, ...sectionProps }) {
  return (
    <TOTPaymentSectionHeader {...sectionProps}>
      {getPeriodDisplayName(period)} {additionalHeaderContent}
    </TOTPaymentSectionHeader>
  )
}

function TOTPaymentSectionHeader({ children, large, periodPath, licenseReportPath, isRemovable }) {
  return (
    <FlexRow>
      <Header large={large}>{children}</Header>
      <RemovePeriodButton periodPath={periodPath} licenseReportPath={licenseReportPath} removable={isRemovable} />
    </FlexRow>
  )
}

export function TOTPaymentPeriodSection({ periodPath, isRemovable }) {
  const [{ value: period }] = useField(periodPath);

  const config = useSelector(appSelectors.renderConfig);
  const isMultiPropertyTOTEnabled = useSelector(appSelectors.isMultiPropertyTOTEnabled);
  const isPaymentPeriodDynamic = config?.reporting?.isPaymentPeriodDynamic

  const numSelectedReports = useMemo(() => period.licenseReports.filter(({ selected }) => selected).length,
    [period.licenseReports]);

  const licenseIdToLicenseInReport = useLicensesInReportMap();

  const noLicenseReportsSelectedInPaymentPeriod = !period.licenseReports.some(({ selected }) => selected);
  if (!numSelectedReports || noLicenseReportsSelectedInPaymentPeriod) return null;

  const useMultiPropertyLayout = period.licenseReports.length > 1 || isMultiPropertyTOTEnabled;

  if (period.licenseReports.length < 2 || period.licenseReports.some(report => report.taxableActivities.length > 1)) {
    return (
      <>
        <TOTPaymentPeriodHeader
          period={period}
          periodPath={periodPath}
          isRemovable={!isPaymentPeriodDynamic && !useMultiPropertyLayout && (isRemovable || numSelectedReports > 1)}
          large={useMultiPropertyLayout}
        />
        {period.licenseReports.map((licenseReport, licenseReportIdx) => {
          if (!licenseReport?.selected) return null;

          const licenseReportPath = getLicenseReportFormikPath({ periodPath, licenseReportIdx });
          const propertyDisplayName = formatProperty(licenseIdToLicenseInReport[licenseReport.licenseId]);
          const unitNumber = get(licenseIdToLicenseInReport[licenseReport.licenseId], 'unitNumber');
          const internalIsRemovable = !isPaymentPeriodDynamic && (isRemovable || numSelectedReports > 1);

          return (
            <Container key={licenseReportIdx}>
              {useMultiPropertyLayout && (
                <TOTPaymentSectionHeader
                  licenseReportPath={licenseReportPath}
                  periodPath={periodPath}
                  isRemovable={internalIsRemovable}
                >
                  {propertyDisplayName}{unitNumber && ` - Unit: ${unitNumber}`}
                </TOTPaymentSectionHeader>
              )}
              <TOTSingleLicensePaymentReport
                key={licenseReport.licenseId}
                licenseReportPath={licenseReportPath}
                periodName={getPeriodDisplayName(period)}
                useVerticalLayout={isPaymentPeriodDynamic}
              />
            </Container>
          )
        })}
      </>
    );
  }

  return (
    <Container>
      {!isPaymentPeriodDynamic && <TOTPaymentPeriodHeader period={period} periodPath={periodPath} />}
      <TOTMultipleLicensePaymentReport
        periodPath={periodPath}
        isRemovable={isRemovable && !isPaymentPeriodDynamic}
        useVerticalLayout={isPaymentPeriodDynamic}
      />
    </Container>
  )
}
