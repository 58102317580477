import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { appActions } from 'app/state/app/app-slice';
import * as appSelectors from 'app/state/app/app-selectors';
import { PaymentTabText, Tab, TabItem } from './tab-styles';
import { paymentMethodType } from 'common/constants';
import styled from 'styled-components';

export const SVGwrap = styled.img`
  height: 15px;
`;
const CHECK_SVG = '/assets/cheque-sign-icon.svg';

export function PayByCheckTab() {
  const dispatch = useDispatch();

  const activePaymentMethodTab = useSelector(appSelectors.activePaymentMethodTab);

  const isTabActive = useMemo(() => activePaymentMethodTab === paymentMethodType.CHECK, [activePaymentMethodTab]);

  const onClick = useCallback(() => {
    dispatch(appActions.changePaymentMethodTab({ newTab: paymentMethodType.CHECK }));
  }, [dispatch]);

  return (
    <Tab isActive={isTabActive} onClick={onClick} data-cy="check-payment-method">
      <TabItem>
        <SVGwrap src={CHECK_SVG} alt="Check" />
      </TabItem>

      <TabItem>
        <PaymentTabText>Check</PaymentTabText>
      </TabItem>
    </Tab>
  );
}
