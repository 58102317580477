import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useFormikContext } from 'formik';
import get from 'lodash/get';
import merge from 'lodash/merge';
import set from 'lodash/set';
import template from 'lodash/template';
import 'styled-components/macro';
import { parseHTMLString } from 'utils/utility-functions';
import { checkIsRealNumber } from 'pages/pay-flow/report-revenue-page/modules/tot-payment-periods/fields/shared';
import { payloadActions } from 'app/state/payload/payload-slice';

export function DerivedNumber(props) {
  const dispatch = useDispatch();
  const { values, setValues } = useFormikContext();

  const valuesFromFormik = useCallback((pathList) => pathList.map((path) => {
    const value = get(values, path);
    return checkIsRealNumber(value) ? Number(value) : 0;
  }), [values]);

  const templateKeys = useMemo(() => Object.keys(props.templateParams), [props.templateParams]);

  const valueDeterminationEqs = useMemo(() => {
    return templateKeys.reduce(
      (eqMap, paramKey) => ({
        ...eqMap,
        // eslint-disable-next-line no-new-func
        [paramKey]: Function(...props.templateParams[paramKey].mathFnArgs),
      }),
      {}
    );
  }, [props.templateParams, templateKeys]);

  const equationArgs = useMemo(() => {
    return templateKeys.reduce(
      (argMap, paramKey) => ({
        ...argMap,
        [paramKey]: valuesFromFormik(props.templateParams[paramKey].formikValuePaths),
      }),
      {}
    );
  }, [props.templateParams, templateKeys, valuesFromFormik]);

  const templateData = useMemo(() =>
    templateKeys.reduce((templateMap, key) => {
      const cV = get(values, props.templateParams[key].field);
      const rV = valueDeterminationEqs[key](...equationArgs[key]);

      /**
       * Do not add an additional condition here to not save to the Redux and Formik
       * state as if the data doesn't need to be saved, then the entire sub-dict
       * in the supplemental questions field needs to be removed via an "unset"
       * data transform.
       */
      if (cV !== rV) {
        const changeSet = set({}, props.templateParams[key].field, rV);
        dispatch(payloadActions.mergeFormikSnapshot(changeSet));
        setValues(merge(values, changeSet, { _preventAutoSave: true }));
      }

      return { ...templateMap, [key]: rV }
    }, {}),
    [dispatch, equationArgs, props.templateParams, setValues, templateKeys, valueDeterminationEqs, values],
  );

  const templatedString = useMemo(() =>
    parseHTMLString(template(props.templateString)(templateData)),
    [templateData, props.templateString],
  );

  return <div css={props?.additionalCSS}>{templatedString}</div>;
}
