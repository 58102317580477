export class PortalAuthResendCodeError extends Error {
  constructor(message) {
    super(message);
    this.name = 'PortalAuthResendCodeError';
  }
}

export class PortalAuthLoginError extends Error {
  constructor(message) {
    super(message);
    this.name = 'PortalAuthLoginError';
  }
}

export class PortalAuthSignOutError extends Error {
  constructor(message) {
    super(message);
    this.name = 'CognitoAuthSignOutError';
  }
}

export class PortalAuthCustomChallengeError extends Error {
  constructor(message) {
    super(message);
    this.name = 'PortalAuthCustomChallengeError';
  }
}
