export const ZERO = 0;

export const ONE = 1;

export const paymentMethodType = {
  CREDIT_CARD: 0,
  ACH: 1,
  CHECK: 2,
  PUSH_ACH: 3,
}

export const PaymentMethodLabel = {
  none: "None",
  credit_card: "Credit Card",
  ach: "ACH",
  check: "Check",
  push_ach: "ACH",
}

export const DEFAULT_ACH_LIMIT = 6000;

export const TODAYS_DATE = new Date();

export const MAX_VALUE_FOR_TAXABLE_RECEIPTS = 999999.99;

export const MAX_FILE_SIZE = 4 * 1000 * 1000; // Byte -> Kilobyte -> Megabyte * 4 => 4 MB
export const MIN_FILE_SIZE = 15 * 1000; // 15 KB

export const emailRegexPattern = "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-]+$";

export const DEFAULT_FORM_VALUE = '';

// ------------------------------------------------------
// Errors for legacy address lookup page
// ------------------------------------------------------

export const ENTRY_ERROR_PHASES = {
  NoAPNForProperty: 'NoAPNForProperty',
  NoPropertyForAPN: 'NoPropertyForAPN',
  APNHasLicenses: 'APNHasLicenses',
  Default: 'Default'
};

// ------------------------------------------------------
// Legacy input field types
// ------------------------------------------------------

export const LUITypes = { // LUI - Labelled User Interfaces
  text: 'text',
};

// ------------------------------------------------------
// Used in Cognito auth modals
// ------------------------------------------------------

export const loginPhases = {
  EMAIL_ENTRY: 'EMAIL_ENTRY',
  CODE_ENTRY: 'CODE_ENTRY',
};

/* values in this mapping must be identical to flow names from stats response */
export const FLOWS = {
  APPLY_LICENSE: 'applyLicense',

  UPDATE_CERTIFICATE: 'updateCertificate',

  RENEW_LICENSE: 'renewLicense',
  BULK_RENEWAL: 'bulkRenewal',

  PAY_TOT: 'payTot',
  TBID: 'tbid',
};

/*
 * <input type="date" ... /> requires that the value attribute
 * is specified in this particular format. This cannot be used to
 * customize how dates are displayed on the UI.
 *
 * See also: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/date#value
 */
export const DATE_INPUT_VALUE_FORMAT = 'YYYY-MM-DD';

export const BULK_TOT_MAX_PROPERTIES_THRESHOLD = 25;

// ------------------------------------------------------
// Customer Portal Constants/Values
// ------------------------------------------------------

export const APP_TYPE_PORTAL = 'portal';

export const ALT_LANDING_PAGE_HEADER_TEXT_PATH = 'landingPage.headerText';
export const LANDING_PAGE_BODY_TEXT_PATH = 'landingPage.bodyText';

export const CREATE_ACCOUNT_EMAIL_FIELDS_PATH = 'accountManagement.emailFields';
export const CREATE_ACCOUNT_ACCOUNT_DETAILS_FIELDS_PATHS = 'accountManagement.accountDetailsFields';
export const UPDATE_ACCOUNT_FIELDS_PATH = 'accountManagement.updateUserAccountDetailsPageFields';

export const DASHBOARD_NOTICE_PATH = 'dashboard.notice';
export const DASHBOARD_PERMITS_LIST_LAYOUT_PATH = 'dashboard.fullPermitsDisplayLayout';
export const DASHBOARD_REPORTING_HISTORY_CONFIG_PATH = 'dashboard.reportingHistoryConfig';

export const PROVIDER_MAP_PATH = 'providerMap';
export const TAX_SUMMARY_BREAKDOWN_MAP_PATH = 'taxTypeBreakdownMap';

// Feature Flags
export const HIDE_DASHBOARD_PERMITS_DISPLAY_PATH = 'dashboard.hideDashboardPermitsDisplay';
export const HIDE_USER_ACCOUNT_UPDATE_PAGE_PATH = 'dashboard.hideUserAccountUpdatePage';
export const DISABLE_USER_REPORTING_HISTORY_PATH = 'dashboard.disableUserReportingHistory';

export const DISABLE_APPLY_FLOW_PATH = 'dashboard.disableApplyFlow';
export const DISABLE_UPDATE_FLOW_PATH = 'dashboard.disableUpdateFlow';
export const DISABLE_SINGLE_RENEW_FLOW_PATH = 'dashboard.disableSingleRenewFlow';
export const DISABLE_BULK_RENEWAL_FLOW_PATH = 'dashboard.disableBulkRenewalFlow';
export const DISABLE_BULK_TAX_FLOW_PATH = 'dashboard.disableBulkTaxFlow';

export const DISABLE_SINGLE_PERMIT_ACTIONS_PATH = 'dashboard.disableSinglePermitActions';
export const DISABLE_PERMIT_REPORTING_HISTORY_PATH = 'dashboard.disablePermitReportingHistory';

/**
 * This is hardcoded, because until we decide to change the schema for how the data of a "person"
 * is stored then this will never change. As making that change would require a massive effort in
 * updating the database in all existing tables and system configs.
 *
 * Not all jurisdictions require the "misc" field. However, "pick" from Lodash will handle that for us.
 */
export const PERSON_FIELDS_OF_INTEREST = [
  'firstName',
  'lastName',
  'email',
  'companyName',
  'phone',
  'mailingAddress',
  'misc'
];

// Shared Verbiage (will be translated by "useTranslation" hook)
export const PERMIT_VERBIAGE_TRANSLATION_KEY = 'permit';
export const PERMIT_VERBIAGE = 'Permit';

export const PERMIT_NUMBER_VERBIAGE_TRANSLATION_KEY = 'permitNumber'
export const PERMIT_NUMBER_VERBIAGE = 'Permit Number';

export const TOT_VERBIAGE_TRANSLATION_KEY = 'tot';
export const TOT_VERBIAGE = 'TOT';

// ------------------------------------------------------
// Customer Portal Common Strategy Entry Paths
// ------------------------------------------------------

export const ON_UPDATE_FLOW_ENTRY = 'onUpdateFlowEntry';
export const ON_SINGLE_RENEWAL_FLOW_ENTRY = 'onSingleRenewalFlowEntry';

// ------------------------------------------------------
// Reporting History Constants/Values
// ------------------------------------------------------

export const LAST_12_MONTHS_OPTION = 'LAST_12_MONTHS';

export const DEFAULT_TITLE_CASED_KEYS = ['propertyAddress'];

export const MAX_DIGIFY_UPLOAD_RETRY_ATTEMPTS = 3;


// ------------------------------------------------------
// Pay flow Constants/Values
// ------------------------------------------------------
export const TOT_PAYMENT_STATUS_INCOMPLETE = 'incomplete';