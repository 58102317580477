import React from 'react'
import styled from 'styled-components';
import { EmailList } from 'common/email-list';

const Container = styled.div`
  margin-top: 10px;
  margin-bottom: 20px;
`;

const ErrorMessage = styled.p`
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #e02020;
`;

export const typeDocSizeTooLarge = 'typeDocSizeTooLarge';
export const typeDocSizeTooSmall = 'typeDocSizeTooSmall';
export const typeDocCount = 'typeDocCount';

const joinInsert = ', ';

function FilesError({ errorMessage, files = [] }) {
  return (
    <Container>
      <ErrorMessage>{errorMessage}</ErrorMessage>
      <ErrorMessage>{files.join(joinInsert)}</ErrorMessage>
    </Container>
  )
}

const FILES_TOO_LARGE_ERROR = "The following file(s) cannot be uploaded as they exceed the maximum file size of 4 MB:";
const FILES_TOO_SMALL_ERROR = "The following file(s) cannot be uploaded as they are smaller than the minimum size of 15 KB:";

function FilesTooLargeError({ files = [] }) {
  return (
    <FilesError
      files={files}
      errorMessage={FILES_TOO_LARGE_ERROR}
    />
  );
}

function FilesTooSmallError({ files = [] }) {
  return (
    <FilesError
      files={files}
      errorMessage={FILES_TOO_SMALL_ERROR}
    />
  );
}

function TooManyFilesError({ cityInfo }) {
  return (
    <Container>
      <ErrorMessage>
        Maximum number of files exceeded. Please email{' '}
        <EmailList emails={cityInfo?.contactEmail} />
        {' '}for assistance.
      </ErrorMessage>
    </Container>
  );
}

function MessageSwitch(type) {
  switch (type) {
    case typeDocSizeTooLarge:
      return FilesTooLargeError;
    case typeDocSizeTooSmall:
      return FilesTooSmallError;
    case typeDocCount:
      return TooManyFilesError;
    default:
      return null;
  }
}

export function DocumentErrorMessage(props) {
  if (!props?.show) return null;

  const Component = MessageSwitch(props?.type);
  return <Component {...props} />;
}
