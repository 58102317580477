import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { appActions } from 'app/state/app/app-slice';
import * as appSelectors from 'app/state/app/app-selectors';
import { PaymentTabText, Tab, TabItem } from './tab-styles';
import { paymentMethodType } from 'common/constants';
import styled from 'styled-components';

export const SVGwrap = styled.img`
  height: 15px;
`;
const ACH_ICON = '/assets/bank-building-ACH-bank-transfer.svg';

export function PushACHTab() {
  const dispatch = useDispatch();

  const activePaymentMethodTab = useSelector(appSelectors.activePaymentMethodTab);

  const isTabActive = useMemo(() => activePaymentMethodTab === paymentMethodType.PUSH_ACH, [activePaymentMethodTab]);

  const onClick = useCallback(() => {
    dispatch(appActions.changePaymentMethodTab({ newTab: paymentMethodType.PUSH_ACH }));
  }, [dispatch]);

  return (
    <Tab isActive={isTabActive} onClick={onClick} data-cy="push-ach-method">
      <TabItem>
        <SVGwrap src={ACH_ICON} alt="bank payment" />
      </TabItem>

      <TabItem>
        <PaymentTabText>ACH Bank Transfer</PaymentTabText>
      </TabItem>
    </Tab>
  );
}
