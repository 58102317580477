import React from 'react';
import * as cs from 'common/common-styles';

export function UploadButton({ fileKey, children, className }) {
  if (!fileKey) return null;

  return (
    <label htmlFor={fileKey}>
      <cs.GreenButton
        disableElevation
        variant="contained"
        component="span"
        style={{ marginTop: '20px' }}
        className={className}
      >
        {children || 'Upload Files'}
      </cs.GreenButton>
    </label>
  )
}
