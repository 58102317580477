import dayjs from 'dayjs';
import utcPlugin from 'dayjs/plugin/utc';
import localizedFormatPlugin from 'dayjs/plugin/localizedFormat';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utcPlugin);
dayjs.extend(localizedFormatPlugin);
dayjs.extend(timezone);


export { dayjs };
