import * as appSelectors from 'app/state/app/app-selectors';
import { useSelector } from 'react-redux';

export const TRANSLATED_STRING_IDS = {
  rentalStructure: 'rentalStructure'
}

export function useTranslations() {
  return useSelector(appSelectors.translations);
}
