import React from 'react';
import { Button } from 'semantic-ui-react';
import styled from 'styled-components';

const PayButton = styled(Button)`
  &&& {
    background-color: ${(props) => (props.disabled ? '#999a9a' : '#458E87')};
    color: #eee;
    cursor: pointer;
    border-radius: 6px;
    box-shadow: inset 0 0 0 1px rgba(50, 50, 93, 0.1), 0 2px 5px 0 rgba(50, 50, 93, 0.1),
      0 1px 1px 0 rgba(0, 0, 0, 0.07);
    border: 0;
    height: 40px;
    width: 100%;
    font-size: 16px;
    font-weight: 600;
    margin-top: 40px;
    max-width: 93vw;
  }
`;

/**
 * TODO:: DECK-7991
 * Standardized pay button for each and every vendor
 *
 * Requirements:
 *  1. States
 *    A. Loading state
 *    B. Disabled state
 *  2. Functionality:
 *    A. On click functions
 *  3. Display text
 */
export function PaymentButton(props) {
  const { loading, disabled, onClick, label } = props;

  return (
    <PayButton
      type='button'
      id='payButton'
      data-cy='pay-now-button'
      className="cc-pay-button"
      loading={loading}
      disabled={disabled}
      onClick={onClick}
    >
      {label}
    </PayButton>
  );
}
