import _ from 'lodash';
import { payloadActions } from 'app/state/payload/payload-slice';
import { payloadValuesTrimmer } from 'utils/utility-functions';
import { processDictDuplicates } from 'utils/people';
import dayjs from 'dayjs';
import { evaluateDeckardExpr } from "../strategies/deckard-expression-language";
import he from 'he';

function getWithFallback(obj, path) {
  if (Array.isArray(path)) {
    let i = 0;
    let gottenValue = undefined;
    while (gottenValue === undefined) {
      gottenValue = _.get(obj, path[i]);
      i++;
    }
    return gottenValue;
  }

  return _.get(obj, path);
}

function operationsGetter(op, state) {
  switch (op) {
    case 'get':
      // Retrieves a value from the store
      return (path, params = {}) => {
        const value = getWithFallback(state, path);
        if (!params?.clone) return value;

        return _.cloneDeep(value);
      }
    case 'set':
      /**
       * Returns the same value it is passed
       * Useful for when hard coding parts of a payload is necessary
       *  e.g.:
       *    1. Setting local contact as null, when there is no local contact
       */
      return (value) => value;
    case 'merge':
      return (targetPath, sourcePath) => {
        const target = _.cloneDeep(_.get(state, targetPath));
        const source = _.cloneDeep(_.get(state, sourcePath));
        return _.merge(target, source);
      };
    case 'concat':
      // Concatenates values retrieved from the store
      return (...args) => args.map((el) => _.get(state, el)).join(' ');
    case 'equal':
      // Determines if some value in the store is equal to the expected value (hardcoded)
      return (path, expected) => _.eq(_.get(state, path), expected);
    case 'notEqual':
      // Determines if some value in the store is NOT equal to the expected value (hardcoded)
      return (path, expected) => !_.eq(_.get(state, path), expected);
    case 'jsonEq':
      return (arg1, arg2) => _.isEqual(_.get(state, arg1), _.get(state, arg2));
    case 'notjsonEq':
      return (arg1, arg2) => !_.isEqual(_.get(state, arg1), _.get(state, arg2));
    case 'lessThan':
      return (path, value) => {
        if (_.isString(value)) return _.lt(_.get(state, path), _.get(state, value));
        return _.lt(_.get(state, path), value);
      }
    case 'lessThanOrEqual':
      return (path, value) => {
        if (_.isString(value)) return _.lte(_.get(state, path), _.get(state, value));
        return _.lte(_.get(state, path), value);
      }
    case 'greaterThan':
      return (path, value) => {
        if (_.isString(value)) return _.gt(_.get(state, path), _.get(state, value));
        return _.gt(_.get(state, path), value);
      }
    case 'greaterThanOrEqual':
      return (path, value) => {
        if (_.isString(value)) return _.gte(_.get(state, path), _.get(state, value));
        return _.gte(_.get(state, path), value);
      }
    case 'dateIsBefore':
      return (dateOnePath, dateTwoPath) => {
        const dateOne = _.get(state, dateOnePath);
        const dateTwo = _.get(state, dateTwoPath);

        return dayjs(dateOne).isBefore(dayjs(dateTwo));
      };
    case 'dateIsAfter':
      return (dateOnePath, dateTwoPath) => {
        const dateOne = _.get(state, dateOnePath);
        const dateTwo = _.get(state, dateTwoPath);

        return dayjs(dateOne).isAfter(dayjs(dateTwo));
      };
    case 'dateIsSame':
      return (dateOnePath, dateTwoPath) => {
        const dateOne = _.get(state, dateOnePath);
        const dateTwo = _.get(state, dateTwoPath);

        return dayjs(dateOne).isSame(dayjs(dateTwo));
      };
    case 'isNumberUnset':
      return (arg1) => {
        const value = _.get(state, arg1);
        return _.isNil(value) || isNaN(value);
      }
    case 'isDefined':
      return (path) => !_.isNil(_.get(state, path));
    case 'isUndefined':
      return (path) => _.isNil(_.get(state, path));
    case 'isTruthy':
      return (path) => Boolean(_.get(state, path));
    case 'isFalsy':
      return (path) => !(_.get(state, path));
    case 'noneOf':
      return (path, list) => !_.includes(list, _.get(state, path));
    case 'any':
      return (path, list) => _.includes(list, _.get(state, path));
    case 'anyJSON':
      return (path, list) => {
        const comparate = _.get(state, path);
        let index = 0;

        for (const [idx, path] of list.entries()) {
          const pathJSON = _.get(state, path);
          if (_.isEqual(comparate, pathJSON)) {
            index = idx + 1; // Add one, because index 0 is for the case where there is no equal
            break; // First in the list wins
          }
        }

        return index;
      };
    case 'processDictDuplicates':
      // Using this from "reduxStorageParams" requires "paramsTransformOp" to be "set"
      return (...args) => processDictDuplicates(state, args);
    case 'boolMap':
      /**
       * Will evaluate value to a boolean and map the result to a dictionary
       * provided as second argument
       */
      return (path, dict) => dict[Boolean(_.get(state, path))];
    case 'map':
      /**
       * Will map value to a dictionary
       * provided as second argument
       */
      return (path, dictOrPath) => {
        const dict = typeof dictOrPath === 'string' ? _.get(state, dictOrPath) : dictOrPath;
        return dict[_.get(state, path)];
      }
    case 'mapReplace':
      return (path, map, options) => {
        /**
         * 1. Clone the part of the object/dictionary (state) being altered
         * 2. Retrieve all the keys from the part of the obj/dict (state)
         * 3. Iterate over the keys
         *  1. Use the keys the get the value from the obj/dict (state)
         *  2. Find the replacement value from the "map"
         *  3. Replace the value in the original obj/dict with the value found in the "map"
         */
        const sourceDict = _.cloneDeep(_.get(state, path, {}));
        const targetKeyValuePairs = Object.keys(sourceDict);

        const { omitUnmatchedKeys } = options || {};
        const targetDict = omitUnmatchedKeys ? {} : sourceDict;

        targetKeyValuePairs.forEach((key) => {
          const mapKey = _.get(sourceDict, key);
          if (omitUnmatchedKeys && !map?.[mapKey]) {
            return;
          }
          const replacement = _.get(map, mapKey, mapKey);

          _.set(targetDict, key, replacement);
        });

        return targetDict;
      };
    case 'nestedMapReplace':
      return (path, map, options) => {
        const { omitUnmatchedKeys } = options || {};

        const sourceDict = _.cloneDeep(_.get(state, path, state));
        const targetDict = omitUnmatchedKeys ? {} : sourceDict;

        function recursivelyReplace(dict, path = '') {
          const dKeys = Object.keys(dict);

          dKeys.forEach((key) => {
            const keyValue = _.get(dict, key);
            const continualPath = path ? `${path}.${key}` : key;

            if (omitUnmatchedKeys && _.isUndefined(keyValue)) return;

            if (_.isObject(keyValue)) {
              recursivelyReplace(keyValue, continualPath);
            } else {
              const replacement = _.get(map, keyValue, keyValue);
              _.set(targetDict, continualPath, replacement);
            }
          });
        }

        recursivelyReplace(sourceDict);
        return targetDict;
      };
    case 'reduceDictionary':
      /**
       * Iterates through all values of a dictionary/object and invokes the given
       * dataTransform for each value. The dataTransform will be able to read the
       * current dictionary value during an iteration by referencing the `__forEachKey_currentElement` field
       * just as if it were part of the Formik state.
       *
       * Results of all iterations are combined together into a single object (i.e. each iteration result
       * is spread into the final result) allowing this operation to return a dictionary.
       */
      return (path, dataTransform) => {
        const sourceDict = _.get(state, path, {});

        return Object.keys(sourceDict).reduce((acc, key) => {
          const targetDictValue = _.cloneDeep(_.get(sourceDict, key))
          const result = {};
          dataTransformer(dataTransform, { ...state, __forEachKey_currentElement: targetDictValue }, result);
          return _.merge({}, acc, result);
        }, {});
      };
    case 'copyToRef':
      /**
       * Returns an object that has a particular value nested within a particular key path.
       * The key path and value are read from specific fields of the object located at `path`.
       */
      return (path, config) => {
        const { valueField = 'answer', refField = 'ref', allowNulls = false } = config || {};

        const sourceObject = _.get(state, path)
        const value = _.get(sourceObject, valueField);
        const ref = _.get(sourceObject, refField);


        if (!ref) return {};
        if ((value === null || value === undefined) && !allowNulls) return {};

        return _.set({}, ref, value);
      }
    case 'filterKeys':
      return (paths) => _.pick(state, paths)
    case 'evaluateDeckardExpr':
      return (expr) => {
        let expression = expr;
        let additionalEnv = {};
        if (expr?.expr) {
          expression = expr?.expr;
          if (typeof expression === "string") {
            expression = JSON.parse(he.decode(expression))
          }

          if (expr?.env) {
            additionalEnv = Object.keys(expr?.env).reduce((acc, varName) => ({
              ...acc,
              [varName]: _.get(state, expr?.env?.[varName])
            }), {});
          }
        }
        const result = evaluateDeckardExpr(expression, { ...state, ...additionalEnv })
        return _.cloneDeep(result)
      }
    default:
      return () => undefined;
  }
}

/**
 * Evaluates an array conditional expression.
 *  e.g.
 *    [key, '=', true]
 *    Returns true if values[key] === true
 *
 * @param {*} values Data store to retrieve values from
 * @param {Array} parameters
 */
export function evaluateCondition(values, parameters, noParameterDefault, log) {
  if (!parameters) return noParameterDefault ?? true; // No conditions to evaluate
  // console.log('\nevaluateCondition');

  /**
   * TODO: DECK-12896
   * This can be done via the if condition below
   * However, when we have the time to port all the old configs to be built via the TS builder
   *  then those older schemas should be updated to follow the object structure
   */
  if (Array.isArray(_.first(parameters))) {
    // console.log('Nested condition');
    // console.log('parameters:', parameters);
    return parameters.every((param) => evaluateCondition(values, param));
  }

  if (_.isPlainObject(parameters)) {
    const { not: negatedCondition } = parameters;
    if (negatedCondition) {
      return !evaluateCondition(values, negatedCondition);
    }

    const { method = 'every', conditions } = parameters;
    return conditions?.[method]((condition) => evaluateCondition(values, condition));
  }

  const [arg1, op, arg2] = parameters;
  // console.log('[arg1, op, arg2]:', [arg1, op, arg2]);
  // console.log('values:', _.cloneDeep(values));

  const opFunc = operationsGetter(op, values);
  const result = opFunc(arg1, arg2);
  // console.log('result:', result);
  return result;
}

/**
 * Generates (part of) the final payload via retrieving/evaluating values from
 *  an initial/unchanged store of data and sets "computed values" from the
 *  "operationsGetter" function in another object/dictionary
 *
 * @param {Array of Objects} config config that determines how the data is transformed
 *  See "computedExtraValuesFromStore" and "cleanupFormikValues"
 * @param {Object} data initial data store to retrieval and evaluate values from
 * @param {Object} payload data store to become the final payload (via reference)
 */
export function dataTransformer(config = [], data, payload) {
  config?.forEach((fieldConfig) => {
    const { key, op, condition, params = [], dataTransformerSubPayload } = fieldConfig;

    if (fieldConfig?.log) console.group(`dataTransformer - ${op}`);
    if (fieldConfig?.log) console.log('key:', key);
    if (fieldConfig?.log) console.log('op:', op);
    if (fieldConfig?.log) console.log('condition:', condition);
    if (fieldConfig?.log) console.log('params:', params);

    const target = dataTransformerSubPayload ? payload : data;
    if (fieldConfig?.log) console.log('dataTransformerSubPayload using', dataTransformerSubPayload ? 'payload' : 'data');
    if (fieldConfig?.log) console.log('target:', target);

    const evalExit = !evaluateCondition(target, condition);
    if (fieldConfig?.log) console.log('evalExit:', evalExit);
    if (evalExit) {
      if (fieldConfig?.log) console.groupEnd();
      return;
    }

    if (op === 'unset') {
      _.unset(payload, key);
    }
    if (op === 'mapUnset') {
      const [path, keys] = params;
      const source = _.get(payload, path);

      if (Array.isArray(source)) {
        source.forEach((item) => {
          keys.forEach((key) => {
            _.unset(item, key);
          });
        })
      } else {
        keys.forEach((key) => {
          _.unset(source, key);
        });
      }
    }
    else {
      const transformedData = operationsGetter(op, target)(...params);
      if (fieldConfig?.log) console.log('transformedData:', transformedData);
      if (key === '$') {
        _.merge(payload, transformedData);
      } else {
        _.set(payload, key, transformedData);
      }
    }

    if (fieldConfig?.log) console.groupEnd();
  });
}

/**
 * State is generated in "buildSingleStrategyFunction" of "BaseStrategies" class
 *
 * Config Information
 *  Originates from "strategies.[STRATEGY_NAME].dataTransform.computeExtraValues"
 *
 *  computeExtraValues Schema:
 *  {
 *    "key": the path to the property being read or altered
 *      type: String
 *
 *    "op": a pre-defined operation in the "operationsGetter" function to perform
 *      type: String
 *
 *    "params": an array of arguments to be used in the "op" specified
 *      type: Array
 *
 *    "condition": an array of arguments for the "evaluateCondition" function
 *      type: Array
 *  }
 */
export function computeExtraValuesFromStore(state, formikPayload, config = []) {
  const computed = _.cloneDeep(formikPayload);
  dataTransformer(config, state, computed);

  return computed;
}

/**
 * Removes all key-value pairs within the root level of the dictionary
 *  where the key starts with an "_"
 *
 *  e.g.
 *    _isOwner
 *
 * @param {Object} initData the initial state of the payload
 * @param {Object} dataStore the object where the final state of the payload should be stored
 */
function removeFormikFormMetaData(initData, dataStore) {
  const filterChar = '_';
  Object.entries(initData).forEach(([key, value]) => {
    if (key.startsWith(filterChar)) return;
    _.set(dataStore, key, value);
  });
}

/**
 * Creates the final version of the "Formik portion" of the final payload
 *
 * Config Information
 *  Originates from "strategies.[STRATEGY_NAME].dataTransform.convertFormikValues"
 *
 *  convertFormikValues Schema:
 *  {
 *    "key": the path to the property being read or altered
 *      type: String
 *
 *    "op": a pre-defined operation in the "operationsGetter" function to perform
 *      type: String
 *
 *    "params": an array of arguments to be used in the "op" specified
 *      type: Array
 *
 *    "condition": an array of arguments for the "evaluateCondition" function
 *      type: Array
 *  }
 */
export function cleanupFormikValues(formikValues = {}, config = []) {
  const initFormik = _.cloneDeep(formikValues);
  const payload = {};

  removeFormikFormMetaData(initFormik, payload);
  dataTransformer(config, initFormik, payload);

  return payload;
}

export function buildApiPostPayload(
  state,
  dataTransform,
) {
  const formikPayload = cleanupFormikValues(state?.formikSnapshot, dataTransform?.convertFormikValues);
  const computedPayload = computeExtraValuesFromStore(
    state,
    formikPayload,
    dataTransform?.computeExtraValues
  );
  return computedPayload;
}

/**
 * This is a feature just for Arroyo Grande as we have a special API for them that being
 * executed thru strategies.
 * See "IVGRecord"
 */
function templateValueGetter(params, dataStore) {
  const { templateString, templateTarget, dataTarget } = params;
  const [dataTargetPath, field, expected] = dataTarget;

  const data = _.get(dataStore, dataTargetPath);
  if (_.isArray(data)) {
    const result = data.findIndex((e) => _.get(e, field) === expected);
    return _.template(templateString)({ [templateTarget]: result });
  }

  return params;
}

export function buildConfiguredPayloadFromState(store, config, useQueryParams) {
  if (!config) return {};
  const payload = {};

  const dataStore = useQueryParams ? new URLSearchParams(store) : store;

  const retrievalFn = (valuePath) => useQueryParams ?
    dataStore.get(valuePath) :
    _.get(dataStore, valuePath);

  Object.entries(config).forEach(([keyPath, valuePath]) => {
    const isParamSet = _.isObject(valuePath);

    const path = isParamSet ? templateValueGetter(valuePath, dataStore) : valuePath;

    // Get value to be stored at the provided path from redux. E.g.: "a.b.c" will get 42
    // from the nested object { a: { b: { c: 42 } } }
    const value = retrievalFn(path);

    // set value in object based on keyPath. E.g.: A value for the key "a.b.c" will be set
    // in { a: { b: { c: <value> } } }
    _.set(payload, keyPath, value);
  });

  return payload;
}

function getCheckoutDetailsValueGetters(config = []) {
  return config.reduce((acc, curr) => {
    const { id, valueGetter } = curr;
    acc[id] = valueGetter;
    return acc;
  }, {});
}

const invokeLambda = (arg, lambda) => evaluateCalculatedFieldExpr(lambda.expr, { ...lambda.idToValue, [lambda.paramName]: arg });
const calculatedFieldOpImplementations = {
  '+': () => (...operands) => operands.reduce(((acc,x) => acc + x), 0),
  'get': () => (obj, path, defaultValue) => _.get(obj, path, defaultValue),
  'lambda': (idToValue) => (paramName, expr) => ({ idToValue, paramName: paramName.slice(1), expr }),
  'invoke': () => (lambda, arg) => invokeLambda(arg, lambda),
  'map': () => (array, mapperLambda) => array.map(el => invokeLambda(el, mapperLambda)),
  'flatMap': () => (array, mapperLambda) => array.flatMap(el => invokeLambda(el, mapperLambda)),
  'reduce': () => (array, reducerLambda, initValue) => array.reduce((acc, x) => invokeLambda(x, invokeLambda(acc, reducerLambda)), initValue),
};

function evaluateCalculatedFieldExpr(calculatedFieldExpr, idToValue) {
  const { op, operands } = calculatedFieldExpr;
  // const id = Math.round(Math.random() * 1000000);
  // const log = (...x) => console.log(id, ...x);
  // log('eval', calculatedFieldExpr)

  const opFunctionFactory = calculatedFieldOpImplementations?.[op];

  if (!opFunctionFactory) return null;

  const resolvedOperands = op === 'lambda' ? operands : operands.map(x => {
    const isLiteral = typeof x === 'number';
    const isExpr = Boolean(x?.op)

    if (isLiteral) return x;
    if (isExpr) return evaluateCalculatedFieldExpr(x, idToValue);

    if (x.startsWith("'")) {
      return x;
    }
    if (x.startsWith('"') && x.endsWith('"')) {
      return x.slice(1, x.length - 1);
    }

    return idToValue[x];
  });

  // log("resolved operands", resolvedOperands)

  const result = opFunctionFactory(idToValue)(...resolvedOperands);

  // log('result', result)
  return result;
}

// convert config into values, keyed by id. used in pay-tot-selectors
export function getCheckoutDetails(state, config = []) {
  if (!config || _.isEmpty(config)) return null;

  const getters = getCheckoutDetailsValueGetters(config);
  const checkoutDetailValues = buildConfiguredPayloadFromState(state, getters);
  const checkoutDetails = config.map((detail) => ({ ...detail, value: checkoutDetailValues[detail?.id] }));

  const checkoutDetailValuesById = checkoutDetails.reduce((acc, detail) => ({ ...acc, [detail?.id]: detail?.value }), {});

  const checkoutDetailsWithCalculatedValues = checkoutDetails.map(({ calculatedFieldExpr, ...rest }) => {
    if (calculatedFieldExpr) {
      const calculatedValue = evaluateCalculatedFieldExpr(calculatedFieldExpr, checkoutDetailValuesById);
      return { ...rest, value: calculatedValue };
    }

    return rest;
  })

  return checkoutDetailsWithCalculatedValues
    .filter(({ hidden }) => !hidden)
    .map(({ label, value, sticky }) => ({ label, value, sticky }));
}

function allOrNothingValidation(values, valItem) {
  const ERROR_MSG = 'Required.';

  let allFilled = true;
  let allEmpty = true;
  const maybeError = {};

  const fieldsToCheck = valItem.fields;
  fieldsToCheck.forEach((fieldName) => {
    const currValue = values[fieldName];
    allFilled = allFilled && Boolean(currValue);
    allEmpty = allEmpty && !currValue;
    // add error just in case not allFilled nor allEmpty
    if (!currValue) {
      maybeError[fieldName] = ERROR_MSG;
    }
  });

  if (!allFilled && !allEmpty) {
    // form must be either all filled or all empty
    return maybeError
  }

  return {}; // returning empty object because that's the default initial state of formik.errors
}

export const topLevelValidations = {
  allOrNothing: allOrNothingValidation,
};


// Functions that are related to the payload being sent to the server

function join(formikValues, formikKeys) {
  const emptyStringToJoinWith = ' ';

  const stringsToJoin = formikKeys.map((key) => {
    const value = formikValues?.[key]
    return value?.length ? value : null;
  }).filter((string) => string?.length);

  if (stringsToJoin?.length) return stringsToJoin.join(emptyStringToJoinWith);
}

function eq(formikValues, param) {
  return _.eq(formikValues?.[param?.key], param.value);
}

/**
 * Methods are retrieved from the "option" key of the "params"
 * array of dictionaries
 *
 * params Schema:
 *  key:
 *    Required - string - new key to store the information in the payload
 *  option:
 *    method
 *    param
 *  shouldStoreInRedux:
 *    Optional - boolean
 *    If this is true, then the evaluated value will be stored under the "key" key provided above
 */
const evaluationMethods = {
  /**
   * Schema:
   *  method:
   *    Required - string - "join"
   *  param:
   *    Required - array of strings - keys in the formik "values" dictionary
   *    e.g. - ["ownerFirstName", "ownerLastName"]
   */
  join,
  /**
   * Schema:
   *  method:
   *    Required - string - "get"
   *  param:
   *    Required - string - key in the formik "values" dictionary
   */
  get: _.get,
  /**
   * Schema:
   *  method:
   *    Required - string - "eq"
   *  param:
   *    Required - dictionary
   *    { "key": key in formik "values" dictionary, "value": a value to compare against }
   */
  eq,
}

/**
 * Generates the payload that the server expects based on the parameters
 * that it receives
 * Currently, only supports "join", "get", "eq"
 *    "join" - join strings retrieved from formik state with an empty space
 *    "get" - retrieves a value from formik state
 *    "eq" - compares a value in formik state with value
 *
 * @param {[{}]} param an array of dictionaries
 * @param {{}} formikValues the dictionary of formik values
 * @param {{}} reduxState state from the redux store that should be included into the payload
 * @param {*} dispatch dispatch function for Redux store
 */
export function payloadFormatter({ params, formikValues, reduxState, dispatch }) {
  const payload = payloadValuesTrimmer({ ...formikValues, ...reduxState });
  const removalChar = '_';

  Array.isArray(params) && params.forEach(({ key, option, shouldStoreInRedux }) => {
    const evalMethod = evaluationMethods?.[option?.method];

    if (evalMethod) {
      const evalValue = evalMethod(payload, option?.param);
      // Only set the value, if the evalValue has a value
      evalValue && _.set(payload, key, evalValue);
      shouldStoreInRedux && dispatch(payloadActions.updateAdditionalInfo({ [key]: evalValue }));
    }
  });

  const keysToRemove = Object.keys(payload).filter((key) => key.startsWith(removalChar));
  return _.omit(payload, keysToRemove);
}

/**
 * Use this for extracting values from server responses. If no key is defined,
 * return the entire object
 *
 * @param {*} obj
 * @param {*} key
 */
// TODO: Extract data transform out of this function (Will wait for flow diagram)
export function maybeExtractFieldValue(obj, key, dataTransform) {
  // if (dataTransform?.log) console.log('\nmaybeExtractFieldValue');

  // TODO: Should have been return value of this function. Return this function to its old form
  const targetObj = key ? _.get(obj, key) : obj;
  // if (dataTransform?.log) console.log('targetObj:', targetObj);
  // if (dataTransform?.log) console.log('dataTransform:', dataTransform);

  // TODO: Extract this into own function.. should probably run after `maybeExtractFieldValue` in a series of steps to produce the data output
  if (dataTransform) {
    const opFunc = operationsGetter(dataTransform.op, targetObj);
    const args = dataTransform.params?.map((param) => {
      // if (dataTransform?.log) console.log('\ndataTransform.params.map');
      // if (dataTransform?.log) console.log('obj:', obj);
      // if (dataTransform?.log) console.log('param:', param);
      const paramsTransformOpFunc = operationsGetter(dataTransform.paramsTransformOp, obj);
      return paramsTransformOpFunc(param);
    });

    // if (dataTransform?.log) console.log('args:', args);
    // Transforms data obj using parameters TODO: should be its own thing
    const evaluated = opFunc(...args);
    // if (dataTransform?.log) console.log('evaluated:', evaluated);

    // Will return a mapped value instead of the evaluated value, if "valueMap" is declared from the config
    const result = _.get(dataTransform.valueMap, String(evaluated), evaluated);

    // if (dataTransform?.log) console.log('result:', result);

    return result;
  }

  return targetObj;
}
