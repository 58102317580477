import React from 'react';
import styled from "styled-components";
import { renderFieldComponent } from "../index";
import { useMemo } from "react";
import { withVisibility } from "./utils/with-visibility";
import { useFormikContext } from "formik";
import get from "lodash/get";

const BLANK_REGEXP = /_+/;

const Sentence = styled.div`
`;

const BlankField = styled.div`
  display: inline-block;
`;

function renderComponent(config, key, strategyInstance) {
  return (
    <BlankField key={key}>
      {renderFieldComponent(config, strategyInstance)}
    </BlankField>
  );
}

function FillInTheBlanksComponent(props) {
  const { template, templateKey, blankInputs, strategyInstance } = props;
  const { values } = useFormikContext();

  const sentenceFragments = useMemo(() => {
    const text = template || get(values, templateKey, undefined);
    return text?.split(BLANK_REGEXP);
  }, [template, templateKey, values]);

  return (
    <Sentence>
      {sentenceFragments
        ?.map((fragment, i) => i < 1 ? fragment : [
          renderComponent(blankInputs[i-1], `subfield-${i - 1}`, strategyInstance),
          fragment
        ])}
    </Sentence>
  );
}

export const FillInTheBlanks = withVisibility(FillInTheBlanksComponent);
