import * as appSelectors from 'app/state/app/app-selectors';
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { ROOT_PATH } from 'routes/constants';

import { PortalAuthRoute } from './portal-auth-route';

export function PortalAuthMidFlowRoute(props) {
  const { path, children, component: Component } = props;

  const visitedFirstPageInFlow = useSelector(
    appSelectors.visitedFirstPageInFlow,
  );

  if (!visitedFirstPageInFlow) return <Redirect to={ROOT_PATH} />;

  return (
    <PortalAuthRoute exact={props.exact} path={path}>
      {Component ? <Component /> : children}
    </PortalAuthRoute>
  );
}
