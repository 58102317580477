import React from 'react';
import { renderFieldComponent } from '../index';
import { withVisibility } from './utils/with-visibility';

export function renderFields(fields) {
  return fields.map((config, fieldIndex) =>
    renderFieldComponent(config, `field-${fieldIndex}`, null, null));
}

function FragmentComponent({ fields }) {
  return renderFields(fields);
}

export const Fragment = withVisibility(FragmentComponent);

function ContainerComponent(props) {
  return (
    <div css={props.css}>
      <FragmentComponent fields={props.fields} />
    </div>
  );
}

export const Container = withVisibility(ContainerComponent);
