import * as appSelectors from 'app/state/app/app-selectors';
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

import { ROOT_PATH } from '../constants';

/**
 * This component should be used for all pages where the user should not enter
 * directly. Namely, in order to access a MidFlowRoute, the user must have gone in sequence
 * through the flow
 *
 * @param {*} props.children
 * @param {string} props.path
 */
export function MidFlowRoute(props) {
  const { path, children, component: Component } = props;

  const visitedFirstPageInFlow = useSelector(
    appSelectors.visitedFirstPageInFlow,
  );

  if (!visitedFirstPageInFlow) {
    return <Redirect to={ROOT_PATH} />;
  }

  return (
    <Route exact path={path}>
      {Component ? <Component /> : children}
    </Route>
  );
}
