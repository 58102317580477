import * as appSelectors from 'app/state/app/app-selectors';
import { PortalPageStepper } from 'common/stepper/portal-stepper';
import { VerticalStepper } from 'common/stepper/vertical-stepper';
import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ROOT_PATH } from 'routes/constants';
import { withFlowEntry } from 'routes/custom/entry-exit-wrappers';
import { authSender } from 'utils/auth-sender';
import { strRegistrationApiSender } from 'utils/str-registration-api-sender';

import { confPageComponents } from '../conf-page-components';
import { MidFlowAuthRoute } from './mid-flow-auth-route';
import { MidFlowRoute } from './mid-flow-route';
import { PortalAuthMidFlowRoute } from './portal-auth-mid-flow-route';
import { PortalAuthRoute } from './portal-auth-route';

const routeComponentNameMap = {
  Route,
  MidFlowRoute,
  MidFlowAuthRoute,
  PortalAuthRoute,
  PortalAuthMidFlowRoute,
};

function routeComponentFactory(routeComponentName) {
  const RouteComponent = routeComponentNameMap[routeComponentName];
  return RouteComponent || Route;
}

function Wrapper({ children }) {
  return children;
}

function withPageConfigFlowEntry(WrappedComponent) {
  return function withPageConfigWrapper(props) {
    return <WrappedComponent {...props} isFlowEntry={props.isFlowEntry} />;
  };
}

const WrapperComponent = withPageConfigFlowEntry(withFlowEntry(Wrapper));

export function ConfigurableRoutes({
  configurablePages = [],
  strategyInstance,
}) {
  const queryParams = useMemo(
    () => new URLSearchParams(window.location?.search),
    [],
  );

  const rentalscapeLoginToken = queryParams.get('operatorLoginToken');

  useEffect(() => {
    if (!rentalscapeLoginToken) return;
    authSender.setCustomJWT(rentalscapeLoginToken);
    strRegistrationApiSender.setAdminFlowMode(true);
  }, [rentalscapeLoginToken]);

  const stepPositions = useSelector(appSelectors.stepPositions);
  const useCustomerPortal = useSelector(appSelectors.useCustomerPortal);

  const pages = configurablePages.map((pageConfig) => {
    const Component = confPageComponents[pageConfig.component];
    const Stepper = useCustomerPortal ? PortalPageStepper : VerticalStepper;

    const RouteComponent = routeComponentFactory(pageConfig.routeComponent);
    const activeStep = stepPositions?.[pageConfig?.step];

    return (
      <RouteComponent exact key={pageConfig.path} path={pageConfig.path}>
        <Stepper activeStep={activeStep}>
          <WrapperComponent isFlowEntry={pageConfig.isFlowEntry}>
            <Component
              pageConfig={pageConfig}
              strategies={pageConfig.strategies}
              strategyInstance={strategyInstance} // flow's strategy instance only known by parent
            />
          </WrapperComponent>
        </Stepper>
      </RouteComponent>
    );
  });

  return (
    <Switch>
      {pages}
      <Route render={() => <Redirect to={{ pathname: ROOT_PATH }} />} />
    </Switch>
  );
}
