import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import { Popup } from 'semantic-ui-react';
import * as appSelectors from 'app/state/app/app-selectors';
import * as networkSelectors from 'app/state/network/network-selectors';
import { FieldWrapper } from 'common/common-styles';
import { getCheckoutDetails } from 'configurable-form/configurable-form-utils';
import { paymentFeeFactory } from 'utils/checkout-utils';
import { convertNumberToCurrency } from 'utils/utility-functions';
import * as ps from './payment-styles';
import { formatProperty, useLicensesInReportMap } from "../../../pages/pay-flow/report-revenue-page/utils";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import IconButton from "@material-ui/core/IconButton";

function FieldItem({ label, content, accent, popupMessage }) {
  return (
    <FieldWrapper>
      <ps.LabelWrapper>
        <ps.FieldLabel accent={accent}>{label}</ps.FieldLabel>

        {popupMessage && (
          <Popup inverted content={popupMessage} trigger={<ps.StyledIcon name='info circle' />} />
        )}
      </ps.LabelWrapper>

      <ps.FieldContent accent={accent}>{content}</ps.FieldContent>
    </FieldWrapper>
  );
}

/**
 * Sample Config Keys:
 *
 *  checkoutDetailsConfig:
 *    Required - object
 *
 *    totalLabel
 *      Optional - string
 *      The text to display for the calculated sum, defaults to "Total Amount Due"
 *
 *    totalValueKey
 *      Optional - string
 *      Value path to retrieve a number from the the "calculateLicenseFee" endpoint to be used the "total amount due" display value
 *
 *    includeDefaultProcessingFee
 *      Optional - boolean
 *      Determines whether or not to display the default processing fee (Useful for Stripe and Plaid)
 *
 *    costBreakdown
 *      Optional - array of objects
 *      A config to tell the payment summary how to render the cost breakdown
 *
 *        label
 *          Require - string
 *          The label for the cost item
 *
 *        id
 *          Require - string
 *          A unique string that describes the cost item (used in "getCheckoutDetails")
 *
 *        valueGetter
 *          Require - string
 *          The path to a value in the "network" => "paymentFees" of the Redux state
 */
export function PaymentSummary({ config = {} }) {
  const activePaymentMethodTab = useSelector(appSelectors.activePaymentMethodTab);
  const paymentFees = useSelector(networkSelectors.paymentFees);
  const isMultiPropertyTOTEnabled = useSelector(appSelectors.isMultiPropertyTOTEnabled);

  const licenseIdToSummary = useMemo(() => {
    if (!isMultiPropertyTOTEnabled) return {};

    const amountFields = ['totDue', 'latePenalties', 'accruedInterest', 'total']

    function addAmountFields(prevSummary, valuesToAdd) {
      return amountFields.reduce((acc, k) => ({ ...acc, [k]: (prevSummary?.[k] || 0) + (valuesToAdd[k] || 0)  }), {});
    }

    function addTaxTypes(prevTaxTypes, licenseReport) {
      const taxTypeSummaries = licenseReport.misc?.taxableActivities
        ?.flatMap(taxableActivity => Object.keys(taxableActivity.taxTypes).map(taxType => ({ taxType, ...taxableActivity.taxTypes[taxType] })))


      return taxTypeSummaries?.reduce((acc, taxTypeSummary) => ({
        ...acc,
        [taxTypeSummary.taxType]: addAmountFields(acc[taxTypeSummary.taxType], taxTypeSummary)
      }), prevTaxTypes || {});
    }

    // TODO: Standardize data structure between TOT and non-TOT cases
    const licenseReports = !paymentFees.paymentPeriods ? paymentFees.details : (
      paymentFees.paymentPeriods
        .flatMap(paymentPeriod => paymentPeriod.licenseReports.map(licenseReport => licenseReport))
    );

    return licenseReports.reduce((acc, licenseReport) => ({
        ...acc,
        [licenseReport.licenseId]: {
          summary: addAmountFields(acc[licenseReport.licenseId]?.summary, licenseReport),
          taxTypes: addTaxTypes(acc[licenseReport.licenseId]?.taxTypes, licenseReport) || {},
        }
      }), {});
  }, [isMultiPropertyTOTEnabled, paymentFees]);

  // Calculate the fees to be paid
  const fees = useMemo(() => (
    paymentFees ? paymentFeeFactory(activePaymentMethodTab, paymentFees) : null
  ), [activePaymentMethodTab, paymentFees]);

  const amountDue = useMemo(() => {
    if (config?.totalValueKey) {
      const unformattedFee = get(paymentFees, config?.totalValueKey);
      return convertNumberToCurrency(unformattedFee);
    }
    return fees?.total?.value;
  }, [config?.totalValueKey, fees?.total?.value, paymentFees]);

  const licenseIdToLicense = useLicensesInReportMap();

  const checkoutDetails = useMemo(() => {
    const { costBreakdown = [], propertyCostBreakdown = [], includeDefaultProcessingFee = true, includeProcessingFeeBreakdown = false } = config;
    const checkoutDetails = getCheckoutDetails(paymentFees, costBreakdown);

    const propertyCheckoutDetails = !propertyCostBreakdown.length ? [] : Object.keys(licenseIdToSummary).map(licenseId => {
      const propertyAddress = formatProperty(licenseIdToLicense[licenseId]);
      const data = { ...licenseIdToSummary[licenseId], propertyAddress }
      return {
        header: propertyAddress,
        subItems: getCheckoutDetails(data, propertyCostBreakdown)
      }
    });

    const { processingFee } = fees || {};
    const useDetailedProcessingFeeLabel = includeProcessingFeeBreakdown && processingFee?.secondaryLabel;

    const processingFeeLabel = !useDetailedProcessingFeeLabel ? processingFee?.label
      : `${processingFee?.label} (${processingFee?.secondaryLabel})`;

    return [
      /**
       * If the config requests for the non-default checkout details,
       *  then use the specified config
       * If not, then default to the one specified by "fees?.licenseFee"
       */
      ...(checkoutDetails || [fees?.licenseFee]),
      // Conditionally add the processing fee based on the config
      ...(includeDefaultProcessingFee ? [{ ...fees?.processingFee, label: processingFeeLabel }] : []),
      ...(propertyCheckoutDetails),
    ];
  }, [config, paymentFees, licenseIdToSummary, fees, licenseIdToLicense]);

  return (
    <ps.Column>
      <ps.PaymentWrapper>
        <ps.PaymentHeader data-cy='total-amount-due-title'>{config.totalLabel || 'Total Amount Due'}</ps.PaymentHeader>
        <ps.PaymentItem>{amountDue}</ps.PaymentItem>
      </ps.PaymentWrapper>

      <CheckoutDetails details={checkoutDetails} />
    </ps.Column>
  );
}

function SingleCheckoutDetail({ detail }) {
  const { label, value } = detail;
  return <FieldItem key={label} label={label} content={convertNumberToCurrency(value)} />
}

const CheckoutDetailsListContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5px 0;
`;

const CheckoutDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
// eslint-disable-next-line no-unused-vars
const CheckoutDetailsHeader = styled.div`
  font-weight: bold;
  display: flex;
  flex-direction: column;
`;

const CheckoutDetailsHeaderItemsContainer = styled.div`
  font-weight: normal;
`;

const CheckoutDetailsHeaderTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
`;

function CheckoutDetailsList({ header, items }) {

  const stickyItems = items.filter(({ sticky }) => sticky);

  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <CheckoutDetailsListContainer>
      <CheckoutDetailsHeader>
        <CheckoutDetailsHeaderTitle>
          {header}
          <IconButton
            onClick={() => setIsExpanded(!isExpanded)}
            size="small"
          >
            <ExpandMoreIcon
              style={{ transform: isExpanded ? 'rotate(180deg)' : '' }}
            />
          </IconButton>
        </CheckoutDetailsHeaderTitle>
        <CheckoutDetailsHeaderItemsContainer>
          {!isExpanded && stickyItems.map((detail, i) => <SingleCheckoutDetail key={i} detail={detail} />)}
        </CheckoutDetailsHeaderItemsContainer>

      </CheckoutDetailsHeader>
      {isExpanded && items.map((detail, i) => <SingleCheckoutDetail key={i} detail={detail} />)}
    </CheckoutDetailsListContainer>
  )

}
function CheckoutDetails({ details = [] }) {
  return (
    <CheckoutDetailsContainer>
      {
        details.map(
          (detail, i) => {
            if (detail.subItems) {
              return <CheckoutDetailsList key={i} items={detail.subItems} header={detail.header} />
            }
            if (detail?.label === null) {
              return null
            }
            return ( <SingleCheckoutDetail key={i} detail={detail} /> )
            }
      )

      }
    </CheckoutDetailsContainer>
  )
}
