import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import MenuList from '@material-ui/core/MenuList';
import Popper from '@material-ui/core/Popper';
import * as appSelectors from 'app/state/app/app-selectors';
import * as loginSelectors from 'app/state/login/login-selectors';
import { HIDE_USER_ACCOUNT_UPDATE_PAGE_PATH } from 'common/constants';
import React, { useCallback, useRef, useState } from 'react';
import { BiChevronDown } from 'react-icons/bi';
import { useSelector } from 'react-redux';

import { BannerItem } from '../modules/item';
import { UserLogout } from './actions/user-logout';
import { UserUpdateInfo } from './actions/user-update-info';
import { userContextMenuStyles, UserMenuDiv } from './styles';

function MenuOptionsContainer(props) {
  const classes = userContextMenuStyles();

  return (
    <ClickAwayListener onClickAway={props.handleClose}>
      <Grow {...props.TransitionProps}>
        <div>
          <MenuList className={classes.menuList}>{props.children}</MenuList>
        </div>
      </Grow>
    </ClickAwayListener>
  );
}

export function UserContextMenu() {
  const classes = userContextMenuStyles();

  const hideUserAccountUpdatePage = useSelector(
    appSelectors.getFromPortalStats(HIDE_USER_ACCOUNT_UPDATE_PAGE_PATH),
  );

  const isUserCognitoAuthenticated = useSelector(
    loginSelectors.isUserCognitoAuthenticated,
  );

  const userAccountDetails = useSelector(loginSelectors.userAccountDetails);

  const bannerRef = useRef();

  const [openContextMenu, setOpenContextMenu] = useState(false);

  const handleMenuClick = useCallback((event) => {
    event.stopPropagation();
    setOpenContextMenu((prev) => !prev);
  }, []);

  const handleClose = useCallback((event) => {
    if (bannerRef.current && bannerRef.current.contains(event?.target)) return;
    setOpenContextMenu(false);
  }, []);

  if (!isUserCognitoAuthenticated) return null;

  return (
    <>
      <BannerItem forwardRef={bannerRef} onClick={handleMenuClick}>
        <UserMenuDiv>
          <p>
            Logged in as: {userAccountDetails.firstName}{' '}
            {userAccountDetails.lastName}
          </p>
          <BiChevronDown onClick={handleMenuClick} />
        </UserMenuDiv>
      </BannerItem>

      <Popper
        disablePortal
        transition
        placement="bottom-end"
        className={classes.popper}
        open={openContextMenu}
        anchorEl={bannerRef.current}
      >
        {({ TransitionProps }) => (
          <MenuOptionsContainer
            handleClose={handleClose}
            TransitionProps={TransitionProps}
          >
            {!hideUserAccountUpdatePage && (
              <UserUpdateInfo handleClose={handleClose} />
            )}
            <UserLogout handleClose={handleClose} />
          </MenuOptionsContainer>
        )}
      </Popper>
    </>
  );
}
