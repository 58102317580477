import { CoreReportingFields } from "../../constants";
import { useSelector } from "react-redux";
import * as appSelectors from "../../../../../app/state/app/app-selectors";
import { useMemo } from "react";
import { getPropertyColumn } from "./property-columns";

const CORE_REPORTING_COLUMN_ORDER = [
  CoreReportingFields.TAXABLE_RECEIPTS_FIELD,
  CoreReportingFields.NUM_DAYS_AVAILABLE_FIELD,
  CoreReportingFields.NUM_DAYS_OCCUPIED_FIELD,
];
const CORE_REPORTING_COLUMNS = CORE_REPORTING_COLUMN_ORDER.map(field => ({ field, isCoreColumn: true, inputRequired: true }));

export function useAllReportingColumns({ includePropertyColumn } = {}) {
  const config = useSelector(appSelectors.renderConfig);

  const additionalReportingColumns = useMemo(
    () => config?.reporting?.taxableItemExtraParams?.columns || [],
    [config?.reporting?.taxableItemExtraParams?.columns]
  ).filter(({ enable = true }) => !!enable);

  return useMemo(() => {
    const showUnconfigurableHeaders = !config?.reporting?.taxableItemExtraParams?.usingConfigurableColumns;

    const columns = [];

    if (includePropertyColumn) {
      columns.push(getPropertyColumn())
    }

    columns.push(...additionalReportingColumns.slice(0));

    if (showUnconfigurableHeaders) {
      columns.push(...CORE_REPORTING_COLUMNS);
    }

    return columns;
  }, [additionalReportingColumns, config?.reporting?.taxableItemExtraParams?.usingConfigurableColumns, includePropertyColumn]);
}

export function customizeReportingColumns(columns, fieldToTransformationFn) {
  return columns.map((col) => {
    const transformColumn = fieldToTransformationFn[col.field];
    if (!transformColumn) return col;
    return transformColumn(col);
  });
}
