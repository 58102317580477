import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import styled from 'styled-components';
import * as appSelectors from 'app/state/app/app-selectors';
import * as cs from 'common/common-styles';
import { DEFAULT_FORM_VALUE as emptyString } from 'common/constants';
import { DEFAULT_FIND_LICENSE_MODE, findLicenseModes, FLOW_PATHS } from 'routes/constants';
import { userInputTrimmer } from 'utils/utility-functions';

const sizes = {
  sm: 'sm',
  md: 'md',
  fluid: 'fluid',
}

const sizePixels = {
  [sizes.sm]: '200px',
  [sizes.md]: '400px',
  [sizes.fluid]: '100%',
}

const StyledLink = styled(cs.Link)`
  margin-top: 20px;
`;

const StyledInput = styled(cs.Input)`
  width: ${(props) => sizePixels[props.size]};
`;

export const LOOKUP_TYPES = {
  permitLookup: findLicenseModes.LICENSE_NUMBER,
  parcelLookup: findLicenseModes.APN,
}

export function LicenseNumberInput(props) {
  const { licNumInput, setLicNumInput } = props;

  const certVerbiage = useSelector(appSelectors.certificateVerbiage);

  const renderConfig = useSelector(appSelectors.renderConfig);
  const lookupInputHeaderMsg = renderConfig?.findLicense?.licenseInputLabel;

  const history = useHistory();
  const { findLicenseMode = DEFAULT_FIND_LICENSE_MODE } = useParams();

  const onChange = useCallback((val) => {
    const trimmed = userInputTrimmer(val);
    setLicNumInput(trimmed);
  }, [setLicNumInput]);

  const handleLinkClick = useCallback(() => {
    const apnSearchRoute = `${FLOW_PATHS.payTot}/find-license/${findLicenseModes.APN}`;
    history.push(apnSearchRoute);
  }, [history]);

  const HEADER_MESSAGE_BY_LOOKUP_TYPE = useMemo(() => ({
    [LOOKUP_TYPES.permitLookup]: `Let's get started by entering your ${certVerbiage}`,
    [LOOKUP_TYPES.parcelLookup]: 'Parcel number',
  }), [certVerbiage]);

  const valuesByLookupType = useMemo(() => ({
    permitLookup: { linkHref: emptyString, linkLabel: renderConfig?.findLicense?.permitLookupLabel || 'Help me find my permit number' },
    parcelLookup: { linkHref: emptyString, linkLabel: 'Look up parcel on county website' },
  }), [renderConfig?.findLicense?.permitLookupLabel]);

  return (
    <>
      <cs.HeaderText>{lookupInputHeaderMsg || HEADER_MESSAGE_BY_LOOKUP_TYPE[findLicenseMode]}</cs.HeaderText>

      <StyledInput size="sm" value={licNumInput} onChange={onChange} maxLength={20} data-cy="license-input" />

      <StyledLink target="_blank" rel="noreferrer" onClick={handleLinkClick}>
        {valuesByLookupType.permitLookup.linkLabel}
      </StyledLink>
    </>
  );
}
