import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const styledLink = styled(Link).attrs(props => ({
  to: props.url,
}))`
  :hover {
    text-decoration: none;
  }
`;

export const styledAnchor = styled.a.attrs(props => ({
  href: props.url,
  target: props.target,
}))`
  :hover {
    text-decoration: none;
  }
`;
