import React, { useMemo } from 'react'
import { useFormikContext } from 'formik';
import get from 'lodash/get';
import styled from 'styled-components';
import { FormControl, MenuItem, Select } from '@material-ui/core';
import { useInput } from 'configurable-form/components/utils/use-input';
import { renderFieldComponent } from 'configurable-form';
import { DEFAULT_FORM_VALUE } from 'common/constants';
import { FormikError } from 'configurable-form/components/inputs/shared-components';
import { parseHTMLString } from 'utils/utility-functions';
import { withVisibility } from 'configurable-form/components/utils/with-visibility';


const Div = styled.div`
  margin-top: 20px;
`;

function RentalStructureComponent(props) {
  const {
    /**
     * There will always be components here to render.
     * In the case where no components are defined, then the TS builder will insert a singular "PageHeader"
     *  component as we don't want this unit number input field to not be able while displayed on the screen.
     */
    preFieldComponents,
    postFieldComponents = [],
    serverHasMatchedAProperty,
  } = props;

  const { values: formikValues } = useFormikContext();

  // "rentalStructure" is hardcoded purposefully
  const { field, meta } = useInput({
    name: 'rentalStructure',
    validation: serverHasMatchedAProperty ? props.validation : undefined
  });

  const preparedOptions = useMemo(() => props.options.filter(opt => !!opt.label).map(opt => {
    const labelHTML = opt.label || get(formikValues, opt.labelKey, undefined);
    return ({
      ...opt,
      labelHTML,
      label: parseHTMLString(labelHTML)
    });
  }),[formikValues, props.options]);

  if (!serverHasMatchedAProperty) return null;

  return (
    <Div>
      {preFieldComponents.map((c, i) => renderFieldComponent(c, `preUnitNumberFieldComponents${i}`))}

      <FormControl variant="outlined">
        <Select
          css="min-width: 200px"
          displayEmpty
          /**
           * Spread "field" before "value" prop or else React will complain about the component
           * switching from an uncontrolled component to a controlled component
           */
          {...field}
          value={field.value || DEFAULT_FORM_VALUE}
        >
          {props.emptyOptionLabel && <MenuItem disabled value="">{parseHTMLString(props.emptyOptionLabel)}</MenuItem>}
          {preparedOptions.map(({ id, name, label, dataCy }) => (
            <MenuItem data-cy={dataCy} key={id} value={name}>{label}</MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormikError {...meta} />

      {postFieldComponents.map((c, i) => renderFieldComponent(c, `postUnitNumberFieldComponents${i}`))}
    </Div>
  );
}

export const RentalStructure = withVisibility(RentalStructureComponent);
