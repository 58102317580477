import React, { useMemo } from 'react';
import { useField } from "formik";
import {
  getLicenseReportFormikPath,
  getLicenseReportsFormikPath,
  getPeriodDisplayName,
  getTaxableActivityFormikPath
} from "../../utils";

import { TOTTaxableActivity } from './tot-taxable-activity';
import {
  customizeReportingColumns,
  useAllReportingColumns,
} from "./use-all-reporting-columns";
import { useDaysInPeriod } from "./use-days-in-period";
import { FlexCol } from "./styles";
import { TOTPaymentRowHeader } from "./tot-payment-row-header";
import { usePropertyColumnCustomizerFactory } from "./property-columns";
import { RemovePeriodButton } from "./remove-period-button";
import styled from 'styled-components';

const ACTIONS_COLUMN_ID = '__actions';

const StyledRemovePeriodButton = styled(RemovePeriodButton)`
  margin-top: 5px;
`;

export function TOTMultipleLicensePaymentReport({ periodPath, useVerticalLayout }) {
  const [{ value: licenseReports }] = useField(getLicenseReportsFormikPath({ periodPath }));
  const [{ value: period }] = useField(periodPath);

  const daysInPeriod = useDaysInPeriod(licenseReports.flatMap(({ taxableActivities }) => taxableActivities));
  const allReportingColumns = useAllReportingColumns({ includePropertyColumn: true });

  const allColumns = useMemo(() => ([...allReportingColumns, { field: ACTIONS_COLUMN_ID, type: 'react', value: null }]), [allReportingColumns]);

  const getPropertyColumnCustomizer = usePropertyColumnCustomizerFactory();

  const allLicensesTaxableActivityPaths = useMemo(() => licenseReports
      .map((licenseReport, licenseReportIdx) => ({ licenseReport, licenseReportIdx }))
      .filter(({ licenseReport }) => licenseReport.selected)
      .flatMap(({ licenseReport, licenseReportIdx }) =>
      licenseReport.taxableActivities.map(
        (_, taxableActivityIdx) => ({
          customizedReportingColumns: customizeReportingColumns(allColumns, {
            [ACTIONS_COLUMN_ID]: col => ({ ...col, value:<StyledRemovePeriodButton licenseReportPath={getLicenseReportFormikPath({ periodPath, licenseReportIdx })} removable /> }),
            ...getPropertyColumnCustomizer(licenseReport?.licenseId)
          }),
          taxableActivityPath: getTaxableActivityFormikPath({
          periodPath, licenseReportIdx, taxableActivityIdx
        }),
        }))),
  [allColumns, getPropertyColumnCustomizer, licenseReports, periodPath]);

  return (
    <React.Fragment>
      <TOTPaymentRowHeader
        reportingColumns={allColumns}
        daysInPeriod={daysInPeriod}
      />
      <FlexCol>
        {allLicensesTaxableActivityPaths.map(({ customizedReportingColumns, taxableActivityPath }) => (
          <TOTTaxableActivity
            key={taxableActivityPath}
            taxableActivityPath={taxableActivityPath}
            daysInPeriod={daysInPeriod}
            reportingColumns={customizedReportingColumns}
            periodName={getPeriodDisplayName(period)}
            useVerticalLayout={useVerticalLayout}
          />
        ))}
      </FlexCol>
    </React.Fragment>
  )

}
