import styled, { css } from 'styled-components';
import { textBase } from 'common/common-styles';

export const PaymentInfoText = styled.p`
  ${textBase}
  font-size: 14px;
  line-height: 1.5;
`;

export const FieldLabel = styled.label`
  font-family: 'Lato';
  margin: 12px 0;
  font-weight: 500;
  font-size: ${(props) => props.fontSize || '13px'};
  color: rgba(26, 26, 26, 0.7);
`;

export const formFieldBase = css`
  width: 100%;
  padding: 8px 12px;
  box-shadow: 0 0 0 1px #e0e0e0, 0 2px 4px 0 rgba(0, 0, 0, 0.07), 0 1px 1.5px 0 rgba(0, 0, 0, 0.05);
`;
