import * as appSelectors from 'app/state/app/app-selectors';
import { appActions } from 'app/state/app/app-slice';
import { payloadActions } from 'app/state/payload/payload-slice';
import * as cs from 'common/common-styles';
import { LicenseLookup } from 'pages/flow-entry/license-lookup';
import { PropertySearch } from 'pages/flow-entry/property-search/property-search';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { findLicenseParams } from 'routes/constants';
import { ConfigurableRoutes } from 'routes/custom/configurable-routes';
import { withFlowEntry } from 'routes/custom/entry-exit-wrappers';
import { CustomRouteStrategy } from 'strategies/custom-route-strategy';

const PropertySearchHOC = withFlowEntry(PropertySearch);
const LicenseLookupHOC = withFlowEntry(LicenseLookup);

const Div = (props) => <div>{props.children}</div>;

export function CustomFlowRoutes(props) {
  const { flowId } = props;

  const dispatch = useDispatch();

  const useCustomerPortal = useSelector(appSelectors.useCustomerPortal);

  const flows = useSelector(appSelectors.stats)?.alternateFlows;
  const activeFlowId = useSelector(appSelectors.activeFlow);

  const currentFlow = flows?.[flowId];

  useEffect(() => {
    dispatch(appActions.setActiveFlow(flowId));

    if (currentFlow?.earlyInit) {
      dispatch(
        payloadActions.updateFormikSnapshot(currentFlow?.initialFormikValues),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  // Must wait for the active flow to be set so all selectors specific to this flow work
  if (!activeFlowId || !currentFlow) return null;

  const skipInitOnSpecialPages = Boolean(currentFlow?.earlyInit);
  const View = useCustomerPortal ? Div : cs.ViewContainer;

  return (
    <View>
      <Switch>
        <Route exact path={currentFlow.rootPath}>
          <Redirect
            to={`${currentFlow.rootPath}/${currentFlow.entrySubPath}${window.location.search}`}
          />
        </Route>

        <Route exact path={`${currentFlow.rootPath}/property`}>
          <PropertySearchHOC
            reset={props.resetFlow}
            init={!skipInitOnSpecialPages && currentFlow?.initialFormikValues}
            exitWithoutReset={props.exitWithoutReset}
          />
        </Route>

        <Route
          exact
          path={`${currentFlow.rootPath}/find-license/${findLicenseParams.findLicenseMode}`}
        >
          <LicenseLookupHOC
            reset={props.resetFlow}
            init={!skipInitOnSpecialPages && currentFlow?.initialFormikValues}
          />
        </Route>

        <ConfigurableRoutes
          configurablePages={currentFlow.configurablePages}
          strategyInstance={CustomRouteStrategy.getInstance()}
        />
      </Switch>
    </View>
  );
}
