import chunk from 'lodash/chunk';
import get from 'lodash/get';
import { BaseStrategy } from './base-strategy';
import { authSender } from 'utils/auth-sender';
import { captureExceptionWithContext } from 'utils/sentry-functions';

class BulkRenewLicensesAPIError extends Error {
  constructor(message) {
    super(message);
    this.name = 'BulkRenewLicensesAPIError';
  }
}

export class BulkRenewalStrategy extends BaseStrategy {
  static instance = null;

  static getInstance() {
    if (BulkRenewalStrategy.instance) return BulkRenewalStrategy.instance;

    BulkRenewalStrategy.instance = new BulkRenewalStrategy();
    return BulkRenewalStrategy.instance;
  }

  // TODO: Clean up
  bulkRenewalLicenses = async ({ params, state }) => {
    // const { dataTransform, path, pathTemplate, isAuth } = params;

    const bulkLicensesToBeRenewed = get(state, 'dashboard.bulkLicensesToBeRenewed');

    const userId = get(state, 'userAccountDetails.userId');
    const commonLicenseInfo = get(state, 'formikSnapshot');
    const chunkedBulkLicensesToBeRenewed = chunk(bulkLicensesToBeRenewed, 5);

    try {
      for (const chunk of chunkedBulkLicensesToBeRenewed) {
        await authSender.post('/bulkRenewLicenses', { userId, commonLicenseInfo, renewalRequests: chunk });
      }
    } catch (err) {
      const licensesList = (bulkLicensesToBeRenewed || []).map((license) => {
        return license?.license ?? license?.applicationNumber ?? '';
      });

      captureExceptionWithContext(new BulkRenewLicensesAPIError(err), {
        licenses: licensesList
      });

      throw err;
    }
  }
}
