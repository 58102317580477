import React from 'react';

import { PermitDataCell, PermitGridRow, SDiv } from '../styles';

export function PermitsTableHeader(props) {
  return (
    <SDiv>
      <PermitGridRow layout={props.layout}>
        {props.layout.map(({ key, label }) => (
          <PermitDataCell key={key}>{label}</PermitDataCell>
        ))}
      </PermitGridRow>
    </SDiv>
  );
}
