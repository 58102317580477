import React from 'react'
import * as cs from './common-styles';

const emailSeparator = ', ';

export function EmailList(props) {
  const { emails, color } = props;

  if (Array.isArray(emails)) {
    return (
      <>
        {emails.map((email, idx) => {
          return (
            <span key={email}>
              <cs.Link color={color} href={`mailto:${email}`}>{email}</cs.Link>
              {idx !== emails.length - 1 ? emailSeparator : null}
            </span>
          )
        })}
      </>
    );
  }

  return (<cs.Link color={color} href={`mailto:${emails}`}>{emails}</cs.Link>);
}
