import Checkbox from '@material-ui/core/Checkbox';
import { WhiteButton } from 'common/navigation-buttons';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import titleize from 'titleize';
import { convertNumberToCurrency } from 'utils/utility-functions';

import { TableCell } from '../styles';

const Button = styled(WhiteButton)`
  &&& {
    background: white;
  }
`;

function DataTableCellData(props) {
  const cellValue = useMemo(() => {
    const fn = props?.titleCased ? titleize : (e) => e;
    if (!props?.type) return fn(props.dataValue);
    if (props.type === 'currency')
      return convertNumberToCurrency(props.dataValue);
  }, [props.dataValue, props?.titleCased, props.type]);

  if (props.renderCheckbox) {
    return (
      <Checkbox
        color="primary"
        onClick={props.onCheckboxClick}
        checked={props.checked}
      />
    );
  }

  if (props.renderRowActionButton) {
    return <Button onClick={props.onRowActionClick}>View</Button>;
  }

  return cellValue;
}

export function DataTableCell(props) {
  return (
    <TableCell variant={props.variant}>
      <DataTableCellData {...props} />
    </TableCell>
  );
}
