import { ReverseArgumentInput } from 'configurable-form/components/inputs/shared-components';
import { withVisibility } from 'configurable-form/components/utils/with-visibility';
import React, { useCallback, useState } from 'react';
import { InputGroup } from 'rsuite';
import styled from 'styled-components';
import { usePropertyLookup } from '../hooks/usePropertyLookup';
import ParcelLookup from './parcel-lookup';

const ConstrainedInputGroup = styled(InputGroup)`
  width: 400px !important; // Matches the width of "ReverseArgumentInput"
`;

const Label = styled.div`
  font-size: 18px;
`;

function ParcelNumberInputFieldComponent(props) {
  const { selectedAddressAPN, setSelectedAddressAPN } = props;

  const [serverErrorMessage, setServerErrorMessage] = useState(undefined);

  const { clearStoredStates } = usePropertyLookup();

  const onChange = useCallback((e) => {
    if (serverErrorMessage) setServerErrorMessage(undefined);

    clearStoredStates();

    const parcelNumber = String(e.target.value).replaceAll(/[\W_]+/g, '');
    setSelectedAddressAPN(parcelNumber);
  }, [clearStoredStates, serverErrorMessage, setSelectedAddressAPN]);

  return (
    <div>
      <Label>{props.label}</Label>

      <ConstrainedInputGroup inside>
        <ReverseArgumentInput value={selectedAddressAPN} onChange={onChange} autoComplete="off" />
      </ConstrainedInputGroup>

      <ParcelLookup />
    </div>
  )
}

export const ParcelNumberInputField = withVisibility(ParcelNumberInputFieldComponent);
