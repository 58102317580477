/* eslint-disable */
import {Container as SemanticContainer, Grid, Segment} from "semantic-ui-react";
import {NoticeBox} from "./modules/notice-box";
import React, { useEffect } from "react";
import styled from "styled-components";

const NoticeTitle = styled.h2`
 margin-bottom: 20px;
`

const Container = styled(SemanticContainer)`
  margin-top: 10rem;
`;

const Column = styled(Grid.Column)`
  margin: auto;
`;


export function StaticRedirect({ destUrl }) {
  let noticeTxt = `Registrations for the requested area are not longer processed by this site.  You will be redirected to the <a href=${destUrl}>registration site</a> in a moment.`;

  useEffect(() => { setTimeout(() => { window.location.replace(destUrl) }, 5000) }, [])

  return (
    <Container>
      <Grid stackable>
        <Column width={13}>
          <Segment basic>
            <NoticeTitle>
              Notice
            </NoticeTitle>
            <NoticeBox text={noticeTxt} />
            <img style={{marginTop: "50px"}} src="/assets/error-page.svg" alt="Multiple homes together" />
          </Segment>
        </Column>
      </Grid>
    </Container>
  )
}
