import { Container } from 'rsuite';
import * as appSelectors from 'app/state/app/app-selectors';
import { appActions } from 'app/state/app/app-slice';
import { filesActions } from 'app/state/files/files-slice';
import * as payloadSelectors from 'app/state/payload/payload-selectors';
import { payloadActions } from 'app/state/payload/payload-slice';
import { ONE as ACH_PAYMENT_TAB } from 'common/constants';
import { renderFieldComponent } from 'configurable-form';
import { useFormikContext } from 'formik';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { ExistingLicenses } from 'pages/flow-entry/property-search/modules/existing-licenses';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { history } from 'routes/history';
import { ApplyLicenseStrategy } from 'strategies/apply-license-strategy';
import { filesManager } from 'utils/files-manager';
import { captureExceptionWithContext } from 'utils/sentry-functions';
import { usePreselectedPropertyChanged } from "utils/use-preselected-property-changed";
import { RadioGroup } from '../radio-group';
import { usePropertyLookup } from './hooks/usePropertyLookup';
import { AddressToAPNSearch } from './modules/address-to-apn-search';
import { BasicError } from './modules/basic-error';
import { ExistingLicensesNavigation } from './modules/existing-licenses-navigation';
import { FoundProperty } from './modules/found-property';
import { ParcelNumberInputField } from './modules/parcel-number-input-field';
import { PropertyLookupNavigation } from './modules/property-lookup-navigation';
import { RentalStructure } from './modules/rental-structure';
import { formatErrorMessage } from './modules/shared';
import { UnitNumber } from './modules/unit-number';
import { HTMLRenderer } from '../html-renderer';
import styled from 'styled-components';

const PROPERTY_LOOKUP_STEP = 'PropertyLookup';
const EXISTING_LICENSES_STEP = 'ExistingLicenses';

const AddressLookupErrorWrapper = styled(Container)`
  font-family: Lato;
  font-size: 14px;
  line-height: 1.5;
  color: #5c5d5d;
  margin-top: 16px;
`;

export function PropertyLookup(props) {
  const {
    skipMultipleLicensesNotice = false,
    allowMultipleLicenses = true,
    additionalErrorMessageText,
    usePreselectedProperty = false,
  } = props;

  const dispatch = useDispatch();

  const flow = useSelector(appSelectors.flows);
  const activeFlow = useSelector(appSelectors.activeFlow);

  const storedPropertyDetails = useSelector(payloadSelectors.additionalInfo);

  const addressLookupError = useSelector(payloadSelectors.addressLookupError);

  /**
   * Internal state for tracking which view of the Property Lookup page the user is in.
   * The two pages are:
   *  1. the page where the user is looking up a property via the APN, property address, or another identifier
   *  2. the page where the user sees all the existing licenses for the particular property to be registered
   */
  const [step, setStep] = useState(PROPERTY_LOOKUP_STEP);

  const [waitlistQueryInFlight, setWaitlistQueryInFlight] = useState(false);
  const [waitlistErrorMessage, setWaitlistErrorMessage] = useState(undefined);

  const [selectedAddressAPN, setSelectedAddressAPN] = useState(storedPropertyDetails.parcelNumber ?? undefined);

  const { values: formikValues, dirty, isValidating } = useFormikContext();

  const {
    licenses,
    clearStoredStates,
    queryForMatchingProperty,
    waitlistInfoFromSearchParams,
  } = usePropertyLookup({ ...props });

  const serverHasMatchedAProperty = useMemo(() => !isEmpty(storedPropertyDetails), [storedPropertyDetails]);

  const displayExistingLicenses = useMemo(() => {
    const jurisdictionMultipleLicenses = allowMultipleLicenses && Boolean(licenses.length);

    return (
      step === EXISTING_LICENSES_STEP &&
      !skipMultipleLicensesNotice &&
      jurisdictionMultipleLicenses
    );
  }, [allowMultipleLicenses, licenses.length, step, skipMultipleLicensesNotice]);

  const executeStrategy = useCallback((entryStrategy) => {
    if (!entryStrategy) throw new Error('No strategy defined for executeStrategy');
    ApplyLicenseStrategy.getInstance().executeStrategy(props.strategies, entryStrategy);
  }, [props.strategies]);

  /**
   * This useEffect hook handles waitlist related logics.
   * This is used on Ouray.
   */
  useEffect(() => {
    (async() => {
      const waitlistInfo = waitlistInfoFromSearchParams();
      if (!waitlistInfo?.token || !waitlistInfo?.apn) return;

      try {
        setWaitlistQueryInFlight(true);
        await queryForMatchingProperty({ apn: waitlistInfo.apn, waitlistToken: waitlistInfo.token });
        // Replace so that when the user hits the back button they don't get automatically redirected into the flow again
        history.replace(history.location.pathname);
        executeStrategy('onContinueClick');
      } catch(err) {
        captureExceptionWithContext(err, { apn: waitlistInfo.apn, waitlistToken: waitlistInfo.token });

        const waitlistError = formatErrorMessage(err?.response?.data?.Message, additionalErrorMessageText);

        if (waitlistError) setWaitlistErrorMessage(waitlistError);
      } finally {
        setWaitlistQueryInFlight(false);
      }
    })()
  }, [executeStrategy, additionalErrorMessageText, queryForMatchingProperty, waitlistInfoFromSearchParams]);

  /**
   * This useEffect hook handles some pre-flow clean up actions.
   * However, it does nothing when the user enters this view and is in an admin flow.
   */
  useEffect(() => {
    if (usePreselectedProperty) return;
    clearStoredStates();
    filesManager.clearProofs();

    const initialFormikState = get(flow, `${activeFlow}.initialFormikValues`, {});

    batch(() => {
      dispatch(filesActions.reset());
      dispatch(appActions.changePaymentMethodTab({ newTab: ACH_PAYMENT_TAB }));

      // This might need to only happen when the retrieved state is not empty
      dispatch(payloadActions.setFormikSnapshot({ values: initialFormikState }));
    });
  // We only want the resets to trigger when the user goes the to "Property Lookup" view
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Only clear the error message when the user interacts with any Formik connected components
    if (dirty && isValidating) {
      setWaitlistErrorMessage(undefined);
      history.replace(history.location.pathname);
    }
  }, [dirty, isValidating]);

  const isPreselectedPropertyChanged = usePreselectedPropertyChanged({
    parcelNumber: formikValues?.[props.parcelSearchProps?.name],
  });

  return (
    <div>
      {displayExistingLicenses ? (
        <>
          <ExistingLicenses
            disableDefaultNavigation={true}
            address={formikValues?.[props.propertySearchProps.name]}
            licenses={licenses}
          />

          <ExistingLicensesNavigation
            onBackClick={() => setStep(PROPERTY_LOOKUP_STEP)}
            onContinueClick={() => executeStrategy('onContinueClick')}
          />
        </>
      ) : (
        <div>
          {/* Render standard page texts */}
          {props.headerFields.map((c, i) => renderFieldComponent(c, `propertyLookupStandardTexts${i}`))}

          {/* These components will only render when an APN has been matched with a property valid for registration */}
          {serverHasMatchedAProperty && (
            <>
              {props?.preSearchFieldComponents?.map((c, i) => renderFieldComponent(
                c, `preSearchFieldComponents${i}`
              ))}
            </>
          )}

          {/*
            Render standard fields:
            1. Radio to select what property identifying information to search via
            AND ONE OF:
            2-1. Identification number input field (APN, Parcel number, Geo-ID, Property ID, etc)
            2-2. Address input field
          */}
          <RadioGroup {...props.searchMethodRadioProps} />

          <ParcelNumberInputField
            {...props.parcelSearchProps}
            selectedAddressAPN={selectedAddressAPN}
            setSelectedAddressAPN={setSelectedAddressAPN}
          />

          <AddressToAPNSearch
            {...props.propertySearchProps}
            selectedAddressAPN={selectedAddressAPN}
            setSelectedAddressAPN={setSelectedAddressAPN}
          />

          {/** For foreclosures (and possibly other systems), this component may need to be conditionally rendered after the "rentalStructure" field. */}
          {!props.unitNumberProps.afterRentalStructure && (
            <UnitNumber {...props?.unitNumberProps} searchMethodRadioProps={props.searchMethodRadioProps} />
          )}

          {selectedAddressAPN && addressLookupError &&
            <AddressLookupErrorWrapper>
              <HTMLRenderer htmlString={addressLookupError} />
            </AddressLookupErrorWrapper>}

          <FoundProperty {...props.foundPropertyProps} storedPropertyDetails={storedPropertyDetails} />

          <RentalStructure {...props?.rentalStructureProps} serverHasMatchedAProperty={serverHasMatchedAProperty} />

          {/** This component si needed to be conditionally shown after the rentalStructure (foreclosures) */}
          {serverHasMatchedAProperty && props.unitNumberProps.afterRentalStructure && <UnitNumber
            {...props?.unitNumberProps}
            searchMethodRadioProps={props.searchMethodRadioProps}
            avoidClearStore
          />}

          {/* These components will only render when an APN has been matched with a property valid for registration */}
          {serverHasMatchedAProperty && (
            <>
              {props?.postSearchFieldComponents?.map((c, i) => renderFieldComponent(
                c, `postSearchFieldComponents${i}`
              ))}
            </>
          )}

          {/* Error message for waitlist searching */}
          <BasicError message={waitlistErrorMessage} margin={0} />

          <PropertyLookupNavigation
            {...storedPropertyDetails}
            selectedAddressAPN={selectedAddressAPN}
            serverHasMatchedAProperty={serverHasMatchedAProperty}
            executeStrategy={executeStrategy}
            setStep={() => setStep(EXISTING_LICENSES_STEP)}
            additionalErrorMessageText={additionalErrorMessageText}
            allowMultipleLicenses={allowMultipleLicenses}
            skipMultipleLicensesNotice={skipMultipleLicensesNotice}
            continueCondition={props?.continueCondition}
            loading={waitlistQueryInFlight}
            skipValidationOnContinue={usePreselectedProperty && !isPreselectedPropertyChanged}
          />
        </div>
      )}
    </div>
  );
}
