import { WhiteButton } from 'common/navigation-buttons';
import styled, { css } from 'styled-components';

// ------------------------------------------------------
// Styles for "PermitsScrollingList"
// ------------------------------------------------------

export const PermitsScroller = styled.div`
  margin-top: 20px;
  height: calc(100vh - 500px);
  overflow-y: scroll;
`;

export const RDiv = styled.div`
  position: relative;
  width: '100%';
  height: ${(props) => `${props.height}px`};
`;

const shared = css`
  top: 0;
  left: 0;
  width: '100%';
`;

export const ADiv = styled.div`
  ${shared}
  position: 'absolute';
  transform: ${(props) => `translateY(${props.start}px)`};
`;

export const SDiv = styled.div`
  ${shared}
  background-color: white;
  position: sticky;
  z-index: 1;
`;

export const PermitGridRow = styled.div`
  height: 50px;
  padding: 0 15px;

  display: grid;
  grid-auto-flow: column;

  /* If no widths are provided in the columns config, then this will auto-size all columns to take up the space evenly */
  grid-auto-columns: 1fr;
  grid-template-columns: ${(props) =>
    props.layout.map(({ gridWidth }) => gridWidth).join(' ')};

  gap: 10px;
`;

export const PermitDataCell = styled.div`
  margin: auto 0;
`;

// ------------------------------------------------------
// Styles for "ListItem"
// ------------------------------------------------------

export const PermitViewButton = styled(WhiteButton)`
  &&& {
    background: white;
    display: 'block'
  }
`;

export const ListItemDiv = styled.div`
  background-color: ${(props) =>
    props.index % 2 ? 'white' : 'rgb(240, 240, 240)'};
`;
