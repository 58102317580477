import { useSelector } from "react-redux";
import * as appSelectors from 'app/state/app/app-selectors';
import { isBoolean, isUndefined } from "lodash";

/**
 Support both standard and express registration systems. 
 The standard registration system is JSON-based, allowing 
 the direct use of boolean values. In contrast, the 
 express registration system is Jinja-based and only supports 
 string values, requiring the conversion of strings to boolean 
 values.
 */
function parseBoolean(value) {
    if (isBoolean(value)) {
        return value;
    }

    return value.toLowerCase() === 'true';
}

export function useIsCCActive() {
    const activeFlow = useSelector(appSelectors.activeFlow);
    const flows = useSelector(appSelectors.flows);
    // If isCCActive is not defined, then it is assumed CC is active
    const isCCActive = !isUndefined(flows[activeFlow]?.isCCActive) ? parseBoolean(flows[activeFlow]?.isCCActive) : true;

    return isCCActive;
}

export function useIsACHActive() {
    const activeFlow = useSelector(appSelectors.activeFlow);
    const flows = useSelector(appSelectors.flows);
    // If isCCActive is not defined, then it is ACH is active
    const isACHActive = !isUndefined(flows[activeFlow]?.isACHActive) ? parseBoolean(flows[activeFlow]?.isACHActive) : true;

    return isACHActive;
}