import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import styled from 'styled-components';
import * as appSelectors from 'app/state/app/app-selectors';
import { InputTextFieldLabel } from 'common/common-styles';
import {
  AGGREGATION_NULL_FN,
  AGGREGATION_SUM_FN,
  FORMAT_CURRENCY,
  DEFAULT_TOTAL_FIELD_AGGREGATION_FUNCTIONS,
} from '../../constants';
import { addVectors, formatValue, getTaxableActivitiesPathFromLicenseReportPath } from '../../utils';
import { Grid } from './styles';

const StyledGrid = styled(Grid)`
  border-top: 2px solid black;
  padding-top: 15px;
`;

const LeftAlignedSumValue = styled(InputTextFieldLabel)`
  text-align: left;
  padding-left: ${({ extraPadding }) => extraPadding ? '29px' : '12px'};
`;

const fnMap = { SUM: AGGREGATION_SUM_FN, NULL: AGGREGATION_NULL_FN };

export function PaymentTotals({ reportingColumns = [], licenseReportPath }) {
  const fieldFormats = {
    taxableReceipts: FORMAT_CURRENCY,
    totalRevenue: FORMAT_CURRENCY,
    allowableDeductions: FORMAT_CURRENCY,
  };

  const { values } = useFormikContext();

  const config = useSelector(appSelectors.renderConfig);

  const pathKeys = reportingColumns.map(({ field }) => field);

  const fieldKeyToAggregationFn = useMemo(() => {
    if (isEmpty(config?.reporting?.taxableItemExtraParams?.paymentTotalFields)) {
      return DEFAULT_TOTAL_FIELD_AGGREGATION_FUNCTIONS;
    }

    return config?.reporting?.taxableItemExtraParams?.paymentTotalFields.reduce((acc, { func, key }) => ({
      ...acc,
      [key]: fnMap[func] || AGGREGATION_NULL_FN,
    }), {});
  }, [config?.reporting?.taxableItemExtraParams?.paymentTotalFields]);


  const paymentTotals = useMemo(() => {
    const taxableActivities = get(values, getTaxableActivitiesPathFromLicenseReportPath({ licenseReportPath })) || [];

    const valuesToAggregatePerTaxableItem = taxableActivities.map(
      (taxableActivity) => pathKeys.map((field) => taxableActivity[field]));

    const allAggregationFunctions = pathKeys.map(key => fieldKeyToAggregationFn[key] || AGGREGATION_NULL_FN);

    return valuesToAggregatePerTaxableItem.reduce(
      (partialTotal, nextItemValues) => addVectors(partialTotal, nextItemValues, allAggregationFunctions),
      pathKeys.map(() => 0)
    );
  }, [fieldKeyToAggregationFn, pathKeys, licenseReportPath, values]);

  const TOTAL_FIELDS_TO_SKIP = 1;
  const totalsToDisplay = paymentTotals.slice(TOTAL_FIELDS_TO_SKIP);
  const fieldsWithDisplayedTotals = pathKeys.slice(TOTAL_FIELDS_TO_SKIP);

  return (
    <StyledGrid>
      <InputTextFieldLabel>Total</InputTextFieldLabel>

      {totalsToDisplay.map((total, idx) => {
        const format = fieldFormats?.[fieldsWithDisplayedTotals[idx]];
        return (

          <LeftAlignedSumValue extraPadding={format === FORMAT_CURRENCY} key={idx}>{formatValue(total, format)}</LeftAlignedSumValue>
        )
      })}
    </StyledGrid>
  );
}
