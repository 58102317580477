import { usePaymentUIState } from "../../../use-payment-ui-state";
import React, { useCallback } from "react";
import { Alert } from "rsuite";
import { CANCELLED_ALERT_MSG, DEFAULT_ALERT_MSG } from "../../../constants";
import { ZERO as RSUITE_ALERT_DO_NOT_CLOSE } from "common/constants";
import { STRIPE_NO_PAYMENT_METHOD_ATTACHED_INTENT_STATUSES, SUCCESSFUL_STRIPE_ACH_PAYMENT_INTENT_STATUSES } from "../constants";
import { useSelector } from "react-redux";
import * as appSelectors from "app/state/app/app-selectors";
import { capitalize } from "lodash";
import * as cs from "common/common-styles";
import { Link } from "common/common-styles";
import { PaymentButton } from "../../../modules/payment-button/payment-button";
import styled from "styled-components";
import { PaymentConfirmationForm, StyledPaymentInfoText } from "./stripe-ach-styles";
import { dayjs } from "utils/dayjs";


const MandateBankDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: stretch;
`;

export function StripeACHPaymentConfirmationForm(props) {
  const {
    setBankAccountDetails,
    setClientSecret,
    clientSecret,
    bankAccountDetails,
    stripe,
    onSuccess,
  } = props;

  const {
    runWithUILockAndExceptionHandling,
    shouldLockPayments,
    label,
  } = usePaymentUIState(props);

  const processPayment = useCallback(() => runWithUILockAndExceptionHandling(async () => {
    if (!stripe) return;

    const { paymentIntent, error } = await stripe.confirmUsBankAccountPayment(clientSecret);

    if (error) {
      Alert.error(DEFAULT_ALERT_MSG, RSUITE_ALERT_DO_NOT_CLOSE);
      throw new Error(error.code);

    } else if (STRIPE_NO_PAYMENT_METHOD_ATTACHED_INTENT_STATUSES.includes(paymentIntent.status)) {
      Alert.error(CANCELLED_ALERT_MSG, RSUITE_ALERT_DO_NOT_CLOSE);

    } else if (SUCCESSFUL_STRIPE_ACH_PAYMENT_INTENT_STATUSES.includes(paymentIntent.status)) {
      await onSuccess();

    } else {
      Alert.error(DEFAULT_ALERT_MSG, RSUITE_ALERT_DO_NOT_CLOSE);
    }

  }), [runWithUILockAndExceptionHandling, stripe, clientSecret, onSuccess])

  const resetPaymentIntent = useCallback(() => {
    setClientSecret();
    setBankAccountDetails({})
  }, [setBankAccountDetails, setClientSecret]);

  const cityInfo = useSelector(appSelectors.cityInfo);
  const { name: billingEntityName } = cityInfo || {};

  const bankAccountDetailsToDisplay = [
    { label: 'Date', value: dayjs().format("MMMM DD, YYYY") },
    { label: 'Account Holder\'s Name', value: bankAccountDetails.accountHolder },
    { label: 'Bank Name', value: bankAccountDetails.bankName },
    { label: 'Routing Number', value: bankAccountDetails.routingNumber },
    { label: 'Account Number', value: `****${bankAccountDetails.last4}` },
    { label: 'Account Type', value: capitalize(bankAccountDetails.accountType) },
  ]
  return (
    <PaymentConfirmationForm>
      <div>
        <StyledPaymentInfoText>
          Please confirm your bank account details below.
        </StyledPaymentInfoText>

        <Link as="p" onClick={resetPaymentIntent}>
          Use a different account instead
        </Link>
      </div>

      <MandateBankDetails>
        {bankAccountDetailsToDisplay.map(({ label, value }) => (
          <div key={label}>
            <cs.InputTextFieldLabel>{label}</cs.InputTextFieldLabel>
            <p>{value}</p>
          </div>
        ))}
      </MandateBankDetails>

      <p>
        {`By clicking "Pay", you authorize ${billingEntityName} to debit the bank account specified above
            for any amount owed for charges arising from your registration, renewal and/or transient occupancy tax
            pursuant to applicable ordinances and by-laws of ${billingEntityName}
            until this authorization is revoked.
            You may amend or cancel this authorization at any time by providing
            ${billingEntityName} with a 30 (thirty) days notice.
            `}
      </p>
      <PaymentButton
        label={label}
        onClick={processPayment}
        disabled={shouldLockPayments}
      />
    </PaymentConfirmationForm>
  );
}
