import * as appSelectors from 'app/state/app/app-selectors';
import { appActions } from 'app/state/app/app-slice';
// import {
//   DASHBOARD_FAILED_STATUS,
//   DASHBOARD_INFLIGHT_STATUS,
//   DASHBOARD_SUCCESS_STATUS,
// } from 'app/state/dashboard/constants';
import { dashboardActions } from 'app/state/dashboard/dashboard-slice';
import * as loginSelectors from 'app/state/login/login-selectors';
import { networkActions } from 'app/state/network/network-slice';
import { payloadActions } from 'app/state/payload/payload-slice';
import {
  DASHBOARD_NOTICE_PATH,
  DISABLE_BULK_RENEWAL_FLOW_PATH,
  DISABLE_BULK_TAX_FLOW_PATH,
  DISABLE_SINGLE_RENEW_FLOW_PATH,
  HIDE_DASHBOARD_PERMITS_DISPLAY_PATH,
} from 'common/constants';
import concat from 'lodash/concat';
import { NoticeBox } from 'pages/modules/notice-box';
import React, { useCallback, useEffect } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
// import { Alert } from 'rsuite';
import styled from 'styled-components';
import { authSender } from 'utils/auth-sender';
import { captureExceptionWithContext } from 'utils/sentry-functions';

import {
  DashboardRenewableLicensesAPIError,
  DashboardRetrieveUserLicensesError,
  DashboardTaxableLicensePeriodsAPIError,
  // DashboardUserReportingHistoryAPIError,
} from '../../errors';
import {
  camelCaseDictKeys,
  parseRawUserLicensesDataAsActiveLicenses,
} from '../../helper';
import { ActionableItems } from './modules/actionable-items/actionable-items';
import { TruncatedPermitsList } from './modules/truncated-permits-list';

// import { UserReportingHistory } from './modules/user-reporting-history/user-reporting-history';

const Flex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 25px;
  height: 400px;
`;

export function Dashboard() {
  const dispatch = useDispatch();

  const dashboardNotice = useSelector(
    appSelectors.getFromPortalStats(DASHBOARD_NOTICE_PATH),
  );

  const hideDashboardPermitsDisplay = useSelector(
    appSelectors.getFromPortalStats(HIDE_DASHBOARD_PERMITS_DISPLAY_PATH),
  );

  const disableBulkRenewalFlow = useSelector(
    appSelectors.getFromPortalStats(DISABLE_BULK_RENEWAL_FLOW_PATH),
  );

  const disableSingleRenewalFlow = useSelector(
    appSelectors.getFromPortalStats(DISABLE_SINGLE_RENEW_FLOW_PATH),
  );

  const disableBulkTaxFlow = useSelector(
    appSelectors.getFromPortalStats(DISABLE_BULK_TAX_FLOW_PATH),
  );

  const userAccountDetails = useSelector(loginSelectors.userAccountDetails);

  const fetchPermitsForUser = useCallback(async () => {
    let [contToken, userPermits] = [undefined, []];
    dispatch(dashboardActions.setUserPermitsList({}));

    try {
      do {
        /**
         * Here we fetch the permits that are linked to the user's account in
         * chunks until there are no more permits to be retrieved.
         */
        const userLicensesEndpoint =
          '/userLicenses' +
          (contToken
            ? `?${new URLSearchParams({ contToken }).toString()}`
            : '');

        const { data } = await authSender.get(userLicensesEndpoint);
        contToken = data.contToken;

        userPermits = concat(
          userPermits,
          parseRawUserLicensesDataAsActiveLicenses(data.licenses),
        );
      } while (contToken);

      // Once all the permits have been retrieved we store it in Redux.
      dispatch(dashboardActions.setUserPermitsList({ data: userPermits }));
    } catch (err) {
      dispatch(dashboardActions.setUserPermitsList({ failed: true }));
      captureExceptionWithContext(
        new DashboardRetrieveUserLicensesError(err),
        userAccountDetails,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAccountDetails]);

  const fetchRenewalLicenseData = useCallback(async () => {
    if (disableBulkRenewalFlow && disableSingleRenewalFlow) return;
    dispatch(dashboardActions.setBulkRenewableLicenses({}));

    try {
      // Get all the renewable licenses linked and then put them into Redux.
      const { data } = await authSender.get('/renewableLicenses');
      const camelCasedData = camelCaseDictKeys(data);
      dispatch(
        dashboardActions.setBulkRenewableLicenses({ data: camelCasedData }),
      );
    } catch (err) {
      dispatch(dashboardActions.setBulkRenewableLicenses({ failed: true }));
      captureExceptionWithContext(
        new DashboardRenewableLicensesAPIError(err),
        userAccountDetails,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disableBulkRenewalFlow, userAccountDetails, disableSingleRenewalFlow]);

  const fetchTaxableLicensePeriodsData = useCallback(async () => {
    if (disableBulkTaxFlow) return;
    dispatch(dashboardActions.setTaxableLicensePeriods({}));

    try {
      // Get all the taxable payment periods and then put them into Redux.
      const { data } = await authSender.get('/totPaymentPeriods');
      const camelCasedData = camelCaseDictKeys(data);
      dispatch(
        dashboardActions.setTaxableLicensePeriods({ data: camelCasedData }),
      );
    } catch (err) {
      dispatch(dashboardActions.setTaxableLicensePeriods({ failed: true }));
      captureExceptionWithContext(
        new DashboardTaxableLicensePeriodsAPIError(err),
        userAccountDetails,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disableBulkTaxFlow, userAccountDetails]);

  const fetchActionData = useCallback(() => {
    fetchRenewalLicenseData();
    fetchTaxableLicensePeriodsData();
  }, [fetchRenewalLicenseData, fetchTaxableLicensePeriodsData]);

  // const fetchUserReportingHistory = useCallback(
  //   async (isUserRequestedRefetching = false) => {
  //     // console.group('fetchUserReportingHistory');
  //     let contToken;

  //     try {
  //       do {
  //         const reportingHistoriesByUserInChunksEndpoint =
  //           '/reportingHistoriesByUserInChunks' +
  //           (contToken
  //             ? `?${new URLSearchParams({ contToken }).toString()}`
  //             : '');
  //         // console.log('reportingHistoriesByUserInChunksEndpoint:', reportingHistoriesByUserInChunksEndpoint);

  //         // Data looks like https://deckardtech.postman.co/workspace/Team-Workspace~2aea37f6-06d9-4b52-8ffe-f69edce926cb/example/19413758-3933a729-452a-4184-bbc2-6d81b4700378
  //         const { data } = await authSender.get(
  //           reportingHistoriesByUserInChunksEndpoint,
  //         );
  //         // console.log('data:', data);

  //         contToken = null;
  //         // contToken = data.contToken;
  //         // dispatch(dashboardActions.updateUserTaxReportingHistory({ data: data, status: data.contToken }));
  //       } while (contToken);
  //     } catch (err) {
  //       captureExceptionWithContext(
  //         new DashboardUserReportingHistoryAPIError(err),
  //       );

  //       Alert.error('Failed to get reporting history for user.', 10000);
  //     } finally {
  //       // console.groupEnd();
  //     }
  //   },
  //   [],
  // );

  useEffect(() => {
    batch(() => {
      // A set of clean up actions to ensure that the Redux state is what the system expects.
      dispatch(appActions.setActiveFlow(''));
      dispatch(appActions.setVisitedFirstPageInFlow({ value: true }));
      dispatch(dashboardActions.cleanDashboard());
      dispatch(networkActions.reset());
      dispatch(payloadActions.resetFlow());
    });

    (async () => {
      fetchPermitsForUser();
      fetchRenewalLicenseData();
      fetchTaxableLicensePeriodsData();
      // fetchUserReportingHistory();
    })();
    // This should only run once and after the initial rendering of the page.
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <NoticeBox text={dashboardNotice} />

      <Flex>
        {/**
         * Show if at least one of either bulk renewal, single renewal or pay tax flows are NOT disabled,
         */}
        {(!disableBulkRenewalFlow ||
          !disableSingleRenewalFlow ||
          !disableBulkTaxFlow) && <ActionableItems fetch={fetchActionData} />}

        {!hideDashboardPermitsDisplay && (
          <TruncatedPermitsList fetch={fetchPermitsForUser} />
        )}
      </Flex>

      {/* <UserReportingHistory fetch={fetchUserReportingHistory} /> */}
    </>
  );
}
