import React, { useState } from 'react';
import styled from 'styled-components';

import { RenewAction } from './renew-action';

const TableActionsDiv = styled.div`
  margin-top: 20px;

  display: flex;
  flex-direction: row;
  gap: 20px;
`;

export function TableActions() {
  const [loading, setLoading] = useState(false);

  return (
    <TableActionsDiv>
      <RenewAction loading={loading} setLoading={setLoading} />

      {/* <BlackButton>Pay {translations[TOT_VERBIAGE_LOWER]?.default ?? TOT_VERBIAGE}</BlackButton> */}
    </TableActionsDiv>
  );
}
