import { isNumber, template } from 'lodash';
import React, { useMemo } from 'react';
import { Divider } from 'rsuite';
import styled from 'styled-components';
import { parseHTMLString } from 'utils/utility-functions';

const Div = styled.div`
  display: flex;
  font-weight: ${({ bold }) => bold ? 'bold' : 'normal'};
  font-size: ${({ fontSize }) => fontSize ?? undefined};
`;

const Flex = styled.div`
  flex: 1;
`;

const Item = styled.div`
  width: 150px;
  text-align: center;
  margin: auto;
`;

const formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

export function LineItem(props) {
  const header = useMemo(() => parseHTMLString(
    template(props.header)({ paymentPeriodLabel: props. paymentPeriodLabel })
  ), [props.header, props.paymentPeriodLabel]);

  const lineItems = useMemo(() => props.items.map((item) => {
    if (isNumber(item)) return formatter.format(item);
    return item;
  }), [props.items]);

  return (
    <Div bold={props?.bold} fontSize={props?.fontSize}>
      {/* This can be an empty string to render an empty cell for the "columns" row of the table */}
      <Flex>{header}</Flex>
      {lineItems.map((item, idx) => (
        <Item key={idx}>{item}</Item>
      ))}
    </Div>
  )
}

const Gap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Line = styled(Divider)`
  background-color: black;
  margin: 5px 0;
`;

const TotalLineItem = styled(LineItem)`
  font-weight: bold !important;
`;

export function TabulatedLineItems(props) {
  const { assembledLineItems, assembledTotalLineItem, paymentPeriodLabel } = props;

  return (
    <div>
      <Gap>{assembledLineItems.map((item) => <LineItem key={item.field} {...item} />)}</Gap>
      <Line />
      <TotalLineItem bold {...assembledTotalLineItem} paymentPeriodLabel={paymentPeriodLabel} />
    </div>
  );
}

export function TotalPaymentLineItem(props) {
  return (
    <div style={{ margin: '20px 0' }}>
      <LineItem bold fontSize="24px" header="Total Payment Due" items={props.items} />
    </div>
  );
}
