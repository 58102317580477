import React, { useCallback, useEffect, useMemo } from 'react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { useField, useFormikContext } from 'formik';
import 'styled-components/macro';
import { WhiteButton } from 'common/navigation-buttons';
import { PersonForm } from './array-person-form-modules/person-form';
import { useSelector } from "react-redux";
import * as appSelectors from "app/state/app/app-selectors";
import styled from 'styled-components';
import { withVisibility } from 'configurable-form/components/utils/with-visibility';

const STANDARD_MAX = 10;

const Button = styled(WhiteButton).attrs({
  width: 'fit-content',
})``;

function AddPerson(props) {
  // We only want the "helpers" from this hook
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField({ name: `people.${props.personType}[${props.currentCapacity}]` });

  const userCannotAddMorePeople = !props.allowPersonCountChanges;

  // See comments for "startingIndex" prop in "ArrayPersonFormComponent".
  const maxLength = STANDARD_MAX - props.startingIndex;
  const capacityReached = props.currentCapacity >= maxLength;

  const onClick = useCallback(() => {
    if (!capacityReached) helpers.setValue({});
  }, [capacityReached, helpers]);

  if (userCannotAddMorePeople || capacityReached) return null;

  return (
    <Button type="button" onClick={onClick}>
      Add {props.personLabel ?? "person"}
    </Button>
  );
}

// See "ArrayPersonFormProps" in fieldProps.ts
function ArrayPersonFormComponent(props) {
  const {
    personType,
    allowPersonCountChanges: allowPersonCountChangesProp = true,
    startingIndex = 0, // See "make-array-person-form.ts"
  } = props;

  const enableEditingAllFields = useSelector(appSelectors.enableEditingAllFields);
  const allowPersonCountChanges = enableEditingAllFields || allowPersonCountChangesProp;

  const { values } = useFormikContext();

  // We only want the "helpers" from this hook
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField({ name: `people.${props.personType}` });

  const retrievedPeopleOfType = useMemo(() => {
    const standardPeoplesArray = get(values, `people.${personType}`, [{}]);

    if (!startingIndex) return standardPeoplesArray;
    return standardPeoplesArray.length > startingIndex ? standardPeoplesArray.slice(startingIndex) : standardPeoplesArray;
  }, [personType, startingIndex, values]);

  const currentCapacity = useMemo(() => {
    const currentNumPeoples = retrievedPeopleOfType.length;
    return currentNumPeoples + startingIndex;
  }, [retrievedPeopleOfType.length, startingIndex]);

  useEffect(() => {
    const retrievedPeoplesArray = get(values, `people.${personType}`, []);
    if (isEmpty(retrievedPeoplesArray)) helpers.setValue([{}]);
  }, [helpers, personType, values]);

  return (
    <div css={props.additionalCSS}>
      {retrievedPeopleOfType.map((__person, index) => (
        <PersonForm
          key={index}
          index={index + startingIndex}
          personType={personType}
          personLabel={props.personLabel}
          config={props.config}
          /**
           * Do not display the person removal button when:
           * 1. It is the first person form being rendered
           * 2. props.allowPersonCountChanges is "false" (config controlled, but defaulted to "true")
           */
          userCanRemovePeople={Boolean(index) && allowPersonCountChanges}
        />
      ))}

      <AddPerson
        currentCapacity={currentCapacity}
        startingIndex={startingIndex}
        personType={personType}
        personLabel={props.personLabel}
        allowPersonCountChanges={allowPersonCountChanges}
      />
    </div>
  );
}

export const ArrayPersonForm = withVisibility(ArrayPersonFormComponent);
