import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import 'styled-components/macro';
import { BlackButton, WhiteButton } from 'common/navigation-buttons';
import { errorActions } from 'app/state/error/error-slice';
import { captureExceptionWithContext } from 'utils/sentry-functions';

function BlackStratButton(props) {
  return (
    <BlackButton css={props.buttonCSS} onClick={props.handleStrategiesExecution}>
      {props.label}
    </BlackButton>
  );
}

function WhiteStratButton(props) {
  return (
    <WhiteButton css={props.buttonCSS} onClick={props.handleStrategiesExecution}>
      {props.label}
    </WhiteButton>
  );
}

/**
 * Sample Config Keys:
 *
 *  component: StrategiesButton
 *    Required - string
 *    The name of the component that will be used to render
 *
 *  id:
 *    Required - string
 *    String for the React prop "key"
 *
 *  label:
 *    Required - string
 *    Text label for the button
 *
 *  strategies:
 *    Required - object
 *    Strategies to execute
 *    ! NOTE THAT "onClick" is the strategy to be executed
 *
 *    ! DO NOT USE THIS FOR NETWORK REQUESTS AS LOGIC FOR THAT HAS NOT BEEN IMPLEMENTED
 *    ! AND WILL NOT BE UNTIL REQUIRED
 *
 *  useBlackButton:
 *    Option - boolean
 *    Boolean flag to indicate which button to render
 *
 *  additionalCSS
 *    Optional - string
 *    Additional css
 *    Note that this will not apply to the button itself
 *
 *  buttonCSS
 *    Optional - string
 *    Additional css
 *    Note that this will apply to just the button
 */
export function StrategiesButton(props) {
  const { additionalCSS, buttonCSS, label, strategies, strategyInstance } = props;

  const dispatch = useDispatch();

  // ! THIS CODE IS COPY PASTED FORM "ConfigurableForm" "onSubmitStrategyCB"
  const handleStrategiesExecution = useCallback(async () => {
    try {
      await strategyInstance.executeStrategy(strategies, 'onClick');
    } catch (err) {
      console.error('Caught error in StrategiesButton handleStrategiesExecution:', err);

      captureExceptionWithContext(err, {
        error:
          "Error when executing strategy function in StrategiesButton handleStrategiesExecution",
      });

      dispatch(errorActions.setHasErrored(true));
    }
  }, [dispatch, strategies, strategyInstance]);

  const StratButton = props.useBlackButton ? BlackStratButton : WhiteStratButton;

  if (!label || !strategies || !strategyInstance) return null;

  return (
    <div css={additionalCSS}>
      <StratButton
        buttonCSS={buttonCSS}
        handleStrategiesExecution={handleStrategiesExecution}
        label={label}
      />
    </div>
  );
}
