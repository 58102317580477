import { DEFAULT_ACH_LIMIT, TODAYS_DATE } from 'common/constants';
import parse from 'html-react-parser';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import merge from 'lodash/merge';
import set from 'lodash/set';
import appSettings from '../app-settings.json';
import { dayjs } from './dayjs';

// Delay function
export async function delay(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

/**
 * Only trims whitespace on the left side as we want to allow users
 * who have add whitespace on the right side of the value in the case
 * in the case that it is needed
 * Excess whitespace will be trimmed in payloadValuesTrimmer or
 * with another function
 * @param {string} input the user input
 * @returns user input with all whitespace on the left side trimmed
 */
export function userInputTrimmer(input) {
  if (typeof input === 'string') return input.trimStart();
  return input;
}

/**
 * Function to trim the whitespace from the payload
 * @param {string} input the user input
 * @returns user input with all whitespace on the left side trimmed
 */
export function payloadValuesTrimmer(payload) {
  const updatedPayload = { ...payload };
  const payloadKeys = Object.keys(updatedPayload);

  payloadKeys.forEach((key) => {
    const payloadValue = updatedPayload[key];
    if (typeof payloadValue === 'string') updatedPayload[key] = payloadValue.trim();
  });

  return updatedPayload;
}

/**
 * Converts a number to a USD currency format
 * @param {number} number number
 * @returns number formatted to US currency format
 */
export function convertNumberToCurrency(number) {
  if (typeof number === 'string') return number;
  if (!Number.isFinite(number)) return '';
  const formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
  return formatter.format(number);
}

/**
 * Converts a string to a USD currency format
 * @param {string} number number
 * @returns number formatted to US currency format
 */
export function convertStringToCurrency(input) {
  let number = parseFloat(input);
  if (isNaN(number)) {
    // If parsing fails, return input as is
    return input;
  }
  return convertNumberToCurrency(number);
}

export function convertFeeDictToArrayWithCalculatedSummaryValues(data, keys, calculateSummaryRowValueMap, ...options) {
  const augmentedData = { ...data, __calculatedSummaryValues: calculateSummaryRowValueMap };
  const augmentedKeys = keys.map(k => k && k.aggregateField ? `__calculatedSummaryValues['${k.aggregateField}']` : k);
  return convertFeeDictToArray(augmentedData, augmentedKeys, ...options);
}
/**
 * Generates an array from USD currency formatted numbers from data provided from /calculateTOT endpoint
 * @param {object} data data we receive from the /calculateTOT endpoint
 * @param {[string]} keys a list of keys to use to extract information from the data object
 * @returns an array of USD currency formatted numbers
 */
export function convertFeeDictToArray(data, keys, rowLabelField = 'period' ) {
  const feeArray = keys.map((key) => {
    if (key === null) return key;
    return convertNumberToCurrency(get(data, key))
  });

  // If the period key doesn't exist, then this should be the summary row
  const summaryRowLabel = 'Total';
  const rowLabel = get(data, rowLabelField, summaryRowLabel);

  return [rowLabel, ...feeArray];
}

export const DEFAULT_CITY_TIMEZONE = 'America/Los_Angeles';

/**
 *
 * @param {string} tz IANA timezone (e.g. 'America/New_York')
 * @returns {dayjs.Dayjs} A dayjs instance representing current time, in
 * the specified timezone
 */
export function getCurrentTimeInTimezone(tz = DEFAULT_CITY_TIMEZONE) {
  return dayjs().tz(tz);
}

/**
 * Results that are possible:
 *
 * Flow limit does exist
 *
 *  1. Amount due does not exceed the flow and global limit   => false
 *     (global limit is ignored when there is a flow limit)
 *  2. Amount due does exceed the flow limit                  => true (change the payment tab to credit card)
 *  3. Flow does not have a limit (0)                         => false
 *
 * Flow limit does not exist
 *
 *  1. Amount due does not exceed the global limit            => false
 *  2. Amount due does exceed the global limit                => true (change the payment tab to credit card)
 */
export function checkIfExceedsACHLimit(amountDue, flowLimit, globalLimit = DEFAULT_ACH_LIMIT) {
  // Flow ACH limit exists as a number
  if (Number.isFinite(flowLimit)) {
    // The flow ACH limit is not 0 (no limit) and the amount due exceeds the limit
    if (Boolean(flowLimit) && amountDue > flowLimit) return true;
  } else if (Number.isFinite(globalLimit)) {
    // The global ACH limit is not 0 (no limit) and the amount due exceeds the limit
    if (Boolean(globalLimit) && amountDue > globalLimit) return true;
  }

  return false;
}

/**
 * Takes a HTML string and parses the appropriate DOM element to render
 * @param {*} value to be checked
 * @returns the HTML element represented by the string or null if value is invalid
 *
 * Note when trying to render a link do not use the "cs.Link" component, but instead
 *  use an anchor element with the class "configLink"
 *  e.g.
 *    <a
 *      class="configLink"
 *      download
 *      href="https://generic-tot-app-file-storage.s3.us-east-2.amazonaws.com/Burlingame%2BSTR%2BOwner%2BConsent.pdf"
 *      target="_blank"
 *      rel="noreferrer"
 *    >
 *      Download Consent Form
 *    </a>
 */
export function parseHTMLString(value) {
  const singleStringValue = ensureSingleString(value);
  if (!singleStringValue) return null;

  return parse(singleStringValue);
}

/**
 * @param {string | string[]} stringOrArray
 * @returns {string | null} Returns the argument itself if it is a non-empty string,
 * returns all the elements joined by a newline if the argument is a string array,
 * and returns null otherwise
 */
export function ensureSingleString(stringOrArray) {
  if (!stringOrArray) return null;
  if (typeof(stringOrArray) === 'string') return stringOrArray;
  if (Array.isArray(stringOrArray)) return stringOrArray.join("\n");

  return null;
}

export function getLocationDetails(place) {
  const [state, county, city = ''] = place.split('-');
  return { state, county, city };
}

export function asyncDebounce(func, wait) {
  const debounced = debounce(async (resolve, reject, bindSelf, args) => {
    try {
      const result = await func.bind(bindSelf)(...args);
      resolve(result);
    } catch (error) {
      reject(error);
    }
  }, wait);

  // This is the function that will be bound by the caller, so it must contain the `function` keyword.
  function returnFunc(...args) {
    return new Promise((resolve, reject) => {
      debounced(resolve, reject, this, args);
    });
  }

  return returnFunc;
}

export function checkIfProdCognitoUserPool() {
  const prodCognitoUserPool = 'us-west-2_7QPFbKVm2';
  return appSettings?.cognitoUserPool === prodCognitoUserPool;
}

export function checkIfDevCognitoUserPool() {
  const devCognitoUserPool = 'us-east-2_OZsd0sGfR';
  return appSettings?.cognitoUserPool === devCognitoUserPool;
}

export function mapObject(obj, mapper) {
  if (!obj || isEmpty(obj)) return null;
  return Object.assign(...Object.keys(obj).map(k => ({ [k]: mapper(k, obj[k]) })));
}

export function addObjects(o1, o2) {
  const result = mapObject(o1, (key, value1) => isNumber(value1) ? (value1 + o2[key]) : undefined);
  return result;
}

/**
 * Generates a name for a Formik field that should not be sent to the API during `apiPost`
 */
export function createInternalFormikFieldName(baseName) {
  const nameSuffix = baseName || `field${Math.round(Math.random() * 10**5)}`;
  return `_${nameSuffix}`;
}

const customerConfigValueTypeToTransformation = {
  json: (rawValue) => {
    const jsonString = rawValue.includes("'") ? rawValue.replaceAll("'", '"') : rawValue;
    return JSON.parse(jsonString);
  },
  string: (s) => s,
}

function transformCustomerConfigValue(value) {
  let valueToTransform = value;
  let transformer = customerConfigValueTypeToTransformation.json

  if (typeof value === 'object') {
    valueToTransform = value.value;
    transformer = customerConfigValueTypeToTransformation[value.type] || transformer;
  }

  return transformer(valueToTransform);
}

/**
 * Converts string values from the "_customerConfig" sub-dictionary of
 *    (generally should only be seen in initial Formik values)
 * into an object if possible.
 */
export function parseCustomerConfig(formikValues) {
  const customerConfig = get(formikValues, '_customerConfig', {});
  if (isEmpty(customerConfig)) return formikValues;

  const parsed = {};

  const customerConfigFieldKeys = Object.keys(customerConfig);

  customerConfigFieldKeys.forEach((key) => {
    const uPath = `_customerConfig[${key}]`;
    const rawValue = get(formikValues, uPath);
    const transformedValue = transformCustomerConfigValue(rawValue);
    set(parsed, uPath, transformedValue);
  });

  return merge({}, formikValues, parsed);
}

export function checkCognitoUserForCustomerPortal(
  place,
  cognitoUsername
) {
  const regex = new RegExp(`(${place})(::)(userid\\/\\d+)`, 'g');
  return regex.test(cognitoUsername);
}

// ------------------------------------------------------
// Reporting History Functions
// ------------------------------------------------------

export function filterByLast12Months(period) {
  return Math.abs(dayjs(period.startDate).diff(dayjs(TODAYS_DATE), 'month')) < 12;
}

export function filterBySameYear(period, targetYear) {
  return dayjs(period.startDate).year() === targetYear;
}


export function formatDate(date) {
  return dayjs(date).format("MMM D YYYY");;
}

export function formatTomorrow(date) {
  return dayjs(date).add(1, 'day').format("MMM D YYYY");;
}
