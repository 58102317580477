import React, { useMemo } from 'react'
import styled from 'styled-components';
import { LineItem, TabulatedLineItems } from './line-item';
import { cloneDeep, get, merge } from 'lodash';
import { formatProperty, useLicensesInReportMap } from 'pages/pay-flow/report-revenue-page/utils'


const PaymentPeriodLabel = styled.p`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
`;

const Block = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

function gatherSortedLineItemsFn(licenseReport, order = []) {
  const dataStore = cloneDeep(licenseReport).misc.taxableActivities;

  const dataStoreSortedByProviderOrder = dataStore.sort((a, b) => {
    return order.indexOf(a.provider) - order.indexOf(b.provider);
  });

  return (field, useFullData) => {
    return useFullData ?
      [get(licenseReport, field)] :
      dataStoreSortedByProviderOrder.map((pData) => get(pData, field));
  }
}

export function LicenseReportSummary(props) {
  const { licenseReport, period, providerLineItems, providerOrder, lineItems, hidePeriodName, hidePropertyLabel } = props;

  const tabulatedLinedItems = useMemo(() => {
    const gatherSortedLineItems = gatherSortedLineItemsFn(licenseReport, providerOrder);

    return lineItems.map(({ lineItems, totalLineItem }) => ({
      assembledLineItems: lineItems.map((item) => merge({},
        item,
        { items: gatherSortedLineItems(item.field, item.useFullData) }
      )).filter(item => {
        // filter out items whose "field" values are evaluated to undefined. 
        return !item.items.every(item => item === undefined)
      }),
      assembledTotalLineItem: merge({},
        totalLineItem,
        { items: gatherSortedLineItems(totalLineItem.field, totalLineItem.useFullData) }
      ),
    }));
  }, [licenseReport, lineItems, providerOrder]);

  const licenseIdToLicense = useLicensesInReportMap();
  const propertyLabel = formatProperty(licenseIdToLicense[licenseReport?.licenseId]);
  const periodLabelComponents = [
    !hidePeriodName && period.period,
    !hidePropertyLabel && propertyLabel
  ].filter(Boolean)
  const headerLabel = periodLabelComponents.join(' - ');

  return (
    <div>
      {headerLabel && <PaymentPeriodLabel>{headerLabel}</PaymentPeriodLabel>}

      {Boolean(providerLineItems.length) && (
        <div style={{ marginBottom: '10px' }}><LineItem header="" items={providerLineItems} /></div>
      )}

      <Block>
        {tabulatedLinedItems.map((el, i) => (
          <TabulatedLineItems key={i} {...el} paymentPeriodLabel={period.period} />
        ))}
      </Block>
    </div>
  );
}
