import * as appSelectors from 'app/state/app/app-selectors';
import {
  DISABLE_SINGLE_PERMIT_ACTIONS_PATH,
  DISABLE_SINGLE_RENEW_FLOW_PATH,
  DISABLE_UPDATE_FLOW_PATH,
  PERMIT_VERBIAGE,
  PERMIT_VERBIAGE_TRANSLATION_KEY,
} from 'common/constants';
import get from 'lodash/get';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Alert } from 'rsuite';
import { PermitActionabilityAPIError } from 'strategies/customer-portal/errors';
import { authSender } from 'utils/auth-sender';
import { captureExceptionWithContext } from 'utils/sentry-functions';
import { useTranslations } from 'utils/translations';

import { SinglePermitRenewalButton } from './single-permit-renewal-button';
import { ButtonsFlex } from './styles';
import { UpdatePermitButton } from './update-permit-button';

/**
 * This component handles everything related to the actionability of the permit.
 *
 * This means that the component itself will check if any flows (update, renewal, pay, etc.)
 * should be hidden from the user for each individual permit or permit application.
 *
 * The component will also call the "/permitActionability" API to check if the permit
 * or permit application is actionable (updated, renewed, tax reportable, etc.).
 *
 * For a flow to be enabled:
 *  1. the client JSON must not have the respective feature disabled
 *  2. the "/permitActionability" API must indicate that the flow is available for the
 *      permit or permit application
 */
export function PermitActions(props) {
  const translations = useTranslations();

  const disableSinglePermitActions = useSelector(
    appSelectors.getFromPortalStats(DISABLE_SINGLE_PERMIT_ACTIONS_PATH),
  );

  const disableSingleRenewFlow = useSelector(
    appSelectors.getFromPortalStats(DISABLE_SINGLE_RENEW_FLOW_PATH),
  );

  const disableUpdateFlow = useSelector(
    appSelectors.getFromPortalStats(DISABLE_UPDATE_FLOW_PATH),
  );

  const [permitActionability, setPermitActionability] = useState({});

  useEffect(() => {
    (async () => {
      try {
        const { data } = await authSender.get(
          `/permitActionability/${props.applicationNumber}`,
        );

        setPermitActionability(data);
      } catch (err) {
        const permitVerbiage = get(
          translations,
          `${PERMIT_VERBIAGE_TRANSLATION_KEY}.default`,
          PERMIT_VERBIAGE.toLowerCase(),
        );

        Alert.error(
          `Failed to determine actionability for the ${permitVerbiage}.`,
          10000,
        );

        captureExceptionWithContext(new PermitActionabilityAPIError(err), {
          applicationNumber: props.applicationNumber,
        });
      }
    })();
  }, [props.applicationNumber, translations]);

  if (disableSinglePermitActions) return null;

  return (
    <ButtonsFlex>
      {!disableSingleRenewFlow && permitActionability?.renewable && (
        <SinglePermitRenewalButton
          {...props}
          actionable={permitActionability?.renewable}
        />
      )}
      {!disableUpdateFlow && permitActionability?.updatable && (
        <UpdatePermitButton
          {...props}
          actionable={permitActionability?.updatable}
        />
      )}
    </ButtonsFlex>
  );
}
