import { Input } from 'common/common-styles';
import React from 'react';
import styled from 'styled-components';

import { ErrorMessage, Info, Instructions } from '../styles';

const StyledInput = styled(Input)`
  width: 400px;
  margin-bottom: 20px;
  max-width: 100%;
`;

export function EmailEntry(props) {
  const {
    licenseHolderEmail,
    emailInputValue,
    emailInputValueOnChange,
    isEmailError,
  } = props;

  return (
    <>
      <Instructions>
        Please enter the complete email address associated with this Tax ID.
      </Instructions>
      <Info>{licenseHolderEmail}</Info>
      <StyledInput
        value={emailInputValue}
        onChange={emailInputValueOnChange}
        maxLength={320}
        data-cy="email-modal-input"
      />
      <ErrorMessage show={isEmailError}>
        Email does not match records
      </ErrorMessage>
    </>
  );
}
