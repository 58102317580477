import { CircularProgress } from '@material-ui/core';
import React from 'react';

import { LargeDisplay, PersonalDetailsContainer, Row } from './styles';

export function PropertyContactsInflight(props) {
  const { disabled, onClick, dataFetchHasFailed } = props;

  return (
    <PersonalDetailsContainer disabled={disabled} onClick={onClick}>
      <Row justifyContent={dataFetchHasFailed ? undefined : 'center'}>
        <LargeDisplay>
          {dataFetchHasFailed ? (
            'Failed to retrieve property contacts. Click to try again.'
          ) : (
            <CircularProgress size={20} />
          )}
        </LargeDisplay>
      </Row>
    </PersonalDetailsContainer>
  );
}
