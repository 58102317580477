import { Check } from '@material-ui/icons';
import clsx from 'clsx';
import React from 'react';

import * as ss from '../vertical-stepper-styles';

export function QontoStepIcon(props) {
  const classes = ss.useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div className={clsx(classes.root, { [classes.active]: active })}>
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  );
}
