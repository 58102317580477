import { payloadActions } from 'app/state/payload/payload-slice';
import * as cs from 'common/common-styles';
import { BackButton, ContinueButton } from 'common/navigation-buttons';
import { useFormikContext } from 'formik';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { history } from 'routes/history';
import styled from 'styled-components';

const Container = styled(cs.ButtonWrapper)`
  margin-top: ${(props) => props.marginTopOverride ?? undefined};
`;

export function Navigation(props) {
  const dispatch = useDispatch();

  const { values } = useFormikContext();

  const onBackClickCB = useCallback(() => {
    dispatch(payloadActions.setFormikSnapshot({ values }));

    if (props.onBackClick) props.onBackClick();
    else history.goBack();
  }, [dispatch, props, values]);

  return (
    <Container marginTopOverride={props.marginTopOverride}>
      <BackButton onClick={onBackClickCB} />

      <ContinueButton
        type={props.continueButtonType}
        onClick={props.onContinueClick}
        loading={props?.loading}
        isDisabled={props?.loading || props?.disabled}
      />
    </Container>
  );
}
