import React from 'react';
import { useField } from "formik";

export function TextField({ additionalReportingColumn, fieldKey }) {
  const [field] = useField(fieldKey)
  const fieldValue = field?.value;

  const { valueLabels } = additionalReportingColumn;

  const value = valueLabels?.[fieldValue] || fieldValue;

  if (!value) return null;
  return <p>{value}</p>;
}
