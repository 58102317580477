import * as appSelectors from 'app/state/app/app-selectors';
import { appActions } from 'app/state/app/app-slice';
import { payloadActions } from 'app/state/payload/payload-slice';
import { FLOWS } from 'common/constants';
import React, { useEffect } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { BULK_RENEWAL_PATHS, FLOW_PATHS } from 'routes/constants';
import { ConfigurableRoutes } from 'routes/custom/configurable-routes';
import { BulkRenewalStrategy } from 'strategies/bulk-renewal-strategy';

export function BulkRenewalRoutes() {
  const dispatch = useDispatch();

  const flows = useSelector(appSelectors.flows);
  const activeFlow = useSelector(appSelectors.activeFlow);

  useEffect(() => {
    batch(() => {
      dispatch(appActions.setActiveFlow(FLOWS.BULK_RENEWAL));
      dispatch(payloadActions.updateFormikSnapshot(flows?.bulkRenewal?.initialFormikValues));
    });
    return () => {
      dispatch(appActions.setVisitedFirstPageInFlow({ value: false }));
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  // Must wait for the active flow to be set so all selectors specific to this flow work
  if (!activeFlow) return null;

  return (
    <div>
      <Switch>
        <Route exact path={FLOW_PATHS.bulkRenewal}>
          {/* The first step of bulk renewals is to let the user select the properties they want to renew */}
          <Redirect to={BULK_RENEWAL_PATHS.selectProperties} />
        </Route>

        <ConfigurableRoutes
          configurablePages={flows?.bulkRenewal?.configurablePages}
          strategyInstance={BulkRenewalStrategy.getInstance()}
        />
      </Switch>
    </div>
  );
}
