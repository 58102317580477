import * as networkSelectors from 'app/state/network/network-selectors';
import template from 'lodash/template';
import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { strRegistrationApiSender } from 'utils/str-registration-api-sender';
import { parseHTMLString } from 'utils/utility-functions';
import { PaymentButton } from '../../modules/payment-button/payment-button';

const savePayerDetails = async ({
  email,
  onSuccess,
  paymentId,
  signatory,
}) => {
  await strRegistrationApiSender.post('/payCheck', {
    email,
    paymentId,
    signatory,
  });

  await onSuccess();
};

const label = 'Select Check as your payment method';

export const DEFAULT_PAY_BY_CHECK_TEXT = `
<div>
  Please click the button below to confirm that you wish to pay by check.
</div>
`;

export const Check = ({ signatory, email, onSuccess, paymentTabPayByCheckText = DEFAULT_PAY_BY_CHECK_TEXT }) => {
  const [isLoading, setIsLoading] = useState(false);

  // const paymentFees = useSelector(networkSelectors.paymentFees);
  const { paymentId } = useSelector(networkSelectors.paymentFees);

  // const { data: checkDetails } =
  //   useFetchPayByCheckDetails({ email, paymentId, signatory });

  const templatedMessage = useMemo(
    () => {
      // const checkDetailsInit = {
      //   checkContactEmail: undefined,
      //   checkMailingAddress: undefined,
      //   checkUniqueCode: undefined,
      //   id: undefined,
      //   paymentId: undefined,
      //   checkTotal: undefined,
      // };

      // const checkDetailsUpdated = {
      //   ...checkDetailsInit,
      //   ...checkDetails,
      //   checkTotal: convertNumberToCurrency(paymentFees.checkTotal)
      // };

      return template(paymentTabPayByCheckText)({})
    },
    [paymentTabPayByCheckText],
  );

  const handleClick = useCallback(() => {
    setIsLoading(true);
    savePayerDetails({ paymentId, signatory, email, onSuccess });
  }, [paymentId, signatory, email, onSuccess]);

  return (
    <div>
      { paymentTabPayByCheckText && parseHTMLString(templatedMessage) }
      <PaymentButton
        disabled={false}
        label={label}
        loading={isLoading}
        onClick={handleClick}
      />
    </div>
  );
};
