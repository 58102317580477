import * as cs from 'common/common-styles';
import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: ${(props) => props.condensed ? '15px': '40px'};
`;

export const InputsRowWrapper = styled.div`
  display: flex;
  gap: 40px;
`;

export const SectionHeaderText = styled.div`
  ${cs.textBase}

  font-size: 15px;
  font-weight: bold;
  line-height: normal;
  color: #000000;
  margin-bottom: 18px;
`;

export const InputContainer = styled.div`
  max-width: 400px;
  width: 100%;
`;

export const ErrorMessage = styled.div`
  color: red;
`;

export const ActionButtonContainer = styled.div`
  width: 40px;
  flex-shrink: 0;
`;

export const Button = styled.button`
  padding: 0;
  margin-top: 33px;
  background-color: transparent;
`;

export const ExtraTaxableItemParamValue = styled.div`
  margin-top: 10px;
`;

export const ExtraTaxableItemCell = styled(InputContainer)`
  max-width: 150px;
`;
