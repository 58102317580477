import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Button, Modal } from 'semantic-ui-react';
import * as appSelectors from 'app/state/app/app-selectors';
import { formatterFuncByName } from 'pages/pay-flow/report-revenue-page/utils';
import { FORMAT_CURRENCY } from 'pages/pay-flow/report-revenue-page/constants';
import { DEFAULT_ACH_LIMIT } from 'common/constants';

export function ACHPaymentLimitModal({ open, setOpen }) {
  const globalACHLimit = useSelector(appSelectors.globalACHLimit);

  const achLimit = useMemo(() => {
    const currencyFormatter = formatterFuncByName[FORMAT_CURRENCY];
    return currencyFormatter(globalACHLimit || DEFAULT_ACH_LIMIT);
  }, [globalACHLimit]);

  return (
    <Modal size='small' open={open} onClose={() => setOpen(false)} onOpen={() => setOpen(true)}>
      <Modal.Header> Amount Exceed</Modal.Header>
      <Modal.Content>
        {/* TODO: Template the correct ACH limit value */}
        Cannot use ACH for payments over {achLimit} USD. Only credit card option is available for this amount.
      </Modal.Content>
      <Modal.Actions>
        <Button basic content='Close' onClick={() => setOpen(false)} />
      </Modal.Actions>
    </Modal>
  );
}
