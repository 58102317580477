import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import isString from 'lodash/isString';
import styled from 'styled-components';
import * as appSelectors from 'app/state/app/app-selectors';
import * as payloadSelectors from 'app/state/payload/payload-selectors';
import * as cs from 'common/common-styles';
import { evaluateCondition } from 'configurable-form/configurable-form-utils';
import { ensureSingleString, parseHTMLString } from 'utils/utility-functions';
import { DEFAULT_HEADER_TEXT } from '../../constants';

const HTML_TYPE = 'html';
const LICENSE_AWARE_TYPE = 'licenseAware';

const Div = styled(cs.HeaderText)`
  padding-bottom: 0px;
  margin-bottom: 20px;
`;

export function HeaderText() {
  const {
    reporting: {
      headerText = DEFAULT_HEADER_TEXT,
    }
  } = useSelector(appSelectors.renderConfig);

  const license = useSelector(payloadSelectors.license);

  const textToRender = useMemo(() => {
    if (!headerText) return null;

    // Simple string render
    if (isString(headerText)) return ensureSingleString(headerText);

    const { type, text } = headerText;

    if (type === HTML_TYPE) return parseHTMLString(text);
    if (type === LICENSE_AWARE_TYPE) {
      const firstValidIndex = text.findIndex((textElem) => evaluateCondition(license, textElem.condition));
      return parseHTMLString(text[firstValidIndex].text);
    }
  }, [license, headerText]);

  if (!textToRender) return null;
  return <Div>{textToRender}</Div>;
}
