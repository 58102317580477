import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { Button } from 'semantic-ui-react';
import * as cs from 'common/common-styles'
import { useDispatch } from 'react-redux';
import { errorActions } from 'app/state/error/error-slice';

/**
 * Use these buttons to go back and forward in the flows
 *
 * @param {func} props.onBackClick (optional) this will be called when back is clicked. This
 *   component will call history.goBack() right after executing onBackClick
 * @param {func} props.onContinueClick this is what needs to be done when continue is clicked
 * @param {boolean} props.continueDisabled boolean that disables continue button
 */
export function BackContinueButton(props) {
  const {
    onBackClick,
    onContinueClick,
    continueDisabled,
    isLoading = false,
    continueLabel,
    backHidden,
    continueHidden,
  } = props;

  const history = useHistory();
  const dispatch = useDispatch();

  const handleBackClick = useCallback(() => {
    if (onBackClick) onBackClick();
    else history.goBack();
  }, [history, onBackClick]);

  const handleContinueClick = useCallback(async () => {
    try {
      // start spinner
      await onContinueClick();
      // end spinner
    } catch (err) {
      console.error(err);
      Sentry.captureException(err);
      dispatch(errorActions.setHasErrored(true));
    }
  }, [dispatch, onContinueClick]);

  return (
    <cs.ButtonWrapper>
      {!backHidden &&
        <Button
          basic
          content='Back'
          disabled={isLoading}
          onClick={handleBackClick}
          data-cy="back-button"
        />
      }

      {!continueHidden &&
        <Button
          color='black'
          data-testid="continue-button"
          content={continueLabel || "Continue"}
          loading={isLoading}
          disabled={continueDisabled || isLoading}
          onClick={handleContinueClick}
          style={{ width: '200px' }}
          data-cy="continue-button"
        />
      }
    </cs.ButtonWrapper>
  );
}
