import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import styled from 'styled-components/macro';
import * as payTOTSelector from 'app/state/pay-tot/pay-tot-selectors';
// import { payTotActions } from 'app/state/pay-tot/pay-tot-slice';
import {
  derivePropertyAddress,
  toggleSelectProperty,
  useLicensesInReport
} from '../../utils';
// import { MoreButton } from './more-button';
import { TaxablePeriods } from './taxable-periods';

const Flex = styled.div`
  display: flex;
`;

const PropertyContainer = styled.div`
  max-width: 65%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  border: 1px solid black;
  border-radius: 5px;
  padding: 5px 5px 5px 10px;
`;

const Checkbox = styled.input`
  margin-right: 10px;
  cursor: pointer;
`;

const Label = styled.label`
  font-size: 16px;
  font-weight: bold;
  line-height: 40px;
  cursor: pointer;
`;

// function displayReportingInputsForProperty(config, propertyAddress, newValue, unpaidPeriods) {
//   const unpaidPeriodIds = new Set(unpaidPeriods.map(({ id }) => id));
//
//   return config.map((el) => {
//     const newTaxableActivities = el.misc.taxableActivities.map((ele) => ({
//       ...ele,
//       ...(ele.propertyAddress === propertyAddress && (!newValue || unpaidPeriodIds.has(el.id)) && { isReporting: newValue })
//     }));
//
//     const hasReportingActivities = newTaxableActivities.some(({ isReporting }) => isReporting)
//
//     return { ...el, misc: { taxableActivities: newTaxableActivities },
//       selected: hasReportingActivities,
//     };
//   });
// }

export function Property(props) {
  const { values, setValues, setFieldValue } = useFormikContext();

  const dispatch = useDispatch();

  const {
    finalTOTs = [],
  } = useSelector(payTOTSelector.reportedRevenue);

  const { unpaidPeriodsByLicenseId, ignoreUnpaidPeriods, disabled } = props;

  const propertyAddress = derivePropertyAddress(props.propertyAddress);

  const isChecked = useMemo(() => {
    const selectedPeriods = Object.values(values.taxablePeriods)
      .filter(({ licenseReports }) => licenseReports
        .some(({ selected, licenseId }) => selected && licenseId === props.licenseId));

    return selectedPeriods.length > 0;

  }, [props.licenseId, values.taxablePeriods]);

  const { availablePeriodsById } = props;
  const licensesInReport = useLicensesInReport();

  const toggleSelect = useCallback(() => {
    toggleSelectProperty({
      licenseIds: [props.licenseId],
      setFieldValue,
      licensesInReport,
      unpaidPeriodsByLicenseId,
      values,
      availablePeriodsById,
      ignoreUnpaidPeriods,
    });
  }, [availablePeriodsById, ignoreUnpaidPeriods, licensesInReport, props.licenseId, setFieldValue, unpaidPeriodsByLicenseId, values]);

  // eslint-disable-next-line no-unused-vars
  const updateReportingConfig = useCallback((isChecked) => {
    // const updatedConfig = displayReportingInputsForProperty(values, props.propertyAddress, !isChecked, unpaidPeriods);

    /**
     * https://formik.org/docs/api/formik#settouched-fields--field-string-boolean--shouldvalidate-boolean--void
     * The hardcoded "false" argument indicates to Formik that it should perform any validation after
     *  updating it's internal state
     */
    // setValues(updatedConfig, false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.propertyAddress, setValues, values]);

  // eslint-disable-next-line no-unused-vars
  const updatePropertyVisibility = useCallback((isChecked) => {
    // if (isChecked) {
    //   const updatedFinalTOTs = finalTOTs.filter((el) => el != propertyAddress);
    //   dispatch(payTotActions.setReportedRevenue({ finalTOTs: updatedFinalTOTs }));
    // }
    //
    // updateReportingConfig(isChecked);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, finalTOTs, isChecked, propertyAddress, updateReportingConfig]);

  // eslint-disable-next-line no-unused-vars
  const onClick = useCallback(() => {
    updatePropertyVisibility(isChecked);
  }, [isChecked, updatePropertyVisibility])

  // const handleMarkAsFinalTOT = useCallback(() => {
  //   const propertyMarkedForFinalTOT = finalTOTs.includes(propertyAddress);
  //   const updatedFinalTOTs = propertyMarkedForFinalTOT
  //     ? finalTOTs.filter((el) => el != propertyAddress)
  //     : [...finalTOTs, propertyAddress];

  //   dispatch(payTotActions.setReportedRevenue({ finalTOTs: updatedFinalTOTs }));
  //   if (!propertyMarkedForFinalTOT) updateReportingConfig(isChecked);
  // }, [dispatch, finalTOTs, isChecked, propertyAddress, updateReportingConfig]);

  // const isFinalTOT = useMemo(() => finalTOTs.includes(propertyAddress), [finalTOTs, propertyAddress]);

  const unpaidPeriods = unpaidPeriodsByLicenseId[props.licenseId];

  return (
    <Flex>
      <PropertyContainer>
        <Label>
          <div>
            <Checkbox type='checkbox' checked={isChecked} disabled={disabled || (!isChecked && !ignoreUnpaidPeriods && unpaidPeriods.length < 1)} onChange={toggleSelect} />
            {props.license || props.applicationNumber}
          </div>
          <div>{propertyAddress}</div>
          {props.unitNumber && (<div>Unit: {props.unitNumber}</div>)}
        </Label>
        {/*<MoreButton isFinalTOT={isFinalTOT} handleMarkAsFinalTOT={handleMarkAsFinalTOT} />*/}
      </PropertyContainer>

      <TaxablePeriods periods={unpaidPeriods} onClick={() => {}} />
    </Flex>
  );
}
