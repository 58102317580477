import MenuItem from '@material-ui/core/MenuItem';
import {
  SORT_ACTION_ASC_EXPIRATION_DATE,
  SORT_ACTION_ASC_PERMIT_NUMBER,
  SORT_ACTION_ASC_PROPERTY_ADDRESS,
  SORT_ACTION_ASC_PROPERTY_OWNER,
  // SORT_ACTION_ASC_TAX_REPORTING,
  SORT_ACTION_DESC_EXPIRATION_DATE,
  SORT_ACTION_DESC_PERMIT_NUMBER,
  SORT_ACTION_DESC_PROPERTY_ADDRESS,
  SORT_ACTION_DESC_PROPERTY_OWNER,
  // SORT_ACTION_DESC_TAX_REPORTING,
} from 'pages/portal/dashboard/constants';
import React, { useCallback, useContext } from 'react';
import styled from 'styled-components';

import { FilterContext } from './filter-context';
import { Flex, Select } from './styles';

const FullWidth = styled(Flex)`
  width: 100%;
`;

export function SortAction() {
  const { sortMethod, setSortMethod } = useContext(FilterContext);

  const handleChange = useCallback(
    (e) => setSortMethod(e.target.value),
    [setSortMethod],
  );

  return (
    <FullWidth>
      <Select
        fullWidth
        variant="outlined"
        value={sortMethod}
        onChange={handleChange}
      >
        <MenuItem value={SORT_ACTION_ASC_PROPERTY_ADDRESS}>
          Property Address, A to Z
        </MenuItem>

        <MenuItem value={SORT_ACTION_DESC_PROPERTY_ADDRESS}>
          Property Address, Z to A
        </MenuItem>

        <MenuItem value={SORT_ACTION_ASC_PERMIT_NUMBER}>
          {/* This verbiage should change based on whatever the jurisdiction wants it to be */}
          Permit Number, Smallest to biggest
        </MenuItem>

        <MenuItem value={SORT_ACTION_DESC_PERMIT_NUMBER}>
          {/* This verbiage should change based on whatever the jurisdiction wants it to be */}
          Permit Number, Biggest to smallest
        </MenuItem>

        <MenuItem value={SORT_ACTION_ASC_EXPIRATION_DATE}>
          Expiration Date, Soonest first
        </MenuItem>

        <MenuItem value={SORT_ACTION_DESC_EXPIRATION_DATE}>
          Expiration Date, Furthest first
        </MenuItem>

        <MenuItem value={SORT_ACTION_ASC_PROPERTY_OWNER}>
          {/* This verbiage should change based on whatever the jurisdiction wants it to be */}
          {"Property Owner's Name, A to Z"}
        </MenuItem>

        <MenuItem value={SORT_ACTION_DESC_PROPERTY_OWNER}>
          {/* This verbiage should change based on whatever the jurisdiction wants it to be */}
          {"Property Owner's Name, Z to A"}
        </MenuItem>

        {/* Disabled until Phase 2 */}
        {/* <MenuItem value={SORT_ACTION_ASC_TAX_REPORTING}>
          Tax Reporting, Up-to-date first
        </MenuItem> */}

        {/* Disabled until Phase 2 */}
        {/* <MenuItem value={SORT_ACTION_DESC_TAX_REPORTING}>
          Tax Reporting, Overdue first
        </MenuItem> */}
      </Select>
    </FullWidth>
  );
}
