import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useFormikContext } from "formik";
import { Tooltip, Whisper } from 'rsuite';
import get from "lodash/get";
import 'styled-components/macro'; // This lets us use inline CSS
import * as appSelector from 'app/state/app/app-selectors';
import * as cs from 'common/common-styles';
import { pageHeaderTestId } from 'test/constants';
import { parseHTMLString } from 'utils/utility-functions';
import { withVisibility } from './utils/with-visibility';

function ToolTipImage({ tooltip }) {
  if (!tooltip) return null;

  return (
    <Whisper
      trigger="click"
      placement="left"
      speaker={<Tooltip>{tooltip}</Tooltip>}
    >
      <img src="/assets/icon-info.svg" alt="Info Icon" style={{ marginLeft: '5px' }} />
    </Whisper>
  );
}

/**
 * Config:
 *
 * {
 *  "component": "PageHeader"
 *    !REQUIRED
 *
 *  "id": For the React "key" prop
 *    !REQUIRED
 *    Type - String
 *
 *  "text": Text to be rendered
 *    !REQUIRED
 *    Type - String
 *
 *  "tooltip": Text to be displayed in a tooltip
 *    Type - String
 *
 *  "isShownOrHidden": Visibility Configuration (See "with-visibility.jsx")
 *    Type - Visibility Configuration
 *
 *  "additionalCSS": Additional CSS to render
 *    Type - String
 * }
 */
function PageHeaderComponent(props) {
  const { values } = useFormikContext();

  const theme = useSelector(appSelector.theme);

  const formattedText = useMemo(() => {
    /**
     * Either:
     *  1. Receive text to render from props.text
     *  2. Retrieve text to render from props.formikKey
     */
    const text = props.text || get(values, props.formikKey, undefined);
    return parseHTMLString(text);
  }, [props.formikKey, props.text, values]);

  if (!formattedText) return null;

  return (
    <cs.HeaderText
      css={`text-align: justify; ${props.additionalCSS}; font-size: ${theme?.form?.pageHeaderFontSize};`}
      data-cy={props.dataCy}
      data-testid={pageHeaderTestId}
    >
      {formattedText}
      <ToolTipImage tooltip={props.tooltip} />
    </cs.HeaderText>
  );
}

export const PageHeader = withVisibility(PageHeaderComponent);
