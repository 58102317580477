import styled from 'styled-components';
import { FormControlLabel } from '@material-ui/core';

export const CheckboxLabel = styled(FormControlLabel)`
  margin-top: 9px;
  margin-bottom: 9px;
  &&& {
    span {
      font-family: Lato;
      font-weight: bold;
      letter-spacing: normal;

      align-self: flex-start;
      &.MuiCheckbox-root {
        padding-top: 1px;
        padding-bottom: 0;
      }
    }
  }
`;
