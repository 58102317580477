import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';
import styled from 'styled-components';

const Div = styled.div`
  display: flex;
  justify-content: center;
  font-style: italic;
  padding: 50px 0;
  height: 100%;

  cursor: ${(props) => (props.disabled ? 'normal' : 'pointer')};
  pointer-events: ${(props) => (props.disabled ? 'none' : undefined)};
`;

export function NoData(props) {
  const { onClick, disabled } = props;

  return (
    <Div disabled={disabled} onClick={onClick}>
      {disabled ? <CircularProgress size={20} /> : <>{props.children}</>}
    </Div>
  );
}
