import * as appSelectors from 'app/state/app/app-selectors';
import React, { useMemo } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import styled from 'styled-components/macro';
import * as cs from 'common/common-styles';
import { TaxSummaryTableComponent } from '../tax-summary-table/tax-summary-table';
import { convertNumberToCurrency } from 'utils/utility-functions';
import { useSelector } from 'react-redux';

const StyledAccordionSummary = styled(AccordionSummary)`
  ${cs.greyBackground}
`;

const ReportingPeriodSection = styled.div`
  margin-bottom: 50px;
`;

const TaxPeriodHeading = styled.div`
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 20px;
`;

const TaxPeriodTotal = styled.div`
  font-weight: bold;
  font-size: 16px;
`;

const TaxPeriodSumaryAddress = styled.div`
  flex-grow: 1
`;

const TaxPeriodSumarTotal = styled.div`
  width: 150px;
  padding-right: 15px;
  text-align: right;
`;

export function TaxSummaryAccordionPeriodComponent({ data }) {
  const totalDueForMonth = useMemo(() => {
    return convertNumberToCurrency(data.totalDueForPeriod)
  }, [data]);

  const taxVerbiage = useSelector(appSelectors.totName);

  return (
    <ReportingPeriodSection>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <TaxPeriodHeading>{data.label}</TaxPeriodHeading>
        <div style={{ marginRight: '65px' }}>{taxVerbiage} due</div>
      </div>

      {
        data.licenses.map((item, idx) => (
          <div key={idx}>
            <Accordion>
              <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                <TaxPeriodSumaryAddress>{item.address}</TaxPeriodSumaryAddress>
                <TaxPeriodSumarTotal>{item.totalPaymentDue.total}</TaxPeriodSumarTotal>
              </StyledAccordionSummary>
              <cs.AccordionDetail>
                <TaxSummaryTableComponent data={item} />
              </cs.AccordionDetail>
            </Accordion>
          </div>
        ))
      }
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
        <TaxPeriodTotal>Total due for {data.label}</TaxPeriodTotal>
        <TaxPeriodTotal style={{ marginRight: '65px' }}>{totalDueForMonth}</TaxPeriodTotal>
      </div>
    </ReportingPeriodSection>
  );
}