import React from 'react';
import { Formik } from 'formik';
import { renderFieldComponent } from 'configurable-form';

export function HelperComponents({ components }) {
  if (!components) return;

  return (
    <Formik>
      <>
        {components.map((config, fieldIndex) =>
          renderFieldComponent(config, `field-${fieldIndex}`, null, null)
        )}
      </>
    </Formik>
  );
}
