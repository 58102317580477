import React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import App from './App';
import { store } from './app/app-store';
import reportWebVitals from './reportWebVitals';

import './index.css';
import 'semantic-ui-css/semantic.min.css';
import 'rsuite/dist/styles/rsuite-default.css';
import { captureExceptionWithContext } from 'utils/sentry-functions';
import { strRegistrationApiSender } from 'utils/str-registration-api-sender';

require('dotenv').config();

/**
 * A ValidationError most likely comes from Formik when the user
 *  has not typed anything into the input field yet
 * This information probably does not need to be recorded, so we
 *  do not send the event when this error (ValidationError)
 *  is encountered
 */
function isFormikErrorEvent(event) {
  return event?.exception?.values.some((error) => error.type === 'ValidationError');
}

Sentry.init({
  dsn: "https://0eee184cdfa047a19d101cf145eed375@o323940.ingest.sentry.io/6139124",
  environment: window.location.hostname,
  integrations: [new Integrations.BrowserTracing({
    /**
     * By default, Sentry will send a header: Access-Control-Request-Headers: sentry-trace
     * However, http-server is not capable of receiving this header and nor are we able
     *  to config http-server to be able to receive this header
     * So in order to not attach the request when requesting the JSON from http-server
     *  we use a regex this specific regex that tests if 'localhost' is in the URL or not
     */
    tracingOrigins: [/^(?!.*?localhost)/],
  })],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  beforeSend: event => {
    // Add a console.log here for "event" to see all events being sent to Sentry
    if (isFormikErrorEvent(event)) return; // Do not send the error to Sentry
    return event;
  }
});

window.Sentry = Sentry;

/**
 * https://stackoverflow.com/questions/28001722/how-to-catch-uncaught-exception-in-promise/28004999#28004999
 */
window.addEventListener('unhandledrejection', function(promiseRejectionEvent) {
  class UnhandledRejectionError extends Error {
    constructor(message) {
      super(message);
      this.name = 'UnhandledRejectionError';
    }
  }

  const reason = JSON.stringify(promiseRejectionEvent.reason.toJSON())
  captureExceptionWithContext(new UnhandledRejectionError(), { reason });
});

window._triggerUnhandledError = async () => {
  await strRegistrationApiSender.get('nonexistentEndpoint_triggerUnhandledError');
}

window._triggeredHandledError = async () => {
  try {
    await window._triggerUnhandledError();
  } catch (err) {
    class ManuallyTriggeredError extends Error {
      constructor(message) {
        super(message);
        this.name = 'ManuallyTriggeredError';
      }
    }

    Sentry.captureException(new ManuallyTriggeredError());
  }
}

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

/**
 * If you want to start measuring performance in your app, pass a function
 * to log results (for example: reportWebVitals(console.info))
 * or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
 */
reportWebVitals();
