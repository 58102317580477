import React from 'react';

import { HighlightedPermitData } from './highlighted-permit-data';

export function PermitIdHighlight(props) {
  const label = `${
    props.isPermitApplication ? 'Application' : props.permitVerbiage
  } Number`;
  const data = props.isPermitApplication
    ? props.applicationNumber
    : props.licenseNumber;

  if (!data) return null;
  return <HighlightedPermitData label={label} data={data} />;
}
