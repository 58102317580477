import styled from 'styled-components';

export const Tab = styled.div`
  height: 50px;
  border-radius: 6px;
  border-color: ${(props) => (props.isActive ? 'black' : 'rgba(128,128,128, 0.5)')};
  border-width: 1px;
  border-style: solid;

  display: flex;
  flex-direction: column;

  padding: 8px 8px;
  box-sizing: border-box;
  cursor: pointer;
`;

export const TabItem = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const PaymentTabText = styled.span`
  width: 100%;
  height: 12px;
  font-size: 12px;
  line-height: 1;
  padding: 5px 0 2px;
`;
