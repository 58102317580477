import React, { useCallback, useMemo } from 'react';
import { useField, useFormikContext } from 'formik';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import kebabCase from 'lodash/kebabCase';
import merge from 'lodash/merge';
import pullAt from 'lodash/pullAt';
import set from 'lodash/set';
import startCase from 'lodash/startCase';
import styled from 'styled-components';
import { ONE } from 'common/constants';
import {
  InputField,
  MultiInputField,
} from 'configurable-form/components/input-field';
import { PageHeader } from 'configurable-form/components/page-header';
import { AddressInputField } from '../address-input-fields';
import { isString } from 'lodash';

const inputComponentMap = {
  InputField,
  MultiInputField,
  AddressInputField,
};

function templateFormikPaths(path, config) {
  const clone = cloneDeep(config);

  // This component is just a nested form of the default component ("InputField") configuration
  if (clone.component === 'MultiInputField') {
    clone.components = clone.components.map((component) => templateFormikPaths(path, component));
    if (clone.alternateComponents) {
      const { componentSetIdField } = clone.alternateComponents;

      if (componentSetIdField) {
        set(clone, 'alternateComponents.componentSetIdField', `${path}.${componentSetIdField}`);
      }

      const mapped = get(clone, 'alternateComponents.alternateComponentSets', [])
        .map(compSetSpec => ({
          ...compSetSpec,
          components: compSetSpec.components.map((component) => templateFormikPaths(path, component)),
        }));

      set(clone, 'alternateComponents.alternateComponentSets', mapped);

      const { transformTargetPath } = clone.alternateComponents;

      if (transformTargetPath !== undefined && transformTargetPath !== null) {
        clone.alternateComponents.transformTargetPath =
          !transformTargetPath ? path : `${path}.${transformTargetPath}`
      }
    }

    // template the fields on the prefixComponent
    if (clone.prefixComponent) {
      if (clone.prefixComponent?.displayCondition?.conditions) {
        const { conditions } = clone.prefixComponent.displayCondition;

        clone.prefixComponent.displayCondition.conditions = conditions.map((conditions) => {
            return conditions.map(condition => {
              if (!isString(condition)) return condition;

              return condition.replace(clone.personTypeRootField, path);
            })
        })
      }

      if (clone.prefixComponent?.variables) {
        const { variables } = clone.prefixComponent;

        clone.prefixComponent.variables = Object.keys(variables).reduce((acc, variable) => {
          const value = variables[variable];

          return {
            ...acc,
            [variable]: value?.key ? {
              ...value,
              key: value.key.replace(clone.personTypeRootField, path),
            }: value,
          }
        }, {})
      }
    }

    return clone;
  }

  const { personRelativeProps = [] } = config;

  const personRelativePropsObject = ['name', ...personRelativeProps].reduce(
    (acc, propName) => {
      let currentPath = path;
      if (propName === 'payloadFromStore') {
        currentPath = `payload.formikSnapshot.${path}`;
      }

      return merge({}, acc, set({}, propName, `${currentPath}.${get(clone, propName)}`));
  }, {});

  const updatedNameProp = `${path}.${clone.name}`;
  const source = merge({},
    // Use the proper "name" prop to allow Formik to access and write to the property value path
    personRelativePropsObject,
    /**
     * Apply the correct Cypress selectors depending on the component type
     * We only have 2 base input components, which other input components are built from
     *  1. InputField
     *  2. AddressInputField
     */
    (clone.component === 'AddressInputField'
      ? {
          dataCyInput: kebabCase(`${updatedNameProp}Input`),
          dataCyCheckbox: kebabCase(`${updatedNameProp}Checkbox`),
        }
      : { dataCy: kebabCase(updatedNameProp) }),
    /**
     * !ONLY FOR THE FIRST PERSON IN THE ARRAY FORM
     *  !This person is usually the "Permit Holder" whose given and family name cannot be changed
     *
     * Determine whether or not to disable a specific field:
     *  1. If person being rendered is the first person in the array of people
     *  2. If there is a "disabledCondition" defined in the config
     */
    (clone.disabledCondition && { disabledCondition: updatedNameProp })
  );

  return merge({}, clone, source);
}

const FlexContainer = styled.div`
  width: 400px;
  display: flex;
  justify-content: space-between;
`;

const RemovePersonText = styled.div`
  cursor: pointer;
  /* To match default PageHeaderComponent margins */
  margin-bottom: 20px;
  align-self: flex-end;
`;

export function PersonForm(props) {
  const { values } = useFormikContext();

  const personPath = `people.${props.personType}`;
  const formikValuePath = `${personPath}[${props.index}]`;

  const userFriendlyPersonLabel = useMemo(() => {
    const label = `${props.personLabel || startCase(props.personType)}`;
    const numbering = `#${props.index + ONE}`;

    return `${label} ${numbering}`;
  }, [props.index, props.personLabel, props.personType]);

  // We only want the "helpers" from this hook
  const [field, meta, helpers] = useField({ name: personPath }); // eslint-disable-line no-unused-vars

  const onClick = useCallback(() => {
    const clone = cloneDeep(get(values, personPath));
    pullAt(clone, props.index);
    helpers.setValue(clone);
  }, [helpers, personPath, props.index, values]);

  return (
    <div>
      <FlexContainer>
        <PageHeader text={userFriendlyPersonLabel} />
        {props.userCanRemovePeople && (
          <RemovePersonText onClick={onClick}>
            Remove {userFriendlyPersonLabel}
          </RemovePersonText>
        )}
      </FlexContainer>

      {props?.config?.map((el) => {
        const Component = inputComponentMap[el.component];
        const config = templateFormikPaths(formikValuePath, el, props.index);

        return <Component key={config.name} {...config} />;
      })}
    </div>
  );
}
