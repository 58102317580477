import React from 'react';
import { useSelector } from 'react-redux';
import * as appSelectors from 'app/state/app/app-selectors';
import { AddressInput } from './address-input';
import { APNInput } from './apn-input';

export function APNAddressInput(props) {
  const { parcelNumber, setParcelNumber, address, setAddress, disabled } = props;

  const renderConfig = useSelector(appSelectors.renderConfig);
  const showAddressSearch = renderConfig?.search?.showAddressSearch;
  const addressSearchButtonText = renderConfig?.search?.addressSearchButtonText;

  return (
    <>
      {
        showAddressSearch && (
          <AddressInput
            address={address}
            searchButtonLabel={addressSearchButtonText}
            disabled={disabled}
            setAddress={setAddress}
            setParcelNumber={setParcelNumber}
          />
        )
      }
      <APNInput parcelNumber={parcelNumber} setParcelNumber={setParcelNumber} disabled={disabled} />
    </>
  );
}
