import styled from 'styled-components';
import { formFieldBase } from '../../shared-styles';

export const CardField = styled.div`
  display: flex;
  margin-bottom: 10px;

  .stripe-element {
    height: 35px;
  }

  #stripe-card-number {
    ${formFieldBase}
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
  }

  #stripe-expiry {
    ${formFieldBase}
    padding: 8px 12px;
    border-bottom-left-radius: 6px;
  }

  #stripe-cvc {
    ${formFieldBase}
    font-size: 14px;
    padding: 8px 12px;
    border-bottom-right-radius: 6px;
  }
`;

export const PaymentProcessorContainer = styled.div`
  max-width: 500px;
  padding: 1px;
  @media screen and (max-width: 756px) {
    max-width: none;
    width: 100%;
  }
`;
