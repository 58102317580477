import { MenuItem } from '@material-ui/core';
import React, { useCallback } from 'react';
import { PORTAL_PATHS } from 'routes/constants';
import { history } from 'routes/history';

import { userContextMenuStyles } from '../styles';

export function UserUpdateInfo(props) {
  const classes = userContextMenuStyles();

  const onClick = useCallback(() => {
    if (history.location.pathname === PORTAL_PATHS.dashboardUpdateUserDetails)
      return;
    props.handleClose();
    history.push(PORTAL_PATHS.dashboardUpdateUserDetails);
  }, [props]);

  return (
    <MenuItem className={classes.menuItem} onClick={onClick}>
      Change my personal details
    </MenuItem>
  );
}
