import MUISelect from '@material-ui/core/Select';
import MUITextField from '@material-ui/core/TextField';
import { BoxShadow } from 'common/common-styles';
import styled, { css } from 'styled-components';

export const ColumnDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const Flex = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
`;

export const SearchDiv = styled.div`
  ${BoxShadow}

  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1.5fr 3fr 1fr;
  gap: 15px;
  padding: 15px 20px;
  background-color: #f0f0f0;
`;

// ------------------------------------------------------
// Styled MUI Components
// ------------------------------------------------------

const shared = css`
  background-color: white;

  .MuiOutlinedInput-notchedOutline {
    border-color: black;
  }

  .MuiOutlinedInput-input {
    padding: 14px 10px;
  }
`;

export const Select = styled(MUISelect).attrs({
  variant: 'outlined',
})`
  &&& {
    ${shared}
    width: ${(props) => (props.fullWidth ? '100%' : '200px')};

    .MuiSelect-select:focus {
      background-color: white;
    }
  }
`;

export const TextField = styled(MUITextField).attrs({
  variant: 'outlined',
})`
  &&& {
    ${shared}
    width: 100%;
  }
`;
