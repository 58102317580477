import withStyles from '@material-ui/core/styles/withStyles';
import MUITooltip from '@material-ui/core/Tooltip'
import React from 'react'

const StyledTooltip = withStyles({
  tooltip: {
    fontSize: 14,
  },
})(MUITooltip);

export function Tooltip(props) {
  const {
    useTooltip = true,
    placement = 'top',
    title,
  } = props;

  if (!useTooltip) return <>{props.children}</>;

  return (
    <StyledTooltip placement={placement} title={title}>
      <div>{props.children}</div>
    </StyledTooltip>
  );
}
