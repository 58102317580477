export class PortalAccountCreationEmailStepError extends Error {
  constructor(message) {
    super(message);
    this.name = 'PortalAccountCreationEmailStepError';
  }
}

export class PortalAccountCreationCreationStepError extends Error {
  constructor(message) {
    super(message);
    this.name = 'PortalAccountCreationCreationStepError';
  }
}
