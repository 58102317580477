import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from 'rsuite';
import * as appSelectors from 'app/state/app/app-selectors';
import * as networkSelectors from 'app/state/network/network-selectors';
import * as paymentSelectors from 'app/state/payment/payment-selectors';
import { paymentActions } from 'app/state/payment/payment-slice';
import { Link } from 'common/common-styles';
import { ZERO as firstIndexInArray } from 'common/constants';
import { ERROR_MESSAGES } from 'common/error-constants';
import { paymentFeeFactory } from 'utils/checkout-utils';
import { captureExceptionWithContext } from 'utils/sentry-functions';
import { strRegistrationApiSender } from 'utils/str-registration-api-sender';
import { convertNumberToCurrency } from 'utils/utility-functions';
import { PaymentButton } from '../../../modules/payment-button/payment-button';
import { PaymentInfoText } from '../../shared-styles';
import { PaymentProcessorContainer } from '../card/stripe-styles';

// Opening of the Plaid modal
const PLAID_OPEN_EVENT = 'OPEN';
// Successful completion of verification
const PLAID_VERIFICATION_SUCCESS_EVENT = 'HANDOFF';
// Used to verify if the button for payment is already pressed or not.
const INITIAL_CASE = "init"
const VERIFYING = "Verifying"
const NOT_VERIFYING = "Not verifying"

const verifiedAccountText = 'Bank account verified.';
const preVerificationText = 'To make a secure payment, connect to your bank account and verify your identity.';

export function Plaid(props) {
  const dispatch = useDispatch()

  const [bankIdentifiers, setBankIdentifiers] = useState();

  /**
   * Can have multiple values:
   *  1. True
   *  2. False
   *  3. INITIAL_CASE ('init')
   *
   * Case 3 is used to verify if the user have verified a
   *  bank account at least once or if the operation
   *  has been cancelled before entering the credentials
   *  necessary for versification
   */
  const [isVerifyingBank, setIsVerifyingBank] = useState(INITIAL_CASE);

  // For holding Plaid link instance
  const linkHandlerRef = useRef();

  const activePaymentMethodTab = useSelector(appSelectors.activePaymentMethodTab);
  const paymentFees = useSelector(networkSelectors.paymentFees);

  const shouldLockPayments = useSelector(paymentSelectors.shouldLockPayments);

  // TODO: DECK-7991 This calculation is performed in multiple places
  const fees = useMemo(
    () => (paymentFees ? paymentFeeFactory(activePaymentMethodTab, paymentFees) : null),
    [activePaymentMethodTab, paymentFees]
  );

  const updateLock = useCallback((status) => {
    dispatch(paymentActions.setShouldLockPayments(status));
  }, [dispatch]);

  useEffect(() => {
    async function initPlaid() {
      updateLock(true);
      const { data } = await strRegistrationApiSender.post('/getLinkToken');
      const linkToken = data.link_token;

      const configs = {
        token: linkToken,
        onSuccess: async (publicToken, metadata) => {
          const { id: accountId } = metadata.accounts[firstIndexInArray];

          const { data } = await strRegistrationApiSender.post(
            '/plaidExchangeTokens',
            { publicToken, accountId },
          );

          setBankIdentifiers({ bTok: data.stripe_bank_account_token, metadata });
        },
        onEvent: (event) => {
          if (event === PLAID_OPEN_EVENT) setIsVerifyingBank(VERIFYING);
          else if (event === PLAID_VERIFICATION_SUCCESS_EVENT) setIsVerifyingBank(NOT_VERIFYING);
        },
        onExit: () => { setIsVerifyingBank(INITIAL_CASE); }
      };

      linkHandlerRef.current = window.Plaid.create(configs);
      updateLock(false);
    }

    initPlaid();
  }, [updateLock]);

  const disabled = useMemo(
    () =>
      shouldLockPayments ||
      (isVerifyingBank !== INITIAL_CASE && !bankIdentifiers) ||
      isVerifyingBank === VERIFYING,
    [bankIdentifiers, isVerifyingBank, shouldLockPayments]
  );

  const label = useMemo(() => (disabled ?
    'Verifying': `Pay ${convertNumberToCurrency(fees?.total?.value)}`
  ), [disabled, fees?.total?.value]);

  const achPaymentMethod = useCallback(async () => {
    if (shouldLockPayments) return;
    updateLock(true);

    try {
      await strRegistrationApiSender.post('/payACH', {
        bTok: bankIdentifiers.bTok,
        paymentId: paymentFees.paymentId,
        signatory: props.signatory,
        email: props.email,
        metadata: bankIdentifiers.metadata
      });

      await props.onSuccess();
      // Trigger config functions here
    } catch (err) {
      captureExceptionWithContext(err, {
        paymentId: paymentFees.paymentId,
        receiptEmail: props.email,
        signatoryName: props.signatory,
      });

      Alert.error(ERROR_MESSAGES.PayACHError, 0);
    }

    // Allow the payment button to be clicked again
    updateLock(false);
  }, [bankIdentifiers, paymentFees.paymentId, props, shouldLockPayments, updateLock]);

  const onClick = useCallback(async () => {
    if (bankIdentifiers) return await achPaymentMethod();
    return linkHandlerRef?.current?.open();
  }, [achPaymentMethod, bankIdentifiers]);

  return (
    <PaymentProcessorContainer>
      {/*
        Conditionally display some information for the user
          depending on whether or not they have completed
          the bank account verification via Plaid
      */}
      <>
        <PaymentInfoText>
          {bankIdentifiers ? verifiedAccountText : preVerificationText}
        </PaymentInfoText>

        {bankIdentifiers && (
          <Link as="p" onClick={() => linkHandlerRef?.current?.open()}>
            Use a different account instead
          </Link>
        )}
      </>

      <PaymentButton
        loading={shouldLockPayments}
        disabled={disabled}
        onClick={onClick}
        label={label}
      />
    </PaymentProcessorContainer>
  );
}
