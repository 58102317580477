import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import * as appSelectors from 'app/state/app/app-selectors';
import * as cs from 'common/common-styles';
import { emailRegexPattern } from 'common/constants';
import { LabelledUserInterface } from 'common/form-components';

const Header = styled(cs.HeaderText)`
  margin-bottom: 20px;
  padding-bottom: 0px;
`;

export function EmailInput({ config, email, setEmail }) {
  const cityInfo = useSelector(appSelectors.cityInfo);

  const onChange = useCallback((e) => setEmail(e.target.value), [setEmail]);

  const headerString = useMemo(
    () =>
      config?.alternateEmailSearchHeader ??
      `Let's get started by entering the email associated with your ${cityInfo.certificateVerbiage}:`,
    [cityInfo.certificateVerbiage, config?.alternateEmailSearchHeader]
  );

  return (
    <>
      <Header>{headerString}</Header>

      <LabelledUserInterface
        type="email"
        value={email}
        onChange={onChange}
        maxLength={100}
        pattern={emailRegexPattern}
        dataCy="email-input"
      />
    </>
  );
}
