import React, { useMemo } from 'react';
import styled from 'styled-components';
import { addObjects, convertNumberToCurrency, mapObject } from "../../utils/utility-functions";
import get from "lodash/get";
import isNumber from "lodash/isNumber";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { AccordionDetail } from "../../common/common-styles";
import { Tooltip as RSuiteTooltip, Whisper } from 'rsuite';

const VerticalLayoutRow = styled.div`
  display: flex;
`;

const VerticalLayoutLabel = styled.div`
  flex: 1;
`;

const VerticalLayoutValue = styled.div`
  width: 200px;
`;

const VerticalLayoutPeriodTitle = styled.div`
  font-weight: bold;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #ccc;
`;

const VerticalLayoutPeriodItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const VerticalLayoutPeriod = styled.div`
`;

const VerticalLayoutPeriods = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
`;

function KeyValuePairVerticalLayoutItem(props) {
  const { style, labelStyle, field, label, tooltip, source, periodData, taxableActivityTotals } = props;

  const sourceObject = source === 'taxableActivityTotals' ?
    taxableActivityTotals :
    source === 'groupedPeriodData' ?
      periodData.groupedPeriodData :
      periodData.summaryData;

  const value = convertNumberToCurrency(get(sourceObject, field));

  return (
    <StaticKeyValuePairVerticalLayoutItem
      style={style}
      labelStyle={labelStyle}
      label={label}
      tooltip={tooltip}
      value={value}
    />
  );
}

const Tooltip = styled(RSuiteTooltip)`
  .rs-tooltip-inner {
    text-align: left;
  }
`;

export function StaticKeyValuePairVerticalLayoutItem({ style, labelStyle, label, tooltip, value }) {
  return (
    <VerticalLayoutRow style={style}>
      <VerticalLayoutLabel style={labelStyle}>
        {label}
        {tooltip && (
          <Whisper trigger='hover' placement='right' speaker={<Tooltip>{tooltip}</Tooltip>}>
            <img src="/assets/icon-info.svg" alt="Info Icon" style={{ marginLeft: '5px' }} />
          </Whisper>
        )}
      </VerticalLayoutLabel>
      <VerticalLayoutValue>
        {value}
      </VerticalLayoutValue>
    </VerticalLayoutRow>
  )
}

function addGroupedPeriodData(groupedPeriodData1, groupedPeriodData2, groupField) {
  const groupedPeriodData2ByGroupFieldValue = groupedPeriodData2.reduce((acc, data) => ({
    ...acc,
    [data[groupField]]: data,
  }), {});

  return groupedPeriodData1.map(data => ({
    ...addObjects(data, groupedPeriodData2ByGroupFieldValue[data[groupField]]),
    [groupField]: data[groupField],
  }));
}

function aggregateGroupedPeriodData(groupPeriods, groupField, numPeriodsInChunk = 3) {
  const periodChunks = [];

  groupPeriods.forEach((periodData, idx) => {
    if (idx % numPeriodsInChunk === 0) {
      periodChunks.push([]);
    }

    periodChunks[periodChunks.length - 1].push(periodData);
  });

  return periodChunks.map(periods => {
    const aggregatedPeriods = periods.reduce(
      (acc, p) => ({
        ...acc,
        summaryData: addObjects(acc.summaryData, p.summaryData),
        groupedPeriodData: addGroupedPeriodData(acc.groupedPeriodData, p.groupedPeriodData, groupField)
      }), {
        periods,
        period: periods.map(p => p.period).join(", "),
        summaryData: mapObject(periods[0].summaryData, (key, value) => isNumber(value) ? 0 : value),
        groupedPeriodData: periods[0].groupedPeriodData.map(groupedDataItem => mapObject(groupedDataItem, (key, value) => isNumber(value) ? 0 : value ))
      }
    );

    aggregatedPeriods.summaryData.licenseReports = periods.flatMap(p => p.summaryData?.licenseReports || [])

    return aggregatedPeriods;
  })

}

function GroupDataVerticalLayoutItem({ labelStyle, groupValueField, periodData, groupField, groupDisplayConfigurationByGroupValue }) {
  return periodData.groupedPeriodData.map((groupItem) => {
    const groupFieldValue = groupItem[groupField];
    const groupDisplayConfig = groupDisplayConfigurationByGroupValue[groupFieldValue]

    return (
      <StaticKeyValuePairVerticalLayoutItem
        key={groupFieldValue}
        labelStyle={labelStyle}
        label={groupDisplayConfig?.label || groupFieldValue}
        tooltip={groupDisplayConfig?.tooltip}
        value={convertNumberToCurrency(groupItem[groupValueField])}
      />
    )
  });
}

const VERTICAL_LAYOUT_ITEM_TYPE_TO_COMPONENT = {
  'keyValue': KeyValuePairVerticalLayoutItem,
  'groupData': GroupDataVerticalLayoutItem,
};

function VerticalLayoutItem({ type, ...rest }) {
  const Component = VERTICAL_LAYOUT_ITEM_TYPE_TO_COMPONENT[type] || KeyValuePairVerticalLayoutItem;

  return <Component {...rest} />
}

function PeriodFeesList(props) {
  const { periodData, feeItems, groupField, hideHeader, groupDisplayConfigurationByGroupValue } = props;

  const taxableActivityTotals = useMemo(() => {
    const taxableActivities = periodData.summaryData.licenseReports?.flatMap(r => r.misc.taxableActivities).filter(Boolean) || [];


    return taxableActivities.reduce((acc, x) => addObjects(acc, x),
      mapObject(taxableActivities[0],(key, value) => isNumber(value) ? 0 : undefined))

  }, [periodData]);

  return (
    <VerticalLayoutPeriod>
      {!hideHeader && <VerticalLayoutPeriodTitle>{periodData.period}</VerticalLayoutPeriodTitle>}
      <VerticalLayoutPeriodItems>
        {feeItems.map((verticalLayoutItem, i) => (
          <VerticalLayoutItem
            key={i}
            {...verticalLayoutItem}
            groupField={groupField}
            periodData={periodData}
            taxableActivityTotals={taxableActivityTotals}
            groupDisplayConfigurationByGroupValue={groupDisplayConfigurationByGroupValue}
          />
        ))}
      </VerticalLayoutPeriodItems>
    </VerticalLayoutPeriod>
  )
}
export function FeesListGrouped({ groupPeriods, feeItems, groupField, groupDisplayConfigurationByGroupValue }) {
  return (
    <VerticalLayoutPeriods>
      {groupPeriods.map(periodData => (
        <PeriodFeesList
          key={periodData.period}
          periodData={periodData}
          feeItems={feeItems}
          groupField={groupField}
          groupDisplayConfigurationByGroupValue={groupDisplayConfigurationByGroupValue}
        />
      ))}
    </VerticalLayoutPeriods>
  );
}

const IndividualMonths = styled.div`
`;

const PeriodChunk = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export function FeesListGroupedAndChunked(props) {
  const {
    groupPeriods, // "tableData" from "FeesTableGrouped"
    feeItems,
    groupField,
    groupDisplayConfigurationByGroupValue,
  } = props;

  const periodChunks = useMemo(
    () => aggregateGroupedPeriodData(groupPeriods, groupField),
    [groupField, groupPeriods]
  );

  return (
    <VerticalLayoutPeriods>
      {periodChunks.map((periodChunk, i) => (
        <PeriodChunk key={i}>
          <PeriodFeesList
            periodData={periodChunk}
            feeItems={feeItems}
            groupField={groupField}
            groupDisplayConfigurationByGroupValue={groupDisplayConfigurationByGroupValue}
          />

          <IndividualMonths>
            <p>Individual months</p>
            {periodChunk.periods.map(periodData => (
              <Accordion key={periodData.period}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  {periodData.period}
                </AccordionSummary>
                <AccordionDetail>
                  <PeriodFeesList
                    key={periodData.period}
                    hideHeader
                    periodData={periodData}
                    feeItems={feeItems}
                    groupField={groupField}
                    groupDisplayConfigurationByGroupValue={groupDisplayConfigurationByGroupValue}
                  />
                </AccordionDetail>
              </Accordion>
            ))}
          </IndividualMonths>
        </PeriodChunk>
      ))}
    </VerticalLayoutPeriods>
  )

}
