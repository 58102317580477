import * as appSelectors from 'app/state/app/app-selectors';
import {
  DISABLE_PERMIT_REPORTING_HISTORY_PATH,
  LAST_12_MONTHS_OPTION,
  PROVIDER_MAP_PATH,
  TAX_SUMMARY_BREAKDOWN_MAP_PATH,
} from 'common/constants';
import { ReportingHistoryAccordionComponent } from 'configurable-form/components/reporting-history-accordion/reporting-history-accordion';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  filterByLast12Months,
  filterBySameYear,
} from 'utils/utility-functions';

import { ReportingHistoryFilter } from './reporting-history-filter';
import { ReportingHistoryLoader } from './reporting-history-loader';
import { Container } from './styles';

/**
 * This component handles rendering of the tax reporting history for the individual permit.
 * The data comes from "/reportingHistoryByApplicationNumber" API.
 * Configuration of how the data is shown to the user is dictated by the client JSON.
 * For an example, see "tax-breakdown-config.ts" from
 * "scripts/stats-src/places/co-san_miguel-town_of_telluride/portal/tax-breakdown-config.ts"
 */
export function SinglePermitReportingHistory(props) {
  const {
    paymentPeriods,
    fetchInflight,
    failedLicenseFetch,
    fetchLicenseData,
    applicationNumber,
    totPayable
  } = props;

  const [selectedYearOption, setSelectedYearOption] = useState(
    LAST_12_MONTHS_OPTION,
  );

  const hideDashboardPermitsDisplay = useSelector(
    appSelectors.getFromPortalStats(DISABLE_PERMIT_REPORTING_HISTORY_PATH),
  );

  const providerMap = useSelector(
    appSelectors.getFromPortalStats(PROVIDER_MAP_PATH),
  );

  const taxTypeBreakDownMap = useSelector(
    appSelectors.getFromPortalStats(TAX_SUMMARY_BREAKDOWN_MAP_PATH),
  );

  const filteredReportingHistory = useMemo(() => {
    if (!paymentPeriods) return null;

    const filterFn =
      selectedYearOption === LAST_12_MONTHS_OPTION
        ? filterByLast12Months
        : filterBySameYear;

    return paymentPeriods.filter((period) =>
      filterFn(period, selectedYearOption),
    );
  }, [paymentPeriods, selectedYearOption]);

  if (
    hideDashboardPermitsDisplay ||
    isNil(taxTypeBreakDownMap) ||
    isNil(providerMap)
  )
    return null;

  return (
    // Additional styling of "ReportingHistory" components occurs in "Container"
    <Container>
      <ReportingHistoryFilter
        data={paymentPeriods}
        selectedYearOption={selectedYearOption}
        setSelectedYearOption={setSelectedYearOption}
      />

      {isEmpty(filteredReportingHistory) ? (
        <ReportingHistoryLoader
          disabled={fetchInflight}
          onClick={fetchLicenseData}
          dataFetchHasFailed={failedLicenseFetch}
        />
      ) : (
        // TODO: Look at https://deckardtech.atlassian.net/browse/TECHDEBT-343
        <div
          style={{
            height: 'calc(100vh - 600px)',
            overflowY: 'scroll',
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            paddingRight: '10px',
          }}
        >
          <ReportingHistoryAccordionComponent
            filteredData={filteredReportingHistory}
            providerMap={providerMap}
            taxTypeBreakdownMap={taxTypeBreakDownMap}
            applicationNumber={applicationNumber}
            totPayable={totPayable}
          ></ReportingHistoryAccordionComponent>
        </div>
      )}
    </Container>
  );
}
