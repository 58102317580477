import { List, Map } from 'immutable';
import first from 'lodash/first';
import merge from 'lodash/merge';
import { errorActions } from 'app/state/error/error-slice';
import { filesActions } from 'app/state/files/files-slice';
import { ONE, ZERO } from 'common/constants';
import { captureExceptionWithContext } from './sentry-functions';
import { strRegistrationApiSender } from './str-registration-api-sender';
import { delay } from './utility-functions';

class FileUploadFailureError extends Error {
  constructor(message) {
    super(message);
    this.name = 'FileUploadFailureError';
  }
}

const filesString = 'files';
const MAX_FILES = 20;
const completeProgress = 95;

/**
 * Recursively counts the number of files to be uploaded
 *
 * @param {Object} store the file store
 * @param {String} key the part of the file store to get files from
 */
function countTotalFiles(store, key) {
  const sub = store?.[key] || store;

  if (typeof sub === 'string') return ZERO;
  if (Array.isArray(sub)) return sub.length;
  else return Object.keys(sub).reduce((acc, curr) => acc + countTotalFiles(sub, curr), 0);
}

async function getUploadToken(applicationNumber, documentType, apiReqParams = {}) {
  const { data: uploadToken } = await strRegistrationApiSender.get(
    `/getUploadToken/${applicationNumber}`,
    merge({ document_type: documentType }, apiReqParams),
  );

  return uploadToken;
}

/**
 * Files uploaded via "FileUpload" component are stored in the following schema:
 *  This obj/dict is Map from Immutable
 *
 *  {
 *    "fileKey": List (from Immutable)
 *  }
 *
 * Files uploaded via "MultiFileUpload" component are stored in the following schema:
 *  This obj/dict is Map from Immutable
 *
 *  {
 *    "fileKey": {
 *      "storageKey_1": List,
 *      "storageKey_2": List,
 *      ...
 *    }
 *  }
 */
class FilesManager {
  constructor() {
    this.fileStore = new Map();
  }

  get getProofs() { return this.fileStore; }
  get hasFiles() { return Boolean(this.fileStore.size); }
  get countTotalNumberOfFiles() { return countTotalFiles(this.fileStore.toJS()); }

  clearProofs() { this.fileStore = new Map(); }

  updateStore({ uploadKey, storageKey, files }) {
    const values = storageKey ? new Map({ uploadKey, files }) : files;

    this.fileStore = storageKey ?
      this.fileStore.setIn([uploadKey, storageKey], values) :
      this.fileStore.set(uploadKey, values);
  }

  /**
   * Method for a deleting a certain part of the file store
   *
   * {
   *    "first": [], <--- Deleted, if only "uploadKey" is provided
   *    "second": {  <--- Deleted, if either "a" or "b" are deleted and there are no other keys
   *      "a": {}    <--- Deleted, if "uploadKey" and "storageKey" are provided
   *      "b": {}    <--- Deleted, if "uploadKey" and "storageKey" are provided
   *    }
   * }
   *
   * @param {String} args.uploadKey The key to the where the files should be stored
   * @param {String} args.storageKey The key to the where the files should be stored (storage key)
   */
  deleteFromStore({ uploadKey, storageKey }) {
    let newStore = storageKey ?
      this.fileStore.deleteIn([uploadKey, storageKey]) :
      this.fileStore.delete(uploadKey);

    /**
     * Deletes the parent Map of a "MultiFileUpload" file key, when there
     *  are no files for any of the storage keys used
     */
    const shouldDeleteMap = newStore.get(uploadKey)?.size === ZERO;
    if (storageKey && shouldDeleteMap) newStore = this.fileStore.delete(uploadKey);

    return newStore;
  }

  /**
   * Method for retrieving files from a certain part of the file store
   *
   * @param {String} args.uploadKey The key to the where the files should be stored
   * @param {String} args.storageKey The key to the where the files should be stored (storage key)
   */
  retrieveFromStore({ uploadKey, storageKey }) {
    return storageKey ?
      this.fileStore.getIn([uploadKey, storageKey, filesString]) :
      this.fileStore.get(uploadKey)
  }

  retrieveListOfFilesFromStore(key) {
    const store = this.fileStore.toJS();

    if (Array.isArray(store?.[key])) return store?.[key];
    else {
      const files = [];
      const nestedStore = store?.[key];

      if (!nestedStore) return files;
      Object.keys(nestedStore).forEach((nest) => { files.push(...nestedStore?.[nest]?.[filesString]); });

      return files;
    }
  }

  /**
   * Method for counting the number of files for a certain part of the file store
   *
   * @param {String} args.uploadKey The key to the where the files should be stored
   * @param {String} args.storageKey The key to the where the files should be stored (storage key)
   */
  countFilesOfType({ uploadKey, storageKey }) {
    const docsOfType = this.retrieveFromStore({ uploadKey, storageKey });
    return docsOfType?.size || ZERO;
  }

  /**
   * Method for adding files to the FileManager store
   *
   * @param {[File]} args.files An array of files to be added to the file store
   * @param {String} args.uploadKey The key to the where the files should be stored
   * @param {String} args.storageKey The key to the where the files should be stored (storage key)
   */
  addDocumentToStore({ files, uploadKey, storageKey }) {
    const filesOfTypeAlreadyUploaded = this.retrieveListOfFilesFromStore(uploadKey).length;

    if (
      files.length > MAX_FILES ||
      filesOfTypeAlreadyUploaded + files.length > MAX_FILES
    ) throw new Error('Exceeded limit of files that can be uploaded');

    const storedFiles = this.retrieveFromStore({ uploadKey, storageKey });

    /**
     * Check if the list has any files:
     *  1. If there are files, then append new files to the existing list
     *  2. If there are no files, then make a new list with the new files
     */
    const fileList = storedFiles?.size ? storedFiles.push(...files) : List(files);
    this.updateStore({ uploadKey, storageKey, files: fileList });

    return fileList.size;
  }


  /**
   * Method for removing files from the FileManager store
   *
   * @param {String} args.uploadKey The key to the where the files should be stored
   * @param {String} args.storageKey The key to the where the files should be stored (storage key)
   * @param {Number} args.index The index of the file to be removed from it's part of the store
   */
  removeDocumentFromStore({ uploadKey, storageKey, index }) {
    const storedFiles = this.retrieveFromStore({ uploadKey, storageKey });
    const updatedFileList = storedFiles.delete(index);

    const isListEmpty = updatedFileList?.size === ZERO;

    if (isListEmpty) this.fileStore = this.deleteFromStore({ uploadKey, storageKey });
    else this.updateStore({ uploadKey, storageKey, files: updatedFileList });

    return updatedFileList?.size;
  }

  /**
   * Config:
   *
   *  {
   *    "method": "uploadFiles",
   *    "params": { "documents": ["proof_of_ownership", "proof_of_residence"] }
   *  }
   *
   * @param {String} applicationNumber The application number for the application
   * @param {[String]} docTypes Documents to be uploaded received from strategy config (See above)
   * @param {Function} dispatch Redux dispatch function received from BaseStrategy
   */
  async startUpload(applicationNumber, docTypes, dispatch, apiReqParams) {
    const uploadTokenMap = {};
    const totalNumFiles = this.countTotalNumberOfFiles;
    let numOfFilesUploaded = ZERO;

    try {
      if (totalNumFiles) {
        dispatch(filesActions.setIsUploading({ isUploading: true }));

        // Upload first file of each type
        const firstTypeFilesUpload = docTypes.map(async (type) => {
          const firstFileOfType = first(this.retrieveListOfFilesFromStore(type));
          if (firstFileOfType) {
            const uploadToken = await getUploadToken(applicationNumber, type, apiReqParams);
            uploadTokenMap[type] = uploadToken;

            try {
              await strRegistrationApiSender.upload({ uploadToken, file: firstFileOfType });
              dispatch(filesActions.setUploadProgress({ uploadProgress: (++numOfFilesUploaded / totalNumFiles) * completeProgress }));
            } catch (err) {
              captureExceptionWithContext(
                new FileUploadFailureError(err),
                { applicationNumber, fileType: type, fileName: firstFileOfType.name, error: err.message }
              );

              dispatch(filesActions.setHasUploadFailed(true));
              dispatch(errorActions.setAlternateErrorString(`
                There was an issue uploading ${firstFileOfType.name}.
                Please try again, if this is a reoccurring issue then please use another file that follows the specifications.
              `));
              throw err;
            }
          }
        });

        await Promise.all(firstTypeFilesUpload);
        await delay(3000); // Wait for Digify actions to finish or else the back end processes will have to race against the upload

        /**
         * Upload remaining files of each type
         * Files will be uploaded in parallel in batches of a singular type
         */
        for (const docType of docTypes) {
          const docsFromStore = this.retrieveListOfFilesFromStore(docType) || [];
          // The first was already uploaded earlier in the process.
          const allDocumentsOfTypeExceptFirst = docsFromStore.slice(ONE);

          const uploadToken = uploadTokenMap[docType];

          if (allDocumentsOfTypeExceptFirst.length) {
            const remainingUploads = allDocumentsOfTypeExceptFirst.map(async (file) => { // eslint-disable-line no-loop-func
              try {
                await strRegistrationApiSender.upload({ uploadToken, file });
                dispatch(filesActions.setUploadProgress({ uploadProgress: (++numOfFilesUploaded / totalNumFiles) * completeProgress }));
              } catch (err) {
                captureExceptionWithContext(
                  new FileUploadFailureError(err),
                  { applicationNumber, fileType: docType, fileName: file.name, error: err.message }
                );

                dispatch(filesActions.setHasUploadFailed(true));
                dispatch(errorActions.setAlternateErrorString(`
                  There was an issue uploading ${file.name}.
                  Please try again, if this is a reoccurring issue then please use another file that follows the upload specifications.
                `));
                throw err;
              }
            });

            // Upload all remaining files in parallel
            await Promise.all(remainingUploads);
          }
        }

        dispatch(filesActions.setUploadProgress({ uploadProgress: completeProgress }));
      }
    } catch (err) {
      // captureExceptionWithContext(err, { applicationNumber, documents: docTypes, });
      dispatch(errorActions.setHasErrored(true));
      throw err;
    } finally {
      dispatch(filesActions.resetUploadingStateAndProgress());
    }
  }
}

export const filesManager = new FilesManager();

window.FM = filesManager;
