import React from 'react';
import { Grid } from './styles';
import {
  DaysAvailableHeader,
  DaysOccupiedHeader,
  ReportRevenueInputHeader,
  TaxableReceiptsHeader,
} from '../input-fields/headers';
import { CoreReportingFields } from "../../constants";
import { isCoreColumn } from "../../utils";

export function TOTPaymentRowHeader({ reportingColumns, daysInPeriod }) {
  const coreColumnFieldToHeader = {
    [CoreReportingFields.TAXABLE_RECEIPTS_FIELD]: <TaxableReceiptsHeader />,
    [CoreReportingFields.NUM_DAYS_AVAILABLE_FIELD]: <DaysAvailableHeader daysInPeriod={daysInPeriod} />,
    [CoreReportingFields.NUM_DAYS_OCCUPIED_FIELD]: <DaysOccupiedHeader />,
  };

  return (
    <Grid>
      {reportingColumns.map((col, idx) => (
        <React.Fragment key={idx}>
          {isCoreColumn(col) ? (
            coreColumnFieldToHeader[col.field]
          ) : (
            <ReportRevenueInputHeader key={idx} text={col.label} />
          )}
        </React.Fragment>
      ))}
    </Grid>
  );
}
