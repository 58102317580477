import { useDispatch, useSelector } from "react-redux";
import * as networkSelectors from "app/state/network/network-selectors";
import * as paymentSelectors from "app/state/payment/payment-selectors";
import * as appSelectors from "app/state/app/app-selectors";
import { useCallback, useMemo } from "react";
import { paymentFeeFactory } from "utils/checkout-utils";
import { convertNumberToCurrency } from "utils/utility-functions";
import { paymentActions } from "app/state/payment/payment-slice";
import { captureExceptionWithContext } from "utils/sentry-functions";

export function usePaymentUIState({ email, signatory }) {
  const dispatch = useDispatch();

  const paymentFees = useSelector(networkSelectors.paymentFees);

  const shouldLockPayments = useSelector(paymentSelectors.shouldLockPayments);

  const activePaymentMethodTab = useSelector(appSelectors.activePaymentMethodTab);
  // TODO: DECK-7991 This calculation is performed in multiple places
  const fees = useMemo(
    () => (paymentFees ? paymentFeeFactory(activePaymentMethodTab, paymentFees) : null),
    [activePaymentMethodTab, paymentFees]
  );

  const label = useMemo(() => `Pay ${convertNumberToCurrency(fees?.total?.value)}`,
    [fees]);

  const updateLock = useCallback((status) => {
    dispatch(paymentActions.setShouldLockPayments(status));
  }, [dispatch]);

  const capturePaymentException = useCallback((err, context) => {
    captureExceptionWithContext(err, {
      paymentId: paymentFees.paymentId,
      receiptEmail: email,
      signatoryName: signatory,
      ...context
    });
  }, [email, paymentFees.paymentId, signatory]);

  const runWithUILockAndExceptionHandling = useCallback(async (runnable) => {
    if (shouldLockPayments) return;
    updateLock(true);
    try {
      await runnable();
    } catch (err) {
      console.error(err);
      capturePaymentException(err);
    }

    updateLock(false);
  }, [capturePaymentException, shouldLockPayments, updateLock]);

  return {
    paymentFees,
    shouldLockPayments,
    activePaymentMethodTab,
    label,
    updateLock,
    fees,
    capturePaymentException,
    runWithUILockAndExceptionHandling,
  }
}
