import * as appSelectors from 'app/state/app/app-selectors';
import { appActions } from 'app/state/app/app-slice';
import { payloadActions } from 'app/state/payload/payload-slice';
import * as cs from 'common/common-styles';
import { FLOWS } from 'common/constants';
import { PropertySearch } from 'pages/flow-entry/property-search/property-search';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { APPLY_STR_PATHS, FLOW_PATHS } from 'routes/constants';
import { ConfigurableRoutes } from 'routes/custom/configurable-routes';
import { withFlowEntry } from 'routes/custom/entry-exit-wrappers';
import { PortalAuthMidFlowRoute } from 'routes/custom/portal-auth-mid-flow-route';
import { ApplyLicenseStrategy } from 'strategies/apply-license-strategy';

const PropertySearchHOC = withFlowEntry(PropertySearch);

const Div = (props) => <div>{props.children}</div>;

export function ApplyStrLicensesRoutes(props) {
  const dispatch = useDispatch();

  const useCustomerPortal = useSelector(appSelectors.useCustomerPortal);

  const flows = useSelector(appSelectors.flows);
  const activeFlow = useSelector(appSelectors.activeFlow);

  const currentFlow = flows?.[FLOWS.APPLY_LICENSE];

  useEffect(() => {
    dispatch(appActions.setActiveFlow(FLOWS.APPLY_LICENSE));

    if (currentFlow?.earlyInit) {
      dispatch(
        payloadActions.updateFormikSnapshot(
          flows?.[FLOWS.APPLY_LICENSE]?.initialFormikValues,
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  // Must wait for the active flow to be set so all selectors specific to this flow work
  if (!activeFlow) return null;

  const skipInitOnSpecialPages = Boolean(currentFlow?.earlyInit);
  const View = useCustomerPortal ? Div : cs.ViewContainer;
  const PropertySearchRoute = useCustomerPortal
    ? PortalAuthMidFlowRoute
    : Route;

  return (
    <View>
      <Switch>
        <Route exact path={FLOW_PATHS.applyStrLicense}>
          <Redirect
            to={`${FLOW_PATHS.applyStrLicense}/${flows.applyLicense.entrySubPath}`}
          />
        </Route>

        <PropertySearchRoute exact path={APPLY_STR_PATHS.property}>
          <PropertySearchHOC
            reset={props.resetFlow}
            init={
              !skipInitOnSpecialPages &&
              flows?.applyLicense?.initialFormikValues
            }
            exitWithoutReset={props.exitWithoutReset}
          />
        </PropertySearchRoute>

        <ConfigurableRoutes
          configurablePages={flows?.applyLicense?.configurablePages}
          strategyInstance={ApplyLicenseStrategy.getInstance()}
        />
      </Switch>
    </View>
  );
}
