import * as appSelectors from 'app/state/app/app-selectors';
import {
  SubmittedSuccessfullyParagraph,
  ThankYouWrapper,
} from 'common/success-view-styles';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { parseHTMLString } from 'utils/utility-functions';
import { useTemplatedMessages } from "./hooks/use-templates-messages";

const ThankYou = styled(ThankYouWrapper)`
  width: 100%;
  margin-bottom: 20px;
`;

export function DefaultSuccessRender({
  extraTemplateTokensToValues = {},
  info,
  header,
  message,
}) {
  const templatedMessages = useTemplatedMessages({
    message,
    extraTemplateTokensToValues,
    info
  });

  const useCustomerPortal = useSelector(appSelectors.useCustomerPortal);

  return (
    <>
      {Boolean(header) && <ThankYou>{header}</ThankYou>}

      <SubmittedSuccessfullyParagraph useCustomerPortal={useCustomerPortal} data-cy='success-paragraph'>
        {parseHTMLString(templatedMessages)}
      </SubmittedSuccessfullyParagraph>
    </>
  );
}
