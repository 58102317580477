import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Container } from 'semantic-ui-react';
import * as appSelectors from 'app/state/app/app-selectors';
import * as cs from 'common/common-styles';
import { parseHTMLString } from 'utils/utility-functions';

const FooterWrapper = styled(Container)`
  ${cs.textBase}
  font-size: 14px;
  line-height: 1.5;
  margin-top: 33px;

  a {
    ${cs.linkCss}
  }
`;

export function Footer() {
  const stats = useSelector(appSelectors.stats);

  const footerText = stats?.app?.footerTextHtml;

  return (
    <FooterWrapper>
      {parseHTMLString(footerText)}
    </FooterWrapper>
  );
}

