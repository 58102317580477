import * as appSelectors from 'app/state/app/app-selectors';
import { BackButton, ContinueButton } from 'common/navigation-buttons';
import { useFormikContext } from 'formik';
import { usePortalAuth } from 'login/hooks/use-portal-auth';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { PORTAL_ACCOUNT_CREATION_SLUGS, PORTAL_PATHS } from 'routes/constants';
import { history } from 'routes/history';
import styled from 'styled-components';

import { renderFields } from './helpers';

const ACCOUNT_DETAILS_FIELDS_PATHS = 'accountManagement.accountDetailsFields';

const Flex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 700px;
`;

export function AccountDetails(props) {
  const { values } = useFormikContext();

  const { params } = useRouteMatch(PORTAL_PATHS.createAccountSlugged);

  const { userSignOut } = usePortalAuth();

  const fields = useSelector(
    appSelectors.getFromPortalStats(ACCOUNT_DETAILS_FIELDS_PATHS),
  );

  useEffect(() => {
    (async () => {
      await userSignOut();

      // Redirect the user back to the first step of the account creation process
      if (
        !values.email &&
        params.slug === PORTAL_ACCOUNT_CREATION_SLUGS.account
      ) {
        history.push(PORTAL_PATHS.createAccountEmail);
      }
    })();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div>{fields.map(renderFields)}</div>
      <Flex>
        <BackButton />
        <ContinueButton loading={props.loading} />
      </Flex>
    </>
  );
}
