import { createSlice } from '@reduxjs/toolkit';
import { DEFAULT_FORM_VALUE as emptyString } from 'common/constants';
import merge from 'lodash/merge';

const INIT_STATE = {
  // Email specifically for the email entered in input in update flow
  lookupModeEmail: emptyString,
  addressLookupError: emptyString,
  license: null,
  licenses: [],
  // Information that is not directly related to the owner and authorized agent
  additionalInfo: {},
  // Pre-populate this to display the first input field for the user
  listingUrls: [emptyString],
  messageInput: emptyString,
  // values entered in formik forms
  formikSnapshot: {}
};

export const payloadSlice = createSlice({
  name: 'payload',
  initialState: INIT_STATE,
  reducers: {
    setLicense: (state, { payload: license }) => {
      state.license = license;
    },

    setLicenses: (state, { payload: licenses }) => {
      state.licenses = licenses;
    },

    /**
     * Workaround to solve the useEffect in login-modal being called
     *    multiple times which is to use a different email variable
     *    There will be 2 email variables:
     *      1. The email the user enters in the verification modal
     *      2. The email the user enters in the input for update flow
     */
    setLookupModeEmail: (state, { payload }) => {
      state.lookupModeEmail = payload;
    },

    setAdditionalInfo: (state, { payload }) => {
      state.additionalInfo = payload;
    },

    updateAdditionalInfo: (state, { payload }) => {
      state.additionalInfo = merge({}, state.additionalInfo, payload);
    },

    setAddressLookupError: (state, { payload: { text } }) => {
      state.addressLookupError = text;
    },

    setListingUrls: (state, { payload }) => {
      state.listingUrls = payload;
    },

    updateMessageInput: (state, { payload }) => {
      state.messageInput = payload;
    },

    setFormikSnapshot: (state, { payload }) => {
      const { values = {} } = payload;
      state.formikSnapshot = { ...values, _preventAutoSave: true };
    },

    autoSaveFormikSnapshot: (state, { payload }) => {
      const { values = {} } = payload;
      state.formikSnapshot = values;
    },

    updateFormikSnapshot: (state, { payload }) => {
      state.formikSnapshot = { ...state.formikSnapshot, ...payload, _preventAutoSave: true };
    },

    mergeFormikSnapshot: (state, { payload }) => {
      state.formikSnapshot =  merge({}, state.formikSnapshot, payload, { _preventAutoSave: true });
    },

    mergeCopyFromReduxToFormikSnapshot: (state, { payload: { resultKey, reduxValue } }) => {
      state.formikSnapshot =  merge({}, state.formikSnapshot, { [resultKey]: reduxValue }, { _preventAutoSave: true }); 
    },
    
    resetFlow: () => INIT_STATE,
  },
});

export const payloadActions = payloadSlice.actions;

export const payloadReducer = payloadSlice.reducer;
