import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import * as appSelector from 'app/state/app/app-selectors';
import * as cs from 'common/common-styles';
import { EmailList } from 'common/email-list';

const Img = styled.img`
  width: 500px;
  height: 300px;
  margin-left: auto;
  margin-right: 340px;
  margin-top: 60px;
  display: block;
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 150px;
  padding-left: 200px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1680px;

  @media screen and (max-width: 850px) {
    padding-left: 25px;
    padding-right: 25px;

    ${Img} {
      width: 300px;
      height: 200px;
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

const H1 = styled.h1`
  margin-top: 85px;
  font-family: Lato;
  font-size: 50px;
  line-height: 1.2;
  color: #5c5d5d;
`;

const H3 = styled.h3`
  font-family: Lato;
  margin-top: 40px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.29;
  color: #000000;
`;

const Text = styled.div`
  font-family: Lato;
  font-size: 14px;
  line-height: 1.5;
  color: #5c5d5d;
`;

export function Help() {
  const cityInfo = useSelector(appSelector.cityInfo);

  return (
    <Wrapper>
      <H1>Contact Us</H1>

      <H3>
        For questions relating to {cityInfo?.certificateVerbiage ?? 'short-term rentals'}, please contact {cityInfo?.name}:
      </H3>

      <Text>
        <br />

        Visit our website at&nbsp;
        <cs.Link href={cityInfo?.website} target='_blank' rel='noreferrer'>
          {cityInfo?.website}
        </cs.Link>
        <br />

        {cityInfo?.contactEmail && (
          <>
            Email us at&nbsp;
            <EmailList emails={cityInfo?.contactEmail} />
            <br />
          </>
        )}

        {cityInfo?.phoneNumber && (
          <div>
            {/* Our phone number is <span style={{ color: '#027680' }}>{cityInfo?.phoneNumber}</span> */}
            {'Our phone number is '}
            <cs.Link href={`tel:${cityInfo?.phoneNumber}`} target='_blank'>
              {cityInfo?.phoneNumber}
            </cs.Link>
          </div>
        )}
      </Text>

      <H3>For technical support:</H3>
      <br />

      <Text>
        {cityInfo?.contactEmail && (
          <div>
            To update the information associated with your {cityInfo?.certificateVerbiage},
            please email&nbsp;
            <EmailList emails={cityInfo?.contactEmail} />
            <br/>
          </div>
        )}
        {cityInfo?.technicalEmail && (
          <div>
            For support using this website, please email&nbsp;
            <EmailList emails={cityInfo?.technicalEmail} />
            <br/>
          </div>
        )}

        For personal information, opt-out, or deletion requests please contact {cityInfo?.name}.
        <br />

      </Text>
      <Img src="/assets/help-page-background.svg" />
    </Wrapper>
  );
}
