import { createSelector } from '@reduxjs/toolkit';

export const files = (state) => state.files;

export const hasAnyFileUploadFailed = createSelector(
  files,
  (filesState) => filesState?.hasAnyFileUploadFailed,
);

export const isUploading = createSelector(
  files,
  (filesState) => filesState?.isUploading,
);

export const uploadProgress = createSelector(
  files,
  (filesState) => filesState?.uploadProgress,
);

export const uploadedDocumentTypesMap = createSelector(
  files,
  (filesState) => filesState?.uploadedDocumentTypesMap,
);
