import * as dashboardSelectors from 'app/state/dashboard/dashboard-selectors';
import { dashboardActions } from 'app/state/dashboard/dashboard-slice';
import { BoldedLabel, DarkModal } from 'common/common-styles';
import { TOT_PAYMENT_STATUS_INCOMPLETE } from 'common/constants';
import { WhiteButton } from 'common/navigation-buttons';
import { StyledTab, StyledTabs } from 'common/styled-tabs';
import { renderFieldComponent } from 'configurable-form';
import { DataTable } from 'configurable-form/components/data-table/data-table';
import { Navigation } from 'configurable-form/shared/navigation';
import dayjs from 'dayjs';
import { useFormikContext } from 'formik';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import set from 'lodash/set';
import { extendFormikPath } from 'pages/pay-flow/report-revenue-page/utils';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { Button, Checkbox, Icon } from 'semantic-ui-react';
import { PayTotStrategy } from 'strategies/pay-tot-strategy';
import styled from 'styled-components';

const TaxPeriodWrapper = styled.div`
  margin-bottom: 40px;
`;

const TaxPeriodHeading = styled.div`
  font-weight: bold;
  font-size: 16px;
`;

const ReportingWindow = styled.div`
  width: 540px;
  background: white;
  border-radius: 5px;
`;

const ReportingHeaderSection = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 15px;
  display: flex;
  justify-content: space-between;
`;

const ReportingBodySection = styled.div`
  padding: 15px;
`;

const ModalHeader = styled(BoldedLabel)`
  font-weight: bold;
  font-size: 18px;
`;

const MonthLayout = styled.div`
  margin-top: 15px;
  display: inline-grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 1fr);
  row-gap: 10px;
  column-gap: 60px;
`;

const ReportingFooterSection = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 25px;
  padding: 15px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
`;

export function BulkTaxSelectProperties(props) {
  const { layout, strategies, standardFields, useCheckbox, useFilter, taxVerbiage } = props;

  const dispatch = useDispatch();

  /**
   * We don't need to check for the fetch status, because this data is only used
   * when the user is entering the bulk tax flow from the permits list view.
   *
   * The permits list view requires that the data fetch to be completed before
   * allowing user to perform any and all individual and bulk actions.
   */
  const taxableLicensePeriods = useSelector(
    dashboardSelectors.taxableLicensePeriods,
  );

  const preSelectedTaxableLicensePeriod = useSelector(
    dashboardSelectors.preSelectedTaxableLicensePeriod,
  );

  const [data, setData] = useState(
    isNil(taxableLicensePeriods)
      ? []
      : taxableLicensePeriods.data.paymentPeriods,
  );

  // Contains periods which have at least one selected property (only includes the selected properties)
  const selectedData = useMemo(() =>
    data.filter(month =>
      month.licenses.some(license => license.selected)
    ).map(month => ({
      ...month,
      licenses: month.licenses.filter(license => license.selected).map(license => ({
        ...license
      }))
    })), [data]);

  const [open, setOpen] = useState(false); // Controls display for additional payment period selection modal

  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [availablePeriodsForYear, setAvailablePeriodsForYear] = useState([]);

  const { setFieldValue, values, setValues } = useFormikContext();

  useEffect(() => {
    GenerateReportingPeriods();
    //todo dont like this, but otherwise formik values do not seem to be updated the first time GenerateReportingPeriods is run
  }); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // Clear any previously selecttions
    dispatch(dashboardActions.setLicensesToPayTaxOn(null));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  const yearsArray = useMemo(() => {
    return get(values, 'selectedTaxablePeriods.yearsArray')
  }, [values]);

  const taxablePeriodsGroupedMonths = useMemo(() => {
    return get(values, 'selectedTaxablePeriods.groupedMonths')
  }, [values]);


  const selectedPeriods = useMemo(() => {
    return Object.entries(taxablePeriodsGroupedMonths).flatMap(([year, periods]) =>
      periods.filter(period => period.selected).map(period => ({
        startDate: period.startDate,
        endDate: period.endDate,
        label: period.label + ' ' + year
      }))
    ).sort((a, b) => new Date(b.endDate) - new Date(a.endDate)); // Sort by end date in descending order
  }, [taxablePeriodsGroupedMonths]);

  // ------------------------------------------------------
  // UI States
  // ------------------------------------------------------

  const [loading, setLoading] = useState(false);

  const isNavigationDisabled = useMemo(
    () => loading || isEmpty(selectedData) || isEmpty(data),
    [data, loading, selectedData],
  );

  const findPeriodByDates = (periods, startDate, endDate) => {
    return periods.find(period => period.startDate === startDate && period.endDate === endDate);
  };

  const toggleSelectedPeriod = (period) => {

    const deselectAllLicensesInPeriod = (period) => {
      setData(prevData => {
        return prevData.map(taxPeriod => {
          if (dayjs(taxPeriod.startDate).isSame(dayjs(period.startDate)) && dayjs(taxPeriod.endDate).isSame(dayjs(period.endDate))) {
            return {
              ...taxPeriod,
              licenses: taxPeriod.licenses.map(license => ({ ...license, selected: false }))
            };
          }
          return taxPeriod;
        });
      });
    };

    const preselectLicensesInPeriod = (period) => {
      setData(prevData => {
        return prevData.map(taxPeriod => {
          if (dayjs(taxPeriod.startDate).isSame(dayjs(period.startDate)) && dayjs(taxPeriod.endDate).isSame(dayjs(period.endDate))) {
            const updatedLicenses = taxPeriod.licenses.map(license => ({
              ...license,
              selected: license.totPaidStatus === TOT_PAYMENT_STATUS_INCOMPLETE
            }));
            return {
              ...taxPeriod,
              licenses: updatedLicenses
            };
          }
          return taxPeriod;
        });
      });
    };

    // Update the selected property of the period
    const newValues = cloneDeep(taxablePeriodsGroupedMonths);
    const clickedPeriod = findPeriodByDates(newValues[selectedYear], period.startDate, period.endDate);
    clickedPeriod.selected = !clickedPeriod.selected;

    setFieldValue(extendFormikPath('selectedTaxablePeriods', 'groupedMonths'), newValues);

    if (!clickedPeriod.selected) {
      deselectAllLicensesInPeriod(period);
    } else {
      preselectLicensesInPeriod(period);
    }
  }

  const isPeriodChecked = (period) => {
    const matchedPeriod = findPeriodByDates(taxablePeriodsGroupedMonths[selectedYear], period.startDate, period.endDate);
    if (matchedPeriod) {
      return matchedPeriod.selected;
    }
    else {
      return false;
    }
  }

  const GenerateReportingPeriods = useCallback(() => {
    if (yearsArray.length === 0) {
      const currentDate = dayjs();

      // Generate the list of the last 36 months, not including the current month
      const months = [];
      for (let i = 1; i < 36; i++) {
        const date = currentDate.subtract(i, 'month');
        months.push(date);
      }

      // Group the months by year
      const groupedMonths = months.reduce((acc, month) => {
        const year = month.year();
        const monthName = month.format('MMMM');
        const lastDayOfMonth = month.endOf('month').date();
        const monthNumber = month.format('MM'); // Ensure two digits
        const startDate = `${year}-${monthNumber}-01`;
        const endDate = `${year}-${monthNumber}-${lastDayOfMonth}`;
        if (!acc[year]) {
          acc[year] = [];
        }

        // Determine if the period should be selcted
        // Should be selected on load if the pressed the pay tax button this period on the dashboard
        const startDateMatch = dayjs(startDate).isSame(dayjs(preSelectedTaxableLicensePeriod?.payload?.startDate));
        const endDateMatch = dayjs(endDate).isSame(dayjs(preSelectedTaxableLicensePeriod?.payload?.endDate));
        const selected = startDateMatch && endDateMatch;

        acc[year].unshift({ label: monthName, startDate: startDate, endDate: endDate, selected: selected }); // prepend to maintain order
        return acc;
      }, {});

      // Get all years as an array
      const yearsArray = Object.keys(groupedMonths).map(Number).sort((a, b) => b - a);

      const newValues = cloneDeep(values);
      set(newValues, extendFormikPath('selectedTaxablePeriods', 'groupedMonths'), groupedMonths);
      set(newValues, extendFormikPath('selectedTaxablePeriods', 'yearsArray'), yearsArray);
      setValues(newValues, false);
    }
  }, [values, yearsArray, preSelectedTaxableLicensePeriod, setValues])

  useEffect(() => {
    const filteredData = taxablePeriodsGroupedMonths[selectedYear];
    setAvailablePeriodsForYear(filteredData);
  }, [selectedYear, yearsArray, taxablePeriodsGroupedMonths]);

  const handleYearSelect = useCallback((e, v) => { setSelectedYear(v); }, []);

  const executeStrategy = useCallback((entryStrategy) => {
    if (!entryStrategy)
      throw new Error(
        'No strategy defined for executeStrategy - BulkTaxSelectProperties',
      );
    PayTotStrategy.getInstance().executeStrategy(
      strategies,
      entryStrategy,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const handleBulkTax = useCallback(async () => {
    setLoading(true);
    batch(() => {
      dispatch(dashboardActions.setPreSelectedTaxableLicensePeriod(undefined));
      dispatch(dashboardActions.setLicensesToPayTaxOn(selectedData));
    })
    setLoading(false);
    executeStrategy('onContinueClick');

  }, [
    dispatch,
    executeStrategy,
    selectedData
  ]);

  const handleContinueClick = useCallback(async () => {
    // Can only continue if one or more properties have been selected
    if (isNil(selectedData) || !selectedData.length) return;
    return await handleBulkTax();
  }, [handleBulkTax, selectedData]);


  const upDateSelectedLicenseForPeriod = (periodStartDate, periodEndDate, licenses) => {
    setData(prevData => {
      return prevData.map(period => {
        if (period.startDate === periodStartDate && period.endDate === periodEndDate) {
          return { ...period, licenses: licenses };
        }
        return period;
      });
    });
  }
  return (
    <div>
      {standardFields.map((f, i) =>
        renderFieldComponent(f, `btPropertySelectionStandardHeaders${i}`),
      )}

      {
        selectedPeriods.map((paymentPeriod, index) => {
          const taxPeriod = data.find(taxPeriod => taxPeriod.startDate === paymentPeriod.startDate && taxPeriod.endDate === paymentPeriod.endDate);
          return (
            <TaxPeriodWrapper key={`paymentPeriod_${index}`}>
              <TaxPeriodHeading>{paymentPeriod.label}</TaxPeriodHeading>
              {
                taxPeriod?.licenses.length ?
                  <DataTable
                    key={index}
                    useCheckboxes={useCheckbox}
                    useFilter={useFilter}
                    data={taxPeriod.licenses}
                    updateData={(licenses) => upDateSelectedLicenseForPeriod(paymentPeriod.startDate, paymentPeriod.endDate, licenses)}
                    layout={layout}
                  /> :
                  <div>There are no properties to report {taxVerbiage} on for this time period.</div>
              }
            </TaxPeriodWrapper>
          );
        })
      }

      <Button
        type="button"
        onClick={() => {
          setOpen(true);
        }}
        style={{ color: "#5c5d5d", backgroundColor: "#e0e1e2" }}
        data-cy="report-revenue-add-additional-months"
      >
        <Icon name='add' />
        <span>Add additional periods</span>
      </Button>

      <DarkModal open={open} onClose={() => setOpen(false)}>
        <ReportingWindow>
          <ReportingHeaderSection>
            <ModalHeader>Report Additional {taxVerbiage}</ModalHeader>
            <Icon name='close' onClick={() => setOpen(false)} style={{ cursor: 'pointer' }} />
          </ReportingHeaderSection>
          <ReportingBodySection>
            <div>Select the periods you wish to report {taxVerbiage}, including reporting $0.00 receipts.</div>
            <StyledTabs
              indicatorColor="primary"
              value={selectedYear}
              onChange={handleYearSelect}
            >
              {yearsArray?.map((year) => <StyledTab key={year} label={year} value={year} />)}
            </StyledTabs>
            <MonthLayout>
              {
                availablePeriodsForYear?.map((period, index) =>
                  <Checkbox key={`period_${index}`}
                    id={String(period.label)}
                    name={String(period.label)}
                    label={period.label}
                    checked={isPeriodChecked(period)}
                    onChange={() => { toggleSelectedPeriod(period) }} />)
              }
            </MonthLayout>
          </ReportingBodySection>
          <ReportingFooterSection>
            <Button type="button" onClick={() => setOpen(false)}>
              Add Periods
            </Button>
            <WhiteButton type="button" onClick={() => setOpen(false)}>
              Cancel
            </WhiteButton>
          </ReportingFooterSection>
        </ReportingWindow>
      </DarkModal>

      <Navigation
        continueButtonType="button"
        loading={loading}
        disabled={isNavigationDisabled}
        onContinueClick={handleContinueClick}
      />
    </div>
  );
}
