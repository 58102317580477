import React from 'react';
import { Divider } from 'rsuite';
import { TooltipInfoIcon } from 'common/tooltip-info-icon';
import get from 'lodash/get';

const TaxableReceiptsGroup = ({ index, children }) => {
  return (
    <tbody style={{
      backgroundColor: index % 2 ? '#ffffff' : '#f0f0f0',
    }}>
      {children}
    </tbody>
  );
};

const Row = ({ children, paddingBottom }) => {
  return (
    <tr style={{
      paddingBottom: paddingBottom
    }}>
      {children}
    </tr>
  );
};

const DescriptionColumn = ({ children, fontSize, isBold = false, padding = undefined }) => {
  return (
    <td style={{ fontSize: fontSize, fontWeight: isBold ? 'bold' : 'unset', flexGrow: 1, padding: padding || '0px' }}>
      {children}
    </td>
  );
};

const BreakdownDescriptionColumn = ({ children, fontSize }) => {
  return (
    <td style={{ fontSize: fontSize, paddingLeft: '30px', paddingBottom: '5px', flexGrow: 1 }}>
      {children}
    </td>
  );
};

const ProviderColumn = ({ children, fontSize, isBold = false }) => {
  return (
    <td style={{
      width: '150px', paddingRight: '50px', textAlign: 'right', fontSize: fontSize, fontWeight: isBold ? 'bold' : 'unset', paddingLeft: '50px',
    }}>
      {children}
    </td>
  );
};

const ColumnHeadingRow = ({ providers }) => (
  <Row paddingBottom={'10px'}>
    <DescriptionColumn></DescriptionColumn>
    {
      providers.map(provider => (
        <ProviderColumn key={`columnHeading_${provider.id}`} fontSize={'14px'}>
          {provider.label}
        </ProviderColumn>
      ))
    }
  </Row>
);

const SalesRow = ({ providers, salesLabel, salesProviderValues }) => (
  <Row paddingBottom={'10px'}>
    <DescriptionColumn fontSize={16} padding={'10px 0px 0px 0px'}>{salesLabel}</DescriptionColumn>
    {
      providers.map(provider => {
        const value = salesProviderValues.find(values => values.providerID === provider.id)?.value || "";
        return (
          <ProviderColumn key={`providerSales_${provider.id}`}>
            {value}
          </ProviderColumn>
        );
      })
    }
  </Row>
);

const DeductionsRow = ({ providers, deductionsLabel, deductionsProviderValues }) => (
  <Row>
    <DescriptionColumn fontSize={16} padding={'15px 0px 0px 0px'}>{deductionsLabel}</DescriptionColumn>
    {
      providers.map((provider, index) => {
        const value = deductionsProviderValues.find(values => values.providerID === provider.id)?.value || "";
        return (
          <ProviderColumn key={`providerDeductions_${provider.id}`} isLast={index === providers.length - 1}>
            {value}
          </ProviderColumn>
        );
      })
    }
  </Row>
);

const TotalRow = ({ providers, totalLabel, taxableReceiptsProviderValues }) => (
  <Row paddingBottom={'10px'}>
    <DescriptionColumn fontSize={16} isBold={true} padding={'0px 0px 10px 0px'}>{totalLabel}</DescriptionColumn>
    {
      providers.map(provider => {
        const value = taxableReceiptsProviderValues.find(values => values.providerID === provider.id)?.value || "";
        return (
          <ProviderColumn key={`taxableReceipts_${provider.id}`} isBold={true}>
            {value}
          </ProviderColumn>
        );
      })
    }
  </Row>
);

const TaxableReceiptsPrimaryRow = ({ primaryItem, providers }) => (
  <Row paddingBottom={'8px'}>
    <DescriptionColumn fontSize={16} padding={'5px 0px 10px 15px'}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {primaryItem.label}
        {primaryItem.infoText && (
          <TooltipInfoIcon placement={'right'} text={primaryItem.infoText} iconSize={'16px'} iconMargin={'0px 0px 0px 5px'} />
        )}
      </div>
    </DescriptionColumn>
    {
      providers.map(provider => {
        const value = primaryItem.providerValues.find(values => values.providerID === provider.id)?.value || "";
        return (
          <ProviderColumn key={`taxbleReceiptsPrimaryItem_${provider.id}`} style={{ paddingLeft: 500 }}>
            {value}
          </ProviderColumn>
        );
      })
    }
  </Row>
);

const TaxableReceiptsBreakdownRow = ({ breakdown, providers }) => (
  <Row paddingBottom={'5px'}>
    <BreakdownDescriptionColumn>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {breakdown.label}
        {breakdown.infoText && (
          <TooltipInfoIcon placement={'right'} text={breakdown.infoText} iconSize={'16px'} iconMargin={'0px 0px 0px 5px'} />
        )}
      </div>
    </BreakdownDescriptionColumn>
    {
      providers.map(provider => {
        const value = breakdown.providerValues.find(values => values.providerID === provider.id)?.value || "";
        return (
          <ProviderColumn key={`taxbleReceiptsBreakdown_${provider.id}`}>
            {value}
          </ProviderColumn>
        );
      })
    }
  </Row>
);

const TaxableReceiptsGroupComponent = ({ group, providers }) => (
  <TaxableReceiptsGroup index={group.index}>
    <TaxableReceiptsPrimaryRow primaryItem={group.primaryItem} providers={providers} />
    {
      group.breakdown.map((breakdown, index) => (
        // Only include the breakdown row if there are values
        breakdown.providerValues?.length  ? (
          <TaxableReceiptsBreakdownRow key={index} breakdown={breakdown} providers={providers} />
        ) : null
      ))
    }
  </TaxableReceiptsGroup>
);

const DividerRow = ({ colspan }) => (
  <Row>
    <td colSpan={colspan}>
      <Divider />
    </td>
  </Row>
)

const TotalPaymentDueRow = ({ providers, periodLabel, totalPaymentDueProviderValues }) => (
  <Row paddingBottom={'10px'}>
    <DescriptionColumn fontSize={16} isBold={true}>Total payment due for {periodLabel}</DescriptionColumn>
    {
      providers.map(provider => {
        const value = totalPaymentDueProviderValues.find(values => values.providerID === provider.id)?.value || "";
        return (
          <ProviderColumn key={`tableReceipts_${provider.id}`} isBold={true}>
            {value}
          </ProviderColumn>
        );
      })
    }
  </Row>
);

export function TaxSummaryTableComponent({ data }) {
  const salesLabel = get(data, 'salesAndDeductions.salesLabel');
  const deductionsLabel = get(data, 'salesAndDeductions.deductionsLabel');
  const totalLabel = get(data, 'salesAndDeductions.totalLabel');
  const periodLabel = get(data, 'periodLabel');
  const providers = get(data, 'providers');
  const taxableReceipts = get(data, 'taxableReceipts');
  const taxableReceiptsProviderValues = get(data, 'salesAndDeductions.taxableReceiptsProviderValues');
  const salesProviderValues = get(data, 'salesAndDeductions.salesProviderValues');
  const deductionsProviderValues = get(data, 'salesAndDeductions.deductionsProviderValues');
  const showDeductions = Array.isArray(deductionsProviderValues) && deductionsProviderValues.length;
  const totalPaymentDueProviderValues = get(data, 'totalPaymentDue.providerValues');

  return (
    <table style={{ width: '100%' }}>
      <tbody>
        <ColumnHeadingRow providers={providers} />
        <SalesRow providers={providers} salesLabel={salesLabel} salesProviderValues={salesProviderValues} />
        {
          showDeductions &&
          <DeductionsRow providers={providers} deductionsLabel={deductionsLabel} deductionsProviderValues={deductionsProviderValues} />
        }
        <DividerRow colspan={providers.length + 1} />
        <TotalRow providers={providers} totalLabel={totalLabel} taxableReceiptsProviderValues={taxableReceiptsProviderValues} />
      </tbody>
      {
        taxableReceipts.map((group, index) => (
          <TaxableReceiptsGroupComponent key={`taxableReceiptsGroup_${index}`} group={{ ...group, index }} providers={providers} />
        ))
      }
      <tbody>
        <DividerRow colspan={providers.length + 1} />
        <TotalPaymentDueRow providers={providers} periodLabel={periodLabel} totalPaymentDueProviderValues={totalPaymentDueProviderValues} />
      </tbody>
    </table>
  );
}