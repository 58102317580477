import React, { useMemo } from 'react';
import { useFormikContext } from 'formik';
import { evaluateCondition } from 'configurable-form/configurable-form-utils';
import { parseCustomerConfig } from 'utils/utility-functions';

/**
 * Config Schema
 *
 * {
 *  "defaultHidden":
 *    Type - Boolean
 *    Required when using "showWhen", since this probably wants to be hidden by default if it is conditionally shown
 *
 *  "showWhen" / "hideWhen":
 *    Conditions Array (See "evaluationCondition")
 * }
 */
export function withVisibility(Component) {
  return function VisibilityLogic(props) {
    const { isShownOrHidden = {}, ...componentProps } = props;

    const { values } = useFormikContext();

    const formiKValues = useMemo(() => parseCustomerConfig(values), [values]);

    /**
     * Evaluation if the values stored in Formik state matches
     *    with any of the key-value pairs within the "hide" and "show"
     *    key within the "isShownOrHidden" object of the the config object
     *    to determine if the component should be "hidden" or "shown"
     *
     *  Note: params can be either an array or
     *     an array of arrays. ie [ 'propertyName', '!=', 'hello' ] OR
     *     [
     *       [ 'propertyName', '!=', 'hello' ],
     *       [ 'whoIsUnitRentedBy', '=', 'owner' ]
     *     ]
     */
    const isVisible = useMemo(() => {
      const {
        showWhen = null,
        hideWhen = null,
      } = isShownOrHidden || {};

      if (showWhen) return evaluateCondition(formiKValues, showWhen);
      if (hideWhen) return !evaluateCondition(formiKValues, hideWhen);
      return true;
    }, [isShownOrHidden, formiKValues]);

    if (!isVisible) return null;
    return <Component {...componentProps} />;
  }
}
