import { store } from 'app/app-store';
import { errorActions } from 'app/state/error/error-slice';
import { networkActions } from 'app/state/network/network-slice';
import { payTotActions } from 'app/state/pay-tot/pay-tot-slice';
import { payloadActions } from 'app/state/payload/payload-slice';
import {
  buildApiPostPayload,
  buildConfiguredPayloadFromState,
  evaluateCondition,
  maybeExtractFieldValue,
} from 'configurable-form/configurable-form-utils';
import get from 'lodash/get';
import merge from 'lodash/merge';
import set from 'lodash/set';
import { history } from 'routes/history';
import { authSender } from 'utils/auth-sender';
import { filesManager } from 'utils/files-manager';
import { strRegistrationApiSender } from 'utils/str-registration-api-sender';
import { parseCustomerConfig } from 'utils/utility-functions';
import { evaluateDeckardExpr } from "./deckard-expression-language";
import { NoStrategyToExecuteError } from './strategy-errors';

window.hh = history;

export class BaseStrategy {
  static strategiesData = null;

  /**
   * You must do this before instantiating any strategies. This will populate the static
   * strategiesData member variable. We do it this way so that we won't have to pass
   * any data when getting the singleton instances later
   *
   * @param {*} flows flows from stats
   */
  static setStrategiesDataFromFlowsArray(flows) {
    const strategiesDataObj = {};
    Object.keys(flows).forEach((flowKey) => {
      strategiesDataObj[flowKey] = flows[flowKey].strategies;
    });
    BaseStrategy.strategiesData = strategiesDataObj;
  }

  // copied from https://stackoverflow.com/a/59084440
  static parseStringTemplate(str, obj) {
    let parts = str.split(/\$\{(?!\d)[\wæøåÆØÅ]*\}/);
    let args = str.match(/[^{\}]+(?=})/g) || []; // eslint-disable-line no-useless-escape
    let parameters = args.map(argument => obj[argument] || (obj[argument] === undefined ? "" : obj[argument]));
    return String.raw({ raw: parts }, ...parameters);
  }

  static apiSenderFactory(isAuthenticatedRequest) {
    if (isAuthenticatedRequest) return authSender;
    return strRegistrationApiSender;
  }

  constructor() {
    if (!BaseStrategy.strategiesData) {
      throw new Error('You must set the strategiesData before creating a strategy class instance');
    }
  }

  /**
   * Returns redux state
   */
  get reduxState() {
    return store.getState();
  }

  get formikSnapshot() {
    return this.reduxState.payload.formikSnapshot;
  }

  get reduxDispatch() {
    return store.dispatch;
  }

  getStateForStrategyExecution = () => {
    const formikSnapshot = parseCustomerConfig(get(this.reduxState, 'payload.formikSnapshot', {}));

    const mergedState = merge(
      {},
      get(this.reduxState, 'payTot', {}),
      get(this.reduxState, 'payload', {}),
      get(this.reduxState, 'applyLicense', {}),
      get(this.reduxState, 'network', {}),
      get(this.reduxState, 'login', {}),
      { dashboard: get(this.reduxState, 'dashboard', {}) },
      { formikSnapshot }
    )

    return mergedState;
  };

  /**
   * Executes the strategies defined from the strategies config object of the current page.
   * Each strategy is evaluated at run-time, so whatever data is saved to the Redux store
   *  is immediately available for the next strategy to utilize.
   *    Example:
   *      Easton, PA (Foreclosures):
   *        Renew flow Confirmation strategy where a user may or may not need to be directed to the payment page.
   */
  executeStrategy = async (strategies, strategyName, setSubmitting = () => { }) => {
    const strategyToExecute = strategies?.[strategyName];

    if (!strategyToExecute) throw new NoStrategyToExecuteError(`${strategyName} is not a defined in the strategies`);

    if (Array.isArray(strategyToExecute)) {
      for (let strategy of strategyToExecute) { // For of loops are async aware
        await this.executeStrategy(strategies, strategy, setSubmitting);
      }
    } else {
      const state = this.getStateForStrategyExecution();

      const strategyCondition = strategyToExecute?.condition;
      const strategyConditionNotValid = !evaluateCondition(state, strategyCondition);

      // If a strategy has a condition, then it must have a fallback
      if (strategyConditionNotValid) return await this.executeStrategy(strategies, strategyToExecute.fallback);

      const { method, params } = strategyToExecute;
      // Finally, execute the strategy using the available methods of the "BaseStrategy" class
      await this[method]({ params, state, setSubmitting, strategies });
    }
  };

  actionFactory = (action) => {
    const actionMap = { payloadActions, payTotActions, networkActions };
    return actionMap?.[action];
  }

  goTo({ params: path, setSubmitting = () => { } }) {
    setSubmitting(false);
    history.push(path);
  }

  buildConfiguredPath(path, pathTemplate) {
    if (path) return path;
    const store = pathTemplate.useQueryParams ? history.location.search : this.reduxState;
    const pathVariables = buildConfiguredPayloadFromState(store, pathTemplate.valueGetters, pathTemplate.useQueryParams);
    const builtPath = BaseStrategy.parseStringTemplate(pathTemplate.template, pathVariables);

    return builtPath;
  }

  /**
   * Inserts response data into the redux store via a config
   * @param {*} data response object
   * @param {*} params reduxStorageParams from config
   */
  storeDataInReduxState = (data, params) => {
    if (!params) return;

    function insertIntoRedux(responseObj, param) {
      // See ReduxStorageOperation type
      const { action, method, responseKey, storageKey, dataTransform, payloadCondition, resultKey, reduxKey } = param;

      /**
       * 1. Check if there is an action (tells us if we want to save data or not)
       * 2. Dispatch the action to save the data
       */
      const reduxAction = action ? this.actionFactory(action) : null;
      if (!reduxAction) return;

      if (!evaluateCondition(data, payloadCondition)) return;

      /**
       * Cases for initialization of "payload":
       *
       *  storageKey exists && responseKey exists
       *    Expect payload shape is { key: value }
       *    Where value is a particular value within the response object
       *
       *  storageKey exists && responseKey does not exist
       *    Expect payload shape is { key: value }
       *    Where value is the entire response object
       *
       *  storageKey does not exist && responseKey exist
       *    No expected payload shape as the "entire payload" will be inserted
       *    However, the "entire payload" is a value within the response object
       *
       *  storageKey does not exist && responseKey does not exist
       *    No expected payload shape as the response object will be inserted
       */
      const extractedValue = maybeExtractFieldValue(responseObj, responseKey, dataTransform);
      const payload = storageKey ? set({}, storageKey, extractedValue) : extractedValue;

      if (reduxKey && resultKey) {
        const reduxValue = get(this.reduxState, reduxKey);
        this.reduxDispatch(reduxAction?.[method]({ resultKey, reduxValue }));
      } else {
        this.reduxDispatch(reduxAction?.[method](payload));
      }
    }

    if (Array.isArray(params)) {
      params.forEach((param) => { insertIntoRedux.bind(this)(data, param); });
    } else {
      insertIntoRedux.bind(this)(data, params);
    }
  }

  apiGet = async ({ params }) => {
    // See PathField, ApiGetStrategyParams types
    const { path, pathTemplate, isAuth } = params;

    // Retrieve the correct sender class to use
    const apiSender = BaseStrategy.apiSenderFactory(isAuth);

    /**
     * 1. Build the path
     * 2. Send the request
     */
    const configuredPath = this.buildConfiguredPath(path, pathTemplate);
    const { data } = await apiSender[pathTemplate?.method || 'get'](configuredPath);
    this.storeDataInReduxState(data, params?.reduxStorageParams);
  }

  /**
   * State is generated in "buildSingleStrategyFunction"
   */
  apiPost = async ({ params, state }) => {
    // See PathField, ApiPostStrategyParams types
    const { dataTransform, path, pathTemplate, isAuth } = params;

    const payload = buildApiPostPayload(state, dataTransform);

    // console.log('\napiPost');

    // console.log('\n payload');
    // console.log(payload);

    // console.log('\n payload?.people');
    // console.log(payload?.people);

    // console.log('\n payload?.misc');
    // console.log(payload?.misc);

    // console.log('\n payload?.misc?.supplementalQuestions');
    // console.log(payload?.misc?.supplementalQuestions);

    // Retrieve the correct sender class to use
    const apiSender = BaseStrategy.apiSenderFactory(isAuth);

    const configuredPath = this.buildConfiguredPath(path, pathTemplate);
    const { data } = await apiSender.post(configuredPath, payload);
    this.storeDataInReduxState(data, params?.reduxStorageParams);
  }

  /**
   * Triggers the file upload functionality of the FilesManager class
   * ! It is important that "generateApplicationNumber" proceeds this function
   */
  uploadFiles = async ({ params }) => {
    const { documents, valueGetters, additionalRequestParams = [] } = params;

    const applicationNumPath = valueGetters?.applicationNumber || 'network.applicationNumber';
    const applicationNumber = get(this.reduxState, applicationNumPath);

    const savedNetworkResponses = get(this.reduxState, 'network');
    const apiReqParams = new URLSearchParams();

    additionalRequestParams.forEach((p) => {
      const [name, path] = p;
      const value = get(savedNetworkResponses, path);
      apiReqParams.append(name, value);
    });

    await filesManager.startUpload(applicationNumber, documents, this.reduxDispatch, Object.fromEntries(apiReqParams));
  };

  dataTransform = ({ params, state }) => { // eslint-disable-line no-unused-vars
    // TODO: Do this. It would be nice to do transforms on page change.
    /* We also need a new transform called setDerivateValues which given
      values A, B, can produce C₁, C₂, C₃... based on combinations of A, B (like a state machine).
      This will be useful in this use case:
      -> We only want to show PropetyManager in the confirmation (Shreveport) section if
          1. Registrant is not the property manager
          2. License does not have a separate PM (_hasSeparatePM = No).

            However, _hasSeparatePM is undefined by default and the user is not presented
            the input for setting it when registrantType=propertyManager. Therefore, this
            value stays undefined.

          We should be able to set _hasSeparatePM=No deriving from registrantType=propertyManager
    */
  }

  apiPoll = async ({ params }) => {
    // See PathField, ApiGetStrategyParams types
    const { path, pathTemplate, isAuth, successCondition, maxRetries = 5, intervalMs = 1000, debugDescription = "API condition" } = params;

    // Retrieve the correct sender class to use
    const apiSender = BaseStrategy.apiSenderFactory(isAuth);

    /**
     * 1. Build the path
     * 2. Send the request
     */
    const configuredPath = this.buildConfiguredPath(path, pathTemplate);


    let isConditionMet = false;
    let numTries = 0;
    let maxTriesToUse = Math.min(maxRetries, 5);

    while (!isConditionMet && numTries < maxTriesToUse) {
      const { data } = await apiSender[pathTemplate?.method || 'get'](configuredPath);
      numTries++;
      isConditionMet = evaluateCondition(data, successCondition);
      if (isConditionMet) {
        this.storeDataInReduxState(data, params?.reduxStorageParams);
        break;
      }
      await new Promise((resolve) => setTimeout(resolve, intervalMs));
    }

    if (!isConditionMet) {
      throw new Error(`Failed polling ${configuredPath} for ${debugDescription}`)
    }
  }

  async setPageError({ params: message }) {
    this.reduxDispatch(errorActions.setPageError({ message }));
  };

  async postMessageToOpenerWindow({ params: message }) {
    window.opener?.postMessage(message, '*');
  };

  runDeckardExpr = async ({ params, strategies, setSubmitting }) => {
    const { expr, options } = params;

    const extraBuiltinFunctions = {
      "deckard/exec-named-strategy": (strategyName) => this.executeStrategy(
        strategies, strategyName, setSubmitting
      ),
      "deckard/invoke-strategy-method": (method, params) => {
        const state = this.getStateForStrategyExecution();
        // TODO: Support async methods
        this[method]({ params, state, setSubmitting, strategies });
      },
      "deckard/store": this.reduxState,
    }

    evaluateDeckardExpr(expr, extraBuiltinFunctions, options || {});
  };

  /**
   * This is a placeholder method used when creating configurations
   */
  overrideThis() {
    throw new Error('Dev error. This method must be overridden in configuration');
  }
}
