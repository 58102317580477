import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import { useFormikContext } from 'formik';
import styled from 'styled-components';
import * as appSelectors from 'app/state/app/app-selectors';
import * as networkSelectors from 'app/state/network/network-selectors';

const EmailChangedWrapper = styled.div`
  max-width: 600px;
  margin-bottom: 20px;
  background-color: #ffeddf;
  border: 1px solid #fc7e49;
  border-radius: 5px;
  padding: 10px 14px 10px 14px;
`;

const Text = styled.p`
  color: #fc7e49;
`;

const BoldText = styled(Text)`
  font-weight: bold;
`;

export function LoginEmailChangeBanner({ path }) {
  const { values } = useFormikContext();
  const cityInfo = useSelector(appSelectors.cityInfo);
  const cleanLicense = useSelector(networkSelectors.originalLicenseInfo);

  const hasLoginEmailChanged = useMemo(() => (
    path && get(values, path) !== get(cleanLicense, path)
  ), [cleanLicense, path, values]);

  if (!hasLoginEmailChanged) return null;

  return (
    <EmailChangedWrapper>
      <BoldText>You are changing the email associated with your {cityInfo.certificateVerbiage}.</BoldText>
      <Text>This will change your login email address. Please make sure you have access to this email.</Text>
    </EmailChangedWrapper>
  );
}
