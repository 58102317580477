import React from 'react';
import { Tooltip, Whisper } from 'rsuite';
import { InputLayout } from 'common/common-styles';
import * as cs from 'common/common-styles';

export function InfoTooltip(props) {
  const { placement, text } = props;

  return (
    <Whisper
      trigger="hover"
      placement={placement}
      speaker={<Tooltip>{text}</Tooltip>}
    >
      <img src="/assets/icon-info.svg" alt="Info Icon" />
    </Whisper>
  );
}

/**
 * Displays the tooltip and the message when you hover over the tooltip
 */
export function InputHeader(props) {
  const { placement, tooltip, text } = props;

  return (
    <InputLayout>
      <cs.InputTextFieldLabel>{text}</cs.InputTextFieldLabel>
      {Boolean(placement) && Boolean(tooltip) && (<InfoTooltip placement={placement} text={tooltip} />)}
    </InputLayout>
  );
}
