import React from 'react';
import { useSelector } from 'react-redux';
import startCase from 'lodash/startCase';
import styled from 'styled-components';
import * as appSelectors from 'app/state/app/app-selectors';
import { BackContinueButton } from 'widgets/back-continue-button';

const LicenseItem = styled.div`
  color: black;
  background: rgb(217,218,220);
  padding: 15px;
  border-radius: 5px;
`;

function License(props) {
  return (
    <LicenseItem>
      <p style={{ fontWeight: 'bold' }}>{startCase(props.type)} #{props.number} - {props.registrant.name}</p>
      <p>{props.address}</p>
      {props?.registrant?.expiryDate && <p>Valid thru {props.registrant.expiryDate}</p>}
    </LicenseItem>
  )
}

const Container = styled.div`
  margin-top: 40px;
`;

const Header = styled.p`
  font-size: 18px;
  font-weight: bold;
  font-family: 'Lato';
`;

const Text = styled.p`
  font-size: 16px;
  font-family: 'Lato';
`;

const LicenseList = styled.div`
  margin: 50px 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
`;

export function ExistingLicenses(props) {
  const {
    disableDefaultNavigation = false
  } = props;

  const certificateType = useSelector(appSelectors.certificateVerbiage);
  const { search: config } = useSelector(appSelectors.renderConfig);

  return (
    <Container>
      <Header>
        {config?.existingLicensesConfig?.header ??
          `This APN already has existing ${certificateType}(s) and/or application(s).`}
      </Header>


      <Text>
        {config?.existingLicensesConfig?.subheader ??
          `If you wish to create a new ${certificateType} application, then please click the continue button.`}
      </Text>

      <LicenseList>
        {props.licenses.map((license) => (
          <License key={license.number} {...license} address={props.address} />
        ))}
      </LicenseList>

      {!disableDefaultNavigation && (
        <BackContinueButton
          onContinueClick={props.onContinueClick}
          onBackClick={props.onBackClick}
        />
      )}
    </Container>
  );
}
