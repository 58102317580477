export const CARD_ERROR = 'card_error';
export const STRIPE_PAYMENT_INTENT_STATUS_SUCCESS = 'succeeded';

export const STRIPE_PAYMENT_INTENT_STATUSES = {
  SUCCESS: 'succeeded',
  PROCESSING: 'processing',
  PENDING: 'pending',
  REQUIRES_CONFIRMATION: 'requires_confirmation',
  REQUIRES_PAYMENT_METHOD: 'requires_payment_method',
  /**
   * https://stripe.com/docs/payments/intents
   * Versions of the API before 2019-02-11 show `requires_source` instead of `requires_payment_method`
   *  and `requires_source_action` instead of `requires_action`.
   */
  REQUIRES_SOURCE: 'requires_source'
};

export const STRIPE_NO_PAYMENT_METHOD_ATTACHED_INTENT_STATUSES = [
  STRIPE_PAYMENT_INTENT_STATUSES.REQUIRES_PAYMENT_METHOD,
  STRIPE_PAYMENT_INTENT_STATUSES.REQUIRES_SOURCE
];

export const SUCCESSFUL_STRIPE_ACH_PAYMENT_INTENT_STATUSES = [
  STRIPE_PAYMENT_INTENT_STATUSES.SUCCESS,
  STRIPE_PAYMENT_INTENT_STATUSES.PENDING,
  STRIPE_PAYMENT_INTENT_STATUSES.PROCESSING,
]

export const STRIPE_ELEMENTS_STYLE_VARIABLES = {
  fontWeight: 500,
  fontFamily: 'Lato, Roboto, Open Sans, Segoe UI, sans-serif',
  fontSizeBase: '14px',
  colorText: "#5c5d5d",
  lineHeight: 1.5,
};

export const BANK_OPTION_CHASE = { value: 'chase', label: 'JPMorgan Chase' };
export const BANK_OPTION_BOFA = { value: 'bofa', label: 'Bank of America' };
export const BANK_OPTION_WELLSFARGO = { value: 'wellsfargo', label: 'Wells Fargo' };
export const BANK_OPTION_CITIGROUP = { value: 'citigroup', label: 'Citi' };
export const BANK_OPTION_OTHER = { value: 'other', label: 'Other' };

export const AVAILABLE_BANKS_OPTIONS = [
  BANK_OPTION_CHASE,
  BANK_OPTION_BOFA,
  BANK_OPTION_WELLSFARGO,
  BANK_OPTION_CITIGROUP,
  BANK_OPTION_OTHER,
];
