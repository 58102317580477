import { networkActions } from 'app/state/network/network-slice';
import { payTotActions } from 'app/state/pay-tot/pay-tot-slice';
import dayjs from 'dayjs';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import { authSender } from 'utils/auth-sender';
import { captureExceptionWithContext } from 'utils/sentry-functions';
import { addObjects, mapObject } from 'utils/utility-functions';

import { CustomerPortalStrategy } from './customer-portal-strategy';
import { CalculateMultiPropertyTotStrategyError } from './errors';

export class PayTaxStrategy extends CustomerPortalStrategy {
  static instance = null;

  static getInstance() {
    if (PayTaxStrategy.instance) return PayTaxStrategy.instance;

    PayTaxStrategy.instance = new PayTaxStrategy();
    return PayTaxStrategy.instance;
  }

  calculateMultiPropertyTot = async () => {
    const AMOUNT_FIELDS = [
      'licenseFee',
      'totDue',
      'assessment',
      'latePenalties',
      'accruedInterest',
    ];

    function toMultiPropertyTOTPaymentFees(paymentFees) {
      const paymentPeriodsByName = groupLicensePeriodsByCalendarPeriod(
        paymentFees.details,
      );
      const paymentPeriods = Object.values(paymentPeriodsByName)
        .sort((a, b) => dayjs(b.startDate).diff(dayjs(a.startDate)))
        .map(calculateTotalsForMultiPropertyPaymentPeriod);
      return {
        ...omit(paymentFees, 'details'),
        paymentPeriods,
      };
    }

    function calculateTotalsForMultiPropertyPaymentPeriod(paymentPeriod) {
      const totalFieldName = 'total';

      let result = {
        ...paymentPeriod,
        licenseReports: paymentPeriod.licenseReports.map((licenseReport) => ({
          ...licenseReport,
          [totalFieldName]: AMOUNT_FIELDS.reduce(
            (sum, field) => (licenseReport[field] || 0) + sum,
            0,
          ),
          ...(!licenseReport.misc
            ? {}
            : {
              misc: {
                ...licenseReport.misc,
                taxableActivities: licenseReport.misc.taxableActivities?.map(
                  (activity) => ({
                    ...activity,
                    ...Object.values(activity.taxTypes || {}).reduce(
                      (totalFields, taxDetails) =>
                        addObjects(totalFields, taxDetails),
                      mapObject(
                        Object.values(activity.taxTypes || {})[0] || {},
                        () => 0,
                      ),
                    ),
                  }),
                ),
              },
            }),
        })),
      };

      const paymentPeriodTotalFields = [
        ...AMOUNT_FIELDS,
        totalFieldName,
        'taxableReceipts',
      ].reduce(
        (acc, field) => ({
          ...acc,
          [field]: result.licenseReports.reduce(
            (sum, licenseReport) => sum + (licenseReport[field] || 0),
            0,
          ),
        }),
        {},
      );

      result = {
        ...result,
        ...paymentPeriodTotalFields,
      };

      return result;
    }

    function groupLicensePeriodsByCalendarPeriod(licensePeriods) {
      const paymentPeriodsByName = {};

      for (const licensePeriod of licensePeriods) {
        if (!paymentPeriodsByName[licensePeriod.period]) {
          paymentPeriodsByName[licensePeriod.period] = {
            ...pick(licensePeriod, [
              'period',
              'startDate',
              'endDate',
              'licenseReports',
            ]),
            name: licensePeriod.period,
            licenseReports: [],
          };
        }

        const paymentPeriod = paymentPeriodsByName[licensePeriod.period];
        paymentPeriod.licenseReports.push({
          ...pick(licensePeriod, [
            'period',
            'licence_no',
            'application_no',
            'display_id',
            'startDate',
            'endDate',
            'taxableReceipts',
            'totDue',
            'licenseFee',
            'assessment',
            'latePenalties',
            'accruedInterest',
            'type',
            'misc',
          ]),
          licenseId: licensePeriod.licence_no,
        });
      }

      return paymentPeriodsByName;
    }

    // Data entered by the user and saved in formik on the tax report revenue page
    const taxableReceipts =
      this.reduxState.payload.formikSnapshot.taxableReceipts;

    // format data for payload for request
    // SHould be in this format:
    // {
    //   "paymentPeriods": [
    //     {
    //       "license": "000010",
    //       "dateStart": "2022-09-01",
    //       "dateEnd": "2022-09-30",
    //       "misc": {
    //         "taxableActivities": [
    //           {
    //             "dateStart": "2022-09-01",
    //             "dateEnd": "2022-09-30",
    //             "taxableReceipts": 222,
    //             "numDaysOccupied": 22,
    //             "numDaysAvailable": 22,
    //             "provider": "airbnb"
    //           }
    //         ]
    //       }
    //     }
    //   ]
    // }
    function transformTaxableReceiptsForCalculateMultiPropertyTot(obj) {
      const paymentPeriods = [];

      for (const dateRange in obj) {
        const licenses = obj[dateRange];

        // Split into parts so we can determine the start and end date
        const dateRangeArray = dateRange.split('_');
        const startDate = dateRangeArray[0];
        const endDate = dateRangeArray[1];

        for (const licenseId in licenses) {
          const license = licenses[licenseId];
          const taxableActivities = [];

          for (const providerKey in license.providers) {
            const providers = license.providers[providerKey];

            const taxableActivity = {
              dateStart: startDate,
              dateEnd: endDate,
              taxableReceipts:
                parseFloat(providers.sales ?? 0) -
                parseFloat(providers.deductions ?? 0),
              totalRevenue: parseFloat(providers.sales ?? 0),
              allowableDeductions: parseFloat(providers.deductions ?? 0),
              numDaysOccupied: providers.daysOccupied,
              numDaysAvailable: providers.daysAvailable,
              provider: providerKey,
            };

            taxableActivities.push(taxableActivity);
          }

          paymentPeriods.push({
            license: licenseId.replace(/^_/, ''), //  remove _ from start of licenseID. the _ was added because formik does not handle numbers as a key well
            dateStart: startDate,
            dateEnd: endDate,
            misc: {
              taxableActivities: taxableActivities,
            },
          });
        }
      }
      return {
        paymentPeriods,
      };
    }

    const convertedPaymentPeriods =
      transformTaxableReceiptsForCalculateMultiPropertyTot(taxableReceipts);

    const CHUNK_SIZE = 25;

    try {
      let data;
      let paymentId = null;

      for (
        let chunkStartIdx = 0;
        chunkStartIdx < convertedPaymentPeriods.paymentPeriods.length;
        chunkStartIdx += CHUNK_SIZE
      ) {
        const chunkEndIdx = Math.min(
          convertedPaymentPeriods.paymentPeriods.length,
          chunkStartIdx + CHUNK_SIZE,
        );
        const paymentPeriodsInChunk =
          convertedPaymentPeriods.paymentPeriods.slice(
            chunkStartIdx,
            chunkEndIdx,
          );

        const result = await authSender.post(`/calculateMultiPropertyTot`, {
          paymentPeriods: paymentPeriodsInChunk,
          numPaymentPeriods: convertedPaymentPeriods.paymentPeriods.length,
          paymentId,
        });

        data = result.data;
        paymentId = data.paymentId;
      }

      // save raw response in redux
      this.reduxDispatch(payTotActions.setCalculatedTOTData(data));

      // save payment data in redux
      let lFormattedData = toMultiPropertyTOTPaymentFees(data);
      this.reduxDispatch(networkActions.setPaymentFees(lFormattedData));
    } catch (err) {
      captureExceptionWithContext(
        new CalculateMultiPropertyTotStrategyError(err),
        {
          convertedPaymentPeriods: JSON.stringify(
            convertedPaymentPeriods.paymentPeriods,
          ),
        },
      );
      throw err;
    }
  };
}
