import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { InputGroup, InputNumber } from 'rsuite';
import * as appSelectors from 'app/state/app/app-selectors';
import { FormikError } from 'configurable-form/components/inputs/shared-components';
import { useInput } from 'configurable-form/components/utils/use-input';
import { HiddenFieldSpacer, InputContainer } from './styles';
import { useFieldCustomChangeHandler } from "./useFieldCustomChangeHandler";
import { convertToInputNumberFieldValue } from '../tot-payment-periods/fields/shared';

const DATA_CY_PREFIX_STRING = 'report-revenue-input-days-available';

function useValidation(max) {
  return useMemo(() => ({
    validationType: 'number',
    rules: [
      { type: 'required', params: ['This field is required.'] },
      { type: 'min', params: [0, 'The minimum value accepted is 0.'] },
      { type: 'max', params: [max, `The maximum value accepted is ${max}.`] },
      { type: 'integer', params: ['The field only accepts integer values.'] }
    ],
  }), [max]);
}

export function DaysAvailableInput(props) {
  const config = useSelector(appSelectors.renderConfig);
  const validation = useValidation(props.daysInPeriod);
  const { field, helpers, meta } = useInput({ name: props.fieldKey, validation });

  const onChange = useFieldCustomChangeHandler({ helpers, getValueFromArgs: convertToInputNumberFieldValue });

  if (config?.reporting?.hideDaysAvailable) return <HiddenFieldSpacer />;

  return (
    <InputContainer data-cy={`${DATA_CY_PREFIX_STRING}-${props.fieldKey}`}>
      <InputGroup>
        <InputNumber {...field} scrollable={false} onChange={onChange} maxLength={2} />
      </InputGroup>
      <FormikError {...meta} touched />
    </InputContainer>
  );
}
