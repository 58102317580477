import * as appSelectors from 'app/state/app/app-selectors';
import * as cs from 'common/common-styles';
import React from 'react';
import { useSelector } from 'react-redux';
import { parseHTMLString } from 'utils/utility-functions';

function ParcelLookup() {
  const cityInfo = useSelector(appSelectors.cityInfo);

  if (cityInfo?.parcelLookupLinkHtml) return parseHTMLString(cityInfo?.parcelLookupLinkHtml);

  if (cityInfo?.parcelLookupWebsite || cityInfo?.parcelLookupLinkText)
    return (
      <cs.Link href={cityInfo?.parcelLookupWebsite} target="_blank" rel="noreferrer">
        {cityInfo?.parcelLookupLinkText}
      </cs.Link>
    );

  return null;
}

ParcelLookup.propTypes = {};

export default ParcelLookup;
