import React, { useMemo } from 'react';
import { PaymentTotals } from './payment-totals';
import { TOTTaxableActivity } from './tot-taxable-activity';
import { TOTPaymentRowHeader } from './tot-payment-row-header';
import { getTaxableActivityFormikPath } from '../../utils';
import { useField } from "formik";
import { useAllReportingColumns } from "./use-all-reporting-columns";
import { useDaysInPeriod } from "./use-days-in-period";
import { FlexCol } from "./styles";

// eslint-disable-next-line no-unused-vars
export function TOTSingleLicensePaymentReport({ licenseReportPath, isRemovable, useVerticalLayout, periodName }) {
  const [{ value: licenseReport }] = useField(licenseReportPath);

  const taxableActivities = useMemo(() => licenseReport.taxableActivities, [licenseReport]);

  const daysInPeriod = useDaysInPeriod(taxableActivities);
  const allReportingColumns = useAllReportingColumns();

  if (!licenseReport.selected) return null;


  return (
    <React.Fragment>
      {!useVerticalLayout && (
        <TOTPaymentRowHeader
          reportingColumns={allReportingColumns}
          daysInPeriod={daysInPeriod}
        />)}

      {/* Row Inputs */}
      <FlexCol>
        {taxableActivities.map((taxableActivity, idx) => (
          <TOTTaxableActivity
            key={idx}
            taxableActivityPath={getTaxableActivityFormikPath({
              licenseReportPath,
              taxableActivityIdx: idx
            })}
            daysInPeriod={daysInPeriod}
            reportingColumns={allReportingColumns}
            useVerticalLayout={useVerticalLayout}
            periodName={periodName}
          />
        ))}

        {!useVerticalLayout && taxableActivities.length > 1 && (
          <PaymentTotals
            licenseReportPath={licenseReportPath}
            reportingColumns={allReportingColumns}
          />
        )}
      </FlexCol>
    </React.Fragment>
  )
}
