import React, { useMemo } from 'react'
import { useFormikContext } from 'formik';
import styled from 'styled-components/macro';
import { ONE } from 'common/constants';
import { evaluateCondition } from 'configurable-form/configurable-form-utils';
import { DocTextRender } from './file-upload-modules/document-text';
import { isDocFormikRequired } from './utils';
import { UploadModule } from './file-upload-modules/upload-module';

const MultiUploadContainer = styled.div`
  display: flex;
  gap: 40px;
  margin-top: 20px;
`;

/**
 * Config: Nested within the FileUpload component config in "components" key
 *  See Sacramento County JSON for implementation
 *
 * {
 *   "component": "MultiFileUpload"
 *    !Required
 *    Type - String
 *
 *   "id": String for the React prop "key"
 *    !Required
 *    Type - String
 *
 *   "fileKey": Server-side label for the file being uploaded
 *    !Required
 *    Type - String
 *
 *   "required":
 *    Type - Boolean or Conditional Array
 *      See "evaluateCondition" function in "configurable-form-utils.js"

 *   "headerText": See "document-text.jsx"
 *
 *   "helperText": See "document-text.jsx"
 *
 *   "components": Array of configs the multiple file upload components
 *    !Required
 *    See below
 *
 *   "additionalCSS": Additional CSS to apply
 *    Type - String
 * }
 *
 * {
 *   "text": Text to be rendered to guide the user
 *    !Required
 *    Type - String or HTML String
 *
 *   "storageKey": Client-side label for the file being uploaded
 *    !Required
 *    Type - String
 *
 *   "dataCy": Cypress selector
 *    !Required
 *    Type - String
 *
 *   "additionalCSS": Additional CSS to apply
 *    Type - String
 * }
 */
export function MultiFileUpload(props) {
  const { components = [], fileKey, required } = props;

  const { values } = useFormikContext();

  const isRequired = useMemo(() => {
    if (isDocFormikRequired(required)) return evaluateCondition(values, required?.condition);
    return required; // When "required" is of type Boolean
  }, [required, values]);

  /**
   * This component should only be used when there are at least
   *  two file upload components that need to be grouped
   *  See Sacramento County
   */
  if (components?.length <= ONE) return null;

  return (
    <div css={props?.additionalCSS}>
      <DocTextRender title text={props?.headerText} required={isRequired} fileKey={fileKey} />
      <DocTextRender text={props?.helperText} fileKey={fileKey} />

      <MultiUploadContainer>
        {components.map((config, idx) => {
          return (
            <React.Fragment key={config?.storageKey}>
              {idx >= ONE && <p>AND</p>}
              <UploadModule
                fileKey={fileKey}
                required={required}
                {...config}
              />
            </React.Fragment>
          );
        })}
      </MultiUploadContainer>
    </div>
  );
}
