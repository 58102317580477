import * as appSelectors from 'app/state/app/app-selectors';
import { appActions } from 'app/state/app/app-slice';
import { payloadActions } from 'app/state/payload/payload-slice';
import * as cs from 'common/common-styles';
import { FLOWS } from 'common/constants';
import { LicenseLookup } from 'pages/flow-entry/license-lookup';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router';
import { FLOW_PATHS, RENEWAL_PATHS } from 'routes/constants';
import { ConfigurableRoutes } from 'routes/custom/configurable-routes';
import { withFlowEntry } from 'routes/custom/entry-exit-wrappers';
import { RenewalStrategy } from 'strategies/renewal-strategy';

const LicenseLookupHOC = withFlowEntry(LicenseLookup);

const Div = (props) => <div>{props.children}</div>;

export function RenewalRoutes(props) {
  const dispatch = useDispatch();

  const useCustomerPortal = useSelector(appSelectors.useCustomerPortal);

  const flows = useSelector(appSelectors.flows);
  const activeFlow = useSelector(appSelectors.activeFlow);

  const currentFlow = flows?.[FLOWS.RENEW_LICENSE];
  const earlyInit = currentFlow?.earlyInit;

  useEffect(() => {
    dispatch(appActions.setActiveFlow(FLOWS.RENEW_LICENSE));

    if (earlyInit) {
      const arrayTypeEarlyInit = Array.isArray(earlyInit);
      if (arrayTypeEarlyInit) {
        /**
         * Array type early init, where we dictate via the client config which method to store the information
         * See "scripts/stats-src/types/config.ts"
         */
        const [earlyInitBool, earlyInitMethod] = earlyInit; // eslint-disable-line no-unused-vars
        dispatch(
          payloadActions?.[earlyInitMethod](
            flows?.[FLOWS.RENEW_LICENSE]?.initialFormikValues,
          ),
        );
      } else {
        // Standard early init
        dispatch(
          payloadActions.updateFormikSnapshot(
            flows?.[FLOWS.RENEW_LICENSE]?.initialFormikValues,
          ),
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  // Must wait for the active flow to be set so all selectors specific to this flow work
  if (!activeFlow) return null;

  const skipInitOnSpecialPages = Boolean(earlyInit);
  const View = useCustomerPortal ? Div : cs.ViewContainer;

  return (
    <View>
      <Switch>
        <Route exact path={FLOW_PATHS.renewal}>
          <Redirect
            to={`${FLOW_PATHS.renewal}/${flows?.renewLicense?.entrySubPath}`}
          />
        </Route>

        <Route path={RENEWAL_PATHS.search}>
          <LicenseLookupHOC
            reset={props.resetFlow}
            init={
              !skipInitOnSpecialPages &&
              flows?.renewLicense?.initialFormikValues
            }
          />
        </Route>

        <ConfigurableRoutes
          configurablePages={flows?.renewLicense?.configurablePages}
          strategyInstance={RenewalStrategy.getInstance()}
        />
      </Switch>
    </View>
  );
}
