import React, { useCallback } from 'react';
import styled from 'styled-components';
import { extendFormikPath, getLicenseReportFormikPath } from "../../utils";
import { useField, useFormikContext } from "formik";

const StyledDiv = styled.div`
  align-self: center;
`;

const Button = styled.button`
  background-color: transparent;
`;

export function RemovePeriodButton({ periodPath, licenseReportPath, removable, className }) {
  const { setFieldValue } = useFormikContext();
  const [ field ] = useField({ name: periodPath });

  const onClick = useCallback(() => {
    const licenseReportPaths = licenseReportPath ? [licenseReportPath] : field.value?.licenseReports.map((_, licenseReportIdx) => getLicenseReportFormikPath({
      periodPath,
      licenseReportIdx
    }));

    licenseReportPaths?.forEach(path => setFieldValue(extendFormikPath(path, 'selected'), false, false));
  }, [field.value?.licenseReports, licenseReportPath, periodPath, setFieldValue])

  if (!removable) return null;

  return (
    <StyledDiv className={className}>
      <Button type='button' {...field} onClick={onClick}>
        <img src="../../../assets/circle-cross.svg" alt="Remove payment period button"/>
      </Button>
    </StyledDiv>
  );
}
