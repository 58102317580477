export const DASHBOARD_SINGLE_RENEWAL_ACTION = 'singleRenewal';
export const DASHBOARD_BULK_RENEWAL_ACTION = 'bulkRenewal';
export const DASHBOARD_TAX_REMITTANCE_ACTION = 'taxRemittance';

export const DASHBOARD_ACTION_TYPE = {
  SINGLE_RENEWAL: DASHBOARD_SINGLE_RENEWAL_ACTION,
  Bulk_RENEWAL: DASHBOARD_BULK_RENEWAL_ACTION,
  TAX_REMITTANCE: DASHBOARD_TAX_REMITTANCE_ACTION,
};

// ------------------------------------------------------
// Tax Status Filters
// ------------------------------------------------------

export const TAX_STATUS_FILTER_ALL = 'all';
export const TAX_STATUS_FILTER_UP_TO_DATE = 'upToDate';
export const TAX_STATUS_FILTER_OVERDUE_REPORTING = 'overdueReporting';

// ------------------------------------------------------
// Sort Actions
// All these are in the format of: [Sorting type, Camel cased data key from "/userLicenses" API, ...]
// See "orderPermitsByField" of "src/pages/portal/dashboard/pages/permits-list/helpers.js" for usage
// ------------------------------------------------------

export const SORT_ACTION_ASC_PROPERTY_ADDRESS = ['asc', 'propertyAddress'];
export const SORT_ACTION_DESC_PROPERTY_ADDRESS = ['desc', 'propertyAddress'];

export const SORT_ACTION_ASC_PERMIT_NUMBER = [
  'asc',
  'licenseElseApplicationNumber',
];
export const SORT_ACTION_DESC_PERMIT_NUMBER = [
  'desc',
  'licenseElseApplicationNumber',
];

export const SORT_ACTION_ASC_EXPIRATION_DATE = [
  'asc',
  'expirationDate',
  'date',
];
export const SORT_ACTION_DESC_EXPIRATION_DATE = [
  'desc',
  'expirationDate',
  'date',
];

export const SORT_ACTION_ASC_PROPERTY_OWNER = ['asc', 'owner'];
export const SORT_ACTION_DESC_PROPERTY_OWNER = ['desc', 'owner'];

// See "parseMostRecentPaymentDetails"
export const SORT_ACTION_ASC_TAX_REPORTING = ['asc', 'numOverduePeriods'];
export const SORT_ACTION_DESC_TAX_REPORTING = ['desc', 'numOverduePeriods'];
