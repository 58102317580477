import React from 'react';
import { useSelector } from 'react-redux';
import { Card, Image } from 'semantic-ui-react';
import styled from 'styled-components';
import * as appSelectors from 'app/state/app/app-selectors';
import { styledAnchor, styledLink } from './section-card-styles';

const CardHeader = styled(Card.Header)`
  font-size: ${(props) => (`${props?.tileFontSize} !important`)};
`;

const SectionCard = ({ title, image, url, target, dataCy }) => {
  const theme = useSelector(appSelectors.theme);

  return (
    <Card
      as={target ? styledAnchor : styledLink}
      url={url}
      target={target}
      style={{ width: 'initial' }}
      data-cy={dataCy}
    >
      <Image src={`/assets/${image}`} wrapped ui={false} />
      <Card.Content>
        <CardHeader {...theme}>{title}</CardHeader>
      </Card.Content>
    </Card>
  );
};

export default SectionCard;
