import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components/macro';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import * as cs from 'common/common-styles';

const Container = styled.div`
  margin-top: 20px;
`;

const FileContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

function File({ file, removeFile }) {
  return (
    <FileContainer>
      <ClearRoundedIcon onClick={removeFile} style={{ marginRight: '8px' }} />
      <cs.BoldedLabel bold>{file.name}</cs.BoldedLabel>
    </FileContainer>
  );
}

export function FileList(props) {
  const files = useMemo(() => {
    if (!props?.files?.size) return [];
    return props?.files?.toJS();
  }, [props?.files]);

  const removeFile = useCallback((index) => {
    return () => props?.removeFile(index);
  }, [props]);

  if (!files?.length) return null;

  return (
    <Container>
      {files.map((file, index) => <File key={index} file={file} removeFile={removeFile(index)} /> )}
    </Container>
  )
}
