import get from 'lodash/get';
import isUndefined from 'lodash/isUndefined';
import template from 'lodash/template';
import React, { useCallback } from 'react';
import styled from 'styled-components';
import {
  convertNumberToCurrency,
  parseHTMLString,
} from 'utils/utility-functions';

const BoldDiv = styled.div`
  font-weight: bold;
`;

function TotalCell(props) {
  const { totalingParams } = props;

  const determineValue = useCallback(() => {
    const { method, type } = totalingParams;
    const data = props.data.map((d) => get(d, props.path));

    let rawValue;

    if (method === 'count') rawValue = data.length;
    if (method === 'sum') rawValue = data.reduce((a, c) => a + c, 0);

    switch (type) {
      case 'currency':
        return convertNumberToCurrency(rawValue);
      default:
        // Returns null if there is no defined method
        return rawValue;
    }
  }, [props.data, props.path, totalingParams]);

  const getCellDisplayValue = useCallback(() => {
    const { template: templateString } = totalingParams;
    const cellData = determineValue();
    return templateString
      ? parseHTMLString(template(templateString)({ val: cellData }))
      : cellData;
  }, [determineValue, totalingParams]);

  // Default the cell as the "Total" cell if it is the first cell
  if (props.isTotalLabel) return <BoldDiv>Total</BoldDiv>;

  // This is required to space out cells, so that totals align properly with their respective columns
  if (isUndefined(totalingParams)) return <BoldDiv />;

  return <BoldDiv>{getCellDisplayValue()}</BoldDiv>;
}

const Div = styled.div`
  height: 60px;
  max-height: 60px;
  width: ${(props) => (props.width ? `${props.width}px` : null)};

  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: ${(props) => props.columnWidths};

  /* 15px left right padding matches the padding used in the main table */
  padding: 15px 15px 0 15px;
  border-top: 1px solid black;
`;

export function DataTableTotals(props) {
  if (!props.show) return null;

  return (
    <Div width={props.width} columnWidths={props.columnWidths}>
      {props.columns.map((c, i) => (
        <TotalCell
          key={c.key}
          {...c}
          isTotalLabel={!i}
          path={c.key}
          data={props.data}
        />
      ))}
    </Div>
  );
}
