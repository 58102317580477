import * as appSelectors from 'app/state/app/app-selectors';
import { Input } from 'common/common-styles';
import * as lws from 'pages/flow-entry/license-warnings-styles';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import { Instructions } from '../styles';

const errorHighlight = css`
  border: ${(props) =>
    props.$isError ? '1px solid #e0211d' : '1px solid rgb(207, 207, 207)'};
  background-color: ${(props) =>
    props.$isError ? 'rgb(255, 247, 247)' : '#ffffff'};
  color: ${(props) => (props.$isError ? '#e0211d' : '#000000')};
`;

const StyledInput = styled(Input)`
  ${errorHighlight}
  width: 160px;
  margin-right: 10px;

  &:hover,
  &:focus {
    ${errorHighlight}
  }
`;

const StyledInstructions = styled(Instructions)`
  width: 465px;
  max-width: 100%;
`;

const StyledResendCode = styled.div`
  color: #027680;
  text-decoration: underline;
  &:hover {
    color: #027680;
    cursor: pointer;
  }
  margin-top: 20px;
`;

const StyledInvalidCodeResult = styled(lws.ErrorResult)`
  width: 400px;
  height: 48px;
  padding: 0 0 0 22px;
  margin-top: 20px;
  border: 1px solid #e0211d;
  background-color: rgb(255, 247, 247);
  color: #e0211d;
`;

const MAX_CODE_LENGTH = 6;

export function CodeEntry(props) {
  const {
    codeValue,
    setCodeValue,
    codeInvalid,
    setCodeInvalid,
    resendConfirmationCode,
    certificateText,
  } = props;

  const verbiage = useSelector(appSelectors.certificateVerbiage);

  const handleInputChange = useCallback(
    (val) => {
      setCodeValue(val);
      if (codeInvalid) setCodeInvalid(false);
    },
    [codeInvalid, setCodeInvalid, setCodeValue],
  );

  return (
    <>
      <StyledInstructions>
        We have sent a verification code to the email address associated with
        this {certificateText || verbiage}. Please enter the code below. Make
        sure to check your spam folder.
      </StyledInstructions>

      <div style={{ display: 'block' }}>
        <StyledInput
          $isError={codeInvalid}
          value={codeValue}
          onChange={handleInputChange}
          maxLength={MAX_CODE_LENGTH}
          data-cy="secret-code-input"
        />

        <StyledResendCode onClick={resendConfirmationCode}>
          Resend code
        </StyledResendCode>

        {codeInvalid && (
          <StyledInvalidCodeResult>
            <li>Invalid Code. Please enter a valid code to continue.</li>
          </StyledInvalidCodeResult>
        )}
      </div>
    </>
  );
}
