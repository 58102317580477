import React, { useMemo } from 'react';
import { PropertyIdentificationUI } from "../../pages/flow-entry/property-search/modules/property-identification";
import { useSelector } from "react-redux";
import * as appSelectors from "../../app/state/app/app-selectors";
import get from 'lodash/get';
import { evaluateCondition } from 'configurable-form/configurable-form-utils';
import { useFormikContext } from 'formik';

export function RentalDetailsEditor(props) {
  const {
    addressStatePath = 'payload.additionalInfo.propertyAddress',
    apnStatePath = 'payload.additionalInfo.parcelNumber',
  } = props;

  const { values } = useFormikContext();

  const appState = useSelector(appSelectors.appState);
  const address = get(appState, addressStatePath);
  const apn = get(appState, apnStatePath);

  const shouldShowUnitNumberField = useMemo(() => {
    if (Array.isArray(props?.showUnitNumberFieldCondition)) {
      return evaluateCondition(values, props?.showUnitNumberFieldCondition);
    }

    return props?.showUnitNumberFieldCondition;

    /**
     * No exhaustive dependencies as "evaluateCondition" would re-evaluate
     *  when the user types anything into the unit number field
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.showUnitNumberFieldCondition]);

  return (
    <PropertyIdentificationUI
      address={address}
      parcelNumber={apn}
      disableAddressInput
      shouldShowUnitNumberField={shouldShowUnitNumberField}
    />
  );
}
