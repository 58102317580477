import { BaseStrategy } from "./base-strategy";

export class ApplyLicenseStrategy extends BaseStrategy {
  static instance = null;

  static getInstance() {
    if (ApplyLicenseStrategy.instance) return ApplyLicenseStrategy.instance;

    ApplyLicenseStrategy.instance = new ApplyLicenseStrategy();
    return ApplyLicenseStrategy.instance;
  }

  get strategiesData() {
    return BaseStrategy.strategiesData.applyLicense;
  }
}
