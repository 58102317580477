import React from 'react';
import { withVisibility } from "./utils/with-visibility";
import * as cs from 'common/common-styles';
import styled from "styled-components/macro";
import MUIAccordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { renderFields } from "./fragment";

const StyledAccordion = styled(MUIAccordion)`
  ${cs.greyBackground}
`;

const StyledAccordionSummary = styled(AccordionSummary)`
  ${cs.greyBackground}
`;

function AccordionComponent({ fields, header }) {
  return (
    <StyledAccordion>
      <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
        {header}
      </StyledAccordionSummary>
      <cs.AccordionDetail>
        {renderFields(fields)}
      </cs.AccordionDetail>
    </StyledAccordion>
  )
}

export const Accordion = withVisibility(AccordionComponent);
