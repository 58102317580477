import React, { useMemo } from 'react';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import * as networkSelectors from 'app/state/network/network-selectors';
import * as payloadSelectors from 'app/state/payload/payload-selectors';
import * as appSelectors from 'app/state/app/app-selectors';
import { dayjs } from 'utils/dayjs';
import {
  convertFeeDictToArray,
  convertFeeDictToArrayWithCalculatedSummaryValues,
  convertNumberToCurrency,
} from 'utils/utility-functions';
import {
  addVectors,
  formatProperty,
  useLicensesInReportMap,
} from 'pages/pay-flow/report-revenue-page/utils';
import {
  FeesListGrouped,
  FeesListGroupedAndChunked,
  StaticKeyValuePairVerticalLayoutItem,
} from './fees-list-grouped';
import { RowRender, TableLayout } from './fees-table-components';
import { PROPERTY_REPORTING_COLUMN_FIELD_PLACEHOLDER } from 'pages/pay-flow/report-revenue-page/modules/tot-payment-periods/property-columns';
import { typePaymentPeriod } from 'pages/pay-flow/report-revenue-page/constants';

function wrapItem(type, item, key) {
  if (type === 'array') return [item];
  if (type === 'object' && Boolean(key)) return { [key]: item };
  return item;
}

function objectToArrayIfNeeded(objOrArray, dictKeyField) {
  if (Array.isArray(objOrArray)) return objOrArray;
  return Object.keys(objOrArray).map(key => ({ ...objOrArray[key], [dictKeyField]: key }));
}

export function flattenPaymentPeriodData(data, nestedFieldsToFlatten) {
  let result = [data];

  let nestedFieldPrefix = '';

  for (const nestedField of nestedFieldsToFlatten) {
    const { field, wrap: type, alias = field, dictKeyField = null } = nestedField;

    const fullyQualifiedFieldName = `${nestedFieldPrefix}${field}`;

    result = result.flatMap(data => {
      const objOrArray = get(data, fullyQualifiedFieldName);

      const pObjOrArray = wrapItem(type, objOrArray, alias);
      const elementArray = objectToArrayIfNeeded(pObjOrArray, dictKeyField);

      return elementArray.map(element => ({ ...data, [alias]: element }));
    });

    nestedFieldPrefix = `${alias}.`
  }

  return result;
}

export function groupRows(data, groupField, groupOrder, aggregateFields) {
  if (!groupField) return data;

  const unorderedGroups = groupBy(data, (row) => get(row, groupField));

  const groupOrderToUse = groupOrder.length ? groupOrder : Object.keys(unorderedGroups);

  return groupOrderToUse
    .map(groupValue => {
      const groupRows = unorderedGroups[groupValue];
      const initialTotals = aggregateFields.map(() => 0);

      const totals = groupRows
        .map(row => aggregateFields.map((field) => get(row, field)))
        .reduce((acc, x) => addVectors(acc, x), initialTotals);

      return ({
        [groupField]: groupValue,
        ...(Object.assign(...aggregateFields.map((field, i) => ({ [field]: totals[i] }))))
      });
  })

}

function groupPeriodData(periodDetails, nestedFieldsToFlatten, groupField, groupOrder, aggregateFields) {
  const flatData = flattenPaymentPeriodData(periodDetails, nestedFieldsToFlatten);

  return groupRows(flatData, groupField, groupOrder, aggregateFields);
}

const SEPARATOR_GREY_BRIGHTNESS = 180
const ACCENT_GREY_BRIGHTNESS = 244

function getRGBFromBrightness(brightness) {
  return new Array(3).fill(0).map(() => brightness).join(" ,")
}

const SEPARATOR_GREY_RGB = getRGBFromBrightness(SEPARATOR_GREY_BRIGHTNESS);
const ACCENT_GREY_RGB = getRGBFromBrightness(ACCENT_GREY_BRIGHTNESS);

const StyledRow = styled(RowRender)`
  padding-left: 10px;
  padding-right: 10px;
  border-top: ${(props) => (props.separator || props.thinSeparator) ? `${props.thinSeparator ? 1 : 3}px solid rgb(${props.accentSeparator ? ACCENT_GREY_RGB : SEPARATOR_GREY_RGB})` : ''};
  ${({ accent }) => accent ? `background-color: rgb(${ACCENT_GREY_RGB});` : ''}
`;

const StyledGroupRow = styled(StyledRow)`
  &:nth-child(even) {
    background-color: #f4f4f4;
  }
`;

const StyledGroupSummaryRow = styled(StyledRow)`
  margin-bottom: 20px;
`

const PAYMENT_FEES_PERIOD_DATE_FORMAT = 'YYYY/MM/DD';

function parsePaymentFeeDate(dateString) {
  return dayjs(dateString, PAYMENT_FEES_PERIOD_DATE_FORMAT);
}

/*
When rendering striped rows for multiple periods, we want to make sure
that multiple periods' rows aren't all under the same parent element so
that we "re-start" striping rows for every period for a more consistent look
for each of the tables.
 */
const RestartStriping = 'div';

/**
 * @typedef {Object} NestedFieldsConfiguration
 * @property {string} field
 * @property {string} alias
 * @property {string | undefined} dictKeyField
 */

/**
 * @typedef {Object} GroupDisplayConfiguration
 * @property {string} groupFieldValue
 * @property {string} label
 */
/**
 * A variation of FeesTable that supports displaying multiple rows per period
 * to show tax calculation breakdown (e.g. TOT vs TBID for the same payment period in Indio)
 *
 * Each payment period consists of multiple rows that group taxes by a particular
 * attribute (e.g. a tax type that could be TOT or TBID), and a summary row, which
 * aggregates all of the tax fields for the entire period
 *
 *
 * @param {string[]} props.tableHeaders  Column names
 * @param {string[]} props.summaryRowDataKeys  Fields used for the the summary row for each period
 * @param {NestedFieldsConfiguration[]} props.nestedFieldsToFlatten For every array field specified in the array, a cartesian
 * product of the payment period objects with the elements of the array would be taken, with each resulting augmented payment
 * period containing its corresponding array field element in a new field specified by `alias`
 *
 * If `field` corresponds to a property that is a map/object, the result will be the cartesian product of the payment
 * periods with the values of the fields in the object. Another extra field would be added to contain the key of the object value
 * (specified by `dictKeyField`).
 *
 * This process is best thought of as a join between payment periods and taxable activity arrays that each period
 * contains. The idea was inspired by a feature in an SQL engine: https://prestodb.io/docs/current/sql/select.html#unnest
 * (see the examples of the `CROSS JOIN UNNEST...` approach, and note the similarity with joining our TOT periods with
 * taxableActivities and taxTypes within each taxable activity).
 *
 * @param {string} props.groupField Field name to group by to produce detail rows for each period. Can contain dots to reference
 * deeply-nested field, and can reference `alias` fields that resulted from "unnesting" nestedFieldsToFlatten (see above)
 *
 * @param {GroupDisplayConfiguration[]} props.groupDisplayConfiguration A list of objects representing a value of the group field
 * and the corresponding user-friendly label to display. The ordering of these objects determines the order in which the grouped
 * detail rows are displayed (e.g. in case of Indio, this is an ordered list of tax types to be displayed for each period).
 *
 * @param {string[]} props.groupedRowDataKeys A list of fields (potentially with dots for deeply-nested access) to aggregate
 * for each detail row (e.g. for Indio, a list of tax-related amounts to sum up for each tax type). The ordering of these fields
 * must correspond to the column labels in `tableHeaders`.
 *
 * @returns {JSX.Element}
 * @constructor
 */
export function FeesTableGrouped(props) {
  const {
    tableHeaders = [],
    summaryRowDataKeys = [],
    groupedRowDataKeys = [],
    nestedFieldsToFlatten = [],
    groupField,
    additionalCSS,
    useVerticalLayout = false,
    verticalLayoutItems = [],
  } = props;

  const groupDisplayConfiguration = props.groupDisplayConfiguration.filter(c => !!c.groupFieldValue)

  const groupDisplayConfigurationByGroupValue  = useMemo(() => groupDisplayConfiguration.reduce((acc, groupConfig) => ({
    ...acc,
    [groupConfig.groupFieldValue]: groupConfig,
  }), {}), [groupDisplayConfiguration]);

  const config = useSelector(appSelectors.renderConfig);

  const paymentFees = useSelector(networkSelectors.paymentFees);

  const license = useSelector(payloadSelectors.license);

  const licenseIdToLicense = useLicensesInReportMap();

  const tableData = useMemo(() => {
    return paymentFees?.paymentPeriods?.slice()
      .sort((a, b) => parsePaymentFeeDate(b.startDate).diff(parsePaymentFeeDate(a.startDate)))
      .map(paymentPeriod => ({
        ...paymentPeriod,
        licenseReports: paymentPeriod.licenseReports.map(licenseReport => ({
          ...licenseReport,
          [PROPERTY_REPORTING_COLUMN_FIELD_PLACEHOLDER]: formatProperty(licenseIdToLicense[licenseReport.licenseId]),
        }))
      }))
      .map((data) => {
        const { period } = data;

        const groupOrder = groupDisplayConfiguration.map(({ groupFieldValue }) => groupFieldValue);

        const groupedRows = groupPeriodData(
          data,
          nestedFieldsToFlatten,
          groupField,
          groupOrder,
          groupedRowDataKeys
        );

        const calculatedSummaryRowKeys = summaryRowDataKeys.filter(k => k && k.aggregateField);
        const calculatedSummaryRowValues = groupedRows
          .reduce((totals, row) => addVectors(totals, calculatedSummaryRowKeys.map(k => row[k.aggregateField])),
            calculatedSummaryRowKeys.map(() => 0));

        const calculateSummaryRowValueMap = calculatedSummaryRowKeys
          .reduce((acc, k, i) => ({ ...acc, [k.aggregateField]: calculatedSummaryRowValues[i] }), {});

        const preparedSummaryRow =  convertFeeDictToArrayWithCalculatedSummaryValues(data, summaryRowDataKeys, calculateSummaryRowValueMap);

        preparedSummaryRow[0] = 'Subtotal for ' + period;

        const preparedGroupedRows = groupedRows.map(row => {
          const labelledRow = { ...row, [groupField]: groupDisplayConfigurationByGroupValue[row[groupField]]?.label || row[groupField] };
          return convertFeeDictToArray(labelledRow, groupedRowDataKeys, groupField)
        })

        return {
          period,
          groupedPeriodData: groupedRows,
          groupedRows: preparedGroupedRows,
          summaryData: data,
          summaryRow: preparedSummaryRow,
          calculateSummaryRowValueMap,
        };
      })
  }, [paymentFees?.paymentPeriods, licenseIdToLicense, groupDisplayConfiguration, nestedFieldsToFlatten, groupField, groupedRowDataKeys, summaryRowDataKeys, groupDisplayConfigurationByGroupValue])

  const summaryRowData = useMemo(
    () => {
      const calculatedSummaryValues = tableData.reduce((acc, { calculateSummaryRowValueMap }) =>
        Object.keys(calculateSummaryRowValueMap).reduce((partialResult, key) => ({
          ...partialResult,
          [key]: (partialResult[key] || 0) + (calculateSummaryRowValueMap[key] || 0)
        }), acc), {});

      return convertFeeDictToArrayWithCalculatedSummaryValues(paymentFees?.summary, summaryRowDataKeys, calculatedSummaryValues);
    },
    [paymentFees?.summary, summaryRowDataKeys, tableData]
  );

  const isPaymentPeriodDynamic = config?.reporting?.isPaymentPeriodDynamic;
  const isDynamicQuarter = isPaymentPeriodDynamic && license?.totPaymentPeriod === typePaymentPeriod.QUARTERLY;

  if (useVerticalLayout) {
    const FeesListComponent = isDynamicQuarter ? FeesListGroupedAndChunked : FeesListGrouped;

    return (
      <>
        <FeesListComponent
          groupPeriods={tableData}
          feeItems={verticalLayoutItems}
          groupField={groupField}
          groupDisplayConfigurationByGroupValue={groupDisplayConfigurationByGroupValue}
        />

        <StaticKeyValuePairVerticalLayoutItem
          style={{
            'font-weight': 'bold',
            'font-size': '18px',
            'padding': '10px 0',
            'margin-top': '50px',
            'background': '#ddd'
          }}
          label={"Total Tax Due"}
          value={convertNumberToCurrency(paymentFees?.summary?.total)}
        />
      </>
    );
  }

  return (
    <TableLayout css={additionalCSS}>
      {/* Render the headers row first */}
      <StyledRow forceBolding={true} data={tableHeaders} />

      {tableData?.map(({ period, groupedRows, summaryRow }, periodIndex) => {
        return (
          <RestartStriping key={period}>
            <StyledRow separator={periodIndex < 1} accentSeparator key={period} data={[period]} />
            {groupedRows.map(row => <StyledGroupRow key={get(row, groupField)} data={row} />)}
            {(!props?.disableSubtotal) && <StyledGroupSummaryRow thinSeparator data={summaryRow} />}
          </RestartStriping>
        );
      })}

      <StyledRow accent forceBolding={true} data={summaryRowData} />
    </TableLayout>
  );
}
