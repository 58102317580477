import React, { useMemo } from 'react'
import styled from 'styled-components';
import { FieldItem } from 'common/field-item';
import { ONE } from 'common/constants';
import { first } from 'lodash';

const BaseLabel = styled.p`
  font-weight: bold;
  margin-bottom: 15px;
`;

const Label = styled(BaseLabel)`
  font-size: 18px;
`;

const ListLabel = styled(BaseLabel)`
  font-size: 18px;
`;

const Flex = styled.div`
  display: flex;
  flex-direction: column;
`;

const FlexDisplay = styled(Flex)`
  display: flex;
  flex-direction: column;
  gap: 17px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
`;

function List(props) {
  return (
    <FlexDisplay>
      {props.list.map((item) => <FieldItem key={item?.key} {...item} />)}
    </FlexDisplay>
  );
}

export function SummarySubsection(props) {
  const { label, listLabel, config = [], isGrid } = props;

  const isNestedConfig = useMemo(() => Array.isArray(first(config)), [config]);
  const LabelComponent = useMemo(() => isGrid ? ListLabel : Label, [isGrid]);

  const Display = isGrid ? Grid : FlexDisplay;

  if (!config.length) return null;

  return (
    <Flex>
      {Boolean(label) && <LabelComponent>{label}</LabelComponent>}

      <Display>
        {isNestedConfig ? (
          config.map((el, idx) => (
            <SummarySubsection
              key={`nested-sub-${idx}`}
              label={listLabel && `${listLabel} #${idx + ONE + props.startingIndex}`}
              config={el}
            />
          ))
        ) : (
          <List list={config} />
        )}
      </Display>
    </Flex>
  );
}
