import React, { useMemo } from 'react';
import styled from 'styled-components/macro';
import { useFormikContext } from 'formik';
import { DEFAULT_FORM_VALUE as emptyString } from 'common/constants';
import { evaluateCondition } from 'configurable-form/configurable-form-utils';
import { parseHTMLString } from 'utils/utility-functions';

const requiredString = ' (Required)';

const DocumentTitle = styled.div`
  font-family: 'Lato';
  font-size: 15px;
  font-weight: bold;
  color: #000000;
  margin-bottom: 10px;
`;

const DocumentHelperText = styled.div`
  font-family: 'Lato';
  font-size: 14px;
  line-height: 1.5;
  color: #5c5d5d;
`;

// See "DocumentText" from "types/fields/component-props/file-upload.ts"
export function DocTextRender(props) {
  const { text, fileKey } = props;
  const { values } = useFormikContext();

  const Component = props?.title ? DocumentTitle : DocumentHelperText;

  const renderText = useMemo(() => {
    if (!text) return;

    if (Array.isArray(text)) {
      return text.map(({ condition, text }) =>
        evaluateCondition(values, condition)
          ? `${text}${props.required ? requiredString : emptyString}`
          : emptyString,
      );
    }

    return [`${text}${props.required ? requiredString : emptyString}`];
  }, [props.required, text, values]);

  if (!renderText) return null;

  return (
    <Component>
      {renderText.map((el, idx) => (
        <div key={`${fileKey}-${props?.title ? 'title' : 'helper'}-${idx}`}>
          {parseHTMLString(el)}
        </div>
      ))}
    </Component>
  );
}
