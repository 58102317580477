import isUndefined from 'lodash/isUndefined';
import { MAX_FILE_SIZE, MIN_FILE_SIZE } from 'common/constants';

/**
 * When the "required" config is in the format of:
 *  required: {
 *    condition: ...
 *  }
 *
 * Then we want to evaluate the condition to determine whether or not the
 *  document type in question is required to be uploaded via the
 *  "evaluateCondition" function
 */
export function isDocFormikRequired(requirementConfig) {
  return !isUndefined(requirementConfig?.condition);
}

export function filterFilesBySize(files) {
  const largeFilesList = [];
  const smallFilesList = [];

  const filesList = Array.from(files)
    .filter((file) => {
      if (file.size > MAX_FILE_SIZE) largeFilesList.push(file.name);
      if (file.size < MIN_FILE_SIZE) smallFilesList.push(file.name);
      return file.size <= MAX_FILE_SIZE && file.size >= MIN_FILE_SIZE;
    });

  return { filesList, largeFilesList, smallFilesList }
}
