import { createSelector } from '@reduxjs/toolkit';

export const payload = (state) => state.payload;

export const license = createSelector(
  payload,
  (payloadState) => payloadState.license,
);

export const licenses = createSelector(
  payload,
  (payloadState) => payloadState.licenses,
);

// email specifically for the email entered in input in update flow
export const lookupModeEmail = createSelector(
  payload,
  (payloadState) => payloadState.lookupModeEmail?.trim(),
);

export const additionalInfo = createSelector(
  payload,
  (payloadState) => payloadState.additionalInfo,
);

export const addressLookupError = createSelector(
  payload,
  (payloadState) => payloadState.addressLookupError,
)

export const listingUrls = createSelector(
  payload,
  (payloadState) => payloadState.listingUrls,
);

export const messageInput = createSelector(
  payload,
  (payloadState) => payloadState.messageInput,
);

export const formikSnapshot = createSelector(
  payload,
  (payloadState) => payloadState.formikSnapshot,
);

export const updateCertificatePayload = createSelector(
  messageInput,
  license,
  (message, licData) => ({
    requestMessage: message,
    applicationNumber: licData?.applicationNumber,
  }),
);
