import React from 'react';

const EmptyFieldComponent = (personLabel) => (
  <p key={`emptyFields-${personLabel}`}>
    Make sure all required fields related to the <b>{personLabel}</b> are filled properly.
  </p>
);

const InvalidPhoneNumberComponent = (personLabel) => (
  <p key={`invalidPhoneNumber-${personLabel}`}>
    Invalid phone number for the <b>{personLabel}</b>.
  </p>
);

const InvalidEmailComponent = (personLabel) => (
  <p key={`invalidEmailAddress-${personLabel}`}>
    Invalid email address for the <b>{personLabel}</b>.
  </p>
);

const InvalidPhoneAndEmailComponent = (personLabel) => (
  <p key={`invalidPhoneAndEmail-${personLabel}`}>
    Invalid phone number and email address for the <b>{personLabel}</b>.
  </p>
);

export const ERROR_MESSAGES = {
  // ApplyTOT Flow
  InvalidParcelLength: 'Parcel number does not meet the length requirement. (12 digits)',
  MissingRentalType: 'Please make a selection for the type of rental before continuing.',
  MissingCertificateHolder: 'Please make a selection for the certificate holder.',
  MissingLocalContactPerson: 'Please make a selection for the local contact person.',

  // PayTOT Flow
  PayACHError: 'Payment processing for ACH payment methods are currently unavailable. Please try again at another time.',

  // UpdateTOT Flow
  OperatesRentalIsNull: "Sorry we can't update your details right now please try back later. This attempt has been logged and our developers have been notified.",

  // Shared
  NoErrors: '',
  NoErrorsArray: '',
  EmptyFields: 'Make sure all required fields are filled properly.',
  InvalidPhoneNumber: 'Invalid phone number.',
  InvalidEmailAddress: 'Invalid email address.',
  InvalidPhoneAndEmail: 'Invalid phone number and email address.',
  /**
   * Returns HTML for error messages for property manager and local point of contact forms
   * @param {String} personLabel either "property manager" or "local contact person"
   */
  EmptyFieldsLabeled: EmptyFieldComponent,
  InvalidPhoneNumberLabeled: InvalidPhoneNumberComponent,
  InvalidEmailAddressLabeled: InvalidEmailComponent,
  InvalidPhoneAndEmailLabeled: InvalidPhoneAndEmailComponent,
};
