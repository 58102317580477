import { DEFAULT_FORM_VALUE } from 'common/constants';
import isNil from 'lodash/isNil';
import isNumber from 'lodash/isNumber';
import toNumber from 'lodash/toNumber';
import { InputNumber } from 'rsuite';
import styled from 'styled-components';
import { formatValue } from "../../../utils";
import { FORMAT_CURRENCY } from "../../../constants";

export const REPORT_REVENUE_DATA_CY_PREFIX_STRING = 'report-revenue';

export const createCurrencyValidation = ({ lessThanAmount = 1000000 }) => ({
  validationType: 'number',
  rules: [
    { type: 'required', params: ['This field is required.'] },
    { type: 'min', params: [0, 'The minimum value accepted is 0.'] },
    { type: 'lessThan', params: [lessThanAmount, `The maximum value accepted is ${formatValue(lessThanAmount - 0.01, FORMAT_CURRENCY)}`] },
  ],
});

export const InputContainer = styled.div`
  max-width: 400px;
  width: 100%;
`;

export const StyledInputNumber = styled(InputNumber)`
  input {
    margin-left: 25px;
  }
`;

export function checkIsRealNumber(value) {
  const number = toNumber(value);
  if (isNaN(number) || isNil(number)) return false;
  return isNumber(number);
}

function checkHasExcessDecimalPlaces(value) {
  const hasExcessDecimalPlaces = value.includes('.')
  && value.length - value.indexOf('.') > 3; // 3 as "indexOf" is 0 indexed
  return hasExcessDecimalPlaces;
}

export function convertToInputNumberFieldValue(input) {
  if (input === DEFAULT_FORM_VALUE) return DEFAULT_FORM_VALUE;

  const inputIsRealNumber = checkIsRealNumber(input);
  if (!inputIsRealNumber) return DEFAULT_FORM_VALUE;

  const numberString = String(input);
  return checkHasExcessDecimalPlaces(numberString)
    // Use "slice" method as "toFixed" will round up based on the last digit value
    ? numberString.slice(0, -1) : numberString;
}
