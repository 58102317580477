import { createSlice, current } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';
import concat from 'lodash/concat';
import get from 'lodash/get';
import merge from 'lodash/merge';
import isNil from 'lodash/isNil';
import dayjs from 'dayjs';

import {
  DASHBOARD_FAILED_STATUS,
  DASHBOARD_INFLIGHT_STATUS,
  DASHBOARD_SUCCESS_STATUS,
} from './constants';
import { TOT_PAYMENT_STATUS_INCOMPLETE } from 'common/constants';

function determineNextStatus(data, fetchFailed) {
  if (!isNil(data)) return DASHBOARD_SUCCESS_STATUS;
  if (fetchFailed) return DASHBOARD_FAILED_STATUS;
  return DASHBOARD_INFLIGHT_STATUS;
}

const INIT_STATE = {
  // Stores the parsed data of "/userLicenses"
  userPermitsList: { status: DASHBOARD_INFLIGHT_STATUS, data: undefined },

  // Stores the parsed data of "/renewableLicenses"
  bulkRenewableLicenses: { status: DASHBOARD_INFLIGHT_STATUS, data: undefined },

  // Stores the data of "/reportingHistoriesByUserInChunks"
  userTaxReportingHistory: {
    status: DASHBOARD_INFLIGHT_STATUS,
    data: undefined,
  },

  /**
   * Populated on the dashboard page
   * The data stored comes from the "/totPaymentPeriods" endpoint tranformed via the "camelCaseDictKeys" in /src/pages/portal/dashboard/helper.js
   * The licenses property has a "selected" proeprty added to it on the action-item component
   * Used throughout the tax flow
   */
  taxableLicensePeriods: { status: DASHBOARD_INFLIGHT_STATUS, data: undefined },

  /**
   * Stores the start date and end date of the period the users wants to pay tax for
   * Should be an object with properties of "startDate" and "endDate"
   * Populated when th user clicks on the "Pay Tax" button on the actions section of the dashboard
   * Used to show the specified taxable period on load of the bulk tax select properties page
   */
  preSelectedTaxableLicensePeriod: undefined,

  /**
   * Stores only the licenses/permits that are being renewed.
   * This is data to be used in the "confirmation" page of the bulk renewal flow.
   */
  bulkLicensesToBeRenewed: null,

  /**
   * This stores application number of the active permit that the user is
   * attempt to action upon. This is mainly so that the Customer Portal
   * strategy instance only need to look at once place in state for this
   * piece of information
   */
  activeSinglePermitApplicationNumber: null,

  /**
   * This is a list of application * numbers of the permits selected in
   * the "PermitsListView" (specifically in the "PermitsTable" component).
   *
   * The data is used to inform the available flows where bulk processing can
   * occur (bulk renewal and bulk TOT payments) of which permits should be
   * actioned/pre-selected for the user when entering the bulk flow via
   * an alternative pathway (e.g. from "PermitsListView").
   */
  preselectedPermitsByApplicationNumber: null,

  /**
   * Stores only the licenses/permits that are selected to pay tax on
   * Data is set on the tax flow select properties page and when a user clicks on pay tax in the reporting history
   * component of the single license view
   * This is data used on the report revenue, and confirmation pages of the tax flow
   * Should be an array of the following objects
   *  {
   *    startDate: e.g. 2023-09-20
   *    endDate: e.g. 2023-09-20
   *    name: Period label e.g. July 2023
   *    licenses: [
   *    {
   *      address:
   *      license: licenseNumber
   *      ownerName:
   *    }
   * ]
   * }
   */
  licensesToPayTaxOn: null,
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: INIT_STATE,
  reducers: {
    setUserPermitsList: (state, action) => {
      const { data, failed } = action.payload;

      const nextState = {
        status: determineNextStatus(data, failed),
        data: !isNil(data)
          ? data
          : get(current(state), 'userPermitsList.data', undefined),
      };

      state.userPermitsList = nextState;
    },

    setBulkRenewableLicenses: (state, action) => {
      const { data, failed } = action.payload;

      const nextState = {
        status: determineNextStatus(data, failed),
        data: !isNil(data)
          ? data
          : get(current(state), 'bulkRenewableLicenses.data', undefined),
      };

      state.bulkRenewableLicenses = nextState;
    },

    setBulkLicensesToBeRenewed: (state, action) => {
      state.bulkLicensesToBeRenewed = action.payload;
    },

    setTaxableLicensePeriods: (state, action) => {
      const { data, failed } = action.payload;

      const nextState = {
        status: determineNextStatus(data, failed),
        data: !isNil(data)
          ? data
          : get(current(state), 'taxableLicensePeriods.data', undefined),
      };

      state.taxableLicensePeriods = nextState;
    },

    setPreSelectedTaxableLicensePeriod: (state, selectedPeriod) => {
      state.preSelectedTaxableLicensePeriod = selectedPeriod;

      // Pre select and properties for the selected license period that have a status of "incomplete"
      const { startDate, endDate } = selectedPeriod?.payload || {};
      if (startDate && endDate) {
        const taxableLicensePeriods = cloneDeep(get(current(state), 'taxableLicensePeriods', { status: DASHBOARD_INFLIGHT_STATUS, data: undefined }));
        const match = taxableLicensePeriods?.data?.paymentPeriods.find(period => dayjs(period.startDate).isSame(dayjs(startDate)) && dayjs(period.endDate).isSame(dayjs(endDate)));

        if (match) {
          match.licenses = match.licenses.map(license => {
            if (license.totPaidStatus === TOT_PAYMENT_STATUS_INCOMPLETE) {
              return merge({}, license, { selected: true });
            }
            return license;
          });
        }
        state.taxableLicensePeriods = taxableLicensePeriods;
      }
    },

    setLicensesToPayTaxOn: (state, action) => {
      state.licensesToPayTaxOn = action?.payload;
    },

    clearUserTaxReportingHistory: (state) => {
      state.userTaxReportingHistory = {
        status: DASHBOARD_INFLIGHT_STATUS,
        data: undefined,
      };
    },

    updateUserTaxReportingHistory: (state, action) => {
      /**
       * Data looks like
       * https://deckardtech.postman.co/workspace/Team-Workspace~2aea37f6-06d9-4b52-8ffe-f69edce926cb/example/19413758-3933a729-452a-4184-bbc2-6d81b4700378
       */
      const { data, status, failed } = action.payload;

      if (failed) {
        state.userTaxReportingHistory = {
          status: DASHBOARD_FAILED_STATUS,
          data: undefined,
        };
        return;
      }

      const noDataInStore = isNil(
        get(current(state), 'state.userTaxReportingHistory.data', undefined),
      );

      /**
       * Status in this context is raw value "contToken" received in "/reportingHistoriesByUserInChunks" API.
       *
       * "contToken" can be either "null" or a number, starts from 1.
       * A value of "null" would indicate to the client that there we are done retrieving the data for the user.
       * A number for "contToken" would mean that there is still more data to be retrieved and the next set can be
       *  requested by utilizing the value of "contToken" in the query parameters for the request.
       */
      const finishedRetrievingUserReportingHistory = !status;

      state.userTaxReportingHistory = {
        data: {
          licenses: noDataInStore
            ? data.licenses
            : concat(
              cloneDeep(
                get(
                  current(state),
                  'state.userTaxReportingHistory.data.licenses',
                  [],
                ),
              ),
              data.licenses,
            ),
        },
        status: finishedRetrievingUserReportingHistory
          ? DASHBOARD_SUCCESS_STATUS
          : DASHBOARD_INFLIGHT_STATUS,
      };
    },

    setActiveSinglePermitApplicationNumber: (state, action) => {
      state.activeSinglePermitApplicationNumber = action.payload;
    },

    setPreselectedPermitsByApplicationNumber: (state, action) => {
      state.preselectedPermitsByApplicationNumber = action.payload;
    },

    cleanDashboard: (state) => {
      /**
       * The difference "cleanDashboard" and "resetDashboard" is that "cleanDashboard"
       * is meant to clean up the dashboard related pieces of state without impacting
       * the main dash view.
       *
       * So the following are not put back to their default state, as it would put back
       *  various UI components back to their initial state.
       *  - userPermitsList
       *  - bulkRenewableLicenses
       *  - userTaxReportingHistory
       */
      state.bulkLicensesToBeRenewed = null;
      state.licensesToPayTaxOn = null;
      state.activeSinglePermitApplicationNumber = null;
      state.preselectedPermitsByApplicationNumber = null;
    },

    resetDashboard: () => INIT_STATE,
  },
});

export const dashboardActions = dashboardSlice.actions;

export const dashboardReducer = dashboardSlice.reducer;
