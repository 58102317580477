import React from 'react';
import { Grid, Row, Col } from 'rsuite';
import { PropertyTaxableReceiptsComponent } from './property-taxable-recepits';

export function TaxableReceiptsMonthOfYearComponent({ periodData, deductionsLabel, salesLabel, taxableReceiptsLabel, showPropertyOccupancySection, periodSpacing, propertySpacing }) {
  return (
    <Grid fluid style={{ marginBottom: periodSpacing }}>
      <Row style={{ marginBottom: '30px' }}>
        <Col xs={12} style={{ fontWeight: 'bold', fontSize: '24px' }}>
          {periodData.name}
        </Col>
      </Row>
      {
        periodData.licenses.map((item, index) => (
          <PropertyTaxableReceiptsComponent key={index} propertyAddress={item.address} deductionsLabel={deductionsLabel} salesLabel={salesLabel}
            taxableReceiptsLabel={taxableReceiptsLabel} showPropertyOccupancySection={showPropertyOccupancySection}
            startDate={periodData.startDate} endDate={periodData.endDate} license={item.license}
            propertySpacing={index === periodData?.licenses?.length - 1 ? '0px' : '25px'}
          />
        ))
      }
    </Grid>
  );
}
