import React, { useMemo } from 'react';
import { useFormikContext } from 'formik';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import { useBulkTOTEnabled, useLicensesInReport } from '../../utils';
import { BulkTOTUploadReporting } from './bulk-tot-upload-reporting';
import { ManualTOTReporting } from './manual-tot-reporting';
import { useToggleDynamicQuarterPeriod } from '../report-additional-revenue-dynamic';
import { useTogglePeriod } from '../report-additional-revenue';
import { ORDER_ASC } from '../../constants';

export function ReportTOT(props) {
  const {
    isDynamicQuarter,
    availablePeriodsById,
    addPeriodsButton,
    taxableItemExtraParams,
    valuesHaveData,
    continueButtonDisabled,
    chronologicalPeriodsOrder,
  } = props;

  const {
    values: {
      taxablePeriods: paymentPeriodsById,
      quarterlyTaxablePeriods
    },
  } = useFormikContext();

  const chronologicalPeriods = useMemo(
    () => {
      const paymentPeriods = Object.values(paymentPeriodsById);
      if (isEmpty(paymentPeriods)) return [];
      return cloneDeep(paymentPeriods).sort((a, b) =>
      chronologicalPeriodsOrder === ORDER_ASC ?
        a.dateStartTimestamp - b.dateStartTimestamp:
        b.dateStartTimestamp - a.dateStartTimestamp
      );
    },
    [paymentPeriodsById, chronologicalPeriodsOrder]
  );

  const isBulkTOTUploadEnabled = useBulkTOTEnabled();

  const ReportingComponent = isBulkTOTUploadEnabled ? BulkTOTUploadReporting : ManualTOTReporting;

  const licensesInReport = useLicensesInReport();

  const toggleDynamicQuarterPeriod = useToggleDynamicQuarterPeriod({
    availablePaymentPeriods: Object.values(availablePeriodsById),
    licensesInReport
  });

  const toggleNonDynamicPeriod = useTogglePeriod({ licensesInReport });

  const toggleDynamicQuarterPeriodFn = (period) => toggleDynamicQuarterPeriod({ paymentPeriod: period, isDynamicQuarter });
  const toggleNonDynamicPeriodFn = (period) => toggleNonDynamicPeriod({ checked: false, period, periodFieldValue: period });

  const removeEnabledPeriod = isDynamicQuarter ? toggleDynamicQuarterPeriodFn : toggleNonDynamicPeriodFn;

  return (
    <>
      <ReportingComponent
        removeEnabledPeriod={removeEnabledPeriod}
        chronologicalPeriods={chronologicalPeriods}
        paymentPeriodsById={paymentPeriodsById}
        isDynamicQuarter={isDynamicQuarter}
        quarterlyTaxablePeriods={quarterlyTaxablePeriods}
        addPeriodsButton={addPeriodsButton}
        taxableItemExtraParams={taxableItemExtraParams}
        availablePeriodsById={availablePeriodsById}
        valuesHaveData={valuesHaveData}
        continueButtonDisabled={continueButtonDisabled}
      />

      {!isBulkTOTUploadEnabled && addPeriodsButton}
    </>
  )
}

