import React, { useCallback, useMemo, useState } from 'react';
import debounce from 'lodash/debounce';
import first from 'lodash/first';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { Alert, AutoComplete, InputGroup, Loader } from 'rsuite';
import styled from 'styled-components';
import { FormikError } from 'configurable-form/components/inputs/shared-components';
import { useInput } from 'configurable-form/components/utils/use-input';
import { withVisibility } from 'configurable-form/components/utils/with-visibility';
import { captureExceptionWithContext } from 'utils/sentry-functions';
import { strRegistrationApiSender } from 'utils/str-registration-api-sender';
import { usePropertyLookup } from '../hooks/usePropertyLookup';
import { BasicError } from './basic-error';
import { shouldQuery } from './shared';

const Div = styled.div`
  max-width: 600px;
`;

const Label = styled.div`
  font-size: 18px;
`;

// ! This component is intended for use within "Property Lookup" component.
// ! As logic and props are tied to the parent "Property Lookup" component.
function AddressToAPNSearchComponent(props) {
  const {
    selectedAddressAPN,
    setSelectedAddressAPN,
  } = props;

  const { clearStoredStates } = usePropertyLookup();

  const [isQuerying, setIsQuerying] = useState(false);

  const [addressSuggestions, setAddressSuggestions] = useState([]);

  const [addressToAPNMapping, setAddressToAPNMapping] = useState({});

  const [serverErrorMessage, setServerErrorMessage] = useState(undefined);

  const { field, meta, helpers } = useInput({ name: props.name, validation: props.validation });

  const queryPotentialAddresses = useMemo(() => debounce(
    async (event) => {
      setIsQuerying(true);
      const address = event.target.value;

      try {
        const { data } = await strRegistrationApiSender.get(`/lookupAddress/${encodeURIComponent(address)}`);
        setAddressSuggestions(Object.keys(data));
        setAddressToAPNMapping(data);
      } catch (err) {
        Alert.error('We are unable to search for your address at the moment.', 10000); // Keep open for 10 seconds
        captureExceptionWithContext(err, { address });
      } finally {
        setIsQuerying(false);
      }
    },
    300, { leading: true }
  ), []);

  const handleAddressChange = useCallback(async (address, event) => {
    clearStoredStates();
    setServerErrorMessage(undefined);
    field.onChange(event);

    if (selectedAddressAPN) setSelectedAddressAPN(null);

    if (shouldQuery(event)) await queryPotentialAddresses(event);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearStoredStates, field, queryPotentialAddresses, selectedAddressAPN]);

  const handleAddressSelect = useCallback(async ({ value: address }) => {
    if (selectedAddressAPN) setSelectedAddressAPN(null);
    if (isEmpty(addressToAPNMapping)) return;

    helpers.setValue(address);

    const apnForSelectedAddress = first(get(addressToAPNMapping, address));
    if (!apnForSelectedAddress) return;

    setSelectedAddressAPN(apnForSelectedAddress);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAddressAPN, addressToAPNMapping, helpers]);

  return (
    <Div>
      <Label>{props.label}</Label>

      <InputGroup inside>
        <AutoComplete
          filterBy={() => true}
          {...field}
          onChange={handleAddressChange}
          data={addressSuggestions}
          onSelect={handleAddressSelect}
          autoComplete="off"
        />

        {isQuerying && (<InputGroup.Addon><Loader /></InputGroup.Addon>)}
      </InputGroup>

      <FormikError {...meta} />

      {/* Error message returned to the client to render by the server */}
      <BasicError message={serverErrorMessage} />

      {/* Renders error message telling user they must make a selection from the address suggestions */}
      <FormikError
        touched={(field?.value?.length && meta.touched) && !selectedAddressAPN}
        error="A selection must be made."
      />
    </Div>
  );
}

export const AddressToAPNSearch = withVisibility(AddressToAPNSearchComponent);
