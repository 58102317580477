async function getPNGBlob(text) {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  canvas.width = 1000;
  canvas.height = 1000;
  ctx.fillStyle = "#FFFFFF";
  ctx.fillRect(0, 0, canvas.width, canvas.height);
  ctx.font = "36px sans-serif";
  ctx.fillStyle = "#000000";
  ctx.fillText(text, 25, 250);

  return new Promise(resolve=>canvas.toBlob(resolve, 'image/png'))
}

export async function getDebugImageBlob(text) {
  return getPNGBlob(text);
}

export async function getDebugImageFile(text = "Deckard Test Image") {
  return new File([await getDebugImageBlob(text)], "debug-image.png", { type: "image/png" });
}
