import * as appSelector from 'app/state/app/app-selectors';
import { DASHBOARD_SUCCESS_STATUS } from 'app/state/dashboard/constants';
import * as dashboardSelectors from 'app/state/dashboard/dashboard-selectors';
import { WhiteButton } from 'common/navigation-buttons';
import pluralize from 'pluralize';
import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { PORTAL_PATHS } from 'routes/constants';
import { history } from 'routes/history';
import styled from 'styled-components';
import titleize from 'titleize';

const Row = styled.div`
  display: grid;
  grid-template-columns: 4fr 1fr;
  gap: 20px;
  padding: 5px 10px;
`;

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const PropertyAddress = styled.div`
  width: 373px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const PermitInfoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
`;

const PermitButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Button = styled(WhiteButton)`
  &&& {
    background-color: white;
  }
`;

function Permit(props) {
  const { status: dataFetchStatus } = useSelector(
    dashboardSelectors.userPermitsList,
  );

  const handleClick = useCallback(() => {
    history.push(
      `${PORTAL_PATHS.dashboardSinglePermit}/${props.applicationNumber}`,
    );
  }, [props.applicationNumber]);

  return (
    <Row>
      <Flex>
        <PropertyAddress>{titleize(props.propertyAddress)}</PropertyAddress>
        <PermitInfoGrid>
          <div>{props.licenseElseApplicationNumber}</div>
          {props.expirationDate && (
            <div>Expiration: {props.expirationDate}</div>
          )}
        </PermitInfoGrid>
      </Flex>
      {dataFetchStatus === DASHBOARD_SUCCESS_STATUS && (
        <PermitButtonContainer>
          <Button onClick={handleClick}>View</Button>
        </PermitButtonContainer>
      )}
    </Row>
  );
}

const FullHeightDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  > div :nth-child(odd) {
    background: #f2f2f2;
  }
`;

const SeeMoreContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 0 10px;
  text-decoration: underline;
  cursor: pointer;
`;

export function LicenseDisplay(props) {
  const { licenses } = props;

  const certificateVerbiage = useSelector(appSelector.certificateVerbiage);
  const pluralCertificateVerbiage = pluralize(certificateVerbiage);

  const truncatedLicensesToDisplay = useMemo(
    () => licenses.slice(0, 4),
    [licenses],
  );

  const numAdditionalPermitsUndisplayed = useMemo(
    () => licenses.length - truncatedLicensesToDisplay.length,
    [licenses.length, truncatedLicensesToDisplay.length],
  );

  return (
    <FullHeightDataContainer>
      {truncatedLicensesToDisplay.map((license) => (
        <Permit key={license.applicationNumber} {...license} />
      ))}
      {Boolean(numAdditionalPermitsUndisplayed) && (
        <SeeMoreContainer
          onClick={() => history.push(PORTAL_PATHS.dashboardPermitsList)}
        >
          +{numAdditionalPermitsUndisplayed} more {pluralCertificateVerbiage}
        </SeeMoreContainer>
      )}
    </FullHeightDataContainer>
  );
}
