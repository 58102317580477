import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useFormikContext } from 'formik';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import set from 'lodash/set';
import unset from 'lodash/unset';
import { payloadActions } from 'app/state/payload/payload-slice';

const AUTO_SAVE_FIELD = '_preventAutoSave';

export function AutoSave({ formikSnapshot }) {
  const dispatch = useDispatch();
  const { values, setValues } = useFormikContext();

  useEffect(() => {
    if (get(values, AUTO_SAVE_FIELD) && isEqual(values, formikSnapshot)) {
      const cloned = cloneDeep(values);
      unset(cloned, AUTO_SAVE_FIELD);

      setValues(cloned);
      dispatch(payloadActions.autoSaveFormikSnapshot({ values: cloned }));
    }

    if (!get(formikSnapshot, AUTO_SAVE_FIELD) && !isEqual(values, formikSnapshot)) {
      const cloned = cloneDeep(values);
      set(cloned, AUTO_SAVE_FIELD, true);

      dispatch(payloadActions.autoSaveFormikSnapshot({ values: cloned }));
    }
  }, [dispatch, formikSnapshot, setValues, values]);

  return null;
}
