import { BoldedLabel, DarkModal } from 'common/common-styles';
import { WhiteButton } from 'common/navigation-buttons';
import { StyledTab, StyledTabs } from 'common/styled-tabs';
import { useInput } from 'configurable-form/components/utils/use-input';
import dayjs from 'dayjs';
import { useFormikContext } from 'formik';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import set from 'lodash/set';
import uniq from 'lodash/uniq';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Checkbox } from 'semantic-ui-react';
import styled from 'styled-components';
import {
  extendFormikPath, getAllSelectedLicensesOnReportPage, getLicenseReportFormikPath,
  getPeriodFormikPath,
  getShortPeriodDisplayName,
  initializePeriodFromAvailablePeriodStub
} from '../utils';
import { QuarterlyDynamicCheckbox } from './report-additional-revenue-dynamic';
import {
  MonthLayout,
  ReportingBody,
  ReportingFooterSection,
  ReportingHeaderSection,
  ReportingHeaderText,
  ReportingWindow,
} from './report-additional-revenue-styles';

export function useTogglePeriod({ licensesInReport }) {
  const { setFieldValue, values, setValues } = useFormikContext();

  const togglePeriod = useCallback(({ checked, period, periodFieldValue }) => {
    const licenseReports = periodFieldValue?.licenseReports || [];
    const selectedLicenseIds = Array.from(getAllSelectedLicensesOnReportPage({ taxablePeriods: values.taxablePeriods }))

    const periodPath = getPeriodFormikPath({ periodId: period.id });
    if (checked && !periodFieldValue) {
      setFieldValue(periodPath, initializePeriodFromAvailablePeriodStub(period, licensesInReport, { selectedLicenseIds }), false);
      return;
    }

    const licenseReportPaths =
      licenseReports.filter(r => !checked || selectedLicenseIds.includes(r.licenseId))
        .map((_, licenseReportIdx) => getLicenseReportFormikPath({ periodPath, licenseReportIdx }))

    const newValues = cloneDeep(values);
    licenseReportPaths.forEach(path => {
      set(newValues, extendFormikPath(path, 'selected'), checked)
    });

    setValues(newValues, false);
  }, [values, setValues, setFieldValue, licensesInReport]);

  return togglePeriod;
}

function PeriodCheckbox({ period, selectedYear, licensesInReport }) {
  const periodPath = getPeriodFormikPath({ periodId: period.id });

  const { field: periodField } = useInput({ name: periodPath })

  const licenseReports = useMemo(() => periodField.value?.licenseReports || [], [periodField.value?.licenseReports]);

  const togglePeriod = useTogglePeriod({ licensesInReport })

  const onChange = useCallback((e, { checked }) => {
    togglePeriod({ checked, period, periodFieldValue: periodField?.value })
  }, [period, periodField?.value, togglePeriod])

  if (selectedYear !== dayjs.unix(period.dateStartTimestamp).year()) return null;

  return (
    <Checkbox
      id={String(period.id)}
      name={String(period.id)}
      label={getShortPeriodDisplayName(period)}
      checked={licenseReports.some(({ selected }) => selected)}
      disabled={period.disabled}
      onChange={onChange}
    />
  );
}

const Header = styled(BoldedLabel)`
  margin-bottom: '14px';
`;

export function ReportAdditionalRevenue(props) {
  const {
    open,
    handleClose,
    availablePaymentPeriodsById,
    licensesInReport,
    isQuarterlyDynamicPeriod,
    totName = "TOT"
  } = props;

  const { values: { quarterlyTaxablePeriods = [] } } = useFormikContext();

  const availableYears = useMemo(() => {
    const periods = isQuarterlyDynamicPeriod ? quarterlyTaxablePeriods : Object.values(availablePaymentPeriodsById);
    const years = uniq(periods.map((el) => dayjs.unix(el.dateStartTimestamp).year())).sort((a, b) => b - a);

    return years;
  }, [availablePaymentPeriodsById, isQuarterlyDynamicPeriod, quarterlyTaxablePeriods]);

  const [activeYear, setActiveYear] = useState()

  const firstAvailableYear = availableYears[0];
  useEffect(() => {
    setActiveYear(firstAvailableYear);
  }, [firstAvailableYear]);

  const handleYearSelect = useCallback((e, v) => { setActiveYear(v); }, []);

  const availablePaymentPeriods = useMemo(() => Object.values(availablePaymentPeriodsById), [availablePaymentPeriodsById]);

  if (isEmpty(availablePaymentPeriods)) return null;

  return (
    <DarkModal open={open} onClose={handleClose}>
      <ReportingWindow>
        <ReportingHeaderSection>
          <ReportingHeaderText data-cy="report-revenue-additional-months-modal">Report Additional {totName}</ReportingHeaderText>
        </ReportingHeaderSection>

        <ReportingBody>
          <Header bold>
            Select the periods you want to report {totName}, including reporting $0.00 receipts for that period.
          </Header>

          <StyledTabs
            indicatorColor="primary"
            value={activeYear}
            onChange={handleYearSelect}
          >
            {availableYears.map((year) => <StyledTab key={year} label={year} value={year} />)}
          </StyledTabs>

            <MonthLayout>
              {isQuarterlyDynamicPeriod && quarterlyTaxablePeriods.map(
                (period, idx) => (
                  <QuarterlyDynamicCheckbox
                    availablePaymentPeriods={availablePaymentPeriods}
                    licensesInReport={licensesInReport}
                    selectedYear={activeYear}
                    key={idx}
                    period={period}
                    isQuarterlyDynamicPeriod={isQuarterlyDynamicPeriod}
                  />
                ))}
              {!isQuarterlyDynamicPeriod && availablePaymentPeriods.map((period, idx) => (
                <PeriodCheckbox
                  period={period}
                  key={idx}
                  selectedYear={activeYear}
                  licensesInReport={licensesInReport} />
              ))}
            </MonthLayout>
        </ReportingBody>

        <ReportingFooterSection>
          <WhiteButton type="button" onClick={handleClose} width="75px">
            Close
          </WhiteButton>
        </ReportingFooterSection>
      </ReportingWindow>
    </DarkModal>
  );
}
