import React, { useCallback } from 'react';
import { GenericBaseModule } from './module/generic-base-module';

export function CertifiedPayments(props) {
  const {
    strategyInstance,
    strategies,
    params: { text }
  } = props;

  const onClick = useCallback((url, data) => {
    /* The CertifiedPayments GenericSTI interface requires POST-ing form data which
     * sets cookies and redirects to the CP payment form.
     * Use a form so we can submit it into a new window. */
    const form = document.createElement('form');
    form.setAttribute('method', 'POST');
    form.setAttribute('target', '_blank');
    form.setAttribute('rel', 'noreferrer noopener');
    form.setAttribute('action', url);

    const baseUrl = window.location.href.replace(/\/$/g, '').split('/').slice(0, -1).join('/');

    if (data?.formData?.xmlTransaction) {
      data.formData.xmlTransaction = data.formData.xmlTransaction
        .replace(/DECKARD_PAYMENT_SUCCESS_URL/g, baseUrl + '/success')
        .replace(/DECKARD_PAYMENT_CANCELLED_URL/g, baseUrl + '/payment-cancelled')
        .replace(/DECKARD_PAYMENT_DUPLICATE_URL/g, baseUrl + '/payment-duplicate');
    }

    for (let key in data.formData) {
      const inputElem = document.createElement('input');
      inputElem.setAttribute('type', 'hidden');
      inputElem.setAttribute('name', key);
      inputElem.setAttribute('value', data.formData[key]);
      form.appendChild(inputElem);
    }

    document.getElementsByTagName('body')[0].appendChild(form);
    form.submit();

    strategyInstance.executeStrategy(strategies, 'postWindowOpen');
  },
  [strategyInstance, strategies]);

  return (
    <GenericBaseModule onClick={onClick} {...props}>
      <div>{text}</div>
    </GenericBaseModule>
  );
}
