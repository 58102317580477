import { useEffect, useMemo } from "react";
import { authSender } from "./auth-sender";
import { strRegistrationApiSender } from "./str-registration-api-sender";

export function useRSTokenForAdminFlows() {
  const queryParams = useMemo(() => new URLSearchParams(window.location?.search), []);
  const rentalscapeLoginToken = queryParams.get('operatorLoginToken')

  useEffect(() => {
    if (!rentalscapeLoginToken) return;
    authSender.setCustomJWT(rentalscapeLoginToken);
    strRegistrationApiSender.setAdminFlowMode(true);
  },[rentalscapeLoginToken]);
}
