import styled, { css } from 'styled-components';
import { Icon } from 'semantic-ui-react';

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Flex = styled.div`
  display: flex;
  justify-content: ${(props) => props.useCustomerPortal ? 'center' : 'space-between'};
  width: 100%;
  gap: 25px;
  @media only screen and (max-width: 756px) {
    flex-direction: column;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin-top: 3rem;
`;

// Used in payment-summary.jsx
export const PaymentWrapper = styled.div`
  margin-bottom: 2rem;
`;

export const PaymentHeader = styled.div`
  font-size: 18px;
  margin: 0.5rem 0;
`;

export const PaymentItem = styled.div`
  font-size: 40px;
  font-weight: bold;
  color: #000000;
`;

export const ButtonContainer = styled.div`
  margin: 1rem 0;
  margin-top: 100px;
`;

export const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledIcon = styled(Icon)`
  margin-top: -5px;
  margin-left: 3px;
`;

export const blackColor = css`
  color: ${(props) => props.accent ? 'black' : null};
`;

export const boldedFont = css`
  font-weight: ${(props) => props.accent ? '900' : 'none'};
`;

export const FieldLabel = styled.div`
  ${boldedFont}
  font-size: 10px;
  color: #7f8fa4;
`;

export const FieldContent = styled.div`
  ${blackColor};
  ${boldedFont};
`;
