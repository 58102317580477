import { createSlice } from '@reduxjs/toolkit';
import { PERSON_FIELDS_OF_INTEREST, loginPhases } from 'common/constants';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import pickBy from 'lodash/pickBy';

const INIT_STATE = {
// Username of whomever is logged in
  loggedInUsername: null,

  // Email for the login modal email entry component
  userEmail: '',

  openLoginModal: false,

  // Either email entry or code entry
  loginPhase: loginPhases.EMAIL_ENTRY,

  /**
   * This is information returned by "getUserByEmailAuth" or "createAccount" APIs.
   * However, this cannot be used in most (if not all) payloads as there are
   * extra fields that the API may not accept.
   */
  userAccountDetails: null,

  /**
   * This is a subset of information from "userAccountDetails" that can be for
   * most (if not all) payloads for the API.
   */
  relevantPersonalDetailsFromAccount: null,

  // Boolean value based on the call off "cognitoUser.signInUserSession.isValid()"
  isUserCognitoAuthenticated: null,
};

export const loginSlice = createSlice({
  name: 'login', // will be used for creating the action types. E.g.: "app/serverReturnedStats"
  initialState: INIT_STATE,
  reducers: {
    setLoggedInUsername: (state, action) => {
      const { username } = action.payload;
      state.loggedInUsername = username;
    },

    setUserEmail: (state, { payload }) => {
      state.userEmail = payload;
    },

    setOpenLoginModal: (state, action) => {
      state.openLoginModal = action.payload;
    },

    setLoginPhase: (state, action) => {
      state.loginPhase = action.payload;
    },

    setUserAccountDetails: (state, action) => {
      state.userAccountDetails = action.payload;
      const picked = pickBy(action.payload, (value, key) => (
        PERSON_FIELDS_OF_INTEREST.includes(key) && !isNil(value)
      ));
      state.relevantPersonalDetailsFromAccount = isEmpty(picked) ? null : picked;
    },

    setCognitoUserDetails: (state, action) => {
      const {
        isUserCognitoAuthenticated = null,
        email: userEmail = null,
        username: loggedInUsername = null,
      } = action.payload || {};

      state.isUserCognitoAuthenticated = isUserCognitoAuthenticated;
      state.userEmail = userEmail;
      state.loggedInUsername = loggedInUsername;
    },

    resetLogin: () => INIT_STATE,
  },
})

export const loginActions = loginSlice.actions;

export const loginReducer = loginSlice.reducer;
