import * as cs from 'common/common-styles';
import concat from 'lodash/concat';
import first from 'lodash/first';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { FLOW_PATHS, HELP_PATH } from 'routes/constants';
import { history } from 'routes/history';
import styled from 'styled-components';

import { BannerItem } from './item';

const MaskedLink = styled(cs.Link)`
  color: black;
  text-decoration: none;

  cursor: ${(props) => (props.disabled ? undefined : 'pointer')};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'inherit')};

  :hover {
    color: black;
    text-decoration: none;
  }
`;

export function HelpBannerItem(props) {
  const { alternateFlows = {}, alternateDisplayableFlowIds = [] } = props;

  const location = useLocation();

  const disabled = location.pathname.includes(HELP_PATH);

  const constructedHelpPath = useMemo(() => {
    // This will default to an empty array if nothing is found.
    const possibleCustomerFlowPaths = alternateDisplayableFlowIds.map((f) =>
      get(alternateFlows, `${f}.rootPath`),
    );

    const standardFlowPaths = concat(Object.values(FLOW_PATHS), HELP_PATH).map(
      (e) => e.replaceAll('/', ''),
    );

    const subPath = history.createHref(location);
    const filteredPathSplit = subPath.split('/').filter(Boolean);
    if (isEmpty(filteredPathSplit))
      return `${window.location.origin}${HELP_PATH}`;

    const paths = concat(standardFlowPaths, possibleCustomerFlowPaths);
    const possiblePlaceString = first(filteredPathSplit);
    const isUsingSubpathStatsRetrieval = paths.includes(possiblePlaceString);

    return isUsingSubpathStatsRetrieval
      ? `${window.location.origin}${HELP_PATH}`
      : `${window.location.origin}/${possiblePlaceString}${HELP_PATH}`;
  }, [alternateDisplayableFlowIds, alternateFlows, location]);

  return (
    <BannerItem disabled={disabled}>
      <MaskedLink
        target="_blank"
        href={constructedHelpPath}
        disabled={disabled}
      >
        Help
      </MaskedLink>
    </BannerItem>
  );
}
