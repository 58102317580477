import { UPDATE_CERTIFICATE_PATHS } from 'routes/constants';
import { BaseStrategy } from "./base-strategy";

export class UpdateCertificateStrategy extends BaseStrategy {
  static instance = null;

  static strategyToRouteMap = {
    goToSuccessPage: UPDATE_CERTIFICATE_PATHS.success,
    goToDescribeChangesPage: UPDATE_CERTIFICATE_PATHS.describeChanges,
  }

  static getInstance() {
    if (UpdateCertificateStrategy.instance) {
      return UpdateCertificateStrategy.instance;
    }
    UpdateCertificateStrategy.instance = new UpdateCertificateStrategy();
    return UpdateCertificateStrategy.instance;
  }

  get strategiesData() {
    return BaseStrategy.strategiesData.updateCertificate;
  }

  get lookupPageContinueCB() {
    return this.stratMap[this.strategiesData.lookupPageContinueCB];
  }

  get loginPageContinueCB() {
    return this.stratMap[this.strategiesData.loginPageContinueCB];
  }

  _goToSuccessPage(history) {
    history.push(UpdateCertificateStrategy.strategyToRouteMap.goToSuccessPage);
  }

  _goToDescribeChangesPage(history) {
    history.push(UpdateCertificateStrategy.strategyToRouteMap.goToDescribeChangesPage);
  }

  stratMap = {
    goToSuccessPage: this._goToSuccessPage,
    goToDescribeChangesPage: this._goToDescribeChangesPage,
  }
}

window.UCS = UpdateCertificateStrategy;
