import { Accordion } from './components/accordion';
import { Checkbox } from './components/checkbox';
import { AddressInputField } from './components/custom/address-input-fields';
import { ArrayPersonForm } from './components/custom/array-person-form';
import { ConnectedDataTable } from './components/data-table/connected-data-table';
import { DerivedNumber } from './components/derived-number';
import { Dropdown } from './components/dropdown';
import { TaxSummary } from './components/fees-lists-tables/tax-summary';
import { FeesTable } from './components/fees-table';
import { FeesTableGrouped } from './components/fees-table-grouped';
import { FileUpload } from './components/file-upload/file-upload';
import { MultiFileUpload } from './components/file-upload/multi-file-upload';
import { FillInTheBlanks } from './components/fill-in-the-blanks';
import { Container, Fragment } from './components/fragment';
import { HTMLRenderer } from './components/html-renderer';
import { InfoSummary } from './components/info-summary/info-summary';
import { InputField, InputNumberField, InputCurrencyField, MultiInputField } from './components/input-field';
import { ListEditor } from './components/list-editor';
import { ListInput } from './components/list-input';
import { Message } from './components/message';
import { PageHeader } from './components/page-header';
import { PageImage } from './components/page-image';
import { PageText } from './components/page-text';
import { PaymentForm } from './components/payment/payment-form';
import { RadioGroup } from './components/radio-group';
import { RentalDetailsEditor } from './components/rental-details-editor';
import { Requirements } from './components/requirements/requirements';
import { StrategiesButton } from './components/strategies-button';
import { Success } from './components/success/success';
import { QuestionAnswerAccordion } from './components/sup-accordion/question-answer-accordion';
import { QuestionAnswerAccordionGeneric } from './components/sup-accordion/question-answer-accordion-generic';
import { TextArea } from './components/text-area';

// Property Lookup Page
import { PropertyLookup } from './components/property-lookup';

// Bulk Renewal Pages
import { BulkRenewalRenewProperties } from './connected-portal-pages/bulk-renewal/renew-properties';
import { BulkRenewalSelectProperties } from './connected-portal-pages/bulk-renewal/select-properties';


import { PropertyTaxableReceiptsComponent } from './tax-report-revenue/property-taxable-recepits';
import { TaxableReceiptsComponent } from './tax-report-revenue/taxable-recepits';
import { TaxableReceiptsMonthOfYearComponent } from './tax-report-revenue/taxable-recepits-month-of-year';
import { TaxSummaryAccordionComponent } from './components/tax-summary-accordion/tax-summary-accordion';
import { BulkTaxSelectProperties } from './components/tax-select-properties';
import { TaxSummaryTableComponent } from './components/tax-summary-table/tax-summary-table';

export const fieldTypeMap = {
  Accordion,
  AddressInputField,
  ArrayPersonForm,
  Checkbox,
  ConnectedDataTable,
  Container,
  DerivedNumber,
  Dropdown,
  FeesTable,
  FeesTableGrouped,
  FileUpload,
  FillInTheBlanks,
  Fragment,
  HTMLRenderer,
  InfoSummary,
  InputField,
  InputNumberField,
  InputCurrencyField,
  ListEditor,
  ListInput,
  Message,
  MultiFileUpload,
  MultiInputField,
  PageHeader,
  PageImage,
  PageText,
  PaymentForm,
  QuestionAnswerAccordion,
  QuestionAnswerAccordionGeneric,
  RadioGroup,
  RentalDetailsEditor,
  Requirements,
  StrategiesButton,
  Success,
  TaxSummary,
  TextArea,

  // Property Lookup Page
  PropertyLookup,



  // Bulk Renewal Pages
  BRSelectPropertiesPage: BulkRenewalSelectProperties,
  BRRenewPropertiesPage: BulkRenewalRenewProperties,

  // Tax Pages
  BulkTaxSelectPropertiesPage: BulkTaxSelectProperties,
  TaxableReceiptsComponent,
  TaxableReceiptsMonthOfYearComponent,
  PropertyTaxableReceiptsComponent,
  TaxSummaryAccordionComponent,
  TaxSummaryTableComponent
};
