import * as appSelectors from 'app/state/app/app-selectors';
import * as dashboardSelectors from 'app/state/dashboard/dashboard-selectors';
import { networkActions } from 'app/state/network/network-slice';
import { DISABLE_PERMIT_REPORTING_HISTORY_PATH } from 'common/constants';
import first from 'lodash/first';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { Alert } from 'rsuite';
import titleize from 'titleize';
import { authSender } from 'utils/auth-sender';
import { captureExceptionWithContext } from 'utils/sentry-functions';

import { Header } from '../../shared/dashboard-styles';
import { PermitActions } from './modules/permit-actions/permit-actions';
import { PropertyContacts } from './modules/property-contacts';
import { SinglePermitReportingHistory } from './modules/reporting-history';
import { PermitDataHighlights } from './permit-data-highlights/permit-data-highlights';
import { Div, Flex } from './styles';

class SinglePermitReportingHistoryAPIError extends Error {
  constructor(message) {
    super(message);
    this.name = 'SinglePermitReportingHistoryAPIError';
  }
}

export function SinglePermitView() {
  const {
    params: { applicationNumber },
  } = useRouteMatch();

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  // These are for "/reportingHistoryByApplicationNumber" API.
  const [fetchInflight, setFetchInflight] = useState(false);
  const [failedLicenseFetch, setFailedLicenseFetch] = useState(false);
  const [fetchedLicense, setFetchedLicense] = useState(null);

  const disablePermitReportingHistory = useSelector(
    appSelectors.getFromPortalStats(DISABLE_PERMIT_REPORTING_HISTORY_PATH),
  );

  const activePermitData = useSelector(
    dashboardSelectors.activePermitByApplicationNumber(applicationNumber),
  );

  const fetchLicenseData = useCallback(async () => {
    setFetchInflight(true);
    setFailedLicenseFetch(false);
    setFetchedLicense(null);

    try {
      /**
       * Initially, this API was just meant to provide the tax reporting history data for the client.
       * However, it now returns a sizeable chunk of JSON for the client, but the name is still the same.
       * The data from the "/reportingHistoryByApplicationNumber" API provides the client with data used
       * in the "PermitDataHighlights" and "SinglePermitReportingHistory" components.
       */
      const { data } = await authSender.get(
        `/reportingHistoryByApplicationNumber/${applicationNumber}`,
      );

      setFetchedLicense(first(get(data, 'licenses')));
      setFetchInflight(false);
    } catch (err) {
      setFailedLicenseFetch(true);
      setFetchInflight(false);

      captureExceptionWithContext(
        new SinglePermitReportingHistoryAPIError(err),
        { applicationNumber },
      );

      Alert.error(
        'Failed to retrieve data. Please try again at a later time.',
        10000,
      );
    }
  }, [applicationNumber]);

  useEffect(() => {
    (async () => {
      await fetchLicenseData();
    })();
  }, [fetchLicenseData]);

  useEffect(() => {
    if (isNil(activePermitData.licenseElseApplicationNumber)) return;
    // Needed for the flows
    dispatch(
      networkActions.setMiscInfo({
        permitNumber: activePermitData.licenseElseApplicationNumber,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePermitData.licenseElseApplicationNumber]);

  return (
    <div>
      <Flex>
        <Header>{titleize(activePermitData.propertyAddress)}</Header>

        <PermitActions
          loading={loading}
          setLoading={setLoading}
          applicationNumber={applicationNumber}
          activePermitData={activePermitData}
        />
      </Flex>

      <PermitDataHighlights
        fetchInflight={fetchInflight}
        failedLicenseFetch={failedLicenseFetch}
        data={fetchedLicense}
      />

      <Div>
        {!disablePermitReportingHistory && (
          <SinglePermitReportingHistory
            applicationNumber={applicationNumber}
            fetchInflight={fetchInflight}
            failedLicenseFetch={failedLicenseFetch}
            paymentPeriods={get(fetchedLicense, 'paymentPeriods')}
            totPayable={get(fetchedLicense, 'totPayable')}
            fetchLicenseData={fetchLicenseData}
          />
        )}

        <PropertyContacts
          applicationNumber={applicationNumber}
          licenseDataFetchInFlight={fetchInflight}
        />
      </Div>
    </div>
  );
}
