import { useCallback, useMemo } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash/debounce'
import * as payloadSelectors from 'app/state/payload/payload-selectors';
import { payloadActions } from 'app/state/payload/payload-slice';
import { strRegistrationApiSender } from 'utils/str-registration-api-sender';
import { history } from 'routes/history';

export function usePropertyLookup(props) {
  const dispatch = useDispatch();

  const licenses = useSelector(payloadSelectors.licenses);

  // This acts as a reset function
  const clearStoredStates = useMemo(() => debounce(() => {
    batch(() => {
      dispatch(payloadActions.setLicenses([]));
      dispatch(payloadActions.setAdditionalInfo({}));
      dispatch(payloadActions.setAddressLookupError({ text: undefined }));
    })
  }, 300), [dispatch]);

  const setLicensesToRedux = useCallback((data) => {
    if (data.length) dispatch(payloadActions.setLicenses(data));
  }, [dispatch]);

  const getAPNLookupEndpoint = useCallback(({ apn, unitNumber, waitlistToken }) => {
    const lookupEndpoint = `/apn/${apn}`;

    const searchParams = new URLSearchParams();

    if (unitNumber) searchParams.append('unitNumber', unitNumber);
    if (waitlistToken) searchParams.append('waitlistToken', waitlistToken);

    const queryParams = searchParams.toString();

    return `${lookupEndpoint}${queryParams ? `?${queryParams}` : ''}`;
  }, []);

  const handlePropertyData = useCallback(({ apn, data }) => {
    const {
      licenses = [],
      address = '',
      parcelSpecificInfo = {},
    } = data;

    setLicensesToRedux(licenses);

    batch(() => {
      dispatch(payloadActions.updateAdditionalInfo({
        parcelNumber: apn,
        propertyAddress: address,
        parcelSpecificInfo,
      }));

      dispatch(payloadActions.mergeFormikSnapshot({
        _parcelSpecificInfo: parcelSpecificInfo
      }));
    });
  }, [dispatch, setLicensesToRedux]);

  const handleLookupMatchError = useCallback((text) => {
    dispatch(payloadActions.setAddressLookupError({ text }));
  },[dispatch]);

  const queryForMatchingProperty = useCallback(async ({ apn, unitNumber, waitlistToken }) => {
    const endpoint = getAPNLookupEndpoint({ apn, unitNumber, waitlistToken });

    // No error handling here on purpose so that we can specify what should be done per call
    try {
      const { data } = await strRegistrationApiSender.get(endpoint);
      handlePropertyData({ apn, data });

      return data;
    } catch(err) {
      if(err.response.data.Code === 'DkMismatch'){
        handleLookupMatchError(err.response.data.Message);
      } else {
        throw err;
      }
      return null;
    }

  }, [getAPNLookupEndpoint, handlePropertyData, handleLookupMatchError]);

  const waitlistInfoFromSearchParams = useCallback(() => {
    const queryParams = new URLSearchParams(history?.location?.search);
    if (!queryParams.size) return;

    return { token: queryParams.get('waitlistToken'), apn: queryParams.get('apn') };
  }, []);

  return {
    licenses,
    clearStoredStates,
    queryForMatchingProperty,
    waitlistInfoFromSearchParams,
  };
}
