import { paymentMethodType } from 'common/constants';
import { convertNumberToCurrency } from 'utils/utility-functions';

const PAYMENT_METHOD_LABELS = {
  CC: 'Card Processor Fee',
  CHECK: 'Check Processor Fee',
  ACH: 'Bank Processor Fee',
  PUSH_ACH:'Bank Processor Fee'
};
const TOTAL_PAYMENT_LABEL = 'Total';
const PRORATED_PERMIT_FEE_LABEL = 'Permit Fee';

export function paymentFeeFactory(paymentType, feeInfo) {
  let totalPaymentValue = null;
  let processorFeeLabel= null;
  let processorFeeBreakdownLabel = null;
  let processorFeeValue = null;
  let licenseFeeValue = feeInfo?.summary?.licenseFee;
  switch (paymentType) {
    case paymentMethodType.CREDIT_CARD:
      totalPaymentValue = feeInfo.ccTotal;
      processorFeeLabel = PAYMENT_METHOD_LABELS.CC;
      processorFeeBreakdownLabel = "2.99% + $0.31"
      processorFeeValue = feeInfo.ccFee;
      break;
    case paymentMethodType.ACH:
      totalPaymentValue = feeInfo.achTotal;
      processorFeeLabel = PAYMENT_METHOD_LABELS.ACH;
      processorFeeBreakdownLabel = "$1.50 + 0.81%, up to a max. of $6.50"
      processorFeeValue = feeInfo.achFee;
      break;
    case paymentMethodType.PUSH_ACH:
        processorFeeBreakdownLabel =
          convertNumberToCurrency(feeInfo.achCreditFee)
        processorFeeLabel = PAYMENT_METHOD_LABELS.PUSH_ACH;
        processorFeeValue = feeInfo.achCreditFee;
        totalPaymentValue = feeInfo.achCreditTotal;
        break;
    case paymentMethodType.CHECK:
      totalPaymentValue = feeInfo.checkTotal;
      processorFeeLabel = PAYMENT_METHOD_LABELS.CHECK;
      processorFeeValue = feeInfo.checkFee;
      break;
    default:
      throw new Error('Invalid Payment Type');
  }

  return {
    total: {
      label: TOTAL_PAYMENT_LABEL,
      value: convertNumberToCurrency(totalPaymentValue),
      rawValue: totalPaymentValue,
    },
    processingFee: {
      label: processorFeeLabel,
      secondaryLabel: processorFeeBreakdownLabel,
      value: convertNumberToCurrency(processorFeeValue)
    },
    licenseFee: {
      label: PRORATED_PERMIT_FEE_LABEL,
      value: convertNumberToCurrency(licenseFeeValue),
    },
  };
}
