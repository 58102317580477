import { createSelector } from '@reduxjs/toolkit';

export const topLevelState = (state) => state;

export const payTot = (state) => state.payTot;


export const reportedRevenue = createSelector(
  payTot,
  (payTotData) => payTotData.reportedRevenue,
);

// raw data returned from  /calculateMultiPropertyTot
export const calculatedTOTData = createSelector(
  payTot,
  (payTotData) => payTotData.calculatedTOTData,
);

export const paymentData = createSelector(
  payTot,
  (payTotData) => payTotData.paymentData,
);
