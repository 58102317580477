import styled from 'styled-components';

export const ReportingWindow = styled.div`
  width: 540px;
  background: white;
  border-radius: 5px;
`;

export const ReportingHeaderSection = styled.div`
  display: flex;
  align-items: center;

  height: 60px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
`;

export const ReportingFooterSection = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 25px;
  padding: 22px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
`;

export const ReportingHeaderText = styled.p`
  margin-left: 22px;
  font-family: 'Lato';
  font-size: 20px;
  font-weight: bold;
  color: black;
`;

export const ReportingBody = styled.div`
  margin: 25px;
`;

export const MonthLayout = styled.div`
  margin-top: 15px;

  display: inline-grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 1fr);
  row-gap: 10px;
  column-gap: 60px;
`;
