import { configureStore } from '@reduxjs/toolkit';
import appSettings from '../app-settings.json';
import { appReducer } from './state/app/app-slice';
import { dashboardReducer } from './state/dashboard/dashboard-slice';
import { errorReducer } from './state/error/error-slice';
import { filesReducer } from './state/files/files-slice';
import { loginReducer } from './state/login/login-slice';
import { networkReducer } from './state/network/network-slice';
import { payTotReducer } from './state/pay-tot/pay-tot-slice';
import { payloadReducer } from './state/payload/payload-slice';
import { paymentReducer } from './state/payment/payment-slice';

export const reducer = {
  app: appReducer, // global level information stored here
  payload: payloadReducer,
  payTot: payTotReducer,
  error: errorReducer,
  login: loginReducer,
  files: filesReducer,
  network: networkReducer,
  payment: paymentReducer,
  dashboard: dashboardReducer,
};

const initState = {};

export const createStore = (initState) => configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
  // devTools: true,
  devTools: appSettings.region === 'us-east-2',
  preloadedState: initState,
  enhancers: [],
});

export const store = createStore(initState);
