import React from 'react';
import { useSelector } from 'react-redux';
import * as appSelectors from 'app/state/app/app-selectors';
import { InputHeader } from 'common/info-tooltip';

export function ReportRevenueInputHeader(props) {
  if (props.hide) return null;
  return <InputHeader placement='top' {...props} />;
}

export function TaxableReceiptsHeader(props) {
  const config = useSelector(appSelectors.renderConfig);

  const text = config?.reporting?.revenueInputFieldLabels?.taxableReceipts || 'Taxable receipts';
  return <ReportRevenueInputHeader {...props} tooltip="e.g. $2000" text={text} />;
}

export function DaysAvailableHeader(props) {
  const text = 'Number of days available';
  return <ReportRevenueInputHeader {...props} tooltip={`Max: ${props.daysInPeriod}`} text={text} />;
}

export function DaysOccupiedHeader(props) {
  const text = 'Number of days occupied';
  return <ReportRevenueInputHeader {...props} tooltip="e.g. 20" text={text} />;
}
