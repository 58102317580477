import * as appSelectors from 'app/state/app/app-selectors';
import debounce from 'lodash/debounce';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { FilterContext } from './filter-context';
import { SortAction } from './sort-action';
import { ColumnDiv, SearchDiv, TextField } from './styles';

// import { TaxStatusFilter } from './tax-status-filter';

const Div = styled.div`
  white-space: nowrap;
`;

export function TableSettings() {
  const { searchInput, handleSearchInputChange } = useContext(FilterContext);

  const certificateVerbiage = useSelector(appSelectors.certificateVerbiage);

  const [internalSearchInput, setInternalSearchInput] = useState(searchInput);

  const debouncedHandleSearchInputChange = useMemo(
    () => debounce((e) => handleSearchInputChange(e), 250),
    [handleSearchInputChange],
  );

  const handleSearchChange = useCallback(
    (e) => {
      const inputString = String(e.target.value).trimStart();
      setInternalSearchInput(inputString);
      debouncedHandleSearchInputChange(e);
    },
    [debouncedHandleSearchInputChange],
  );

  return (
    <SearchDiv>
      <ColumnDiv>
        <Div>Search for a {certificateVerbiage}</Div>
        <TextField
          placeholder="Search by address, owner name, and more..."
          value={internalSearchInput}
          onChange={handleSearchChange}
        />
      </ColumnDiv>

      <ColumnDiv>
        {/* Disabled until Phase 2, keeping the parent div preserves the desired spacing */}
        {/* <div>Filter by</div>
        <TaxStatusFilter /> */}
      </ColumnDiv>

      <ColumnDiv>
        <div>Sort by</div>
        <SortAction />
      </ColumnDiv>
    </SearchDiv>
  );
}
