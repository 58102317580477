import CircularProgress from '@material-ui/core/CircularProgress';
import * as appSelectors from 'app/state/app/app-selectors';
import {
  InputTextFieldLabel as Label,
  StyledInput,
} from 'common/common-styles';
import { DEFAULT_FORM_VALUE } from 'common/constants';
import { WhiteButton } from 'common/navigation-buttons';
import isEmpty from 'lodash/isEmpty';
import { PortalAuthLoginError } from 'login/errors';
import { usePortalAuth } from 'login/hooks/use-portal-auth';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { Alert } from 'rsuite';
import styled from 'styled-components';
import { captureExceptionWithContext } from 'utils/sentry-functions';
import { strRegistrationApiSender } from 'utils/str-registration-api-sender';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px;

  border: 1px solid rgb(230, 230, 233);
  border-radius: 10px;
  box-shadow: rgba(8, 10, 37, 0.03) 0px 2px 3px 0px,
    rgba(8, 10, 37, 0.05) 0px 4px 8.5px 0px,
    rgba(89, 126, 247, 0.03) 0px 7.5px 35px 0px;
`;

const Header = styled.div`
  font-weight: bold;
  font-size: 20px;
`;

const Input = styled(StyledInput)`
  width: 100% !important;
  max-width: 100% !important;
`;

export function AccountLogin() {
  const cityInfo = useSelector(appSelectors.cityInfo);
  const jurisdictionPlaceString = useSelector(appSelectors.place);

  const [email, setEmail] = useState(DEFAULT_FORM_VALUE);

  const [loading, setLoading] = useState(false);

  const { dispatchInitialUserLoginFlowActions } = usePortalAuth();

  const handleChange = useCallback((value) => {
    const emailAddress = String(value).trim().toLowerCase();
    setEmail(emailAddress);
  }, []);

  const handleLogin = useCallback(
    async (e) => {
      e.preventDefault();
      setLoading(true);
      Alert.closeAll();

      try {
        const { data } = await strRegistrationApiSender.get(
          `/getUserIdByEmail/${email}`,
        );

        if (isEmpty(data)) {
          // "Empty data" means that the email address is not registered.
          setLoading(false);
          return Alert.error(
            'Please check your email address as there is no account for that email address.',
            10000,
          );
        }

        dispatchInitialUserLoginFlowActions(email, data);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        captureExceptionWithContext(new PortalAuthLoginError(err), {
          errorMsg: 'Error when attempting to login via home page',
          place: jurisdictionPlaceString,
          emailAddress: email,
        });

        Alert.error(
          'There was an issue while attempting to log in. Please try again at a later time.',
          10000,
        );
      }
    },
    [dispatchInitialUserLoginFlowActions, email, jurisdictionPlaceString],
  );

  return (
    <Form onSubmit={handleLogin}>
      <Header>Account Login</Header>
      <div>
        To get started, please enter the email address associated with your{' '}
        {cityInfo.certificateVerbiage} below
      </div>

      <div>
        <Label>Email Address</Label>
        <Input
          type="email"
          value={String(email).replaceAll(' ', '')}
          onChange={handleChange}
          maxLength={128}
        />
      </div>

      <WhiteButton type="submit" disabled={loading}>
        {loading ? <CircularProgress size={20} /> : 'Continue'}
      </WhiteButton>
    </Form>
  );
}
