import styled from 'styled-components';

export const BoldText = styled.div`
  font-weight: bold;
`;

export const TextWithDivider = styled.div`
  border-bottom: 1px solid #D5D5D5;
  margin-bottom: 5px;
  padding-bottom: 5px;
`;
