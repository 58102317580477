export const DATA_TABLE_DATA_SELECTED_KEY = 'selected';

export const dataTableCheckboxColumn = {
  key: 'checkbox',
  gridWidth: '55px',
};

export const dataTableRowActionButtonColumn = {
  key: 'rowAction',
  gridWidth: '120px',
};

export const dataTableRowRenewActionButtonColumn = {
  key: 'rowRenewAction',
  gridWidth: '120px',
};
