import * as appSelectors from 'app/state/app/app-selectors';
import {
  DASHBOARD_INFLIGHT_STATUS,
  DASHBOARD_SUCCESS_STATUS,
} from 'app/state/dashboard/constants';
import * as dashboardSelectors from 'app/state/dashboard/dashboard-selectors';
import { dashboardActions } from 'app/state/dashboard/dashboard-slice';
import {
  DISABLE_APPLY_FLOW_PATH,
  DISABLE_BULK_TAX_FLOW_PATH,
} from 'common/constants';
import dayjs from 'dayjs';
import isNil from 'lodash/isNil';
import sortBy from 'lodash/sortBy';
import { DataFetchSpinner } from 'pages/portal/dashboard/shared/data-fetch-spinner';
import { NoData } from 'pages/portal/dashboard/shared/no-data';
import pluralize from 'pluralize';
import { useCallback, useMemo } from 'react';
import React, { useDispatch, useSelector } from 'react-redux';
import { FLOW_PATHS, PORTAL_PATHS } from 'routes/constants';
import { history } from 'routes/history';

import {
  ClickableModuleHeader,
  Module,
  ModuleDataContainer,
  ModuleHeader,
} from '../styles';
import { LicenseDisplay } from './license-display';
import { Button, ButtonContainer, DataFlex } from './styles';

export function TruncatedPermitsList(props) {
  const certificateVerbiage = useSelector(appSelectors.certificateVerbiage);
  const pluralCertificateVerbiage = pluralize(certificateVerbiage);
  const taxVerbiage = useSelector(appSelectors.totName);

  const dispatch = useDispatch();

  const disableApplyFlow = useSelector(
    appSelectors.getFromPortalStats(DISABLE_APPLY_FLOW_PATH),
  );

  const disableBulkTaxFlow = useSelector(
    appSelectors.getFromPortalStats(DISABLE_BULK_TAX_FLOW_PATH),
  );

  const { status: dataFetchStatus, data: userPermitsList } = useSelector(
    dashboardSelectors.userPermitsList,
  );

  const { status: dataFetchStatusTaxableLicensePeriods } = useSelector(
    dashboardSelectors.taxableLicensePeriods,
  );

  const expirationDateSortedLicenses = useMemo(() => {
    if (isNil(userPermitsList)) return [];
    return sortBy(userPermitsList, ({ expirationDate }) =>
      dayjs(expirationDate).unix(),
    );
  }, [userPermitsList]);

  const navigateToApplyFlow = useCallback(() => {
    history.push(FLOW_PATHS.applyStrLicense);
  }, []);

  const openPermitsListView = useCallback(() => {
    history.push(PORTAL_PATHS.dashboardPermitsList);
  }, []);

  const navigateToPayTaxFlow = useCallback(() => {
    // default the preselected dates to the previous month
    // Calculate the start and end dates of the previous month
    const startDate = dayjs()
      .subtract(1, 'month')
      .startOf('month')
      .format('YYYY-MM-DD');
    const endDate = dayjs()
      .subtract(1, 'month')
      .endOf('month')
      .format('YYYY-MM-DD');

    dispatch(
      dashboardActions.setPreSelectedTaxableLicensePeriod({
        startDate: startDate,
        endDate: endDate,
      }),
    );
    history.push(`${FLOW_PATHS.payTot}`);
  }, [dispatch]);

  return (
    <Module flex={1}>
      <ModuleHeader>
        <ClickableModuleHeader
          disabled={dataFetchStatus === DASHBOARD_INFLIGHT_STATUS}
          onClick={props.fetch}
        >
          {pluralCertificateVerbiage}
          {dataFetchStatus === DASHBOARD_SUCCESS_STATUS &&
            Boolean(expirationDateSortedLicenses.length) &&
            ` (${expirationDateSortedLicenses.length})`}
        </ClickableModuleHeader>

        <DataFetchSpinner
          show={
            dataFetchStatus === DASHBOARD_INFLIGHT_STATUS &&
            Boolean(expirationDateSortedLicenses.length)
          }
        />
      </ModuleHeader>

      <ModuleDataContainer noScrolling>
        <DataFlex>
          {dataFetchStatus !== DASHBOARD_SUCCESS_STATUS ||
          !expirationDateSortedLicenses.length ? (
            <NoData
              onClick={props.fetch}
              disabled={dataFetchStatus === DASHBOARD_INFLIGHT_STATUS}
            >
              {dataFetchStatus === DASHBOARD_SUCCESS_STATUS
                ? `No ${pluralCertificateVerbiage}.`
                : 'Failed to retrieve data. Click to try again.'}
            </NoData>
          ) : (
            <LicenseDisplay licenses={expirationDateSortedLicenses} />
          )}

          <ButtonContainer>
            {!disableApplyFlow && (
              <Button onClick={navigateToApplyFlow}>
                Apply for a new {certificateVerbiage}
              </Button>
            )}

            {dataFetchStatus === DASHBOARD_SUCCESS_STATUS &&
              expirationDateSortedLicenses &&
              Boolean(expirationDateSortedLicenses.length) && (
                <Button onClick={openPermitsListView}>
                  See all {pluralCertificateVerbiage}
                </Button>
              )}

            {!disableBulkTaxFlow &&
              dataFetchStatusTaxableLicensePeriods ===
                DASHBOARD_SUCCESS_STATUS && (
                <Button onClick={navigateToPayTaxFlow}>
                  Pay {taxVerbiage}
                </Button>
              )}
          </ButtonContainer>
        </DataFlex>
      </ModuleDataContainer>
    </Module>
  );
}
