import { RENEWAL_PATHS } from 'routes/constants';
import { BaseStrategy } from "./base-strategy";

export class RenewalStrategy extends BaseStrategy {
  static instance = null;

  static getInstance() {
    if (RenewalStrategy.instance) {
      return RenewalStrategy.instance;
    }
    RenewalStrategy.instance = new RenewalStrategy();
    return RenewalStrategy.instance;
  }

  get strategiesData() {
    return BaseStrategy.strategiesData.renewLicense;
  }

  get paymentPageSuccessCB() {
    return this.stratMap[this.strategiesData.paymentPageSuccessCB];
  }

  _goToSuccessPage(history) {
    history.push(RENEWAL_PATHS.success);
  }

  stratMap = {
    goToSuccessPage: this._goToSuccessPage,
  }
}

window.RS = RenewalStrategy;
