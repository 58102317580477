import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Formik } from 'formik';
import * as appSelectors from 'app/state/app/app-selectors';
import * as payloadSelectors from 'app/state/payload/payload-selectors';
import { payloadActions } from 'app/state/payload/payload-slice';
import {
  ONE as DEFAULT_MAX_NUMBER_OF_LICENSES_FOR_APN,
  ENTRY_ERROR_PHASES,
  DEFAULT_FORM_VALUE as emptyString
} from 'common/constants';
import { renderFieldComponent } from 'configurable-form';
import { evaluateCondition } from 'configurable-form/configurable-form-utils';
import { APNAddressInput } from 'pages/flow-entry/entry-modules/apn-address-input';
import { PropertyAPNWarnings } from 'pages/flow-entry/license-warnings';
import { history } from 'routes/history';
import { captureExceptionWithContext } from 'utils/sentry-functions';
import { strRegistrationApiSender } from 'utils/str-registration-api-sender';
import { BackContinueButton } from 'widgets/back-continue-button';
import { PlaceSpecificQuestions } from "./place-specific-questions";
import { RentalDetails } from './rental-details';
import { usePreselectedPropertyChanged } from "utils/use-preselected-property-changed";

export function PropertyIdentification(props) {
  const location = useLocation();
  const dispatch = useDispatch();

  const formikSnapshot = useSelector(payloadSelectors.formikSnapshot);

  // Page configs
  const { search: config } = useSelector(appSelectors.renderConfig);

  // Page states
  const [isLoading, setIsLoading] = useState(false);
  const [lookupError, setLookupError] = useState(emptyString);

  // Only when "/<PLACE>/apn/<APN>" endpoint fails with a 4XX status code
  const [lookupErrorMessage, setLookupErrorMessage] = useState(emptyString);

  const {
    waitlistToken,
    waitlistAPN,
    onContinueClick,
    disablePropertySearchAutoViewShiftCondition,
    setLicenses,
    setAddress,
    parcelNumber,
    setParcelNumber,
  } = props;

  const presetAdditionalInfo = useSelector(payloadSelectors.additionalInfo)
  const isPreselectedPropertyChanged = usePreselectedPropertyChanged({
    parcelNumber,
  });

  useEffect(() => {
    if (presetAdditionalInfo?.parcelNumber) {
      setParcelNumber(presetAdditionalInfo?.parcelNumber);
    }

    if (presetAdditionalInfo?.propertyAddress) {
      setAddress(presetAdditionalInfo?.propertyAddress);
    }

  }, [presetAdditionalInfo?.parcelNumber, presetAdditionalInfo?.propertyAddress, setAddress, setParcelNumber])

  useEffect(() => { setLookupError(false); }, [parcelNumber]); // Clears all errors

  const unitNumber = formikSnapshot?.unitNumber;

  const handleParcelNumberChange = useCallback((apn) => {
    setParcelNumber(apn);

    // Clear parameters from the URL
    if (location.search) history.push(location.pathname);
  }, [location.pathname, location.search, setParcelNumber]);

  const getPropertyInfo = useCallback(async () => {
    setLicenses([]);

    try {
      const apnToLookup = !waitlistToken ? parcelNumber : waitlistAPN;
      let lookupEndpoint = `/apn/${apnToLookup}`;
      const searchParams = new URLSearchParams();

      const apnLookupConfig = config?.existingLicensesConfig?.apnLookupEndpoint
      if (apnLookupConfig?.additionalQueryParams){
        Object.entries(apnLookupConfig.additionalQueryParams).forEach(([key, value]) => {
          searchParams.append(key, value)
        })
      }

      if (waitlistToken) {
        searchParams.append('waitlistToken', waitlistToken)
      }
      if (unitNumber) {
        searchParams.append('unitNumber', unitNumber)
      }
      const queryParamString = searchParams.toString()
      if (queryParamString) {
        lookupEndpoint = `/apn/${apnToLookup}?${queryParamString}`;
      }

      /**
       * Whenever a 404 status is received, then we want to completely block the user from continuing
       * However, when a 2XX status is received, then the user should be allowed to proceed into to flow
       * In the case where it is a 2XX status and there are existing licenses, then the user has to
       * confirmation before proceeding with the flow
       */
      const {
        data: {
          licenses = [], // Existing licenses and license applications
          address: propertyAddress = emptyString,
          useWaitlist = false,
          parcelSpecificInfo = {},
        },
      } = await strRegistrationApiSender.get(lookupEndpoint);

      // Add "_parcelSpecificInfo" for "disablePropertySearchAutoViewShiftCondition"
      return { licenses, propertyAddress, useWaitlist, parcelSpecificInfo, _parcelSpecificInfo: parcelSpecificInfo };
    } catch (err) {
      setLookupError(ENTRY_ERROR_PHASES.NoPropertyForAPN);
      setLookupErrorMessage(err.response.data.Message + (config.additionalErrorMessageText ? ' ' + config.additionalErrorMessageText : ''));
      throw err;
    }
  }, [setLicenses, waitlistToken, parcelNumber, waitlistAPN, unitNumber, config]);


  const lookupPropertyInfoAndContinue = useCallback(async () => {
    if (
      config?.usePreselectedProperty
      && presetAdditionalInfo?.parcelNumber
      && !isPreselectedPropertyChanged
    ) {
      onContinueClick({})
      return;
    }

    setIsLoading(true);


    try {
      const { allowMultipleLicenses = false, skipMultipleLicensesNotice = false } = config;

      const propertyInfo = await getPropertyInfo();

      setLicenses(propertyInfo.licenses);
      setAddress(propertyInfo.propertyAddress);

      batch(() => {
        dispatch(
          payloadActions.updateAdditionalInfo({
            parcelNumber: !waitlistToken ? parcelNumber : waitlistAPN,
            propertyAddress: propertyInfo.propertyAddress,
          })
        );
        dispatch(payloadActions.mergeFormikSnapshot({ _parcelSpecificInfo: propertyInfo.parcelSpecificInfo }));
      });

      // The city/county DOES NOT ALLOW for a property to have MORE THAN ONE of either a license or license application
      const propertyExceedsMax = !allowMultipleLicenses && propertyInfo.licenses.length >= DEFAULT_MAX_NUMBER_OF_LICENSES_FOR_APN;
      if (propertyExceedsMax) setLookupError(ENTRY_ERROR_PHASES.APNHasLicenses);

      setIsLoading(false);

      /**
       * Handle "waitlistToken" first, since the user is entering via
       *  this method then the value of "useWaitlist" will be true
       *  and then redirect the user to the the waitlist flow instead
       *  of the apply flow
       */
      if (waitlistToken) {
        dispatch(payloadActions.updateFormikSnapshot({ waitlistToken }))
        onContinueClick({});
      } else if (propertyInfo.useWaitlist) {
        onContinueClick({ useWaitlist: propertyInfo.useWaitlist });
      } else if (
        // Always allow when there are no licenses for the APN
        !propertyInfo.licenses.length ||
        // Only allow when there are licenses for the APN, BUT ALSO when automatic navigation is disabled
        (propertyInfo.licenses.length && evaluateCondition(propertyInfo, disablePropertySearchAutoViewShiftCondition, false)) ||
        (allowMultipleLicenses && skipMultipleLicensesNotice)
      ) {
        onContinueClick({ hasLicenses: propertyInfo.licenses.length, propertyInfo });
      }
    } catch (err) {
      captureExceptionWithContext(err, { parcelNumber });
      setIsLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config, getPropertyInfo, setLicenses, setAddress, dispatch, waitlistToken, parcelNumber, waitlistAPN, disablePropertySearchAutoViewShiftCondition, onContinueClick]);

  /*
    NOTE: This should run once only and can still work properly
    with just the initial values for all the dependencies.
   */
  useEffect(() => {
    if (!waitlistToken) return;
    lookupPropertyInfoAndContinue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const continueDisabled = useMemo(() => {
    const isRentalStructureInvalid =
      config?.rentalDetailsConfig?.noDefaultRentalStructure &&
      !formikSnapshot.rentalStructure;

    const userSelectedRestrictedRentalStructureOption =
      config?.rentalDetailsConfig?.restrictedOptions?.some(
        (option) => option.value === formikSnapshot.rentalStructure
      );

    const isUnitNumberInvalid =
      (config?.rentalDetailsConfig?.requireUnitNumber && !formikSnapshot.unitNumber) ||
      userSelectedRestrictedRentalStructureOption;

    return (
      props?.preventForwardNavigation ||
      !parcelNumber ||
      isRentalStructureInvalid ||
      isUnitNumberInvalid
    );
  }, [
    config?.rentalDetailsConfig?.noDefaultRentalStructure,
    config?.rentalDetailsConfig?.requireUnitNumber,
    config?.rentalDetailsConfig?.restrictedOptions,
    formikSnapshot.rentalStructure,
    formikSnapshot.unitNumber,
    parcelNumber,
    props?.preventForwardNavigation,
  ]);

  return (
    <>
      <Formik>
        <>
          {config?.configurableComponents?.map((el, idx) => renderFieldComponent(el, idx))}
          {props?.context?.components?.map((el, idx) => renderFieldComponent(el, idx))}
        </>
      </Formik>

      <PropertyIdentificationUI
        parcelNumber={parcelNumber}
        setParcelNumber={handleParcelNumberChange}
        address={props.address}
        setAddress={props.setAddress}
        warningType={lookupError}
        message={lookupErrorMessage}
        licenses={props.licenses}
        initializeRentalDetailsFromState={Boolean(props?.context) || config?.rentalDetailsConfig?.initializeRentalDetailsFromState}
      />

      <Formik>
        <>
          {props?.context?.lowerDisplayComponents?.map((el, idx) => renderFieldComponent(el, idx))}
        </>
      </Formik>

      <BackContinueButton
        isLoading={isLoading}
        continueDisabled={continueDisabled}
        onBackClick={history.goBack}
        onContinueClick={lookupPropertyInfoAndContinue}
        continueHidden={lookupError}
      />
    </>
  )
}

export function PropertyIdentificationUI({
  parcelNumber,
  setParcelNumber = () => {},
  address,
  setAddress = () => {},
  reset = () => {},
  warningType,
  message,
  licenses = [],
  disableAddressInput = false,
  ...additionalProps
}) {
  return (
    <>
      <APNAddressInput
        parcelNumber={parcelNumber}
        setParcelNumber={setParcelNumber}
        address={address}
        setAddress={setAddress}
        disabled={disableAddressInput}
      />

      {/* Unit number and rental structure fields section */}
      <RentalDetails
        reset={reset}
        disableRentalStructure={disableAddressInput}
        initializeRentalDetailsFromState={additionalProps?.initializeRentalDetailsFromState ?? disableAddressInput}
        shouldShowUnitNumberField={additionalProps?.shouldShowUnitNumberField}
      />

      {/* Place-specific supplemental questions that are separated from the main UX flow
      (e.g. Indio existing permit)
      */}
      <PlaceSpecificQuestions />

      {/* Error messages received from the server to be displayed */}
      <PropertyAPNWarnings
        warningType={warningType}
        message={message}
        licenses={licenses}
      />
    </>
  );
}
