import * as networkSelectors from 'app/state/network/network-selectors';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { GenericBaseModule } from './module/generic-base-module';

/**
 * Redirect based payment
 * Upon successful payment, we are redirected to the /{flow}/success page
 */
export function XpressBillPay(props) {
  const {
    strategyInstance,
    strategies,
    params: {
      text,
    }
  } = props;

  const applicationNumber = useSelector(networkSelectors.applicationNumber);
  const miscInfo = useSelector(networkSelectors.miscInfo);

  const onClick = useCallback((paymentUrlFromServer) => {
    const url = new URL(paymentUrlFromServer);

    const sURLParams = new URLSearchParams();
    // This is from the Single Permit view
    sURLParams.set('id', miscInfo?.permitNumber || applicationNumber);

    const transactionURL = url.origin + url.pathname;

    /**
     * Hardcode the instructions for the opening of the XpressBillPay payment system
     *  as we'll most likely want to do the same for other places, if they
     *  happen to be using XpressBillPay too
     */
    window.open(transactionURL, '_blank', 'toolbar=0,location=0,menubar=0');
    strategyInstance.executeStrategy(strategies, 'postWindowOpen');
  },
    [miscInfo?.permitNumber, applicationNumber, strategyInstance, strategies]);

  return (
    <GenericBaseModule onClick={onClick} {...props}>
      <div>{text}</div>
    </GenericBaseModule>
  );
}
