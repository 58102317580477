import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import set from 'lodash/set';
import template from 'lodash/template';
import { parseHTMLString } from 'utils/utility-functions';

const Div = styled.div`
  margin: 20px 0;
`;

const Property = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  padding: 15px;
  background-color: #f2f2f2;
`;

const PropertyAddress = styled.div`
  font-size: 20px;
  font-weight: bold;
`
const ParcelNumber = styled.div`
  font-size: 14px;
`;

export function FoundProperty(props) {
  const {
    hideDefaultAPN,
    htmlTemplate,
    storedPropertyDetails
  } = props;

  const noFoundProperty = useMemo(() => isEmpty(storedPropertyDetails), [storedPropertyDetails]);

  const htmlRender = useMemo(() => {
    if (noFoundProperty || isEmpty(htmlTemplate)) return null;

    const { string, templates } = htmlTemplate;

    const options = {};
    templates.forEach((el) => { set(options, el, get(storedPropertyDetails, el)); });

    return parseHTMLString(template(string)(options));
  }, [htmlTemplate, noFoundProperty, storedPropertyDetails]);

  if (noFoundProperty) return null;

  return (
    <Div>
      <p>Selected property</p>
      <Property>
        <PropertyAddress>{storedPropertyDetails?.propertyAddress}</PropertyAddress>
        {!hideDefaultAPN && (<ParcelNumber>{storedPropertyDetails?.parcelNumber}</ParcelNumber>)}
        {Boolean(htmlRender) && htmlRender}
      </Property>
    </Div>
  );
}

FoundProperty.propTypes = {
  hideDefaultAPN: PropTypes.bool,
  htmlTemplate: PropTypes.object,
  storedPropertyDetails: PropTypes.object,
};
