import React, { useMemo } from 'react';
import first from 'lodash/first';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import styled from 'styled-components';
import * as cs from 'common/common-styles';
import * as rs from '../requirements-styles';
import { parseHTMLString } from 'utils/utility-functions';

const StyledAccordion = styled(Accordion)`
  margin: 16px 0 !important;
`;

const StyledAccordionSummary = styled(AccordionSummary)`
  ${cs.greyBackground}
`;

export function TableContent(props) {
  const { data, allowHtml } = props;
  const transformLabel = allowHtml ? parseHTMLString : ((x) => x);
  const numOfColumns = useMemo(() => {
    return first(data).length;
  }, [data]);

  return (
    <>
      {data.map((tableRow, _idx) => (
        <rs.TableGrid key={`requirementsTable-row-${_idx}`} columns={numOfColumns}>
          {tableRow.map(({ label, type }) => (
            <rs.TableCell key={label} type={type}>
              {transformLabel(label)}
            </rs.TableCell>
          ))}
        </rs.TableGrid>
      ))}
    </>
  );
}



function AccordionWrappedTableContent({ header, ...other }) {
  return (
    <StyledAccordion>
      <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
        {header}
      </StyledAccordionSummary>

      <cs.AccordionDetail>
        <TableContent {...other} />
      </cs.AccordionDetail>
    </StyledAccordion>
  );
}

export function TableRender(props) {
  const Component = props.isAccordion ? AccordionWrappedTableContent : TableContent;
  return <Component {...props} />;
}
