import * as appSelectors from 'app/state/app/app-selectors';
import * as loginSelectors from 'app/state/login/login-selectors';
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { ROOT_PATH } from 'routes/constants';

import { PortalRoute } from './portal-route';

export function PortalAuthRoute(props) {
  const { path, children, component: Component } = props;

  const useCustomerPortal = useSelector(appSelectors.useCustomerPortal);

  const isUserCognitoAuthenticated = useSelector(
    loginSelectors.isUserCognitoAuthenticated,
  );

  if (!useCustomerPortal || !isUserCognitoAuthenticated) {
    return <Redirect to={ROOT_PATH} />;
  }

  return (
    <PortalRoute exact={props.exact} path={path}>
      {Component ? <Component /> : children}
    </PortalRoute>
  );
}
