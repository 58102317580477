import { HeaderLabel } from 'common/page-banner-styles';
import React from 'react';
import styled from 'styled-components';

const Div = styled.div`
  height: 100%;
  padding: 0 20px;

  cursor: ${(props) => (props.disabled ? undefined : 'pointer')};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'inherit')};
`;

export function BannerItem(props) {
  return (
    <Div
      ref={props.forwardRef}
      disabled={props.disabled}
      onClick={props?.onClick}
    >
      <HeaderLabel>{props.children}</HeaderLabel>
    </Div>
  );
}
