import {
  FormikError,
  ReverseArgumentInput,
} from 'configurable-form/components/inputs/shared-components';
import { useInput } from 'configurable-form/components/utils/use-input';
import { useFormikContext } from 'formik';
import React, { useCallback } from 'react';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import styled from 'styled-components';
import { parseHTMLString } from 'utils/utility-functions';
import { usePropertyLookup } from '../hooks/usePropertyLookup';
import { withVisibility } from 'configurable-form/components/utils/with-visibility';

const Container = styled.div`
  margin: 10px 0;
`;

const UnitNumberFieldLabel = styled.p`
  font-size: 18px;
`;

function UnitNumberComponent(props) {
  const {
    subLabel,
    searchMethodRadioProps: { id: searchMethodRadioId },
    disabled = false, // Disables the entire component
    avoidClearStore = false, // Avoids calling clearStoredStates
  } = props;

  const { clearStoredStates } = usePropertyLookup();

  const { values } = useFormikContext();

  /**
   * Used to obtain the selected search method in use.
   * Any non-nil value will allow the component to render if the "disabled" flag is not set to true.
   *
   * Any selection made in the radio should be a truthy value. Unless the value set to Formik state itself is false-y.
   * However, that shouldn't matter as we are checking if the value is non-nil rather than truthy vs false-y.
   */
  const propertySearchMethodNotSelected = isNil(get(values, searchMethodRadioId, undefined));

  /**
   * "unitNumber" is hardcoded purposefully.
   * We're also still using Formik validation for the field.
   */
  const { field, meta } = useInput({ name: 'unitNumber', validation: disabled ? undefined : props.validation });

  const onChange = useCallback((event) => {
    if (!avoidClearStore) {
      clearStoredStates();
    }

    field.onChange(event);
  }, [clearStoredStates, field, avoidClearStore]);

  if (disabled || propertySearchMethodNotSelected) return null;

  return (
    <Container>
      <UnitNumberFieldLabel>{props.label ?? 'Unit Number'}</UnitNumberFieldLabel>
      {subLabel && <div>{parseHTMLString(subLabel)}</div>}

      <ReverseArgumentInput {...field} value={field.value || ''} onChange={onChange} autoComplete="off" />
      <FormikError {...meta} />
    </Container>
  );
}

/**
 *  Foreclosures and potentially Express based places need to hide
 *  the "UnitNumber" component from the default "PropertyLookup" component.
 */
export const UnitNumber = withVisibility(UnitNumberComponent);
