export class GetInputFromJSError extends Error {
  constructor(message) {
    super(message);
    this.name = 'GetInputFromJSError';
  }
}

export class SetOutputForJSError extends Error {
  constructor(message) {
    super(message);
    this.name = 'SetOutputForJSError';
  }
}

export class CheckRenewalPaymentError extends Error {
  constructor(message) {
    super(message);
    this.name = 'CheckRenewalPaymentError';
  }
}
