/*
 * A set of static redirects for "place" URLs. This is hard-coded so
 * no backend customer config is necessarily needed for a redirect.
 */

function heraRegistryUrl(heraId) {
    return `https://apps.heraregistry.com/Herawebsite/CommunityLandingPage.aspx?CommunityId=${heraId}`;
}

export const staticRedirects = {
    'vfpr-ca-orange-city_of_santa_ana': heraRegistryUrl(9607),
    'vfpr-de-new_castle-town_of_elsmere': heraRegistryUrl(10535),
    'vfpr-fl-alachua-city_of_high_springs': heraRegistryUrl(10913),
    'vfpr-fl-alachua-city_of_newberry': heraRegistryUrl(11135),
    'vfpr-fl-bradford': heraRegistryUrl(324),
    'vfpr-fl-broward-city_of_oakland_park': heraRegistryUrl(11161),
    'vfpr-fl-citrus': heraRegistryUrl(329),
    'vfpr-fl-clay': heraRegistryUrl(330),
    'vfpr-fl-duval-city_of_jacksonville': heraRegistryUrl(10960),
    'vfpr-fl-lee-city_of_fort_myers': heraRegistryUrl(10832),
    'vfpr-fl-martin': heraRegistryUrl(362),
    'vfpr-fl-miami_dade-city_of_hialeah': heraRegistryUrl(10907),
    'vfpr-fl-miami_dade-city_of_miami': heraRegistryUrl(11096),
    'vfpr-fl-miami_dade-city_of_north_miami': heraRegistryUrl(11151),
    'vfpr-fl-miami_dade-town_of_miami_lakes': heraRegistryUrl(11099),
    'vfpr-fl-miami_dade-town_of_cutler_bay': heraRegistryUrl(10753),
    'vfpr-fl-miami_dade-village_of_virginia_gardens': heraRegistryUrl(11431),
    'vfpr-fl-orange-city_of_apopka': heraRegistryUrl(10603),
    'vfpr-fl-palm_beach-city_of_boynton_beach': heraRegistryUrl(10663),
    'vfpr-fl-palm_beach-city_of_greenacres': heraRegistryUrl(10876),
    'vfpr-fl-palm_beach-city_of_pahokee': heraRegistryUrl(11192),
    'vfpr-fl-palm_beach-city_of_west_palm_beach': heraRegistryUrl(11465),
    'vfpr-fl-palm_beach': heraRegistryUrl(370),
    'vfpr-fl-palm_beach-town_of_ocean_ridge': heraRegistryUrl(11167),
    'vfpr-fl-palm_beach-village_of_palm_springs': heraRegistryUrl(11208),
    'vfpr-fl-st_johns-city_of_saint_augustine': heraRegistryUrl(11297),
    'vfpr-fl-st_johns': heraRegistryUrl(375),
    'vfpr-fl-volusia-city_of_deltona': heraRegistryUrl(10772),
    'vfpr-fl-volusia-city_of_holly_hill': heraRegistryUrl(10923),
    'vfpr-il-adams-city_of_quincy': heraRegistryUrl(13530),
    'vfpr-il-cook-village_of_chicago_ridge': heraRegistryUrl(12742),
    'vfpr-il-cook-city_of_burbank': heraRegistryUrl(12671),
    'vfpr-il-cook-city_of_chicago_heights': heraRegistryUrl(12741),
    'vfpr-il-cook-city_of_harvey': heraRegistryUrl(13042),
    'vfpr-il-cook-city_of_prospect_heights': heraRegistryUrl(13528),
    'vfpr-il-cook-village_of_dolton': heraRegistryUrl(12838),
    'vfpr-il-cook-village_of_evergreen_park': heraRegistryUrl(12905),
    'vfpr-il-cook-village_of_homewood': heraRegistryUrl(13081),
    'vfpr-il-cook-village_of_robbins': heraRegistryUrl(13561),
    'vfpr-il-cook-village_of_steger': heraRegistryUrl(13689),
    'vfpr-il-cook-village_of_flossmoor': heraRegistryUrl(12932),
    'vfpr-il-cook-village_of_harwood_heights': heraRegistryUrl(13043),
    'vfpr-il-cook-village_of_lynwood': heraRegistryUrl(13241),
    'vfpr-il-cook-village_of_maywood': heraRegistryUrl(13292),
    'vfpr-il-cook-village_of_niles': heraRegistryUrl(13391),
    'vfpr-il-cook-village_of_orland_hills': heraRegistryUrl(13452),
    'vfpr-il-cook-village_of_phoenix': heraRegistryUrl(13495),
    'vfpr-il-cook-village_of_river_grove': heraRegistryUrl(13556),
    'vfpr-il-cook-village_of_sauk_village': heraRegistryUrl(13619),
    'vfpr-il-cook-village_of_schiller_park': heraRegistryUrl(13627),
    'vfpr-il-cook-village_of_tinley_park': heraRegistryUrl(13737),
    'vfpr-il-dupage-village_of_hanover_park': heraRegistryUrl(13032),
    'vfpr-il-grundy-village_of_minooka': heraRegistryUrl(13322),
    'vfpr-il-lake-city_of_north_chicago': heraRegistryUrl(13403),
    'vfpr-il-lake-city_of_waukegan': heraRegistryUrl(13807),
    'vfpr-il-macon-city_of_decatur': heraRegistryUrl(12819),
    'vfpr-il-mcdonough-city_of_macomb': heraRegistryUrl(13253),
    'vfpr-il-peoria-city_of_west_peoria': heraRegistryUrl(13826),
    'vfpr-il-peoria-village_of_hanna_city': heraRegistryUrl(13030),
    'vfpr-il-tazewell-city_of_east_peoria': heraRegistryUrl(12867),
    'vfpr-il-will-city_of_crest_hill': heraRegistryUrl(12790),
    'vfpr-il-will-village_of_bolingbrook': heraRegistryUrl(12630),
    'vfpr-il-will-village_of_homer_glen': heraRegistryUrl(13079),
    'vfpr-il-winnebago-city_of_rockford': heraRegistryUrl(13570),
    'vfpr-nj-atlantic-city_of_atlantic_city': heraRegistryUrl(22304),
    'vfpr-nj-atlantic-borough_of_buena': heraRegistryUrl(22358),
    'vfpr-nj-atlantic-borough_of_folsom': heraRegistryUrl(22448),
    'vfpr-nj-atlantic-city_of_absecon': heraRegistryUrl(22289),
    'vfpr-nj-atlantic-city_of_brigantine': heraRegistryUrl(22350),
    'vfpr-nj-atlantic-city_of_estell_manor': heraRegistryUrl(22433),
    'vfpr-nj-atlantic-city_of_linwood': heraRegistryUrl(22554),
    'vfpr-nj-atlantic-city_of_northfield': heraRegistryUrl(22622),
    'vfpr-nj-atlantic-city_of_port_republic': heraRegistryUrl(22680),
    'vfpr-nj-atlantic-city_of_somers_point': heraRegistryUrl(22744),
    'vfpr-nj-atlantic-city_of_corbin_city': heraRegistryUrl(22394),
    'vfpr-nj-atlantic-city_of_egg_harbor_city': heraRegistryUrl(22421),
    'vfpr-nj-atlantic-town_of_hammonton': heraRegistryUrl(22485),
    'vfpr-nj-atlantic...township_of_buena_vista': heraRegistryUrl(36380),
    'vfpr-nj-atlantic...township_of_egg_harbor': heraRegistryUrl(36381),
    'vfpr-nj-atlantic...township_of_galloway': heraRegistryUrl(36382),
    'vfpr-nj-atlantic...township_of_hamilton': heraRegistryUrl(38453),
    'vfpr-nj-atlantic...township_of_mullica': heraRegistryUrl(36383),
    'vfpr-nj-atlantic-city_of_ventnor_city': heraRegistryUrl(22784),
    'vfpr-nj-atlantic...township_of_weymouth': heraRegistryUrl(36384),
    'vfpr-nj-bergen-borough_of_paramus': heraRegistryUrl(22647),
    'vfpr-nj-burlington...township_of_eastampton': heraRegistryUrl(36417),
    'vfpr-nj-camden-borough_of_bellmawr': heraRegistryUrl(22325),
    'vfpr-nj-camden-borough_of_berlin': heraRegistryUrl(22329),
    'vfpr-nj-camden-borough_of_haddon_heights': heraRegistryUrl(22480),
    'vfpr-nj-camden-borough_of_lawnside': heraRegistryUrl(22540),
    'vfpr-nj-camden-borough_of_pine_hill': heraRegistryUrl(22662),
    'vfpr-nj-camden-borough_of_runnemede': heraRegistryUrl(22716),
    'vfpr-nj-camden...township_of_gloucester': heraRegistryUrl(36445),
    'vfpr-nj-camden...township_of_voorhees': heraRegistryUrl(36449),
    'vfpr-nj-camden...township_of_waterford': heraRegistryUrl(36441),
    'vfpr-nj-gloucester...township_of_deptford': heraRegistryUrl(36555),
    'vfpr-nj-gloucester...township_of_monroe': heraRegistryUrl(39114),
    'vfpr-nj-hudson-town_of_guttenberg': heraRegistryUrl(22476),
    'vfpr-nj-hudson-town_of_west_new_york': heraRegistryUrl(22807),
    'vfpr-nj-morris-town_of_boonton': heraRegistryUrl(22339),
    'vfpr-nj-ocean...township_of_berkeley': heraRegistryUrl(36861),
    'vfpr-nj-ocean...borough_of_ocean_gate': heraRegistryUrl(22635),
    'vfpr-nj-passaic-borough_of_bloomingdale': heraRegistryUrl(22336),
    'vfpr-nj-passaic...township_of_little_falls': heraRegistryUrl(36883),
    'vfpr-nj-passaic...township_of_wayne': heraRegistryUrl(36884),
    'vfpr-nj-sussex...township_of_hardyston': heraRegistryUrl(36995),
    'vfpr-nj-union-city_of_plainfield': heraRegistryUrl(22667),
    'vfpr-ny-monroe...town_of_greece': heraRegistryUrl(23684),
    'vfpr-ny-nassau...town_of_oyster_bay': heraRegistryUrl(24067),
    'vfpr-ny-nassau-village_of_hempstead': heraRegistryUrl(23729),
    'vfpr-ny-onondaga...town_of_salina': heraRegistryUrl(39748),
    'vfpr-ny-putnam...town_of_kent': heraRegistryUrl(38723),
    'vfpr-ny-rockland...town_of_clarkstown': heraRegistryUrl(37804),
    'vfpr-ny-suffolk-village_of_lindenhurst': heraRegistryUrl(23846),
    'vfpr-ny-suffolk...town_of_babylon': heraRegistryUrl(42033),
    'vfpr-ny-suffolk...town_of_islip': heraRegistryUrl(23782),
    'vfpr-ny-suffolk-village_of_babylon': heraRegistryUrl(23325),
    'vfpr-oh-hamilton-city_of_forest_park': heraRegistryUrl(25957),
    'vfpr-pa-allegheny-borough_of_baldwin': heraRegistryUrl(27998),
    'vfpr-pa-allegheny...municipality_of_bethel_park': heraRegistryUrl(28041),
    'vfpr-pa-allegheny-borough_of_castle_shannon': heraRegistryUrl(28148),
    'vfpr-pa-allegheny-city_of_mckeesport': heraRegistryUrl(28816),
    'vfpr-pa-allegheny...borough_of_homestead': heraRegistryUrl(28603),
    'vfpr-pa-allegheny...borough_of_swissvale': heraRegistryUrl(29448),
    'vfpr-pa-allegheny...township_of_elizabeth': heraRegistryUrl(38146),
    'vfpr-pa-allegheny...borough_of_west_mifflin': heraRegistryUrl(29602),
    'vfpr-pa-allegheny...borough_of_white_oak': heraRegistryUrl(29624),
    'vfpr-pa-berks...township_of_lower_alsace': heraRegistryUrl(38903),
    'vfpr-pa-bucks...township_of_lower_makefield': heraRegistryUrl(38913),
    'vfpr-pa-dauphin-borough_of_penbrook': heraRegistryUrl(29107),
    'vfpr-pa-dauphin...township_of_lower_paxton': heraRegistryUrl(38918),
    'vfpr-pa-dauphin...township_of_susquehanna': heraRegistryUrl(40068),
    'vfpr-pa-dauphin...township_of_swatara': heraRegistryUrl(40077),
    'vfpr-pa-dauphin...township_of_derry': heraRegistryUrl(37997),
    'vfpr-pa-delaware-borough_of_east_lansdowne': heraRegistryUrl(28335),
    'vfpr-pa-delaware-borough_of_lansdowne': heraRegistryUrl(28726),
    'vfpr-pa-delaware-borough_of_marcus_hook': heraRegistryUrl(28840),
    'vfpr-pa-delaware-borough_of_yeadon': heraRegistryUrl(29671),
    'vfpr-pa-delaware-city_of_chester': heraRegistryUrl(28171),
    'vfpr-pa-lackawanna-city_of_scranton': heraRegistryUrl(29301),
    'vfpr-pa-luzerne-city_of_pittston': heraRegistryUrl(29138),
    'vfpr-pa-luzerne-city_of_wilkes_barre': heraRegistryUrl(29631),
    'vfpr-pa-montgomery-borough_of_pottstown': heraRegistryUrl(29174),
    'vfpr-pa-northampton...township_of_bethlehem': heraRegistryUrl(37506),
    'vfpr-pa-northampton-borough_of_walnutport': heraRegistryUrl(29549),
    'vfpr-pa-northampton-city_of_bethlehem': heraRegistryUrl(28042),
    'vfpr-pa-northampton-city_of_easton': heraRegistryUrl(28338),
    'vfpr-pa-northampton...township_of_palmer': heraRegistryUrl(36357),
    'vfpr-pa-northampton...township_of_forks': heraRegistryUrl(38248),
    'vfpr-pa-westmoreland-borough_of_west_newton': heraRegistryUrl(29606),
    'vfpr-pa-york-city_of_york': heraRegistryUrl(29674)
}
