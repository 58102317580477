import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 12px 24px;
  border-left: 5px solid #ffe564;
  background-color: rgba(255, 229, 100, 0.2);
`;

function renderString(template, variables) {
  return template.replace(
    /\${(.*?)}/g,
    (match, variable) => variables[variable.trim()],
  );
}

export function WarningInfo({ content, variables }) {
  const message = renderString(content, variables);

  return (
    <Wrapper>
      <div dangerouslySetInnerHTML={{ __html: message }} />
    </Wrapper>
  );
}
