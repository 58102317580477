import isEmpty from 'lodash/isEmpty';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Alert } from 'rsuite';
import { authSender } from 'utils/auth-sender';
import { captureExceptionWithContext } from 'utils/sentry-functions';

import { PropertyContactsInflight } from './data-inflight-display';
import {
  listOfUniquePeopleWithAllRoles,
  SinglePermitPeopleByApplicationNumbersAPIError,
} from './helpers';
import { Person } from './person';
import { Div, PCHeader } from './styles';

/**
 * This component handles the rendering of each unique person that is listed on a permit
 * along with their respective roles. (Registrant, property owner, property manager, etc.).
 *
 * The data comes from "/peopleByApplicationNumbers" API, which is called from within the
 * component itself, so everything related to the property contacts occurs in here.
 * The component does not consume the JSON response directly from the API as it needs to be
 * parsed beforehand. This happens in the memoized "organizedPeoples" variable.
 */
export function PropertyContacts(props) {
  const { applicationNumber, licenseDataFetchInFlight } = props;

  const [fetchInflight, setFetchInflight] = useState(false);

  const [failedPropertyContactsFetch, setFailedPropertyContactsFetch] =
    useState(false);

  const [
    fetchedPeopleAssociatedWithPermit,
    setFetchedPeopleAssociatedWithPermit,
  ] = useState(null);

  const organizedPeoples = useMemo(() => {
    if (licenseDataFetchInFlight || fetchInflight) return [];
    return listOfUniquePeopleWithAllRoles(fetchedPeopleAssociatedWithPermit);
  }, [
    fetchInflight,
    fetchedPeopleAssociatedWithPermit,
    licenseDataFetchInFlight,
  ]);

  const fetchPeopleAssociatedWithPermit = useCallback(async () => {
    setFetchInflight(true);
    setFailedPropertyContactsFetch(false);
    setFetchedPeopleAssociatedWithPermit(null);

    try {
      const { data } = await authSender.get(
        `/peopleByApplicationNumbers/${applicationNumber}`,
      );

      setFailedPropertyContactsFetch(false);
      setFetchedPeopleAssociatedWithPermit(data);
      setFetchInflight(false);
    } catch (err) {
      setFailedPropertyContactsFetch(true);
      setFetchInflight(false);

      captureExceptionWithContext(
        new SinglePermitPeopleByApplicationNumbersAPIError(err),
        { applicationNumber },
      );

      Alert.error(
        'Failed to retrieve property contacts. Please try again at a later time.',
        10000,
      );
    }
  }, [applicationNumber]);

  useEffect(() => {
    (async () => {
      await fetchPeopleAssociatedWithPermit();
    })();
  }, [fetchPeopleAssociatedWithPermit]);

  const renderInflightComponent = useMemo(() => {
    /**
     * Before rendering the each property contact, we must ensure that we have:
     *  1. determined all roles for each unique person
     *  2. received data from the "/reportingHistoryByApplicationNumber" API
     *    - The data contains the "registrantType" field that this component needs
     */
    return isEmpty(organizedPeoples) || licenseDataFetchInFlight;
  }, [licenseDataFetchInFlight, organizedPeoples]);

  return (
    <Div>
      <PCHeader>Property Contacts</PCHeader>
      {renderInflightComponent ? (
        <PropertyContactsInflight
          disabled={fetchInflight || licenseDataFetchInFlight}
          onClick={fetchPeopleAssociatedWithPermit}
          dataFetchHasFailed={failedPropertyContactsFetch}
        />
      ) : (
        organizedPeoples.map(([data, roles], i) => (
          <Person key={i} data={data} roles={roles} />
        ))
      )}
    </Div>
  );
}
