import { AccordionDetails, Button, Modal, Radio } from '@material-ui/core';
import { Input as RsInput, InputNumber } from 'rsuite';
import { css } from 'styled-components';
import { Header } from 'semantic-ui-react';
import styled from "styled-components/macro";

/**
 *   _____                      _
 *  / ____|                    (_)
 * | |  __  ___ _ __   ___ _ __ _  ___ ___
 * | | |_ |/ _ \ '_ \ / _ \ '__| |/ __/ __|
 * | |__| |  __/ | | |  __/ |  | | (__\__ \
 *  \_____|\___|_| |_|\___|_|  |_|\___|___/
 */

export const linkCss = css`
  color: #027680;
  text-decoration: underline;
  display: inline-block;
  line-height: 1.5;
  cursor: pointer;
`;

export const Link = styled.a`
  ${linkCss}
  color: ${props => props.color};
`;

export const Wrapper = styled.div`
  max-width: 700px;
  width: 100%;
  padding-left:16px;
  padding-right:16px;
  margin-left: auto;
  margin-right: auto;
`;

/* TODO: Please add comments explaining when to use ViewContainer and when to use Wrapper */
export const ViewContainer = styled.div`
  padding-top: 8rem;
  padding-bottom: 8rem;
  padding-left: 16px;
  padding-right: 16px;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
`;

export const ImageWrapper = styled.div`
  margin-top: ${(props) => props.marginTop ? props.marginTop : '200px' };
  display: flex;
  justify-content: flex-end;
`;

export const ButtonWrapper = styled.div`
  margin-top: 40px;
  display: flex;
  gap: 16px;
  justify-content: space-between;
  max-width: 100%;
`;

export const FieldLabel = styled.div`
  font-size: 10px;
  color: #7f8fa4;
`;

export const FieldWrapper = styled.div`
  font-size: 14px;
`;


export const TightButtonWrapper = styled.div`
  margin-top: 40px;
  margin-bottom: 60px;
  display: flex;
  gap: 20px;
`;

export const ErrorHeader = styled(Header)`
  color: #5c5d5d;
  font-weight: normal;
`;

export const InputSection = styled.div`
  max-width: ${(props) => props.width ? props.width : '400px'};
  width: 100%;
  margin-bottom: 10px;
`;

export const BoxShadow = css`
  border: 1px solid rgb(230, 230, 233);
  border-radius: 10px;
  box-shadow: rgba(8, 10, 37, 0.03) 0px 2px 3px 0px,
    rgba(8, 10, 37, 0.05) 0px 4px 8.5px 0px,
    rgba(89, 126, 247, 0.03) 0px 7.5px 35px 0px;
`;

/**
 *  __  __           _       _
 * |  \/  |         | |     | |
 * | \  / | ___   __| | __ _| |
 * | |\/| |/ _ \ / _` |/ _` | |
 * | |  | | (_) | (_| | (_| | |
 * |_|  |_|\___/ \__,_|\__,_|_|
 */

const modalBase = css`
  /* Sit on top of the page content */
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.65);
  overflow: auto;
`;

export const MUIModal = styled(Modal)`
  ${modalBase}
  backdrop-filter: blur(4px);
`;

export const MUIModalV2 = styled(Modal)`
  display: flex;
  justify-content: center;
  margin-top: 50px;
  padding-top: 100px;
  background-color: rgba(255, 255, 255, 0.65);
  backdrop-filter: blur(4px);
`;

export const DarkModal = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(4px);
`;

export const WhiteModal = styled(Modal)`
  ${modalBase}
  margin-top: ${(props) => props.$showBanner ? '50px' : null };
  background-color: white;
`;

/**
 *  _______        _
 * |__   __|      | |
 *    | | _____  _| |_
 *    | |/ _ \ \/ / __|
 *    | |  __/>  <| |_
 *    |_|\___/_/\_\\__|
 */

export const textBase = css`
  font-family: 'Lato';
  color: #5c5d5d;
`;

export const HeaderText = styled.div`
  ${textBase}

  font-size: 18px;
  line-height: 1.56;
  margin-bottom: 20px;
`;

export const HelperText = styled.div`
  ${textBase}

  max-width: 800px;
  font-size: 14px;
  line-height: 1.5;
`;

export const BoldedLabel = styled.p`
  font-family: 'Lato';
  font-size: ${(props) => props.fontSize || '14px'};
  font-weight: ${(props) => props.bold ? 'bold' : 'normal'};
  line-height: 1.5;
  color: #5c5d5d;
`;


/**
 * _    _                 _____                   _
 * | |  | |               |_   _|                 | |
 * | |  | |___  ___ _ __    | |  _ __  _ __  _   _| |_
 * | |  | / __|/ _ \ '__|   | | | '_ \| '_ \| | | | __|
 * | |__| \__ \  __/ |     _| |_| | | | |_) | |_| | |_
 *  \____/|___/\___|_|    |_____|_| |_| .__/ \__,_|\__|
 *                                    | |
 *                                    |_|
 */

// TODO: We should use this input component for the entire app (except the exceptional)
export const Input = styled(RsInput)`
  border-color: rgb(207, 207, 207);
  border-radius: 5px;
  height: 36px;

  &:hover, &:focus {
    border-color: #027680;
  }
`;

export const StyledInput = styled(RsInput)`
  max-width: ${(props) => props.width ? props.width : '400px' };
  height: 36px;

  ::-webkit-outer-spin-button, ::-webkit-inner-spin-button {
    -moz-appearance: textfield;
    -webkit-appearance: none;
    margin: 0
  }
`;

export const StyledInputNumber = styled(InputNumber)`
  border-color: rgb(207, 207, 207);
  border-radius: 5px;
  height: 36px;

  &:hover, &:focus {
    border-color: #027680;
  }
`;

export const InputTextFieldLabel = styled.div`
  ${textBase}
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.5;
  color: #7f8fa4;
`;

export const InputTextFieldSubLabel = styled.div`
  ${textBase}
  margin-top: 5px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  color: #7f8fa4;
`;

export const BlackRadio = styled(Radio)`
  .MuiIconButton-label {
    color: #1b1c1d
  }
`;

export const GreenButton = styled(Button)`
  &&& {
    width: 140px;
    height: 36px;
    background-color: rgba(83, 156, 162, 0.1);

    text-transform: none;
    font-family: 'Lato';
    font-size: 14px;
    font-weight: bold;
    line-height: 1.5;
    color: #027680;
  }
`;

export const ErrorMessage = styled.div`
  color: red;
`;

export const InputContainer = styled.div`
  max-width: 400px;
  width: 100%;
`;

export const InputLayout = styled.div`
  display: flex;
  justify-content: space-between;
`;

/**
 *  ______
 * |  ____|
 * | |__ ___  _ __ _ __ ___  ___
 * |  __/ _ \| '__| '_ ` _ \/ __|
 * | | | (_) | |  | | | | | \__ \
 * |_|  \___/|_|  |_| |_| |_|___/
 */

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

export const SideBySideInputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 400px;
`;

/**
 *                                _ _
 *     /\                        | (_)
 *    /  \   ___ ___ ___  _ __ __| |_  ___  _ __
 *   / /\ \ / __/ __/ _ \| '__/ _` | |/ _ \| '_ \
 *  / ____ \ (_| (_| (_) | | | (_| | | (_) | | | |
 * /_/    \_\___\___\___/|_|  \__,_|_|\___/|_| |_|
 */

export const greyBackground = css`
  background-color: rgba(0, 0, 0, .03) !important;
`;

export const AccordionDetail = styled(AccordionDetails)`
  display: block !important;
`;
