import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import first from 'lodash/first';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';
import * as appSelectors from 'app/state/app/app-selectors';
import { appActions } from 'app/state/app/app-slice';
import { loginActions } from 'app/state/login/login-slice';
import { payTotActions } from 'app/state/pay-tot/pay-tot-slice';
import * as payloadSelectors from 'app/state/payload/payload-selectors';
import { payloadActions } from 'app/state/payload/payload-slice';
import * as cs from 'common/common-styles';
import {
  DEFAULT_FORM_VALUE as emptyString,
  ENTRY_ERROR_PHASES,
  FLOWS,
  ONE as SINGLE_LICENSE_COUNT
} from 'common/constants';
import { VerticalStepper } from 'common/stepper/vertical-stepper';
import { evaluateCondition } from 'configurable-form/configurable-form-utils';
import { EmailInput } from 'pages/flow-entry/entry-modules/email-input';
import {
  LookupError,
  PropertyAPNWarnings
} from 'pages/flow-entry/license-warnings';
import { SearchResults } from 'pages/flow-entry/modules/search-results';
import { DEFAULT_FIND_LICENSE_MODE, findLicenseModes, ROOT_PATH } from 'routes/constants';
import { strRegistrationApiSender } from 'utils/str-registration-api-sender';
import { BackContinueButton } from 'widgets/back-continue-button';
import { APNAddressInput } from './entry-modules/apn-address-input';
import {
  LicenseNumberInput,
  LOOKUP_TYPES
} from './entry-modules/license-number-input';
import { strategyFactory } from "../../strategies/strategy-utils";
import { authSender } from "../../utils/auth-sender";
import { LoadingPage } from "../Loading";

const StyledWrapper = styled(cs.Wrapper)`
  margin-top: 60px;
`;

/**
 * This component renders the forms that allow searching for licenses.
 * It also renders the search results, which allow the user to select a license.
 * This component was made generic and render multiple different types of inputs that facilitate
 * the license lookup (You can search licenses by parcel#, email, license#). Which form appears
 * is governed by a route parameter `findLicenseMode`
 *
 * *** Happy flow ***
 * - user fills out form (email, parcel#, license#) value and clicks continue to initiate search
 * - component fetches licenses and presents results to user
 * - user selects a license and clicks continue again
 * - the continue button callback fetches proceeds to next strategy
 *
 * @returns React.FC
 */
export function LicenseLookup(props) {
  const history = useHistory();
  const dispatch = useDispatch();

  const { findLicenseMode = DEFAULT_FIND_LICENSE_MODE } = useParams();

  const activeFlow = useSelector(appSelectors.activeFlow);
  const rootPath = useSelector(appSelectors.rootPath);
  const isMultiPropertyTOTEnabled = useSelector(appSelectors.isMultiPropertyTOTEnabled);

  const addressOfRentalProperty = useSelector(appSelectors.addressOfRentalProperty);
  const strPermitNumber = useSelector(appSelectors.strPermitNumber);
  const parcelNum = useSelector(appSelectors.parcelNumber);
  const stepPositions = useSelector(appSelectors.stepPositions);
  const renderConfig = useSelector(appSelectors.renderConfig);

  const certVerbiage = useSelector(appSelectors.certificateVerbiage);

  const licensesRedux = useSelector(payloadSelectors.licenses);
  const selectedLicense = useSelector(payloadSelectors.license);
  const lookupModeEmail = useSelector(payloadSelectors.lookupModeEmail);

  const [address, setAddress] = useState(addressOfRentalProperty || emptyString);

  const [licNumInput, setLicNumInput] = useState(strPermitNumber || emptyString);
  const [parcelNumber, setParcelNumber] = useState(parcelNum || emptyString);
  const [email, setEmail] = useState(lookupModeEmail || emptyString);

  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLookupError, setIsLookupError] = useState(false);

  const queryParams = useMemo(() => new URLSearchParams(window.location?.search), []);
  const rentalscapeLoginToken = queryParams.get('operatorLoginToken')

  /**
   * Both of these needs to be set to a valid value for PropertyAPNWarnings to render the right information:
   *  warningType: a proper key from ENTRY_ERROR_PHASES from "/common/constants"
   *  warningMessage: the error message to display to the user
   *    This is either:
   *      1. Retrieved from the license data from the server
   *      2. The fallback value "defaultLicenseErrorMessage"
   */
  const [warningType, setWarningType] = useState(null);
  const [warningMessage, setWarningMessage] = useState(null);

  useEffect(() => { Boolean(props?.reset) && props?.reset() }, [props]);

  useEffect(() => dispatch(payTotActions.resetNonLoginInformation()), [dispatch]);

  useEffect(() => {
    const requiredQueryStringParams = renderConfig?.login?.requiredQueryStringParams;
    if (!requiredQueryStringParams) return;

    const missingParams = requiredQueryStringParams
      .filter((requiredParam) => !queryParams.has(requiredParam));

    if (missingParams.length) {
      history.replace(ROOT_PATH);
      Sentry.captureMessage("Attempted to access a flow without required parameters", {
        level: 'error',
        extra: {
          "missingParams": missingParams
        }
      });
    }
  }, [history, queryParams, renderConfig?.login?.requiredQueryStringParams]);

  // Retrieves the list of licenses for the APN, email, or license number
  const licenseGetter = useMemo(() => {
    if (findLicenseMode === findLicenseModes.APN || findLicenseMode === findLicenseModes.LICENSE_NUMBER) {
      return () => {
        const parcelWithoutHyphen = parcelNumber.replace(/-/g, emptyString).trim();
        setParcelNumber(parcelWithoutHyphen);

        const urlPath = (findLicenseMode === LOOKUP_TYPES.permitLookup) ?
          `/licensesByLicenseNumber/${licNumInput}` :
          `/licensesByApn/${parcelWithoutHyphen}`;

        return strRegistrationApiSender.getV2(urlPath, { validateStatus: false });
      };
    } else if (findLicenseMode === findLicenseModes.EMAIL) {
      const licenseType = renderConfig?.login?.licenseType;
      const queryParams = {};
      if (licenseType) {
        Object.assign(queryParams, { licenseType });
      }

      return () => strRegistrationApiSender
        .getV2(`/licensesByEmail/${email.trim()}`, { validateStatus: false, queryParams });

    }
  }, [email, findLicenseMode, licNumInput, parcelNumber, renderConfig?.login?.licenseType]);

  // This function handles error recovery whenever there's a server error or license not found
  const onFetchLicenseError = useCallback(() => {
    setIsLookupError(true);
    dispatch(payloadActions.setLicenses([]));
  }, [setIsLookupError, dispatch]);

  const shouldDisplayLicenseInResults = useCallback((license) => {
    const activeFlowNeedsLicenseNumber = ![FLOWS.UPDATE_CERTIFICATE].includes(activeFlow) && !renderConfig?.login?.allowApplications;

    const licenseNumNotRequiredOrExists =
      !activeFlowNeedsLicenseNumber || Boolean(license?.license);

    const licenseSpecificConditions = evaluateCondition(
      license,
      renderConfig?.login?.licenseFilterConditions
    );

    return (
      licenseNumNotRequiredOrExists &&
      (activeFlow !== FLOWS.UPDATE_CERTIFICATE || license?.updatable) &&
      licenseSpecificConditions
    );
  }, [activeFlow, renderConfig?.login?.allowApplications, renderConfig?.login?.licenseFilterConditions]);

  // "data" is defaulted to {} to avoid having to handle case where data is null
  const handleLicenseDataReceived = useCallback((data = {}) => {
    // Filter out licenses that do not have a license number
    const licData = data?.licenses
      .filter(elem => Boolean(elem.displayId) && shouldDisplayLicenseInResults(elem));

    if (!licData?.length) onFetchLicenseError();

    if (licData.length === SINGLE_LICENSE_COUNT) {
      dispatch(payloadActions.setLicense(first(licData)));
    }

    dispatch(payloadActions.setLicenses(licData));

    return licData;
  }, [dispatch, onFetchLicenseError, shouldDisplayLicenseInResults]);

  const handleFetchLicenses = useCallback(async () => {
    setIsLoading(true);

    try {
      const { data } = await licenseGetter();
      return handleLicenseDataReceived(data);
    } catch (err) {
      onFetchLicenseError();
      Sentry.captureException(err);
    } finally {
      setIsLoading(false);
    }
  }, [handleLicenseDataReceived, licenseGetter, onFetchLicenseError]);

  const licenseSelectedCB = useCallback((displayId) => {
    setIsDisabled(false);
    setWarningType(null);
    setWarningMessage(null);

    const selectedLic = licensesRedux.find((lic) => lic.displayId === displayId);
    dispatch(payloadActions.setLicense(selectedLic));
  }, [dispatch, licensesRedux]);

  const isCustomRenewalFlow = renderConfig?.login?.isRenewalFlow;
  const licenseActionableForFlow = useCallback((license) => {
    if (
      ((activeFlow !== FLOWS.RENEW_LICENSE && !isCustomRenewalFlow) || license?.renewable) &&
      (activeFlow !== FLOWS.PAY_TOT || license?.totPayable)
    ) { return true; }

    /**
     * Only triggers when:
     *  1. The user is in the renewal flow
     *  2. The license is NOT renewable
     */
    setIsDisabled(true);
    setWarningType(ENTRY_ERROR_PHASES.Default);

    if (activeFlow === FLOWS.RENEW_LICENSE || isCustomRenewalFlow) {
      setWarningMessage(license?.renewableError?.replace('license', certVerbiage)
        || `This ${certVerbiage} cannot be selected`);
    } else if (activeFlow === FLOWS.PAY_TOT) {
      setWarningMessage(`This ${certVerbiage} is not eligible for payments.`);
    }

    return false;
  }, [activeFlow, certVerbiage, isCustomRenewalFlow]);

  const { licenseChecks = {} } = renderConfig?.login || {};

  const checkLicense = useCallback((license) => {
    return evaluateCondition(license, licenseChecks?.condition);
  }, [licenseChecks?.condition]);

  const loginToLicense = useCallback((selectedLicense, { suppressLoginModal } = {}) => {
    if (checkLicense(selectedLicense)) {
      const { apn, propertyAddress } = selectedLicense;
      dispatch(payloadActions.updateAdditionalInfo({ parcelNumber: apn, propertyAddress }));

      // Actions to perform if the license is able to be paid for/updated/renewed
      batch(() => {
        // setting all of these at same to prevent splitting the code into two paths
        dispatch(appActions.setStrPermitNumber(licNumInput));
        dispatch(appActions.setParcelNumber(parcelNumber));
        dispatch(appActions.setAddressOfRentalProperty(address));
        dispatch(payloadActions.mergeFormikSnapshot({ _parcelSpecificInfo: selectedLicense?.parcelSpecificInfo }));
        dispatch(payloadActions.setLookupModeEmail(email));
        dispatch(loginActions.setUserEmail(email));
        if (!suppressLoginModal) {
          dispatch(loginActions.setOpenLoginModal(true));
        }
      });
    } else {
      setWarningType(ENTRY_ERROR_PHASES.Default);

      const defaultLicenseErrorMessage = `Unable to proceed with this ${certVerbiage}. Please contact your local jurisdiction for help.`;
      const message = get(selectedLicense, licenseChecks?.errorPath) || defaultLicenseErrorMessage;
      setWarningMessage(message);
    }
  }, [address, certVerbiage, checkLicense, dispatch, email, licNumInput, licenseChecks?.errorPath, parcelNumber]);

  const onContinueClickCB = useCallback(async () => {
    if (!selectedLicense) {
      const licenses = await handleFetchLicenses();
      if (activeFlow === FLOWS.PAY_TOT && isMultiPropertyTOTEnabled) {
        const licenseToLogin = licenses.find(checkLicense);

        if (!licenseToLogin) {
          setWarningType(ENTRY_ERROR_PHASES.Default);
          setWarningMessage(`No ${certVerbiage} available. Please contact your local jurisdiction for help.`);
          return
        }

        dispatch(payloadActions.setLicense(licenseToLogin));
        loginToLicense(licenseToLogin);
      }
    } else if (licenseActionableForFlow(selectedLicense)) {
      loginToLicense(selectedLicense);
    }
  }, [activeFlow, certVerbiage, checkLicense, dispatch, handleFetchLicenses, isMultiPropertyTOTEnabled, loginToLicense, selectedLicense, licenseActionableForFlow]);

  const strategyInstance = useMemo(() => {
    if (!activeFlow) return null;
    return strategyFactory(activeFlow);
  }, [activeFlow]);

  useEffect(() => {
    async function getLicensesAndUserEmailForRSTokenLogin() {
      try {
        const { data: { licenses } } = await authSender.get('/licenses');
        const { data: { email } } = await authSender.get("/callerIdentity")

        return { licenses, email }
      }
      catch(e) {
        history.replace(ROOT_PATH);
        throw e;
      }
    }

    async function effect() {
      if (!rentalscapeLoginToken) return;
      authSender.setCustomJWT(rentalscapeLoginToken);
      strRegistrationApiSender.setAdminFlowMode(true);

      const { licenses, email } = await getLicensesAndUserEmailForRSTokenLogin();

      const [license] = licenses;
      dispatch(payloadActions.setLicense(license));
      dispatch(payloadActions.setLicenses(licenses));

      loginToLicense(license, { suppressLoginModal: true })


      dispatch(payloadActions.setLookupModeEmail(email));
      dispatch(loginActions.setUserEmail(email));

      dispatch(appActions.setEnableEditingAllFields(true));

      const { apn, propertyAddress } = license;
      const { unitNumber } = license;

      const queryStringParams = new URLSearchParams();
      if (unitNumber) {
        queryStringParams.set('unitNumber', unitNumber)
      }
      const queryString = queryStringParams.size ? `?${queryStringParams.toString()}` : '';
      let parcelSpecificInfo;
      try {
        const { data } = await strRegistrationApiSender.get(`/apn/${apn}${queryString}`);
        parcelSpecificInfo = data.parcelSpecificInfo;
      }
        // eslint-disable-next-line no-empty
      catch (e) {}

      dispatch(payloadActions.updateAdditionalInfo({
        parcelNumber: apn,
        propertyAddress,
        ...(!parcelSpecificInfo ? {} : { parcelSpecificInfo }),
      }));

      dispatch(payloadActions.mergeFormikSnapshot({
        ...(!parcelSpecificInfo ? {} : { _parcelSpecificInfo: parcelSpecificInfo }),
      }));

      const strategies = renderConfig?.login?.strategies;
      strategyInstance.executeStrategy(strategies, 'onContinueClick');
    }
    effect();
  }, [dispatch, loginToLicense, renderConfig?.login?.strategies, rentalscapeLoginToken, strategyInstance, history]);

  const clearLicenseResults = useCallback(() => {
    setIsDisabled(false);

    // Clear the screen of licenses
    dispatch(payloadActions.setLicenses([]));
    !isEmpty(selectedLicense) && dispatch(payloadActions.setLicense(null));

    // Clear any entry errors
    setIsLookupError(false);
    setWarningType(null);
    setWarningMessage(null);
  }, [dispatch, selectedLicense]);

  useEffect(() => {
    if (!lookupModeEmail) clearLicenseResults();
    /**
     * clearLicenseResults needs to watch the value of selectedLicense or else it's going
     *  to be called as many times as there are keystrokes (dispatching a lot of actions to the store)
     *  so if that function changes, then this useEffect hook will re-trigger (which is bad, so we don't want to do that)
     */
  }, [parcelNumber, licNumInput]); // eslint-disable-line react-hooks/exhaustive-deps

  const isContinueDisabled = useMemo(() => {
    if (licensesRedux.length) return !selectedLicense;

    switch (findLicenseMode) {
      case findLicenseModes.LICENSE_NUMBER:
        return !licNumInput;
      case findLicenseModes.APN:
        return !parcelNumber;
      case findLicenseModes.EMAIL:
        return !email;
      default:
        return false
    }
  }, [licensesRedux.length, selectedLicense, findLicenseMode, licNumInput, parcelNumber, email]);

  /**
   * If the user enters a random word to replace the param, then we only show
   *    the back and continue buttons.
   *    So instead of that, we re-direct the user to the root path of the application
   *
   * We should use 'hasOwn' when it is supported by most modern browsers
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/hasOwn
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/hasOwn#browser_compatibility
   */
  // eslint-disable-next-line no-prototype-builtins
  if (!findLicenseModes.hasOwnProperty(findLicenseMode.toUpperCase())) {
    return <Redirect to={rootPath} />
  }

  if (rentalscapeLoginToken) {
    return (
      <VerticalStepper activeStep={stepPositions.permit}>
        <LoadingPage />
      </VerticalStepper>
    )
  }

  return (
    <VerticalStepper activeStep={stepPositions.permit}>
      <div> {/* Note that this div is required to keep the formatting of the page working */}
        <StyledWrapper>
          {findLicenseMode === findLicenseModes.APN && (
            <APNAddressInput parcelNumber={parcelNumber} setParcelNumber={setParcelNumber} address={address} setAddress={setAddress} />
          )}

          {findLicenseMode === findLicenseModes.EMAIL && (
            <EmailInput
              config={renderConfig.login}
              email={email}
              setEmail={(e) => {
                setEmail(e);
                clearLicenseResults();
              }}
            />
          )}

          {findLicenseMode === findLicenseModes.LICENSE_NUMBER && (
            <LicenseNumberInput licNumInput={licNumInput} setLicNumInput={setLicNumInput} />
          )}

          {(!isMultiPropertyTOTEnabled || (activeFlow !== FLOWS.PAY_TOT)) && (
            <SearchResults licenses={licensesRedux} licenseSelectedCB={licenseSelectedCB} />
          )}

          <LookupError lookupMode={findLicenseMode} isLookupError={isLookupError} />

          <PropertyAPNWarnings warningType={warningType} message={warningMessage} />

          <BackContinueButton
            isLoading={isLoading}
            continueDisabled={isDisabled || isContinueDisabled}
            onBackClick={history.goBack}
            onContinueClick={onContinueClickCB}
            continueLabel={licensesRedux?.length ? 'Continue' : 'Search'}
          />

          <cs.ImageWrapper>
            <img
              src={`/assets/${renderConfig?.search?.svg || 'apply-tot-property'}.svg`}
              alt="house with trees"
            />
          </cs.ImageWrapper>
        </StyledWrapper>
      </div>
    </VerticalStepper>
  );
}
