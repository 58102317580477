import React from 'react';
import styled, { css } from 'styled-components';

export const TableLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 800px;
  overflow-x:auto ;
  & > *{
    min-width:600px ;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 15px 0;

  border-top: ${(props) => props.separator ? '3px solid rgb(244, 244, 244)' : ''};
`;

const textBase = css`
  font-family: 'Lato';
  font-size: 14px;
  line-height: 1.5;
`;

const TableText = styled.div`
  width: 100%;
  text-align: ${(props) => props.shouldAlignRight ? 'right' : 'left'};

  ${textBase}
  font-weight: ${(props) => !props.shouldAlignRight || props.isBold ? 'bold' : 'normal'};
  color: #000000;
  margin: auto 0;
`;

export function RowRender({ data, forceBolding, separator, className }) {
  return (
    <Row separator={separator} className={className}>
      {data.map((item, idx) => (
        <TableText
          key={`${item}-${idx}`}
          shouldAlignRight={Boolean(idx)}
          isBold={forceBolding || !idx}
        >
          {item}
        </TableText>
      ))}
    </Row>
  );
}
