import get from "lodash/get";
const TRACE_LENGTH = 50;

function intEvaluator(expr) {
  if (typeof expr !== 'number') return;
  return { result:expr, succeeded: true };
}

function stringEvaluator(expr) {
  if (typeof expr !== 'string') return;
  const regexpResult = /`(.*)`/.exec(expr);
  if (!regexpResult) return;
  const result = regexpResult?.[1]
  return { result, succeeded: true }
}

function nullEvaluator(expr) {
  if (expr === null) return { result: null, succeeded: true }
}

function jsLiteralEvaluator(expr) {
  if (!Array.isArray(expr)) return;
  const [op, result] = expr;

  if (op !== 'js/literal') return;

  return { result, succeeded: true }
}

const TRACE_ENABLED_ENV_KEY = Symbol.for('DeckardExprTraceEnabled');

function isTraceEnabled(env) {
  return Boolean(env[TRACE_ENABLED_ENV_KEY])
}

function traceLog(env, ...args) {
  if (!isTraceEnabled(env)) return;

  console.log(...args);
}

function composeEvaluators(...evaluators) {
  return function(expr, env) {
    const debugDescription = JSON.stringify(expr).substring(0, TRACE_LENGTH);
    for(const evaluator of evaluators) {
      try {
        const { result, succeeded } = evaluator(expr, env) || {};
        if (succeeded) {
          traceLog(env, "Evaluated", debugDescription, { result, succeeded })
          return { result, succeeded: true };
        }
      }
      catch(e) {
        traceLog(env, "Error evaluating", debugDescription)
        throw e;
      }
    }
  }
}

const primitiveEvaluator = composeEvaluators(
  intEvaluator,
  stringEvaluator,
  nullEvaluator,
  jsLiteralEvaluator,
)

function letExprEvaluator(expr, env) {
  if (!Array.isArray(expr)) return;
  const [letOpcode, declarations, subExpression] = expr;

  if (letOpcode !== 'let') return;

  const declaredIdToValue = declarations.reduce((acc, [id, valueExpr]) => ({
    ...acc,
    [id]: evaluateDeckardExpr(valueExpr, { ...env, ...acc })
  }), {});

  return success(evaluateDeckardExpr(subExpression, { ...env, ...declaredIdToValue }));
}

function success(result) {
  return { result, succeeded: true }
}

function variableEvaluator(expr, env) {
  if (typeof expr !== "string") return;
  if (!Object.keys(env).includes(expr)) throw new Error(`Unexpected identifier - ${expr}`);
  return success(env[expr]);
}

function lambdaEvaluator(expr, env) {
  if (!Array.isArray(expr)) return;
  const [lambdaOpcode, params, fnExpr] = expr;
  if (lambdaOpcode !== 'lambda') return;

  return {
    __type: "lambda",
    closure: { ...env },
    params,
    fnExpr,
  }
}

function assertEvalResult({ result, succeeded }, expr) {

  if (!succeeded) throw new Error("Unable to parse expression: " + JSON.stringify(expr))
  return result;
}
function functionApplicationEvaluator(expr, env) {
  if (!Array.isArray(expr)) return;
  const [fnExpr, ...argExprs] = expr;

  const fn = evaluateDeckardExpr(fnExpr, env);
  const args = argExprs.map(argExpr => evaluateDeckardExpr(argExpr, env));

  if (typeof fn === 'function') {
    return success(fn(...args)); // JS built-in
  }

  const executionEnvironment = {
    ...fn.closure,
    ...fn.params.reduce((acc, param, i) => ({
      ...acc,
      [param]: args[i]
    }), {}),
  }

  return evaluateDeckardExpr(fn.fnExpr, executionEnvironment, { trace: isTraceEnabled(env) });
}

function dynamicJSBuiltinEvaluator(expr, env) {
  if (!Array.isArray(expr)) return;

  const [opcode, targetExpr, ...argExprs] = expr;

  const prefix = "js/.";
  if (!opcode.startsWith(prefix)) return;

  const method = opcode.replace(prefix, '');

  const target = evaluateDeckardExpr(targetExpr, env);
  const args = argExprs.map(argExpr => evaluateDeckardExpr(argExpr, env));

  return success(target[method](...args));
}

function ifEvaluator(expr, env) {
  if (!Array.isArray(expr)) return;

  const [opcode, condExpr, trueExpr, falseExpr] = expr;

  if (opcode !== 'if') return;

  const conditionValue = evaluateDeckardExpr(condExpr, env);

  return success(evaluateDeckardExpr(conditionValue ? trueExpr : falseExpr, env));
}

function condEvaluator(expr, env) {
  if (!Array.isArray(expr)) return;

  const [opcode, ...clauses] = expr;

  if (opcode !== 'cond') return;

  traceLog(env, clauses)
  const defaultFallbackClause = clauses.find(c => c[0] === 'else');
  if (!defaultFallbackClause) {
    throw new Error("cond-expressions must have an `else` clause");
  }

  const matchedClause = clauses.find(c => {
    if (c[0] === 'else') return false;

    const [conditionExpr] = c;

    const conditionValue = evaluateDeckardExpr(conditionExpr, env);

    return Boolean(conditionValue);
  })

  const clauseToEvaluate = matchedClause ? matchedClause : defaultFallbackClause;

  // eslint-disable-next-line no-unused-vars
  const [_, resultExpr] = clauseToEvaluate;

  return success(evaluateDeckardExpr(resultExpr, env));
}

const evaluator = composeEvaluators(
  ifEvaluator,
  condEvaluator,
  primitiveEvaluator,
  variableEvaluator,
  letExprEvaluator,
  dynamicJSBuiltinEvaluator,
  lambdaEvaluator,
  functionApplicationEvaluator,
)

const builtins = {
  'get-in': (src, ...fields) => get(src, fields),
  'get-path-in': (src, path) => get(src, path),
  '=': (a, b) => a === b,
  '>': (a, b) => a > b,
  '<': (a, b) => a < b,
  '-': (a, b) => a - b,
  '+': (a, b) => a + b,
  'list': (...elements) => elements,
  'not': (value) => !value,
  'or': (...xs) => xs.reduce((acc, x) => acc || x, false),
  'and': (...xs) => xs.reduce((acc, x) => acc && x, true),
  'js/literal-undefined': undefined,
}

export function evaluateDeckardExpr(expr, env, { trace = false } = {}) {
  const traceEnabled = trace || isTraceEnabled(env);
  if (traceEnabled) {
    console.log("Deckard Expression Trace", JSON.stringify(expr).substring(0, TRACE_LENGTH))
  }
  const result = evaluator(expr, { ...env, ...builtins, [TRACE_ENABLED_ENV_KEY]: Boolean(traceEnabled) }) || {};
  if (traceEnabled) {
    console.log("Deckard Expression Trace", JSON.stringify(expr).substring(0, TRACE_LENGTH), result)
  }
  return assertEvalResult(result, expr)
}
