// these strings are test ids for the test suite to be able to target the components in question
export const htmlRendererTestId = 'htmlRendererTestId';
export const checkboxTestId = 'checkboxTestId';
export const debugTestId = 'debugTestId';
export const infoSummaryTestId = 'infoSummaryTestId';
export const inputFieldTestId = 'inputFieldTestId';
export const multiInputFieldTestId = 'multiInputFieldTestId';
export const pageHeaderTestId = 'pageHeaderTestId';
export const pageTextTestId = 'pageTextTestId';
export const radioGroupTestId = 'radioGroupTestId';
export const requirementsTestId = 'requirementsTestId';
export const textAreaTestId = 'textAreaTestId';
