import * as appSelectors from 'app/state/app/app-selectors';
import {
  DISABLE_SINGLE_RENEW_FLOW_PATH,
  PERMIT_VERBIAGE,
  PERMIT_VERBIAGE_TRANSLATION_KEY,
} from 'common/constants';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Alert } from 'rsuite';
import { CustomerPortalStrategy } from 'strategies/customer-portal/customer-portal-strategy';
import { SingleRenewalFlowEntryError } from 'strategies/customer-portal/errors';
import { captureExceptionWithContext } from 'utils/sentry-functions';
import { useTranslations } from 'utils/translations';

import { Button } from './styles';

export function SinglePermitRenewalButton(props) {
  const {
    loading,
    setLoading,
    actionable,
    applicationNumber,
    activePermitData: permitData,
  } = props;

  const disableSingleRenewFlow = useSelector(
    appSelectors.getFromPortalStats(DISABLE_SINGLE_RENEW_FLOW_PATH),
  );

  const translations = useTranslations();

  const handlePermitRenewClick = useCallback(async () => {
    if (disableSingleRenewFlow) return;

    try {
      await CustomerPortalStrategy.getInstance().initiateSingleRenewalFlowEntry(
        {
          setLoading,
          applicationNumber,
          permitData,
        },
      );
    } catch (err) {
      setLoading(false);

      Alert.error(
        'Failed to initiate the renewal process. Please try again later.',
        10000,
      );

      captureExceptionWithContext(new SingleRenewalFlowEntryError(err), {
        applicationNumber,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationNumber, permitData]);

  if (disableSingleRenewFlow) return null;

  return (
    <Button
      disabled={disableSingleRenewFlow || loading || !actionable}
      onClick={handlePermitRenewClick}
    >
      Renew{' '}
      {translations?.[PERMIT_VERBIAGE_TRANSLATION_KEY]?.default ??
        PERMIT_VERBIAGE}
    </Button>
  );
}
