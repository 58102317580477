import * as yup from 'utils/yup';

// https://github.com/jquense/yup/issues/559
export function createYupSchema({ validationType, rules, nullable = false }) {
  let validator = yup[validationType]();

  if (nullable) {
    validator = validator.nullable();
  }

  rules.forEach((validation) => {
    const { params, type } = validation;

    if (!validator[type]) return;
    validator = validator[type](...params);
  });

  return validator;
}
