import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import merge from 'lodash/merge';
import omit from 'lodash/omit';
import orderBy from 'lodash/orderBy';
import pick from 'lodash/pick';
import pullAllWith from 'lodash/pullAllWith';
import set from 'lodash/set';
import transform from 'lodash/transform';
import uniqWith from 'lodash/uniqWith';
import { camelCaseDictKeys } from 'pages/portal/dashboard/helper';

export class SinglePermitPeopleByApplicationNumbersAPIError extends Error {
  constructor(message) {
    super(message);
    this.name = 'SinglePermitPeopleByApplicationNumbersAPIError';
  }
}

// Doesn't support international numbers
export function formatPhoneNumber(phoneNumber) {
  if (!phoneNumber || isNil(phoneNumber)) return;
  // eslint-disable-next-line no-unused-vars
  const [_, ...splits] = phoneNumber
    .replace(/\D/g, '')
    .match(/^(\d{3})(\d{3})(\d{4})$/);
  return splits.join('-');
}

const DEFAULT_PERSON_TYPE_SORT_ORDER = {
  registrant: 0,
  owner: 1,
  property_manager: 2,
  local_contact: 3,
};

/**
 * The API provides the keys in snake case "misc" should be configurable per place (e.g. physical address).
 * The order is important for "getFullNameOfPerson"
 */
const NAME_RELATED_FIELDS_SNAKE_CASED = [
  'first_name',
  'last_name',
  'full_name',
];

const FIELD_TO_PICK_FROM_RAW_PERSON_JSON =
  NAME_RELATED_FIELDS_SNAKE_CASED.concat([
    'email',
    'company_name',
    'address',
    'primary_phone',
    'misc.physical_address',
  ]);

function getFullNameOfPerson(personJSON) {
  const pickedNameFields = pick(personJSON, NAME_RELATED_FIELDS_SNAKE_CASED);

  // This should match the order of "NAME_RELATED_FIELDS_SNAKE_CASED"
  const [firstName, lastName, fullName] = NAME_RELATED_FIELDS_SNAKE_CASED.map(
    (field) => String(pickedNameFields[field]).trim(),
  );

  const fullNameOfPerson = fullName
    ? fullName
    : [firstName, lastName].join(' ');

  return merge({}, omit(personJSON, NAME_RELATED_FIELDS_SNAKE_CASED), {
    name: fullNameOfPerson,
  });
}

export function pickRelevantPersonDetails(rawPersonJSON) {
  return getFullNameOfPerson(
    pick(rawPersonJSON, FIELD_TO_PICK_FROM_RAW_PERSON_JSON),
  );
}

function transformer(acc, people, type) {
  const uniquePickedPeople = uniqWith(
    people.map(pickRelevantPersonDetails),
    isEqual,
  );
  if (uniquePickedPeople.length) set(acc, type, uniquePickedPeople);
}

function getRoleImportance(role) {
  return DEFAULT_PERSON_TYPE_SORT_ORDER[role] ?? Number.MAX_SAFE_INTEGER;
}

/**
 * Parses the raw person data from the "/peopleByApplicationNumbers" API into a React render-friendly format.
 * @param {Object} rawPersonJSON This is data directly from the "/peopleByApplicationNumbers" API
 * @returns [
 *    Person info JSON,
 *    Person type[]
 *  ][]
 *
 * e.g.
 * [
 *  {
 *    email: 'ndelpego@gmail.com',
 *    companyName: null,
 *    name: 'Nickolas Del Pego',
 *    address: '1222 HARRIS WAY GALVESTON TX 77551',
 *    primaryPhone: '8582489492',
 *    misc: {
 *      physicalAddress: '1222 HARRIS WAY GALVESTON TX 77551',
 *    },
 *  },
 *  ['owner', 'local_contact'] <-- Will be translated later
 * ]
 *
 * The order not guaranteed.
 * I believe that the server only uses the labels:
 *  1. "registrant"
 *  2. "owner"
 *  3. "property_manager"
 *  4. "local_contact"
 *
 * So the client will have to handle translating all those into whatever each customer wants
 */
export function listOfUniquePeopleWithAllRoles(rawPersonJSON) {
  if (isEmpty(rawPersonJSON)) return [];

  // This map will be mutated later on in the function when we need to match each person with their relevant roles
  const mutableMapOfPersonTypesToPeople = transform(rawPersonJSON, transformer);

  // Getting a list of all the roles for the permit.
  const existingRolesOfPermit = Object.keys(mutableMapOfPersonTypesToPeople);

  /**
   * Here we're determining the unique people that are listed in the permit as
   * a singular person can play multiple roles for a rental property.
   */
  const uniquePeopleOfPermit = uniqWith(
    existingRolesOfPermit.flatMap(
      (type) => mutableMapOfPersonTypesToPeople[type],
    ),
    isEqual,
  );

  /**
   * Assigning the roles for each unique person.
   * The structure of this data is a tuple of person data and an array of roles.
   * (See the function comments for an example.)
   */
  const uniquePeopleWithRoles = uniquePeopleOfPermit.map((person) => {
    /**
     * Get all the roles for a unique person by matching all occurrences of said person
     * in the permit data.
     */
    const roles = existingRolesOfPermit.reduce((acc, role) => {
      const hasFoundPerson = mutableMapOfPersonTypesToPeople[role].find((p) =>
        isEqual(p, person),
      );

      if (!hasFoundPerson) return acc;

      /**
       * If the person has been found, then:
       *  1. we remove that person from the search target
       *  2. build a list of roles for the person
       */
      pullAllWith(mutableMapOfPersonTypesToPeople[role], person, isEqual);
      return acc.concat(role);
    }, []);

    // Sort the roles for the person in order of importance.
    const orderedRoles = orderBy(roles, getRoleImportance, ['asc']);
    return [camelCaseDictKeys(person), orderedRoles];
  });

  // Organize the people based on their roles by order of role importance
  const result = orderBy(
    uniquePeopleWithRoles,
    ([_, roles]) => roles.map(getRoleImportance),
    ['asc'],
  );

  return result;
}
