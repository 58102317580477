import React, { useCallback } from 'react';
import { CardCvcElement, CardExpiryElement, CardNumberElement, Elements, } from '@stripe/react-stripe-js';
import { strRegistrationApiSender } from 'utils/str-registration-api-sender';
import { PaymentButton } from '../../../modules/payment-button/payment-button';
import { CardField, PaymentProcessorContainer } from './stripe-styles';
import { FieldLabel } from '../../shared-styles';
import { Alert } from 'rsuite';
import { ZERO as RSUITE_ALERT_DO_NOT_CLOSE } from 'common/constants';
import { useDeckardStripe, useStripePaymentUIState } from "../use-stripe-payment-ui-state";
import { CARD_ERROR, STRIPE_PAYMENT_INTENT_STATUS_SUCCESS } from "../constants";
import { DEFAULT_ALERT_MSG } from "../../../constants";

const CARD_OPTIONS = {
  style: {
    base: {
      fontWeight: 500,
      fontFamily: 'Nunito Sans, Roboto, Open Sans, Segoe UI, sans-serif',
      fontSize: '16px',
    },
  },
};

function StripeComponent(props) {
  const {
    stripe,
    elements,
    shouldLockPayments,
    paymentFees,
    isPayButtonDisabled,
    label,
    runWithStripeUILockAndExceptionHandling,
  } = useStripePaymentUIState(props);


  const onClick = useCallback(() => runWithStripeUILockAndExceptionHandling(async () => {
      // Register payment in server, who responds with client secret so we can proceed with payment
      const { data: { clientSecret } } = await strRegistrationApiSender.post('/payCC', {
        paymentId: paymentFees.paymentId,
        signatory: props.signatory,
        email: props.email,
      });

      // Make card payment directly with stripe. Requires client_secret from backend Stripe code
      const result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardNumberElement),
          billing_details: { name: props.signatory },
        },
        receipt_email: props.email,
      });

      // Check for errors in stripe transaction
      if (result.error) {
        let alertMsg = DEFAULT_ALERT_MSG;
        /*
         * Errors can be card_error, api_error, idempotency_error, invalid_request_error.
         * Here we only display the nice `error.message` msg for card_error. Else we want
         * the user to see our own custom error message string
         */
        if (result.error.type === CARD_ERROR) {
          alertMsg = result.error.message;
        }
        Alert.error(alertMsg, RSUITE_ALERT_DO_NOT_CLOSE);
        throw new Error(result.error.code);
      } else if (result.paymentIntent.status === STRIPE_PAYMENT_INTENT_STATUS_SUCCESS) {
        await props.onSuccess();
      }
  }), [elements, paymentFees.paymentId, props, runWithStripeUILockAndExceptionHandling, stripe]);

  return (
    <PaymentProcessorContainer>
      <FieldLabel>Card Information</FieldLabel>

      <CardField>
        <CardNumberElement
          id="stripe-card-number"
          className="stripe-element"
          options={{ showIcon: true, ...CARD_OPTIONS }}
        />
      </CardField>

      <CardField>
        <CardExpiryElement className="stripe-element" id="stripe-expiry" options={CARD_OPTIONS} />
        <CardCvcElement className="stripe-element" id="stripe-cvc" options={CARD_OPTIONS} />
      </CardField>

      <PaymentButton
        loading={shouldLockPayments}
        disabled={isPayButtonDisabled}
        onClick={onClick}
        label={label}
      />
    </PaymentProcessorContainer>
  );
}

export function Stripe(props) {
  const stripe = useDeckardStripe();

  return (
    <Elements stripe={stripe}>
      <StripeComponent {...props} />
    </Elements>
  );
}
