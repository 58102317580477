import { PortalPageContainer } from 'common/portal/styles';
import React from 'react';
import { Switch } from 'react-router-dom';
import { PORTAL_PATHS } from 'routes/constants';
import { PortalAuthMidFlowRoute } from 'routes/custom/portal-auth-mid-flow-route';
import { PortalAuthRoute } from 'routes/custom/portal-auth-route';
import styled from 'styled-components';

import { Dashboard } from './pages/dashboard';
import { PermitsListView } from './pages/permits-list';
import { SinglePermitView } from './pages/single-permit';
import { UpdateUserDetailsPage } from './pages/update-user-details';

const Div = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
`;

export function PortalDashboard() {
  return (
    <PortalPageContainer>
      <Div>
        <Switch>
          <PortalAuthRoute
            exact
            path={PORTAL_PATHS.dashboard}
            component={Dashboard}
          />

          <PortalAuthMidFlowRoute
            exact
            path={PORTAL_PATHS.dashboardUpdateUserDetails}
            component={UpdateUserDetailsPage}
          />

          <PortalAuthMidFlowRoute
            exact
            path={PORTAL_PATHS.dashboardPermitsList}
            component={PermitsListView}
          />

          <PortalAuthMidFlowRoute
            exact
            path={PORTAL_PATHS.dashboardSinglePermitSlugged}
            component={SinglePermitView}
          />
        </Switch>
      </Div>
    </PortalPageContainer>
  );
}
