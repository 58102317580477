import { formatProperty, useLicensesInReportMap } from "../../utils";

export const PROPERTY_REPORTING_COLUMN_FIELD_PLACEHOLDER = "__propertyAddress";

export function getPropertyColumn() {
  return {
    field: PROPERTY_REPORTING_COLUMN_FIELD_PLACEHOLDER,
    label: "Property Address",
    value: "Unknown Property",
    type: "constant"
  }
}

export function getPropertyColumnCustomizer(license) {
  const customizePropertyColumn = function (col) {
    return {
      ...col,
      value: formatProperty(license)
    }
  }

  return {
    [PROPERTY_REPORTING_COLUMN_FIELD_PLACEHOLDER]: customizePropertyColumn
  }
}

export function usePropertyColumnCustomizerFactory() {
  const licenseIdToLicense = useLicensesInReportMap();

  return (licenseId) => getPropertyColumnCustomizer(licenseIdToLicense[licenseId]);
}
