import React from 'react';

import { ACHTab } from './ach-tab';
import { CCTab } from './cc-tab';
import { PayByCheckTab } from './pay-by-check-tab';
import * as pmms from './payment-method-module-styles';
import { PushACHTab } from './push-ach-tab';

export function PaymentMethodModule(props) {
  const { isPayByCheckActive, isPushACHActive, isCCActive, isACHActive } =
    props;

  return (
    <div>
      <pmms.FieldLabel>Payment Method</pmms.FieldLabel>

      <pmms.SelectionWrapper>
        {isACHActive && <ACHTab />}
        {isCCActive && <CCTab />}
        {isPushACHActive && <PushACHTab />}
        {isPayByCheckActive && <PayByCheckTab />}
      </pmms.SelectionWrapper>
    </div>
  );
}
