import { createSelector } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import sortBy from 'lodash/sortBy';

import { DASHBOARD_SUCCESS_STATUS } from './constants';

export const dashboard = (state) => state.dashboard;

/**
 * Retrieves the list of permits the user has access to.
 * Parsed data, raw retrieved from "/userLicenses" API.
 */
export const userPermitsList = createSelector(
  dashboard,
  (dashboardState) => dashboardState.userPermitsList,
);

/**
 * Retrieves the permit data for an individual permit based on
 * the application number.
 * Parsed data, raw retrieved from "/userLicenses" API.
 */
export function activePermitByApplicationNumber(applicationNumber) {
  return createSelector(userPermitsList, (userPermitsList) => {
    if (userPermitsList.status !== DASHBOARD_SUCCESS_STATUS) return {};
    return userPermitsList.data.find(
      (p) => p.applicationNumber === applicationNumber,
    );
  });
}

/**
 * Retrieves the list of RENEWABLE permits for the user.
 * Data from "/renewableLicenses" API.
 */
export const bulkRenewableLicenses = createSelector(
  dashboard,
  (dashboardState) => dashboardState.bulkRenewableLicenses,
);

/**
 * Retrieves the list of RENEWABLE permits for the user sorted by expiration date
 * in ascending order.
 * Data from "/renewableLicenses" API.
 */
export const bulkRenewalDataExpirationDateAsc = createSelector(
  bulkRenewableLicenses,
  (bulkRenewableLicenses) => {
    if (bulkRenewableLicenses.status !== DASHBOARD_SUCCESS_STATUS) return;
    return sortBy(bulkRenewableLicenses.data, ({ expirationDate }) =>
      dayjs(expirationDate).unix(),
    );
  },
);

/**
 * Retrieves the list of permits to be renewed sorted by expiration date in
 * ascending order for the "BulkRenewalRenewProperties" component to use in
 * a network request.
 */
export const bulkLicensesToBeRenewedExpirationDateAsc = createSelector(
  dashboard,
  (dashboardState) => {
    return sortBy(
      dashboardState.bulkLicensesToBeRenewed,
      ({ expirationDate }) => dayjs(expirationDate).unix(),
    );
  },
);

/**
 * Retrieves the list of permits to pay tax on sorted by expiration date in
 * descending order for the tax flow report revenue page to be
 * populated from.
 */
export const bulkLicensesToPayTaxDateDesc = createSelector(
  dashboard,
  (dashboardState) => {
    return sortBy(
      dashboardState.licensesToPayTaxOn,
      ({ endDate }) => -dayjs(endDate).unix(),
    );
  },
);

/**
 * Retrieves the list of tax payment perids for the user.
 * Data from "/totPaymentPeriods" API.
 */
export const taxableLicensePeriods = createSelector(
  dashboard,
  (dashboardState) => dashboardState.taxableLicensePeriods,
);

/**
 * Retrieves the reporting history data for the user.
 * Data from "/reportingHistoriesByUserInChunks" API.
 */
export const userTaxReportingHistory = createSelector(
  dashboard,
  (dashboardState) => dashboardState.userTaxReportingHistory,
);

/**
 * See "preselectedPermitsByApplicationNumber" in "INIT_STATE" of
 * "src/app/state/dashboard/dashboard-slice.js" for details.
 */
export const preselectedPermitsByApplicationNumber = createSelector(
  dashboard,
  (dashboardState) => dashboardState.preselectedPermitsByApplicationNumber,
);

/**
 * Stores the tax period clicked on by the user on the dashboard actions section
 */
export const preSelectedTaxableLicensePeriod = createSelector(
  dashboard,
  (dashboardState) => dashboardState.preSelectedTaxableLicensePeriod,
);
