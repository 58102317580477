import axios from 'axios';
import { checkIfProdCognitoUserPool } from './utility-functions';

export const snapPayAPI = `https://${checkIfProdCognitoUserPool() ? 'www' : 'stage'}.snappayglobal.com/Interop`;

const axiosConfig = {
  headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  timeout: 180000, // https://medium.com/@masnun/handling-timeout-in-axios-479269d83c68
};

class FiServSender {
  constructor() {
    this.axiosInstance = axios.create({
      baseURL: snapPayAPI,
      ...axiosConfig
    });
  }

  convertJSONPayloadToFormData(payload) {
    const formData = new FormData();

    const formKeys = Object.keys(payload);
    formKeys.forEach((key) => { formData.append(key, JSON.stringify(payload[key])); });

    return formData;
  }

  async post(path, body) {
    const formData = this.convertJSONPayloadToFormData(body);
    return this.axiosInstance.post(path, formData);
  }
}

export const fiServSender = new FiServSender();
