import { createSlice } from '@reduxjs/toolkit';

const INIT_STATE = {
  reportedRevenue: {},
  calculatedTOTData: {}, // raw data returned from  /calculateMultiPropertyTot
  paymentData: {},
};

export const payTotSlice = createSlice({
  name: 'pay-tot', // will be used for creating the action types. E.g.: "app/serverReturnedStats"
  initialState: INIT_STATE,
  reducers: {
    setReportedRevenue: (state, { payload }) => {
      state.reportedRevenue = payload;
    },

    setCalculatedTOTData: (state, { payload }) => {
      state.calculatedTOTData = payload;
    },

    setPaymentData: (state, { payload }) => {
      state.paymentData = payload;
    },

    // Reset all the fields unrelated to login
    resetNonLoginInformation: (state) => {
      state.reportedRevenue = {};
      state.calculatedTOTData = {};
      state.paymentData = {};
    },

    resetFlow: () => INIT_STATE,
  },
})

export const payTotActions = payTotSlice.actions;

export const payTotReducer = payTotSlice.reducer;
