import { BlackButton } from 'common/navigation-buttons';
import styled from 'styled-components';

export const TableActionButton = styled(BlackButton).attrs({
  width: 'fit-content',
})`
  min-width: 100px !important;

  .MuiButton-label {
    padding: 5px 15px;
  }
`;
