import CircularProgress from '@material-ui/core/CircularProgress';
import { Tooltip } from 'common/portal/tooltip';
import React from 'react';

export function DataFetchSpinner(props) {
  const { show, size, tooltip } = props;

  if (!show) return null;

  return (
    <Tooltip placement="top" title={tooltip ?? 'Data fetch in progress.'}>
      <CircularProgress size={size ?? 20} />
    </Tooltip>
  );
}
