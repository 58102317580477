import styled from 'styled-components';

export const TaxSummaryHeader = styled.p`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
`;

export const LicenseReportSummaries = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export const PaymentPeriodSummaries = styled.div`
  display: flex;
  flex-direction: column;
  gap: 45px;
`;

export const PaymentPeriodSummary = styled.div`
`;

export const TotalPayment = styled.div`
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
`;
