import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import * as appSelectors from 'app/state/app/app-selectors';
import * as dashboardSelectors from 'app/state/dashboard/dashboard-selectors';
import { dashboardActions } from 'app/state/dashboard/dashboard-slice';
import * as cs from 'common/common-styles';
import { BoxShadow } from 'common/common-styles';
import { WhiteButton } from 'common/navigation-buttons';
import dayjs from 'dayjs';
import {
  NonReportedItem,
  ReportedItem,
} from 'pages/portal/dashboard/pages/single-permit/modules/reporting-history/styles';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FLOW_PATHS } from 'routes/constants';
import { history } from 'routes/history';
import styled from 'styled-components/macro';
import titleize from 'titleize';
import { convertNumberToCurrency } from 'utils/utility-functions';
import { TaxSummaryTableComponent } from '../tax-summary-table/tax-summary-table';

const StyledAccordionSummary = styled(AccordionSummary)`
  ${BoxShadow}
`;

const HeaderColumnFirst = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  flex: 1.1;
  text-align: left;
`;

const HeaderColumnStandard = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  flex: 1;
  text-align: center;
  justify-content: space-between;
`;

const ReportedOnDiv = styled.div`
font-size: 16px;
margin-bottom: 5px;
`;

const ValueDivBold = styled.div`
font-weight: bold;
font-size: 16px;
margin-bottom: 0px;
`;

const NonReportedItemDateLabel = styled.div`
flex-grow: 2;
font-size: 16px;
`;

const NonReportedItemRightSideWrapper = styled.div`
padding: 20px 0px;
display: flex;
justify-content: space-between;
flex-grow: 2;
align-items: center;
`;

const Button = styled(WhiteButton)`
  &&& {
    width: 125px;
    background-color: white;
  }
`;

const StyledAccordion = styled(Accordion)`
  &&& {
    border: 1px solid rgb(230, 230, 233);
    border-radius: 10px;
    box-shadow: none;
  }
`;

const ReportedPeriodComponent = (props) => {
  const { data } = props;

  return (
    <StyledAccordion>
      <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
        <ReportedItem>
          <HeaderColumnFirst>
            <ReportedOnDiv>{data.headerData.paymentDateLabel}</ReportedOnDiv>
            <div>Reported on {dayjs(data.headerData.datePaid).format('MM/DD/YYYY')}</div>
            <div>by {data.headerData.reporter}</div>
          </HeaderColumnFirst>
          <HeaderColumnStandard>
            <div>Days<br />Available</div>
            <ValueDivBold>{data.headerData.daysAvailable}</ValueDivBold>
          </HeaderColumnStandard>
          <HeaderColumnStandard>
            <div>Days<br />Occupied</div>
            <ValueDivBold>{data.headerData.daysOccupied}</ValueDivBold>
          </HeaderColumnStandard>
          <HeaderColumnStandard>
            <div>Total Taxable<br />Receipts</div>
            <ValueDivBold>{convertNumberToCurrency(data.headerData.totalTaxableReceipts)}</ValueDivBold>
          </HeaderColumnStandard>
          <HeaderColumnStandard>
            <div>Payment</div>
            <ValueDivBold>{convertNumberToCurrency(data.headerData.paymentAmount)}</ValueDivBold>
          </HeaderColumnStandard>
          <HeaderColumnStandard>
            <div>Paid by</div>
            <ValueDivBold fontSize='16px' fontWeight='bold'>{data.headerData.paymentProvider}</ValueDivBold>
          </HeaderColumnStandard>
        </ReportedItem>
      </StyledAccordionSummary>
      <cs.AccordionDetail>
        <TaxSummaryTableComponent data={data.detailData} />
      </cs.AccordionDetail>
    </StyledAccordion>
  );
}

const NonReportedPeriodComponent = (props) => {
  const { data, applicationNumber, totPayable } = props;

  const dispatch = useDispatch();
  const activePermitData = useSelector(dashboardSelectors.activePermitByApplicationNumber(applicationNumber));
  const taxVerbiage = useSelector(appSelectors.totName);

  const PayTax = useCallback(() => {
    dispatch(dashboardActions.setLicensesToPayTaxOn([{
      name: data.headerData.paymentDateLabel,
      startDate: data.headerData.startDate,
      endDate: data.headerData.endDate,
      licenses: [{
        license: activePermitData.license,
        address: titleize(activePermitData.propertyAddress),
        totPaidStatus: "",
        ownerName: activePermitData.owner.name,
        selected: true,
      }],
    }]));
    history.push(`${FLOW_PATHS.payTot}/report-revenue`);
  }, [activePermitData, data, dispatch]);

  return (
    <NonReportedItem>
      <NonReportedItemDateLabel>{data.headerData.paymentDateLabel}</NonReportedItemDateLabel>
      <NonReportedItemRightSideWrapper>
        {
          totPayable && (
            <>
              <div>Not Yet Reported</div>
              <div><Button onClick={PayTax}>Pay {taxVerbiage}</Button></div>
            </>
          )
        }
      </NonReportedItemRightSideWrapper>
    </NonReportedItem>
  );
}

export function ReportingHistoryAccordionPeriodComponent(props) {
  const { data, applicationNumber, totPayable } = props;
  return (
    data.headerData.datePaid ?
      <ReportedPeriodComponent data={data} /> :
      <NonReportedPeriodComponent data={data} applicationNumber={applicationNumber} totPayable={totPayable} />
  );
}
