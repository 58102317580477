import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import dropRight from 'lodash/dropRight';
import last from 'lodash/last';
import styled from 'styled-components/macro';
import { Tooltip as MUITooltip, withStyles } from '@material-ui/core';
import * as appSelectors from 'app/state/app/app-selectors';
import { getPeriodDisplayName } from '../../utils';

const Tooltip = withStyles({
  tooltip: {
    fontSize: '14px',
    color: 'black',
    backgroundColor: 'white',
    border: '1px solid black',
    padding: '5px',
  },
})(MUITooltip);

const UnorderedList = styled.ul`
  margin: auto 5px;
  padding-left: 24px;
`;

const UnderlinedListItem = styled.li`
  text-decoration: underline;
  cursor: pointer;
`;

function getFormattedPeriodName(period) {
  return getPeriodDisplayName(period) || dayjs(period.dateStart).format('MMMM YYYY');
}

export function TaxablePeriods({ periods = [], onClick }) {
  const renderConfig = useSelector(appSelectors.renderConfig);

  const tooltipMessage = useMemo(() => dropRight(periods, 1).map(getFormattedPeriodName).reverse().join(', '), [periods]);

  if (!periods.length) return null;

  return (
    <UnorderedList>
      <li>{getFormattedPeriodName(last(periods))}</li>
      {/* Only list additional periods if there is more than one element in "totPayments" */}
      {periods.length > 1 && (
        <Tooltip title={tooltipMessage} placement="right">
          <UnderlinedListItem onClick={onClick}>
            +{periods.length - 1}{' '}
            {renderConfig?.reporting?.isQuarterlyPeriods
              ? 'Quarters'
              : 'Months'}
          </UnderlinedListItem>
        </Tooltip>
      )}
    </UnorderedList>
  );
}
