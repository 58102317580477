import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;

  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;

  .MuiCircularProgress-root {
    margin: auto 0;
  }
`;
