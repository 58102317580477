import * as appSelectors from 'app/state/app/app-selectors';
import { appActions } from 'app/state/app/app-slice';
import { loginActions } from 'app/state/login/login-slice';
import { Footer } from 'common/footer';
import SectionCard from 'common/section-card';
import React, { useEffect, useMemo } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { FLOW_PATHS } from 'routes/constants';
import {
  Grid,
  Segment,
  Container as SemanticContainer,
  Header as SemanticHeader,
} from 'semantic-ui-react';
import styled from 'styled-components';

import { AdditionalCards } from './modules/additional-cards';
import { NoticeBox, Type } from './modules/notice-box';
import { SecondaryNotice } from './modules/secondary-notice';
import { MAIN_REG_URL, isVfpr, FORECLOUSRES_REG_URL } from 'utils/constants';
import { staticRedirects } from 'utils/static-redirects';


import appSettings from 'app-settings.json';

const stage = appSettings.env;

const Container = styled(SemanticContainer)`
  margin-top: 10rem;
`;

const Column = styled(Grid.Column)`
  margin: auto;
`;

const Header = styled(SemanticHeader)`
  color: #5c5d5d !important;
  font-size: 50px !important;
  font-weight: normal !important;
`;

export function Home() {
  const dispatch = useDispatch();

  const stats = useSelector(appSelectors.stats);
  const flows = useSelector(appSelectors.flows);

  useEffect(() => {
    batch(() => {
      dispatch(appActions.setActiveFlow(null));
      dispatch(loginActions.resetLogin());
    });
  }, [dispatch]);

  const updateLeft =
    flows?.payTot?.enabled ||
    flows?.renewLicense?.enabled ||
    flows?.additionalCards?.right?.length;

  const displayWarningBanner = stage !== 'prod';


  const nonProdWarn = useMemo(() => {
    const deckardUrl = (isVfpr ? FORECLOUSRES_REG_URL : MAIN_REG_URL) + window.location.pathname;
    const placeName = stats?.cityInfo?.place;
    const url = (placeName in staticRedirects) ? staticRedirects[placeName] : deckardUrl;

    return `This is a test system only - please do not attempt to register your Property using this website.
      Please navigate to <a href="${url}">the production system</a> to register your Property`;
  }, [stats?.cityInfo?.place]);

  return (
    <Container>
      <Grid stackable>
        <Column width={13}>
          <Segment basic>
            <Header size="huge">Welcome!</Header>

            {/* Notice for unfinished system */}
            <NoticeBox
              isDevelopmentalNotice
              hideDevelopmentalNotice={stats?.app?.hideDevelopmentalNotice}
              type={Type.WARNING}
              certificateVerbiage={stats?.cityInfo?.certificateVerbiage}
            />

            {displayWarningBanner && <NoticeBox
              type={Type.WARNING}
              text={nonProdWarn}
            />}

            {/* Normal notice */}
            <NoticeBox text={stats?.app?.notice} type={Type.INFORMATIVE} />

            <Grid columns={2} stackable relaxed divided>
              <Grid.Column>
                {flows?.applyCertificate?.enabled && (
                  <SectionCard
                    title="Apply for a TOT Certificate"
                    url="/apply-tot"
                    image="ui-image-avatar-tot.svg"
                    dataCy="apply-certificate"
                  />
                )}

                {flows?.applyLicense?.enabled && (
                  <SectionCard
                    title={flows?.applyLicense?.menuButtonLabel}
                    url={FLOW_PATHS.applyStrLicense}
                    image="ui-image-avatar-str.svg"
                    dataCy="apply-license"
                  />
                )}

                {flows?.updateCertificate?.enabled && updateLeft && (
                  <SectionCard
                    title={flows?.updateCertificate?.menuButtonLabel}
                    url={FLOW_PATHS.updateCertificate}
                    image="ui-image-avatar-update-information.svg"
                    dataCy="update-certificate"
                  />
                )}

                <AdditionalCards cards={flows?.additionalCards?.left} />
              </Grid.Column>

              <Grid.Column>
                {flows?.updateCertificate?.enabled && !updateLeft && (
                  <SectionCard
                    title={flows?.updateCertificate?.menuButtonLabel}
                    url={FLOW_PATHS.updateCertificate}
                    image="ui-image-avatar-update-information.svg"
                    dataCy="update-certificate"
                  />
                )}

                {flows?.payTot?.enabled && (
                  <SectionCard
                    title={flows?.payTot?.menuButtonLabel}
                    url={FLOW_PATHS.payTot}
                    image="ui-image-avatar-pay-tot.svg"
                    dataCy="pay-tot"
                  />
                )}

                {flows?.renewLicense?.enabled && (
                  <SectionCard
                    title={flows?.renewLicense?.menuButtonLabel}
                    url={FLOW_PATHS.renewal}
                    image="renew-license-menu-button.svg"
                    dataCy="renew-license"
                  />
                )}

                <AdditionalCards cards={flows?.additionalCards?.right} />
              </Grid.Column>
            </Grid>

            <SecondaryNotice notice={stats?.app?.secondaryNotice} />
            <Footer />
          </Segment>
        </Column>
      </Grid>
    </Container>
  );
}
