import dayjs from 'dayjs';
import { useFormikContext } from 'formik';
import get from 'lodash/get';
import React, { useMemo } from 'react';
import { Col, Divider, Grid, Row } from 'rsuite';
import { Icon } from 'semantic-ui-react';
import { convertNumberToCurrency } from 'utils/utility-functions';
import { InputCurrencyField, InputNumberField } from '../components/input-field';

const PropertyHeader = ({ propertyAddress }) => (
  <Row gutter={30} style={{ display: 'flex', alignItems: 'flex-end', paddingBottom: '15px' }}>
    <Col xs={12} style={{ fontWeight: 'bold', fontSize: '18px' }}>
      {propertyAddress}
    </Col>
    <Col xs={6}></Col>
    <Col xs={6}>All Platforms</Col>
  </Row>
);

const SalesRow = ({ salesLabel, formikKeyStart, salesAndDeductionsValidation }) => (
  <Row style={{ display: 'flex', alignItems: 'center', padding: '10px', backgroundColor: "#F2F2F2" }} gutter={30}>
    <Col xs={12}>{salesLabel}</Col>
    <Col xs={6}></Col>
    <Col xs={6}>
      <div style={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
        <Icon name='dollar sign' style={{ marginBottom: '10px' }} />
        <InputCurrencyField id={`${formikKeyStart}.providers.other.sales`} name={`${formikKeyStart}.providers.other.sales`} validation={salesAndDeductionsValidation} />
      </div>
    </Col>
  </Row>
);

const DeductionsRow = ({ deductionsLabel, formikKeyStart, salesAndDeductionsValidation }) => (
  <Row style={{ display: 'flex', alignItems: 'center', padding: '10px' }} gutter={30}>
    <Col xs={12}>{deductionsLabel}</Col>
    <Col xs={6}></Col>
    <Col xs={6}>
      <div style={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
        <Icon name='dollar sign' style={{ marginBottom: '10px' }} />
        <InputCurrencyField type='number' id={`${formikKeyStart}.providers.other.deductions`} name={`${formikKeyStart}.providers.other.deductions`} validation={salesAndDeductionsValidation} />
      </div>
    </Col>
  </Row>
);

const TaxableReceiptsRow = ({ taxableReceiptsLabel, getTotalTaxableReceiptsForOtherPlatforms }) => (
  <Row style={{ display: 'flex', alignItems: 'center', padding: '10px' }} gutter={30}>
    <Col xs={12} style={{ fontWeight: 'bold' }}>{taxableReceiptsLabel}</Col>
    <Col xs={6}></Col>
    <Col xs={6}>{convertNumberToCurrency(getTotalTaxableReceiptsForOtherPlatforms)}</Col>
  </Row>
);

const DividerRow = () => (
  <Row>
    <Col>
      <Divider style={{ margin: '0', padding: '0' }} />
    </Col>
  </Row>
);

const PropertyOccupancySection = ({ formikKeyStart, numDaysInPeriod }) => {
  const { values } = useFormikContext();
  const maxNumberOfDaysAvailable = useMemo(() => get(values, `${formikKeyStart}.providers.other.daysAvailable`) || 0, [values, formikKeyStart]);

  const daysAvailableValidation = {
    validationType: 'number',
    rules: [
      { type: 'required', params: ['This field is required.'] },
      { type: 'integer', params: ['This field only accepts integers.'] },
      { type: 'min', params: [0, `The number of days available cannot be less than 0.`] },
      { type: 'max', params: [numDaysInPeriod, `The number of days available cannot be more than ${numDaysInPeriod}.`] }
    ],
  };

  const daysOccupiedValidation = useMemo(() => ({
    validationType: 'number',
    rules: [
      { type: 'required', params: ['This field is required.'] },
      { type: 'min', params: [0, 'The minimum value accepted is 0.'] },
      { type: 'max', params: [maxNumberOfDaysAvailable, `The maximum value can't be more than the number of days available ${maxNumberOfDaysAvailable}.`] },
      { type: 'integer', params: ['The field only accepts integer values.'] }
    ],
  }), [maxNumberOfDaysAvailable]);

  return (
    <Row style={{ display: 'flex', alignItems: 'center', padding: '10px' }} gutter={30}>
      <Col xs={12} style={{ fontWeight: 'bold' }}>Property Occupancy</Col>
      <Col xs={6}>
        No. days available
        <InputNumberField id={`${formikKeyStart}.providers.other.daysAvailable`} name={`${formikKeyStart}.providers.other.daysAvailable`} validation={daysAvailableValidation} />
      </Col>
      <Col xs={6}>
        No. days occupied
        <InputNumberField validation={daysOccupiedValidation} id={`${formikKeyStart}.providers.other.daysOccupied`} name={`${formikKeyStart}.providers.other.daysOccupied`} />
      </Col>
    </Row>
  );
};

export function PropertyTaxableReceiptsComponent(props) {
  const {
    propertyAddress,
    license,
    salesLabel,
    deductionsLabel,
    taxableReceiptsLabel,
    showPropertyOccupancySection,
    startDate,
    endDate,
    propertySpacing,
  } = props;

  const differenceInDays = useMemo(() => {
    const start = dayjs(startDate);
    const end = dayjs(endDate);
    return end.diff(start, 'day') + 1; // Adding 1 to include both start and end days
  }, [startDate, endDate]);

  const formikKeyStart = useMemo(() => {
    const dateKey = `${startDate}_${endDate}`;
    return `taxableReceipts.${dateKey}._${license}`; // Have to add the _ before license because formik does not handle numbers as a key well
  }, [startDate, endDate, license])

  const numDaysInPeriod = differenceInDays;

  const { values } = useFormikContext();

  const salesPlatformsThatDontRemitTOT = useMemo(() => get(values, `${formikKeyStart}.providers.other.sales`) || 0, [values, formikKeyStart]);
  const deductionsPlatformsThatDontRemitTOT = useMemo(() => get(values, `${formikKeyStart}.providers.other.deductions`) || 0, [values, formikKeyStart]);

  const getTotalTaxableReceiptsForOtherPlatforms = useMemo(() => {
    return salesPlatformsThatDontRemitTOT - deductionsPlatformsThatDontRemitTOT;
  }, [salesPlatformsThatDontRemitTOT, deductionsPlatformsThatDontRemitTOT]);

  const salesAndDeductionsValidation = {
    validationType: 'number',
    rules: [
      { type: 'required', params: ['This field is required.'] },
      { type: 'min', params: [0, 'The minimum value accepted is 0.'] },
    ],
  };

  return (
    <Grid fluid={true} style={{ width: '100%', marginBottom: propertySpacing }}>
      <PropertyHeader propertyAddress={propertyAddress} />
      <SalesRow salesLabel={salesLabel} formikKeyStart={formikKeyStart} salesAndDeductionsValidation={salesAndDeductionsValidation} />
      <DeductionsRow deductionsLabel={deductionsLabel} formikKeyStart={formikKeyStart} salesAndDeductionsValidation={salesAndDeductionsValidation} />
      <DividerRow />
      <TaxableReceiptsRow taxableReceiptsLabel={taxableReceiptsLabel} getTotalTaxableReceiptsForOtherPlatforms={getTotalTaxableReceiptsForOtherPlatforms} />
      {showPropertyOccupancySection && (
        <PropertyOccupancySection formikKeyStart={formikKeyStart} numDaysInPeriod={numDaysInPeriod} />
      )}
      <DividerRow />
    </Grid>
  );
}
