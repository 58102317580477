import { Auth } from 'aws-amplify';

export const isAuthenticated = async () => {
  try {
    await Auth.currentSession();
    return true;
  } catch (err) {
    return false;
  }
};

export const currentAuthenticatedUser = async () => {
  try {
    const { username } = await Auth.currentAuthenticatedUser();
    return username;
  } catch {
    return null;
  }
}
