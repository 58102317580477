import * as loginSelectors from 'app/state/login/login-selectors';
import * as payloadSelectors from 'app/state/payload/payload-selectors';
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { ROOT_PATH } from 'routes/constants';

import { MidFlowRoute } from './mid-flow-route';

/**
 * This component should be used for all pages where the user should not enter
 * directly and without being authenticated first.
 * Namely, in order to access a MidFlowAuthRoute, the user must have gone in sequence
 * through the flow and have had their identify verified.
 *
 * @param {*} props.children
 * @param {string} props.path
 */
export function MidFlowAuthRoute(props) {
  const { path, children, component } = props;

  const authedEmailFromModal = useSelector(loginSelectors.userEmail);
  const authedEmailFromLookupModeEmail = useSelector(
    payloadSelectors.lookupModeEmail,
  );

  if (!authedEmailFromModal && !authedEmailFromLookupModeEmail)
    return <Redirect to={ROOT_PATH} />;

  return (
    <MidFlowRoute exact path={path} component={component}>
      {children}
    </MidFlowRoute>
  );
}
