import { createSlice } from '@reduxjs/toolkit';

const INIT_STATE = {
  hasErrored: false,
  pageError: null,
  alternateErrorString: null,
};

export const errorSlice = createSlice({
  name: 'error',
  initialState: INIT_STATE,
  reducers: {
    setHasErrored: (state, { payload }) => {
      state.hasErrored = payload;
    },

    setPageError: (state, { payload }) => {
      state.pageError = payload?.message
    },

    setAlternateErrorString: (state, action) => {
      state.alternateErrorString = action.payload;
    },

    clearAlternateErrorString: (state) => {
      state.alternateErrorString = INIT_STATE.alternateErrorString;
    },

    resetErrorStates: () => INIT_STATE,
  },
})

export const errorActions = errorSlice.actions;

export const errorReducer = errorSlice.reducer;
