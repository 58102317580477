import { usePaymentUIState } from "../../../use-payment-ui-state";
import { ZERO as RSUITE_ALERT_DO_NOT_CLOSE } from "common/constants";
import React, { useCallback, useState } from "react";
import { strRegistrationApiSender } from "utils/str-registration-api-sender";
import {
  STRIPE_NO_PAYMENT_METHOD_ATTACHED_INTENT_STATUSES,
  STRIPE_PAYMENT_INTENT_STATUSES
} from "../constants";
import { Alert } from "rsuite";
import {
  ACCOUNT_PICKER_CANCELLED_ALERT_MSG,
  ACCOUNT_PICKER_ERROR_ALERT_MSG,
} from "../../../constants";
import { ERROR_MESSAGES } from "common/error-constants";
import * as cs from "common/common-styles";
import { PaymentButton } from "../../../modules/payment-button/payment-button";
import styled from "styled-components";
import { Input, PaymentConfirmationForm, StyledPaymentInfoText } from "./stripe-ach-styles";
import { StripeAchUserReportingModal } from "./stripe-ach-user-reporting-modal";

const InputSection = styled.div`
`;

const BasicBankDetailsInputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: stretch;
  width: 100%;
  max-width: 93vw;
`;

export function StripeACHAccountHolderForm(props) {
  const {
    setBankAccountDetails,
    setClientSecret,
    stripe,
  } = props;

  const {
    paymentFees,
    updateLock,
    shouldLockPayments,
    label,
    capturePaymentException,
  } = usePaymentUIState(props);

  const [accountHolderName, setAccountHolderName] = useState(props.signatory);
  const [accountHolderEmail, setAccountHolderEmail] = useState(props.email);

  const [errorModalOpen, setErrorModalOpen] = useState(false);

  const setupPaymentIntent = useCallback(async () => {
    updateLock(true);
    try {
      const { data: { clientSecret } } = await strRegistrationApiSender.post('/payACHDirectDebit', {
        paymentId: paymentFees.paymentId,
        signatory: props.signatory,
        email: props.email,
      });

      const { paymentIntent, error } = await stripe.collectBankAccountForPayment({
        clientSecret,
        params: {
          payment_method_type: 'us_bank_account',
          payment_method_data: {
            billing_details: {
              name: accountHolderName,
              email: accountHolderEmail.trim(),
            }
          },
        },
        expand: ['payment_method'],
      });

      if (!error && paymentIntent.status === STRIPE_PAYMENT_INTENT_STATUSES.REQUIRES_CONFIRMATION) {
        const {
          account_number,
          routing_number,
          account_type,
          bank_name,
          last4,
        } = paymentIntent?.payment_method?.us_bank_account || {};

        const {
          name
        } = paymentIntent?.payment_method?.billing_details || {};

        setBankAccountDetails({
          accountNumber: account_number,
          routingNumber: routing_number,
          accountType: account_type,
          bankName: bank_name,
          accountHolder: name,
          last4,
        });

        setClientSecret(paymentIntent.client_secret);
      } else if (STRIPE_NO_PAYMENT_METHOD_ATTACHED_INTENT_STATUSES.includes(paymentIntent?.status)) {
        setErrorModalOpen(true);
        Alert.error(ACCOUNT_PICKER_CANCELLED_ALERT_MSG, RSUITE_ALERT_DO_NOT_CLOSE)
      } else if (error) {
        Alert.error(ACCOUNT_PICKER_ERROR_ALERT_MSG, RSUITE_ALERT_DO_NOT_CLOSE)
        throw new Error(error.code);
      } else {
        Alert.error(ACCOUNT_PICKER_ERROR_ALERT_MSG, RSUITE_ALERT_DO_NOT_CLOSE)
        throw new Error(`Received unexpected payment intent status from collectBankAccountForPayment: ${paymentIntent?.status}`);
      }
    } catch (err) {
      capturePaymentException(err);
      console.error(err)
      Alert.error(ERROR_MESSAGES.PayACHError, RSUITE_ALERT_DO_NOT_CLOSE);
    }

    updateLock(false);
  }, [accountHolderEmail, accountHolderName, capturePaymentException, paymentFees.paymentId, props.email, props.signatory, setBankAccountDetails, setClientSecret, stripe, updateLock]);

  return (
    <PaymentConfirmationForm>
      <StyledPaymentInfoText>
        <p>To make a secure payment, connect to your bank account and verify your identity.</p>
        <p>Please ensure that the name provided matches the name on file for the bank account and disable any active pop-up blockers as they could interfere with the payment process.</p>
      </StyledPaymentInfoText>

      <StripeAchUserReportingModal
        capturePaymentException={capturePaymentException}
        paymentFees={paymentFees}
        setErrorModalOpen={setErrorModalOpen}
        errorModalOpen={errorModalOpen}
      />

      <BasicBankDetailsInputs>
        <InputSection>
          <cs.InputTextFieldLabel>{`Account Holder's Full Name`}</cs.InputTextFieldLabel>
          <Input value={accountHolderName} onChange={setAccountHolderName}/>
        </InputSection>

        <InputSection>
          <cs.InputTextFieldLabel>Email</cs.InputTextFieldLabel>
          <Input value={accountHolderEmail} onChange={setAccountHolderEmail}/>
        </InputSection>
      </BasicBankDetailsInputs>

      <PaymentButton
        label={label}
        onClick={setupPaymentIntent}
        disabled={shouldLockPayments}
      />
    </PaymentConfirmationForm>
  )
}
