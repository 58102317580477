import * as appSelector from 'app/state/app/app-selectors';
import { PortalPageContainer } from 'common/portal/styles';
import { Form, Formik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import { usePortalAuth } from 'login/hooks/use-portal-auth';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { PORTAL_ACCOUNT_CREATION_SLUGS, PORTAL_PATHS } from 'routes/constants';
import { history } from 'routes/history';
import { Alert } from 'rsuite';
import styled from 'styled-components';
import { captureExceptionWithContext } from 'utils/sentry-functions';
import { strRegistrationApiSender } from 'utils/str-registration-api-sender';

// import { Debug } from 'configurable-form/components/debugging/debug';
import { AccountDetails } from './create-process/account-details';
import { EmailPage } from './create-process/email-page';
import {
  PortalAccountCreationCreationStepError,
  PortalAccountCreationEmailStepError,
} from './create-process/errors';

const Div = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export function CreatePortalAccount() {
  const match = useRouteMatch(PORTAL_PATHS.createAccountSlugged);

  const jurisdictionPlaceString = useSelector(appSelector.place);

  const [loading, setLoading] = useState(false);

  const { dispatchInitialUserLoginFlowActions } = usePortalAuth();

  const handleEmailPageContinueClick = useCallback(
    async (email) => {
      const emailAddress = String(email).toLowerCase();
      try {
        /**
         * This step is important to ensure that an email address is not
         * already registered with our system. If it is, then we will
         * indicate as such to the user. However, if email address is not
         * already in use, then the we will direct the user to the next step.
         */
        const { data } = await strRegistrationApiSender.get(
          `/getUserIdByEmail/${emailAddress}`,
        );

        setLoading(false);

        if (!isEmpty(data)) {
          /**
           * When "data is not empty", then that means there already is an account
           * registered with that email address.
           */
          return Alert.error('That email address is already in use.', 10000);
        }

        history.push(PORTAL_PATHS.createAccountAccount);
      } catch (err) {
        setLoading(false);

        captureExceptionWithContext(
          new PortalAccountCreationEmailStepError(err),
          {
            errorMsg:
              'Error when attempting to create an account at the email step.',
            place: jurisdictionPlaceString,
            emailAddress,
          },
        );

        Alert.error(
          'There was an issue while attempting create your account. Please try again at a later time.',
          10000,
        );
      }
    },
    [jurisdictionPlaceString],
  );

  const handleAccountDetailsContinueClick = useCallback(
    async (payload) => {
      /**
       * Payload in this context is the data contained in Formik state.
       * Specifically, the "values" sub-dict of the "formik" object.
       * Contained within "values" is the information the user entered
       * in the form on the personal details page.
       */
      const userEmail = String(payload.email).toLowerCase(); // Ensure lowercase

      try {
        const { data } = await strRegistrationApiSender.post(
          '/createAccount',
          payload,
        );

        /**
         * Upon a successful account creation, we will then display the
         * authentication modal for the user to verify themselves.
         */
        dispatchInitialUserLoginFlowActions(userEmail, data);
        setLoading(false);
      } catch (err) {
        setLoading(false);

        captureExceptionWithContext(
          new PortalAccountCreationCreationStepError(err),
          {
            errorMsg:
              'Error when attempting to create an account at account creation step.',
            place: jurisdictionPlaceString,
            payload: JSON.stringify(payload),
          },
        );

        return Alert.error(
          'There was an issue while attempting create your account. Please try again at a later time.',
          10000,
        );
      }
    },
    [dispatchInitialUserLoginFlowActions, jurisdictionPlaceString],
  );

  const handleSubmit = useCallback(
    async (payload) => {
      setLoading(true);

      /**
       * Depending on which page the user is on, the system will invoke either:
       *  1. "handleEmailPageContinueClick" on the email page
       *  2. "handleAccountDetailsContinueClick" on the personal details page
       *
       * Matching is done via the URL slug
       */
      if (match?.params?.slug === PORTAL_ACCOUNT_CREATION_SLUGS.email) {
        await handleEmailPageContinueClick(payload.email);
      } else if (
        match?.params?.slug === PORTAL_ACCOUNT_CREATION_SLUGS.account
      ) {
        await handleAccountDetailsContinueClick(payload);
      }

      setLoading(false);
    },
    [
      handleAccountDetailsContinueClick,
      handleEmailPageContinueClick,
      match?.params?.slug,
    ],
  );

  return (
    <PortalPageContainer>
      <Formik
        validateOnChange
        validateOnBlur
        initialValues={{}}
        onSubmit={handleSubmit}
      >
        <Form>
          <Div>
            <Switch>
              <Route exact path={PORTAL_PATHS.createAccountEmail}>
                <EmailPage loading={loading} />
              </Route>

              <Route exact path={PORTAL_PATHS.createAccountAccount}>
                <AccountDetails loading={loading} />
              </Route>

              <Route path={PORTAL_PATHS.createAccount}>
                <Redirect to={PORTAL_PATHS.createAccountEmail} />
              </Route>
            </Switch>
          </Div>
          {/* <Debug /> */}
        </Form>
      </Formik>
    </PortalPageContainer>
  );
}
