import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import * as networkSelectors from 'app/state/network/network-selectors';
import template from 'lodash/template';
import { strRegistrationApiSender } from 'utils/str-registration-api-sender';
import { convertNumberToCurrency, parseHTMLString } from 'utils/utility-functions';

import { PaymentButton } from '../../modules/payment-button/payment-button';
import isNil from 'lodash/isNil';
import { usePaymentUIState } from "../../use-payment-ui-state";
import * as appSelectors from "app/state/app/app-selectors";

export const useFetchACHBankDetails = ({ email, signatory }) => {
  const { runWithUILockAndExceptionHandling } = usePaymentUIState({ email, signatory })
  const { paymentId } = useSelector(networkSelectors.paymentFees);
  const [paymentDetails, setPaymentDetails] = useState();

  useEffect(() => {
    runWithUILockAndExceptionHandling(
      async () => {
        if (isNil(paymentId) || !email || !signatory) return;
        if (paymentDetails) {
          console.warn("useFetchACHBankDetails should not be called multiple times")
          throw new Error("useFetchACHBankDetails should not be called multiple times");
        }

        const { data: payACHCreditResponse } = await strRegistrationApiSender.post('/payACHCredit', {
          email,
          paymentId,
          signatory,
        });

        setPaymentDetails(payACHCreditResponse);
      }
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentId, email, signatory, paymentDetails]);

  return paymentDetails;
};

const ACH_BUTTON_LABEL = 'Select ACH Bank Transfer as your payment method';

export const PushACH = ({
  email,
  onSuccess,
  paymentTabPayByACHPushText,
  signatory,
}) => {
  const { shouldLockPayments, runWithUILockAndExceptionHandling, paymentFees } = usePaymentUIState({ email, signatory })
  const pushACHBankDetails = useFetchACHBankDetails({ email, signatory });
  const cityInfo = useSelector(appSelectors.cityInfo);

  const templatedMessage = useMemo(
    () => {
      if (!pushACHBankDetails?.clientPaymentInfo || !paymentTabPayByACHPushText) return null;
      const { account_number, bank_name, routing_number } =
        pushACHBankDetails?.clientPaymentInfo || {};
      const clientPaymentInfo = {
        account_number,
        bank_name,
        routing_number,
        total_amount_due: convertNumberToCurrency(paymentFees?.achCreditTotal),
        city_name: cityInfo?.name,
      };
      return template(paymentTabPayByACHPushText)(clientPaymentInfo)
    },
    [cityInfo?.name, paymentFees?.achCreditTotal, paymentTabPayByACHPushText, pushACHBankDetails?.clientPaymentInfo],
  );

  const handleClick = useCallback(async () => {
    runWithUILockAndExceptionHandling(onSuccess);
  }, [runWithUILockAndExceptionHandling, onSuccess]);

  return (
    <div>
      {templatedMessage && parseHTMLString(templatedMessage)}
      <PaymentButton
        disabled={shouldLockPayments}
        label={ACH_BUTTON_LABEL}
        loading={shouldLockPayments}
        onClick={handleClick}
      />
    </div>
  );
};
