import { createStyles, withStyles } from "@material-ui/core/styles";
import { Tab, Tabs } from "@material-ui/core";
import React from "react";

export const StyledTabs = withStyles({
  root: {
    maxWidth: 450,
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
  },
  indicator: {
    backgroundColor: '#000000',
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span/> }}/>);

export const StyledTab = withStyles(() =>
  createStyles({
    root: {
      textTransform: 'none',
      minWidth: 72,
      fontFamily: 'Lato',
      fontSize: '15px',
      color: '#000000',
      '&:focus': {
        fontWeight: 'bold'
      },
    },
  }),
)((props) => <Tab disableRipple {...props} />);
