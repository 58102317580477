export class DashboardRetrieveUserLicensesError extends Error {
  constructor(message) {
    super(message);
    this.name = 'DashboardRetrieveUserLicensesError';
  }
}

export class DashboardRenewableLicensesAPIError extends Error {
  constructor(message) {
    super(message);
    this.name = 'DashboardRenewableLicensesAPIError';
  }
}

export class DashboardTaxableLicensePeriodsAPIError extends Error {
  constructor(message) {
    super(message);
    this.name = 'DashboardTaxableLicensePeriodsAPIError';
  }
}

export class DashboardUserReportingHistoryAPIError extends Error {
  constructor(message) {
    super(message);
    this.name = 'DashboardUserReportingHistoryAPIError';
  }
}
