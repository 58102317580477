import * as appSelectors from 'app/state/app/app-selectors';
import * as dashboardSelectors from 'app/state/dashboard/dashboard-selectors';
import * as payTOTSelector from 'app/state/pay-tot/pay-tot-selectors';
import { PROVIDER_MAP_PATH, TAX_SUMMARY_BREAKDOWN_MAP_PATH } from 'common/constants';
import dayjs from 'dayjs';
import { useFormikContext } from 'formik';
import get from 'lodash/get';
import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { convertNumberToCurrency, convertStringToCurrency } from 'utils/utility-functions';
import { TaxSummaryAccordionPeriodComponent } from './tax-summary-accordion-period';

const formatDate = (dateString) => {
  const date = dayjs(dateString);
  return date.format('YYYY-MM-DD');
}

const datesAreTheSame = (dateOne, dateTwo) => {
  const dayOneDayJs = dayjs(dateOne);
  const dayTwoDayJs = dayjs(dateTwo);
  return dayOneDayJs.isSame(dayTwoDayJs);
};

// Checks if an object alreasy exists in 'groups' with the same dates as 'item'
// If it does return it, else create and return a new group
const findOrCreateGroupForPeriod = (groups, item) => {
  const formattedStartDate = formatDate(item.startDate);
  const formattedEndDate = formatDate(item.endDate);

  let group = groups.find(group => datesAreTheSame(group.startDate, item.startDate) && datesAreTheSame(group.endDate, item.endDate));
  if (!group) {
    group = {
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      label: item.period,
      licenses: [],
      totalDueForPeriod: 0
    };
    groups.push(group);
  }
  return group;
};

export function TaxSummaryAccordionComponent(
  {
    deductionsEnabled,
    salesLabel,
    deductionsLabel,
    totalLabel,
  }) {

  const providerMap = useSelector(
    appSelectors.getFromPortalStats(PROVIDER_MAP_PATH),
  );

  const taxTypeBreakdownMap = useSelector(
    appSelectors.getFromPortalStats(TAX_SUMMARY_BREAKDOWN_MAP_PATH),
  );

  // Licenses selected to pay tax on (used to get the addresss of licenses)
  const bulkLicensesToPayTaxOn = useSelector(
    dashboardSelectors.bulkLicensesToPayTaxDateDesc
  );

  // raw data returned from  /calculateMultiPropertyTot
  const totData = useSelector(payTOTSelector.calculatedTOTData);
  const totDetails = totData.details;

  // Formik values, needed to get the sales and deductions inputs
  // the /calculateMultiPropertyTot only returns the taxablereceipts not what is is calculated from
  const { values } = useFormikContext();

  const getAddress = useMemo(() => {
    return (licenseNumber, startDate, endDate) => {
      const selectedLicensesToPayTaxOn = bulkLicensesToPayTaxOn;
      const matchingPeriod = selectedLicensesToPayTaxOn.find(period => datesAreTheSame(period.startDate, startDate) && datesAreTheSame(period.endDate, endDate));

      let address = matchingPeriod?.licenses.find(property => property.license === licenseNumber)?.address
      return address || 'Unknown';
    };
  }, [bulkLicensesToPayTaxOn]);


  const initializeLicenseValues = useCallback(() => {
    return {
      taxableReceipts: [],
      salesAndDeductions: {
        taxableReceiptsProviderValues: [],
        salesProviderValues: [],
        deductionsProviderValues: [],
        salesLabel: salesLabel || "Lodging Gross Sales",
        deductionsLabel: deductionsLabel || "Allowable Deductions",
        totalLabel: totalLabel || "Taxable Receipts"
      },
      totalPaymentDueProviderValues: [],
      allProvidersSet: new Set()
    };
  }, [salesLabel, deductionsLabel, totalLabel])

  const addProvider = useCallback((providerKey, allProvidersSet) => {
    allProvidersSet.add({
      id: providerKey,
      label: providerMap[providerKey] || providerKey
    });
  }, [providerMap])

  const addTaxableReceiptsProviderValue = useCallback((providerKey, salesAndDeductions, value) => {
    let taxableReceiptsForProvider = convertStringToCurrency(value);
    salesAndDeductions.taxableReceiptsProviderValues.push({
      providerID: providerKey,
      value: taxableReceiptsForProvider
    })
  }, [])

  const addSalesAndDeductionsProviderValue = useCallback((periodKey, providerKey, licenseNumber, salesAndDeductions) => {
    // find match in formik tot get the sales and deductions values
    // When saving taxableRecepits in formik we add a _ prior to the licenseNumber as formik does not handle numbers well as keys
    let lMatchFormikInputs = get(values, `taxableReceipts.${periodKey}._${licenseNumber}.providers.${providerKey}`);

    let sales = convertStringToCurrency(lMatchFormikInputs.sales);

    salesAndDeductions.salesProviderValues.push({
      providerID: providerKey,
      value: sales
    });

    if (deductionsEnabled) {
      let deductions = convertStringToCurrency(lMatchFormikInputs.deductions);

      salesAndDeductions.deductionsProviderValues.push({
        providerID: providerKey,
        value: deductions
      });
    }
  }, [values, deductionsEnabled])

  const convertTaxTypeBreakdown = useCallback((licenseTaxableReceiptsForType, taxType, activity, provider) => {
    const amountDueForType = activity.taxTypes[taxType.key][taxType.totalKey];
    const providerValue = convertStringToCurrency(amountDueForType);
    licenseTaxableReceiptsForType.primaryItem.providerValues.push({ providerID: provider, value: providerValue });

    taxType.breakdown.forEach(component => {
      let breakdownMatch = licenseTaxableReceiptsForType.breakdown.find(obj => obj.key === component.key);
      if (!breakdownMatch) {
        breakdownMatch = {
          key: component.key,
          label: component?.name,
          infoText: component?.infoText || "",
          providerValues: []
        };
        licenseTaxableReceiptsForType.breakdown.push(breakdownMatch);
      }
      const breakdownValue = activity.taxTypes[taxType.key][component.key];
      breakdownMatch.providerValues.push({ providerID: provider, value: convertStringToCurrency(breakdownValue) });
    });
  }, []);

  // Convert the data into a suitable format for the tax accordion components
  const convertedDataGroupedByPeriod = useMemo(() => {
    const sortedData = [...totDetails].sort((a, b) => new Date(b.endDate) - new Date(a.endDate));

    // Group data by start and end dates
    const displayTaxablePeriods = sortedData.reduce((allTaxablePeriods, calculatedLicenseTaxData) => {
      let taxablePeriod = findOrCreateGroupForPeriod(allTaxablePeriods, calculatedLicenseTaxData)

      let { taxableReceipts, salesAndDeductions, totalPaymentDueProviderValues, allProvidersSet } = initializeLicenseValues();

      let periodKey = `${formatDate(taxablePeriod.startDate)}_${formatDate(taxablePeriod.endDate)}`

      // Iterate over each taxable activity for the license
      calculatedLicenseTaxData.misc.taxableActivities.forEach(activity => {
        const provider = activity.provider;
        addProvider(provider, allProvidersSet);

        addTaxableReceiptsProviderValue(provider, salesAndDeductions, activity.taxableReceipts)

        addSalesAndDeductionsProviderValue(periodKey, provider, calculatedLicenseTaxData.licence_no, salesAndDeductions);

        // Total tax to be paid for this provider
        let totalDueForProvider = 0;

        // iterate over each tax type in the taxtypemapping
        // we can't just loop through activity.taxTypes, beucase sometimes we want to show data
        // that is not directly returned from the endpoint (which may vay for different towns)
        taxTypeBreakdownMap.forEach((taxType) => {
          // find matching object in activity
          let resultTaxObj = activity.taxTypes[taxType.key];

          if (resultTaxObj) {
            let licenseTaxableReceiptsForType = taxableReceipts.find(rec => rec.key === taxType);

            if (!licenseTaxableReceiptsForType) {
              licenseTaxableReceiptsForType = {
                primaryItem: {
                  key: taxType,
                  label: taxType.name,
                  infoText: taxType.infoText || "", // If empty string no info icon will be displayed
                  providerValues: []
                },
                breakdown: []
              }
              taxableReceipts.push(licenseTaxableReceiptsForType);
            }

            convertTaxTypeBreakdown(licenseTaxableReceiptsForType, taxType, activity, provider);
            totalDueForProvider += parseFloat(resultTaxObj[taxType.totalKey]);
          }
        })

        totalPaymentDueProviderValues.push({
          providerID: provider,
          value: convertStringToCurrency(totalDueForProvider)
        })

        taxablePeriod.totalDueForPeriod += totalDueForProvider;
      });

      let propertyAddress = getAddress(calculatedLicenseTaxData.licence_no, taxablePeriod.startDate, taxablePeriod.endDate);

      let convertedLicense = {
        providers: Array.from(allProvidersSet),
        salesAndDeductions: salesAndDeductions,
        taxableReceipts: taxableReceipts,
        totalPaymentDue: {
          total: convertStringToCurrency(calculatedLicenseTaxData.total),
          providerValues: totalPaymentDueProviderValues
        },
        address: propertyAddress,
        periodLabel: taxablePeriod.label
      }

      taxablePeriod.licenses.push(convertedLicense);

      return allTaxablePeriods;
    }, []);

    return displayTaxablePeriods;

  }, [totDetails, taxTypeBreakdownMap, addProvider, addSalesAndDeductionsProviderValue, convertTaxTypeBreakdown, getAddress, initializeLicenseValues, addTaxableReceiptsProviderValue]);

  // calculate total tax payable for the selected properties
  const totalPaymentDue = useMemo(() => {
    let totalDue = convertedDataGroupedByPeriod.reduce((total, reportingPeriod) => total + reportingPeriod.totalDueForPeriod, 0);
    return convertNumberToCurrency(totalDue);
  }, [convertedDataGroupedByPeriod]);

  return (
    <>
      {
        convertedDataGroupedByPeriod.map((group, index) => (
          <TaxSummaryAccordionPeriodComponent key={index} data={group} />
        ))
      }
      <div style={{ marginTop: '40px', display: 'flex', justifyContent: 'space-between' }}>
        <h4>Total Payment Due</h4>
        <h4 style={{ marginRight: '65px' }}>{totalPaymentDue}</h4>
      </div>
    </>);
}

