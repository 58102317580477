import * as appSelectors from 'app/state/app/app-selectors';
import { WhiteButton } from 'common/navigation-buttons';
import { PortalPageContainer } from 'common/portal/styles';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { PORTAL_PATHS } from 'routes/constants';
import styled from 'styled-components';
import { parseHTMLString } from 'utils/utility-functions';

import { AccountLogin } from './modules/account-login';

const altLandingPageHeaderTextPath = 'landingPage.headerText';
const landingPageBodyTextPath = 'landingPage.bodyText';

const Grid = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 50px;
`;

const TextDiv = styled.div`
  p + p {
    margin: 0;
  }
`;

const WelcomeP = styled.p`
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 15px;
`;

const LandingBodyText = styled.div`
  font-size: 16px;
`;

const CreateAccountButton = styled(WhiteButton).attrs({
  width: '200px',
})`
  margin-top: 15px !important;
`;

export function PortalLogin() {
  const cityInfo = useSelector(appSelectors.cityInfo);

  const { portalType } = useSelector(appSelectors.statsApp);

  const altLandingPageHeaderText = useSelector(
    appSelectors.getFromPortalStats(altLandingPageHeaderTextPath),
  );

  const altLandingPageBodyText = useSelector(
    appSelectors.getFromPortalStats(landingPageBodyTextPath),
  );

  return (
    <PortalPageContainer>
      <Grid>
        <div>
          <TextDiv>
            <WelcomeP>
              {altLandingPageBodyText
                ? parseHTMLString(altLandingPageHeaderText)
                : `Welcome to the ${cityInfo.name} Short-Term Rental ${portalType}`}
            </WelcomeP>
            <LandingBodyText>
              {parseHTMLString(altLandingPageBodyText)}
            </LandingBodyText>
          </TextDiv>

          <Link to={PORTAL_PATHS.createAccount}>
            <CreateAccountButton>Create new account</CreateAccountButton>
          </Link>
        </div>

        <AccountLogin />
      </Grid>
    </PortalPageContainer>
  );
}
