import React, { useMemo } from 'react';
import styled from 'styled-components';
import { InputGroup, InputNumber } from 'rsuite';
import { FormikError } from 'configurable-form/components/inputs/shared-components';
import { useInput } from 'configurable-form/components/utils/use-input';
import { InputContainer } from './styles';
import { useFieldCustomChangeHandler } from "./useFieldCustomChangeHandler";
import { convertToInputNumberFieldValue } from '../tot-payment-periods/fields/shared';
import { useTOTReportingModuleConfigContext } from "../tot-reporting-module-config-context";
import { formatValue } from "../../utils";
import { FORMAT_CURRENCY } from "../../constants";

const DATA_CY_PREFIX_STRING = 'report-revenue-input-taxable-receipt';

const createTaxableReceiptsValidation = ({ lessThanAmount = 1000000 }) => ({
  validationType: 'number',
  rules: [
    { type: 'required', params: ['This field is required.'] },
    { type: 'min', params: [0, 'The minimum value accepted is 0.'] },
    { type: 'lessThan', params: [lessThanAmount, `The maximum value accepted is ${formatValue(lessThanAmount - 0.01, FORMAT_CURRENCY)}`] },
  ],
});

const StyledInputNumber = styled(InputNumber)`
  input {
    margin-left: 25px;
  }
`;

export function TaxableReceiptsInputComponent(props) {
  const { lessThanAmount } = props;
  const validation = useMemo(
    () => createTaxableReceiptsValidation({ lessThanAmount }),
    [lessThanAmount]
  );
  const { field, helpers, meta } = useInput({ name: props.fieldKey, validation });

  const onChange = useFieldCustomChangeHandler({ helpers, getValueFromArgs: convertToInputNumberFieldValue });

  return (
    <InputContainer data-cy={`${DATA_CY_PREFIX_STRING}-${props.fieldKey}`}>
      <InputGroup inside>
        <InputGroup.Addon>
          <img src="/assets/icon-dollar.svg" alt="Dollar sign" />
        </InputGroup.Addon>

        <StyledInputNumber {...field} scrollable={false} onChange={onChange} />
      </InputGroup>
      <FormikError {...meta} touched />
    </InputContainer>
  );
}

export function TaxableReceiptsInput(props) {
  const { currencyFieldsLessThanAmount } = useTOTReportingModuleConfigContext();
  return (
    <TaxableReceiptsInputComponent
      {...props}
      lessThanAmount={currencyFieldsLessThanAmount}
    />
  );
}
