import styled from 'styled-components';

export const Flex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Div = styled.div`
  display: flex;
  flex-direction: row;

  gap: 30px;
  margin-top: 40px;
`;
