import * as dashboardSelectors from 'app/state/dashboard/dashboard-selectors';
import { networkActions } from 'app/state/network/network-slice';
import { renderFieldComponent } from 'configurable-form';
import { DataTable } from 'configurable-form/components/data-table/data-table';
import isString from 'lodash/isString';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

const ProcessingDiv = styled.div`
  margin-top: 40px;
  text-align: right;
  color: red;
`;

export function BulkRenewalRenewProperties(props) {
  const dispatch = useDispatch();

  const bulkLicensesToBeRenewedExpirationDateAsc = useSelector(
    dashboardSelectors.bulkLicensesToBeRenewedExpirationDateAsc,
  );

  const [data, setData] = useState(
    isString(bulkLicensesToBeRenewedExpirationDateAsc)
      ? []
      : bulkLicensesToBeRenewedExpirationDateAsc,
  );

  useEffect(() => {
    dispatch(networkActions.replaceMiscInfo(null));
    return () => {
      dispatch(
        networkActions.replaceMiscInfo({
          bulkRenewal: {
            success: {
              data,
            },
          },
        }),
      );
    };
  }, [data, dispatch]);

  return (
    <div>
      {props.standardFields.map((f, i) =>
        renderFieldComponent(f, `brPropertyRenewalStandardHeaders${i}`),
      )}

      {props?.additionalFieldsBeforeDataTableComponent?.map((f, i) =>
        renderFieldComponent(f, `additionalFieldsBeforeDataTableComponent${i}`),
      )}

      <DataTable
        useCheckboxes={false}
        useFilter={props.useFilter}
        data={data}
        updateData={setData}
        layout={props.layout}
      />

      {props.additionalFieldsAfterDataTableComponent.map((f, i) =>
        renderFieldComponent(f, `additionalFieldsAfterDataTableComponent${i}`),
      )}

      <ProcessingDiv>This may take a moment to process.</ProcessingDiv>
    </div>
  );
}
