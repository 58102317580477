import React from 'react';
import { Tooltip, Whisper } from 'rsuite';
import { InfoRounded } from '@material-ui/icons';

export function TooltipInfoIcon({ placement, text, iconSize, iconMargin }) {
  return (
    <Whisper
      trigger="hover"
      placement={placement}
      speaker={<Tooltip>{text}</Tooltip>}
    >
      <InfoRounded style={{ margin: iconMargin, fontSize: iconSize, cursor: 'pointer' }} />
    </Whisper>
  );
}