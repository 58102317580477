import React, { useMemo } from 'react';
import { InputGroup } from 'rsuite';
import { FormikError } from 'configurable-form/components/inputs/shared-components';
import { useInput } from 'configurable-form/components/utils/use-input';
import {
  convertToInputNumberFieldValue,
  createCurrencyValidation,
  InputContainer,
  REPORT_REVENUE_DATA_CY_PREFIX_STRING,
  StyledInputNumber,
} from './shared';
import { useFieldCustomChangeHandler } from "../../input-fields/useFieldCustomChangeHandler";
import { useTOTReportingModuleConfigContext } from "../../tot-reporting-module-config-context";

export function CurrencyFieldComponent(props) {
  const { lessThanAmount } = props;
  const validation = useMemo(() => createCurrencyValidation({
    lessThanAmount
  }), [lessThanAmount]);
  const { field, helpers, meta } = useInput({ name: props.fieldKey, validation });

  const onChange = useFieldCustomChangeHandler({ helpers, getValueFromArgs: convertToInputNumberFieldValue });

  return (
    <InputContainer data-cy={`${REPORT_REVENUE_DATA_CY_PREFIX_STRING}-${props.fieldKey}`}>
      <InputGroup inside>
        <InputGroup.Addon>
          <img src="/assets/icon-dollar.svg" alt="Dollar sign"/>
        </InputGroup.Addon>

        <StyledInputNumber {...field} scrollable={false} onChange={onChange} min={0} max={lessThanAmount - 0.01} />
      </InputGroup>
      <FormikError {...meta} touched />
    </InputContainer>
  );
}

export function CurrencyField(props) {
  const { currencyFieldsLessThanAmount } = useTOTReportingModuleConfigContext();
  return (
    <CurrencyFieldComponent
      {...props}
      lessThanAmount={currencyFieldsLessThanAmount}
    />
  )
}
