import React, { useCallback, useMemo } from 'react';
import { useFormikContext } from 'formik';
import get from 'lodash/get';
import isNumber from 'lodash/isNumber';
import capitalize from 'lodash/capitalize';
import { ZERO } from 'common/constants';
import { evaluateCondition } from 'configurable-form/configurable-form-utils';
import { BoldText, TextWithDivider } from './shared';
import { parseCustomerConfig } from 'utils/utility-functions';

export function AccordionItem(props) {
  const { values } = useFormikContext();

  const formikValues = useMemo(() => parseCustomerConfig(values), [values]);

  const userAnswer = useMemo(() => {
    if (props.valueFromHOC) return props.valueFromHOC;
    if (Boolean(props.override) && evaluateCondition(formikValues, props.override)) return props.fallback;

    const userInput = get(formikValues, props.dataKey); // Retrieve user's input from Formik state

    if (isNumber(userInput)) return userInput; // Always display numbers

    /**
     * The a map (props.map) is provided, then see if the stringified value of "userInput"
     *  is a valid key and render that text, if not then default back to the "userInput".
     *  If the "userInput" is not a valid value (false-y, maybe this should be changed
     *    to either undefined or null) to be displayed, then display the fallback value
     *    (props.fallback).
     */
    const map = get(formikValues, props.mapKey, props.map);
    return capitalize(get(map, String(userInput), userInput)) || props.fallback;
  }, [props.dataKey, props.fallback, props.map, props.mapKey, props.override, props.valueFromHOC, formikValues]);

  const question = useMemo(
    () => get(formikValues, props.dataKeyQuestion) || props.text,
    [props.dataKeyQuestion, props.text, formikValues]
  );

  // TODO: This block is copied from withShowHideCapability. Refactor this into reusable function.
  const evalParams = useCallback((params) => {
    if (params) {
      return Array.isArray(params[ZERO]) ?
        params.every((param) => evaluateCondition(formikValues, param)) :
        evaluateCondition(formikValues, params);
    } else {
      return Boolean(params);
    }
  }, [formikValues]);

  const shouldHide = useMemo(() => evalParams(props.hideWhen), [evalParams, props.hideWhen]);

  if (shouldHide || (!question && !userAnswer)) return null;

  return (
    <div>
      <BoldText>{question}</BoldText>

      <TextWithDivider>{userAnswer}</TextWithDivider>
    </div>
  );
}
