import * as appSelectors from 'app/state/app/app-selectors';
import * as payloadSelectors from 'app/state/payload/payload-selectors';
import {
  BULK_TOT_MAX_PROPERTIES_THRESHOLD,
  DATE_INPUT_VALUE_FORMAT,
  MAX_VALUE_FOR_TAXABLE_RECEIPTS,
  TODAYS_DATE,
  ZERO,
} from 'common/constants';
import { buildApiPostPayload } from 'configurable-form/configurable-form-utils';
import capitalize from 'lodash/capitalize';
import cloneDeep from 'lodash/cloneDeep';
import every from 'lodash/every';
import isEmpty from 'lodash/isEmpty';
import nth from 'lodash/nth';
import set from 'lodash/set';
import trim from 'lodash/trim';
import words from 'lodash/words';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { dayjs } from 'utils/dayjs';
import {
  FIRST_DAY_IN_MONTH,
  FORMAT_CURRENCY,
  MONTHS_IN_YEAR,
  QUARTERS_IN_YEAR,
} from './constants';

// ------------------------------------------------------
// ------------------ Helper Functions ------------------
// ------------------------------------------------------

export function coalesceNaN(value, defaultValue = 0) {
  return isNaN(value) ? defaultValue : value;
}

export const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  currencyDisplay: 'narrowSymbol',
});

export const formatterFuncByName = {
  [FORMAT_CURRENCY]: (value) => currencyFormatter.format(value),
};

export function formatValue(value, formatterName) {
  const formatter = formatterFuncByName[formatterName];
  return formatter ? formatter(value) : value;
}

export function addVectors(a, b, operations = null) {
  if (!operations) {
    operations = new Array(a.length)
      .fill((a, b) => a + b);
  }
  return a.map((fieldA, i) => {
    const fieldB = b[i];
    const fieldANumericValue = coalesceNaN(parseFloat(fieldA));
    const fieldBNumericValue = coalesceNaN(parseFloat(fieldB));

    return operations[i](fieldANumericValue, fieldBNumericValue);
  });
}

export function generateUpdatedRevenue(taxableItem, name, value, maxValue = MAX_VALUE_FOR_TAXABLE_RECEIPTS) {
  let setterValue = value;
  if (setterValue >= maxValue) setterValue = String(maxValue);

  //updates the taxable item object, changing the name key's value to setterValue
  const updatedTaxableItem = set(cloneDeep(taxableItem), name, setterValue);
  return updatedTaxableItem;
}

export function getPeriodIndexFromString(string, isQuarterlyPeriods) {
  if (isQuarterlyPeriods) {
    const map = { 'Jan-Mar': 0, 'Apr-Jun': 1, 'Jul-Sep': 2, 'Oct-Dec': 3 };
    return map?.[string];
  }

  // Zero-indexed month
  return dayjs(`${string} ${FIRST_DAY_IN_MONTH}`).month();
}

// ------------------------------------------------------
// ------------- Internal Helper Functions --------------
// ------------------------------------------------------

function formatDate(date) {
  return dayjs(date).format(DATE_INPUT_VALUE_FORMAT);
}

export function calcDaysInPeriod(startingDate, endingDate) {
  return dayjs(endingDate).diff(dayjs(startingDate), 'day');
}

export function getPreviousPeriod(isQuarterlyPeriods = false, currentDate) {
  const date = currentDate || dayjs();

  const subtrahend = isQuarterlyPeriods ? 3 : 1;
  const previousPeriod = date.subtract(subtrahend, 'month').startOf('month');

  return previousPeriod.toDate();
}

export function getFirstDateOfPeriod(date, isQuarterlyPeriods) {
  const parsedDate = dayjs(date);

  const periodCoefficient = isQuarterlyPeriods ? 3 : 1;
  const zeroIndexPeriod = Math.floor(parsedDate.month() / periodCoefficient) * periodCoefficient;

  const firstDateOfPeriod = new Date(parsedDate.year(), zeroIndexPeriod, 1);
  return dayjs(firstDateOfPeriod);
}

export function getLastDateOfPeriod(date, isQuarterlyPeriods) {
  const periodAdditionValue = isQuarterlyPeriods ? 2 : 0;
  const firstDateOfPeriod = getFirstDateOfPeriod(date, isQuarterlyPeriods);
  const lastDateOfPeriod = firstDateOfPeriod
    .add(periodAdditionValue, 'month') // Change the month to the last month in the quarter
    .endOf('month'); // Change the date to the end of the month

  return lastDateOfPeriod;
}

function findPeriodIndex(date, isQuarterlyPeriods) {
  /**
   * Index of the payment period (either a month or a quarter):
   *
   * Month:
   *  [Jan, Feb, ...]
   *  Jan = 0, Feb = 1, ...
   *
   * Quarter:
   *  [Jan-Mar, Apr-Jun, ...]
   *  Jan-Mar = 0, Apr-Jun = 1
   */
  const divisor = isQuarterlyPeriods ? 3 : 1;
  return Math.floor(date.getMonth() / divisor);
}

// ------------------------------------------------------
// ------------------------ Other -----------------------
// ------------------------------------------------------

/**
 * @typedef {Object} TaxableItem
 */
/**
 * Replaces every taxable period object in taxableItems with multiple objects per
 * calendar period, each containing a different set of additional fields (e.g.
 * a "provider" field that could be set to "airbnb" or "vrbo" depending on the platform
 * where the owner earned rental income)
 * @param {TaxableItem[]} taxableItems Taxable item objects, one for each calendar period
 * @param {object[]} extraParamSets "sets" of extra fields to apply. Each of those "sets" is an object
 * whose key-value pairs will be merged into a copy of every item from taxableItems
 * @returns {TaxableItem[]} An array of taxable item objects. For every calendar period
 * the returned array will now have multiple objects, one object for each "set" of extra fields from
 * extraParamSets
 */
export function expandTaxableItemsWithExtraParams(taxableItems, extraParamSets) {
  if (!Array.isArray(extraParamSets) || !extraParamSets?.length) return taxableItems;

  return taxableItems.flatMap((calendarPeriodItem) => extraParamSets.map((paramSet) => ({ ...calendarPeriodItem, ...paramSet })));
}

/**
 * Returns a string that could be used to uniquely identify a taxable item, as there may be
 * multiple taxable items with different extra params for the same calendar period/title
 * @param {TaxableItem} taxableItem
 * @param {string[]} sortedTaxableItemExtraParamFieldNames Ordered names of all extra param fields that may be used in taxable items.
 * @returns {string|*}
 */
export function getTaxableItemUniqueKey(taxableItem, sortedTaxableItemExtraParamFieldNames) {
  if (!Array.isArray(sortedTaxableItemExtraParamFieldNames) || !sortedTaxableItemExtraParamFieldNames?.length)
    return taxableItem.title;

  const canonicallyOrderedExtraParamKeyValuePairs = sortedTaxableItemExtraParamFieldNames
    .map(paramKey => `${paramKey}:${taxableItem[paramKey]}`)
    .join(',');

  return `${taxableItem.title},${canonicallyOrderedExtraParamKeyValuePairs}`;
}

function allExtraParamsMatch(item, extraParams) {
  return every(Object.keys(extraParams), (extraParamKey) => {
    return item?.taxableItem?.[extraParamKey] === extraParams[extraParamKey]
  });
}

/**
 * Groups taxable items by calendar period
 * @param {TaxableItem[]} taxableItems Taxable items - must already be sorted by calendar period (`title` field)
 * @param {object[]} extraParamSets "sets" of extra params applied to taxable items with expandTaxableItemsWithExtraParams
 * @returns {TaxableItem[][]} A list of groups of taxable items, each group containing taxable items of the same calendar period/title,
 * in the same order as their additional params are specified in extraParamSets. The groups themselves are sorted by calendar period.
 */
export function groupTaxableItemsByPeriod(taxableItems, extraParamSets) {
  if (!taxableItems || !Array.isArray(extraParamSets) || !extraParamSets?.length)
    return taxableItems.map((taxableItem,i) => ([{ taxableItem, taxableItemIndex: i }]));

  const groups = [];

  let lastPeriodTitle = null;

  for (let i = 0; i < taxableItems.length; i++) {
    const taxableItem = taxableItems[i];
    const currentPeriodTitle = taxableItem.title;
    if (lastPeriodTitle !== currentPeriodTitle) {
      groups.push([]);
    }

    const currentGroupIndex = groups.length - 1;
    groups[currentGroupIndex].push({ taxableItem, taxableItemIndex: i });
    lastPeriodTitle = currentPeriodTitle;
  }

  for (let i = 0; i < groups.length; i++) {
    const currentGroup = groups[i];

    const sortedGroup = extraParamSets.map((paramSet) => currentGroup.find((item) => allExtraParamsMatch(item, paramSet)));

    groups[i] = sortedGroup;
  }

  return groups;
}

/**
 * Pulls just the street address from the unformatted property address from data received from API
 * @param {string} propertyAddress Unformatted property address pulled from data received from API
 * @returns Formatted street address
 */
export function derivePropertyAddress(propertyAddress) {
  if (!propertyAddress) return propertyAddress;
  const [streetAddress] = propertyAddress.split(',').map(trim);

  const formattedStreetAddress = words(streetAddress).map(capitalize).join('  ');
  return formattedStreetAddress;
}

// ------------------------------------------------------
// -------------- Payment Period Functions --------------
// ------------------------------------------------------

export function getPaymentPeriod({
  isQuarterlyPeriods = false,
  year,
  periodIndex,
  displayLabel,
}) {
  // This is the first reportable period
  const dateOfPreviousPeriod = getPreviousPeriod(isQuarterlyPeriods);
  const yearOfPreviousPeriod = dateOfPreviousPeriod.getFullYear();

  // Year of the payment period being calculated
  const calcYear = isNaN(year) ? yearOfPreviousPeriod : year;

  const calcPeriodIndex = isNaN(periodIndex)
    ? findPeriodIndex(dateOfPreviousPeriod, isQuarterlyPeriods)
    : periodIndex;

  const labelsList = isQuarterlyPeriods ? QUARTERS_IN_YEAR : MONTHS_IN_YEAR;
  const displayLabelForPeriod = displayLabel || `${nth(labelsList, calcPeriodIndex)} ${calcYear}`;

  const calcMonth = (isQuarterlyPeriods ? calcPeriodIndex * 3 : calcPeriodIndex) + 1;
  const calcDate = dayjs(`${calcYear}-${calcMonth}-${FIRST_DAY_IN_MONTH}`);

  const dateStart = getFirstDateOfPeriod(new Date(calcDate), isQuarterlyPeriods);

  const dateEnd = getLastDateOfPeriod(new Date(calcDate), isQuarterlyPeriods);

  const daysInPeriod = calcDaysInPeriod(dateStart, dateEnd);

  return {
    title: displayLabelForPeriod,
    dateStart: formatDate(dateStart),
    dateEnd: formatDate(dateEnd),
    daysInPeriod: daysInPeriod,
    sort: calcDate.unix()
  };
}

function generateTaxableActivities({ periodDateStart, periodDateEnd, rowParams }) {
  const baseSpread = {
    dateStart: periodDateStart,
    dateEnd: periodDateEnd,
  };

  return isEmpty(rowParams) ? [{ ...baseSpread }] : rowParams.map((param) => ({ ...baseSpread, ...param }));
}

export function generateTaxablePeriods({
  totalReportableYears,
  isDynamicQuarter=false,
  isQuarterlyPeriods,
  rowParams,
  enableReportingUnfinishedCurrentPeriod,
  // disableReportingUnfinishedFirstPeriod,//needed for monthly breakdown of quarters
  // in dynamic payment periods
  allowOmittingLatestPeriod,
  maxTotEndDate,
}) {
  const taxablePeriods = [];

  // This can be any date within the previous reporting period
  const previousReportablePeriodDate = getPreviousPeriod(isQuarterlyPeriods);
  const earliestReportablePeriodDate = dayjs(previousReportablePeriodDate).subtract(totalReportableYears, 'year');
  let currentReportablePeriod = getFirstDateOfPeriod(earliestReportablePeriodDate, isQuarterlyPeriods);

  // The current reportable period, the first reportable period which is always displayed to the user
  const lastReportablePeriodReferenceDate = enableReportingUnfinishedCurrentPeriod
    ? TODAYS_DATE
    : getPreviousPeriod(isQuarterlyPeriods);

  let lastReportablePeriod = getFirstDateOfPeriod(lastReportablePeriodReferenceDate, isQuarterlyPeriods);
  if (maxTotEndDate) {
    const maxTotEndDayJSDate = dayjs(maxTotEndDate, DATE_INPUT_VALUE_FORMAT);
    const maxConfiguredReportablePeriod = getFirstDateOfPeriod(
      getPreviousPeriod(isQuarterlyPeriods, maxTotEndDayJSDate),
      isQuarterlyPeriods
    )

    if (lastReportablePeriod.diff(maxConfiguredReportablePeriod) > 0) {
      lastReportablePeriod = maxConfiguredReportablePeriod
    }
  }

  const dateCoefficient = isQuarterlyPeriods ? 3 : 1;

  // Generating taxable periods from the earliest to current reportable period
  while (currentReportablePeriod <= lastReportablePeriod) {
    const periodSliceIndex = Math.floor(currentReportablePeriod.month() / dateCoefficient);

    // Only true when the dates are EXACTLY the same as the diff comes out to be 0
    const isCurrentPeriodSameAsLast = !currentReportablePeriod.diff(lastReportablePeriod);


    const dateStart = currentReportablePeriod.format(DATE_INPUT_VALUE_FORMAT);
    const dateEnd = getLastDateOfPeriod(currentReportablePeriod, isQuarterlyPeriods).format(DATE_INPUT_VALUE_FORMAT);

    taxablePeriods.push({
      // Page configurations
      disabled: isCurrentPeriodSameAsLast && !allowOmittingLatestPeriod, // Prevents certain checkboxes in "additional quarters" modal from changing states
      selected: isCurrentPeriodSameAsLast, // All additional reportable periods should not be selected by default
      // The id should not be something that could be turned into a number
      // (i.e. it should not be a number or a string representation of a number).
      // Keeping the id numeric causes a crash - one possible explanation could
      // be that when we tell Formik to index into the dictionary of periods,
      // it determines that id is a number, assumes that the dictionary is actually an
      // array, and somehow causes the browser to allocate a massive amount of memory
      // to get an array of ~1.6e9 entries to get to index like 1667275200.
      id: currentReportablePeriod.unix().toString() + "_" + currentReportablePeriod.format("MMMYYYY"),

      // This should be used for any logic that compares periods by time, instead
      // of assuming the id is a unix timestamp
      dateStartTimestamp: currentReportablePeriod.unix(),

      periodIndexInYear: periodSliceIndex,
      isQuarterlyPeriods,

      dateStart,
      dateEnd,

      misc: {
        taxableActivities: generateTaxableActivities({ periodDateStart: dateStart, periodDateEnd: dateEnd, rowParams })
      }
    });

    currentReportablePeriod = currentReportablePeriod.add(dateCoefficient, 'month');
  }

  if(isDynamicQuarter && !isQuarterlyPeriods){
    // trim partial quarters
    return trimMonthsToWholeQuarters({ taxablePeriods })
  }
  return taxablePeriods;
}

export function generateTaxablePeriodsWithEarliestReportableDate({ earliestReportingPeriod, isQuarterlyPeriods }) {
  const taxablePeriods = {};
  const selectedPeriods = {};

  const firstReportablePeriodDate = getPreviousPeriod(isQuarterlyPeriods);

  const subtrahend = isQuarterlyPeriods ? 3 : 1;

  const paymentPeriodsInYear = isQuarterlyPeriods ? QUARTERS_IN_YEAR : MONTHS_IN_YEAR;

  const dateOfEarliestReportingPeriod = dayjs(earliestReportingPeriod);

  let currentDate = dayjs(firstReportablePeriodDate).subtract(subtrahend, 'month');

  while (currentDate >= dateOfEarliestReportingPeriod) {
    const iterativeYear = currentDate.year();
    const periodIndex = findPeriodIndex(new Date(currentDate), isQuarterlyPeriods);

    const paymentPeriod = paymentPeriodsInYear[periodIndex];

    set(selectedPeriods, `${paymentPeriod} ${iterativeYear}`, false);
    taxablePeriods[iterativeYear] = [paymentPeriod, ...(taxablePeriods[iterativeYear] || [])];
    currentDate = currentDate.subtract(subtrahend, 'month');
  }

  return { taxablePeriods, selectedPeriods };
}

// ------------------------------------------------------
// ----------------- Payload Generation -----------------
// ------------------------------------------------------

function toAPITaxableActivity({
  taxableActivity,
  taxableActivityExtraFields,
  extraNumericColumns = [],
  nullifyDaysAvailable = false,
  nullifyDaysOccupied = false,
}) {
  const apiTaxableActivity = {
    dateStart: taxableActivity.dateStart,
    dateEnd: taxableActivity.dateEnd,
    ...Object.assign({}, ...(extraNumericColumns.map(field => ( { [field]: parseFloat(taxableActivity[field] || 0) })))),
    taxableReceipts: taxableActivity.taxableReceipts ? parseFloat(taxableActivity.taxableReceipts) : ZERO,
    ...(taxableActivityExtraFields.reduce((acc, additionalField) => (
      { ...acc, [additionalField]: taxableActivity?.[additionalField] }
    ), {}))
  }

  if(!nullifyDaysOccupied){
    apiTaxableActivity.numDaysOccupied = Number(taxableActivity.numDaysOccupied)
  }

  if(!nullifyDaysAvailable){
    apiTaxableActivity.numDaysAvailable = Number(taxableActivity.numDaysAvailable)
  }

  return apiTaxableActivity
}

function toAPIPeriod({
  period,
  licenseReport,
  taxableActivityExtraFields,
  nullifyDaysOccupied,
  nullifyDaysAvailable,
  extraNumericColumns,
}) {
  const { dateStart, dateEnd } = period;
  let apiPeriod = {
    dateStart,
    dateEnd,
    license: licenseReport.licenseId,
  }


  const apiTaxableActivities = licenseReport.taxableActivities
    .map(taxableActivity => toAPITaxableActivity(
      {
        taxableActivity,
        taxableActivityExtraFields,
        nullifyDaysOccupied,
        nullifyDaysAvailable,
        extraNumericColumns,
      }));

  if (apiTaxableActivities.length === 1 && !taxableActivityExtraFields.length) {
    apiPeriod = {
      ...apiPeriod,
      ...apiTaxableActivities[0],
    }
  } else {
    apiPeriod = {
      ...apiPeriod,
      misc: {
        taxableActivities: apiTaxableActivities
      }
    }
  }

  return apiPeriod;
}

function getAllTaxableItemExtraFieldNames({ rowParamSets = [] } = {}) {
  const flatMappedParams = rowParamSets.flatMap((rowParamSet) => Object.keys(rowParamSet));
  const flatMappedParamSets = new Set(flatMappedParams);
  const taxableItemExtraParamFields = Array.from(flatMappedParamSets).sort();

  return taxableItemExtraParamFields;
}

/**
 * Generates the expected payload for the /calculateTOT endpoint
 * @param {[]} paymentDetails All reporting periods
 * @param {{}} taxableItemExtraParams Entire config in "reporting" key of the render config
 * @param {boolean} nullifyDaysAvailable boolean to determine if this value should be a number or null
 * @param {boolean} nullifyDaysOccupied boolean to determine if this value should be a number or null
 * @returns the payload expected by the server for /calculateTot endpoint
 */
export function constructCalculateTOTPayload({
  paymentDetails,
  taxableItemExtraParams,
  nullifyDaysAvailable,
  nullifyDaysOccupied,
  misc,
  dataTransform,
}) {
  const selectedLicenseReportsWithPeriods = paymentDetails.flatMap(
    (period) => period.licenseReports
      .filter((el) => el.selected && el.taxableActivities.length)
      .map((licenseReport) => ({ period, licenseReport }))
  );
  const taxableActivityExtraFields = getAllTaxableItemExtraFieldNames(taxableItemExtraParams);
  const extraNumericColumns = taxableItemExtraParams?.columns
    ?.map(c => c.field).filter(f => f !== 'taxableReceipts') || [];

  const apiPeriods = selectedLicenseReportsWithPeriods.map(({ period, licenseReport }) => toAPIPeriod({
    period,
    licenseReport,
    taxableActivityExtraFields,
    nullifyDaysOccupied,
    nullifyDaysAvailable,
    extraNumericColumns,
  }));

  const miscPayload = isEmpty(misc) ? {} : buildApiPostPayload(misc, dataTransform);

  return { paymentPeriods: apiPeriods, ...miscPayload };
}

export function getPeriodFormikPath({ periodId }) {
  return `taxablePeriods['${periodId}']`;
}

export function getLicenseReportsFormikPath({ periodPath }) {
  return extendFormikPath(periodPath, 'licenseReports');
}

export function getLicenseReportFormikPath({ periodPath, licenseReportIdx }) {
  return extendFormikPath(
    getLicenseReportsFormikPath({ periodPath }),
    licenseReportIdx
  );
}

export function getTaxableActivityFormikPath({ periodPath, licenseReportPath, licenseReportIdx, taxableActivityIdx }) {
  const licenseReportFullPath = licenseReportPath || getLicenseReportFormikPath({ periodPath, licenseReportIdx });
  const taxableActivitiesPath = getTaxableActivitiesPathFromLicenseReportPath({ licenseReportPath: licenseReportFullPath });

  return `${taxableActivitiesPath}[${taxableActivityIdx}]`;
}

export function getTaxableActivitiesPathFromLicenseReportPath({ licenseReportPath }) {
  return `${licenseReportPath}['taxableActivities']`;
}

function quote(s) {
  return `'${s}'`;
}

export function extendFormikPath(path, fieldNameOrIdx) {
  if (Array.isArray(fieldNameOrIdx)) {
    return fieldNameOrIdx.map(i => extendFormikPath(path, i));
  }
  return `${path}[${typeof fieldNameOrIdx === 'number' ? fieldNameOrIdx : quote(fieldNameOrIdx)}]`;
}

export function isCoreColumn(col) {
  return Boolean(col.isCoreColumn);
}

export function getShortPeriodDisplayName({ periodIndexInYear, isQuarterlyPeriods }) {
  const paymentPeriodsInYear = isQuarterlyPeriods ? QUARTERS_IN_YEAR : MONTHS_IN_YEAR;

  return paymentPeriodsInYear[periodIndexInYear];
}

export function getPeriodDisplayName(period) {
  const { dateStart } = period;
  const year = dayjs(dateStart).year();

  return `${getShortPeriodDisplayName(period)} ${year}`;
}

export function initializePeriodFromAvailablePeriodStub(
  availablePeriod,
  licensesInReport,
  { selectedLicenseIds = null } = {},
  isDynamicQuarter,
) {
  const newPeriod = cloneDeep(availablePeriod);

  const taxableActivities = newPeriod.misc.taxableActivities;

  delete newPeriod.misc.taxableActivities;
  delete newPeriod.selected

  if (!Object.keys(newPeriod.misc).length) delete newPeriod.misc;

  const selectedLicenseIdsSet = !selectedLicenseIds?.length ? null : new Set(selectedLicenseIds);

  return {
    ...newPeriod,
    licenseReports: licensesInReport.map(({ licenseId }) => ({
      taxableActivities,
      licenseId,
      selected: !selectedLicenseIdsSet || selectedLicenseIdsSet.has(licenseId)
    }))
  };
}

export function licensePeriodTypeFilter({
  disablePeriodTypeFilter,
  dynamicQuarterSelectedPeriodType,
}) {
  return (license) =>
    disablePeriodTypeFilter ||
    !license?.totPaymentPeriod ||
    !dynamicQuarterSelectedPeriodType ||
    license?.totPaymentPeriod === dynamicQuarterSelectedPeriodType;
};

export function useLicensesInReport({ disablePeriodTypeFilter = false } = {}) {
  const singleLicense = useSelector(payloadSelectors.license);
  const allLicenses = useSelector(payloadSelectors.licenses);
  const dynamicQuarterSelectedPeriodType = useSelector(appSelectors.dynamicQuarterSelectedPeriodType);


  const isMultiPropertyTOTEnabled = useSelector(appSelectors.isMultiPropertyTOTEnabled);

  const licensesInReport = useMemo(() => {
    return isMultiPropertyTOTEnabled ? allLicenses : [singleLicense];
  },
    [isMultiPropertyTOTEnabled, allLicenses, singleLicense])

  function licenseComparisonKey(license) {
    return license.license || license.applicationNumber;
  }

  return useMemo(() => licensesInReport.filter(Boolean).filter(license => Boolean(license.licenseId))
      .filter(licensePeriodTypeFilter({ disablePeriodTypeFilter, dynamicQuarterSelectedPeriodType }))
      .sort((l1, l2) => licenseComparisonKey(l1).localeCompare(licenseComparisonKey(l2))),
    // TODO: sort by license number!
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [disablePeriodTypeFilter, (disablePeriodTypeFilter || dynamicQuarterSelectedPeriodType), licensesInReport]);
}

export function formatProperty(license) {
  if (!license) return  "Unknown Property";
  return `${license?.license || license?.applicationNumber} - ${license?.propertyAddress?.split(",")[0]}`;
}

export function useLicensesInReportMap() {
  const licensesInReport = useLicensesInReport();
  return useMemo(() => licensesInReport.reduce((acc, x) => ({
    ...acc,
    [x.licenseId]: x
  }), {}), [licensesInReport]);
}

export function useBulkTOTEnabled() {
  const isBulkTOTUploadEnabled = useSelector(appSelectors.isBulkTOTUploadEnabled);
  const licenses = useLicensesInReport();
  return isBulkTOTUploadEnabled && ((licenses.length > BULK_TOT_MAX_PROPERTIES_THRESHOLD) || window.__DECKARD_FORCE_ENABLE_BULK_TOT_UPLOAD);
}

export function getUnpaidPeriods(licenseIssueDate, totPayments, availablePeriods, isQuarterlyPeriods) {
  if (!licenseIssueDate || !totPayments) return [];
  const earliestPayablePeriodStartDateTimestamp = getFirstDateOfPeriod(licenseIssueDate, isQuarterlyPeriods).unix();
  const paidPeriodStartDates = new Set(totPayments.map(({ startDate }) => startDate));

  return availablePeriods
    .filter(p => p.dateStartTimestamp >= earliestPayablePeriodStartDateTimestamp
      && !paidPeriodStartDates.has(p.dateStart))
}


function trimMonthsToWholeQuarters({ taxablePeriods }) {
  const MONTHS_IN_QUARTER = 3;
  const lastPeriod = taxablePeriods[taxablePeriods.length - 1];
  const monthsToDeleteEnd = (dayjs(lastPeriod.dateStart, DATE_INPUT_VALUE_FORMAT).month() + 1) % MONTHS_IN_QUARTER;

  const startingMonth = dayjs(taxablePeriods[0].dateStart, DATE_INPUT_VALUE_FORMAT).month();
  const startingMonthIndexInQuarter = startingMonth % MONTHS_IN_QUARTER;
  const monthsToDeleteStart = startingMonthIndexInQuarter === 0 ? 0 : (MONTHS_IN_QUARTER - startingMonthIndexInQuarter);

  return taxablePeriods.slice(
    monthsToDeleteStart,
    taxablePeriods.length - monthsToDeleteEnd,
  ).map((period, index) => ({ ...period, path: index }));
}

export function getLicenseReportPathsFromPeriods(periods, licenseId) {
  return periods.flatMap(period => period.licenseReports.map((licenseReport, idx) => ({ licenseReport, idx }))
    .filter(({ licenseReport }) => licenseReport.licenseId === licenseId)
    .map(({ idx: licenseReportIdx }) => getLicenseReportFormikPath({
      periodPath: getPeriodFormikPath({ periodId: period.id }),
      licenseReportIdx
    })));
}

export function getAllSelectedLicensesOnReportPage({ taxablePeriods }) {
  return new Set(Object.values(taxablePeriods)
    .flatMap(({ licenseReports }) => licenseReports
      .filter(({ selected }) => selected)
      .map(({ licenseId }) => licenseId)));
}

export function toggleSelectProperty ({ ignoreUnpaidPeriods, licenseIds, forceAllSelections, values, setFieldValue, unpaidPeriodsByLicenseId, availablePeriodsById, licensesInReport }) {
  const licenseReportPathsToChange = [];

  const { taxablePeriods } = values;
  const allSelectedLicenseIds = getAllSelectedLicensesOnReportPage({ taxablePeriods });

  for (const licenseId of licenseIds) {
    const shouldSelect = forceAllSelections !== undefined ? forceAllSelections : !allSelectedLicenseIds.has(licenseId);
    const unpaidPeriods = unpaidPeriodsByLicenseId[licenseId];

    if (shouldSelect) {
      let periodsToToggle;
      if (!ignoreUnpaidPeriods) {
        const unpaidPeriodsInValues = unpaidPeriods.map(({ id }) => ({ id, period: values.taxablePeriods[id] }))

        const unaddedUnpaidPeriodIds = unpaidPeriodsInValues.filter(({ period }) => !period).map(({ id }) => id);

        unaddedUnpaidPeriodIds
          .map(id => initializePeriodFromAvailablePeriodStub(availablePeriodsById[id], licensesInReport, { selectedLicenseIds: [licenseId] }))
          .forEach(period => {
            setFieldValue(getPeriodFormikPath({ periodId: period.id }), period, false);
          });

        const addedUnpaidPeriods = unpaidPeriodsInValues.filter(({ period }) => Boolean(period)).map(({ period }) => period);
        periodsToToggle = addedUnpaidPeriods;
      } else {
        periodsToToggle = Object.values(values.taxablePeriods)
      }

      licenseReportPathsToChange.push(
        ...getLicenseReportPathsFromPeriods(periodsToToggle, licenseId).map((path) => ({ path, value: shouldSelect }))
      )
    } else {
      const allPeriodsInValues = Object.values(values.taxablePeriods);
      licenseReportPathsToChange.push(
        ...getLicenseReportPathsFromPeriods(allPeriodsInValues, licenseId).map((path) => ({ path, value: shouldSelect }))
      )
    }
  }

  licenseReportPathsToChange.forEach(({ path, value }) => {
    setFieldValue(
      extendFormikPath(path, 'selected'), value, false
    )
  });
}


