import { DASHBOARD_SUCCESS_STATUS } from 'app/state/dashboard/constants';
import * as dashboardSelectors from 'app/state/dashboard/dashboard-selectors';
import { dashboardActions } from 'app/state/dashboard/dashboard-slice';
import { WhiteButton } from 'common/navigation-buttons';
import dayjs from 'dayjs';
import sortBy from 'lodash/sortBy';
import {
  DASHBOARD_BULK_RENEWAL_ACTION,
  DASHBOARD_SINGLE_RENEWAL_ACTION,
  DASHBOARD_TAX_REMITTANCE_ACTION,
} from 'pages/portal/dashboard/constants';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FLOW_PATHS } from 'routes/constants';
import { history } from 'routes/history';
import { Alert, Popover, Whisper } from 'rsuite';
import { CustomerPortalStrategy } from 'strategies/customer-portal/customer-portal-strategy';
import { SingleRenewalFlowEntryError } from 'strategies/customer-portal/errors';
import styled from 'styled-components';
import { captureExceptionWithContext } from 'utils/sentry-functions';

const Div = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
}
`;

const Flex = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const LeftPaddedDiv = styled.div`
  padding-left: ${(props) => props.padding};
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 4fr 1fr 1fr;
  gap: 15px;
  align-items: center;
  height: 45px;
`;

const GridWithInvisibleScroll = styled(Grid)`
  overflow-y: scroll;

  ::-webkit-scrollbar-track {
    background: #ffffff;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Button = styled(WhiteButton)`
  &&& {
    width: 125px;
    background-color: white;
  }
`;

function Speaker(props) {
  return (
    <Popover {...props}>This is the earliest expiration of the set.</Popover>
  );
}

export function Action(props) {
  const { dueDate, type } = props;

  const dispatch = useDispatch();

  const { status: dataFetchStatus } = useSelector(
    dashboardSelectors.bulkRenewableLicenses,
  );

  const activePermitData = useSelector(
    dashboardSelectors.activePermitByApplicationNumber(props.applicationNumber),
  );

  const onClick = useCallback(async () => {
    if (type === DASHBOARD_BULK_RENEWAL_ACTION)
      history.push(FLOW_PATHS.bulkRenewal);
    if (type === DASHBOARD_SINGLE_RENEWAL_ACTION) {
      try {
        await CustomerPortalStrategy.getInstance().initiateSingleRenewalFlowEntry(
          {
            setLoading: () => { },
            applicationNumber: props.applicationNumber,
            permitData: activePermitData,
          },
        );
      } catch (err) {
        Alert.error(
          'Failed to initiate the renewal process. Please try again later.',
          10000,
        );

        captureExceptionWithContext(new SingleRenewalFlowEntryError(err), {
          applicationNumber: props.applicationNumber,
        });
      }
    } else if (type === DASHBOARD_TAX_REMITTANCE_ACTION) {
      dispatch(
        dashboardActions.setPreSelectedTaxableLicensePeriod({
          startDate: props.startDate,
          endDate: props.endDate,
        }),
      );
      history.push(`${FLOW_PATHS.payTot}`);
    }
  }, [type, props, activePermitData, dispatch]);

  return (
    <Grid>
      <LeftPaddedDiv padding="25px">{props.label}</LeftPaddedDiv>

      <Flex>
        <div>{dayjs.unix(dueDate).format('MM/DD/YYYY')}</div>
        {type === DASHBOARD_BULK_RENEWAL_ACTION && (
          <Whisper placement="top" trigger="hover" speaker={<Speaker />}>
            <img
              src={`/assets/icon-info.svg`}
              alt={dayjs.unix(dueDate).format('MM/DD/YYYY')}
            />
          </Whisper>
        )}
      </Flex>

      {dataFetchStatus === DASHBOARD_SUCCESS_STATUS && (
        <ButtonContainer>
          <Button onClick={onClick}>
            {type === DASHBOARD_BULK_RENEWAL_ACTION && 'Bulk renewal'}
            {type === DASHBOARD_SINGLE_RENEWAL_ACTION && 'Renew'}
            {type === DASHBOARD_TAX_REMITTANCE_ACTION && `Pay ${props.taxVerbiage || 'Tax'}`}
          </Button>
        </ButtonContainer>
      )}
    </Grid>
  );
}

const ActionsContainer = styled.div`
  height: 100%;
  overflow-y: scroll;

  > div :nth-child(odd) {
    background: #f2f2f2;
  }
`;

export function ActionableItemsList(props) {
  const sortedByDueDateActions = useMemo(
    () => sortBy(props.data, ({ dueDate }) => dayjs(dueDate).unix()),
    [props.data],
  );

  return (
    <Div>
      <GridWithInvisibleScroll>
        <LeftPaddedDiv padding="15px">Action</LeftPaddedDiv>
        <div>Due by</div>
      </GridWithInvisibleScroll>

      <ActionsContainer>
        {sortedByDueDateActions.map((action, i) => (
          <Action key={i} {...action} />
        ))}
      </ActionsContainer>
    </Div>
  );
}
