import styled from 'styled-components';

export const InputContainer = styled.div`
  max-width: 400px;
  width: 100%;
`;

/**
 * This is a quick workaround that allows us to let CSS
 * automatically determine the number of columns in CSS grids
 * used by the reporting components (e.g. header, inputs and total row)
 * while still allowing us to hide "days available" and "days occupied"
 * inputs if specified by the config.
 *
 * Keeping a <div /> (or any kind of element) instead of returning a `null`
 * from the input components' render functions would make CSS calculate the
 * appropriate number of columns in the input grid, so that the inputs align
 * with the headers from the header grid.
 *
 * To fix this, we just need to hide the "days available" and "days occupied"
 * headers when their respective inputs are hidden by the config.
 */
export const HiddenFieldSpacer = 'div';
