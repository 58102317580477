import React from 'react';
import { useField } from 'formik';
import styled from 'styled-components';
import { FormikError, ReverseArgumentInput } from './inputs/shared-components';
import { textAreaTestId } from 'test/constants';
import { withShowHideCapability } from './inputs/with-show-hide-capability';

const StyledTextArea = styled(ReverseArgumentInput)`
  resize: 'vertical';
`;

/**
 * Sample Config Keys:
 *
 *  component: TextArea
 *    Required - string
 *    The name of the component that will be used to render
 *
 *  id:
 *    Required - string
 *    String for the React prop "key"
 *
 *  name:
 *    Required - string
 *    Formik uses this as the key to store the values with in a dictionary
 *      Can use lodash-like dot path: https://formik.org/docs/api/field#name
 *
 *  label:
 *    Optional - string
 *    Label for the input field component
 *
 *  subtext:
 *    Optional - string
 *    Text that is displayed below the input field component
 *
 *  placeholder:
 *    Optional - string
 *    Placeholder text displayed within the input field component
 *
 *  validation:
 *    Optional - dictionary
 *    Yup validation scheme
 *
 *  isShownOrHidden:
 *    Optional - dictionary
 *    Determines when a component is shown or hidden based on Formik values
 *
 *    hideWhen:
 *      When all conditionals are true, this component will be hidden
 *      Refer to comments for evalParams function within "shared-components"
 *
 *    showWhen:
 *      When all conditionals are true, this component will be shown
 *      Refer to comments for evalParams function within "shared-components"
 */
function TextAreaComponent(props) {
  const [field, meta] = useField(props);

  return (
    <>
      <StyledTextArea
        componentClass='textarea'
        rows={12}
        {...field}
        width='100%'
        data-testid={textAreaTestId}
        data-cy={props.dataCy}
      />

      <FormikError {...meta} value={field.value} />
    </>
  )
}

export const TextArea = withShowHideCapability(TextAreaComponent);
