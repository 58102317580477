import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import pick from 'lodash/pick';
import omit from 'lodash/omit'
import { Button, Icon } from 'semantic-ui-react';
import * as appSelectors from 'app/state/app/app-selectors';
import { errorActions } from 'app/state/error/error-slice';
import { networkActions } from 'app/state/network/network-slice';
import * as payTOTSelectors from 'app/state/pay-tot/pay-tot-selectors';
import { payTotActions } from 'app/state/pay-tot/pay-tot-slice';
// import * as payloadSelectors from 'app/state/payload/payload-selectors';
import * as cs from 'common/common-styles';
import { BackButton, ContinueButton } from 'common/navigation-buttons';
import { VerticalStepper } from 'common/stepper/vertical-stepper';
import { Debug } from 'configurable-form/components/debugging/debug';
import { PayTotStrategy } from 'strategies/pay-tot-strategy';
import { authSender } from 'utils/auth-sender';
import { captureExceptionWithContext } from 'utils/sentry-functions';
import { addObjects, mapObject } from 'utils/utility-functions';
import {
  DEFAULT_HELPER_TEXT,
  DEFAULT_TOTAL_YEARS_TO_REPORT,
  ORDER_DESC,
} from './constants';
import { HelperComponents } from './modules/helper-components';
import { ReportAdditionalRevenue } from './modules/report-additional-revenue';
import { ReportTOT } from './modules/report-tot';
import {
  constructCalculateTOTPayload,
  generateTaxablePeriods,
  getPeriodFormikPath,
  getUnpaidPeriods,
  initializePeriodFromAvailablePeriodStub, licensePeriodTypeFilter, useBulkTOTEnabled,
  useLicensesInReport,
} from './utils';
import { PropertySelection } from "./modules/property-selection";
import { renderFieldComponent } from 'configurable-form';
import { typePaymentPeriod } from './constants';
import { dayjs } from "utils/dayjs";
import { parseHTMLString } from 'utils/utility-functions';
import isFinite from 'lodash/isFinite';
import toNumber from "lodash/toNumber";
import { useAllReportingColumns } from "./modules/tot-payment-periods/use-all-reporting-columns";
import { HeaderText } from './modules/header-helper-text';
import { filesActions } from "../../../app/state/files/files-slice";
import { UploadMask } from "../../../configurable-form/upload-mask";
import { TOTReportingModuleConfigContext } from "./modules/tot-reporting-module-config-context";

function hasAllTotalFieldsFactory(reportingColumns) {
  const fields =
    reportingColumns.map(({ inputRequired, field }) => inputRequired ? field : null)
      .filter(Boolean);

  const baseEvery = (taxableActivity) => fields.every((field) => isFinite(toNumber(get(taxableActivity, field))));

  return (licenseReport) => get(licenseReport, 'taxableActivities').every(baseEvery);
}

function groupLicensePeriodsByCalendarPeriod(licensePeriods) {

  const paymentPeriodsByName = {}

  for(const licensePeriod of licensePeriods) {
    if (!paymentPeriodsByName[licensePeriod.period]) {
      paymentPeriodsByName[licensePeriod.period] = {
        ...pick(licensePeriod, [
          'period',
          'startDate',
          'endDate',
          'licenseReports',
        ]),
        name: licensePeriod.period,
        licenseReports: []
      }
    }

    const paymentPeriod = paymentPeriodsByName[licensePeriod.period];
    paymentPeriod.licenseReports.push({
      ...pick(licensePeriod, [
        'period',
        'licence_no',
        'application_no',
        'display_id',
        'startDate',
        'endDate',
        "taxableReceipts",
        "totDue",
        "licenseFee",
        "assessment",
        "latePenalties",
        "accruedInterest",
        "type",
        'misc',
      ]),
      licenseId: licensePeriod.licence_no,
    });
  }

  return paymentPeriodsByName;
}

const AMOUNT_FIELDS = [
  'licenseFee',
  'totDue',
  'assessment',
  'latePenalties',
  'accruedInterest'
]

function calculateTotalsForMultiPropertyPaymentPeriod(paymentPeriod) {
  const totalFieldName = 'total';

  let result = {
    ...paymentPeriod,
    licenseReports: paymentPeriod.licenseReports.map(licenseReport => ({
      ...licenseReport,
      [totalFieldName]: AMOUNT_FIELDS.reduce((sum, field) => (licenseReport[field] || 0) + sum, 0),
      ...(!licenseReport.misc ? {} : {
        misc: {
          ...licenseReport.misc,
          taxableActivities: licenseReport.misc.taxableActivities?.map(activity => ({
            ...activity,
            ...Object.values(activity.taxTypes || {}).reduce(
              (totalFields, taxDetails) => addObjects(totalFields, taxDetails),
              mapObject(Object.values(activity.taxTypes || {})[0] || {}, () => 0)
            )
          }))
        }
      })
    }))
  };


  const paymentPeriodTotalFields = [...AMOUNT_FIELDS, totalFieldName, 'taxableReceipts']
    .reduce((acc, field) => ({
      ...acc,
      [field]: result.licenseReports
        .reduce((sum, licenseReport) => sum + (licenseReport[field] || 0), 0)
    }), {})

  result = {
    ...result,
    ...paymentPeriodTotalFields,
  }

  return result;
}


function toMultiPropertyTOTPaymentFees(paymentFees) {
  const paymentPeriodsByName = groupLicensePeriodsByCalendarPeriod(paymentFees.details);
  const paymentPeriods = Object.values(paymentPeriodsByName)
    .sort((a,b) => dayjs(b.startDate).diff(dayjs(a.startDate)))
    .map(calculateTotalsForMultiPropertyPaymentPeriod);

  return {
    ...omit(paymentFees, 'details'),
    paymentPeriods,
  }
}

function parseFormikErrors(values = {}, errors = {}) {
  const {
    taxablePeriods: taxablePeriodErrors = {},
    misc: miscErrors = {},
  } = errors;

  const taxablePeriodsHaveErrors = Object.keys(taxablePeriodErrors)
    .some(periodId => {
      const period = get(values, getPeriodFormikPath({ periodId }));
      return period?.licenseReports.some((report, reportIdx) => report?.selected
        && !isEmpty(taxablePeriodErrors[periodId].licenseReports[reportIdx]));
    });

  const miscHasErrors = !isEmpty(miscErrors);

  return taxablePeriodsHaveErrors || miscHasErrors;
}

export function ReportRevenue() {
  const dispatch = useDispatch();

  // const singleLicense = useSelector(payloadSelectors.license);

  const isMultiPropertyTOTEnabled = useSelector(appSelectors.isMultiPropertyTOTEnabled);
  const licensesInReport = useLicensesInReport();

  const {
    reporting: {
      // Functional Flags
      isQuarterlyPeriods = false,
      allowOmittingLatestPeriod,
      enableReportingUnfinishedCurrentPeriod,
      currencyFieldsLessThanAmount = 1000000,

      // UI Strings
      helperText = DEFAULT_HELPER_TEXT,

      // Configurable Components
      helperTextComponents = [],
      additionalReportingComponents = [],

      // Additional Parameters
      taxableItemExtraParams = {},
      hideDaysAvailable: nullifyDaysAvailable,
      hideDaysOccupied: nullifyDaysOccupied,
      totalReportableYears = DEFAULT_TOTAL_YEARS_TO_REPORT,
      chronologicalPeriodsOrder = ORDER_DESC,

      // Data
      strategies,
      dataTransform = {},
    }
  } = useSelector(appSelectors.renderConfig);

  const { storedPageConfig = {} } = useSelector(payTOTSelectors.reportedRevenue);

  const stepPositions = useSelector(appSelectors.stepPositions);

  const config = useSelector(appSelectors.renderConfig);
  const isPaymentPeriodDynamic = config?.reporting?.isPaymentPeriodDynamic

  const [open, setOpen] = useState(false); // Controls display for additional payment period selection modal
  const [pageConfig, setPageConfig] = useState(storedPageConfig);

  const availablePeriodsById = useMemo(() => pageConfig?.state?.availablePeriodsById || {}, [pageConfig?.state?.availablePeriodsById]);

  const licenseIdToUnpaidPeriods = useMemo(() => licensesInReport.reduce((acc, license) => ({
    ...acc,
    [license.licenseId]: getUnpaidPeriods(license.issuedDate, license.totPayments, Object.values(availablePeriodsById), isQuarterlyPeriods),
  }), {}),
  [availablePeriodsById, isQuarterlyPeriods, licensesInReport]);


  // TODO: Implement dynamic-period multi-property TOT support later
  // For now, this means that a quarterly license in dynamic period mode
  const isDynamicQuarter = useMemo(() =>
    isPaymentPeriodDynamic && licensesInReport.some(license => license?.totPaymentPeriod === typePaymentPeriod.QUARTERLY)
  , [isPaymentPeriodDynamic, licensesInReport]);

  // TODO: Support different max dates for different licenses in multi-property use cases
  const maxTotEndDate = licensesInReport
    .map(({ maxTotEndDate }) => maxTotEndDate)
    .filter(Boolean)
    .sort((dateString1, dateString2) => dateString1.localeCompare(dateString2))[0];

  const unfilteredLicensesInReport = useLicensesInReport({ disablePeriodTypeFilter: true });

  const resetTaxablePeriods = useCallback(({ dynamicQuarterSelectedPeriodType }) => {
    // This is just an array of providers (listing platforms)
    let rowParams = taxableItemExtraParams?.rowParamSets;

    if (rowParams && 'enableRowParamSets' in taxableItemExtraParams && !taxableItemExtraParams.enableRowParamSets) {
      // console.log('rowParams set to "undefined"');
      rowParams = undefined;
    }

    /**
     * List of licenses filtered by which type of payment period (either monthly or quarterly) that the
     * Pay flow is instructed to utilize by the switcher on the page (if enabled, see Galveston Pay flow).
     * Additional logic is applied, so see "licensePeriodTypeFilter" for details.
     */
    const licensesInReport = unfilteredLicensesInReport.filter(licensePeriodTypeFilter({ dynamicQuarterSelectedPeriodType }));

    const isAnyLicenseQuarterlyReporting = licensesInReport.some((license) => (
      license?.totPaymentPeriod === typePaymentPeriod.QUARTERLY
    ));
    const isDynamicQuarter = isPaymentPeriodDynamic && isAnyLicenseQuarterlyReporting;

    // totPaymentPeriod = null | monthly | quarterly
    const taxablePeriods = generateTaxablePeriods({
      totalReportableYears,
      isQuarterlyPeriods,
      rowParams,
      enableReportingUnfinishedCurrentPeriod,
      allowOmittingLatestPeriod,
      isDynamicQuarter,
      maxTotEndDate,
    });

    // in dynamic quarters, we show quarters in modal, but months on page.
    let quarterlyTaxablePeriods;
    if (isDynamicQuarter) {
      quarterlyTaxablePeriods = generateTaxablePeriods({
        totalReportableYears,
        isQuarterlyPeriods: true,
        rowParams,
        enableReportingUnfinishedCurrentPeriod,
        allowOmittingLatestPeriod,
        maxTotEndDate,
      });

      const earliestMonthlyReportablePeriodStart = dayjs(taxablePeriods[0].dateStart);

      const earliestReportableQuarterIndex = quarterlyTaxablePeriods.findIndex(
        quarter => !dayjs(quarter.dateStart).isBefore(earliestMonthlyReportablePeriodStart)
      );

      quarterlyTaxablePeriods = quarterlyTaxablePeriods.slice(earliestReportableQuarterIndex);

      const selectedQuarters = quarterlyTaxablePeriods.filter(({ selected }) => Boolean(selected));

      selectedQuarters.forEach((selectedQuarter) => {
        const firstMonthPeriodIndex = taxablePeriods.findIndex(({ dateStart }) => dateStart === selectedQuarter.dateStart);

        const slice = taxablePeriods.slice(firstMonthPeriodIndex, firstMonthPeriodIndex + 3);
        slice.forEach((monthlyPeriod) => { monthlyPeriod.selected = true });
      });
    }

    const taxablePeriodsById = taxablePeriods.reduce((acc, period) => ({ ...acc, [period.id]: period }), {});

    const finalTaxablePeriods = taxablePeriods
      .filter(({ selected }) => selected)
      .reduce((acc, p) => ({ ...acc, [p.id]: initializePeriodFromAvailablePeriodStub(p, licensesInReport) }), {});

    setPageConfig({
      values: { taxablePeriods: finalTaxablePeriods, quarterlyTaxablePeriods, },
      state: { availablePeriodsById: taxablePeriodsById },
    });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowOmittingLatestPeriod, enableReportingUnfinishedCurrentPeriod, isPaymentPeriodDynamic, isQuarterlyPeriods, maxTotEndDate, taxableItemExtraParams?.rowParamSets, totalReportableYears, unfilteredLicensesInReport]);

  const dynamicQuarterSelectedPeriodType = useSelector(appSelectors.dynamicQuarterSelectedPeriodType);

  useEffect(() => {
    if (!isEmpty(pageConfig)) return;
    resetTaxablePeriods({ dynamicQuarterSelectedPeriodType });
  }, [pageConfig, resetTaxablePeriods, dynamicQuarterSelectedPeriodType]);

  const reportingColumns = useAllReportingColumns();

  const isDisabled = useCallback((formik) => {
    const formikIsUninitialized = isEmpty(formik?.values);
    const hasValidFormikError = parseFormikErrors(formik?.values, formik?.errors);

    if (formikIsUninitialized || hasValidFormikError || formik.isSubmitting) {
      return true;
    }

    const isValidReport = hasAllTotalFieldsFactory(reportingColumns);
    const allSelectedLicenseReports = Object.values(formik.values.taxablePeriods)
      .flatMap(periodData => periodData.licenseReports.filter(({ selected }) => Boolean(selected)))

    return allSelectedLicenseReports.length < 1 || !allSelectedLicenseReports.every(isValidReport);
  }, [ reportingColumns ]);

  const submitChunkedTOTReport = async (payload) => {
    const { paymentPeriods } = payload;

    const CHUNK_SIZE = 50;

    dispatch(filesActions.setUploadProgress({ uploadProgress: 0 }));
    dispatch(filesActions.setIsUploading({ isUploading: true }));

    try {
      let data;
      let paymentId = null;

      for (let chunkStartIdx = 0; chunkStartIdx < paymentPeriods.length; chunkStartIdx += CHUNK_SIZE) {
        const chunkEndIdx = Math.min(paymentPeriods.length, chunkStartIdx + CHUNK_SIZE);
        const paymentPeriodsInChunk = paymentPeriods.slice(chunkStartIdx, chunkEndIdx);

        const result = await authSender.post(`/calculateMultiPropertyTot`, {
          ...payload,
          paymentPeriods: paymentPeriodsInChunk,
          numPaymentPeriods: paymentPeriods.length,
          paymentId,
        });

        data = result.data;
        paymentId = data.paymentId;

        const uploadProgress = Math.round(chunkEndIdx*100/paymentPeriods.length)
        dispatch(filesActions.setUploadProgress({ uploadProgress }));
      }
      return data;
    }
    finally {
      dispatch(filesActions.setIsUploading({ isUploading: false }));
    }
  }
  const handleSubmit = async (values) => {
    dispatch(payTotActions.setReportedRevenue({ storedPageConfig: { values, state: pageConfig?.state },  }));

    const payload = constructCalculateTOTPayload({
      paymentDetails: Object.values(values.taxablePeriods),
      taxableItemExtraParams,
      nullifyDaysAvailable,
      nullifyDaysOccupied,
      misc: values.misc,
      dataTransform,
      isMultiPropertyTOTEnabled,
    });

    try {
      let data;
      if (!isBulkTOTEnabled) {
        const result = await authSender.post(`/calculateMultiPropertyTot`, payload);
        data = result.data
      } else {
        data = await submitChunkedTOTReport(payload);
      }
      dispatch(networkActions.setPaymentFees(toMultiPropertyTOTPaymentFees(data)));
      PayTotStrategy.getInstance().executeStrategy(strategies, 'onContinueClick');
    } catch (err) {
      dispatch(errorActions.setHasErrored(true));
      captureExceptionWithContext(err, { payload: JSON.stringify(payload) });
    }
  };

  const isBulkTOTEnabled = useBulkTOTEnabled();
  const totName = useSelector(appSelectors.totName);

  if (!pageConfig) return null;

  const addPeriodsButton = (
    <Button
      type="button"
      onClick={() => setOpen(true)}
      style={{ color:"#5c5d5d", backgroundColor: "#e0e1e2" }}
      data-cy="report-revenue-add-additional-months"
    >
      <Icon name='add' />
      <span>Add additional {(isQuarterlyPeriods || isDynamicQuarter) ? 'quarters' : 'months'}</span>
    </Button>
  );

  return (
    <>
    <VerticalStepper activeStep={stepPositions.revenue}>
      <cs.Wrapper data-cy="report-revenue-view">
        <HeaderText />

        {Boolean(helperText.length) && (
          <cs.HelperText style={{ marginBottom: '40px', maxWidth: '100%' }}>
            {parseHTMLString(helperText)}
          </cs.HelperText>
        )}

        {maxTotEndDate && (
          <cs.HelperText style={{ marginBottom: '40px', maxWidth: '100%' }}>
            Please note that only periods with end dates up to {maxTotEndDate} (exclusive) could be reported.
          </cs.HelperText>
        )}

        <HelperComponents components={helperTextComponents} />

        <Formik
          enableReinitialize
          validateOnMount
          validateOnChange
          validateOnBlur
          initialValues={pageConfig?.values}
          onSubmit={handleSubmit}
        >
          {(formikProps) => (
            <FormikDerivedValues
              formikProps={formikProps}
              derivedValueFactoryMap={{
                valuesHaveData: formikValuesHaveData,
                disableContinue: isDisabled,
              }}
            >
              {({ formik, derivedValues: { valuesHaveData, disableContinue } }) => {
                return (
                  <Form>
                    {!isEmpty(formik.values) && (
                      <>
                        <ReportAdditionalRevenue
                          open={open}
                          handleClose={() => setOpen(false)}
                          totalReportableYears={totalReportableYears}
                          availablePaymentPeriodsById={availablePeriodsById}
                          licensesInReport={licensesInReport}
                          isQuarterlyDynamicPeriod={isDynamicQuarter}
                          totName={totName}
                        />

                        {isMultiPropertyTOTEnabled && (
                          <PropertySelection
                            isPaymentPeriodDynamic={isPaymentPeriodDynamic}
                            unpaidPeriodsByLicenseId={licenseIdToUnpaidPeriods}
                            availablePeriodsById={availablePeriodsById}
                            ignoreUnpaidPeriods={isBulkTOTEnabled}
                            disabled={isBulkTOTEnabled && valuesHaveData}
                            resetTaxablePeriods={resetTaxablePeriods}
                          />
                        )}

                        <TOTReportingModuleConfigContext.Provider
                          value={{
                            currencyFieldsLessThanAmount,
                          }}
                        >
                          <ReportTOT
                            chronologicalPeriodsOrder={chronologicalPeriodsOrder}
                            isDynamicQuarter={isDynamicQuarter}
                            availablePeriodsById={availablePeriodsById}
                            addPeriodsButton={addPeriodsButton}
                            taxableItemExtraParams={taxableItemExtraParams}
                            valuesHaveData={valuesHaveData}
                            continueButtonDisabled={disableContinue}
                          />
                        </TOTReportingModuleConfigContext.Provider>
                      </>
                    )}

                    {additionalReportingComponents.map((el, idx) => renderFieldComponent(el, idx))}

                    <cs.ButtonWrapper>
                      <BackButton />
                      <ContinueButton
                        loading={formik.isSubmitting}
                        isDisabled={disableContinue}
                      />
                    </cs.ButtonWrapper>

                    <Debug />
                  </Form>
                );
              }}
            </FormikDerivedValues>
          )}
        </Formik>

        <cs.ImageWrapper marginTop="124px">
          <img src="/assets/tot-pay-report-revenue.svg" alt="Calendar with check marks" />
        </cs.ImageWrapper>
      </cs.Wrapper>
    </VerticalStepper>
      <UploadMask heading={`Uploading ${totName} Report`} subtext={`We are uploading your ${totName} report data and calculating your tax summary.`} />
    </>
  );
}

function formikValuesHaveData(formik) {
  const { values } = formik;
  return Object.values(values?.taxablePeriods || {}).some(period =>
    period.licenseReports?.some(report => report.selected
      && report.taxableActivities?.some(
        ({ taxableReceipts, allowableDeductions }) => isFinite(taxableReceipts) || isFinite(allowableDeductions)
      )));
}

function FormikDerivedValues({ children: formikAndDerivedValuesToReactNode, derivedValueFactoryMap, formikProps }) {
  const derivedValues = useMemo(() => {
    return Object.keys(derivedValueFactoryMap).reduce((acc, valueKey) => ({
      ...acc,
      [valueKey]: derivedValueFactoryMap[valueKey](formikProps),
    }), {});
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [derivedValueFactoryMap, formikProps?.values]);


  return formikAndDerivedValuesToReactNode({ formik: formikProps, derivedValues });
}
