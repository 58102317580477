import MUISelect from '@material-ui/core/Select';
import styled from 'styled-components';

export const PortalPageContainer = styled.div`
  display: flex;
  margin: auto;
  max-width: calc(100vw - 300px);

  padding-top: 100px;
  padding-bottom: 150px;

  @media screen and (min-width: 1920px) {
    max-width: 1600px;
  }
`;

export const ReportingHistorySelect = styled(MUISelect).attrs({
  variant: 'outlined',
})`
  &&& {
    width: 200px;

    .MuiOutlinedInput-input {
      padding: 14px 10px;
    }
  }
`;
