import styled from "styled-components";
import * as cs from "common/common-styles";
import { PaymentInfoText } from "../../shared-styles";

export const StyledPaymentInfoText = styled(PaymentInfoText)`
`;
export const Input = styled(cs.StyledInput)`
  max-width: none;
  width: 100%;
`;
export const PaymentConfirmationForm = styled.div`
  width: 500px;
  padding: 1px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media screen and (max-width: 756px) {
    width: 100%;
  }
`
