import { createBrowserHistory } from 'history'

const DOMAINLESS_PORTAL_SUB_DOMAINS = ['str', 'propertyscape-reg'];

const [, domainlessPortalPlaceName] = window.location.pathname.split('/');
export const isDomainlessPortal = process.env.REACT_APP_DECKARD_FORCE_DOMAINLESS_PORTAL || DOMAINLESS_PORTAL_SUB_DOMAINS.includes(window.location.hostname.split('.')[0]);
export { domainlessPortalPlaceName }
export const history = createBrowserHistory({
  basename: isDomainlessPortal ? `/${domainlessPortalPlaceName}` : undefined,
});
