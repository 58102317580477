import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import unset from 'lodash/unset';

/**
 * Use this to either keep or remove duplicates from a dict
 *
 * @param {*} dict dictionary to dedupe
 * @param {*} orderList order of keys in which to dedupe. Keys that are mentioned earlier win.
 *    If two keys have the same value, value of second key will be set to null
 * @returns
 */
// TODO: Needs to be generic
export function processDictDuplicates(dict, orderList) {
  const newDict = cloneDeep(dict);

  for (let i = orderList.length - 1; i >= 0; i--) {
    for (let si = 0; si < i; si++) {
      const dictOne = get(dict, orderList[si]);
      const dictTwo = get(dict, orderList[i]);

      // If there is a duplicate of a person, we want to remove the person who comes later in the "orderList"
      if (isEqual(dictOne, dictTwo)) unset(newDict, orderList[i]);
    }
  }

  return newDict;
}
