import styled from "styled-components";
import * as cs from "../../../../common/common-styles";
import React, { useCallback, useEffect, useState } from "react";
import { DEFAULT_FORM_VALUE as emptyString } from "../../../../common/constants";
import { userInputTrimmer } from "../../../../utils/utility-functions";
import { useDispatch, useSelector } from "react-redux";
import * as appSelectors from "../../../../app/state/app/app-selectors";
import { payloadActions } from "../../../../app/state/payload/payload-slice";
import set from 'lodash/set';

const Container = styled.div`
  margin-top: 40px;
`;

const Header = styled(cs.HeaderText)`
  margin-top: 40px;
`;

const Question = styled.div`
`

const QuestionSubtext = styled.div`
  font-size: 14px;
`;

const Input = styled(cs.StyledInput)`
  width: 200px;
`;

export function QuestionInput({ question, questionSubtext, dataKey, dataCy, dispatchFormikUpdate }) {
  const [value, setValue] = useState(emptyString);

  useEffect(() => {
    const formikUpdatePayload = set({}, dataKey, value)
    dispatchFormikUpdate({ misc: formikUpdatePayload });
  }, [dispatchFormikUpdate, dataKey, value]);

  const onChange = useCallback((input) => {
    const parsed = userInputTrimmer(input);
    setValue(parsed);
  }, []);

  return (
    <div>
      <Header>
        <Question>{question}</Question>
        {questionSubtext && <QuestionSubtext>{questionSubtext}</QuestionSubtext>}
      </Header>
      <Input value={value} onChange={onChange} data-cy={dataCy} />
    </div>
  )
}



export function PlaceSpecificQuestions() {
  const dispatch = useDispatch();
  const renderConfig = useSelector(appSelectors.renderConfig);

  const dispatchFormikUpdate  = useCallback((payload) => {
    dispatch(payloadActions.mergeFormikSnapshot(payload));
  }, [dispatch]);

  const { supplementalQuestionsToDisplay = [] }  = renderConfig?.search || {};

  if (!supplementalQuestionsToDisplay.length) return null;

  return (
    <Container>
      {supplementalQuestionsToDisplay.map(({ key, question, questionSubtext }) => {
        return (
          <QuestionInput
            key={key}
            dataKey={key}
            dispatchFormikUpdate={dispatchFormikUpdate}
            question={question}
            questionSubtext={questionSubtext}
          />
        )
      })}
    </Container>
  );
}
