import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import * as appSelectors from 'app/state/app/app-selectors';
import { infoSummaryTestId } from 'test/constants';
import { LoginEmailChangeBanner } from './modules/login-email-change-banner';
import { SummarySection } from './modules/summary-section';

const Container = styled.div`
  max-width: ${(props) => props.useCustomerPortal ? undefined : '800px'};
`;

export function InfoSummary({ config, loginEmailPath, additionalCSS }) {
  const useCustomerPortal = useSelector(appSelectors.useCustomerPortal);

  if (!config?.length) return null;

  return (
    <Container
      data-testid={infoSummaryTestId}
      css={additionalCSS}
      useCustomerPortal={useCustomerPortal}
    >
      {loginEmailPath && <LoginEmailChangeBanner path={loginEmailPath} />}
      {config.map((el, idx) => <SummarySection key={idx} rowConfig={el} />)}
    </Container>
  );
}
