
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as appSelectors from 'app/state/app/app-selectors';
import * as networkSelectors from 'app/state/network/network-selectors';
import { appActions } from 'app/state/app/app-slice';
import { paymentMethodType } from 'common/constants';
import { checkIfExceedsACHLimit } from 'utils/utility-functions';
import { ACHPaymentLimitModal } from './ach-payment-limit-modal';
import { PaymentTabText, Tab, TabItem } from './tab-styles';
import { useIsCCActive } from './hooks';
import styled from 'styled-components';

export const SVGwrap = styled.img`
  height: 15px;
`;
const ACH_ICON = '/assets/bank-building-ACH-direct-debit.svg';

export function ACHTab() {
  const dispatch = useDispatch();
  const isCCActive = useIsCCActive();

  const activePaymentMethodTab = useSelector(appSelectors.activePaymentMethodTab);
  const paymentFees = useSelector(networkSelectors.paymentFees);

  // TODO: DECK-7991 Determine the flow and global ACH limits via the config and retrieve from there instead of storing in state
  const flowACHLimit = useSelector(appSelectors.applyLicenseACHLimit);
  const globalACHLimit = useSelector(appSelectors.globalACHLimit);

  const [openLimitWarning, setOpenLimitWarning] = useState(false);

  const isTabActive = useMemo(() => activePaymentMethodTab === paymentMethodType.ACH, [activePaymentMethodTab]);

  const handleClick = useCallback(() => {
    if (checkIfExceedsACHLimit(paymentFees.achTotal, flowACHLimit, globalACHLimit)) {
      setOpenLimitWarning(true);
    } else {
      dispatch(appActions.changePaymentMethodTab({ newTab: paymentMethodType.ACH }));
    }
  }, [dispatch, flowACHLimit, globalACHLimit, paymentFees.achTotal]);

  useEffect(() => {
    dispatch(appActions.changePaymentMethodTab({ newTab: paymentMethodType.ACH }));
    if (checkIfExceedsACHLimit(paymentFees.achTotal, flowACHLimit, globalACHLimit) && isCCActive) {
      dispatch(appActions.changePaymentMethodTab({ newTab: paymentMethodType.CREDIT_CARD }));
    }
  }, [paymentFees.achTotal, dispatch, flowACHLimit, globalACHLimit, isCCActive]);

  return (
    <>
      <ACHPaymentLimitModal open={openLimitWarning} setOpen={setOpenLimitWarning} />
      <Tab isActive={isTabActive} onClick={handleClick} data-cy="ach-payment-method">
        <TabItem>
          <SVGwrap src={ACH_ICON} alt="ACH Icon" />
        </TabItem>

        <TabItem>
          <PaymentTabText>ACH Direct Debit</PaymentTabText>
        </TabItem>
      </Tab>
    </>
  );
}
