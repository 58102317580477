import React from 'react';

import { HighlightedPermitData } from './highlighted-permit-data';

export function DateHighlight(props) {
  const { isPermitApplication, applicationDate, expirationDate } = props;

  const data = isPermitApplication ? applicationDate : expirationDate;

  if (!data || (!isPermitApplication && !expirationDate)) {
    return null;
  }

  return (
    <HighlightedPermitData
      label={`${isPermitApplication ? 'Application' : 'Expiration'} Date`}
      data={data}
    />
  );
}
