import { WhiteButton } from 'common/navigation-buttons';
import styled from 'styled-components';

export const DataFlex = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const ButtonContainer = styled.div`
  border-top: 1px solid rgb(230, 230, 233);
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
  padding: 20px;
  background-color: white;
`;

export const Button = styled(WhiteButton)`
  height: 100% !important;
  flex: 1;
  width: unset !important;
`;
