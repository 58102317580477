import dayjs from 'dayjs';
import minMax from 'dayjs/plugin/minMax';
import camelCase from 'lodash/camelCase';
import first from 'lodash/first';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import merge from 'lodash/merge';
import transform from 'lodash/transform';
import titleize from 'titleize';

import {
  DASHBOARD_BULK_RENEWAL_ACTION,
  DASHBOARD_SINGLE_RENEWAL_ACTION,
  DASHBOARD_TAX_REMITTANCE_ACTION,
} from './constants';

dayjs.extend(minMax);

/**
 * This function will take either an object or an array of objects
 * then iterate over all keys and camel case said keys.
 */
export function camelCaseDictKeys(json) {
  return transform(json, (acc, value, key, target) => {
    const camelKey = isArray(target) ? key : camelCase(key);
    acc[camelKey] = isObject(value) ? camelCaseDictKeys(value) : value;
  });
}

export function parseRawUserLicensesDataAsActiveLicenses(raw) {
  return camelCaseDictKeys(raw);
}

/**
 * This function will generate an object for the "ActionableItems" component
 * to consume to render a bulk or single renewal action onto the "Dashboard" component.
 * If bulk and single renewal are both enables, the bulk renewal path should be used
 *
 * @param {*} userLicensesData This is the data from "/renewableLicenses" API
 * @param {*} taxableLicensePeriodsData This is the data from "/totPaymentPeriods" API
 * @param {string} verbiage The language used by the jurisdiction for their permits
 * @param {boolean} isBulkRenewalEnabled Indicates if bulk renewal is enabled for the jurisdiction
 * @param {boolean} isSingleRenewalEnabled Indicates if single renewal is enabled for the jurisdiction
 * @param {string} taxverbiage The language used by the jurisdiction for their tax payments e.g. TOT, Tax
 * @param {*} taxableLicensePeriodsData This is the data from "/totPaymentPeriods" API
 * @param {boolean} isBulkTaxEnabled Indicates if bulk tax is enabled for the jurisdiction
 */
export function parseRawUserLicensesDataAsAction(
  userLicensesData, // This data comes in as camel cased.
  verbiage,
  isBulkRenewalEnabled,
  isSingleRenewalEnabled,
  taxVerbiage,
  taxableLicensePeriodsData,
  isBulkTaxEnabled,
) {
  const isUserLicenseDataEmpty = isEmpty(userLicensesData);
  const isRenewalDisabled =
    isBulkRenewalEnabled === false && isSingleRenewalEnabled === false;
  const isTaxableLicenseDataEmpty = isEmpty(taxableLicensePeriodsData);
  const isTaxDisabled = isBulkTaxEnabled === false;

  if (
    (isUserLicenseDataEmpty || isRenewalDisabled) &&
    (isTaxableLicenseDataEmpty || isTaxDisabled)
  ) {
    return [];
  } else {
    const renewalActions = generateRenewal(
      isBulkRenewalEnabled,
      isSingleRenewalEnabled,
      userLicensesData,
      verbiage,
    );
    const taxActions = generateTax(
      isBulkTaxEnabled,
      taxableLicensePeriodsData,
      taxVerbiage,
    );

    // Combine actions and order by dueDate DESC
    const actions = renewalActions.concat(taxActions);
    actions.sort((a, b) => {
      const dateA = dayjs(a.dueDate);
      const dateB = dayjs(b.dueDate);
      return dateB.diff(dateA);
    });

    return actions;
  }
}

const generateTax = (isBulkTaxEnabled, usersLicensePeriodsData, verbiage) => {
  if (isBulkTaxEnabled && usersLicensePeriodsData?.paymentPeriods.length) {
    const newArray =
      usersLicensePeriodsData?.paymentPeriods
        ?.map((paymentPeriod) => {
          // Check if there are any licenses within this payment period thar are actionable for tax
          const actionabeLicenses = paymentPeriod.licenses.filter(
            (license) => license.isActionable,
          );

          if (actionabeLicenses.length) {
            const label =
            actionabeLicenses.length <= 1
                ? `Pay ${verbiage} (${paymentPeriod.name}) ${titleize(
                    first(actionabeLicenses).address,
                  )}`
                : `Pay ${verbiage} (${paymentPeriod.name}) for ${actionabeLicenses.length} properties`;

            return {
              label,
              startDate: paymentPeriod.startDate,
              endDate: paymentPeriod.endDate,
              dueDate: dayjs(paymentPeriod.dueDate).unix(),
              type: DASHBOARD_TAX_REMITTANCE_ACTION,
              taxVerbiage: verbiage,
            };
          }
          // no incomplete licenses
          return null;
        })
        .filter((item) => item !== null) || []; // Filter out null values from the result

    return newArray;
  } else {
    return [];
  }
};

const generateBulkRenewal = (userLicensesData, verbiage) => {
  /**
   * Generate a user friendly string for either a single permit
   * or multiple permits.
   */
  const label =
    userLicensesData.length <= 1
      ? `Renew ${verbiage} - ${titleize(
          first(userLicensesData).propertyAddress,
        )}`
      : `Renew ${verbiage} - ${userLicensesData.length} properties`;

  /**
   * Get the earliest expiration date (in unix time) for all renewable permits.
   * This will be used to tell the user the date at which the first permit of the
   * set will expire.
   */
  const earliestExpirationDate = userLicensesData
    .reduce((earliestDate, { expirationDate }) => {
      if (!earliestDate) return dayjs(expirationDate);
      if (dayjs(expirationDate).isValid())
        return dayjs.min(dayjs(earliestDate), dayjs(expirationDate));
      return earliestDate;
    }, null)
    .unix();

  return [
    {
      label,
      dueDate: earliestExpirationDate,
      type: DASHBOARD_BULK_RENEWAL_ACTION,
    },
  ];
};

/**
 * Each property needs to have it's own object in the returned array
 */
const generateSingleRenewal = (userLicensesData, verbiage) => {
  return (
    userLicensesData?.map((license) => ({
      label: `Renew ${verbiage} - ${titleize(license.propertyAddress)}`,
      dueDate: dayjs(license.expirationDate).unix(),
      type: DASHBOARD_SINGLE_RENEWAL_ACTION,
      applicationNumber: license.applicationNumber,
    })) || []
  );
};

const generateRenewal = (
  isBulkRenewalEnabled,
  isSingleRenewalEnabled,
  userLicensesData,
  verbiage,
) => {
  if (
    userLicensesData?.length &&
    (isBulkRenewalEnabled || isSingleRenewalEnabled)
  ) {
    if (isBulkRenewalEnabled) {
      return generateBulkRenewal(userLicensesData, verbiage);
    } else {
      // single renew flow
      return generateSingleRenewal(userLicensesData, verbiage);
    }
  } else {
    return [];
  }
};

/**
 * This function add context to the permits that are selected for bulk renewal.
 */
export function parseBulkLicenseRenewalData(raw, bulkRenewableLicenses) {
  const perLicenseRenewalDetails = camelCaseDictKeys(raw.details);

  const updatedBulkRenewalData = perLicenseRenewalDetails.map((details) => {
    const original = bulkRenewableLicenses.find(
      (d) => d.license === details.licenceNo, // ! The typo is on purpose for "licenceNo"
    );

    return merge({}, original, {
      licenseFee: details.licenseFee,
      newExpirationDate: details.newExpirationDate,
    });
  });

  return updatedBulkRenewalData;
}
