import React from 'react';
import styled from 'styled-components';

const AnimatedTextWrapper = styled.div`
  font-family: 'Lato', sans-serif;
  margin-top: 115px;
  div {
      position: relative;
      color: rgba(0, 0, 0, .3);
      font-size: 14px;
  }
  div:before {
      content: attr(data-text);
      position: absolute;
      overflow: hidden;
      max-width: 12em;
      white-space: nowrap;
      color: #027680;
      animation: loading 2s linear infinite;
  }
  @keyframes loading {
      0% {
          max-width: 0;
      }
  }
`;

export function AnimatedText({ loadingText }) {
  return (
    <AnimatedTextWrapper>
      <div data-text={loadingText}>{loadingText}</div>
    </AnimatedTextWrapper>
  )
}
