import { DEFAULT_TITLE_CASED_KEYS } from 'common/constants';
import Fuse from 'fuse.js';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import merge from 'lodash/merge';
import set from 'lodash/set';
import React, { useCallback, useMemo, useState } from 'react';
import { AutoSizer, Column, Table } from 'react-virtualized';

import {
  DATA_TABLE_DATA_SELECTED_KEY,
  dataTableCheckboxColumn,
  dataTableRowActionButtonColumn,
} from './constants';
import { DataTableTotals } from './data-table-totals';
import { DataTableCell } from './modules/data-table-cell';
import { Div, TableDiv, TextField } from './styles';

export function DataTable(props) {
  const {
    data = [], // ! This should come in pre-sorted, rather than sorted within the component itself
    updateData,

    useCheckboxes = false,
    useFilter = false,

    /**
     * Enables the button to execute some function ("rowActionFn") received from "props".
     * This button will always be rendered on the far right-side of the table.
     * The row data will be used as the arguments for the function.
     */
    useRowActions = false,
    rowActionFn = () => {},

    /**
     * Useful for when another component, function, etc. needs to have a list
     * of only selected items.
     */
    provideSelectedData = () => {},
  } = props;

  const [searchString, setSearchString] = useState('');
  const [selectAllChecked, setSelectAllChecked] = useState(false);

  const internallyIndexedData = useMemo(
    () =>
      data.map((datum, i) =>
        merge({}, datum, { rawIndex: i, selected: datum?.selected ?? false }),
      ),
    [data],
  );

  const fuzzySearchableData = useMemo(
    () =>
      new Fuse(internallyIndexedData, { keys: props.layout.map((e) => e.key) }),
    [internallyIndexedData, props.layout],
  );

  const filteredData = useMemo(() => {
    const tFString = searchString.trim();
    if (!tFString) return internallyIndexedData;
    return fuzzySearchableData.search(searchString).map(({ item }) => item);
  }, [searchString, internallyIndexedData, fuzzySearchableData]);

  // All data rendered in the table comes from here
  const rowGetter = useCallback(
    ({ index }) => get(filteredData, `[${index}]`),
    [filteredData],
  );

  const columns = useMemo(() => {
    let tableColumns = props.layout;

    if (useCheckboxes)
      tableColumns = [dataTableCheckboxColumn].concat(tableColumns);
    if (useRowActions)
      tableColumns = tableColumns.concat([dataTableRowActionButtonColumn]);

    return tableColumns;
  }, [props.layout, useCheckboxes, useRowActions]);

  const columnWidths = useMemo(() => {
    const widths = columns.map(({ gridWidth }) => gridWidth);
    if (widths.some((w) => !w)) return null; // All columns must be specified
    return widths.join(' '); // The white space is for "grid-template-columns" css
  }, [columns]);

  const dataUpdater = useCallback(
    (updatedData) => {
      const fData = updatedData.filter((d) =>
        get(d, DATA_TABLE_DATA_SELECTED_KEY),
      );
      updateData(updatedData);
      provideSelectedData(fData);
    },
    [provideSelectedData, updateData],
  );

  const onSelectAllCheckboxClick = useCallback(() => {
    if (!useCheckboxes || !data?.length) return;
    setSelectAllChecked((prev) => {
      const next = !prev;

      const dataWithAllSelected = data.map((datum) => ({
        ...datum,
        [DATA_TABLE_DATA_SELECTED_KEY]: next,
      }));
      dataUpdater(dataWithAllSelected);

      return next;
    });
  }, [useCheckboxes, data, dataUpdater]);

  const onCheckboxClick = useCallback(
    (rowData, rowIndex) => {
      if (!useCheckboxes || !data?.length) return;

      // Post-click updated value
      const selectedState = !get(
        internallyIndexedData,
        `${rowIndex}.${DATA_TABLE_DATA_SELECTED_KEY}`,
        false,
      );
      const uData = set(rowData, DATA_TABLE_DATA_SELECTED_KEY, selectedState);
      const uRow = set(
        new Array(internallyIndexedData.length),
        rowIndex,
        uData,
      );
      dataUpdater(merge(cloneDeep(internallyIndexedData), uRow));
    },
    [data?.length, dataUpdater, internallyIndexedData, useCheckboxes],
  );

  const HeaderCellRenderer = useCallback(
    ({ label }, cellIndex) => {
      return (
        <DataTableCell
          variant="head"
          renderCheckbox={!cellIndex && useCheckboxes} // Only the leftmost (first column) can render the checkbox
          dataValue={label}
          onCheckboxClick={onSelectAllCheckboxClick}
          checked={selectAllChecked}
        />
      );
    },
    [onSelectAllCheckboxClick, selectAllChecked, useCheckboxes],
  );

  const DataCellRenderer = useCallback(
    ({ type, cellData, dataKey, rowData }, cellIndex) => {
      return (
        <DataTableCell
          variant="body"
          renderCheckbox={useCheckboxes && !cellIndex} // Only the leftmost (first column) can render the checkbox
          renderRowActionButton={
            useRowActions && columns.length - 1 === cellIndex
          } // Only the rightmost (last column) can render the checkbox
          type={type}
          titleCased={DEFAULT_TITLE_CASED_KEYS.includes(dataKey)}
          dataValue={cellData}
          onCheckboxClick={() => onCheckboxClick(rowData, rowData.rawIndex)}
          onRowActionClick={() => rowActionFn(rowData, rowData.rawIndex)}
          checked={Boolean(rowData?.selected)}
        />
      );
    },
    [
      columns.length,
      onCheckboxClick,
      rowActionFn,
      useCheckboxes,
      useRowActions,
    ],
  );

  const includesTotalRowParameters = useMemo(
    () => columns.filter((e) => e?.totalingParams).length,
    [columns],
  );

  return (
    <>
      <Div>
        {useFilter && (
          <TextField
            value={searchString}
            onChange={(e) => setSearchString(e.target.value)}
          />
        )}
      </Div>

      <TableDiv
        width={props.width}
        height={props.height}
        includesTotalRowParameters={includesTotalRowParameters}
        columnWidths={columnWidths}
        rowCount={filteredData?.length ?? 0}
        headerHeight={props.headerHeight ?? 48}
        rowHeight={props.rowHeight ?? 36}
        altTableGridHeight={props.altTableGridHeight}
      >
        <AutoSizer>
          {({ width, height }) => (
            <>
              <Table
                width={width}
                height={height}
                rowGetter={rowGetter}
                rowCount={filteredData?.length ?? 0}
                headerHeight={props.headerHeight ?? 48}
                rowHeight={props.rowHeight ?? 36}
              >
                {columns.map((columnProps, index) => (
                  <Column
                    key={columnProps.key}
                    dataKey={columnProps.key}
                    headerRenderer={(p) =>
                      HeaderCellRenderer({ ...p, ...columnProps }, index)
                    }
                    cellRenderer={(p) =>
                      DataCellRenderer({ ...p, ...columnProps }, index)
                    }
                    cellDataGetter={({ dataKey, rowData }) =>
                      get(rowData, dataKey)
                    }
                    // This is just to keep "Table" from posting an error in the console
                    // "columnWidths" does the actual handling of the widths per column
                    width={1}
                  />
                ))}
              </Table>

              <DataTableTotals
                show={includesTotalRowParameters}
                data={data}
                columns={columns}
                width={width}
                columnWidths={columnWidths}
              />
            </>
          )}
        </AutoSizer>
      </TableDiv>
    </>
  );
}
