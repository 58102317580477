import React from 'react';
import styled from 'styled-components/macro';
import { withVisibility } from './utils/with-visibility';

const StyledMessage = styled.div`
  border: 1px solid red;
  background-color: rgb(253, 242, 242);
  padding: 10px;
  border-radius: 2px;
  color: red;
`;


/**
 * Displays a toast like text message
 *
 * TODO: This should be a wrapper around the rsuite Message component.
 * ...We may have to upgrade rsuite to the next version in order to fix a bug where the
 * ...Message content does not appear.
 * @param {*} props.text
 * @returns
 */
function MessageComponent({ text, additionalCSS }) {
  return (
    <StyledMessage css={additionalCSS}>
      {text}
    </StyledMessage>
  );
}

export const Message = withVisibility(MessageComponent);
