import * as appSelectors from 'app/state/app/app-selectors';
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { ROOT_PATH } from 'routes/constants';

export function PortalRoute(props) {
  const { path, children, component: Component } = props;

  const useCustomerPortal = useSelector(appSelectors.useCustomerPortal);

  if (!useCustomerPortal) return <Redirect to={ROOT_PATH} />;

  return (
    <Route exact={props.exact} path={path}>
      {Component ? <Component /> : children}
    </Route>
  );
}
