import * as appSelectors from 'app/state/app/app-selectors';
import {
  DASHBOARD_INFLIGHT_STATUS,
  DASHBOARD_SUCCESS_STATUS,
} from 'app/state/dashboard/constants';
import * as dashboardSelectors from 'app/state/dashboard/dashboard-selectors';
import {
  DISABLE_BULK_RENEWAL_FLOW_PATH,
  DISABLE_BULK_TAX_FLOW_PATH,
  DISABLE_SINGLE_RENEW_FLOW_PATH,
} from 'common/constants';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { parseRawUserLicensesDataAsAction } from 'pages/portal/dashboard/helper';
import { DataFetchSpinner } from 'pages/portal/dashboard/shared/data-fetch-spinner';
import { NoData } from 'pages/portal/dashboard/shared/no-data';
import pluralize from 'pluralize';
import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
  ClickableModuleHeader,
  Module,
  ModuleDataContainer,
  ModuleHeader,
} from '../styles';
import { ActionableItemsList } from './action-item';

export function ActionableItems(props) {
  const certificateVerbiage = useSelector(appSelectors.certificateVerbiage);

  const taxVerbiage = useSelector(appSelectors.totName);

  const disableBulkRenewalFlow = useSelector(
    appSelectors.getFromPortalStats(DISABLE_BULK_RENEWAL_FLOW_PATH),
  );

  const disableSingleRenewalFlow = useSelector(
    appSelectors.getFromPortalStats(DISABLE_SINGLE_RENEW_FLOW_PATH),
  );

  const disableBulkTaxFlow = useSelector(
    appSelectors.getFromPortalStats(DISABLE_BULK_TAX_FLOW_PATH),
  );

  const { status: dataFetchStatus, data: bulkRenewableLicenses } = useSelector(
    dashboardSelectors.bulkRenewableLicenses,
  );

  const { status: taxDataFetchStatus, data: taxableLicensePeriods } =
    useSelector(dashboardSelectors.taxableLicensePeriods);

  const actionsList = useMemo(() => {
    if (isNil(bulkRenewableLicenses) && isNil(taxableLicensePeriods)) return [];

    const pluralCertificateVerbiage = pluralize(certificateVerbiage);
    return parseRawUserLicensesDataAsAction(
      bulkRenewableLicenses,
      pluralCertificateVerbiage,
      !disableBulkRenewalFlow,
      !disableSingleRenewalFlow,
      taxVerbiage,
      taxableLicensePeriods,
      !disableBulkTaxFlow,
    );
  }, [
    certificateVerbiage,
    bulkRenewableLicenses,
    disableBulkRenewalFlow,
    disableSingleRenewalFlow,
    taxableLicensePeriods,
    disableBulkTaxFlow,
    taxVerbiage,
  ]);

  const renewalsDisabled = useMemo(() => {
    return disableBulkRenewalFlow === true && disableSingleRenewalFlow === true;
  }, [disableBulkRenewalFlow, disableSingleRenewalFlow]);

  const taxDisabled = useMemo(() => {
    return disableBulkTaxFlow === true;
  }, [disableBulkTaxFlow]);

  const isLoading = useMemo(() => {
    const loadingRenewals = !renewalsDisabled && dataFetchStatus === DASHBOARD_INFLIGHT_STATUS;
    const loadingTax = !taxDisabled && taxDataFetchStatus === DASHBOARD_INFLIGHT_STATUS;

    return loadingRenewals || loadingTax;
  }, [
    renewalsDisabled,
    dataFetchStatus,
    taxDisabled,
    taxDataFetchStatus
  ]);

  const ActionableItemsContent = useCallback(() => {
    if (isEmpty(actionsList)) {
      
      if (isLoading) return <NoData onClick={props.fetch} disabled={isLoading} />

      const isRenewalsEvaluationSucceeded = renewalsDisabled || dataFetchStatus === DASHBOARD_SUCCESS_STATUS;
      const isTaxEvaluationSucceeded = taxDisabled || taxDataFetchStatus === DASHBOARD_SUCCESS_STATUS;

      const text = (isRenewalsEvaluationSucceeded && isTaxEvaluationSucceeded) ? 'No outstanding actions.' : 'Failed to retrieve data. Click to try again.';
      
      return (
        <NoData onClick={props.fetch} disabled={isLoading}>
          {text}
        </NoData>
      );
    }
    else {
      return <ActionableItemsList data={actionsList} />;
    }
  }, [
    actionsList,
    isLoading,
    renewalsDisabled,
    taxDisabled,
    dataFetchStatus,
    taxDataFetchStatus,
    props.fetch
  ]);

  return (
    <Module flex={2}>
      <ModuleHeader>
        <ClickableModuleHeader disabled={isLoading} onClick={props.fetch}>
          Actions Required
        </ClickableModuleHeader>
        <DataFetchSpinner show={isLoading && Boolean(actionsList.length)} />
      </ModuleHeader>

      <ModuleDataContainer noScrolling>
        <ActionableItemsContent />
      </ModuleDataContainer>
    </Module>
  );
}
