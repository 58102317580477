import { createSelector } from '@reduxjs/toolkit';
// import { PERSON_FIELDS_OF_INTEREST } from 'common/constants';
// import pick from 'lodash/pick';

export const login = (state) => state.login;

export const loggedInUsername = createSelector(
  login,
  (loginState) => loginState.loggedInUsername,
);

export const userEmail = createSelector(
  login,
  (loginData) => loginData.userEmail?.trim(),
);

export const openLoginModal = createSelector(
  login,
  (loginState) => loginState.openLoginModal,
);

export const loginPhase = createSelector(
  login,
  (loginState) => loginState.loginPhase,
);

export const userAccountDetails = createSelector(
  login,
  (loginState) => loginState.userAccountDetails,
);

// export const relevantUserAccountDetails = createSelector(
//   userAccountDetails,
//   (accountDetails) => {
//     return pick(accountDetails, PERSON_FIELDS_OF_INTEREST);
//   }
// );

export const isUserCognitoAuthenticated = createSelector(
  login,
  (loginState) => loginState.isUserCognitoAuthenticated,
);
