import React, { useMemo } from 'react';
import { useFormikContext } from 'formik';
import get from 'lodash/get';
import isFinite from 'lodash/isFinite';
import toNumber from 'lodash/toNumber';
import { InputGroup, InputNumber } from 'rsuite';
import { FormikError } from 'configurable-form/components/inputs/shared-components';
import { useInput } from 'configurable-form/components/utils/use-input';
import {
  convertToInputNumberFieldValue,
  InputContainer,
  REPORT_REVENUE_DATA_CY_PREFIX_STRING,
} from './shared';
import { ZERO } from 'common/constants';
import { useFieldCustomChangeHandler } from '../../input-fields/useFieldCustomChangeHandler';

function useValidation(max) {
  return useMemo(() => ({
    validationType: 'number',
    rules: [
      { type: 'required', params: ['This field is required.'] },
      { type: 'integer', params: ['The field only accepts integer values.'] },
      { type: 'min', params: [0, 'The minimum value accepted is 0.'] },
      ...(max === undefined ? [] : [{ type: 'max', params: [max, `The maximum value accepted is ${Math.max(max, ZERO)}.`] }]),
    ],
  }), [max]);
}

export function NumberField(props) {
  const { values } = useFormikContext();

  const { daysInPeriod, maxValueKey, additionalReportingColumn } = props;

  const { daysInPeriodRestricted } = props?.additionalReportingColumn || {};

  const maxValidationValue = useMemo(() => {
    const path = additionalReportingColumn ?
      props.fieldKey.replace(additionalReportingColumn.field, additionalReportingColumn.maxValueRelativePath) :
      maxValueKey;

    const maxValueFromUserInput = get(values, path);

    if (isFinite(toNumber(maxValueFromUserInput))) return maxValueFromUserInput;

    return daysInPeriodRestricted ? daysInPeriod : undefined;
  }, [additionalReportingColumn, daysInPeriod, daysInPeriodRestricted, maxValueKey, props.fieldKey, values]);

  const validation = useValidation(maxValidationValue);
  const { field, helpers, meta } = useInput({
    name: props.fieldKey,
    validation,
  });

  const onChange = useFieldCustomChangeHandler({ helpers, getValueFromArgs: convertToInputNumberFieldValue });

  return (
    <InputContainer data-cy={`${REPORT_REVENUE_DATA_CY_PREFIX_STRING}-${props.fieldKey}`}>
      <InputGroup>
        <InputNumber {...field} scrollable={false} onChange={onChange} min={0} max={maxValidationValue} />
      </InputGroup>
      <FormikError {...meta} touched />
    </InputContainer>
  );
}
