import styled from 'styled-components';

export const Instructions = styled.p`
  margin-top: 20px;
  margin-bottom: 20px;
  line-height: 1.5;
  color: black;
`;

export const Info = styled.p`
  line-height: 1.5;
  color: #5c5d5d;
  margin-bottom: 20px;
`;

export const ErrorMessage = styled.div`
  display: ${(props) => (props.show ? 'block' : 'none')};
  color: red;
`;
