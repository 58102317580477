import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import * as appSelectors from 'app/state/app/app-selectors';
import * as loginSelectors from 'app/state/login/login-selectors';
import { loginActions } from 'app/state/login/login-slice';
import {
  PERMIT_VERBIAGE,
  PERMIT_VERBIAGE_TRANSLATION_KEY,
  UPDATE_ACCOUNT_FIELDS_PATH,
} from 'common/constants';
import { WhiteButton } from 'common/navigation-buttons';
import { Form, Formik } from 'formik';
import pluralize from 'pluralize';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ROOT_PATH } from 'routes/constants';
import { history } from 'routes/history';
import { Alert } from 'rsuite';
import styled from 'styled-components';
import { authSender } from 'utils/auth-sender';
import { captureExceptionWithContext } from 'utils/sentry-functions';
import { strRegistrationApiSender } from 'utils/str-registration-api-sender';
import { useTranslations } from 'utils/translations';

// import { Debug } from 'configurable-form/components/debugging/debug';
import { Header } from '../../shared/dashboard-styles';
import {
  disableNameInputFieldsForUserAccountUpdate,
  renderFieldsForUserAccountUpdate,
} from './utils';

const COMPLETED_FORM_WARNING = 'Ensure that the form is filled out properly.';

class EditPersonDetailsByUserAPIError extends Error {
  constructor(message) {
    super(message);
    this.name = 'EditPersonDetailsByUserAPIError';
  }
}

const nameMultiInputFieldId = 'customerPortalNameMultiInputField';

const Div = styled.div`
  margin-bottom: 30px;

  p {
    font-size: 18px;
  }
`;

const Button = styled(WhiteButton)`
  &&& {
    margin-top: 20px;
    padding: 10px 30px;
    width: ${(props) => props.width};

    border: ${(props) => (props.$noBorder ? 'none' : undefined)};

    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    pointer-events: ${(props) => (props.disabled ? 'none' : 'all')};

    .MuiButton-label {
      color: ${(props) =>
        props.$noBorder ? 'rgb(49, 161, 218) !important' : undefined};
    }
  }
`;

export function UpdateUserDetailsPage() {
  const dispatch = useDispatch();

  const translations = useTranslations();
  const permitVerbiage = pluralize(
    String(
      translations?.[PERMIT_VERBIAGE_TRANSLATION_KEY]?.default ??
        PERMIT_VERBIAGE,
    ).toLowerCase(),
  );

  const userAccountDetails = useSelector(loginSelectors.userAccountDetails);
  const loggedInUsername = useSelector(loginSelectors.loggedInUsername);
  const userEmail = useSelector(loginSelectors.userEmail);

  // Do not use these fields directly.
  const accountDetailsFields = useSelector(
    appSelectors.getFromPortalStats(UPDATE_ACCOUNT_FIELDS_PATH),
  );

  const [dialogueOpen, setDialogueOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const fields = useMemo(() => {
    return accountDetailsFields.map((field) => {
      if (field.id === nameMultiInputFieldId)
        return disableNameInputFieldsForUserAccountUpdate(field);
      return field;
    });
  }, [accountDetailsFields]);

  const handleUpdateStart = useCallback((formik) => {
    if (formik.isValid) return setDialogueOpen(true);
    else return Alert.warning(COMPLETED_FORM_WARNING);
  }, []);

  const cancelUpdate = useCallback(() => {
    if (loading) return;
    setDialogueOpen(false);
    setLoading(false);
  }, [loading]);

  const handleSubmit = useCallback(
    async (formik) => {
      if (loading) return;

      setLoading(true);

      if (!formik.isValid) {
        setLoading(false);
        return Alert.warning(COMPLETED_FORM_WARNING);
      }

      try {
        // "formik.values" contains all the information the user entered in the form.
        await authSender.post('/editPersonDetailsByUser', formik.values);

        // Refetch the user details post-update to refresh the stored information in Redux state.
        const { data } = await strRegistrationApiSender.get(
          `/getUserByEmailAuth/${userEmail}`,
        );

        // Refresh the user's personal details in the Redux store.
        dispatch(loginActions.setUserAccountDetails(data));
        Alert.success('Your account has been successfully updated.', 10000);
        setLoading(false);
        history.push(ROOT_PATH);
      } catch (err) {
        setDialogueOpen(false);
        setLoading(false);

        captureExceptionWithContext(new EditPersonDetailsByUserAPIError(err), {
          userName: loggedInUsername,
          email: userEmail,
          payload: formik.values,
        });

        Alert.error(
          'Failed attempting update the details for your account. Please try again at a later time.',
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loading, loggedInUsername, userEmail],
  );

  return (
    <div>
      <Div>
        <Header>Update Permit Holder Details</Header>
        <p>
          Please update your details below. Note that you cannot change some
          details.
        </p>
        <p>
          Please note that any details updated here will apply to all{' '}
          {permitVerbiage} associated with this account.
        </p>
      </Div>

      <Formik
        validateOnChange
        validateOnBlur
        initialValues={userAccountDetails}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <Form>
            <>{fields.map(renderFieldsForUserAccountUpdate)}</>

            {/* Button to open the confirmation modal */}
            <Button type="button" onClick={() => handleUpdateStart(formik)}>
              Update
            </Button>

            {/* Confirmation modal */}
            <Dialog open={dialogueOpen} onClose={cancelUpdate}>
              <DialogTitle>
                Confirmation of Personal Information Update
              </DialogTitle>

              <DialogContent>
                <DialogContentText>
                  Please note that any details updated here will apply to all{' '}
                  {permitVerbiage} associated with this account. If you do not
                  wish to update all {permitVerbiage} with these details, please
                  hit Cancel. This process may take a bit of time depending on
                  how many {permitVerbiage} are associated with the account.
                </DialogContentText>

                <DialogActions>
                  <Button
                    width="150px"
                    type="submit"
                    disabled={loading}
                    onClick={() => handleSubmit(formik)}
                  >
                    {loading ? <CircularProgress size={20} /> : 'Confirm'}
                  </Button>

                  <Button
                    $noBorder
                    width="100px"
                    type="button"
                    disabled={loading}
                    onClick={cancelUpdate}
                  >
                    Cancel
                  </Button>
                </DialogActions>
              </DialogContent>
            </Dialog>

            {/* <Debug /> */}
          </Form>
        )}
      </Formik>
    </div>
  );
}
