import { getFromPortalStats } from 'app/state/app/app-selectors';
import * as dashboardSelectors from 'app/state/dashboard/dashboard-selectors';
import { dashboardActions } from 'app/state/dashboard/dashboard-slice';
import { networkActions } from 'app/state/network/network-slice';
import { DISABLE_SINGLE_RENEW_FLOW_PATH, ONE } from 'common/constants';
import { renderFieldComponent } from 'configurable-form';
import { DataTable } from 'configurable-form/components/data-table/data-table';
import { Navigation } from 'configurable-form/shared/navigation';
import chunk from 'lodash/chunk';
import first from 'lodash/first';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import merge from 'lodash/merge';
import { parseBulkLicenseRenewalData } from 'pages/portal/dashboard/helper';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { Alert } from 'rsuite';
import { BulkRenewalStrategy } from 'strategies/bulk-renewal-strategy';
import { CustomerPortalStrategy } from 'strategies/customer-portal/customer-portal-strategy';
import styled from 'styled-components';
import { authSender } from 'utils/auth-sender';
import { captureExceptionWithContext } from 'utils/sentry-functions';

import { CalculateBulkLicenseRenewalFeeAPIError } from './errors';

const CHUNK_SIZE = 5; // 5 is an arbitrary number

const ProcessingDiv = styled.div`
  margin-top: 40px;
  text-align: right;
  color: red;
`;

export function BulkRenewalSelectProperties(props) {
  const dispatch = useDispatch();

  /**
   * We don't need to check for the fetch status, because this data is only used
   * when the user is entering the bulk renewal flow from the permits list view.
   *
   * The permits list view requires that the data fetch to be completed before
   * allowing user to perform any and all individual and bulk actions.
   */
  const { data: userPermitsList } = useSelector(
    dashboardSelectors.userPermitsList,
  );

  const disableSingleRenewFlow = useSelector(
    getFromPortalStats(DISABLE_SINGLE_RENEW_FLOW_PATH),
  );

  const bulkRenewalDataExpirationDateAsc = useSelector(
    dashboardSelectors.bulkRenewalDataExpirationDateAsc,
  );

  const preselectedPermitsByApplicationNumber = useSelector(
    dashboardSelectors.preselectedPermitsByApplicationNumber,
  );

  const [data, setData] = useState(
    isNil(bulkRenewalDataExpirationDateAsc)
      ? []
      : bulkRenewalDataExpirationDateAsc,
  );

  const [selectedData, setSelectedData] = useState();

  useEffect(() => {
    dispatch(dashboardActions.setBulkLicensesToBeRenewed(null));

    return function cleanUp() {
      dispatch(dashboardActions.setPreselectedPermitsByApplicationNumber(null));
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // ------------------------------------------------------
  // UI States
  // ------------------------------------------------------

  const [loading, setLoading] = useState(false);

  const isNavigationDisabled = useMemo(
    () => loading || isEmpty(selectedData) || isEmpty(data),
    [data, loading, selectedData],
  );

  // ------------------------------------------------------
  // Pre-selected Permits Handling
  // ------------------------------------------------------

  useEffect(() => {
    const exit =
      isNil(bulkRenewalDataExpirationDateAsc) ||
      isNil(preselectedPermitsByApplicationNumber);
    if (exit) return;
    setLoading(true);

    const matched = bulkRenewalDataExpirationDateAsc.map((l) =>
      preselectedPermitsByApplicationNumber.includes(l.applicationNumber)
        ? merge({}, l, { selected: true })
        : l,
    );

    setData(matched);
    setSelectedData(matched);
    setLoading(false);
  }, [bulkRenewalDataExpirationDateAsc, preselectedPermitsByApplicationNumber]);

  // ------------------------------------------------------
  // Permit Fee Calculation Fns
  // ------------------------------------------------------

  const executeStrategy = useCallback((entryStrategy) => {
    if (!entryStrategy)
      throw new Error(
        'No strategy defined for executeStrategy - BulkRenewalSelectProperties',
      );
    BulkRenewalStrategy.getInstance().executeStrategy(
      props.strategies,
      entryStrategy,
    );
    // This shouldn't need any deps as strategies are determined in the config and hence unchanging at runtime.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const processRenewalForChunk = useCallback(
    async (chunk, numRenewalRequests, paymentId = null) => {
      const { data } = await authSender.post(
        '/calculateBulkLicenseRenewalFee',
        {
          renewalRequests: chunk.map((c) => ({ license: c.license })),
          numRenewalRequests,
          paymentId,
        },
      );

      return data;
    },
    [],
  );

  const handleBulkRenewal = useCallback(async () => {
    setLoading(true);
    let paymentFees;

    try {
      const renewalChunks = chunk(selectedData, CHUNK_SIZE);
      for (const chunk of renewalChunks) {
        // For loops are async aware, while standard array methods generally are not
        const response = await processRenewalForChunk(
          chunk,
          selectedData.length,
          paymentFees?.paymentId,
        );
        paymentFees = response;
      }

      const data = parseBulkLicenseRenewalData(
        paymentFees,
        bulkRenewalDataExpirationDateAsc,
      );

      batch(() => {
        dispatch(networkActions.setPaymentFees(paymentFees));
        dispatch(dashboardActions.setBulkLicensesToBeRenewed(data));
      });

      setLoading(false); // Do this before moving to the next page or else React is going to log a mem leak error
      executeStrategy('onContinueClick');
    } catch (err) {
      setLoading(false);
      captureExceptionWithContext(
        new CalculateBulkLicenseRenewalFeeAPIError(err),
        {
          selectedData,
        },
      );

      Alert.error(
        'Failed to calculate renewal fees. Please try again at a later time.',
        10000,
      );
    }
  }, [
    bulkRenewalDataExpirationDateAsc,
    dispatch,
    executeStrategy,
    processRenewalForChunk,
    selectedData,
  ]);

  const handleSingleRenewalRedirect = useCallback(async () => {
    if (disableSingleRenewFlow) return await handleBulkRenewal();
    const applicationNumber = first(selectedData).applicationNumber;
    const permitData = userPermitsList.find(
      (p) => p.applicationNumber === applicationNumber,
    );

    await CustomerPortalStrategy.getInstance().initiateAlternateSingleRenewalFlowEntry(
      {
        setLoading,
        applicationNumber,
        permitData,
      },
    );
  }, [
    disableSingleRenewFlow,
    handleBulkRenewal,
    selectedData,
    userPermitsList,
  ]);

  const handleContinueClick = useCallback(async () => {
    if (isNil(selectedData) || !selectedData.length) return;

    if (selectedData.length > ONE) return await handleBulkRenewal();
    return await handleSingleRenewalRedirect();
  }, [handleBulkRenewal, handleSingleRenewalRedirect, selectedData]);

  return (
    <div>
      {props.standardFields.map((f, i) =>
        renderFieldComponent(f, `brPropertySelectionStandardHeaders${i}`),
      )}

      {/* {props.additionalFieldsBeforeDataTableComponent.map((f, i) => (
        renderFieldComponent(f, `additionalFieldsBeforeDataTableComponent${i}`)
      ))} */}

      <DataTable
        useCheckboxes={props.useCheckbox}
        useFilter={props.useFilter}
        data={data}
        updateData={setData}
        layout={props.layout}
        provideSelectedData={setSelectedData}
      />

      {/* {props.additionalFieldsAfterDataTableComponent.map((f, i) => (
        renderFieldComponent(f, `additionalFieldsAfterDataTableComponent${i}`)
      ))} */}

      <ProcessingDiv>This may take a moment to process.</ProcessingDiv>

      <Navigation
        continueButtonType="button"
        loading={loading}
        disabled={isNavigationDisabled}
        onContinueClick={handleContinueClick}
        marginTopOverride={0}
      />
    </div>
  );
}
