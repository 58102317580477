import * as appSelectors from 'app/state/app/app-selectors';
import { BackButton, ContinueButton } from 'common/navigation-buttons';
import { useFormikContext } from 'formik';
import { usePortalAuth } from 'login/hooks/use-portal-auth';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { renderFields } from './helpers';

const EMAIL_FIELDS_PATH = 'accountManagement.emailFields';

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  /* TODO: Adjust widths */
  width: 700px;
`;

export function EmailPage(props) {
  const { values, setValues } = useFormikContext();

  const { userSignOut } = usePortalAuth();

  const emailFields = useSelector(
    appSelectors.getFromPortalStats(EMAIL_FIELDS_PATH),
  );

  useEffect(() => {
    (async () => {
      await userSignOut();

      if (values.email)
        setValues({ email: String(values.email).toLowerCase() });
    })();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div>{emailFields.map(renderFields)}</div>

      <Flex>
        <BackButton />
        <ContinueButton label="Create Account" loading={props.loading} />
      </Flex>
    </>
  );
}
