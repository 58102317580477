import { BoxShadow } from 'common/common-styles';
import styled from 'styled-components';

// ------------------------------------------------------
// "LabelledDisplay" Component Styles
// ------------------------------------------------------

export const LabelledDisplayDiv = styled.div`
  width: ${(props) => props.$width ?? 'inherit'};
`;

// ------------------------------------------------------
// "Person" Component Styles
// ------------------------------------------------------

export const PersonalDetailsContainer = styled.div`
  ${BoxShadow};

  pointer-events: ${(props) => (props.disabled ? 'none' : 'inherit')};

  width: 500px;
  display: flex;
  flex-direction: column;
  padding: 10px;
`;

export const Columns = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${(props) => props.justifyContent ?? 'inherit'};
  gap: ${(props) => props.gap ?? undefined};
`;

export const Column = styled.div`
  width: ${(props) => props.width ?? '100%'};
  align-items: ${(props) => props.alignItems ?? 'inherit'};

  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const Display = styled.div`
  display: flex;
  justify-content: ${(props) => props.justifyContent ?? 'inherit'};

  width: ${(props) => {
    if (props.type === 'email') return 'inherit';
    return props.width ?? 'inherit';
  }};

  max-width: ${(props) => (props.type === 'email' ? '150px' : undefined)};

  overflow-wrap: ${(props) => props.overflowWrap ?? 'inherit'};

  text-align: ${(props) => props.textAlign ?? 'inherit'};
  font-size: ${(props) => props.fontSize ?? 'inherit'};
  color: ${(props) => props.fontColor ?? 'inherit'};

  a,
  p {
    white-space: ${(props) => (props.useEllipses ? 'nowrap' : 'inherit')};
    overflow: ${(props) => (props.useEllipses ? 'hidden' : 'inherit')};
    text-overflow: ${(props) => (props.useEllipses ? 'ellipsis' : 'inherit')};
  }
`;

export const LargeDisplay = styled(Display)`
  font-size: 18px;
`;

// ------------------------------------------------------
// "PropertyContacts" Component Styles
// ------------------------------------------------------

export const Div = styled.div`
  width: 500px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const PCHeader = styled.div`
  width: 500px;
  font-size: 18px;
  font-weight: bold;
`;
