import * as yup from 'yup';

yup.setLocale({
  mixed: {
    notType: (_ref) => _ref?.type ? `Please enter ${_ref?.type} values only.` : "Please enter values in the correct format"
  }
});

yup.addMethod(yup.string, "deckard_matchesRegexString",
  /**
   * See the type definition for more information about
   * this custom Yup method
   */
  function matchesRegexString(regex, options) {
    let excludeEmptyString = false;
    let message;
    let name;

    if (options) {
      if (typeof options === 'object') {
        ({ excludeEmptyString, message, name } = options);
      } else {
        message = options;
      }
    }

    return this.test({
      name: name || 'matches',
      message: message || 'Value does not match expected pattern',
      params: { regex },
      test: (value) =>
        value == null ||
        (value === '' && excludeEmptyString) ||
        value.search(regex) !== -1,
    });
  });

export * from "yup";
