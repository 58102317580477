/* eslint-disable no-unused-vars */
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import * as appSelectors from 'app/state/app/app-selectors'
import { paymentMethodType } from 'common/constants';
import { StripeACH } from './stripe/ach/stripe-ach';
import { Stripe } from './stripe/card/stripe';
import { FiServ } from './fiserv/fiserv';
import { Hamer } from './generic-based-vendors/hamer';
import { XpressBillPay } from './generic-based-vendors/xpress-bill-pay';
import { CertifiedPayments } from './generic-based-vendors/certified-payments';
import { Plaid } from "./stripe/ach-legacy/plaid";
import { Button } from "rsuite";
import styled, { css } from 'styled-components';
import { checkIfDevCognitoUserPool } from "utils/utility-functions";
import { Check } from './pay-by-check/check';
import { PushACH } from './pay-by-push-ach/push-ach';
import { HeartlandPayments } from './generic-based-vendors/heartland';
import { CardConnect } from './generic-based-vendors/card-connect';

// TODO: Remove these after ACH testing is done
const devStyles = css`
  color: #bbb;
`;
const DevOptions = styled.div`
  margin-top: 50px;
  ${devStyles}
`;

const DevButton = styled(Button)`
  ${devStyles}
`;

function StripePaymentMethodSwitch(props) {
  const activePaymentMethodTab = useSelector(appSelectors.activePaymentMethodTab);
  const stats = useSelector(appSelectors.stats);

  const appConfig = stats?.app;

  const enablePlaidACH = appConfig?.enablePlaidACH;
  const enableStripeACH = appConfig?.enableStripeACH;

  // TODO: END Remove these after ACH testing is done
  const enableACHLegacySwitcher = useMemo(() => enablePlaidACH && enableStripeACH && checkIfDevCognitoUserPool(),
    [enablePlaidACH, enableStripeACH])

  const [useLegacyACH, setUseLegacyACH] = useState(enablePlaidACH);

  if (activePaymentMethodTab === paymentMethodType.PUSH_ACH) return <PushACH {...props} />
  if (activePaymentMethodTab === paymentMethodType.CHECK) return <Check {...props} />
  if (activePaymentMethodTab === paymentMethodType.CREDIT_CARD) return <Stripe {...props} />

  // TODO: This legacy ACH logic should be removed before deploying to prod
  const ACHComponent = !useLegacyACH ? StripeACH : Plaid;
  return <>
    <ACHComponent {...props} />
    {enableACHLegacySwitcher && (
      <DevOptions>
        <p>Currently using {useLegacyACH ? 'Legacy/Plaid' : 'New/Stripe Direct Debit'} ACH payments</p>
        <DevButton onClick={() => setUseLegacyACH(!useLegacyACH)}>
          Switch to {useLegacyACH ? "New ACH" : "Legacy ACH"}
        </DevButton>
      </DevOptions>
    )}
  </>;
}

const availableProviders = {
  stripe: StripePaymentMethodSwitch,
  fiserv: FiServ,
  hamer: Hamer,
  certifiedPayments: CertifiedPayments,
  heartland: HeartlandPayments,
  xpressBillPay: XpressBillPay,
  cardConnect: CardConnect,
}

export function VendorSwitch({ vendor, ...paymentProps }) {
  const { provider, ...other } = vendor;

  const PaymentProcessorComponent = useMemo(() => {
    const possibleComponent = availableProviders[provider];
    return possibleComponent || availableProviders.stripe;
  }, [provider]);
  return <PaymentProcessorComponent {...paymentProps} {...other} />;
}
