import React from 'react';
import SectionCard from 'common/section-card';

export function AdditionalCards({ cards = [] }) {
  return (
    <>
      {cards.map((card) => <SectionCard key={card.dataCy} {...card} />)}
    </>
  )
}
