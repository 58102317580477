import { createSlice } from '@reduxjs/toolkit';

const STARTING_PROGRESS = 5;

const INIT_STATE = {
  hasAnyFileUploadFailed: false,
  isUploading: false,

  uploadProgress: STARTING_PROGRESS,
  uploadedDocumentTypesMap: {},
};

export const filesSlice = createSlice({
  name: 'files',
  initialState: INIT_STATE,
  reducers: {
    setHasUploadFailed: (state, action) => {
      state.hasAnyFileUploadFailed = Boolean(action.payload);
    },

    setIsUploading: (state, action) => {
      const { isUploading } = action.payload;
      state.isUploading = isUploading;
    },

    setUploadProgress: (state, action) => {
      const { uploadProgress } = action.payload;
      state.uploadProgress = uploadProgress;
    },

    setUploadedDocumentTypesMap: (state, action) => {
      state.uploadedDocumentTypesMap = {
        ...state.uploadedDocumentTypesMap,
        ...action.payload,
      };
    },

    resetUploadingStateAndProgress: (state) => {
      state.isUploading = false;
      state.uploadProgress = STARTING_PROGRESS;
    },

    reset: () => INIT_STATE
  },
})

export const filesActions = filesSlice.actions;

export const filesReducer = filesSlice.reducer;
