import React, { useCallback } from 'react';
import NumberFormat from 'react-number-format';
import { InputComponent } from './shared-components';
import { DEFAULT_FORM_VALUE as emptyString } from 'common/constants';

const replaceRegex = [/-/gi, /_/gi];

function removeHyphens(e) {
  replaceRegex.forEach((regex) => {
    if (!e?.target?.value) return;
    e.target.value = e.target.value.replace(regex, emptyString);
  });

  return e;
}

export function MaskPhoneNumberInput({ dataCy, ...other }) {
  const onChange = useCallback((e) => {
    e = removeHyphens(e);
    if (other.onChange) other.onChange(e);
  }, [other]);

  const onBlur = useCallback((e) => {
    e = removeHyphens(e);
    if (other.onBlur) other.onBlur(e);
  }, [other]);

  return (
    <NumberFormat
      {...other}
      onChange={onChange}
      onBlur={onBlur}
      mask="_"
      format="###-###-####"
      customInput={InputComponent}
      data-cy={dataCy}
    />
  );
}
