import React from 'react';
import * as cs from 'common/common-styles';
import { ONE } from 'common/constants';
import { requirementsTestId } from 'test/constants';
import { TableRender } from './modules/table-render';
import { TextRender } from './modules/text-render';
import * as rs from './requirements-styles';
import { renderFields } from "../fragment";
import { withVisibility } from '../utils/with-visibility';

const fallbackHeader = 'Requirements';

function RequirementsItemComponent(props) {
  const { id, index, requirementHeader, requirementText, tableData, links, fields } = props;

  return (
    <rs.ListItem>
      <rs.ListLabel>{index + ONE}</rs.ListLabel>

      <rs.ListContent>
        {Boolean(requirementHeader) && <TextRender text={requirementHeader} component={rs.ListHeader} />}
        <TextRender text={requirementText} component={rs.ListBody} />

        {links && links.map(({ label, link }) => (
          <cs.Link key={id} href={link} target="_blank" rel="noreferrer" style={{ paddingTop: '5px' }}>
            {label}
          </cs.Link>
        ))}

        {tableData && <TableRender {...tableData} />}

        {fields && renderFields(fields)}
      </rs.ListContent>
  </rs.ListItem>
  )
}

const RequirementsItem = withVisibility(RequirementsItemComponent);

// See "RequirementsProps" (types/fields/component-props/requirements.ts)
export function Requirements(props) {
  const { header = fallbackHeader, subHeader, requirements } = props;

  return (
    <div data-testid={requirementsTestId}>
      <rs.Header>
        <TextRender text={header} component={rs.HeaderText} />
        {Boolean(subHeader) && <TextRender text={subHeader} component={rs.HeaderSubText} />}
      </rs.Header>

      {requirements.filter(item => !!item.id).map((item, idx) => (
        <RequirementsItem key={item.id} index={idx} {...item} />
      ))}
    </div>
  )
}
