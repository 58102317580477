import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import styled from 'styled-components';
import * as appSelectors from 'app/state/app/app-selectors';
import { DEFAULT_FORM_VALUE as emptyString, ONE } from 'common/constants';
import { evaluateCondition } from 'configurable-form/configurable-form-utils';
import { filesManager } from 'utils/files-manager';
import { SummarySubsection } from './summary-subsection';
import { useTranslations } from "utils/translations";

const Grid = styled.div`
  display: grid;
  grid-template-columns: ${(props) => `repeat(${props.columns}, 1fr)`};
  margin-bottom: 20px;
  gap: 20px;
`;

const getReactKeyProp = (() => {
  let ticker = ONE;
  const keyForReact = `summary-field-item-key`;

  function inner({ fileKey, formikKey, reduxKey }) {
    const key = fileKey || formikKey || reduxKey;
    if (key) return key;

    return `${keyForReact}-${ticker++}`;
  }

  return inner;
})();

function filterNilContent(el) {
  if (Array.isArray(el)) return el.some((item) => filterNilContent(item));
  return !isNil(el?.content) && el?.content !== emptyString;
}

export function SummarySection(props) {
  const { values } = useFormikContext();

  const appState = useSelector(appSelectors.appState);
  const translations = useTranslations();

  const getContent = useCallback(({ fileKey, formikKey, reduxKey, info, condition, valueMap, translationKeyPath, log }) => {
    // Never return data, when the condition is not met
    const evalExit = !evaluateCondition(values, condition);
    if (evalExit) return null;

    // Only retrieve data, when there is no hardcoded value provided ("info")
    if (info) return info;

    // Retrieve data from one of the keys present
    let retrieved;
    if (formikKey) retrieved = get(values, formikKey);
    if (reduxKey) retrieved = get(appState, reduxKey);
    if (fileKey) {
      const retrievedFiles = filesManager.retrieveListOfFilesFromStore(fileKey);
      retrieved = retrievedFiles.length ? retrievedFiles : null;
    }

    let combinedValueMap = valueMap;

    if (translationKeyPath) {
      const filteredTranslations = translationKeyPath ? get(translations, translationKeyPath, {}) : translations;

      const extractedTranslations = Object.keys(filteredTranslations).reduce((acc, translationKey) => {
        const translation = filteredTranslations[translationKey]?.default;
        if (!translation) return acc;

        return { ...acc, [translationKey]: translation }
      }, {});

      combinedValueMap = { ...combinedValueMap, ...extractedTranslations }
    }

    return get(combinedValueMap, retrieved, retrieved);
  }, [appState, translations, values]);

  const arrayPersonFormBuilder = useCallback((config) => {
    const { arrayKey, condition, data: personFieldsMapList, startingIndex = 0 } = config;

    const array = getContent({ formikKey: arrayKey, condition: condition }) || [];

    if (!Array.isArray(array))
      return [];

    // Generates a list of person for a type
    const allPossiblePersonsOfType = array.map((el, cIDX) =>
      /**
       * This generates the individual person.
       * "fieldMapping" looks like { label: 'First Name', key: 'firstName' } more details
       * can be found in "scripts/stats-src/types/fields/component-props/info-summary.ts".
       */
      personFieldsMapList.map((fieldMapping) => {
        if (!evaluateCondition(values, fieldMapping.condition)) return null;

        const content = get(el, fieldMapping.key);
        if (!content) return null;

        const formikKey = `${config.arrayKey}[${cIDX + (startingIndex)}].${fieldMapping.key}`;
        return { ...fieldMapping, content, key: getReactKeyProp({ formikKey }) }
      }).filter(filterNilContent) // This filters the individual details of the person
    );

    /**
     * The order here is important.
     *
     * In certain scenarios, the resulting list of "allPossiblePersonsOfType" can be contain
     * an empty object (this happens in the Apply flow in Customer Portal systems).
     *
     * If the order were "filter" then "slice", the resulting array would be of a length that
     * is shorter than expected as the data is going from:
     * [{}, {...}, {...}, {...}] to [{...}, {...}] if the "startingIndex" were "1".
     *
     * However, the expected result result is [{...}, {...}, {...}], which is achieved by
     * the invoking "slice" then "filter".
     */
    return allPossiblePersonsOfType.slice(startingIndex).filter(filterNilContent);
  }, [getContent, values]);

  const defaultBuilder = useCallback((config) => {
    const column = config.data
      .map((el) => ({ ...el, key: getReactKeyProp(el), content: getContent(el) }))
      .filter(filterNilContent);

    return column;
  }, [getContent]);

  const columnBuilder = useCallback((columnConfig) => {
    if (columnConfig.arrayKey) { // Special builder for ArrayPersonForm
      return arrayPersonFormBuilder(columnConfig);
    } else { // Default builder process
      return defaultBuilder(columnConfig);
    }
  }, [arrayPersonFormBuilder, defaultBuilder]);

  const config = useMemo(() => props.rowConfig.map(columnBuilder), [columnBuilder, props.rowConfig]);

  if (!config?.length) return null;

  return (
    <Grid columns={config.length}>
      {config.map((el, idx) =>
        <SummarySubsection
          key={`summary-row-${idx}`}
          label={props.rowConfig[idx].label}
          listLabel={props.rowConfig[idx].listLabel}

          // This is for "ArrayPersonForm" display in Customer Portal
          startingIndex={props.rowConfig[idx]?.startingIndex ?? 0}
          isGrid={props.rowConfig[idx].isGrid}
          config={el}
        />
      )}
    </Grid>
  );
}
