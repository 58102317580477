import { BoxShadow } from 'common/common-styles';
import React from 'react';
import styled from 'styled-components';

const Column = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
`;

const DatumP = styled.p`
  font-weight: ${(props) => (props.bold ? 'bold' : 'normal')};
  font-size: ${(props) => (props.bold ? '20px' : '16px')};
  text-align: center;
`;

export function HighlightedPermitData({ data, label }) {
  return (
    <Column>
      <DatumP bold>{data}</DatumP>
      <DatumP>{label}</DatumP>
    </Column>
  );
}

export const HighlightedDataSection = styled.div`
  ${BoxShadow}

  display: flex;
  flex-direction: row;
  justify-content: ${(props) => (props.fetchInflight ? 'center' : undefined)};

  gap: 40px;
  padding: 20px 50px;
`;
