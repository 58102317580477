import * as networkSelectors from 'app/state/network/network-selectors';
import concat from 'lodash/concat';
import dropRight from 'lodash/dropRight';
import last from 'lodash/last';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { GenericBaseModule } from './module/generic-base-module';

const SUCCESS_URL_KEY = 'surl';
const REDIRECT_URL_KEY = 'rurl';

function buildRedirectURL(flowPaths, targetPage) {
  const targetPath = last(targetPage.split('/'));
  return concat(window.location.origin, flowPaths, targetPath).join('/')
}

/**
 * Config:
 *
 * {
 *  "component": "PaymentForm"
 *    !REQUIRED
 *    Type - String
 *
 *  "id": String used for the React "key" prop
 *    !REQUIRED
 *    Type - String
 *
 *  "vendor" Config for payment form
 *    !REQUIRED
 *    Type - Object
 *
 *    {
 *      "provider": "hamer"
 *        !REQUIRED
 *        Type - String
 *
 *      "params": Config for Generic payment component
 *        !REQUIRED
 *        Type - Object
 *
 *        {
 *          "text": Text to be rendered
 *            !REQUIRED
 *            Type - String
 *
 *          "postPaymentSuccessPagePath": Redirect link for successful payment
 *            !REQUIRED
 *            Type - String
 *            e.g.
 *              /payment-success
 *              The forward slash is important
 *
 *          "postPaymentErrorPagePath": Redirect link for failed payment
 *            !REQUIRED
 *            Type - String
 *            e.g.
 *              /payment-error
 *              The forward slash is important
 *        }
 *    }
 * }
 */
export function Hamer(props) {
  const {
    strategyInstance,
    strategies,
    params: {
      text,
      postPaymentSuccessPagePath,
      postPaymentErrorPagePath,
    }
  } = props;

  const applicationNumber = useSelector(networkSelectors.applicationNumber);
  const miscInfo = useSelector(networkSelectors.miscInfo);

  const onClick = useCallback((paymentUrlFromServer) => {
    const url = new URL(paymentUrlFromServer);
    const searchParams = new URLSearchParams(url.searchParams);

    // We don't care about the last path as that should be the current page's path
    const flowPaths = dropRight(window.location.pathname.split('/').filter(Boolean), 1);

    const sURLParams = new URLSearchParams();
    // This is from the Single Permit view
    sURLParams.set('id', miscInfo?.permitNumber || applicationNumber);
    const sURL = buildRedirectURL(flowPaths, postPaymentSuccessPagePath) + `?${sURLParams.toString()}`;
    const rURL = buildRedirectURL(flowPaths, postPaymentErrorPagePath);

    // Replace the "sURL" and "rURL" params
    searchParams.set(SUCCESS_URL_KEY, encodeURI(sURL));
    searchParams.set(REDIRECT_URL_KEY, encodeURI(rURL));
    const transactionURL = url.origin + url.pathname + `?${searchParams.toString()}`;

    /**
     * Hardcode the instructions for the opening of the Hamer payment system
     *  as we'll most likely want to do the same for other places, if they
     *  happen to be using Hamer too
     */
    window.open(transactionURL, '_blank', 'toolbar=0,location=0,menubar=0');
    strategyInstance.executeStrategy(strategies, 'postWindowOpen');
  },
  [miscInfo?.permitNumber, applicationNumber, postPaymentSuccessPagePath, postPaymentErrorPagePath, strategyInstance, strategies]);

  return (
    <GenericBaseModule onClick={onClick} {...props}>
      <div>{text}</div>
    </GenericBaseModule>
  );
}
