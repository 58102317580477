import React, { useCallback, useMemo } from 'react';
import { useFormikContext } from 'formik';
import * as Sentry from '@sentry/react';
import { ZERO } from 'common/constants';
import { evaluateCondition } from 'configurable-form/configurable-form-utils';
import { parseCustomerConfig } from 'utils/utility-functions';
import { createYupSchema } from 'utils/yup-schema-creator';

/**
 * Reads the "isShownOrHidden" key of the config
 *
 * isShownOrHidden:
 *   Optional - dictionary
 *   Determines when a component is shown or hidden based on Formik values
 *
 *   defaultHidden:
 *     Optional - boolean
 *     Determines whether or not a component should be hidden by default
 *
 *   hideWhen:
 *     When all conditionals are true, this component will be hidden
 *     Refer to comments for evalParams function within "shared-components"
 *
 *   showWhen:
 *     When all conditionals are true, this component will be shown
 *     Refer to comments for evalParams function within "shared-components"
 */
export function withShowHideCapability(WrappedComponent) {
  return function ShowHideLogicWrapper(props) {
    const { isShownOrHidden, validation, ...other } = props;
    const { values } = useFormikContext();

    const formiKValues = useMemo(() => parseCustomerConfig(values), [values]);

    /**
     * Evaluation if the values stored in Formik state matches
     *    with any of the key-value pairs within the "hide" and "show"
     *    key within the "isShownOrHidden" object of the the config object
     *    to determine if the component should be "hidden" or "shown"
     *
     *  Note: params can be either an array or
     *     an array of arrays. ie [ 'propertyName', '!=', 'hello' ] OR
     *     [
     *       [ 'propertyName', '!=', 'hello' ],
     *       [ 'whoIsUnitRentedBy', '=', 'owner' ]
     *     ]
     */
    const evalParams = useCallback((params) => {
      if (params) {
        return Array.isArray(params[ZERO]) ?
          params.every((param) => evaluateCondition(formiKValues, param)) :
          evaluateCondition(formiKValues, params);
      } else {
        return Boolean(params);
      }
    }, [formiKValues]);

    const shouldHide = useMemo(
      () => evalParams(isShownOrHidden?.hideWhen),
      [evalParams, isShownOrHidden?.hideWhen]
    );

    const shouldShow = useMemo(
      () => evalParams(isShownOrHidden?.showWhen),
      [evalParams, isShownOrHidden?.showWhen]
    );

    const validate = useCallback(async (value) => {
      if (!validation) return;
      const schema = createYupSchema(validation);

      try {
        await schema.validate(value);
      } catch (err) {
        Sentry.captureException(err);
        return err.errors;
      }
    }, [validation]);

    /**
     * Check if the component should be hidden (not rendered at all):
     *    1. Check if the component should NOT be shown:
     *      a. Check value of "defaultHidden"
     *      b. Check the evaluation of "shouldHide"
     *    2. Check if the component should be shown:
     *      a. Check the negation of the evaluation of "shouldShow"
     */
    if ((isShownOrHidden?.defaultHidden || shouldHide) && !shouldShow) return null;

    // setHidden({ [other.name]: false });
    return <WrappedComponent {...other} validate={validate} />;
  }
}
