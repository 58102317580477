import React, { useMemo } from 'react';
import template from 'lodash/template';
import { useSelector } from 'react-redux';
import { orderBy } from 'lodash';
import { Radio, RadioGroup } from 'rsuite';
import styled from 'styled-components';
import * as appSelectors from 'app/state/app/app-selectors';
import * as payloadSelectors from 'app/state/payload/payload-selectors';
import { DEFAULT_FORM_VALUE } from 'common/constants';
import { SuccessInfo } from '../license-warnings-styles';

const StyledLi = styled.li`
  font-size: 14px;
  font-weight: bold;
  line-height: 1.29;
  color: #000000;
`;

const StyledDiv = styled(StyledLi).attrs({ as: 'div' })``;

const ResultInfo = styled.div`
  line-height: 1.5;
  color: #5c5d5d;
`;

const CustomRadio = styled(Radio)`
  && .rs-radio-inner {
    ::before {
      border-color: black;
      background-color: white;
    }
  }

  .rs-radio-inner::after {
    width: 6px;
    height: 6px;
    background: red;
    border-color: black;
    background-color: black;
    margin-top: 5px;
    margin-left: 5px;
    border-radius: 50%;
  }
`;

const ResultsWrapper = styled.div`
  margin-top: 40px;

  > div {
    margin-bottom: 20px;
  }
`;

function LicenseRadio({ licenseDisplayFormat, ...license }) {
  const {
    displayId,
    expirationDate,
    registrantName,
    propertyAddress,
    registrantEmail,
    unitNumber,
  } = license;

  const title = useMemo(() => {
    const titleTemplate = licenseDisplayFormat?.title;
    if (!titleTemplate) return displayId;

    return template(titleTemplate)(license);
  }, [displayId, license, licenseDisplayFormat?.title]);

  const descriptionLines = useMemo(() => {
    const descriptionTemplate = licenseDisplayFormat?.description;
    if (!descriptionTemplate) {
      return [
        registrantName,
        `${propertyAddress}${unitNumber ? ` - Unit: ${unitNumber}` : ''}`,
        registrantEmail,
        `Expires: ${expirationDate || ''}`,
      ]
    }

    return template(descriptionTemplate)(license).split("\n")
  }, [expirationDate, license, licenseDisplayFormat?.description, propertyAddress, registrantEmail, registrantName, unitNumber]);

  return (
    <CustomRadio key={displayId} value={displayId} data-cy="search-results-radio">
      <StyledDiv>{title}</StyledDiv>
      {descriptionLines.map((line, i) => <ResultInfo key={i}>{line}</ResultInfo>)}
    </CustomRadio>
  );
}

const fallbackMessage = 'We found the following result(s). Please select one:';

export function SearchResults({ licenses, licenseSelectedCB }) {
  const renderConfig = useSelector(appSelectors.renderConfig);

  const license = useSelector(payloadSelectors.license);

  const sortedLicense = useMemo(() => orderBy(licenses, ['expirationDate', 'applicationNumber'], ['desc', 'asc']), [licenses]);

  const licenseDisplayFormat = renderConfig?.search?.licenseDisplayFormat;

  return (
    <ResultsWrapper>
      {Boolean(licenses?.length) && (
        <SuccessInfo>
          {renderConfig?.search?.resultsMessage || fallbackMessage}
        </SuccessInfo>
      )}

      {/* If no license is selected, then set value to empty string so that no radio button is checked */}
      <RadioGroup name="licenses" onChange={licenseSelectedCB} value={license?.displayId ?? DEFAULT_FORM_VALUE}>
        {sortedLicense?.map((license) => <LicenseRadio key={license.displayId} licenseDisplayFormat={licenseDisplayFormat} {...license} />)}
      </RadioGroup>
    </ResultsWrapper>
  );
}
