/* eslint-disable no-template-curly-in-string */
import { withVisibility } from "./utils/with-visibility";
import * as cs from "../../common/common-styles";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import styled from 'styled-components';
import { AutoComplete, Button, Loader } from "rsuite";
import { useField } from "formik";
import { strRegistrationApiSender } from "../../utils/str-registration-api-sender";
import { parseHTMLString } from "../../utils/utility-functions";
import template from "lodash/template";
import * as yup from 'utils/yup';
import debounce from "lodash/debounce";

const AddItemInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const ListSubheader = styled.p`
  font-weight: bold;
  margin-bottom: 20px;
`;

const ListItemContainer = styled.div`
  border: 1px solid grey;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ListItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
function ListItem({ item, isLoading, itemId, onRemove, template: templateString, invalidItemErrorTemplate }) {
  const templateToUse = templateString || item;

  const content = useMemo(() => {
    if (!item) {
      if (isLoading) {
        return <>Loading...</>

      }
      return <cs.ErrorMessage>{parseHTMLString(template(invalidItemErrorTemplate)({ itemId }))}</cs.ErrorMessage>;
    }
    return parseHTMLString(template(templateToUse)(item || {}))
  }, [invalidItemErrorTemplate, isLoading, item, itemId, templateToUse]);

  return (
    <ListItemContainer>
      <p>{content}</p>
      <Button
        onClick={onRemove}
        appearance={"link"}
      >
        Remove
      </Button>
    </ListItemContainer>

  )
}

function getField(item, field) {
  return !field ? item : item?.[field];
}

function ItemPickerAutocomplete({
  suggestionQueryEndpoint,
  suggestionQueryEndpointResponseKey,
  itemDisplayTemplate,
  idKey,
  onPickItem,
  excludedItemIds ,
  itemNotFoundErrorTemplate
}) {
  const [suggestions, setSuggestions] = useState([]);
  const [textInput, setTextInput] = useState("");
  const currentlyPickedItemId = useRef();
  const [notFoundError, setNotFoundError] = useState(false);
  const [unknownError, setUnknownError] = useState(false);
  const [loading, setLoading] = useState(false);

  const lookup = useCallback(async (textInput) => {
    try {
      setUnknownError(false);
      setLoading(true);
      const { data: response } = await strRegistrationApiSender.get(suggestionQueryEndpoint + textInput);
      const results = !suggestionQueryEndpointResponseKey ? response : response[suggestionQueryEndpointResponseKey]

      setNotFoundError(!results?.length)

      setSuggestions(results
        .filter(result => !excludedItemIds.includes(result[idKey]))
        .map(result => ({
          value: result[idKey], label: template(itemDisplayTemplate)(result)
        })));
    }
    catch(e) {
      setUnknownError(true);
    }
    finally {
      setLoading(false);
    }
  }, [excludedItemIds, idKey, itemDisplayTemplate, suggestionQueryEndpoint, suggestionQueryEndpointResponseKey]);

  const debouncedLookup = useMemo(() => debounce(lookup, 300), [lookup]);

  const onSearchSuggestions = useCallback(async (textInput) => {
    setNotFoundError(false);
    if (textInput === currentlyPickedItemId.current) {
      currentlyPickedItemId.current = null;
      setTextInput("");
    } else {
      setTextInput(textInput)
    }
    if (textInput.length < 9) {
      setSuggestions([]);
      return;
    }

    debouncedLookup(textInput);
  }, [debouncedLookup]);

  const notFoundErrorContent = useMemo(() => {
    if (!notFoundError) return null;

    return template(itemNotFoundErrorTemplate)({ itemKeyword: textInput });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textInput, notFoundError]);

  return (
    <>
    <AutoComplete
      data={suggestions}
      filterBy={() => true}
      onChange={textInput => onSearchSuggestions(textInput)}
      onSelect={item => {
        const pickedValue = item.value;
        currentlyPickedItemId.current = pickedValue;
        onPickItem(pickedValue);
      }}
      renderItem={item => parseHTMLString(item.label)}
      value={textInput}
    />
    {notFoundErrorContent && <cs.ErrorMessage>{notFoundErrorContent}</cs.ErrorMessage>}
    {unknownError && <cs.ErrorMessage>An error has occurred. Please try again later.</cs.ErrorMessage>}
    {loading && <Loader />}
    </>
  )
}
function makeValidator(schema) {
  return async (value) => {
    try {
      await schema.validate(value);
    } catch (err) {
      return err.errors;
    }
  }
}
function ListEditorComponent({
  inputLabel, addButtonLabel, listSubheader, listStorageKey,
  additionalInformationEndpoint, additionalInformationResponseKey, idField,
  additionalInformationTemplate, additionalInformationFormikOutputPath,
  newItemLookupEndpoint, newItemLookupEndpointResponseKey,
  invalidItemErrorTemplate = 'Invalid item with ID ${itemId}. Please remove it before proceeding',
  itemNotFoundErrorTemplate = 'Unable to find item ${itemKeyword}.'
}) {
  const validate = makeValidator(yup.array().of(yup.string()));

  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField({ name: listStorageKey, validate });

  // eslint-disable-next-line no-unused-vars
  const [additionalInfoField, additionalInfoMeta, additionalInfoHelpers] = useField({
    name: additionalInformationFormikOutputPath,
    validate: makeValidator(yup.array().of(yup.object().shape({
      item: yup.object().required(),
      itemId: yup.string(),
  })))
  })
  const list = useMemo(() => field.value || [], [field.value]);

  useEffect(() => {
    setLoadingItems(list.reduce((acc, k) => ({ ...acc, [k]: true }), {}));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [loadingItems, setLoadingItems] = useState({});

  const addItem = useCallback((newListItem) => {
    helpers.setValue([...(list || []), newListItem], false);
    setLoadingItems(items => ({ ...items, [newListItem]: true }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list, listStorageKey]);

  const removeItem = useCallback((item) => {
    helpers.setValue(list.filter(otherItem => otherItem !== item), false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list, listStorageKey]);

  const [listWithAdditionalInfo, setListWithAdditionalInfo] = useState([]);
  const [additionalInfoByItemKey, setAdditionalInfoByItemKey] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function getListWithAdditionalInfo() {
      if (!additionalInformationEndpoint || !list?.length) return list;
      setLoading(true);

      try {
        const { data: response } = await strRegistrationApiSender.get(additionalInformationEndpoint + list.join(","))
        if (!additionalInformationResponseKey) return response;
        return response[additionalInformationResponseKey];
      }
      finally {
        setLoading(false);
      }
    }

    (async () => {
      const newList = await getListWithAdditionalInfo();
      const newAdditionalInfoByKey = ((newList || []).reduce((acc, x) => ({ ...acc, [getField(x, idField)]: x }), {}));

      console.log('reset loading items')
      setLoadingItems(currentLoadingItems => {
        const newLoadingItems = { ...currentLoadingItems };
        list.forEach(key => {
          console.log('delete', key)
          delete newLoadingItems[key];
        });

        console.log(newLoadingItems)
        return newLoadingItems;
      })
      setAdditionalInfoByItemKey(newAdditionalInfoByKey);
    })();
  }, [list, additionalInformationEndpoint, additionalInformationResponseKey, idField]);

  useEffect(() => {
    const newList = list?.map(item => ({ item: additionalInfoByItemKey[item], itemId: item })) || [];
    setListWithAdditionalInfo(newList);
    if (additionalInformationFormikOutputPath) {
      setImmediate(() => additionalInfoHelpers.setValue(
        !newList.length ? undefined : newList,
        true
      ));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [additionalInfoByItemKey, list, idField, additionalInformationFormikOutputPath]);


  return (
    <div>
      {inputLabel}
      <AddItemInputContainer>
        <ItemPickerAutocomplete
          idKey={idField}
          onPickItem={pickedItemId => addItem(pickedItemId)}
          itemDisplayTemplate={additionalInformationTemplate}
          suggestionQueryEndpoint={newItemLookupEndpoint}
          suggestionQueryEndpointResponseKey={newItemLookupEndpointResponseKey}
          excludedItemIds={list}
          itemNotFoundErrorTemplate={itemNotFoundErrorTemplate}
        />
        {loading && <Loader />}
        {/*<Button onClick={addItem} loading={loading}>+ {addButtonLabel || 'Add'}</Button>*/}
      </AddItemInputContainer>

      <hr />

      <ListSubheader>{listSubheader}</ListSubheader>

      <ListItems>
        {listWithAdditionalInfo?.map(({ item, itemId, isLoading }) => (
          <ListItem
            key={itemId}
            itemId={itemId}
            isLoading={loadingItems[itemId]}
            onRemove={() => removeItem(itemId)}
            item={item}
            template={additionalInformationTemplate}
            invalidItemErrorTemplate={invalidItemErrorTemplate}
          />
        ))}
      </ListItems>

    </div>
  )
}

export const ListEditor = withVisibility(ListEditorComponent);
