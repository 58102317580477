import CircularProgress from '@material-ui/core/CircularProgress';
import * as appSelectors from 'app/state/app/app-selectors';
import { DASHBOARD_SUCCESS_STATUS } from 'app/state/dashboard/constants';
import * as dashboardSelectors from 'app/state/dashboard/dashboard-selectors';
import {
  DISABLE_BULK_RENEWAL_FLOW_PATH,
  // DISABLE_SINGLE_RENEW_FLOW_PATH,
  PERMIT_VERBIAGE,
  PERMIT_VERBIAGE_TRANSLATION_KEY,
} from 'common/constants';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import pluralize from 'pluralize';
import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { FLOW_PATHS } from 'routes/constants';
import { history } from 'routes/history';
import { useTranslations } from 'utils/translations';

import { TableActionButton } from './styles';

export function RenewAction(props) {
  const { loading, setLoading } = props;

  const translations = useTranslations();

  const label =
    translations[PERMIT_VERBIAGE_TRANSLATION_KEY]?.default ?? PERMIT_VERBIAGE;

  // const disableSingleRenewalFlowFlag = useSelector(
  //   appSelectors.getFromPortalStats(DISABLE_SINGLE_RENEW_FLOW_PATH),
  // );

  const disableBulkRenewalFlow = useSelector(
    appSelectors.getFromPortalStats(DISABLE_BULK_RENEWAL_FLOW_PATH),
  );

  const { status: dataFetchStatus, data: bulkRenewableLicenses } = useSelector(
    dashboardSelectors.bulkRenewableLicenses,
  );

  const enterBulkRenewalFlow = useCallback(async () => {
    setLoading(true);
    history.push(FLOW_PATHS.bulkRenewal);
  }, [setLoading]);

  const onClick = useCallback(async () => {
    return await enterBulkRenewalFlow();
  }, [enterBulkRenewalFlow]);

  const disableRenewalsViaPermitsListView = useMemo(
    () =>
      disableBulkRenewalFlow ||
      dataFetchStatus !== DASHBOARD_SUCCESS_STATUS ||
      isNil(bulkRenewableLicenses) ||
      isEmpty(bulkRenewableLicenses),
    [bulkRenewableLicenses, dataFetchStatus, disableBulkRenewalFlow],
  );

  if (disableRenewalsViaPermitsListView) return null;

  return (
    <TableActionButton disabled={loading} onClick={onClick}>
      {loading ? <CircularProgress size={20} /> : `Renew ${pluralize(label)}`}
    </TableActionButton>
  );
}
