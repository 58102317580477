const MIN_CHARS_BEFORE_QUERY = 4;

export function shouldQuery(e) {
  return (
    e.type === 'change' &&
    e?.target?.value?.length >= MIN_CHARS_BEFORE_QUERY
  );
}

export function formatErrorMessage(errorMessage, append) {
  if (!errorMessage) return;
  return errorMessage + (append ? ' ' + append : '');
}
