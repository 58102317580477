import appSettings from '../app-settings.json';
import axios from 'axios';
import { payloadValuesTrimmer } from './utility-functions';
import { domainlessPortalPlaceName, isDomainlessPortal } from "../routes/history";
import { MAX_DIGIFY_UPLOAD_RETRY_ATTEMPTS } from 'common/constants';
import * as Sentry from '@sentry/react';

const statsServer = 'http://localhost:4000/stats';
const postmanMock = 'https://5e53c370-6c71-4026-bf8d-e56e439a3657.mock.pstmn.io'
const dataKey = 'data';

const axiosConfig = {
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  timeout: 180000, // https://medium.com/@masnun/handling-timeout-in-axios-479269d83c68
};

export class StrRegistrationAPISender {
  static place = null;

  setAdminFlowMode(adminFlowMode) {
    this.adminFlowMode = adminFlowMode;
  }

  constructor() {
    this.axiosInstance = axios.create({
      baseURL: appSettings.strRegistrationApiDomain,
      ...axiosConfig,
    });
  }

  setCurrentPlace(place) {
    StrRegistrationAPISender.place = place
  }

  getCityPath(path) {
    if (!StrRegistrationAPISender.place) {
      throw new Error('Place name not known in this get request');
    }
    const pathPrefix = this.adminFlowMode ? "/rentalscape" : "";
    return `/${StrRegistrationAPISender.place}${pathPrefix}${path}`;
  }

  /**
   * Get request with no place in url
   *
   * @param {*} path
   * @param {*} params
   * @param {*} responseType
   */
  async getStats(originUrl) {
    let stats;

    const baseUrl = appSettings.strRegistrationApiDomain;

    if (process.env.REACT_APP_LOCAL_STATS) {
      const statsObject = process.env.REACT_APP_LOCAL_STATS_OBJECT;
      stats = (await axios.get(`${statsServer}/${statsObject}`))[dataKey];
    } else if (isDomainlessPortal) {
      stats = (await axios.get(`${baseUrl}/stats?place=${domainlessPortalPlaceName}`, { ...axiosConfig }))[dataKey];
    } else {
      stats = (await axios.get(`${baseUrl}/stats?originUrl=${originUrl}`, { ...axiosConfig }))[dataKey];
    }

    this.setCurrentPlace(stats?.cityInfo?.place);
    return stats;
  }

  async rawGet(path) {
    const pathWithPlace = this.getCityPath(path);
    return axios.get(`${postmanMock}${pathWithPlace}`);
  }

  async rawPost(path) {
    const pathWithPlace = this.getCityPath(path);
    return axios.post(`${postmanMock}${pathWithPlace}`);
  }

  /**
   * Get request with place in url
   *
   * @param {*} path
   * @param {*} params
   * @param {*} responseType
   */
  async get(path, params, responseType = 'json') {
    const pathWithPlace = this.getCityPath(path);
    const options = payloadValuesTrimmer({ params, responseType });
    return this.axiosInstance.get(pathWithPlace.trim(), options);
  }

  /**
   *
   * @param {*} path
   * @param {*} params other request params.. For query params, pass { queryParams }. If
   *   { validateStatus: false } is passed, 4xx statuses do not throw errors
   * @returns
   */
  async getV2(path, params = {}) {
    const pathWithPlace = this.getCityPath(path);
    const { queryParams, ...otherParams } = params;
    const trimmedQueryParams = payloadValuesTrimmer({ queryParams });
    const options = { params: trimmedQueryParams?.queryParams, ...otherParams };
    return this.axiosInstance.get(pathWithPlace.trim(), options);
  }

  // Get request without the place in the url
  async getNoPlace(path, params, responseType = 'json') {
    const options = payloadValuesTrimmer({ params, responseType });
    return this.axiosInstance.get(path.trim(), options);
  }

  async post(path, body, responseType = '') {
    const bodyJSON = (body && body.toJSON) ? body.toJSON() : body;
    const pathWithPlace = this.getCityPath(path);
    const options = payloadValuesTrimmer({ responseType });
    return this.axiosInstance.post(pathWithPlace, bodyJSON, options);
  }

  async upload({ uploadToken, file }) {
    const uploadUrl = appSettings.digifyApiDomain;

    let retryCount = 0;
    let latestError;

    while (retryCount < MAX_DIGIFY_UPLOAD_RETRY_ATTEMPTS) {
      try {
        const response = await axios.post(`${uploadUrl}/upload/`, file, {
          params: { upload_token: uploadToken.uploadToken },
          headers: { 'Content-Type': file.type }
        })

        return response;
      }catch(e){
        Sentry.captureException(e);
        retryCount++;
        latestError = e;
      }
    }

    throw latestError;
  }
}

export const strRegistrationApiSender = new StrRegistrationAPISender();
