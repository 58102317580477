import MenuItem from '@material-ui/core/MenuItem';
import { LAST_12_MONTHS_OPTION } from 'common/constants';
import { ReportingHistorySelect } from 'common/portal/styles';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import orderBy from 'lodash/orderBy';
import uniq from 'lodash/uniq';
import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import { SPRHHeader } from './styles';

const ReportingHistoryFilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

/**
 * This component is only used in "SinglePermitReportingHistory", so the assumption going forward
 * is that "ReportingHistoryFilter" is only interested in the first license in the data as
 * "reportingHistoryByApplicationNumber" API should always return only a single license.
 */
export function ReportingHistoryFilter(props) {
  const paymentYearDropdownOptions = useMemo(() => {
    if (!props.data) return [];

    return orderBy(
      uniq(props.data.map(({ startDate }) => dayjs(startDate).year())),
      (e) => e,
      ['desc'],
    );
  }, [props.data]);

  const handleChange = useCallback(
    (e) => props.setSelectedYearOption(e.target.value),
    [props],
  );

  return (
    <ReportingHistoryFilterContainer>
      <SPRHHeader>Reporting History</SPRHHeader>

      {!isEmpty(props.data) && (
        <ReportingHistorySelect
          value={props.selectedYearOption}
          onChange={handleChange}
        >
          <MenuItem value={LAST_12_MONTHS_OPTION}>Last 12 months</MenuItem>
          {paymentYearDropdownOptions.map((year) => (
            <MenuItem key={year} value={year}>
              {year}
            </MenuItem>
          ))}
        </ReportingHistorySelect>
      )}
    </ReportingHistoryFilterContainer>
  );
}
