import React, { useMemo } from 'react';
import { FormControl, MenuItem, Select } from '@material-ui/core';
import { useFormikContext } from 'formik';
import get from 'lodash/get';
import * as cs from 'common/common-styles';
import { DEFAULT_FORM_VALUE as emptyString } from 'common/constants';
import { parseHTMLString } from 'utils/utility-functions';
import { FormikError } from './inputs/shared-components';
import { useTextField } from './use-text-field';
import { useInput } from './utils/use-input';
import { withVisibility } from './utils/with-visibility';

function DropdownComponent(props) {
  const { field: formikField, meta } = useInput(props);
  const { values } = useFormikContext();
  const { label, options, additionalCSS, emptyOptionLabel, dataCy, textFieldName } = props;

  const preparedOptions = useMemo(() => options.map(opt => {
    const labelHTML = opt.label || get(values, opt.labelKey, undefined);
    return ({
      ...opt,
      labelHTML,
      label: parseHTMLString(labelHTML)
    });
  }),[options, values]);

  const preparedEmptyOptionLabel = useMemo(() => parseHTMLString(emptyOptionLabel), [emptyOptionLabel]);

  const field = useTextField(formikField, textFieldName, preparedOptions);

  return (
    <cs.InputSection css={additionalCSS}>
      {label && <cs.InputTextFieldLabel>{label}</cs.InputTextFieldLabel>}
      <FormControl variant="outlined">
        <Select
          data-cy={dataCy}
          css={props.additionalCSSSelect || props.additionalCSS}
          aria-label={props.ariaLabel}
          displayEmpty
          /**
           * Spread "field" before "value" prop or else React will complain about the component
           * switching from an uncontrolled component to a controlled component
           */
          {...field}
          value={field.value || emptyString}
        >
          {preparedEmptyOptionLabel && <MenuItem disabled value="">{preparedEmptyOptionLabel}</MenuItem>}
          {preparedOptions.map(({ id, name, label, dataCy }) => (
            <MenuItem data-cy={dataCy} key={id} value={name}>{label}</MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormikError {...meta} />
    </cs.InputSection>
  );
}

export const Dropdown = withVisibility(DropdownComponent)
