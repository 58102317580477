import React, { useCallback } from 'react';
import { useField } from 'formik';
import { DaysAvailableInput } from '../input-fields/days-available-input';
import { DaysOccupiedInput } from '../input-fields/days-occupied-input';
import { TaxableReceiptsInput } from '../input-fields/taxable-receipts-input';
import { CurrencyField } from './fields/currency-field';
import { DerivedCurrencyField } from './fields/derived-currency-field';
import { NumberField } from './fields/number-field';
import { TextField } from './fields/text-field';
import { Centered, Grid, Grid2Column, GRID2COLUMN_ROW_GAP_PX } from './styles';
import { extendFormikPath, isCoreColumn } from "../../utils";
import { CoreReportingFields } from "../../constants";
import { ConstantField } from "./fields/constant-field";
import styled from "styled-components";
import { parseHTMLString } from 'utils/utility-functions';

const FIELD_MAP = {
  constant: ConstantField,
  text: TextField,
  number: NumberField,
  currency: CurrencyField,
  derivedCurrency: DerivedCurrencyField,
  react: ConstantField,
};

export function TOTTaxableActivity({
  taxableActivityPath,
  daysInPeriod,
  reportingColumns,
  periodName,
  useVerticalLayout,
}) {
  const [numDaysAvailableField] = useField(extendFormikPath(taxableActivityPath, CoreReportingFields.NUM_DAYS_AVAILABLE_FIELD));
  const numDaysAvailable = numDaysAvailableField.value;

  const coreColumnFieldToInput = {
    [CoreReportingFields.TAXABLE_RECEIPTS_FIELD]: (
      <TaxableReceiptsInput
        fieldKey={extendFormikPath(taxableActivityPath, CoreReportingFields.TAXABLE_RECEIPTS_FIELD)}
      />
    ),
    [CoreReportingFields.NUM_DAYS_AVAILABLE_FIELD]: (
      <DaysAvailableInput
        fieldKey={extendFormikPath(taxableActivityPath, CoreReportingFields.NUM_DAYS_AVAILABLE_FIELD)}
        daysInPeriod={daysInPeriod}
      />
    ),
    [CoreReportingFields.NUM_DAYS_OCCUPIED_FIELD]: (
      <DaysOccupiedInput
        fieldKey={extendFormikPath(taxableActivityPath, CoreReportingFields.NUM_DAYS_OCCUPIED_FIELD)}
        daysInPeriod={daysInPeriod}
        numDaysAvailable={numDaysAvailable}
      />
    ),
  }

  const renderAdditionalColumn = useCallback((col) => {
    const Component = FIELD_MAP?.[col?.type] || FIELD_MAP.text;
    const maxValueKey = col?.maxValueKey && extendFormikPath(taxableActivityPath, col?.maxValueKey)

    return (
      <Component
        additionalReportingColumn={col}
        fieldKey={extendFormikPath(taxableActivityPath, col.field)}
        maxValueKey={maxValueKey}
        minuendKey={extendFormikPath(taxableActivityPath, col?.valueDerivationStrategy?.minuendKey)}
        subtrahendKey={extendFormikPath(taxableActivityPath, col?.valueDerivationStrategy?.subtrahendKey)}
        daysInPeriod={daysInPeriod}
      />
    );
  }, [daysInPeriod, taxableActivityPath]);

  const Layout = useVerticalLayout ? Grid2Column : Grid;

  return (
    <Layout>
      {reportingColumns.map((col, idx) => (
        <React.Fragment key={idx}>
          {useVerticalLayout && <DynamicPaymentPeriodLabel el={col} periodName={periodName} />}
          {isCoreColumn(col) ? coreColumnFieldToInput[col.field] : renderAdditionalColumn(col)}
          {useVerticalLayout && col?.additionalInformation && (
            <AdditionalInformation>{col.additionalInformation}</AdditionalInformation>
          )}
        </React.Fragment>
      ))}
    </Layout>
  );
}

function DynamicPaymentPeriodLabel({ el, periodName }){
  if (!el?.label) return null;
  if (!el.labelAppendValue) return <Centered>{parseHTMLString(el.label)}</Centered>;

  return <Centered><b>{parseHTMLString(el.label)} {periodName}</b></Centered>;
}

const AdditionalInformationContainer = styled.div`
  grid-column: 1 / span 2;
  font-style: italic;
  margin-top: ${-GRID2COLUMN_ROW_GAP_PX + 5}px;
`;

function AdditionalInformation({ children }) {
  return (
    <AdditionalInformationContainer>
      {children}
    </AdditionalInformationContainer>
  );
}
