import * as appSelectors from 'app/state/app/app-selectors';
import * as loginSelectors from 'app/state/login/login-selectors';
import { loginActions } from 'app/state/login/login-slice';
import * as payloadSelectors from 'app/state/payload/payload-selectors';
import { payloadActions } from 'app/state/payload/payload-slice';
import { Auth } from 'aws-amplify';
import { DEFAULT_FORM_VALUE, FLOWS, loginPhases } from 'common/constants';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { Alert } from 'rsuite';
import { Button, Image, Modal } from 'semantic-ui-react';
import { strategyFactory } from 'strategies/strategy-utils';
import { currentAuthenticatedUser } from 'utils/auth-functions';
import { userInputTrimmer } from 'utils/utility-functions';

import { appActions } from '../../app/state/app/app-slice';
import { authSender } from '../../utils/auth-sender';
import { CodeEntry } from './modules/code-entry';
import { EmailEntry } from './modules/email-entry';

const authErrors = {
  timeout: 'Invalid session for the user.',
  invalidEntry: 'Incorrect username or password.',
};

const loginError =
  'You have exceeded the maximum number of login attempts. Please try again.';
const verifyError =
  'You have exceeded the maximum number of verification attempts. Please try again.';
const timeoutError = 'Your session has expired. Please try again.';
const resendCodeError = 'Failed to resend code. Please try again';

export function CognitoAuthModal() {
  const dispatch = useDispatch();
  const cognitoUserRef = useRef();

  const isOpen = useSelector(loginSelectors.openLoginModal);
  const loginPhase = useSelector(loginSelectors.loginPhase);

  const place = useSelector(appSelectors.place);
  const license = useSelector(payloadSelectors.license);
  const activeFlow = useSelector(appSelectors.activeFlow);

  // Email specifically for the email entered in input in update flow
  const lookupModeEmail = useSelector(payloadSelectors.lookupModeEmail);
  // Cognito username
  const loggedInUsername = useSelector(loginSelectors.loggedInUsername);
  const renderConfig = useSelector(appSelectors.renderConfig);

  // Email for the login modal email entry component
  const userEmailRedux = useSelector(loginSelectors.userEmail);
  const isMultiPropertyTOTEnabled = useSelector(
    appSelectors.isMultiPropertyTOTEnabled,
  );
  const [emailValue, setEmailValue] = useState(DEFAULT_FORM_VALUE);

  const [isLoading, setIsLoading] = useState(false);

  const [codeValue, setCodeValue] = useState(DEFAULT_FORM_VALUE);

  const [codeInvalid, setCodeInvalid] = useState(false);
  const [emailInvalid, setEmailInvalid] = useState(false);

  const extractUsername = useMemo(
    () => `${place}::${license?.applicationNumber}`,
    [license?.applicationNumber, place],
  );

  const strategies = renderConfig?.login?.strategies;

  const strategyInstance = useMemo(() => {
    if (!activeFlow) return null;
    return strategyFactory(activeFlow);
  }, [activeFlow]);

  // Determine the login phase based on whether lookupModeEmail is set or not
  const determineLoginPhase = useCallback(() => {
    if (lookupModeEmail) {
      dispatch(loginActions.setLoginPhase(loginPhases.CODE_ENTRY));
    } else {
      dispatch(loginActions.setLoginPhase(loginPhases.EMAIL_ENTRY));
    }
  }, [dispatch, lookupModeEmail]);

  useEffect(() => {
    determineLoginPhase();
  }, [determineLoginPhase]);

  // This reset function does not set the login phase back to the email entry
  const resetModal = useCallback(() => {
    // Close the modal
    dispatch(loginActions.setOpenLoginModal(false));

    // Clear the cognito user ref
    cognitoUserRef.current = null;

    // Clear input values
    setEmailValue(DEFAULT_FORM_VALUE);
    setCodeValue(DEFAULT_FORM_VALUE);

    // Clear errors
    setEmailInvalid(false);
    setCodeInvalid(false);

    // If the modal is reset, (which can happen when the user closes the modal)
    // then set the login phase based on whether lookupModeEmail exists
    determineLoginPhase();
  }, [determineLoginPhase, dispatch]);

  const continueDisabled = useMemo(() => {
    const watchedInput =
      loginPhase === loginPhases.EMAIL_ENTRY ? emailValue : codeValue;
    return !watchedInput;
  }, [emailValue, loginPhase, codeValue]);

  const handleModalClose = useCallback(() => {
    if (isLoading) return;
    dispatch(loginActions.setOpenLoginModal(false));
    resetModal(); // we don't always want to set login phase back to email entry
  }, [dispatch, isLoading, resetModal]);

  const handleError = useCallback(
    (errorMsg) => {
      Alert.error(errorMsg);
      handleModalClose();
    },
    [handleModalClose],
  );

  /**
   * Will return one of the three in the following order:
   *   1. lookupModeEmail (from the update flow)
   *   2. emailValue (from the email entry component)
   *   2. userEmailRedux (the last stored version of emailValue)
   */
  const determineEmail = useMemo(() => {
    const email = lookupModeEmail || emailValue || userEmailRedux;
    return email?.trim();
  }, [emailValue, lookupModeEmail, userEmailRedux]);

  const userSignIn = useCallback(async () => {
    const username = extractUsername;
    cognitoUserRef.current = await Auth.signIn(username);
    cognitoUserRef.current = await Auth.sendCustomChallengeAnswer(
      cognitoUserRef.current,
      determineEmail.toLowerCase(),
    );
  }, [extractUsername, determineEmail]);

  // Initiates the login process
  const onEmailSubmittedCB = useCallback(async () => {
    setIsLoading(true);
    try {
      setEmailValue((emailVal) => emailVal.trim());
      await userSignIn();

      setIsLoading(false);
      if (
        cognitoUserRef.current.challengeParam.type === 'SECRET_CODE_CHALLENGE'
      ) {
        batch(() => {
          dispatch(loginActions.setLoginPhase(loginPhases.CODE_ENTRY));
          if (emailValue)
            dispatch(loginActions.setUserEmail(emailValue?.trim()));
        });
      } else {
        throw new Error('Invalid code');
      }
    } catch (err) {
      setIsLoading(false);
      if (err.message === authErrors.invalidEntry) {
        handleError(loginError);
      } else {
        /**
         * Only turn on the invalid email error message, when we are not closing the login modal
         * due to the user exceeding the maximum number of login attempts
         */
        setEmailInvalid(true);
      }
    }
  }, [userSignIn, dispatch, emailValue, handleError]);

  // Callback used after the user enters the login verification code
  const onCodeSubmittedCB = useCallback(async () => {
    setIsLoading(true);

    try {
      cognitoUserRef.current = await Auth.sendCustomChallengeAnswer(
        cognitoUserRef.current,
        codeValue,
      );

      if (cognitoUserRef.current.authenticationFlowType === 'USER_SRP_AUTH') {
        batch(() => {
          dispatch(payloadActions.setLookupModeEmail(lookupModeEmail));
          dispatch(
            loginActions.setLoggedInUsername({
              username: cognitoUserRef.current.username,
            }),
          );
          dispatch(loginActions.setOpenLoginModal(false));
        });

        setIsLoading(false);
        if (activeFlow === FLOWS.PAY_TOT) {
          if (isMultiPropertyTOTEnabled) {
            const allLicensesResponse = await authSender.get('/licenses');
            const allLicenses = allLicensesResponse?.data?.licenses;
            dispatch(payloadActions.setLicenses(allLicenses || []));
            dispatch(
              appActions.setDynamicQuarterSelectedPeriodType(
                allLicenses[0]?.totPaymentPeriod,
              ),
            );
          } else {
            dispatch(
              appActions.setDynamicQuarterSelectedPeriodType(
                license?.totPaymentPeriod,
              ),
            );
          }
        }
        strategyInstance.executeStrategy(strategies, 'onContinueClick');
        resetModal();
      } else {
        throw new Error('Bad login code'); // wrong code
      }
    } catch (err) {
      setIsLoading(false);
      if (err.message === authErrors.invalidEntry) {
        handleError(verifyError);
      } else if (err.message === authErrors.timeout) {
        handleError(timeoutError);
      } else {
        /**
         * Only turn on the invalid email error message, when we are not closing the login modal
         * due to the user exceeding the maximum number of login attempts
         */
        setCodeInvalid(true);
      }
    }
  }, [
    activeFlow,
    codeValue,
    dispatch,
    handleError,
    isMultiPropertyTOTEnabled,
    lookupModeEmail,
    resetModal,
    strategies,
    strategyInstance,
    license,
  ]);

  const continueButtonOnClickCB = useCallback(async () => {
    if (loginPhase === loginPhases.EMAIL_ENTRY) await onEmailSubmittedCB();
    else await onCodeSubmittedCB();
  }, [loginPhase, onEmailSubmittedCB, onCodeSubmittedCB]);

  // Basically, call Auth.signOut() and then start the signUp/signIn sequence again.
  const resendConfirmationCode = async () => {
    setIsLoading(true);
    try {
      await Auth.signOut();
    } catch (error) {
      // console.error('error signing out: ', error);
    }
    try {
      await userSignIn();
      setIsLoading(false);
    } catch (err) {
      handleError(resendCodeError);
      setIsLoading(false);
      // console.error('error resending code: ', err);
    }
  };

  // if there is already an email entered in the lookup email mode, then use that email to log user and go directly to code entry view
  useEffect(() => {
    async function tryLogin() {
      const username = extractUsername;
      const usernameFromCognito = await currentAuthenticatedUser();
      /**
       * If an email has already been entered in the email view of the login page AND
       *    (either the user HAS logged in and the username doesn't match the previously logged in username OR
       *    the user has not logged in before), then use that email to log the user
       *    in and go directly to the code entry view
       *
       *    Note: username !== usernameFromCognito is necessary for when the user has logged in and tries to use a different license
       */
      if (
        lookupModeEmail &&
        (username !== usernameFromCognito || !loggedInUsername)
      )
        await onEmailSubmittedCB();
    }

    isOpen && tryLogin();
    /**
     * We need to have the eslint warning ignore, because if we include "onEmailSubmittedCB"
     *    then this useEffect hook will be triggered infinitely
     */
  }, [isOpen, lookupModeEmail]); // eslint-disable-line react-hooks/exhaustive-deps

  // Try to skip the login, if user has logged in
  useEffect(() => {
    async function trySkipLogin() {
      const username = extractUsername;
      const usernameFromCognito = await currentAuthenticatedUser();

      /**
       * Need to validate that the username matches, so we can skip the login
       * Also ensure loggedInUsername is not empty to check that this is not the first time the user is logging in
       * Otherwise, when the user first logs in, the usernameFromCognito might already be stored and we will be redirected home
       */
      if (username === usernameFromCognito && loggedInUsername) {
        // Proceed to the next page without requiring a login
        strategyInstance.executeStrategy(strategies, 'onContinueClick');
        resetModal();
      }
    }

    isOpen && trySkipLogin();
  }, [
    dispatch,
    extractUsername,
    strategies,
    isOpen,
    loggedInUsername,
    resetModal,
    strategyInstance,
  ]);

  return (
    <Modal onClose={handleModalClose} open={isOpen} size="small">
      <Modal.Header>Verification</Modal.Header>
      <Modal.Content image>
        <Image src="/assets/email-verification.svg" wrapped />
        <Modal.Description>
          {loginPhase === loginPhases.EMAIL_ENTRY && (
            <EmailEntry
              licenseHolderEmail={license?.registrantEmail}
              emailInputValue={emailValue}
              emailInputValueOnChange={(val) =>
                setEmailValue(userInputTrimmer(val))
              }
              isEmailError={emailInvalid}
            />
          )}

          {loginPhase === loginPhases.CODE_ENTRY && (
            <CodeEntry
              certificateText={renderConfig?.login?.certificateText}
              codeValue={codeValue}
              setCodeValue={(val) => setCodeValue(userInputTrimmer(val))}
              codeInvalid={codeInvalid}
              setCodeInvalid={setCodeInvalid}
              resendConfirmationCode={resendConfirmationCode}
            />
          )}
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          basic
          content="Close"
          disabled={isLoading}
          onClick={handleModalClose}
          data-cy="login-modal-close-button"
        />
        <Button
          color="black"
          content="Continue"
          disabled={continueDisabled}
          onClick={continueButtonOnClickCB}
          loading={isLoading}
          data-cy="login-modal-continue-button"
        />
      </Modal.Actions>
    </Modal>
  );
}
