import React from 'react'
import { parseHTMLString } from 'utils/utility-functions';

export function BasicError({ message, margin }) {
  if (!message) return null;

  return (
    <div style={{ color: 'red', margin: margin ?? '20px 0' }}>
      <span>
        {parseHTMLString(message)}
      </span>
    </div>
  )
}
