import * as yup from 'utils/yup';

export const DEFAULT_TOTAL_YEARS_TO_REPORT = 3;

export const DEFAULT_HEADER_TEXT = 'For the following months, please report the taxable receipts';

export const DEFAULT_HELPER_TEXT = `
Taxable receipts mean the consideration charged, whether or not received, for the occupancy of space in short-term
rental valued in money, whether to be received in money, goods, labor or otherwise, including all receipts, cash,
credits and property and services of any kind or nature, without any deduction therefrom whatsoever.

The value of complimentary meals or other similar services or inducements shall not be deducted.
Taxable receipts exclude payments received for units that are occupied more than 30 consecutive days or pre-approved exemptions.
`;

export const FIELD_REQUIRED_VALIDATION_MESSAGE = 'This field is required.';

export const NON_NEGATIVE_VALIDATION_MESSAGE = 'Please enter a non-negative number';

export const INTEGER_VALIDATION_MESSAGE = 'Please enter an integer';

export const paymentPeriodSchema = yup.object({
  taxableReceipts: yup.number()
  .required(FIELD_REQUIRED_VALIDATION_MESSAGE)
  .min(0, NON_NEGATIVE_VALIDATION_MESSAGE),
  numDaysOccupied: yup.number()
  .required(FIELD_REQUIRED_VALIDATION_MESSAGE)
  .integer(INTEGER_VALIDATION_MESSAGE)
  .min(0, NON_NEGATIVE_VALIDATION_MESSAGE),
  numDaysAvailable: yup.number()
  .required(FIELD_REQUIRED_VALIDATION_MESSAGE)
  .integer(INTEGER_VALIDATION_MESSAGE)
  .min(0, NON_NEGATIVE_VALIDATION_MESSAGE),
});

export const allPeriodsSchema = yup.object({
  baseTaxableItems: yup.array(paymentPeriodSchema),
  priorTaxableItems: yup.array(paymentPeriodSchema),
});

export const FORMAT_CURRENCY = 'currency';

export const FIRST_DAY_IN_MONTH = '01';

export const MONTHS_IN_YEAR = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const QUARTERS_IN_YEAR = [
  'Jan-Mar',
  'Apr-Jun',
  'Jul-Sep',
  'Oct-Dec',
];

export const AGGREGATION_SUM_FN = (...xs) => xs.reduce((acc, x) => acc + x, 0);
export const AGGREGATION_NULL_FN = () => null;

export const CoreReportingFields = {
  TAXABLE_RECEIPTS_FIELD: 'taxableReceipts',
  NUM_DAYS_AVAILABLE_FIELD: 'numDaysAvailable',
  NUM_DAYS_OCCUPIED_FIELD: 'numDaysOccupied',
}

export const DEFAULT_TOTAL_FIELD_AGGREGATION_FUNCTIONS = {
  [CoreReportingFields.TAXABLE_RECEIPTS_FIELD]: AGGREGATION_SUM_FN,
  [CoreReportingFields.NUM_DAYS_AVAILABLE_FIELD]: AGGREGATION_NULL_FN,
  [CoreReportingFields.NUM_DAYS_OCCUPIED_FIELD]: AGGREGATION_SUM_FN
};

export const typePaymentPeriod  = {
  MONTHLY: 'monthly',
  QUARTERLY: 'quarterly',
  NULL: null
}

export const ORDER_DESC = 'desc';
export const ORDER_ASC = 'asc';