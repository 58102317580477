import {
  getPeriodDisplayName,
  initializePeriodFromAvailablePeriodStub,
  useLicensesInReport,
  useLicensesInReportMap
} from "../../utils";
import capitalize from "lodash/capitalize";
import { useFormikContext } from "formik";
import React, { useCallback, useRef, useState } from "react";
import {
  downloadBlob,
  parseSpreadsheetFromFile,
  SPREADSHEET_MIME_TYPES,
  stringifyToSpreadsheet
} from "../../../../spreadsheet-utils";
import { createYupSchema } from "../../../../../utils/yup-schema-creator";
import { Header } from "../common-styles";
import styled from "styled-components";
import { UploadButton } from "../../../../../configurable-form/components/file-upload/file-upload-modules/upload-button";
import * as cs from "../../../../../common/common-styles";
import { Alert } from "rsuite";
import { useSelector } from "react-redux";
import * as appSelectors from 'app/state/app/app-selectors';
import groupBy from "lodash/groupBy";
import mapValues from "lodash/mapValues";

import { createCurrencyValidation } from "../tot-payment-periods/fields/shared";

const COLUMN_NAMES = {
  ROW_ID: "Row ID",
  PERIOD: "Period",
  PERMIT_NUMBER: "Permit Number",
  PROPERTY_ADDRESS: "Property Address",
  PROVIDER: "Provider",
  TOTAL_REVENUE: "Total Revenue",
  ALLOWABLE_DEDUCTIONS: "Allowable Deductions",
}
const CHECKSUM_COLUMNS = [
  COLUMN_NAMES.PERIOD,
  COLUMN_NAMES.PERMIT_NUMBER,
  COLUMN_NAMES.PROPERTY_ADDRESS,
  COLUMN_NAMES.PROVIDER,
]
const CHECKSUM_VALUE_COLUMN = "Checksum"
const BULK_TOT_SPREADSHEET_COLUMNS = [
  { name: COLUMN_NAMES.PERIOD, valueGetter: ({ period }) => getPeriodDisplayName(period), isReadOnly: true },
  { name: COLUMN_NAMES.PERMIT_NUMBER, valueGetter: ({ licenseReport }) => licenseReport.licenseId, isReadOnly: true },
  { name: COLUMN_NAMES.PROPERTY_ADDRESS, valueGetter: ({ license }) => license?.propertyAddress, isReadOnly: true },
  {
    name: COLUMN_NAMES.PROVIDER,
    valueGetter: ({ taxableActivity }) => capitalize(taxableActivity.provider),
    isReadOnly: true
  },
  { name: COLUMN_NAMES.TOTAL_REVENUE, validation: createCurrencyValidation({ lessThanAmount: 10000000 }) },
  { name: COLUMN_NAMES.ALLOWABLE_DEDUCTIONS, validation: createCurrencyValidation({ lessThanAmount: 10000000 }) },
  {
    name: COLUMN_NAMES.ROW_ID,
    isSystemColumn: true,
    valueGetter: ({
                    period: { id: periodId },
                    licenseReport: { licenseId },
                    taxableActivity: { provider }
                  }) => btoa(JSON.stringify({ periodId, licenseId, provider })),
    isReadOnly: true
  },
]

async function calculateChecksum(values) {
  const valueToHash = values.join(":");

  try {
    return calculateSHA1Checksum(valueToHash);
  }
  catch (e) {
    return btoa(valueToHash);
  }
}

async function calculateSHA1Checksum(value) {
  const hashArrayBuffer = await crypto.subtle.digest("SHA-1", new TextEncoder().encode(value))
  return btoa(String.fromCharCode(...new Uint8Array(hashArrayBuffer)));
}


async function validateRecords(records, paymentPeriodsById, selectableLicenses, allowableProviders) {
  const selectableLicensesById = mapValues(groupBy(selectableLicenses, "licenseId"), ([license]) => license);

  const validatedRecords = [];

  const errors = [];
  const allColumns = [
    CHECKSUM_VALUE_COLUMN,
    ...BULK_TOT_SPREADSHEET_COLUMNS.map(({ name }) => name)
  ]

  let totPaymentPeriod = null;

  for (let rowIndex = 0; rowIndex < records.length; rowIndex++) {
    const record = records[rowIndex];
    const recordMetadata = {};

    const baseErrorFields = { rowIndex };

    const missingColumns = allColumns.filter(column => {
      const value = record[column];
      return (value === null || value === undefined || !value.toString());
    })

    if (missingColumns.length) {
      errors.push({
        ...baseErrorFields,
        message: `Missing columns: ${missingColumns.join(", ")}`
      });
      continue;
    }

    const invalidColumnErrors = (await Promise.all(BULK_TOT_SPREADSHEET_COLUMNS.map(async column => {
      if (!column.validation) return [];

      const schema = createYupSchema(column.validation)
      try {
        await schema.validate(record[column.name]);
      } catch (err) {
        return err.errors.map(error => ({ column: column.name, error }));
      }

      return []
    }))).flatMap(errors => errors);

    if (invalidColumnErrors.length) {
      errors.push(...invalidColumnErrors.map(({ column, error }) => ({
        ...baseErrorFields,
        message: `Column ${column}: ${error}`
      })));
      continue;
    }


    const providedChecksum = record[CHECKSUM_VALUE_COLUMN];
    const computedChecksum = await calculateChecksum(CHECKSUM_COLUMNS.map(c => record[c]));

    if (providedChecksum !== computedChecksum) {
      errors.push({ ...baseErrorFields, message: `Invalid checksum` });
      continue;
    }

    const rowIdentificationString = record[COLUMN_NAMES.ROW_ID];
    let rowIdentification;
    try {
      rowIdentification = JSON.parse(atob(rowIdentificationString));
    } catch (e) {
      errors.push({ ...baseErrorFields, message: `${COLUMN_NAMES.ROW_ID} field has been modified` });
      continue;
    }

    recordMetadata.rowIdentification = rowIdentification;
    const { periodId, provider, licenseId } = rowIdentification;
    const period = paymentPeriodsById[periodId];
    if (!period) {
      errors.push({ ...baseErrorFields, message: `Invalid period ${record[COLUMN_NAMES.PERIOD]}` });
      continue;
    }

    const license = selectableLicensesById[licenseId];
    if (!license) {
      errors.push({
        ...baseErrorFields,
        message: `License ${record[COLUMN_NAMES.PERMIT_NUMBER]} not available in your account`
      })
      continue;
    }

    if (totPaymentPeriod && totPaymentPeriod !== license.totPaymentPeriod) {
      errors.push({
        ...baseErrorFields,
        message: `License ${record[COLUMN_NAMES.PERMIT_NUMBER]} has ${license.totPaymentPeriod} payment period, while previous rows report for licenses with a ${totPaymentPeriod} period.
        Please submit reports for licenses with a different payment period in a separate spreadsheet upload.`
      })
      continue;
    }

    totPaymentPeriod = license.totPaymentPeriod;

    if (!allowableProviders.includes(provider)) {
      errors.push({
        ...baseErrorFields,
        message: `Platform ${record[COLUMN_NAMES.PROVIDER]} is not available in current configuration. Please download the correct report template and upload a new report.`
      })
      continue;
    }

    validatedRecords.push({
      record,
      metadata: recordMetadata,
    });
  }

  return {
    errors,
    validatedRecords,
  }
}

const MAX_ERRORS_TO_DISPLAY = 10

const SelectedPeriod = styled.li`
  display: flex;
  flex-direction: row;
  align-content: center;
  gap: 10px;
  margin: 0 10px;
`;

const SelectedPeriodList = styled.ul`
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding:0;
  flex-wrap: wrap;
`

const DeleteImageButtonComponent = styled.button`
  background: none;
`

const UploadSteps = styled.ol`
`;

const UploadStep = styled.li`
  margin: 30px 0;

  &:first-child {
    margin-top: 0;
  }
`;

const ValidationErrorsHeader = styled.strong`
  margin-bottom: 20px;
`
const ValidationErrors = styled.div`
  color: red;

`

const SuccessMessage = styled.p`
  color: green;
  font-weight: bold;
`

const UnknownFailureMessage = styled.p`
  color: red;
  font-weight: bold;
`

const UploadTOTReportButton = styled(UploadButton)`
  width: 200px !important;
`;

const UploadControlContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
`;

const Section = styled.div`
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const UPLOAD_FILE_INPUT_ID = 'totReportFileInput';

export function BulkTOTUploadReporting({
  chronologicalPeriods,
  paymentPeriodsById,
  isDynamicQuarter,
  quarterlyTaxablePeriods,
  removeEnabledPeriod,
  addPeriodsButton,
  taxableItemExtraParams,
  availablePeriodsById,
  valuesHaveData,
  continueButtonDisabled,
}) {
  const { setFieldValue, resetForm } = useFormikContext();
  const [validationErrors, setValidationErrors] = useState([]);

  const allLicensesInReport = useLicensesInReport({ disablePeriodTypeFilter: true });
  const [uploadedFileName, setUploadedFileName] = useState(null);

  const fileUploaderRef = useRef();
  const uploadReport = useCallback(async () => {
    setValidationErrors([]);
    const file = fileUploaderRef.current.files[0];
    if (!file) {
      setValidationErrors(["Please select a spreadsheet file."])
      return;
    }
    fileUploaderRef.current.value = '';

    const unvalidatedRecords = await parseSpreadsheetFromFile(file);

    const allowableProviders = taxableItemExtraParams?.rowParamSets?.map(({ provider }) => provider).filter(Boolean);

    const { validatedRecords, errors } = await validateRecords(unvalidatedRecords, availablePeriodsById, allLicensesInReport, allowableProviders);
    if (errors.length) {
      const newValidationErrors = errors.map(({ rowIndex, message }) => `Row ${rowIndex + 2}: ${message}`)
      setValidationErrors(newValidationErrors);
      return;
    }

    const validatedRecordsByPeriodId = groupBy(validatedRecords, r => r.metadata?.rowIdentification?.periodId);

    const newTaxablePeriods = {};
    Object.keys(validatedRecordsByPeriodId).filter(Boolean).forEach((reportedPeriodId) => {
      const licenseIdsForPeriod = validatedRecordsByPeriodId[reportedPeriodId].map(r => r.metadata.rowIdentification.licenseId);
      const availablePeriod = availablePeriodsById[reportedPeriodId];
      if (!availablePeriod) {
        // This should not happen - validateRecords already checks for this
        throw new Error(`Period ${reportedPeriodId} unavailable`)
      }

      const licenses = allLicensesInReport.filter(({ licenseId }) => licenseIdsForPeriod.includes(licenseId));

      const initializedPeriod = initializePeriodFromAvailablePeriodStub(availablePeriod, licenses)

      const periodWithData = validatedRecordsByPeriodId[reportedPeriodId].reduce((accPeriod, validatedRecord) => {
        const csvRecord = validatedRecord.record;
        const { licenseId, provider } = validatedRecord.metadata.rowIdentification;

        const totalRevenue = csvRecord[COLUMN_NAMES.TOTAL_REVENUE]
        const allowableDeductions = csvRecord[COLUMN_NAMES.ALLOWABLE_DEDUCTIONS]

        return {
          ...accPeriod,
          licenseReports: accPeriod.licenseReports
            .map(licenseReport => licenseReport.licenseId !== licenseId ? licenseReport : {
              ...licenseReport,
              taxableActivities: licenseReport.taxableActivities
                .map(taxableActivity => taxableActivity.provider !== provider ? taxableActivity : {
                  ...taxableActivity,
                  totalRevenue,
                  allowableDeductions,
                  taxableReceipts: Math.max(0, totalRevenue - allowableDeductions),
                })
            })
        }
      }, initializedPeriod);

      newTaxablePeriods[periodWithData.id] = periodWithData;
    })

    setFieldValue("taxablePeriods", newTaxablePeriods, true);
    setUploadedFileName(file.name);
    Alert.success("Report validated successfully - click 'Continue' to proceed")
  }, [allLicensesInReport, availablePeriodsById, setFieldValue, taxableItemExtraParams?.rowParamSets]);

  const resetUploadedReport = useCallback(() => {
    setUploadedFileName(null);
    resetForm();
  }, [resetForm]);

  const licensesById = useLicensesInReportMap();

  const downloadTemplate = useCallback(async () => {
    const records = [];
    for (const period of chronologicalPeriods) {
      const selectedLicenseReports = period.licenseReports.filter(({ selected }) => selected);
      for (const licenseReport of selectedLicenseReports) {
        for (const taxableActivity of licenseReport.taxableActivities) {
          const baseRecordValues = BULK_TOT_SPREADSHEET_COLUMNS.reduce((record, column) => ({
            ...record,
            [column.name]: (column.valueGetter || (() => ""))({ period, licenseReport, taxableActivity, license: licensesById?.[licenseReport?.licenseId]  }),
          }), {});

          records.push({
            ...baseRecordValues,
            [CHECKSUM_VALUE_COLUMN]: await calculateChecksum(CHECKSUM_COLUMNS.map(column => baseRecordValues[column]))
          })
        }
      }
    }

    const blob = await stringifyToSpreadsheet(SPREADSHEET_MIME_TYPES.XLSX, records,
      [
        ...BULK_TOT_SPREADSHEET_COLUMNS.map(column => ({ key: column.name, hide: column.isSystemColumn })),
        { key: CHECKSUM_VALUE_COLUMN, hide: true }
      ]
    )

    downloadBlob(blob, "TaxableReceipts.xlsx");

  }, [chronologicalPeriods, licensesById]);

  const selectedPeriods = (isDynamicQuarter ? (quarterlyTaxablePeriods || []).filter(p => p.selected) : Object.values(paymentPeriodsById).filter(({ licenseReports }) => licenseReports?.some(({ selected }) => selected)))
  const totName = useSelector(appSelectors.totName);

  const disablePeriodSelection = valuesHaveData;

  return (
    <Container>
      <Section>
        <Header>Selected Periods</Header>
        <SelectedPeriodList>
          {selectedPeriods.map(period => <SelectedPeriod key={period.id}>
            {!disablePeriodSelection && (
              <DeleteImageButton
                onClick={() => removeEnabledPeriod(period)}
                alt="Remove payment period button"
              />
            )}
            {getPeriodDisplayName(period)}
          </SelectedPeriod>)}
        </SelectedPeriodList>
        {!disablePeriodSelection && addPeriodsButton}
      </Section>
      <Section>
      <Header>Upload your report</Header>
      <UploadSteps>
        <UploadStep>
          After selecting the properties and periods that you would like to report for, download a
          blank spreadsheet template and fill in Taxable Receipts and Allowable Deductions for every
          period.
          <input id={UPLOAD_FILE_INPUT_ID} hidden ref={fileUploaderRef} type={"file"} onChange={uploadReport} />
          <cs.GreenButton
            disableElevation
            variant="contained"
            component="span"
            style={{ marginTop: '20px', width: '200px' }}
            onClick={downloadTemplate}
          >
            Download Template
          </cs.GreenButton>
        </UploadStep>
        <UploadStep>

          Upload your completed spreadsheet by clicking the button below. If there are any validation
          errors reported, please fix the spreadsheet and re-upload it again.
          <UploadControlContainer>
            <UploadTOTReportButton fileKey={UPLOAD_FILE_INPUT_ID}>
              Upload {totName} Report
            </UploadTOTReportButton>
            <div>
              {valuesHaveData && (
                <DeleteImageButton
                  alt={"Cancel upload and reset selections"}
                  onClick={resetUploadedReport}
                />
              )}
              {uploadedFileName || (valuesHaveData && '(unknown file)')}
            </div>
          </UploadControlContainer>
        </UploadStep>
      </UploadSteps>
      {validationErrors.length > 0 && (
        <ValidationErrors>
          <ValidationErrorsHeader>Failed to validate your report</ValidationErrorsHeader>
          <p>Please fix the errors below and re-upload the corrected spreadsheet to continue</p>
          <ul>
            {validationErrors.slice(0, MAX_ERRORS_TO_DISPLAY).map((message) => <li key={message}>{message}</li>)}
            {validationErrors.length > MAX_ERRORS_TO_DISPLAY &&
              <li>+ {validationErrors.length - MAX_ERRORS_TO_DISPLAY} more errors</li>}
          </ul>
        </ValidationErrors>
      )}
      {valuesHaveData && Boolean(uploadedFileName) && !validationErrors.length && (
        !continueButtonDisabled ? (
          <SuccessMessage>
            {"Report validated successfully - click 'Continue' to proceed"}
          </SuccessMessage>
        ) : (
          <UnknownFailureMessage>
            An unknown failure has occurred when validating your upload.
            Please ensure that read-only and hidden fields in the spreadsheet template have not been modified and
            that no rows were added/removed and try again.
            If the problem persists, please contact us for assistance.
          </UnknownFailureMessage>
        )
      )}
      </Section>
    </Container>
  )
}

function DeleteImageButton({ onClick, alt }) {
  return (
    <DeleteImageButtonComponent type={"button"} onClick={onClick}>
      <img src="../../../assets/circle-cross.svg" alt={alt} />
    </DeleteImageButtonComponent>
  )
}
