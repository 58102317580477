import { createSlice } from '@reduxjs/toolkit';

const INIT_STATE = {
  shouldLockPayments: false,
};

export const paymentSlice = createSlice({
  name: 'payment',
  initialState: INIT_STATE,
  reducers: {
    setShouldLockPayments: (state, { payload }) => {
      state.shouldLockPayments = payload;
    },
  },
})

export const paymentActions = paymentSlice.actions;

export const paymentReducer = paymentSlice.reducer;
