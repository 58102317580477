import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import first from 'lodash/first';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import merge from 'lodash/merge';
import orderBy from 'lodash/orderBy';

import {
  TAX_STATUS_FILTER_ALL,
  TAX_STATUS_FILTER_OVERDUE_REPORTING,
  TAX_STATUS_FILTER_UP_TO_DATE,
} from '../../constants';

dayjs.extend(quarterOfYear);

function getNameOfOwner(owner) {
  return owner.name ? owner.name : `${owner.firstName} ${owner.lastName}`;
}

/**
 * This function interprets the person JSON for an individual permit for the Permits List view.
 * It will process either a single person JSON object or an array of person JSON objects and
 * return a string to be rendered in the list view.
 *
 * If a single person JSON object is processed, then the result is the name of the person.
 * As per the Property Contacts section on the single permit view, the name is used first,
 * if that is non existant or undefined the firstName and lastName are used
 * If an array of person JSON is processed, then the result will be the name of the first
 *  person and following will be a count of however many more people there are.
 *  It will also include additional data for a component render a tooltip of all persons
 *    upon hovering on the text.
 */
function parseOwnersContactDetails(ownerJSON) {
  if (isNil(ownerJSON) || isEmpty(ownerJSON)) return '';

  if (Array.isArray(ownerJSON)) {
    const names = ownerJSON.map((d) => getNameOfOwner(d));
    const firstOwnerName = first(names);
    return {
      owner:
        names.length > 1
          ? `${firstOwnerName}, +${names.length - 1}`
          : firstOwnerName,
      ...(names.length > 1 && { allNamesTooltip: names.join(', ') }),
    };
  }

  return { owner:  getNameOfOwner(ownerJSON) };
}

/**
 * This function reads the most recent payment date for the permit data from
 * "/userLicenses" API and then determines whether the permit has update to date
 * on payments or has outstanding overdue payments.
 */
function parseMostRecentPaymentDetails(
  paymentJSON,
  allowOmittingLatestPeriod = false,
) {
  const {
    paymentPeriodType, // Either "monthly" or "quarterly"
    latestVerifiedPaymentSubmissionDate, // This can be undefined
  } = paymentJSON;

  const isQuarterlyPaymentPeriodType = paymentPeriodType === 'quarterly';
  const monthOrQuarter = isQuarterlyPaymentPeriodType ? 'quarter' : 'month'; // These are valid as either arguments or methods for DayJS

  // This date is either the end of the prior or current month or quarter
  const comparatorPaymentDate = dayjs()
    .subtract(allowOmittingLatestPeriod ? 0 : 1, monthOrQuarter)
    .endOf(monthOrQuarter);

  const parsedMostRecentPaymentDate = dayjs(
    latestVerifiedPaymentSubmissionDate,
  ).endOf(monthOrQuarter);

  const numOverduePeriods = comparatorPaymentDate.diff(
    parsedMostRecentPaymentDate,
    monthOrQuarter,
  );

  return merge({}, paymentJSON, {
    // See "SORT_ACTION_ASC_TAX_REPORTING" or "SORT_ACTION_DESC_TAX_REPORTING" for why we use "reportingStatus"
    reportingStatus: !numOverduePeriods
      ? 'Up-to-date'
      : `${numOverduePeriods} ${monthOrQuarter} overdue`,
    numOverduePeriods,
  });
}

/**
 * This function prepares the permit data for the user for the list view.
 * It parses the who the owner of the property and determines the most recent payment period, then
 * inserts that information back to the permit data itself.
 *
 * @param {*} userPermitsJSON Data from the "/userLicenses" API
 * @param {boolean} allowOmittingLatestPeriod Boolean flag to let the function know how to work
 * @param {*} renewableLicenseData Data from the "/renewableLicenses" API
 */
export function preparePermitsForListView(
  userPermitsJSON,
  allowOmittingLatestPeriod,
  renewableLicenseData
) {
  if (isNil(userPermitsJSON)) return [];
  return userPermitsJSON.map((permit, i) =>
  {
    // We need to show a renew button if the license is eligible to be renewed
    // Since the /userLicenses does not return if a license can be renewed we have to look for a match from the /renewableLicenses endpoint instead
    let isRenewable = renewableLicenseData?.some(license => license.apn === permit.apn && license.license === permit.license) || false;
    return merge(
      {},
      permit,
      parseOwnersContactDetails(permit?.owner),
      parseMostRecentPaymentDetails(permit?.payment, allowOmittingLatestPeriod),
      { isRenwable: isRenewable }
    )}
  );
}

export function orderPermitsByField(
  method, // See "Sort Actions" section in "src/pages/portal/dashboard/constants.js"
  permits,
) {
  const [order, key, params] = method;

  return orderBy(
    permits,
    (permit) => {
      if (params === 'date') return dayjs(get(permit, key)).unix();
      return String(get(permit, key)).toLowerCase();
    },
    [order],
  );
}

/**
 * Determines which permits to filter based on number of overdue periods
 * @param {string} taxFilterMethod One of "TAX_STATUS_FILTER_ALL", "TAX_STATUS_FILTER_UP_TO_DATE", "TAX_STATUS_FILTER_OVERDUE_REPORTING"
 * @param {object} permit Parsed permit data
 * @returns Boolean
 */
export function filterByTaxStatus(taxFilterMethod, permit) {
  const { numOverduePeriods } = permit;
  // eslint-disable-next-line default-case
  switch (taxFilterMethod) {
    case TAX_STATUS_FILTER_ALL:
      return true;
    case TAX_STATUS_FILTER_UP_TO_DATE:
      return !numOverduePeriods; // numOverduePeriods === 0, then it is up to date
    case TAX_STATUS_FILTER_OVERDUE_REPORTING:
      return Boolean(numOverduePeriods); // numOverduePeriods > 0, then it is overdue
  }
}
