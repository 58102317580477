import React, { useCallback, useState } from 'react';
import { Popover, Whisper } from 'rsuite';

import { Display, LargeDisplay } from './styles';

function DataTooltip(props) {
  return <Popover {...props}>{props.data}</Popover>;
}

export function PersonDataTooltip(props) {
  const { type, data, useLargeDisplay, widthConstraint } = props;

  const [elementWidth, setElementWidth] = useState(null);

  const setElementWidthFromRef = useCallback((ref) => {
    setElementWidth(ref?.clientWidth);
  }, []);

  const useWhisper = elementWidth >= widthConstraint;
  const DisplayComponent = useLargeDisplay ? LargeDisplay : Display;

  const useATagForEmail = type === 'email';

  return (
    <DisplayComponent
      useEllipses
      fontColor={props.fontColor}
      textAlign={props.textAlign}
      justifyContent={props.justifyContent}
    >
      {useWhisper ? (
        <Whisper
          placement={props.placement}
          trigger="hover"
          speaker={<DataTooltip data={data} />}
        >
          {useATagForEmail ? (
            <a ref={setElementWidthFromRef} href={`mailto:${data}`}>
              {data}
            </a>
          ) : (
            <p ref={setElementWidthFromRef}>{data}</p>
          )}
        </Whisper>
      ) : useATagForEmail ? (
        <a ref={setElementWidthFromRef} href={`mailto:${data}`}>
          {data}
        </a>
      ) : (
        <p ref={setElementWidthFromRef}>{data}</p>
      )}
    </DisplayComponent>
  );
}
