import React, { useCallback, useMemo, useState } from 'react'
import first from 'lodash/first';
import debounce from 'debounce';
import styled from 'styled-components';
import { CircularProgress } from '@material-ui/core';
import { Alert, AutoComplete } from 'rsuite';
import * as cs from 'common/common-styles';
import { DEFAULT_FORM_VALUE } from 'common/constants';
import { strRegistrationApiSender } from 'utils/str-registration-api-sender';
import { userInputTrimmer } from 'utils/utility-functions';
import { captureExceptionWithContext } from 'utils/sentry-functions';
import { useSelector } from 'react-redux';
import * as appSelectors from 'app/state/app/app-selectors';

const MAX_NUMBER_OF_APNS = 1;
const MIN_CHARS_BEFORE_QUERY = 4;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  @media only screen and (max-width: 601px) {
    flex-direction:column ;
  }
`;

const AutocompleteInput = styled(AutoComplete)`
  flex: 1;
  input {
    height: 36px;
    border-radius: 5px;
    border: 1px solid rgb(207, 207, 207);
  }
`;

const Button = styled(cs.GreenButton)`
  &&& {
    width: 160px;
    white-space: nowrap;
  }
`;

export function AddressInput({
  address,
  searchButtonLabel = 'Find Parcel Number',
  disabled,
  setAddress,
  setParcelNumber,
}) {
  const { search: config } = useSelector(appSelectors.renderConfig);

  const [queryingSuggestions, setQueryingSuggestions] = useState(false);
  const [addressSuggestions, setAddressSuggestions] = useState([]);
  const [addressAPNMap, setAddressAPNMap] = useState({});

  const queryRentalAddress = useMemo(
    () => debounce(async (address) => {
    try {
      setQueryingSuggestions(true);
      const { data } = await strRegistrationApiSender.get(`/lookupAddress/${encodeURIComponent(address)}`);
      setAddressSuggestions(Object.keys(data));
      setAddressAPNMap(data);
    } catch (err) {
      Alert.error('We are unable to search for your address at the moment.', 10000); // Keep open for 10 seconds
      captureExceptionWithContext(err, { address });
    } finally {
      setQueryingSuggestions(false);
    }
  }, 300), []);

  const retrieveParcelNumber = useCallback(() => {
    setParcelNumber(DEFAULT_FORM_VALUE);
    const apns = addressAPNMap?.[address];
    if (apns?.length && apns.length <= MAX_NUMBER_OF_APNS) setParcelNumber(first(apns));
  }, [address, addressAPNMap, setParcelNumber]);

  const handleAddressChange = async (address, event) => {
    const trimmedAddress = userInputTrimmer(address);
    setAddress(trimmedAddress);

    // Only query for suggestions when the user types
    if (event.type === 'change' && trimmedAddress.length >= MIN_CHARS_BEFORE_QUERY) await queryRentalAddress(trimmedAddress);
  };

  const isButtonDisabled = useMemo(() => (
    !address || !Object.keys(addressAPNMap).length || queryingSuggestions
  ), [address, addressAPNMap, queryingSuggestions]);

  return (
    <div>
      <cs.HeaderText data-cy='header-label'>
        {config?.addressInputLabel ?? 'Address of the rental property'}
      </cs.HeaderText>

      <Container>
        <AutocompleteInput
          value={address}
          data={addressSuggestions.slice(0, 10)}
          onChange={handleAddressChange}
          // we want to disable client side filtering and show all suggestions returned by server, so we always return true
          filterBy={() => true}
          onSelect={() => setParcelNumber(DEFAULT_FORM_VALUE)}
          data-cy='autocomplete-input'
          disabled={disabled}
        />

        {!disabled && (
          <Button
            disableElevation
            variant="contained"
            component="span"
            onClick={retrieveParcelNumber}
            disabled={isButtonDisabled}
            data-cy='find-parcel-btn'
          >
            {queryingSuggestions ? <CircularProgress size={20} /> : searchButtonLabel}
          </Button>
        )}
      </Container>
    </div>
  );
}
