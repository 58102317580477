import React from 'react';
import styled from 'styled-components';
import { AnimatedText } from 'common/animated-text';
import { BouncingBox } from 'common/bouncing-box';

const Wrapper = styled.div`
  margin-right: ${(props) => props.marginRight};
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
`;

export function LoadingPage() {
  return (
    <Wrapper marginRight='unset'>
      <BouncingBox />
      <AnimatedText loadingText='Loading' />
    </Wrapper>
  )
}
