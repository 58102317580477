import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import * as networkSelectors from 'app/state/network/network-selectors';
import { PaymentButton } from '../../../modules/payment-button/payment-button';
import { strRegistrationApiSender } from "utils/str-registration-api-sender";

export function GenericBaseModule(props) {
  const paymentFees = useSelector(networkSelectors.paymentFees);

  const createPaymentProviderSessionUrl = useCallback(async () => {
    const { data } = await strRegistrationApiSender.post('/payGeneric', {
      paymentId: paymentFees?.paymentId,
      email: props.email,
      signatory: props.signatory,
    });

    return data;
  }, [paymentFees?.paymentId, props.email, props.signatory]);

  const [loading, setLoading] = useState(false);

  const onClick = useCallback(async () => {
    setLoading(true);
    try {
      const data = await createPaymentProviderSessionUrl();

      /**
       * Only open a new tab/window, when there is no "onClick" prop provided
       *  and if the payment URL from the server evaluates to true
       */
      if (!props.onClick && data.url) {
        window.open(data.url, ...props.windowOpenNameParam);
      } else props.onClick(data.url, data);
    }
    finally {
      setLoading(false);
    }
  }, [createPaymentProviderSessionUrl, props]);

  return (
    <div>
      {props.children}
      <PaymentButton label="Pay" loading={loading} disabled={loading} onClick={onClick} />
    </div>
  );
}
