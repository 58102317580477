import React from 'react';
import { renderFieldComponent } from "configurable-form";
import { Container } from "semantic-ui-react";
import { Formik } from 'formik';

export function ConfigurableLandingPage({ fields }) {
  /**
   * All of our existing components destructure {values} from
   * Formik state. Instead of having to go through all of them
   * and decouple them from Formik in order to reuse them here,
   * this page wraps them in a "dummy" Formik context.
   */
  return (
    <Formik>
      <Container style={{ paddingTop: "10rem" }}>
        {fields.map((config, fieldIndex) =>
          renderFieldComponent(config, `field-${fieldIndex}`, null, null)
        )}
      </Container>
    </Formik>
  );
}
