import dayjs from 'dayjs';
import { useFormikContext } from 'formik';
import get from 'lodash/get';
import merge from 'lodash/merge';
import set from 'lodash/set';
import React, { useMemo } from 'react';
import { Checkbox } from 'semantic-ui-react';
import {
  getAllSelectedLicensesOnReportPage,
  getPeriodDisplayName,
  initializePeriodFromAvailablePeriodStub
} from '../utils';

function togglePeriodSelection(period, shouldSelect, { selectedLicenseIds }) {
  const reports = period.licenseReports.map((licenseReport) => {
    const checkReportLicenseIdIncluded = selectedLicenseIds?.length;

    if (!checkReportLicenseIdIncluded) return merge({}, licenseReport, { selected: shouldSelect });

    const reportLicenseId = licenseReport.licenseId;
    const reportLicenseIdIncluded = selectedLicenseIds.includes(reportLicenseId);
    const selected = shouldSelect && reportLicenseIdIncluded;

    return merge({}, licenseReport, { selected });
  });

  return merge({}, period, { licenseReports: reports });
}

/**
 * For regions with dynamic payment periods
 * (quarterly or monthly, which can change with each license)
 * toggles 3 months in a quarter, so that they can be displayed individualy for
 * the quarterly view, only in regions with dynamic payment periods.
 *
 * # Quarterly Payment Period booleans:
 *
 * ## isQuarterlyPeriods,
 *  sets quarterly for all  in region, monthly is the default
 * if set, does not trigger the new ui

 * ## isPaymentPeriodDynamic:true
 * set in reporting page in payTOT, allows quarterly/monthly
 * payment periods in same region, on a application by application basis.
 *
 * ## isDynamicQuarter
 * This ignores the other isQuarterlyPeriods boolean
 * set on license by license basis after backend call
 * triggers new UI e.g. months are displayed in quarter, new totals component.

 * "totPaymentPeriod": null,
 * is ignored, as we use the isPaymentPeriodDynamic boolean to trigger new UI.
 **/

export function useToggleDynamicQuarterPeriod({ availablePaymentPeriods, licensesInReport }) {
  const {
    setFieldValue,
    values: {
      taxablePeriods: paymentPeriods,
      quarterlyTaxablePeriods
    },
  } = useFormikContext();

  const selectedLicenseIds = useMemo(() => (
    Array.from(getAllSelectedLicensesOnReportPage({ taxablePeriods: paymentPeriods }))
  ), [paymentPeriods]);

  const toggleQuarter = ({ paymentPeriod, isDynamicQuarter }) => {
    const monthsInQuarter = getMonthPeriodsInQuarter({
      months: availablePaymentPeriods,
      quarter: paymentPeriod
    });

    if (!monthsInQuarter) return;

    // formik: update selected quarters on modal screen, toggle selected
    // for current quarter checkbox
    const quarterToToggle = quarterlyTaxablePeriods.find((quarterlyPeriod) => quarterlyPeriod.dateStart === paymentPeriod.dateStart);

    const shouldSelect = !quarterToToggle?.selected;

    const quarterlyPaymentPeriodUpdated = quarterlyTaxablePeriods.map((quarterlyPeriod) => (
      merge({}, quarterlyPeriod, quarterlyPeriod.dateStart !== paymentPeriod.dateStart ? {} : { selected: shouldSelect })
    ));


    setFieldValue('quarterlyTaxablePeriods', quarterlyPaymentPeriodUpdated, false);

    let taxablePeriodChangeset = {};

    const flag = paymentPeriod.isQuarterlyPeriods;
    if (flag) {
      const existingPeriod = paymentPeriods[paymentPeriod.id];

      if (isDynamicQuarter) {
        const targetMonths = existingPeriod
          // This is data from Formik "values"
          ? monthsInQuarter.map((m) => get(paymentPeriods, m.id)).filter(Boolean)
          : monthsInQuarter;

        targetMonths.forEach((month) => {
          const periodChangeSet = existingPeriod
            ? togglePeriodSelection(month, shouldSelect, { selectedLicenseIds })
            : initializePeriodFromAvailablePeriodStub(month, licensesInReport, { selectedLicenseIds });

          set(taxablePeriodChangeset, month.id, periodChangeSet );
        });
      } else {
        const periodChangeSet = existingPeriod
          ? togglePeriodSelection(existingPeriod, shouldSelect, { selectedLicenseIds })
          : initializePeriodFromAvailablePeriodStub(paymentPeriod, licensesInReport, { selectedLicenseIds });

        set(taxablePeriodChangeset, paymentPeriod.id, periodChangeSet );
      }
    } else {
      const list = monthsInQuarter.map((monthlyPeriodInQuarter) => {
        const periodId = monthlyPeriodInQuarter.id;

        const existingPeriod = paymentPeriods[periodId];

        if (!existingPeriod && !shouldSelect) return {};

        return {
          [periodId]: existingPeriod
            ? togglePeriodSelection(existingPeriod, shouldSelect, {
                selectedLicenseIds,
              })
            : initializePeriodFromAvailablePeriodStub(
                monthlyPeriodInQuarter,
                licensesInReport,
                { selectedLicenseIds },
              ),
        };
      });

      taxablePeriodChangeset = Object.assign({}, list);
    }

    // formik: update selected months
    setFieldValue('taxablePeriods', { ...paymentPeriods, ...taxablePeriodChangeset });
  };

  return toggleQuarter;
}

export function QuarterlyDynamicCheckbox(props) {
  const {
    period,
    selectedYear,
    availablePaymentPeriods,
    licensesInReport,
    isQuarterlyDynamicPeriod,
  } = props;

  const toggleQuarter = useToggleDynamicQuarterPeriod({
    availablePaymentPeriods,
    licensesInReport
  });

  const isSelectedYearInView = selectedYear === dayjs(period.dateStart).year();

  if (!isSelectedYearInView) return null;

  return (
    <Checkbox
      id={String(period.id)}
      name={String(period.id)}
      label={getPeriodDisplayName(period)}
      checked={period.selected}
      disabled={period.disabled}
      onChange={() => toggleQuarter({ paymentPeriod: period, isDynamicQuarter: isQuarterlyDynamicPeriod })}
    />
  );
}

const getMonthPeriodsInQuarter = ({ months, quarter }) => {
  const startingMonthIndex = months.findIndex(
    month => month.dateStart === quarter.dateStart
  )

  const monthsInQuarter = months.slice(startingMonthIndex, startingMonthIndex + 3)

  if (!monthsInQuarter.every((m) => Boolean(m.dateStart))) {
    return false
  }

  return monthsInQuarter
}
