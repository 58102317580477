import React from 'react';
import titleize from 'titleize';

import { HighlightedPermitData } from './highlighted-permit-data';

export function StatusHighlight(props) {
  const label = `${
    props.isPermitApplication ? 'Application' : props.permitVerbiage
  } Status`;

  if (!props.status) return null;
  return <HighlightedPermitData label={label} data={titleize(props.status)} />;
}
