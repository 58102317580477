import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import replace from 'lodash/replace'
import { Icon, InputGroup, Tooltip, Whisper } from 'rsuite';
import * as cs from 'common/common-styles';
import { DEFAULT_FORM_VALUE as emptyString } from 'common/constants';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
`


// eslint-disable-next-line no-control-regex
const regex = /[^\x00-\x7F]/g;

export function InputAdornment({ tooltip, imgSrc }) {
  if (!tooltip) return null;

  const fileName = imgSrc || 'icon-info';

  return (
    <InputGroup.Addon>
      <Whisper trigger="hover" placement="top" speaker={<Tooltip>{tooltip}</Tooltip>}>
        <img src={`/assets/${fileName}.svg`} alt={fileName} />
      </Whisper>
    </InputGroup.Addon>
  );
}

export function ReverseArgumentInput(props) {
  const { onChange: handleChange, onDOMBlur: handleDOMBlur, dataCy, ...other } = props;

  const ref = useRef();

  const handleWheel = (e) => e.preventDefault();

  useEffect(() => {
    /**
     * https://github.com/facebook/react/pull/19654
     *
     * https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener#parameters
     * See "passive" parameter
     * Notice the `passive: false` option
     * This is done so that using the mouse wheel within any type of input will do nothing
     */
    ref.current.addEventListener('wheel', handleWheel, { passive: false });

    const refReference = ref;
    return function cleanup() {
      refReference?.current?.removeEventListener('wheel', handleWheel, { passive: false });
    }
  }, [ref]);

  useEffect(() => {
    /**
     * HACK: Setting onBlur on the returned element prevents the formik onBlur
     * validation from running, so use the HTML blur event rather than the React event.
     */
    if (handleDOMBlur)
      ref.current.addEventListener('blur', handleDOMBlur);

    const refReference = ref;
    return function cleanup() {
      if (handleDOMBlur)
        refReference?.current?.removeEventListener('blur', handleDOMBlur);
    }
  }, [ref, handleDOMBlur]);

  const onChange = useCallback((val, e) => {
    e.target.value = replace(String(e.target.value), regex, emptyString);
    handleChange(e);
  }, [handleChange]);

  return <cs.StyledInput inputRef={ref} data-cy={dataCy} onChange={onChange} {...other} />;
}

/**
 *
 * @param {*} props
 *    onChange is passed to ReverseArgumentsInput
 *    onDOMBlur is passed to ReverseArgumentsInput
 *    tooltip (string) - the message to display when hovering over icon
 *    imgSrc (string) - the path of the icon to display
 *    leftHandAdornment (boolean) - if true, adornment (usually $) will come first
 */
export function InputComponent(props) {
  const {
    // Optional props for the input adornment
    tooltip, imgSrc, leftHandAdornment,
    clearable,
    value = emptyString, ...other
  } = props;

  const renderComponent = <InputGroup inside>
      {leftHandAdornment && <InputAdornment tooltip={tooltip} imgSrc={imgSrc}/>}
      <ReverseArgumentInput value={value} {...other} />
      {!leftHandAdornment && <InputAdornment tooltip={tooltip} imgSrc={imgSrc}/>}
    </InputGroup>

if (!clearable) return renderComponent

  return (
    <Wrapper>
      {renderComponent}
    {
        clearable &&
        <InputGroup.Button onClick={() => {
          other.onChange({ target: { value: emptyString, id: other.name  } })
        } }>
          <Icon icon="close" />
        </InputGroup.Button>
      }
    </Wrapper>
  );
}

export function FormikError({ touched, value, error }) {
  const showError = useMemo(() => {
    /**
     * Only display an error when:
     *  1. the field has been touched or contains a value
     *  AND
     *  2. there is an error attributed to the field
     */
    return (touched || value) && error;
  }, [error, touched, value]);

  return showError ?
    <cs.ErrorMessage className="error-message">{error}</cs.ErrorMessage> :
    null;
}
