import React, { useMemo } from 'react';
import { useFormikContext } from 'formik';
import get from 'lodash/get';
import { Checkbox as MUICheckbox } from '@material-ui/core';
import 'styled-components/macro';
import { evaluateCondition } from 'configurable-form/configurable-form-utils';
import { checkboxTestId } from 'test/constants';
import { parseHTMLString } from 'utils/utility-functions';
import * as cfs from './config-form-styles';
import { useInput } from './utils/use-input';
import { withVisibility } from './utils/with-visibility';

/**
 *  !NOTE: If the Boolean value stored in the Formik state for this needs to go to backend as part of the payload
 *    then set the proper path in "initialFormikValues" in the JSON to false
 *    See Sacramento County JSON Apply Flow
 *
 * Config:
 *
 * {
 *  "component": "Checkbox"
 *    !REQUIRED
 *
 *  "id/name": The string path to the property in Formik state to store/edit/read
 *    !REQUIRED
 *    Type - String
 *
 *  "validation": Yup validation schema (See "yup-schema-creator.js")
 *    Type - Yup validation schema
 *
 *  "label": Text to display besides the Checkbox
 *    !REQUIRED
 *    Type - String or [Conditions Array, String]
 *      Second type is used for conditionally rendering text based on user input
 *
 *    e.g.
 *      [
 *        [["_registrantType", "equal", "owner"], "Conditional Text #1."],
 *        [["_registrantType", "equal", "tenant"], "Conditional Text #2."]
 *      ]
 *
 *  "isShownOrHidden": Visibility Configuration (See "with-visibility.jsx")
 *    Type - Visibility Configuration
 *
 *  "additionalCSS": Additional CSS to be applied
 *    Type - String
 *
 *  "dataCy": Cypress selector
 *    !REQUIRED
 *    Type - string
 * }
 */
function CheckboxComponent(props) {
  const {
    /**
     * The onClick function is mainly for the AddressInputField component and is NOT from the config
     *
     *  At the moment, the only usage for this is for setting the value of
     *    the mailing address in the license holder form to the same as
     *    the property address of the APN
     */
    onClick = () => {}
  } = props;

  const { values } = useFormikContext();

  const { field } = useInput(props);

  const checkboxLabel = useMemo(() => {
    if (!props.label) return get(values, props.formikKey);

    if (Array.isArray(props.label)) {
      for (const element of props.label) {
        const [condition, text] = element;
        if (evaluateCondition(values, condition)) return text;
      }
    }

    return props.label;
  }, [props.label, values, props.formikKey]);

  const htmlParsedLabel = useMemo(() => parseHTMLString(checkboxLabel), [checkboxLabel]);

  if (!htmlParsedLabel) return null;

  return (
    <div css={props.additionalCSS} data-testid={checkboxTestId}>
      <cfs.CheckboxLabel
        onClick={onClick}
        label={htmlParsedLabel}
        labelPlacement="end"
        data-cy={props.dataCy}
        control={<MUICheckbox color="default" checked={Boolean(field?.value)} {...field} />}
      />
    </div>
  );
}

export const Checkbox = withVisibility(CheckboxComponent);
