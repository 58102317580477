import React from 'react';
import styled from 'styled-components';
import { useTemplatedMessages } from "./hooks/use-templates-messages";
import { parseHTMLString } from 'utils/utility-functions';

const HeadingContainer = styled.div`
  font-size: 14px;
  font-weight: bold;
  line-height: 1.5;
  color: #7f8fa4;
`;

const ConfirmationNumberContainer = styled.div`
  font-size: 50px;
  line-height: 1.2;
  color: #5c5d5d;
  margin-top: 4px;
  margin-bottom: 20px;
`;

const StyledParagraph = styled.div`
  font-size: 18px;
  line-height: 1.56;
  color: #5c5d5d;
`;

/**
 * Different success view for flows that do not require x number of weeks to resolve
 *
 * @param {*} param
 * @returns
 */
export function FocusedSuccessRenderer({ info, header, message, extraTemplateTokensToValues }) {
  const templatedMessages = useTemplatedMessages({
    message,
    extraTemplateTokensToValues,
    info
  })

  return (
    <>
      {info && (
        <>
          <HeadingContainer>{header}</HeadingContainer>
          <ConfirmationNumberContainer>{info}</ConfirmationNumberContainer>
        </>
      )}
      <StyledParagraph data-cy="success-paragraph">
        {parseHTMLString(templatedMessages)}
      </StyledParagraph>
    </>
  );
}
