import get from 'lodash/get';
import React, { useCallback } from 'react';
import titleize from 'titleize';
import { useTranslations } from 'utils/translations';

import { formatPhoneNumber } from './helpers';
import { PersonDataTooltip } from './person-data-tooltip';
import {
  Column,
  Columns,
  Display,
  LabelledDisplayDiv,
  LargeDisplay,
  PersonalDetailsContainer,
  Row,
} from './styles';

function PropertyContactRole({ useLargeDisplay, data }) {
  return (
    <PersonDataTooltip
      useLargeDisplay={useLargeDisplay}
      data={data}
      widthConstraint={130}
      placement="left"
      fontColor="#7f7f7f"
      textAlign="right"
      justifyContent="end"
    />
  );
}

function LabelledDisplay({ label, value, type, width, widthConstraint = 300 }) {
  if (!label || !value) return null;

  return (
    <LabelledDisplayDiv $width={width}>
      <Display fontSize="10px" fontColor="#7f7f7f">
        {label}
      </Display>
      <PersonDataTooltip
        type={type}
        data={value}
        widthConstraint={widthConstraint}
        placement="top"
      />
    </LabelledDisplayDiv>
  );
}

export function Person(props) {
  const { data, roles } = props;

  const translations = useTranslations();

  const translate = useCallback(
    (string) => get(translations, `${string}.default`, string),
    [translations],
  );

  return (
    <PersonalDetailsContainer>
      <Columns>
        <Column width="300px">
          <LargeDisplay>{data?.name}</LargeDisplay>
          <Display>{data?.companyName ?? '-'}</Display>

          <LabelledDisplay
            label="Mailing Address"
            value={titleize(data?.address)}
          />

          <Row gap="20px">
            <LabelledDisplay
              width="150px"
              type="email"
              label="Email"
              value={data?.email}
              widthConstraint={150}
            />
            <LabelledDisplay
              width="150px"
              label="Phone"
              value={formatPhoneNumber(data?.primaryPhone)}
            />
          </Row>
        </Column>

        <Column width="150px">
          {roles.map((role, i) => (
            <PropertyContactRole
              key={i}
              useLargeDisplay={!i}
              data={translate(role)}
            />
          ))}
        </Column>
      </Columns>
    </PersonalDetailsContainer>
  );
}
