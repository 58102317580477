import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import 'styled-components/macro';

import { DataTable } from './data-table';

/**
 * The idea for this variant of the "DataTable" component is to have an
 * easy to use view-only table to display some data to the user that is
 * stored in either the Formik "values" or in Redux somewhere.
 */
export function ConnectedDataTable(props) {
  const reduxState = useSelector((state) => state);

  /**
   * The component shouldn't have to do anything to render the data.
   * One way or another one the data should already be compatible to display.
   */
  const viewOnlyData = useMemo(
    () => cloneDeep(get(reduxState, props.path)),
    [reduxState, props.path],
  );

  return (
    /**
     * "props.additionalCSS" is useful for constraining the size
     * of the "DataTable" component as by default it takes up
     * as much width as possible.
     */
    <div css={props.additionalCSS}>
      <DataTable
        useCheckbox={false}
        useFilter={props.useFilter}
        layout={props.layout}
        data={viewOnlyData}
        updateData={() => {}}
      />
    </div>
  );
}
