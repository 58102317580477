import styled from 'styled-components';

export const Module = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: ${(props) => props.flex};
`;

export const ModuleHeader = styled.div`
  cursor: default;

  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;

  font-weight: bold;
  font-size: 18px;
  margin-left: 5px;
`;

export const ModuleDataContainer = styled.div`
  height: 100%;
  border: 1px solid rgb(230, 230, 233);
  border-radius: 10px;
  box-shadow: rgba(8, 10, 37, 0.03) 0px 2px 3px 0px,
    rgba(8, 10, 37, 0.05) 0px 4px 8.5px 0px,
    rgba(89, 126, 247, 0.03) 0px 7.5px 35px 0px;

  overflow: ${(props) => (props.noScrolling ? 'hidden' : undefined)};
`;

export const ClickableModuleHeader = styled.p`
  font-family: 'Lato';
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
  cursor: ${(props) => (props.disabled ? 'auto' : 'pointer')};
`;
