import * as appSelectors from 'app/state/app/app-selectors';
import { appActions } from 'app/state/app/app-slice';
import { filesActions } from 'app/state/files/files-slice';
import { networkActions } from 'app/state/network/network-slice';
import { payloadActions } from 'app/state/payload/payload-slice';
import { Auth } from 'aws-amplify';
import isEmpty from 'lodash/isEmpty';
import React, { useCallback, useEffect, useRef } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { authSender } from 'utils/auth-sender';
import { filesManager } from 'utils/files-manager';

import { strRegistrationApiSender } from '../../utils/str-registration-api-sender';

export function withFlowEntry(WrappedComponent) {
  return function FlowEntryWrapper(props) {
    const { isFlowEntry = true } = props;

    const dispatch = useDispatch();

    useEffect(() => {
      if (!isFlowEntry) return;
      dispatch(appActions.setVisitedFirstPageInFlow({ value: true }));
      if (!isEmpty(props.init))
        dispatch(payloadActions.updateFormikSnapshot(props.init));
    }, [dispatch, props, props.init, isFlowEntry]);

    return <WrappedComponent {...props} />;
  };
}

export function withFlowExit(WrappedComponent) {
  return function FlowExitWrapper(props) {
    const dispatch = useDispatch();
    /**
     * State is not needed here - the useEffect callback would need to depend on the state
     * and changing it would trigger the cleanup function that resets the flow, which is exactly
     * what we don't want to happen when setting `disabled` to `true`
     */
    const disabled = useRef(false);
    const history = useHistory();

    const useCustomerPortal = useSelector(appSelectors.useCustomerPortal);

    /**
     * Used to "transfer" between flows without resetting Redux state.
     * Calling this function disables the cleanup function to prevent state reset
     * and then navigates to the provided route.
     */
    const exitWithoutReset = useCallback(
      (destinationRoute) => {
        disabled.current = true;
        history.push(destinationRoute);
      },
      [history],
    );

    useEffect(() => {
      return function cleanup() {
        (async () => {
          try {
            if (!useCustomerPortal) return await Auth.signOut();
          } catch (err) {} // eslint-disable-line no-empty
        })();

        if (disabled.current) {
          disabled.current = false;
          return;
        }

        filesManager.clearProofs();

        authSender.setCustomJWT(null);
        strRegistrationApiSender.setAdminFlowMode(false);

        batch(() => {
          dispatch(appActions.setVisitedFirstPageInFlow({ value: false }));
          dispatch(appActions.setEnableEditingAllFields(false));
          dispatch(filesActions.reset());
          dispatch(networkActions.reset());
          dispatch(payloadActions.resetFlow());
        });
      };

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    return <WrappedComponent {...props} exitWithoutReset={exitWithoutReset} />;
  };
}
