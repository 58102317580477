import { createSelector } from '@reduxjs/toolkit';

export const network = (state) => state.network;

export const applicationNumber = createSelector(
  network,
  (state) => state?.applicationNumber,
);

export const miscInfo = createSelector(
  network,
  (state) => state?.miscInfo,
);

export const paymentFees = createSelector(
  network,
  (state) => state?.paymentFees,
);

export const originalLicenseInfo = createSelector(
  network,
  (state) => state?.originalLicenseInfo,
);

/**
 * This object generally looks like:
 *  {
 *    license: "123",
 *    paymentId: "123",
 *    paymentConfirmation: "123"
 *  }
 */
export const successInfo = createSelector(
  network,
  (state) => state?.successInfo,
);

export const genericPaymentUrl = createSelector(
  paymentFees,
  (payment) => payment.url,
);
