import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import get from 'lodash/get';
import isString from 'lodash/isString';
import isUndefined from 'lodash/isUndefined';
import template from 'lodash/template';
import styled from 'styled-components/macro';
import * as appSelector from 'app/state/app/app-selectors';
import * as cs from 'common/common-styles';
import { DEFAULT_FORM_VALUE as emptyString } from 'common/constants';
import { pageTextTestId } from 'test/constants';
import { parseHTMLString } from 'utils/utility-functions';
import { withVisibility } from './utils/with-visibility';

const StyledP = styled.p`
  line-height: 1.5;
`;

const StyledListItem = styled.li`
  line-height: 1.5;
`;

const TextWrapper = styled.div`
  ${cs.textBase}
  margin: 20px 0;
  max-width: 800px;
  font-size: 12px;
  line-height: 1.75;
`;

function MainText({ text }) {
  if (!text) return null;

  return Array.isArray(text) ? (
    <>{text.map((section, idx) => <StyledP key={`pageTextMain-${idx}`}>{parseHTMLString(section)}</StyledP>)}</>
  ) : (
    <StyledP>{parseHTMLString(text)}</StyledP>
  );
}

function UnorderedList(props) {
  return <ul>{props.children}</ul>;
}

function OrderedList(props) {
  return <ol>{props.children}</ol>;
}

const ListComponentMap = {
  unordered: UnorderedList,
  ordered: OrderedList
};

function SubText({ text, listType }) {
  const ListComponent = ListComponentMap?.[listType] || UnorderedList;

  if (!text) return null;

  return (
    <ListComponent>
      {Array.isArray(text) ? (
        text.map((textItem, idx) => (
          <StyledListItem key={`pageTextSub-${idx}`}>{parseHTMLString(textItem)}</StyledListItem>
        ))
      ) : (
        <StyledListItem>{parseHTMLString(text)}</StyledListItem>
      )}
    </ListComponent>
  );
}

// See "PageTextProps" in "fieldProps.ts"
function PageTextComponent(props) {
  const { values: formikValues } = useFormikContext();
  const appState = useSelector(appSelector.appState);

  const values = props.useAppStatePaths ? appState : formikValues;

  const theme = useSelector(appSelector.theme);

  const mainText = useMemo(
    () => {
      const plainText = get(props, 'text.main');
      if (isString(plainText) || Array.isArray(plainText)) return plainText;

      const mainTextKeyPath = get(props, 'mainTextKeyPath');
      if (!isUndefined(mainTextKeyPath)) return get(values, mainTextKeyPath, emptyString);

      const templateString = get(props, 'mainTextKeyTemplate', emptyString);
      const templateValuesPaths = props?.templateValues;
      const templateValues = Object.assign({}, ...Object.keys(templateValuesPaths || {})
        .map(valueId => {
          const path = templateValuesPaths[valueId];
          return { [valueId]: get(values, path, emptyString) }
        }));

      return template(templateString)(templateValues);
    },
    /**
     * "props" doesn't change as it's just the component defined in the JSON config.
     * So it's not listed as a dependency for this hook.
     */
    [values] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const subText = useMemo(
    () => props?.text?.sub || get(values, props?.subTextKeyPath, emptyString),
    [props?.subTextKeyPath, props?.text?.sub, values]
  );

  if (!mainText) return null;

  return (
    <TextWrapper
      css={`${props.additionalCSS}; font-size: ${theme?.form?.pageTextFontSize};`}
      data-testid={pageTextTestId}
    >
      <MainText text={mainText} />
      <SubText text={subText} listType={props?.text?.listType} />
    </TextWrapper>
  );
}

export const PageText = withVisibility(PageTextComponent);
