import styled from 'styled-components';

export const FieldLabel = styled.label`
  font-size: 16px;
  font-weight: 500;
  color: rgba(26, 26, 26, 0.7);
  margin-top: 12px;
`;

export const SelectionWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5px;
  margin-bottom: 20px;
  margin-top: 20px;
  @media (max-width: 768px) {
    grid-template-columns: auto;
  }
`;
