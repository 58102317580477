import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as cs from 'common/common-styles';
import { BackButton, ContinueButton } from 'common/navigation-buttons';
import { payloadActions } from 'app/state/payload/payload-slice';
import { evaluateCondition } from './configurable-form-utils';
import { isBoolean } from 'lodash';
import * as errorSelector from 'app/state/error/error-selectors';
import { errorActions } from 'app/state/error/error-slice';
import { parseHTMLString } from "../utils/utility-functions";
import styled from "styled-components";

const PageError = styled(cs.ErrorMessage)`
  margin-top: 12px;
`;

export function FormNav(props) {
  const {
    formik,
    formikSnapshot,
    forceDisable,
    pageConfig: {
      isOptional,
      usingAltNav,
      continueCondition,
      pageNavigationCSSOverride,
      showNavButtons = true,
    }
  } = props;

  const history = useHistory()
  const dispatch = useDispatch();

  const onBackClickCB = useCallback((values) => () => {
    dispatch(payloadActions.setFormikSnapshot({ values }));
    history.goBack();
  }, [dispatch, history]);

  const isContinueDisabled = useCallback((formik) => {
    const pageIsRequired = !isOptional;

    /**
     * We always want the continue button to be disabled if:
     *  1. Forcibly disabled
     *    A. Currently, the only way to do this is thru the ConfigurableUploadForm
     *        when there are required documents that still need to be uploaded
     *  2. Form is in the process of submission
     *  3. Other conditions
     *    1. The page is required
     *    AND
     *    2. Either the form is:
     *      A. Not valid (fails the validation)
     *      OR
     *      B: In the middle of validation
     * 4. One or several conditions from `evaluateCondition` are not met
     */

    const conditionsMet = evaluateCondition(formikSnapshot, continueCondition);

    return (
      forceDisable ||
      formik.isSubmitting ||
      (pageIsRequired && (!formik.isValid || formik.isValidating)) ||
      !conditionsMet
    );
  }, [isOptional, continueCondition, formikSnapshot, forceDisable]);

  const isLoading = useCallback((formik) => formik.isSubmitting, []);

  const evaluateDisplayCondition = useCallback((condition) => {
    if (Array.isArray(condition)) return evaluateCondition(formikSnapshot, condition);
    return condition;
  }, [formikSnapshot]);

  // See "PageConfig" in "fieldProps.ts"
  const {
    hideNavigation = false,
    showBackButton = true,
    showContinueButton = true,
  } = useMemo(() => {
    // When "shoNavButtons" is a boolean
    if (
      (isBoolean(showNavButtons) && !showNavButtons) ||
      usingAltNav
    ) return { hideNavigation: true };

    if (Array.isArray(showNavButtons)) return { hideNavigation: !evaluateDisplayCondition(showNavButtons) };

    const backButtonCondition = showNavButtons?.back ?? true;
    const showBackButton = evaluateDisplayCondition(backButtonCondition);

    const continueButtonCondition = showNavButtons?.continue ?? true;
    const showContinueButton = evaluateDisplayCondition(continueButtonCondition);

    return {
      showBackButton,
      showContinueButton,
      // This is done so that the container component is not rendered, when both buttons are not displayed
      hideNavigation: !showBackButton && !showContinueButton
    };
  }, [evaluateDisplayCondition, showNavButtons, usingAltNav]);

  const pageError = useSelector(errorSelector.pageError);

  useEffect(() => {
    if (pageError) {
      dispatch(errorActions.setPageError());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, formik.values]);

  if (hideNavigation) return null;

  return (
    <>
      <cs.ButtonWrapper css={pageNavigationCSSOverride}>
        {showBackButton && <BackButton onClick={onBackClickCB(formik.values)} />}
        {showContinueButton && <ContinueButton isDisabled={isContinueDisabled(formik)} loading={isLoading(formik)} />}
      </cs.ButtonWrapper>
      {pageError && <PageError>{parseHTMLString(pageError)}</PageError>}
    </>
  )
}
