import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useWindowSize } from '@react-hook/window-size';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import Confetti from 'react-confetti';
import { errorActions } from 'app/state/error/error-slice';
import * as networkSelectors from 'app/state/network/network-selectors';
import { Wrapper } from 'common/success-view-styles';
import { renderFieldComponent } from 'configurable-form';
import { LoadingPage } from 'pages';
import { withFlowExit } from 'routes/custom/entry-exit-wrappers';
import { captureExceptionWithContext } from 'utils/sentry-functions';
import { convertNumberToCurrency, formatDate, formatTomorrow } from 'utils/utility-functions';
import { PageImage } from '../page-image';
import { DefaultSuccessRender } from './default-success-render';
import { FocusedSuccessRenderer } from './focused-success-renderer';

const formatterLookup = {
  currency: convertNumberToCurrency,
  date: formatDate,
  tomorrow: formatTomorrow
}

const defaultSvgFile = 'success';

function getRenderComponent(type) {
  switch (type) {
    case 'basic':
      return DefaultSuccessRender;
    case 'focused':
      return FocusedSuccessRenderer;
    default:
      return DefaultSuccessRender;
  }
}

export function SuccessComponent(props) {
  const { svg = defaultSvgFile, shouldBlockOnEnterStrategy } = props;

  const dispatch = useDispatch()
  const { width, height } = useWindowSize();

  const successInfo = useSelector(networkSelectors.successInfo);
  const reduxState = useSelector(state => state);

  const isLoadingInitially = Boolean(props?.strategies?.onEnter && shouldBlockOnEnterStrategy);
  const [isLoading, setIsLoading] = useState(isLoadingInitially);

  useEffect(() => {
    async function entryStrategyExecution() {
      if (isEmpty(successInfo) && !isEmpty(props.strategies)) {
        try {
          await props.strategyInstance.executeStrategy(props.strategies, 'onEnter');
        } catch (err) {
          captureExceptionWithContext(err, {
            error: "Error when executing strategy function in SuccessComponent entryStrategyExecution",
            strategyParamsJSON: JSON.stringify(props?.strategies?.onEnter?.params)
          });

          if (props?.strategies?.onEnterError) {
            props.strategyInstance.executeStrategy(props.strategies, 'onEnterError')
          } else {
            dispatch(errorActions.setHasErrored(true));
          }
        }
        finally {
          if (shouldBlockOnEnterStrategy) {
            setIsLoading(false);
          }
        }
      }
    }

    entryStrategyExecution();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const extraTemplateTokensToValues = useMemo(() => {
    const { valuePathMap, valuePathMapUseQueryString } = props;
    if (!valuePathMap) return;

    return Object.keys(valuePathMap).reduce(
      (acc, templateToken) => {
      const valuePath = valuePathMap[templateToken];
      const formatter = valuePath?.formatter && formatterLookup[valuePath.formatter];

      const queryStringParams = new URLSearchParams(window.location?.search);
      const rawValue = !valuePathMapUseQueryString ? get(reduxState, valuePath.value) : queryStringParams.get(valuePath.value)
      const value = (formatter)
        ? formatter(rawValue)
        : rawValue;

      return ({
        ...acc,
        [templateToken]: value
      });
    }, {})
  }, [props, reduxState])

  // This is either the application number, permit number, confirmation number, etc to display in the confirmation view
  const dynamicContent = useMemo(() => {
    const { valuePath, valueQueryStringParam } = props;
    if (valuePath) {
      return get(successInfo, valuePath)
    }

    const searchParams = new URLSearchParams(window.location?.search);
    const rawValue = searchParams.get(valueQueryStringParam);

    const sanitizedResult = /^[A-Za-z0-9]*/.exec(rawValue);
    return sanitizedResult?.[0];
  }, [props, successInfo]);

  // Get the correct success view component
  const Component = getRenderComponent(props.type);

  if (isLoading) return <LoadingPage />;

  return (
    <Wrapper data-cy="success-view">
      {/* When explicitly defined in the config, then the confetti will not be rendered */}
      {!props.disableConfetti && (
        <Confetti width={width} height={height} recycle={false} numberOfPieces={500} duration={7} />
      )}

      <Component
        extraTemplateTokensToValues={extraTemplateTokensToValues}
        header={props.header}
        info={dynamicContent}
        message={props.message}
      />

      {(props?.formikComponents || []).map((f, i) => (
        renderFieldComponent(f, `formikConnectedSuccessComponents${i}`, props?.strategyInstance, props?.strategies)
      ))}

      <PageImage style={{ marginTop: '60px' }} svg={svg} />
    </Wrapper>
  );
}

export const Success = withFlowExit(SuccessComponent);
