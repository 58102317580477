import React, { useMemo } from 'react';
import { useFormikContext } from 'formik';
import { evaluateCondition } from 'configurable-form/configurable-form-utils';
import { isDocFormikRequired } from './utils';
import { UploadModule } from './file-upload-modules/upload-module';
import { withVisibility } from './../utils/with-visibility';

/**
 * Config:
 *
 * {
 *   "component": "FileUpload"
 *    !REQUIRED
 *    Type - String
 *
 *   "id": String for the React prop "key"
 *    !Required
 *    Type - String
 *
 *   "fileKey": Server-side label for the file being uploaded
 *    !Required
 *    Type - String
 *
 *   "required":
 *    Type - Boolean or Conditional Array
 *      See "evaluateCondition" function in "configurable-form-utils.js"
 *
 *   "headerText": See "document-text.jsx"
 *
 *   "helperText": See "document-text.jsx"
 *
 *   "dataCy": Cypress selector
 *    !Required
 *    Type - String
 *
 *   "additionalCSS": Additional CSS to apply
 *    Type - String
 * }
 */
export function FileUploadComponent(props) {
  const { values } = useFormikContext();

  const isRequired = useMemo(() => {
    if (isDocFormikRequired(props?.required)) return evaluateCondition(values, props?.required?.condition);

    // When the "required" config is just a Boolean
    return props?.required;
  }, [props?.required, values]);

  const computedProps = useMemo(() => {
    return {
      additionalCSS: props?.additionalCSS,
      fileKey: props?.fileKey,
      headerText: props?.headerText,
      helperText: props?.helperText,
    }
  }, [props?.additionalCSS, props?.fileKey, props?.headerText, props?.helperText]);

  return <UploadModule values={values} required={isRequired} {...computedProps} />;
}

export const FileUpload = withVisibility(FileUploadComponent);
