import { parse } from "csv-parse";
import { stringify } from "csv-stringify";
import * as XLSX from 'xlsx';

/**
 *
 * @param {Blob} blob
 * @return {Promise<string | ArrayBuffer>}
 */
export async function readBlob(blob, { binary = false } = {}) {
  const reader = new FileReader();

  return new Promise(resolve => {
    reader.addEventListener('load', (e) => resolve(e.target.result));
    // TODO: Read as buffer, support binary files like XLSX
    if (!binary) {
      reader.readAsText(blob);
    } else {
      reader.readAsArrayBuffer(blob);
    }
  });
}

/**
 *
 * @param {Blob} blob
 * @return {Promise<Record<string, object>[]>}
 */
export async function parseSpreadsheetBlobCSV(blob) {
  const textContent = await readBlob(blob);

  return new Promise((resolve, reject) => {
    parse(textContent, { columns: true },
      (err, records, info) => {
        if (err) {
          reject(err);
        } else {
          resolve(records);
        }
      });
  })
}

export async function parseSpreadsheetBlobXLSX(blob) {
  const binaryContent = await readBlob(blob, { binary: true });

  const workbook = XLSX.read(binaryContent, { type: "array" })
  const worksheet = workbook.Sheets['data'];
  return XLSX.utils.sheet_to_json(worksheet);
}

export async function stringifyToSpreadsheetCSV(records, columnOrder) {
  const csvContent = await new Promise((resolve, reject) => stringify(records,
    {
      header: true,
      columns: columnOrder
    },
    (err, output) => {
      if (err) {
        reject(err);
      } else {
        resolve(output)
      }
    }));

  return new Blob([csvContent], { type: "text/csv" });
}

export const SPREADSHEET_MIME_TYPES = {
  XLSX: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
}

export async function stringifyToSpreadsheetXLSX(records, columnOrder) {
  const worksheet = XLSX.utils.json_to_sheet(records, { header: columnOrder.map(c => c.key ? c.key : c) });
  worksheet['!cols'] = columnOrder.map((column, i) => ({ hidden: Boolean(column.hide), width: 20 }))
  const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
  const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  return new Blob([excelBuffer], { type: SPREADSHEET_MIME_TYPES.XLSX });
}

const mimeTypeToSpreasheetFns = {
  "text/csv": {
    parse: parseSpreadsheetBlobCSV,
    stringify: stringifyToSpreadsheetCSV,
  },
  [SPREADSHEET_MIME_TYPES.XLSX]: {
    stringify: stringifyToSpreadsheetXLSX,
    parse: parseSpreadsheetBlobXLSX,
  }
}

/**
 *
 * @param {File} file
 * @return {Promise<Record<string, any>[]>}
 */
export async function parseSpreadsheetFromFile(file) {
  const getRecordsFromSpreadsheetBlob = mimeTypeToSpreasheetFns[file.type]?.parse;
  if (!getRecordsFromSpreadsheetBlob) {
    throw new Error(`Spreadsheet MIME type not supported: ${file.type}`)
  }
  return getRecordsFromSpreadsheetBlob(file);
}

export async function stringifyToSpreadsheet(type, records, columnOrder) {
  const stringifyToSpreadsheetFn = mimeTypeToSpreasheetFns[type]?.stringify;
  if (!stringifyToSpreadsheetFn) {
    throw new Error(`Spreadsheet MIME type not supported: ${type}`)
  }
  return stringifyToSpreadsheetFn(records, columnOrder);
}

export function downloadBlob(blob, fileName) {
  const downloadUrl = URL.createObjectURL(blob)
  const anchorElement = document.createElement("a")
  anchorElement.href = downloadUrl;
  anchorElement.download = fileName;
  anchorElement.target = "_blank";
  anchorElement.click()

  setTimeout(() => URL.revokeObjectURL(downloadUrl), 2000);
}

