import * as appSelectors from 'app/state/app/app-selectors';
import { appActions } from 'app/state/app/app-slice';
import { loginActions } from 'app/state/login/login-slice';
import { payTotActions } from 'app/state/pay-tot/pay-tot-slice';
import { payloadActions } from 'app/state/payload/payload-slice';
import * as cs from 'common/common-styles';
import { DEFAULT_FORM_VALUE as emptyString, FLOWS } from 'common/constants';
import { LicenseLookup } from 'pages/flow-entry/license-lookup';
import { ReportRevenue } from 'pages/pay-flow/report-revenue-page/report-revenue';
import React, { useCallback, useEffect } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { FLOW_PATHS, PAY_TOT_PATHS } from 'routes/constants';
import { ConfigurableRoutes } from 'routes/custom/configurable-routes';
import { withFlowEntry } from 'routes/custom/entry-exit-wrappers';
import { MidFlowAuthRoute } from 'routes/custom/mid-flow-auth-route';
import { PayTaxStrategy } from 'strategies/customer-portal/pay-tax-strategy';
import { PayTotStrategy } from 'strategies/pay-tot-strategy';

const LicenseLookupHOC = withFlowEntry(LicenseLookup);

const Div = (props) => <div>{props.children}</div>;

export function PayTotRoutes() {
  const dispatch = useDispatch();

  const useCustomerPortal = useSelector(appSelectors.useCustomerPortal);

  const flows = useSelector(appSelectors.flows);
  const activeFlow = useSelector(appSelectors.activeFlow);

  useEffect(() => {
    dispatch(appActions.setActiveFlow(FLOWS.PAY_TOT));
    if (useCustomerPortal) {
      dispatch(
        payloadActions.updateFormikSnapshot(flows?.payTot?.initialFormikValues),
      );
    }
  }, [dispatch, useCustomerPortal, flows?.payTot?.initialFormikValues]);

  const reset = useCallback(() => {
    batch(() => {
      dispatch(payTotActions.resetFlow());
      dispatch(appActions.setStrPermitNumber(emptyString));
      /**
       * Must reset loggedInUsername when you go back to home page,
       * Otherwise, the app will remember that this is the last user that
       *  has logged into and attempt to skip the login process
       *  But the value for userEmail in PayTOT slice has been reset, which
       *    is checked in MidFlowAuthRoute and forcibly take the user to the home page
       */
      dispatch(loginActions.setLoggedInUsername({ username: emptyString }));
    });
  }, [dispatch]);

  // Must wait for the active flow to be set so all selectors specific to this flow work
  if (!activeFlow) return null;

  const View = useCustomerPortal ? Div : cs.ViewContainer;

  return (
    <View>
      <Switch>
        <Route exact path={FLOW_PATHS.payTot}>
          <Redirect
            to={`${FLOW_PATHS.payTot}/${flows?.payTot?.entrySubPath}`}
          />
        </Route>

        <Route exact path={PAY_TOT_PATHS.findLicense}>
          <LicenseLookupHOC reset={reset} />
        </Route>

        {
          // If using the CustomerPortal, the ReportRevenue route will be added as part of the configurable routes below
          !useCustomerPortal && (
            <MidFlowAuthRoute
              exact
              path={PAY_TOT_PATHS.reportRevenue}
              component={ReportRevenue}
            />
          )
        }

        <ConfigurableRoutes
          configurablePages={flows?.payTot?.configurablePages}
          strategyInstance={
            useCustomerPortal
              ? PayTaxStrategy.getInstance()
              : PayTotStrategy.getInstance()
          }
        />
      </Switch>
    </View>
  );
}
