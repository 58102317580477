import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import * as networkSelectors from 'app/state/network/network-selectors';
import { LicenseReportSummaries, PaymentPeriodSummaries, PaymentPeriodSummary, TaxSummaryHeader } from './styles';
import styled from "styled-components";
import { LicenseReportSummary } from "./license-report-summary";
import { TotalPaymentLineItem } from "./license-report-summary/line-item";
import { Button, InputPicker } from "rsuite";
import {
  useBulkTOTEnabled,
  useLicensesInReportMap
} from "../../../../pages/pay-flow/report-revenue-page/utils";
import { downloadBlob, SPREADSHEET_MIME_TYPES, stringifyToSpreadsheet } from "../../../../pages/spreadsheet-utils";
import get from "lodash/get";
import template from "lodash/template";

const MultiPropertyPaymentPeriodLabel = styled.p`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
`;

const BulkTOTControls = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  gap: 10px;
`

function processColumnNameTemplate (templateString) {
  return template(templateString)({ paymentPeriodLabel: "period" })
}

// See "TaxSummary" of "tax-summary.ts" and "createTaxSummary" of "create-tax-summary.ts"
export function TaxSummary(props) {
  const { providers, lineItems } = props;

  const paymentFees = useSelector(networkSelectors.paymentFees);

  const {
    providerLineItems,
    providerOrder,
  } = useMemo(() => ({
    providerLineItems: providers.map(({ label }) => label),
    providerOrder: providers.map(({ provider }) => provider),
  }), [providers]);

  const periodDropdownOptions = useMemo(() => paymentFees.paymentPeriods.map((p, i) => ({
    label: p.period, value: i
  })), [paymentFees])
  const [currentlySelectedPeriod, setCurrentlySelectedPeriod] = useState();
  const [currentlySelectedLicense, setCurrentlySelectedLicense] = useState();
  const licenseNumberToLicense = useLicensesInReportMap();

  const selectedLicenseNumbers = useMemo(() => Array.from(new Set(
    paymentFees.paymentPeriods.flatMap(p => p.licenseReports.map(r => r.licence_no)))),
  [paymentFees.paymentPeriods])

  const licenseDropdownOptions = useMemo(() => {
    return Array.from(selectedLicenseNumbers).map(licId => {
      const lic = licenseNumberToLicense[licId];

      return ({
        label: `${lic.license} - ${lic.propertyAddress}`,
        value: licId
      })
    })
  }, [licenseNumberToLicense, selectedLicenseNumbers]);

  const bulkTOTMode = useBulkTOTEnabled()

  const filteredLicenseReportsByPeriod = useMemo(() => {
    if (!currentlySelectedLicense) return [];

    return paymentFees.paymentPeriods.reduce((acc, p) => ({
      ...acc,
      [p.period]: p.licenseReports.filter(r => (r.licence_no === currentlySelectedLicense.license))
    }), {});
  }, [currentlySelectedLicense, paymentFees.paymentPeriods]);

  const shouldDisplayPeriodSummaries = !bulkTOTMode || currentlySelectedLicense;

  const periodsToDisplay = useMemo(() => {
    if (!shouldDisplayPeriodSummaries) return [];
    return currentlySelectedPeriod ? [currentlySelectedPeriod] : paymentFees.paymentPeriods;
  }, [currentlySelectedPeriod, paymentFees.paymentPeriods, shouldDisplayPeriodSummaries]);

  const downloadSpreadsheet = useCallback(async () => {
    const providerSpecificLineItems = [];
    const globalLineItems = [];
    lineItems
      .forEach(({ lineItems, totalLineItem }) => [...lineItems, totalLineItem].forEach((item) => {
        if (item.useFullData) {
          globalLineItems.push(item);
        } else {
          providerSpecificLineItems.push(item);
        }
      }))

    const providerSpecificColumns = providers.flatMap(provider => {
      return providerSpecificLineItems.map(lineItem => {
        return ({
          name: processColumnNameTemplate(`${provider.label} - ${lineItem.header}`),
          valueGetter: (period, licenseReport) => {
            const taxableActivity = licenseReport.misc.taxableActivities.find(t => t.provider === provider.provider)
            return get(taxableActivity, lineItem.field)
          }
        })
      })
    })

    const globalColumns = globalLineItems.map((lineItem) => ({
      name: processColumnNameTemplate(lineItem.header),
      valueGetter: (period, licenseReport) => get(licenseReport, lineItem.field),
    }));

    const allColumns = [
      {
        name: "Period",
        valueGetter: (period) => period.period,
      },
      {
        name: "Permit Number",
        valueGetter: (period, licenseReport) => licenseReport.licenseId,
      },
      {
        name: "Property Address",
        valueGetter: (period, licenseReport) => licenseNumberToLicense[licenseReport.licenseId]?.propertyAddress,
      },
      ...providerSpecificColumns,
      ...globalColumns
    ];

    const records = paymentFees.paymentPeriods.flatMap(period => period.licenseReports.map((licenseReport) => (
      allColumns.reduce((record, column) => {
        return ({
          ...record,
          [column.name]: column.valueGetter(period, licenseReport)
        })
      }, {}))));

    const columnNames = allColumns.map(({ name }) => name);

    const spreadsheetBlob = await stringifyToSpreadsheet(SPREADSHEET_MIME_TYPES.XLSX, records, columnNames);
    downloadBlob(spreadsheetBlob, "TaxSummary.xlsx");
  }, [licenseNumberToLicense, lineItems, paymentFees.paymentPeriods, providers]);

  return (
    <div>
      <TaxSummaryHeader>Tax Summary</TaxSummaryHeader>

      {bulkTOTMode && (
        <BulkTOTControls>
          <InputPicker onChange={periodIndex => setCurrentlySelectedPeriod(paymentFees.paymentPeriods[periodIndex])} data={periodDropdownOptions}/>
          <InputPicker data={licenseDropdownOptions} onChange={licId => setCurrentlySelectedLicense(licenseNumberToLicense[licId])} />
          <Button onClick={downloadSpreadsheet}>Download Tax Summary</Button>
        </BulkTOTControls>
      )}

      <PaymentPeriodSummaries>
        {!shouldDisplayPeriodSummaries && bulkTOTMode && <p>Please select a license to display payment summary</p>}

        {periodsToDisplay.map((period) => (
          <PaymentPeriodSummary key={period.period}>
            {period.licenseReports.length > 1 && (
              <MultiPropertyPaymentPeriodLabel>
                {period.period}
              </MultiPropertyPaymentPeriodLabel>
            )}

            <LicenseReportSummaries>
              {(bulkTOTMode ? (filteredLicenseReportsByPeriod[period.period] || []) : period.licenseReports).map((licenseReport) => {
                return (
                  <LicenseReportSummary
                    key={licenseReport.licenseId}
                    licenseReport={licenseReport}
                    period={period}
                    providerLineItems={providerLineItems}
                    providerOrder={providerOrder}
                    lineItems={lineItems}
                    hidePeriodName={period.licenseReports.length > 1}
                    hidePropertyLabel={period.licenseReports.length < 2}
                  />
                )
              })}
            </LicenseReportSummaries>
          </PaymentPeriodSummary>
        ))}
      </PaymentPeriodSummaries>

      <TotalPaymentLineItem items={[paymentFees.summary.total]} />
    </div>
  );
}
