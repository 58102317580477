import { ApplyLicenseStrategy } from 'strategies/apply-license-strategy';
import { PayTotStrategy } from 'strategies/pay-tot-strategy';
import { UpdateCertificateStrategy } from 'strategies/update-certificate-strategy';
import { RenewalStrategy } from './renewal-strategy';
import { CustomRouteStrategy } from "./custom-route-strategy";

// TODO: Keys must match flows defined in config. Should create schema and get the key names
// from shared constants file
const strategyFlowMap = {
  applyLicense: ApplyLicenseStrategy,
  payTot: PayTotStrategy,
  updateCertificate: UpdateCertificateStrategy,
  renewLicense: RenewalStrategy,
};

export function strategyFactory(flowName) {
  let strategyClass = strategyFlowMap[flowName];
  if (!strategyClass) {
    console.warn('No navigation strategy found for this login process');
    strategyClass = CustomRouteStrategy;
  }

  return strategyClass.getInstance();
}
