import * as appSelectors from 'app/state/app/app-selectors';
import { DASHBOARD_INFLIGHT_STATUS } from 'app/state/dashboard/constants';
import * as dashboardSelectors from 'app/state/dashboard/dashboard-selectors';
import { dashboardActions } from 'app/state/dashboard/dashboard-slice';
import { networkActions } from 'app/state/network/network-slice';
import { payloadActions } from 'app/state/payload/payload-slice';
import { DEFAULT_FORM_VALUE } from 'common/constants';
import pluralize from 'pluralize';
import React, { useCallback, useEffect, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';

import {
  SORT_ACTION_ASC_EXPIRATION_DATE,
  TAX_STATUS_FILTER_ALL,
} from '../../constants';
import { Header } from '../../shared/dashboard-styles';
import { DataFetchSpinner } from '../../shared/data-fetch-spinner';
import { FilterContext } from '../permits-list/standard-filters/filter-context';
import { TableSettings } from '../permits-list/standard-filters/table-settings';
import { PermitsTable } from './permits-table/permits-table';

function PermitsListViewComponent() {
  const certificateVerbiage = useSelector(appSelectors.certificateVerbiage);
  const pluralCertificateVerbiage = pluralize(certificateVerbiage);

  const { status, data } = useSelector(dashboardSelectors.userPermitsList);
  const { status: renewableLicenseStatus, data: renewableLicenseData } = useSelector(dashboardSelectors.bulkRenewableLicenses);
  

  return (
    <div>
      <Header>
        <p>My {pluralCertificateVerbiage}</p>
        <DataFetchSpinner show={status === DASHBOARD_INFLIGHT_STATUS} />
      </Header>

      <TableSettings />

      <PermitsTable status={status} data={data} renewableLicenseData={renewableLicenseData} renewableLicenseStatus={renewableLicenseStatus} />
    </div>
  );
}

export function PermitsListView() {
  const dispatch = useDispatch();

  // Stores the user input for the search box
  const [searchInput, setSearchInput] = useState(DEFAULT_FORM_VALUE);

  // Stores the user selection for the tax filtering method
  const [taxFilterMethod, setTaxFilterMethod] = useState(TAX_STATUS_FILTER_ALL);

  // Stores the user selection for the permit sorting method
  const [sortMethod, setSortMethod] = useState(SORT_ACTION_ASC_EXPIRATION_DATE);

  const handleSearchInputChange = useCallback((e) => {
    const inputString = String(e.target.value).trimStart();
    setSearchInput(inputString);
  }, []);

  useEffect(() => {
    batch(() => {
      // Clean up actions to ensure specific parts of state are reset
      dispatch(payloadActions.setAdditionalInfo({}));
      dispatch(dashboardActions.setActiveSinglePermitApplicationNumber(null));

      /**
       * This is for cleaning up the permit application number or permit number
       * set in state by the Single Permit view. We do this here rather than
       * when the component is unmounted, because we need that information for the
       * update and renewal flow and redirecting the user to another view  will
       * clear it from state.
       */
      dispatch(networkActions.replaceMiscInfo({ permitNumber: undefined }));
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <FilterContext.Provider
      value={{
        searchInput: searchInput.trim(),
        handleSearchInputChange,
        taxFilterMethod,
        setTaxFilterMethod,
        sortMethod,
        setSortMethod,
      }}
    >
      <PermitsListViewComponent />
    </FilterContext.Provider>
  );
}
