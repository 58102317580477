import styled from 'styled-components';
import { SectionHeaderText } from "../revenue-inputs-styles";

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  gap: 15px;
`;

export const GRID2COLUMN_ROW_GAP_PX = 15;
export const Grid2Column = styled.div`
  display: grid;
  grid-template-columns: 1fr minmax(0, 200px);
  gap: ${GRID2COLUMN_ROW_GAP_PX}px;
`;

export const Centered = styled.div`
  display: flex;
  align-items: center;
`;

export const Header = styled(SectionHeaderText)`
  margin: 0;
  ${({ large }) => large ? 'font-size: 21px' : '' }
`;

export const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;
