/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { PaymentProcessorContainer } from '../card/stripe-styles';
import { useDeckardStripe } from "../use-stripe-payment-ui-state";
import { StripeACHAccountHolderForm } from "./stripe-ach-account-holder-form";
import { StripeACHPaymentConfirmationForm } from "./stripe-ach-payment-confirmation-form";

export function StripeACH(props) {
  const stripe = useDeckardStripe();

  const [clientSecret, setClientSecret] = useState(undefined);
  const [bankAccountDetails, setBankAccountDetails] = useState({});

  return (
    <PaymentProcessorContainer>
      {!clientSecret && (
        <StripeACHAccountHolderForm
          stripe={stripe}
          setClientSecret={setClientSecret}
          setBankAccountDetails={setBankAccountDetails}
          signatory={props.signatory}
          email={props.email}
        />
      )}
      {clientSecret && (
        <StripeACHPaymentConfirmationForm
          stripe={stripe}
          setClientSecret={setClientSecret}
          clientSecret={clientSecret}
          setBankAccountDetails={setBankAccountDetails}
          bankAccountDetails={bankAccountDetails}
          signatory={props.signatory}
          email={props.email}
          onSuccess={props.onSuccess}
        />
      )}
    </PaymentProcessorContainer>
  )
}
