import { useVirtualizer } from '@tanstack/react-virtual';
import * as appSelectors from 'app/state/app/app-selectors';
import { DASHBOARD_SUCCESS_STATUS } from 'app/state/dashboard/constants';
import { dataTableRowActionButtonColumn, dataTableRowRenewActionButtonColumn } from 'configurable-form/components/data-table/constants';
import * as JsSearch from 'js-search';
import concat from 'lodash/concat';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import React, { useContext, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';

import {
  filterByTaxStatus,
  orderPermitsByField,
  preparePermitsForListView,
} from '../helpers';
import { FilterContext } from '../standard-filters/filter-context';
import { ADiv, PermitsScroller, RDiv } from '../styles';
import { PermitRow } from './permit-row';
import { PermitsTableHeader } from './permits-table-header';
import { TableActions } from './table-actions/table-actions';
import { DISABLE_BULK_RENEWAL_FLOW_PATH, DISABLE_SINGLE_RENEW_FLOW_PATH } from 'common/constants';

const LAYOUT_PATH = 'dashboard.fullPermitsDisplayLayout';

export function PermitsTable(props) {
  const { status: userPermitsFetchStatus, data: userPermitsData, renewableLicenseData } = props;

  const { searchInput, taxFilterMethod, sortMethod } =
    useContext(FilterContext);

  const {
    app: { allowOmittingLatestPeriod },
  } = useSelector(appSelectors.stats);

  const disableBulkRenewalFlow = useSelector(
    appSelectors.getFromPortalStats(DISABLE_BULK_RENEWAL_FLOW_PATH),
  );

  const disableSingleRenewalFlow = useSelector(
    appSelectors.getFromPortalStats(DISABLE_SINGLE_RENEW_FLOW_PATH),
  );

  // "rawLayout" should not be used directly.
  const rawLayout = useSelector(appSelectors.getFromPortalStats(LAYOUT_PATH));

  const layout = useMemo(
    () =>
      concat(
        rawLayout, // only show renew column of one or more permits are eligible to be renewed and either the single or bulk renew flow is enabled
        userPermitsFetchStatus !== DASHBOARD_SUCCESS_STATUS
          ? []
          : ((!disableSingleRenewalFlow || !disableBulkRenewalFlow) && renewableLicenseData?.length > 0)
          ? [dataTableRowRenewActionButtonColumn, dataTableRowActionButtonColumn]
          : dataTableRowActionButtonColumn
      ),
    [rawLayout, userPermitsFetchStatus, disableSingleRenewalFlow, disableBulkRenewalFlow, renewableLicenseData],
  );


  // ------------------------------------------------------
  // Determining which permits to display
  // ------------------------------------------------------

  const preparedPermits = useMemo(() => {
    if (isNil(userPermitsData)) return [];
    return preparePermitsForListView(
      userPermitsData,
      allowOmittingLatestPeriod,
      renewableLicenseData
    );
  }, [userPermitsData, allowOmittingLatestPeriod, renewableLicenseData]);

  const taxFilteredPermits = useMemo(() => {
    return preparedPermits.filter((p) => filterByTaxStatus(taxFilterMethod, p));
  }, [preparedPermits, taxFilterMethod]);

  const searchClass = useMemo(() => {
    const [pKey, ...keys] = rawLayout.map(({ key }) => key);

    const search = new JsSearch.Search(pKey);
    search.indexStrategy = new JsSearch.AllSubstringsIndexStrategy();

    [pKey, ...keys].forEach((k) => search.addIndex(k));

    search.addDocuments(taxFilteredPermits);

    return search;
  }, [rawLayout, taxFilteredPermits]);

  const filteredPermits = useMemo(() => {
    const noFilterSet = isEmpty(preparedPermits) || isEmpty(searchInput);
    if (noFilterSet) return taxFilteredPermits;

    return searchClass.search(searchInput);
  }, [preparedPermits, searchClass, searchInput, taxFilteredPermits]);

  const sortedListOfPermits = useMemo(() => {
    if (isEmpty(filteredPermits)) return filteredPermits;
    return orderPermitsByField(sortMethod, filteredPermits);
  }, [filteredPermits, sortMethod]);

  // ------------------------------------------------------
  // Virtualization
  // ------------------------------------------------------

  const ref = useRef(null);

  const virtualizer = useVirtualizer({
    count: sortedListOfPermits.length,
    getScrollElement: () => ref.current,
    estimateSize: () => 50,
    overscan: 10,
  });

  const items = virtualizer.getVirtualItems();

  return (
    <div>
      <TableActions />

      <PermitsScroller ref={ref}>
        <PermitsTableHeader layout={layout} />

        {/* The actual list of permits */}
        <RDiv height={virtualizer.getTotalSize()}>
          {items.map((vRow) => (
            <ADiv
              key={vRow.index}
              data-index={vRow.index}
              start={items[0]?.start ?? 0}
              ref={virtualizer.measureElement}
            >
              <PermitRow
                index={vRow.index}
                layout={layout}
                permit={sortedListOfPermits[vRow.index]}
              />
            </ADiv>
          ))}
        </RDiv>
      </PermitsScroller>
    </div>
  );
}
