import React, { useMemo } from 'react';
import { useFormikContext } from 'formik';
import dayjs from 'dayjs';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import set from 'lodash/set';
import template from 'lodash/template';
import { parseHTMLString } from 'utils/utility-functions';
import { AccordionItem } from './accordion-item';

function formatter(value, type, ...args) {
  if (type === 'date' && !isNil(value)) return dayjs(String(value)).format(...args);
  return value;
}

export function TemplatedAccordion(props) {
  const { values: formikValues } = useFormikContext();

  const templatedValue = useMemo(() => {
    const options = props.template.options.reduce((map, el) => {
      if (isString(el)) return set(map, el, get(formikValues, el));

      if (isObject(el)) {
        const { path, params } = el;
        const result = formatter(get(formikValues, path), ...params);
        return set(map, path, result);
      }

      return map;
    }, {});

    return parseHTMLString(template(props.template.string)(options));
  }, [formikValues, props.template.options, props.template.string]);

  return (
    <AccordionItem
      valueFromHOC={templatedValue}
      dataKeyQuestion={props?.dataKeyQuestion}
      hideWhen={props?.hideWhen}
    />
  );
}
