import { useCallback, useEffect, useMemo, useState } from 'react';
import { useElements, useStripe, } from '@stripe/react-stripe-js';
import { strRegistrationApiSender } from "utils/str-registration-api-sender";
import { loadStripe } from "@stripe/stripe-js";
import { usePaymentUIState } from "../../use-payment-ui-state";
import { FLOWS } from 'common/constants';
import { useSelector } from 'react-redux';
import * as appSelectors from 'app/state/app/app-selectors';

export function useStripePaymentUIState({ email, signatory }) {
  const stripe = useStripe();
  const elements = useElements();

  const paymentUIState = usePaymentUIState({ email, signatory });

  const {
    shouldLockPayments,
    runWithUILockAndExceptionHandling,
  } = paymentUIState;

  const isPayButtonDisabled = useMemo(() => shouldLockPayments || !stripe || !elements,
    [elements, shouldLockPayments, stripe]);


  const runWithStripeUILockAndExceptionHandling = useCallback(async (runnable) => {
      if (!stripe || !elements) return;
      return runWithUILockAndExceptionHandling(runnable);
    }, [elements, runWithUILockAndExceptionHandling, stripe]);

  return {
    ...paymentUIState,
    stripe,
    elements,
    isPayButtonDisabled,
    runWithStripeUILockAndExceptionHandling,
  }
}

export function useDeckardStripe() {
  const [stripe, setStripe] = useState(null);
  const activeFlow = useSelector(appSelectors.activeFlow);
  const isTotFlow = activeFlow === FLOWS.PAY_TOT;

  useEffect(() => {
    (async () => {
      const { data } = await strRegistrationApiSender.get(`/stripe-public`);
      const totKey = data?.tot?.stripePublishableKey
      const stripePublishableKey = (isTotFlow && totKey)
        ? totKey
        : data?.stripePublishableKey;
      const stripe = await loadStripe(stripePublishableKey)
      setStripe(stripe);
    })()
  }, [isTotFlow]);

  return stripe;
}

