import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useField } from 'formik';
import Fuse from 'fuse.js';
import { AutoComplete, Button, InputGroup } from 'rsuite';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import styled from 'styled-components/macro';
import * as appSelectors from 'app/state/app/app-selectors';
import * as networkSelectors from 'app/state/network/network-selectors';
import * as cs from 'common/common-styles';
import { inputFieldTestId } from 'test/constants';
import { captureExceptionWithContext } from 'utils/sentry-functions';
import { formatSmartyAddressData, lookupSmartyAddress } from 'utils/smartystreets-sender';
import { Checkbox } from '../checkbox';
import { useDisabled } from '../input-field';
import { FormikError, InputAdornment } from '../inputs/shared-components';
import { createInternalFormikFieldName, parseHTMLString } from 'utils/utility-functions';
import { useInput } from '../utils/use-input';
import { withVisibility } from '../utils/with-visibility';

const minimumCharsRequiredBeforeQuery = 4;

const TARGET_KEYS = [
  'label',
  'suggestion.street_line',
  'suggestion.secondary',
  'suggestion.city',
  'suggestion.state',
  'suggestion.zipcode',
  'suggestion.entries',
  'suggestion.source',
];

const SelectionConfirmationMessage = styled.div`
  margin-top: 10px;
`;

const AutocompleteInput = styled(AutoComplete)`
  input {
    max-width: 400px;
    height: 36px;
    border-radius: 5px;
    border: 1px solid rgb(207, 207, 207);
  }
`;

const StyledInputGroup = styled(InputGroup)`
  /* This must match the max width of "AutocompleteInput" */
  max-width: 400px;
`;

const StyledLinkButton = styled(Button).attrs({
  appearance: 'link',
})`
  padding: 0;
`;

function findClosestParent(element, predicate) {
  if (predicate(element)) return element;

  const { parentElement } = element;

  if (!parentElement) return null;

  return findClosestParent(parentElement, predicate);
}

function isBlurEventToFocusOnNewElement(e, newElement) {
  if (!e.relatedTarget) return false;
  const container = findClosestParent(e.relatedTarget, element => element === newElement);

  return Boolean(container);
}

function CopyFromStoreControl({
  payloadFromStore,
  /* TODO: Review whether we need cbField later */
  cbField,
  dataCy,
  label,
  useLinkButton,
  setAddressFieldValue,
  addressComponentsField,
  onBlur,
  hideControl = false,
}) {
  const appState = useSelector(appSelectors.appState)

  const setAddress = useCallback((options) => {
    let value = get(appState, payloadFromStore);
    /**
     * TODO: Commas are replaced because they might interfere with address suggestion API (Smarty)
     */
    if (addressComponentsField?.validation) {
      value = value.replaceAll(/( *, *| +)/g, ' ').trim();
    }
    setAddressFieldValue(value, options);
  }, [appState, payloadFromStore, addressComponentsField?.validation, setAddressFieldValue]);

  const handleClick = useCallback((e) => {
    setAddress();
  }, [setAddress]);

  const handleCheckboxClick = useCallback((e) => {
    const checkboxCurrentlyChecked = Boolean(cbField.value);
    if (checkboxCurrentlyChecked) return

    return setAddress({ disableQueryAddress: true });
  }, [cbField.value, setAddress]);

  if (hideControl) return null;

  if (!useLinkButton) {
    return (
      <Checkbox
        {...cbField}
        onBlur={onBlur}
        data-cy={dataCy}
        label={label}
        onClick={handleCheckboxClick}
      />
    );
  }

  return (
    <StyledLinkButton
      onClick={handleClick}
      onBlur={onBlur}
      data-cy={dataCy}
    >
      {label}
    </StyledLinkButton>
  )
}

function AddressInputFieldsComponent(props) {
  const place = useSelector(appSelectors.place);

  const [suggestions, setSuggestions] = useState([]);

  const originalLicenseInfo = useSelector(networkSelectors.originalLicenseInfo);
  const disabled = useDisabled(props, originalLicenseInfo);

  const { field, meta, helpers } = useInput({ ...props, name: props.name });

  /**
   * Zip code validation doesn't occur in all jurisdictions, so this will generate a warning
   * in the console whenever the component is rendered
   */
  // we do not show a zipcode input field, but we validate and handle errors for the zip code using this formik hook.
  const addressComponentsFieldName = props.addressComponentsField?.name || createInternalFormikFieldName(`_${props.name}_addressComponents`);
  const addressComponentsField = useInput({
    name: addressComponentsFieldName,
    validation: props?.addressComponentsField?.validation
  });

  const [cbField] = useField({ name: `__${props.name}` });

  const updateSuggestions = useCallback((data) => {
    const suggestedAddresses = formatSmartyAddressData(data);
    setSuggestions(suggestedAddresses);
  }, []);

  const [isAddressQueryLoading, setIsAddressQueryLoading] = useState(false);

  const queryAddress = useCallback(async ({ search, selected }) => {
    setIsAddressQueryLoading(true);
    try {
      const data = await lookupSmartyAddress({ search, selected });
      updateSuggestions(data);
    } catch (err) {
      captureExceptionWithContext(err, { search, selected, place });
    } finally {
      setIsAddressQueryLoading(false);
    }
  }, [place, updateSuggestions]);

  const fuzzySearchableAddressSuggestions = useMemo(() =>
    new Fuse(suggestions, { keys: TARGET_KEYS }),
    [suggestions],
  );

  const rsuiteAutocompleteFilterBy = useCallback((value) => {
    return fuzzySearchableAddressSuggestions.search(value);
  }, [fuzzySearchableAddressSuggestions]);

  const autocompleteInputRef = useRef();

  const [warning, setWarning] = useState();

  const getWarningMessage = useCallback(() => {
    const noMatchesFoundMessage = props.addressComponentsField?.noMatchesFoundMessage;

    if (!suggestions.length && field.value && !isAddressQueryLoading && isNil(addressComponentsField.field.value)) {
      return noMatchesFoundMessage;
    }
  }, [addressComponentsField.field.value, field.value, isAddressQueryLoading, props.addressComponentsField?.noMatchesFoundMessage, suggestions.length]);

  useEffect(() => {
    setWarning(getWarningMessage());
  },[getWarningMessage])

  const useLinkButton = Boolean(props.payloadFromStoreLinkButtonText);

  const setAddressFieldValue = useCallback(async (value, { disableQueryAddress } = {}) => {
    setSuggestions([]);

    helpers.setValue(value, true);
    helpers.setTouched(true);

    addressComponentsField.helpers.setValue(undefined, true);

    if (disableQueryAddress) return;

    if (value.length >= minimumCharsRequiredBeforeQuery) await queryAddress({ search: value });

    autocompleteInputRef.current?.open();
  }, [addressComponentsField.helpers, helpers, queryAddress]);

  const onChange = useCallback(async (_, event) => {
    // Selecting an item from the autocomplete list will trigger this function, but with a different event type
    if (event.type !== 'change') return;

    field.onChange(event);
    return setAddressFieldValue(event.target.value);
  }, [field, setAddressFieldValue]);

  const onSelect = useCallback(async ({ containsMultipleUnits, search, selected, selectValue, suggestion }) => {
    const addressSetValue = containsMultipleUnits ? selectValue : selected;

    helpers.setValue(addressSetValue, true);
    addressComponentsField.helpers.setValue(suggestion, true);
    addressComponentsField.helpers.setTouched(true, false);

    if (containsMultipleUnits) await queryAddress({ search, selected });
  }, [helpers, queryAddress, addressComponentsField.helpers]);

  const containerRef = React.useRef();

  const onBlur = useCallback((e) => {
    if (suggestions.length) {
      addressComponentsField.helpers.setTouched(true, true);
    }

    if (!isBlurEventToFocusOnNewElement(e, containerRef.current)) {
      field.onBlur(e);
    }

    // We want to always make sure that the options popup is closed if the focus is lost
    // by the button when the user clicks on anything other than one of the options.
    // However, to distinguish focus being lost to one of the options vs to any other element
    // on the screen, we'd need to rely on some internal implementation details of the AutoComplete
    // component.
    // This is a workaround that allows enough time for one of the options to get selected
    // before asking the autocomplete to close. Otherwise, the autocomplete closes before
    // selecting the option that the user clicked.
    setTimeout(() => autocompleteInputRef.current?.close(), 250);
  }, [addressComponentsField.helpers, field, suggestions.length]);

  const { street_line, city, state, zipcode } = addressComponentsField.field.value || {};
  const streetAddressSuggestion = street_line &&
    [ street_line, city, state, zipcode ].join(', ');

  return (
    <div css={props.additionalCSS} ref={containerRef}>
      {props.label && <cs.InputTextFieldLabel>{props.label}</cs.InputTextFieldLabel>}

      <StyledInputGroup inside>
        <AutocompleteInput
          {...field}
          onBlur={onBlur}
          autoComplete={props.disableAutoComplete ? 'off' : 'on'}
          value={field.value}
          onChange={onChange}
          onSelect={onSelect}
          data={suggestions}
          data-cy={props.dataCyInput}
          data-testid={inputFieldTestId}
          disabled={disabled || cbField.value}
          ref={autocompleteInputRef}
          filterBy={rsuiteAutocompleteFilterBy}
        />

        {Boolean(props?.tooltip) && <InputAdornment tooltip={props?.tooltip} />}
      </StyledInputGroup>

      <FormikError {...meta} />
      {props?.subText && <cs.InputTextFieldSubLabel>{props?.subText}</cs.InputTextFieldSubLabel>}

      {props.showSelectionConfirmationMessage && streetAddressSuggestion && (
        <div>
          <SelectionConfirmationMessage>
            {props.selectionConfirmationMessage || 'Selected address:'}
          </SelectionConfirmationMessage>
          <cs.InputTextFieldLabel>{streetAddressSuggestion}</cs.InputTextFieldLabel>
        </div>
      )}

      {Boolean(suggestions.length) && addressComponentsField.meta?.touched && addressComponentsField.meta?.error && (
        <cs.ErrorMessage>{parseHTMLString(addressComponentsField.meta?.error?.[0])}</cs.ErrorMessage>
      )}

      {warning && <cs.ErrorMessage>{parseHTMLString(warning)}</cs.ErrorMessage>}

      {!disabled && (
        <CopyFromStoreControl
          payloadFromStore={props.payloadFromStore}
          cbField={cbField}
          useLinkButton={useLinkButton}
          label={props.payloadFromStoreLinkButtonText || props.checkboxText}
          dataCy={props.dataCyCheckbox}
          addressComponentsField={props.addressComponentsField}
          setAddressFieldValue={setAddressFieldValue}
          onBlur={onBlur}
          hideControl={props.hideControl}
        />
      )}
    </div>
  );
}

export const AddressInputField = withVisibility(AddressInputFieldsComponent);
