import { createSlice } from '@reduxjs/toolkit';
import { ONE as ACH_PAYMENT_TAB } from 'common/constants';
import mergeWith from 'lodash/mergeWith';
import appSettings from '../../../app-settings.json';

const INIT_STATE = {
  stats: null,
  translations: {},
  activePaymentMethodTab: ACH_PAYMENT_TAB,
  visitedFirstPageInFlow: false,
  activeFlow: "",
  addressOfRentalProperty: '',
  parcelNumber: '',
  strPermitNumber: '',
  enableEditingAllFields: false,
};

export const sampleSlice = createSlice({
  name: 'app', // will be used for creating the action types. E.g.: "app/serverReturnedStats"
  initialState: INIT_STATE,
  reducers: {
    serverReturnedStats: (state, action) => {
      const { stats } = action.payload;

      if (typeof stats == 'object') {
        const { environmentOverrides } = stats;
        const overrides = environmentOverrides?.[appSettings?.env];

        state.stats = mergeWith(
          {}, stats, overrides,
          (objValue, srcValue) => {
            /**
             * Do not merge arrays, just replace with the new one.
             * Useful for overriding entire flows and keeping only the "new" flow's
             * pages and components of those pages without having remnants from the prior flow.
             */
            if (Array.isArray(objValue) && Array.isArray(srcValue)) return srcValue;

            const objValueType = Object.prototype.toString.call(objValue);
            const srcValueType = Object.prototype.toString.call(srcValue);
            // Always take the source value when the types are not aligned
            if (objValueType !== srcValueType) return srcValue;
          }
        );
        return;
      }

      state.stats = stats;
    },

    serverReturnedTranslations: (state, action) => {
      const { translations } = action.payload;
      state.translations = translations;
    },

    changePaymentMethodTab: (state, action) => {
      const { newTab } = action.payload;
      state.activePaymentMethodTab = newTab;
    },

    setVisitedFirstPageInFlow: (state, action) => {
      const { value } = action.payload;
      state.visitedFirstPageInFlow = value;
    },

    setActiveFlow: (state, action) => {
      state.activeFlow = action.payload;
    },

    setStrPermitNumber: (state, { payload }) => {
      state.strPermitNumber = payload;
    },

    setParcelNumber: (state, { payload }) => {
      state.parcelNumber = payload;
    },

    setAddressOfRentalProperty: (state, { payload }) => {
      state.addressOfRentalProperty = payload;
    },

    setDynamicQuarterSelectedPeriodType: (state, { payload: dynamicQuarterSelectedPeriodType }) => {
      state.dynamicQuarterSelectedPeriodType = dynamicQuarterSelectedPeriodType;
    },

    setEnableEditingAllFields: (state, { payload: enableEditingAllFields }) => {
      state.enableEditingAllFields = enableEditingAllFields;
    },
  },
})

export const appActions = sampleSlice.actions;

export const appReducer = sampleSlice.reducer;
