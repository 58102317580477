import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import toPairs from 'lodash/toPairs';
import * as appSelectors from 'app/state/app/app-selectors';
import * as payloadSelectors from 'app/state/payload/payload-selectors';
import * as cs from 'common/common-styles';
import {
  DEFAULT_FORM_VALUE as emptyString
} from 'common/constants';
import { VerticalStepper } from 'common/stepper/vertical-stepper';
import { evaluateCondition } from 'configurable-form/configurable-form-utils';
import { WAITLISTED_APPLY_PATHS } from 'routes/constants';
import { history } from 'routes/history';
import { ApplyLicenseStrategy } from 'strategies/apply-license-strategy';
import { useRSTokenForAdminFlows } from 'utils/use-rs-token-for-admin-flows';
import { ExistingLicenses } from './modules/existing-licenses';
import { PropertyIdentification } from './modules/property-identification';
import { PortalPageContainer } from 'common/portal/styles';

const PROPERTY_IDENTIFICATION_STEP = 'PropertyIdentification';
const EXISTING_LICENSES_STEP = 'ExistingLicenses';

const Div = (props) => <div>{props.children}</div>;

export function PropertySearch(props) {
  const location = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(location?.search), [location]);
  const waitlistToken = queryParams.get('waitlistToken');
  const waitlistAPN = queryParams.get('apn');

  useRSTokenForAdminFlows();

  // Page configs
  const useCustomerPortal = useSelector(appSelectors.useCustomerPortal);

  const { search: config } = useSelector(appSelectors.renderConfig);
  const stepPositions = useSelector(appSelectors.stepPositions);

  // Data
  const formikSnapshot = useSelector(payloadSelectors.formikSnapshot);

  const [parcelNumber, setParcelNumber] = useState(emptyString);
  const [address, setAddress] = useState(emptyString);
  const [licenses, setLicenses] = useState([]);
  const [step, setStep] = useState(PROPERTY_IDENTIFICATION_STEP);

  useEffect(() => {
    history.push(location.pathname); // Clear URL search params on page load

    setLicenses([]); // Clear the existing list of licenses when the component mounted

    Boolean(props?.reset) && props?.reset(); // Reset the flow when the component mounted
    // Only want these resets to run once (when the component is mounted)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clearLicenses = useCallback(() => Boolean(licenses.length) && setLicenses([]), [licenses.length]);

  const { exitWithoutReset } = props;

  const urlDefinedContext = useMemo(() => {
    const urlParams = new URLSearchParams(location.search);
    const params = Object.fromEntries(urlParams);
    if (isEmpty(params)) return;

    const pairedParams = toPairs(params).flat();
    return get(config?.additionalContext, pairedParams);
  }, [config?.additionalContext, location.search]);

  const handleForwardNavigation = useCallback(({ useWaitlist, hasLicenses, propertyInfo }) => {
    const shouldDisplayExistingLicensesStep = step !== EXISTING_LICENSES_STEP && hasLicenses;

    const useTwoStepNavProcess = evaluateCondition(
      propertyInfo,
      config?.disablePropertySearchAutoViewShiftCondition,
      false,
    );

    if (useWaitlist) {
      exitWithoutReset(WAITLISTED_APPLY_PATHS.waitlist);
    } else if (useTwoStepNavProcess && urlDefinedContext && shouldDisplayExistingLicensesStep) {
      setStep(EXISTING_LICENSES_STEP); // Superseded by "skipMultipleLicensesNotice" in "View"
    } else {
      const strategies = urlDefinedContext?.strategies ?? config?.strategies;
      ApplyLicenseStrategy.getInstance().executeStrategy(strategies, 'onContinueClick');
    }
  }, [config?.disablePropertySearchAutoViewShiftCondition, config?.strategies, exitWithoutReset, step, urlDefinedContext]);

  const returnToPropertyIdentification = useCallback(() => {
    clearLicenses();
    setAddress(emptyString);

    /**
     * For "disablePropertySearchAutoViewShiftCondition" feature
     * Remove the query parameters from the URL used to determine additional contexts.
     * Reset the multi-step process to the initial step
     */
    if (urlDefinedContext) {
      history.push(location.pathname);
      setStep(PROPERTY_IDENTIFICATION_STEP);
    }
  }, [clearLicenses, location.pathname, urlDefinedContext]);

  const useExistingLicense = useMemo(() => {
    const {
      skipMultipleLicensesNotice = false,
      allowMultipleLicenses = false,
    } = config;

    // If a jurisdiction allows for multiple licenses per APN and if an APN already has a license.
    const jurisdictionMultipleLicenses = allowMultipleLicenses && Boolean(licenses.length);

    const useTwoStepNavProcess = evaluateCondition(
      formikSnapshot,
      config?.disablePropertySearchAutoViewShiftCondition,
      false,
    );

    return (
      !skipMultipleLicensesNotice &&
      (jurisdictionMultipleLicenses && (!useTwoStepNavProcess || step === EXISTING_LICENSES_STEP))
    );
  }, [config, formikSnapshot, licenses.length, step]);

  const View = useMemo(() => {
    if (useExistingLicense) return ExistingLicenses;
    return PropertyIdentification;
  }, [useExistingLicense]);

  const ViewWrapper = useCustomerPortal ? PortalPageContainer : Div;

  return (
    <ViewWrapper>
      <VerticalStepper
        activeStep={stepPositions.propertySearch}
        width={useCustomerPortal ? '100%' : undefined}
      >
        <cs.Wrapper>
          <View
            reset={props?.reset}
            parcelNumber={parcelNumber}
            setParcelNumber={setParcelNumber}
            address={address}
            setAddress={setAddress}
            licenses={licenses}
            setLicenses={setLicenses}
            clearLicenses={clearLicenses}
            onContinueClick={useExistingLicense ?
              () => handleForwardNavigation({}) :
              handleForwardNavigation
            }
            onBackClick={returnToPropertyIdentification}
            waitlistToken={waitlistToken}
            waitlistAPN={waitlistAPN}
            context={urlDefinedContext}
            disablePropertySearchAutoViewShiftCondition={config?.disablePropertySearchAutoViewShiftCondition}
            preventForwardNavigation={urlDefinedContext?.preventForwardNavigation}
          />

          {config?.svg && (
            <cs.ImageWrapper>
              <img src={`/assets/${config?.svg}.svg`} alt={config?.svg} />
            </cs.ImageWrapper>
          )}
        </cs.Wrapper>
      </VerticalStepper>
    </ViewWrapper>
  );
}
