import React, { useCallback, useMemo, useState } from 'react';
import { FormikConsumer } from 'formik';
import appSettings from '../../../app-settings.json';
import get from 'lodash/get';
import { getDebugImageFile } from "./debug-image";
import { dayjs } from "../../../utils/dayjs";
import { evaluateDeckardExpr } from "../../../strategies/deckard-expression-language";
import styled from "styled-components";

const devCognitoUserPool = 'us-east-2_Sb7DbMDQi';

export function shouldEnableDebugFeatures() {
  return appSettings?.cognitoUserPool === devCognitoUserPool;
}

const DeckardExprREPLWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
function DeckardExprREPL({ env }) {
  const [expr, setExpr] = useState("");
  const [error, setError] = useState(null);

  const jsonExpr = useMemo(() => {
    try {
      return JSON.parse(expr)
    }
    catch {
      return null;
    }
  }, [expr])

  const [result, setResult] = useState(null)
  const evaluate = useCallback(() => {
    try {
      setError("")
      setResult(evaluateDeckardExpr(jsonExpr, env))
    } catch (e) {
      setResult(null)
      setError(`An error has occurred: ${e.toString()}`);
      console.error(e)
    }
  }, [setError, setResult, env, jsonExpr])


  return (
    <DeckardExprREPLWrapper>
      <p>DeckardExpression Tester</p>
      <textarea value={expr} onChange={(e) => setExpr(e.target.value)} />
      <button type={"button"} disabled={!jsonExpr} onClick={evaluate}>Evaluate</button>
      {error}
      <pre>
        {result !== null && JSON.stringify(result)}
      </pre>
    </DeckardExprREPLWrapper>
  )

}
export function Debug() {
  const uploadAllFiles = useCallback(async () => {
    const fileKeyToUploadFn = get(window, `deckard.debug.handleUpload`, {});
    const timestamp = dayjs().format("MM/DD/YYYY HH:mm A");
    for (const fileKey of Object.keys(fileKeyToUploadFn)) {
      const uploadFile = fileKeyToUploadFn[fileKey];
      try {
        uploadFile(await getDebugImageFile(`${fileKey} ${timestamp}`));
      }
      catch(e) {
        console.error(e)
      }
    }
  }, []);

  if (!shouldEnableDebugFeatures()) return null;

  return (
    <div
      style={{
        marginTop:50,
        maxWidth: 600,
        display:'flex',
        flexDirection:'column',
        gap:16,
      }}
    >
      <button type={"button"} onClick={uploadAllFiles}>Upload all files</button>
      <div
        style={{
          textTransform: "uppercase",
          fontSize: 11,
          borderTopLeftRadius: 4,
          borderTopRightRadius: 4,
          fontWeight: 500,
          padding: ".5rem",
          background: "#555",
          color: "#FFF",
          letterSpacing: "1px"
        }}
      >
        Formik State
      </div>
      <FormikConsumer>
        {(
          { validationSchema, validate, onSubmit, ...rest } // eslint-disable-line no-unused-vars
        ) => (
          <>
            <DeckardExprREPL env={rest} />
          <pre
            style={{
              fontSize: ".85rem",
              padding: ".25rem .5rem",
              overflowX: "scroll"
            }}
          >
            {JSON.stringify(rest, null, 2)}
          </pre>
          </>
        )}
      </FormikConsumer>
    </div>
  );
}
