import { useSelector } from "react-redux";
import { useMemo } from "react";
import { evaluateCondition } from "../../../configurable-form-utils";
import template from "lodash/template";

export function useTemplatedMessages({ extraTemplateTokensToValues, info, message }) {
  const state = useSelector(s => s);

  return useMemo(() => {
    const messageConfigs = Array.isArray(message) ? message : [message];

    const messages = messageConfigs.map(config => {
      if (typeof config === 'string') return config;

      const { content, condition } = config;

      if (condition && !evaluateCondition(state, condition)) return null;

      return content;
    })

    const variables = { id: info, ...extraTemplateTokensToValues }
    return messages.map((m) => {
      try {
        return template(m)(variables);
      } catch (error) {
        console.error(
          `An error was found trying to get the values to fill the message ${message} with these values:`,
          variables,
          'No message will be displayed"',
        );
        return '';
      }
    });
  }, [extraTemplateTokensToValues, info, message, state]);
}
