import styled from 'styled-components';
import * as cs from 'common/common-styles';

export const ColoredText = styled.div`
  ${cs.textBase}
  font-size: 14px;
  font-weight: bold;
  color: ${(props) => props.color};
`;

export const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: ${(props) => (props.singleRow ? '15px' : '0')};
`;

export const WarningContainer = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const ErrorResult = styled.div`
  width: fit-content;
  margin-top: 40px;
  border-radius: 4px;
  border: 1px solid rgb(159, 58, 56);
  background-color: rgb(255, 247, 247);
  font-family: 'Lato';
  color: #9f3a38;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 12px;
`;

export const ResultInfo = styled.div`
  display: flex;
  align-items: center;
  ::before {
    display: flex;
    align-items: center;
    font-size: 22px;
    margin-right: 3px;
  }
`;

export const SuccessInfo = styled(ResultInfo)`
  color: #39b54a;

  ::before {
    content: '✓'
  }
`;

export const ErrorInfo = styled(ResultInfo)`
  font-weight: bold;
  line-height: 1.5;
  color: #ed1c24;
  display: flex;
  align-items: center;

  ${({ showIcon }) => !showIcon ? 'padding-left: 16px' : `
    ::before {
      content: '×';
      font-size: 22px;
      margin-right: 3px;
      display: flex;
      align-items: center;
    }
  `}
`;
