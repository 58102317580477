import { useSelector } from 'react-redux';
import * as payloadSelectors from 'app/state/payload/payload-selectors';

export function usePreselectedPropertyChanged(props) {
  const { parcelNumber } = props ?? {};

  const additionalInfo = useSelector(payloadSelectors.additionalInfo)
  const formikSnapshot = useSelector(payloadSelectors.formikSnapshot);

  return (
    additionalInfo?.parcelNumber !== parcelNumber ||
    !(additionalInfo.unitNumber === formikSnapshot.unitNumber)
  );
}
