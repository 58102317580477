import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import * as appSelectors from 'app/state/app/app-selectors';
import {
  DEFAULT_FORM_VALUE as emptyString, ENTRY_ERROR_PHASES
} from 'common/constants';
import { EmailList } from 'common/email-list';
import { findLicenseModes } from 'routes/constants';
import { parseHTMLString } from 'utils/utility-functions';
import * as lws from './license-warnings-styles';

const applicationType = 'application';
const statusSubmitted = 'submitted';
const statusIssued = 'issued';

const warningTextColorForAPNsWithLicense = '#f7981c'

function DefaultError(props) {
  if (!(props?.message)) return null;
  return <lws.ErrorResult>{parseHTMLString(props.message)}</lws.ErrorResult>;
}

function APNHasLicenses({ licenses }) {
  const cityInfo = useSelector(appSelectors.cityInfo);

  return (
    <lws.WarningContainer>
      {licenses.map((license) => (
        <lws.RowWrapper singleRow key={`${license.type}-${license.number}`}>
          <img
            src="/assets/warning-icon.svg"
            alt="Warning icon"
            style={{ marginRight: '6px' }}
          />

          <div data-cy="license-exists-warning">
            <lws.ColoredText color={warningTextColorForAPNsWithLicense}>
              A {cityInfo.certificateVerbiage}{' '}
              {license.type === applicationType ? applicationType : emptyString} #
              {license.number} has already been{' '}
              {license.type === applicationType ? statusSubmitted : statusIssued} for this
              address.
            </lws.ColoredText>

            <lws.ColoredText color={warningTextColorForAPNsWithLicense}>
              If you believe this is an error, contact us at{' '}
              <EmailList emails={cityInfo.contactEmail} color={warningTextColorForAPNsWithLicense} />
            </lws.ColoredText>
          </div>
        </lws.RowWrapper>
      ))}
    </lws.WarningContainer>
  );
}

function NoParcelForAddress() {
  return (
    <lws.WarningContainer>
      <lws.RowWrapper>
        <img
          src="/assets/icon-info-blue.svg"
          alt="Blue info icon"
          style={{ marginRight: '6px' }}
        />

        <div>
          <lws.ColoredText color="#1991eb">
            We cannot find a parcel number based on the address provided.
          </lws.ColoredText>

          <lws.ColoredText color="#1991eb">
            Please use the County website link above to look up your parcel number.
          </lws.ColoredText>
        </div>
      </lws.RowWrapper>
    </lws.WarningContainer>
  );
}

function NoAddressForParcel(props) {
  const cityInfo = useSelector(appSelectors.cityInfo);

  const errorMessage = useMemo(() => (
    props?.message || `Parcel number not found in ${cityInfo.name}`
  ), [cityInfo.name, props?.message]);

  return <DefaultError message={errorMessage} />;
}

const ERROR_COMPONENT = {
  [ENTRY_ERROR_PHASES.NoAPNForProperty]: NoParcelForAddress,
  [ENTRY_ERROR_PHASES.NoPropertyForAPN]: NoAddressForParcel,
  [ENTRY_ERROR_PHASES.APNHasLicenses]: APNHasLicenses,
  [ENTRY_ERROR_PHASES.Default]: DefaultError
}

export function PropertyAPNWarnings({ warningType, ...other }) {
  if (!ENTRY_ERROR_PHASES[warningType]) return null;

  const ErrorComponent = ERROR_COMPONENT[warningType];
  return <ErrorComponent {...other} />;
}

const fallbackErrorMessage = 'Something went wrong looking for your information. Please try again.';

const MAIN_ERROR_MESSAGE_INDEX = 0;
const PreserveInlineElementSpacing = 'span';

export function LookupError({ lookupMode, isLookupError }) {
  const cityInfo = useSelector(appSelectors.cityInfo);
  const renderConfig = useSelector(appSelectors.renderConfig);

  const messageMap = {
    [findLicenseModes.APN]: "No results found for this parcel number.",
    [findLicenseModes.EMAIL]: "No valid record found for this email address.",
    [findLicenseModes.LICENSE_NUMBER]: `No results found for this ${cityInfo.certificateVerbiage}.`,
  };

  const baseErrorMessage = messageMap[lookupMode] || fallbackErrorMessage;

  const additionalErrorMessage = renderConfig?.login?.additionalLicenseLookupErrorMessage;
  const errorMessages = useMemo(() => {
    if (!additionalErrorMessage) {
      return [baseErrorMessage];
    }
    return [
        baseErrorMessage,
        parseHTMLString(additionalErrorMessage),
    ];
  }, [baseErrorMessage, additionalErrorMessage]);

  if (!isLookupError) return null;

  const MessageComponent = lookupMode === findLicenseModes.EMAIL ? lws.ErrorInfo : lws.ErrorResult;

  return (
    <>
      {errorMessages.map((messageContent, i) => (
        <MessageComponent key={i} showIcon={i === MAIN_ERROR_MESSAGE_INDEX}>
          <PreserveInlineElementSpacing>{messageContent}</PreserveInlineElementSpacing>
        </MessageComponent>
      ))}
    </>
  )
}
