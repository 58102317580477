import React from 'react';
import { Grid, Row, Col } from 'rsuite';
import { useSelector } from 'react-redux';
import * as dashboardSelectors from 'app/state/dashboard/dashboard-selectors';
import { TaxableReceiptsMonthOfYearComponent } from './taxable-recepits-month-of-year';

export function TaxableReceiptsComponent({ deductionsLabel, salesLabel, taxableReceiptsLabel, showPropertyOccupancySection }) {
    const bulkLicensesToPayTaxOn = useSelector(
        dashboardSelectors.bulkLicensesToPayTaxDateDesc
    );

    return (
        <Grid fluid style={{ marginTop: '40px' }}>
            <Row>
                <Col xs={20}>
                    {
                        bulkLicensesToPayTaxOn?.map((item, index) => (
                            <TaxableReceiptsMonthOfYearComponent key={index} periodData={item} deductionsLabel={deductionsLabel} salesLabel={salesLabel}
                                taxableReceiptsLabel={taxableReceiptsLabel} showPropertyOccupancySection={showPropertyOccupancySection}
                                periodSpacing={index === bulkLicensesToPayTaxOn?.length - 1 ? '0px' : '50px'}
                                propertySpacing={'25px'}
                            />
                        ))
                    }
                </Col>
            </Row>
        </Grid>
    );
}