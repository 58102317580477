import styled from "styled-components";
import { useSelector } from "react-redux";
import * as appSelectors from "../../../../../app/state/app/app-selectors";
import { TOTPaymentPeriodSection } from "../tot-payment-periods/tot-payment-period-section";
import { currencyFormatter, getPeriodFormikPath } from "../../utils";
import React from "react";

const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  font-size: 18px;
  font-weight: bold;
  margin-top: 80px;
`;
const TotalTaxableReceipts = ({ paymentPeriods }) => {
  const { reporting } = useSelector(appSelectors.renderConfig);

  const totalTaxableReceipts = Object.values(paymentPeriods)
    .flatMap(
      period => period.licenseReports?.filter(r => r.selected).flatMap(r => r.taxableActivities.map(activity => activity?.taxableReceipts))
    )
    .filter(receipt => receipt !== undefined)
    .map(receipt => Number(receipt))
    .reduce((acc, e) => acc + e, 0);

  return (
    <FlexRow>
      <div>{reporting?.alternateTotalTaxableReceiptsLabel ?? 'Total Taxable Receipts'}</div>
      <div>{currencyFormatter.format(totalTaxableReceipts)}</div>
    </FlexRow>
  )
}

export function ManualTOTReporting({ chronologicalPeriods, paymentPeriodsById }) {
  const config = useSelector(appSelectors.renderConfig);
  const allowOmittingLatestPeriod = config?.reporting?.allowOmittingLatestPeriod;
  const isPaymentPeriodDynamic = config?.reporting?.isPaymentPeriodDynamic

  return (
    <>
      {chronologicalPeriods.map((period, idx) => (
        <TOTPaymentPeriodSection
          key={period.id}
          periodPath={getPeriodFormikPath({ periodId: period.id })}
          isRemovable={idx > 0 || allowOmittingLatestPeriod}/>
      ))}
      {
        isPaymentPeriodDynamic &&
        <TotalTaxableReceipts
          paymentPeriods={paymentPeriodsById}
        />
      }
    </>
  );
}
