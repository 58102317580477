import { BoxShadow } from 'common/common-styles';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .reportingHistoryList {
    height: calc(100vh - 600px) !important;
  }

  .reportingHistoryItem {
    ${BoxShadow}
  }
`;

export const SPRHHeader = styled.div`
  font-size: 18px;
  font-weight: bold;
`;

export const NonReportedItem = styled.div`
  width: 100%;
  padding: 5px 60px 0px 5px;
  display: flex;
  justify-content: space-between;
  ${BoxShadow}
`;

export const ReportedItem = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
