import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import get from 'lodash/get';
import { Alert } from 'rsuite';
import 'styled-components/macro'
import { appActions } from 'app/state/app/app-slice';
import { errorActions } from 'app/state/error/error-slice';
import * as loginSelectors from 'app/state/login/login-selectors';
import * as appSelectors from 'app/state/app/app-selectors';
import { ZERO as CREDIT_CARD, paymentMethodType } from 'common/constants';
import { evaluateCondition } from 'configurable-form/configurable-form-utils';
import { captureExceptionWithContext } from 'utils/sentry-functions';
import { PaymentMethodModule } from './modules/payment-methods/payment-method-module';
import { PaymentImage } from './payment-image';
import * as ps from './payment-styles';
import { PaymentSummary } from './payment-summary';
import { VendorSwitch } from './vendors/vendor-switch';
import { PostPaymentSuccessError } from 'error-objects/post-payment-success-error';
import { useIsACHActive, useIsCCActive } from './modules/payment-methods/hooks';
// import { useFetchACHBankDetails } from './vendors/pay-by-push-ach/push-ach';

// See "PaymentFormProps" in "fieldProps.ts"
export function PaymentForm(props) {
  const {
    paymentTabPayByCheckText,
    paymentTabPayByACHPushText,
    strategies,
    strategyInstance,
    checkoutDetailsConfig, // See payment-summary.jsx for config schema
    valueGetters,
    vendor, // Determines the payment processing provider to use
    params,
  } = props;
  const dispatch = useDispatch();
  const { values } = useFormikContext();

  // See "IBaseProps" in "fieldProps.ts"
  const [disablePaymentSwitching, defaultPaymentMethod] = props?.disablePaymentMethodSwitcher || [false, null];

  const useCustomerPortal = useSelector(appSelectors.useCustomerPortal);

  const userLoggedInEmail = useSelector(loginSelectors.userEmail);
  const activeFlow = useSelector(appSelectors.activeFlow);
  const flows = useSelector(appSelectors.flows);
  const isPayByCheckActive = flows[activeFlow]?.isPayByCheckActive
  const isCCActive =  useIsCCActive();
  const isACHActive = useIsACHActive();

  const stats = useSelector(appSelectors.stats);
  const isPushACHActive = stats?.app?.enablePushACH || Boolean(flows[activeFlow]?.isPushACHActive)

  const retrieveViaValueGetters = useCallback((field) => {
    // Determine conditionally based on Formik values which value path to use to retrieve the correct email
    const matchedValueGetterElement = valueGetters?.[field]?.find((el) => evaluateCondition(values, el?.condition));

    if (matchedValueGetterElement?.path) {
      return get(values, matchedValueGetterElement?.path);
    } else {
      return matchedValueGetterElement?.joinPaths.map(path => get(values, path)).filter(Boolean).join(' ');
    }
  }, [valueGetters, values])

  const emailField = useMemo(() => {
    // Use the email of the person who is logged in (Pay, Update, Renewal, etc)
    if (userLoggedInEmail) return userLoggedInEmail;
    return retrieveViaValueGetters('userEmail');
  }, [retrieveViaValueGetters, userLoggedInEmail]);

  const signatory = useMemo(() => retrieveViaValueGetters('hostName'), [retrieveViaValueGetters]);

  useEffect(() => {
    // Change the payment method to credit card in the case where the payment method switcher is hidden and need to use CC endpoints
    if (defaultPaymentMethod) dispatch(appActions.changePaymentMethodTab({ newTab: CREDIT_CARD }));

    // populate the available payment methods
    const availableMethods = []
    if (isPayByCheckActive) availableMethods.push(paymentMethodType.CHECK)
    if (isPushACHActive) availableMethods.push(paymentMethodType.PUSH_ACH)
    if (isCCActive) availableMethods.push(paymentMethodType.CREDIT_CARD)

    // ach is the default payment method so select the next available payment method if ach is not active
    if (!isACHActive && availableMethods.length) {
      dispatch(appActions.changePaymentMethodTab({
        newTab: availableMethods[0]
      }));
    }

    return function cleanup() {
      Alert.closeAll(); // Want to clear all Rsuite alerts on the screen when leaving the payment page as it could generate visual bugs
    }
  }, [defaultPaymentMethod, dispatch, isPayByCheckActive, isPushACHActive, isCCActive, isACHActive]);

  /**
   * ! THIS CODE IS COPY PASTED FORM "ConfigurableForm" "onSubmitStrategyCB"
   * The arguments for this function has been adjusted, so be aware that it doesn't function exactly the same
   */
  const onSuccess = useCallback(async (set = 'onSuccess') => {
    try {
      await strategyInstance.executeStrategy(strategies, set);
    } catch (err) {
      // console.error(err);
      captureExceptionWithContext(new PostPaymentSuccessError(err), {
        errorMsg: 'Error when executing strategy function in PaymentForm onSuccess',
      });

      dispatch(errorActions.setHasErrored(true));
    }
  }, [strategies, strategyInstance, dispatch]);

  return (
    <ps.Flex useCustomerPortal={useCustomerPortal}>
      {/* Transaction summary */}
      <PaymentSummary config={checkoutDetailsConfig} />
      <ps.Column css="max-width: 500px; width: 100%; justify-content: space-between;">
        {/* Buttons to switch between ACH and credit card payments */}
        {
          !disablePaymentSwitching &&
            <PaymentMethodModule
              isPayByCheckActive={isPayByCheckActive}
              isPushACHActive={isPushACHActive}
              isCCActive={isCCActive}
              isACHActive={isACHActive}
            />
        }

        <VendorSwitch
          vendor={vendor}
          // Callbacks
          onSuccess={onSuccess}
          // Information to be used in the payCC and payACH requests
          paymentTabPayByACHPushText={paymentTabPayByACHPushText}
          paymentTabPayByCheckText={paymentTabPayByCheckText}
          signatory={signatory}
          email={emailField}
          strategies={strategies}
          strategyInstance={strategyInstance}
          params={params}
        />

        <PaymentImage />
      </ps.Column>
    </ps.Flex>
  );
}
