import React, { useCallback, useMemo } from 'react';
import get from 'lodash/get';
import template from 'lodash/template';
import { useFormikContext } from 'formik';
import styled from 'styled-components';
import { Button, Icon } from 'semantic-ui-react';
import { ZERO } from 'common/constants';
import { InputField } from './input-field';

const StyledButton = styled(Button)`
  color: #5c5d5d;
  background-color: #e0e1e2;
  margin-top: 18px;
`;

const Container = styled.div`
  display: flex;
`;

const RemoveButton = styled.button`
  background-color: transparent;
  margin-bottom: 10px;
`;

function ListInputButton({ label, onClick }) {
  return (
    <StyledButton type="button" onClick={onClick}>
      <Icon name="add" />
      <span>{label}</span>
    </StyledButton>
  );
}

/**
 * Note:
 *  This component is used to store user inputted information in an
 *     array of some data structure (strings, numbers, objects, maybe arrays?)
 *  See the "name" config key for how to config the storage structure
 *
 * Sample Config Keys:
 *
 *  component: ListInput
 *    Required - string
 *    The name of the component that will be used to render
 *
 *  id:
 *    Required - string
 *    String for the React prop "key"
 *
 *  name:
 *    Required - template string
 *
 *    See "ES template literal delimiter" example
 *      e.g. licenseListings[${index}].url
 *        Stores values in an array of objects and the value is stored in the key "url" of said object
 *
 *    Formik uses this as the key to store the values with in a dictionary
 *      Can use lodash-like dot path: https://formik.org/docs/api/field#name
 *
 *  type:
 *    Optional - string
 *    https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#input_types
 *      type = "tel"
 *        Will render the MaskPhoneNumberInput component
 *
 *  label:
 *    Optional - string
 *    Label for the input field component
 *
 *  subtext:
 *    Optional - string
 *    Text that is displayed below the input field component
 *
 *  placeholder:
 *    Optional - string
 *    Placeholder text displayed within the input field component
 *
 *  validation:
 *    Optional - dictionary
 *    Yup validation scheme
 *
 *  trimStart:
 *    Optional - boolean
 *    Indicates to the underlying inputs that text should have leading spaces removed
 *
 *  isShownOrHidden:
 *    Optional - dictionary
 *    Determines when a component is shown or hidden based on Formik values
 *
 *    defaultHidden:
 *      Optional - boolean
 *      Determines whether or not a component should be hidden by default
 *
 *    hideWhen:
 *      When all conditionals are true, this component will be hidden
 *      Refer to comments for evalParams function within "shared-components"
 *
 *    showWhen:
 *      When all conditionals are true, this component will be shown
 *      Refer to comments for evalParams function within "shared-components"
 */
export function ListInput(props) {
  const { dataKey, name, buttonLabel, newListItemTemplate, ...other } = props;
  const { values, setValues } = useFormikContext();

  const listItems = useMemo(() => {
    const listings = get(values, dataKey, []);
    if (listings?.length) return listings;
    return [ newListItemTemplate ];
  }, [dataKey, newListItemTemplate, values]);

  const handleAddListInput = useCallback(() => {
    setValues({ ...values, [dataKey]: [...listItems, newListItemTemplate] });
  }, [listItems, dataKey, newListItemTemplate, setValues, values]);

  const handleRemoveListInput = useCallback((index) => {
    const listings = get(values, dataKey, []);
    const removedListings = listings.filter((el, idx) => idx !== index);
    setValues({ ...values, [dataKey]: removedListings });
  }, [dataKey, setValues, values]);

  return (
    <div>
      {listItems?.length ? listItems.map((_, index) => (
        <Container key={index}>
          <InputField name={template(name)({ index })} {...other} />
          {Boolean(index) && (
            <RemoveButton type="button" onClick={() => handleRemoveListInput(index)}>
              <img src="/assets/circle-cross.svg" alt="Close button" />
            </RemoveButton>
          )}
        </Container>
      )) : (
        <InputField name={template(name)({ index: ZERO })} {...other} />
      )}

      <ListInputButton label={buttonLabel} onClick={handleAddListInput} />
    </div>
  )
}
