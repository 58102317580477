import { CircularProgress } from '@material-ui/core';
import { BoxShadow } from 'common/common-styles';
import React from 'react';
import styled from 'styled-components';

const Div = styled.div`
  ${BoxShadow};

  pointer-events: ${(props) => (props.disabled ? 'none' : 'inherit')};

  display: flex;
  justify-content: center;
  width: 100%;

  p {
    font-size: 18px;
    margin: 20px auto;
  }

  .MuiCircularProgress-root {
    margin: 20px auto;
  }
`;

export function ReportingHistoryLoader(props) {
  const { disabled, onClick, dataFetchHasFailed } = props;

  return (
    <Div disabled={disabled} onClick={onClick}>
      {dataFetchHasFailed ? (
        <p>Failed to fetch reporting history. Click to retry.</p>
      ) : (
        <CircularProgress size={20} />
      )}
    </Div>
  );
}
