import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from 'rsuite';
import last from 'lodash/last';
import styled from 'styled-components/macro';
import * as appSelectors from 'app/state/app/app-selectors';
import { filesActions } from 'app/state/files/files-slice';
import { filesManager } from 'utils/files-manager';
import { captureExceptionWithContext } from 'utils/sentry-functions';
import { parseHTMLString } from 'utils/utility-functions';
import { filterFilesBySize } from '../utils';
import { DocumentErrorMessage, typeDocCount, typeDocSizeTooLarge, typeDocSizeTooSmall } from './document-error-message';
import { DocTextRender } from './document-text';
import { FileList } from './file-list';
import { UploadButton } from './upload-button';
import { shouldEnableDebugFeatures } from "../../debugging/debug";
import set from 'lodash/set';
import unset from 'lodash/unset';

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

/**
 * If more filetypes are being supported, please update the following files:
 * Hardcoded Configs:
 *  scripts/stats/ca-sacramento/sacramento-ca-str.json
  * scripts/stats/ca-san_mateo-city_of_burlingame/burlingame-ca-str.json
  * scripts/stats/imperialbeachdummy/imperialbeach-demo-ca-str.json
  * scripts/stats/sc-charleston-town_of_mount_pleasant/mountpleasant-sc-str.json
  * scripts/stats-src/places/ca-placer/legacy-config/placer-ca-str-permit.json
 *
 * Typescript Configs:
 *  scripts\stats-src\common-config\page-modules\index.ts
 *  scripts\stats-src\places\ca-marin-city_of_san_rafael\common\uploadDocuments.ts
 */
const supportedFileTypes = ".jpg, .jpeg, .png, .pdf, .heic";

const supportedFileTypesDict = {
  'jpg': 'jpg',
  'jpeg': 'jpeg',
  'png': 'png',
  'pdf': 'pdf',
  'heic': 'heic',
  'HEIC': 'HEIC',
};

function invalidDocTypeAlert(supportedTypes) {
  Alert.error(`One or more of the documents you have upload is not of the supported types. (${supportedTypes})`, 15000);
}

function areAnyFilesInvalid(files) {
  const periodDelimiter = '.';
  const listOfFileTypes = files.map(({ name }) => last(name.split(periodDelimiter)));
  return listOfFileTypes.some((type) => !(supportedFileTypesDict[type]));
}

export function UploadModule(props) {
  const { fileKey, storageKey } = props;

  const dispatch = useDispatch();

  const cityInfo = useSelector(appSelectors.cityInfo);

  const [files, setFiles] = useState(filesManager.retrieveFromStore({ uploadKey: fileKey, storageKey }));
  const [exceededFileLimit, setExceededFileLimit] = useState(false);
  const [filesExceedingMaxSize, setFilesExceedingMaxSize] = useState([]);
  const [smallFiles, setSmallFiles] = useState([]);

  /**
   * Do not call setFilesExceedingMaxSize within this useEffect, because it will clear
   * the error for when a file exceeds the max file size error per file upload attempt
   */
  useEffect(() => { setExceededFileLimit(false); }, [files]);

  const addDocToStore = useCallback((files) => {
    /**
     * Check if there are any invalid file types
     *  If there are any invalid files, then display an alert and do not store ANY files in the file manager
     */
    if (areAnyFilesInvalid(files)) {
      invalidDocTypeAlert(supportedFileTypes);
      return;
    }

    try {
      const numOfFilesOfType = filesManager.addDocumentToStore({ files, uploadKey: fileKey, storageKey });
      const updatedListOfFiles = filesManager.retrieveFromStore({ uploadKey: fileKey, storageKey });

      setFiles(updatedListOfFiles);
      dispatch(filesActions.setUploadedDocumentTypesMap({ [storageKey || fileKey]: numOfFilesOfType }));
    } catch (err) {
      captureExceptionWithContext(err, { uploadKey: fileKey, storageKey, files })
      setExceededFileLimit(Boolean(err));
    }
  }, [dispatch, storageKey, fileKey]);

  const removeDocFromStore = useCallback((index) => {
    setFilesExceedingMaxSize([]);

    const numOfFilesOfType = filesManager.removeDocumentFromStore({ uploadKey: fileKey, storageKey, index });
    const updatedListOfFiles = filesManager.retrieveFromStore({ uploadKey: fileKey, storageKey });

    setFiles(updatedListOfFiles);
    dispatch(filesActions.setUploadedDocumentTypesMap({ [storageKey || fileKey]: numOfFilesOfType }));
  }, [dispatch, storageKey, fileKey]);

  const handleUpload = useCallback((e) => {
    const { filesList, largeFilesList, smallFilesList } = filterFilesBySize(e.target.files);

    addDocToStore(filesList);
    setFilesExceedingMaxSize(largeFilesList);
    setSmallFiles(smallFilesList);

    // This allows the user to re-upload a file they just removed
    e.target.value = null;
  }, [addDocToStore]);

  useEffect(() => {
    if (!shouldEnableDebugFeatures()) return;

    set(window, `deckard.debug.handleUpload.${fileKey}`, file => addDocToStore([file]))

    return () => unset(window, `deckard.debug.handleUpload.${fileKey}`)
  },[addDocToStore, fileKey]);

  return (
    <div css={props?.additionalCSS}>
      <input
        hidden
        multiple
        type="file"
        id={storageKey || fileKey}
        accept={supportedFileTypes}
        onChange={handleUpload}
        data-cy={props?.dataCy}
      />

      <DocTextRender title text={props?.headerText} required={props?.required} fileKey={fileKey} />
      <DocTextRender text={props?.helperText} fileKey={fileKey} />

      {storageKey ? (
          <Layout>
            <div>{parseHTMLString(props?.text)}</div>
            <UploadButton fileKey={storageKey} />
          </Layout>
        ) : (
          <UploadButton fileKey={fileKey} />
        )
      }

      <DocumentErrorMessage type={typeDocSizeTooLarge} show={filesExceedingMaxSize.length} files={filesExceedingMaxSize} />
      <DocumentErrorMessage type={typeDocSizeTooSmall} show={smallFiles.length} files={smallFiles} />
      <DocumentErrorMessage type={typeDocCount} show={exceededFileLimit} cityInfo={cityInfo} />

      <FileList files={files} removeFile={removeDocFromStore} />
    </div>
  );
}
