import { DEFAULT_TITLE_CASED_KEYS, DISABLE_BULK_RENEWAL_FLOW_PATH } from 'common/constants';
import { FLOW_PATHS } from 'routes/constants';
import get from 'lodash/get';
import React, { useCallback } from 'react';
import { PORTAL_PATHS } from 'routes/constants';
import { history } from 'routes/history';
import titleize from 'titleize';
import { useSelector } from 'react-redux';
import * as appSelectors from 'app/state/app/app-selectors';
import { CustomerPortalStrategy } from 'strategies/customer-portal/customer-portal-strategy';
import { captureExceptionWithContext } from 'utils/sentry-functions';
import { Alert } from 'rsuite';
import { SingleRenewalFlowEntryError } from 'strategies/customer-portal/errors';

import {
  ListItemDiv,
  PermitDataCell,
  PermitGridRow,
  PermitViewButton,
} from '../styles';
import * as dashboardSelectors from 'app/state/dashboard/dashboard-selectors';

function RowButton({ onViewButtonClick }) {
  return (
    <PermitDataCell>
      <PermitViewButton onClick={onViewButtonClick}>View</PermitViewButton>
    </PermitDataCell>
  );
}

function RowRenewButton({ onRenewButtonClick }) {
  return (
    <PermitDataCell>
      <PermitViewButton onClick={(permit) => onRenewButtonClick(permit)}>Renew</PermitViewButton>
    </PermitDataCell>
  );
}

function DataDisplay({ fieldKey, permit, titleCased }) {
  const fn = titleCased ? titleize : (e) => e;
  return <PermitDataCell>{fn(get(permit, fieldKey))}</PermitDataCell>;
}

function Cell(props) {
  if (props.fieldKey === 'rowAction') return <RowButton {...props} />;
  if (props.fieldKey === 'rowRenewAction' && props.permit.isRenwable === true) return <RowRenewButton {...props} />; // only show if permit is eligible to be renewed
  return (
    <DataDisplay
      titleCased={DEFAULT_TITLE_CASED_KEYS.includes(props.fieldKey)}
      {...props}
    />
  );
}

export function PermitRow(props) {
  const { index, layout, permit } = props;

  const disableBulkRenewalFlow = useSelector(
    appSelectors.getFromPortalStats(DISABLE_BULK_RENEWAL_FLOW_PATH),
  );

  const activePermitData = useSelector(
    dashboardSelectors.activePermitByApplicationNumber(permit.applicationNumber)
  );

  const handlePermitViewClick = useCallback(() => {
    history.push(
      `${PORTAL_PATHS.dashboardSinglePermit}/${permit.applicationNumber}`,
    );
  }, [permit.applicationNumber]);

  const handleSingleRenewal = useCallback(async () => {
    try {
      await CustomerPortalStrategy.getInstance().initiateSingleRenewalFlowEntry({
        setLoading: () => { },
        applicationNumber: permit.applicationNumber,
        permitData: activePermitData,
      });
    } catch (err) {
      Alert.error(
        'Failed to initiate the renewal process. Please try again later.',
        10000,
      );

      captureExceptionWithContext(new SingleRenewalFlowEntryError(err), {
        applicationNumber: permit.applicationNumber,
      });
    }
  }, [permit, activePermitData]);

  const handleBulkRenewal = useCallback(() => {
    if (!disableBulkRenewalFlow) {
      history.push(`${FLOW_PATHS.bulkRenewal}`);
    }
  }, [disableBulkRenewalFlow]);

  const handleRenewClick = useCallback(async () => {
    if (!disableBulkRenewalFlow) {
      handleBulkRenewal();
    }
    else {
      handleSingleRenewal();
    }
  }, [disableBulkRenewalFlow, handleSingleRenewal, handleBulkRenewal]);

  return (
    <ListItemDiv index={index}>
      <PermitGridRow layout={layout}>
        {layout.map(({ key }) => (
          <Cell
            key={key}
            fieldKey={key}
            permit={permit}
            onViewButtonClick={handlePermitViewClick}
            onRenewButtonClick={handleRenewClick}
          />
        ))}
      </PermitGridRow>
    </ListItemDiv>
  );
}
