import { PAY_TOT_PATHS } from 'routes/constants';
import { BaseStrategy } from './base-strategy';

export class PayTotStrategy extends BaseStrategy {
  static instance = null;

  static getInstance() {
    if (PayTotStrategy.instance) {
      return PayTotStrategy.instance;
    }
    PayTotStrategy.instance = new PayTotStrategy();
    return PayTotStrategy.instance;
  }

  get strategiesData() {
    return BaseStrategy.strategiesData.payTot;
  }

  get loginPageContinueCB() {
    return this.stratMap[this.strategiesData.loginPageContinueCB];
  }

  get lookupPageContinueCB() {
    return this.stratMap[this.strategiesData.lookupPageContinueCB];
  }

  get reportRevenueContinueCB() {
    return this.stratMap[this.strategiesData.reportRevenueContinueCB];
  }

  get paymentSuccessCB() {
    return this.stratMap[this.strategiesData.paymentSuccessCB];
  }

  _goToReportRevenue(history) {
    history.push(PAY_TOT_PATHS.reportRevenue);
  }

  _goToConfirmation(history) {
    history.push(PAY_TOT_PATHS.confirmation);
  }

  _goToPaymentPage(history) {
    history.push(PAY_TOT_PATHS.payment);
  }

  _goToSuccessPage(history) {
    history.push(PAY_TOT_PATHS.success)
  }

  stratMap = {
    goToReportRevenue: this._goToReportRevenue,
    goToConfirmation: this._goToConfirmation,
    goToPaymentPage: this._goToPaymentPage,
    goToSuccessPage: this._goToSuccessPage,
  }
}

// TODO: Remove when tested and working well
window.PTS = PayTotStrategy;
