import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components/macro';
import { Property } from './property-selection/property';
import {
  getAllSelectedLicensesOnReportPage,
  toggleSelectProperty,
  useBulkTOTEnabled,
  useLicensesInReport
} from "../utils";
import { DynamicQuarterPeriodTypeSelectionTabs } from "./dynamic-quarter-period-type-selection-tabs";
import { useFormikContext } from "formik";
import { Button, Input } from "rsuite";
import { Header } from "./common-styles";

import MUIAccordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import * as cs from 'common/common-styles';

const SelectedPropertiesSection = styled.div`
  margin-top: 40px;
`
const BulkTOTSelectionControls = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const HeaderControls = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const SearchBox = styled(Input)`
  flex: 1;
`;

const Div = styled.div`
  margin-bottom: 40px;
`;

const List = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-top: 20px;
  max-height: 300px;
  overflow-y: auto;
`;


export function PropertySelection({
  ignoreUnpaidPeriods,
  unpaidPeriodsByLicenseId,
  availablePeriodsById,
  isPaymentPeriodDynamic,
  resetTaxablePeriods,
  disabled,
}) {
  const licenses = useLicensesInReport()
  const [searchQuery, setSearchQuery] = useState("");
  const isBulkTOTUploadEnabled = useBulkTOTEnabled();

  const filteredLicenses = useMemo(() => {
    if (!searchQuery) return licenses;
    const processedSearchQuery = searchQuery.toLowerCase().trim();

    return licenses.filter(lic => lic.license?.toLowerCase().includes(processedSearchQuery)
      || lic.propertyAddress?.toLowerCase().includes(processedSearchQuery))
  }, [licenses, searchQuery])


  const { values, setFieldValue } = useFormikContext();

  const allAvailableLicenses = useLicensesInReport({ disablePeriodTypeFilter: true })
  const selectedLicenses = useMemo(() => {
    const { taxablePeriods } = values;
    const selectedLicenseIds = getAllSelectedLicensesOnReportPage({ taxablePeriods })
    return allAvailableLicenses.filter(lic => selectedLicenseIds.has(lic.license))
  },[allAvailableLicenses, values])

  const selectDeselectAll = useCallback((select) => {
    toggleSelectProperty({
      forceAllSelections: select,
      ignoreUnpaidPeriods,
      licenseIds: licenses.map(({ license }) => license),
      values,
      setFieldValue,
      unpaidPeriodsByLicenseId,
      availablePeriodsById,
      licensesInReport: licenses,
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availablePeriodsById, licenses, setFieldValue, unpaidPeriodsByLicenseId, values]);

  return (
    <Div>
      <Header>Listed Properties</Header>
      <HeaderControls>
        {isPaymentPeriodDynamic && <DynamicQuarterPeriodTypeSelectionTabs disabled={disabled} resetTaxablePeriods={resetTaxablePeriods}/>}
        {isBulkTOTUploadEnabled && (
          <BulkTOTSelectionControls>
            <SearchBox placeholder={"Search for a permit number or property address"} value={searchQuery} onChange={value => setSearchQuery(value)} />
            <Button disabled={disabled} onClick={() => selectDeselectAll(true)}>Select All</Button>
            <Button disabled={disabled} onClick={() => selectDeselectAll(false)}>Deselect All</Button>
          </BulkTOTSelectionControls>
        )}
      </HeaderControls>
      <List>{filteredLicenses.map((el) => (
        <Property
          key={el.license}
          {...el}
          unpaidPeriodsByLicenseId={unpaidPeriodsByLicenseId}
          ignoreUnpaidPeriods={ignoreUnpaidPeriods}
          availablePeriodsById={availablePeriodsById}
          disabled={disabled}
        />
      ))}
      </List>
      {isBulkTOTUploadEnabled && (
        <SelectedPropertiesSection>
          <MUIAccordion>
            <AccordionSummary>
              <Header>Selected Properties ({selectedLicenses.length})</Header>
            </AccordionSummary>
            <cs.AccordionDetail>
              <List>
                {selectedLicenses.map((el) => (
                  <Property
                    key={el.license}
                    {...el}
                    unpaidPeriodsByLicenseId={unpaidPeriodsByLicenseId}
                    ignoreUnpaidPeriods={ignoreUnpaidPeriods}
                    availablePeriodsById={availablePeriodsById}
                    disabled={disabled}
                  />
                ))}
              </List>
            </cs.AccordionDetail>
          </MUIAccordion>
        </SelectedPropertiesSection>
      )}
    </Div>
  )
}
