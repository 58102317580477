import React, { useCallback, useState } from 'react';
import { FormControl, MenuItem, Modal, Select as MUISelect } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import styled from 'styled-components';
import * as cs from 'common/common-styles';
import { DEFAULT_FORM_VALUE } from 'common/constants';
import { BlackButton } from 'common/navigation-buttons';
import {
  AVAILABLE_BANKS_OPTIONS,
  BANK_OPTION_OTHER,
  STRIPE_NO_PAYMENT_METHOD_ATTACHED_INTENT_STATUSES
} from '../constants';

import { StripeACHPaymentIntentStatusError } from './ach-errors';
import { Input } from './stripe-ach-styles';

const Select = styled(MUISelect)`
  width: 200px;
`;

const ModalContent = styled.div`
  width: 600px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: white;
  margin: auto;
  margin-top: 200px;
  padding: 20px;
  border-radius: 8px;
`;

const defaultSelectedBank = { value: DEFAULT_FORM_VALUE, label: DEFAULT_FORM_VALUE };

export function StripeAchUserReportingModal(props) {
  const [selectedBank, setSelectedBank] = useState(defaultSelectedBank);
  const [otherBankName, setOtherBankName] = useState(DEFAULT_FORM_VALUE);

  const handleBankSelection = useCallback((_, ref) => {
    setSelectedBank(ref.props);

    props.capturePaymentException(
      new StripeACHPaymentIntentStatusError(`Received payment intent status of either: ${STRIPE_NO_PAYMENT_METHOD_ATTACHED_INTENT_STATUSES}`),
      {
        paymentId: props.paymentFees.paymentId,
        bankType: selectedBank.value,
        bankName: selectedBank.children,
        otherBankSpecification: selectedBank.value === BANK_OPTION_OTHER.value ? otherBankName : DEFAULT_FORM_VALUE,
      }
    );
  }, [otherBankName, props, selectedBank]);

  const handleModalClose = useCallback(() => {
    props.setErrorModalOpen(false);
    setSelectedBank(defaultSelectedBank);
  }, [props]);

  return (
    <Modal open={props.errorModalOpen} onClose={handleModalClose}>
      <ModalContent>
        <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
          <CloseIcon style={{ cursor: 'pointer' }} onClick={handleModalClose} />
        </div>

        <div>
          <p>An error has been encountered while attempting to pay with your bank account.</p>
          <p>If you would kindly report this issue to us, then please indicate which bank you are attempting to connect to complete this transaction.</p>
        </div>

        <div>
          <cs.InputTextFieldLabel>Please select the bank you are attempting to pay with:</cs.InputTextFieldLabel>
          <FormControl variant='outlined'>
            <Select value={selectedBank.value} onChange={handleBankSelection}>
              {AVAILABLE_BANKS_OPTIONS.map(({ value, label }) =>
                <MenuItem key={value} value={value}>{label}</MenuItem>
              )}
            </Select>
          </FormControl>

          {(selectedBank.value === BANK_OPTION_OTHER.value) && (
            <div style={{ marginTop: '10px' }}>
              <cs.InputTextFieldLabel>{`Bank Name`}</cs.InputTextFieldLabel>
              <Input value={otherBankName} onChange={setOtherBankName}/>
            </div>
          )}
        </div>

        <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
          <BlackButton onClick={handleModalClose} width='100px'>Close</BlackButton>
        </div>
      </ModalContent>
    </Modal>
  );
}
