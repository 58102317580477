import axios from 'axios';
import { asyncDebounce } from './utility-functions';

export const SMARTY_API_KEY = '53172441788738393';
export const SMARTY_AUTOCOMPLETE_LICENSE_STRING = 'us-autocomplete-pro-cloud';

const baseURL = `https://us-autocomplete-pro.api.smartystreets.com/lookup`;

async function lookupSmartyAddressFn({ search, selected }) {
  const searchParams = {
    key: SMARTY_API_KEY,
    license: SMARTY_AUTOCOMPLETE_LICENSE_STRING,
    selected: selected || '',
    search: search,
    source: 'all',
  };

  const { data } = await axios.get(baseURL, { params: searchParams });
  return data;
}

export const lookupSmartyAddress = asyncDebounce(lookupSmartyAddressFn, 300);

function buildAddress(suggestion) {
  const { street_line, secondary, city, state, zipcode, entries } = suggestion;

  const addressContainsMultipleUnits = entries > 1;
  const computedSecondary = addressContainsMultipleUnits ? `${secondary} (${entries})` : `${secondary}`;
  const computedAddress = [street_line, computedSecondary, city, state].filter(Boolean).join(', ') + ` ${zipcode}`;

  return {
    // Accessory information for subsequent queries and logics
    search: street_line,
    selected: computedAddress,
    selectValue: `${street_line} ${secondary}`,
    containsMultipleUnits: addressContainsMultipleUnits,
    // RSuite Autocomplete required fields
    value: computedAddress,
    label: computedAddress,
    suggestion,
  };
}

export function formatSmartyAddressData(data) {
  return data?.suggestions?.map(buildAddress) || [];
}
