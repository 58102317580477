import { dashboardActions } from 'app/state/dashboard/dashboard-slice';
import { networkActions } from 'app/state/network/network-slice';
import { payloadActions } from 'app/state/payload/payload-slice';
import {
  ON_SINGLE_RENEWAL_FLOW_ENTRY,
  ON_UPDATE_FLOW_ENTRY,
  PERMIT_VERBIAGE,
  PERMIT_VERBIAGE_TRANSLATION_KEY,
} from 'common/constants';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import { batch } from 'react-redux';
import { Alert } from 'rsuite';
import { authSender } from 'utils/auth-sender';
import { captureExceptionWithContext } from 'utils/sentry-functions';

import { BaseStrategy } from '../base-strategy';
import {
  PermitActionabilityAPIError,
  RetrieveLicenseByApplicationNumber,
  SingleRenewalFlowAltEntryError,
} from './errors';

export class CustomerPortalStrategy extends BaseStrategy {
  static instance = null;

  static getInstance() {
    if (CustomerPortalStrategy.instance) return CustomerPortalStrategy.instance;

    CustomerPortalStrategy.instance = new CustomerPortalStrategy();
    return CustomerPortalStrategy.instance;
  }

  // ------------------------------------------------------
  // Shared Internal Processes
  // ------------------------------------------------------

  dispatchPermitIdentifier = (licenseOrApplicationNumber) => {
    return this.reduxDispatch(
      networkActions.setApplicationNumber(licenseOrApplicationNumber),
    );
  };

  dispatchSetActiveSinglePermitApplicationNumber = (applicationNumber) => {
    return this.reduxDispatch(
      dashboardActions.setActiveSinglePermitApplicationNumber(
        applicationNumber,
      ),
    );
  };

  dispatchUpdateAdditionalInfo = (parcelNumber, propertyAddress) => {
    return this.reduxDispatch(
      payloadActions.updateAdditionalInfo({ parcelNumber, propertyAddress }),
    );
  };

  getVerbiageTranslation = (string, fallback) => {
    return get(this.reduxState, `app.translations.${string}.default`, fallback);
  };

  // ------------------------------------------------------
  // Strategies
  // ------------------------------------------------------

  retrieveLicenseByApplicationNumber = async ({
    params,
    state,
    setSubmitting,
  }) => {
    setSubmitting(true);

    try {
      const applicationNumber = get(
        state,
        'dashboard.activeSinglePermitApplicationNumber',
      );
      const { data } = await authSender.get(`/license/${applicationNumber}`);
      this.storeDataInReduxState(data, params.reduxStorageParams);
      setSubmitting(false);
    } catch (err) {
      setSubmitting(false);
      captureExceptionWithContext(new RetrieveLicenseByApplicationNumber(err));
      throw err;
    }
  };

  initiateUpdateFlowEntry = async ({
    setLoading,
    applicationNumber,
    permitData,
  }) => {
    try {
      setLoading(true);

      // See "scripts/stats-src/types/config.ts" for more information regarding "flowStrategies"
      const strategy = get(
        this.reduxState,
        'app.stats.flows.updateCertificate.flowStrategies',
      );
      if (isNil(strategy))
        throw new Error("'flowStrategies' not setup properly for update flow.");

      const { apn, propertyAddress, licenseElseApplicationNumber } = permitData;

      batch(() => {
        this.dispatchPermitIdentifier(licenseElseApplicationNumber);
        this.dispatchSetActiveSinglePermitApplicationNumber(applicationNumber);
        this.dispatchUpdateAdditionalInfo(apn, propertyAddress);
      });

      /**
       * If you're having issues with storing/inserting data into Formik state in Redux state,
       * see "scripts/stats-src/types/config.ts".
       */
      await this.executeStrategy(strategy, ON_UPDATE_FLOW_ENTRY, setLoading);

      setLoading(false);
    } catch (err) {
      setLoading(false);
      batch(() => {
        this.dispatchPermitIdentifier(null);
        this.dispatchSetActiveSinglePermitApplicationNumber(null);
        this.reduxDispatch(payloadActions.setAdditionalInfo({}));
      });

      // Re-throw to allow each function that invokes "initiateUpdateFlowEntry" to do their own error handling.
      // The rest of the actions performed here are mainly for clean up.
      throw err;
    }
  };

  initiateSingleRenewalFlowEntry = async ({
    setLoading,
    applicationNumber,
    permitData,
  }) => {
    try {
      setLoading(true);

      // See "scripts/stats-src/types/config.ts" for more information regarding "flowStrategies"
      const strategy = get(
        this.reduxState,
        'app.stats.flows.renewLicense.flowStrategies',
      );
      if (isNil(strategy))
        throw new Error(
          "'flowStrategies' not setup properly for single renewal flow.",
        );

      const { apn, propertyAddress, licenseElseApplicationNumber } = permitData;

      batch(() => {
        this.dispatchPermitIdentifier(licenseElseApplicationNumber);
        this.dispatchSetActiveSinglePermitApplicationNumber(applicationNumber);
        this.dispatchUpdateAdditionalInfo(apn, propertyAddress);
      });

      /**
       * If you're having issues with storing/inserting data into Formik state in Redux state,
       * see "scripts/stats-src/types/config.ts".
       */
      await this.executeStrategy(
        strategy,
        ON_SINGLE_RENEWAL_FLOW_ENTRY,
        setLoading,
      );

      setLoading(false);
    } catch (err) {
      setLoading(false);
      batch(() => {
        this.dispatchPermitIdentifier(null);
        this.dispatchSetActiveSinglePermitApplicationNumber(null);
        this.reduxDispatch(payloadActions.setAdditionalInfo({}));
      });

      // Re-throw to allow each function that invokes this "initiateSingleRenewalFlowEntry" to do their own error handling.
      // The rest of the actions performed here are mainly for clean up.
      throw err;
    }
  };

  initiateAlternateSingleRenewalFlowEntry = async ({
    setLoading,
    applicationNumber,
    permitData,
    cleanUp = () => {},
  }) => {
    try {
      setLoading(true);
      const { data } = await authSender.get(
        `/permitActionability/${applicationNumber}`,
      );

      if (!data.renewable) {
        setLoading(false);
        const defaultNotRenewableError = 'The selected item is not renewable.';
        Alert.warning(data.renewableError ?? defaultNotRenewableError, 10000);
        return data.renewableError ?? defaultNotRenewableError;
      }
    } catch (err) {
      setLoading(false);

      cleanUp();

      Alert.error(
        `Failed to determine renewable status for the ${this.getVerbiageTranslation(
          PERMIT_VERBIAGE_TRANSLATION_KEY,
          PERMIT_VERBIAGE.toLowerCase(),
        )}.`,
        10000,
      );

      return captureExceptionWithContext(new PermitActionabilityAPIError(err), {
        applicationNumber,
        permitData,
      });
    }

    try {
      await this.initiateSingleRenewalFlowEntry({
        setLoading,
        applicationNumber,
        permitData,
      });
    } catch (err) {
      setLoading(false);

      cleanUp();

      Alert.error(
        'Failed to initiate the renewal process. Please try again later.',
        10000,
      );

      return captureExceptionWithContext(
        new SingleRenewalFlowAltEntryError(err),
        { applicationNumber, permitData },
      );
    }
  };
}
