import React, { useMemo } from 'react';
import NumberFormat from 'react-number-format';
import styled from 'styled-components';
import { InputGroup, Tooltip, Whisper } from 'rsuite';
import * as cs from './common-styles';
import { DEFAULT_FORM_VALUE as emptyString, LUITypes } from './constants';

const InputSection = styled.div`
  margin-top: ${(props) => props.$noMargin ? null : '18px'};
  max-width: ${(props) => props.width ? props.width : '400px' };
`;

export function MaskPhoneNumberInput(props) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      format="###-###-####"
      mask="_"
      onValueChange={({ value }) => onChange(value)}
    />
  );
}

function InputAdornment(props) {
  const { tooltipMessage, imageSrc, altText } = props;

  if (!imageSrc) return null;

  return (
    <InputGroup.Addon>
      <Whisper
        trigger="hover"
        placement="top"
        speaker={<Tooltip>{tooltipMessage}</Tooltip>}
      >
        <img src={imageSrc} alt={altText || emptyString} />
      </Whisper>
    </InputGroup.Addon>
  );
}

export function LabelledInput(props) {
  const {
    // Icon within the the input field
    tooltipMessage, imageSrc, altText,
    // Functional logic
    value, onChange, required, ...other
  } = props;

  return (
    <InputGroup inside>
      <cs.StyledInput
        value={value || emptyString}
        onChange={(value, e) => onChange(e)}
        required={required}
        {...other}
      />
      {tooltipMessage &&
        <InputAdornment tooltipMessage={tooltipMessage} imageSrc={imageSrc} altText={altText} />
      }
    </InputGroup>
  );
}

const UIFactory = {
  [LUITypes.text]: LabelledInput,
}

export function LabelledUserInterface(props) {
  const {
    // Rendering logic
    type, hidden, width, $noMargin,
    // Labels surrounding the input field
    label, subtext,
    // Component logic
    onChange,
    // Icon within the the input field
    tooltipMessage, imageSrc, altText,
    // testing
    dataCy,
    // Functional logic
    value, required, ...other
  } = props;

  const Component = useMemo(() => {
    if (UIFactory?.[type]) return UIFactory?.[type];
    return UIFactory?.[LUITypes.text];
  }, [type]);

  const generatedProps = useMemo(() => {
    switch(type) {
      case LUITypes.text:
        return { tooltipMessage, imageSrc, altText, value, required, onChange, ...other };
      default:
        // Same as LUITypes.text as that is most likely the most used component from this factory
        return { tooltipMessage, imageSrc, altText, value, required, onChange, ...other };
    }
  }, [type, tooltipMessage, imageSrc, altText, value, required, onChange, other]);

  if (hidden) return null;

  return (
    <InputSection width={width} $noMargin={$noMargin}>
      {label && <cs.InputTextFieldLabel>{label}</cs.InputTextFieldLabel>}
      <Component data-cy={dataCy} {...generatedProps} />
      {subtext && <cs.InputTextFieldSubLabel>{subtext}</cs.InputTextFieldSubLabel>}
    </InputSection>
  );
}

/**
 * RSuite's input component for the onChange the order of the arguments is val, event but for NumberFormat it takes event first
 * so we need to wrap cs.Input in this function that reverses the order of the arguments
 * @param {*} props
 * @returns
 */
export function ReverseArgumentsInput(props) {
  const { value, onChange } = props;

  return (
    <cs.Input
      value={value}
      onChange={(val, e) => onChange(e, val)}
    />
  )
}
