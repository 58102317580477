import * as appSelectors from 'app/state/app/app-selectors';
import * as loginSelectors from 'app/state/login/login-selectors';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { PORTAL_PATHS } from 'routes/constants';

import * as pbs from '../page-banner-styles';
import { HelpBannerItem } from './modules/help';
import { UserContextMenu } from './user-context-menu/user-context-menu';

export function PageBanner() {
  const bannerTitle = useSelector(appSelectors.pageBanner);
  const theme = useSelector(appSelectors.theme);

  const useCustomerPortal = useSelector(appSelectors.useCustomerPortal);

  const isUserCognitoAuthenticated = useSelector(
    loginSelectors.isUserCognitoAuthenticated,
  );

  const rootPath = useSelector(appSelectors.rootPath);
  const cityInfo = useSelector(appSelectors.cityInfo);

  const homePageRoute = useMemo(() => {
    return useCustomerPortal && isUserCognitoAuthenticated
      ? PORTAL_PATHS.dashboard
      : rootPath;
  }, [isUserCognitoAuthenticated, useCustomerPortal, rootPath]);

  if (!rootPath || !cityInfo) return null;

  return (
    <pbs.Banner>
      {/* Left side */}
      <pbs.BannerSection>
        <pbs.LogoContainer to={homePageRoute}>
          <pbs.CityLogo
            src={cityInfo?.logo || `/assets/city-logos/${cityInfo?.place}.png`}
            alt={`${cityInfo.name} logo`}
            width={cityInfo.pageBannerLogoWidth}
          />
        </pbs.LogoContainer>

        <pbs.HeaderLabel fontSize={theme?.bannerFontSize}>
          {bannerTitle || 'Short-Term Rental Management System'}
        </pbs.HeaderLabel>
      </pbs.BannerSection>

      {/* Right side */}
      <pbs.BannerSection>
        {useCustomerPortal ? <UserContextMenu /> : <HelpBannerItem />}
      </pbs.BannerSection>
    </pbs.Banner>
  );
}
