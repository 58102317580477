import * as appSelectors from 'app/state/app/app-selectors';
import {
  DISABLE_UPDATE_FLOW_PATH,
  PERMIT_VERBIAGE,
  PERMIT_VERBIAGE_TRANSLATION_KEY,
} from 'common/constants';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Alert } from 'rsuite';
import { CustomerPortalStrategy } from 'strategies/customer-portal/customer-portal-strategy';
import { UpdateFlowEntryError } from 'strategies/customer-portal/errors';
import { captureExceptionWithContext } from 'utils/sentry-functions';
import { useTranslations } from 'utils/translations';

import { Button } from './styles';

export function UpdatePermitButton(props) {
  const {
    loading,
    setLoading,
    actionable,
    applicationNumber,
    activePermitData: permitData,
  } = props;

  const disableUpdateFlowPath = useSelector(
    appSelectors.getFromPortalStats(DISABLE_UPDATE_FLOW_PATH),
  );

  const translations = useTranslations();

  const handlePermitUpdateClick = useCallback(async () => {
    if (disableUpdateFlowPath) return;

    try {
      await CustomerPortalStrategy.getInstance().initiateUpdateFlowEntry({
        setLoading,
        applicationNumber,
        permitData,
      });
    } catch (err) {
      setLoading(false);

      Alert.error(
        'Failed to initiate the update process. Please try again later.',
        10000,
      );

      captureExceptionWithContext(new UpdateFlowEntryError(err), {
        applicationNumber,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationNumber, permitData]);

  if (disableUpdateFlowPath) return null;

  return (
    <Button
      disabled={disableUpdateFlowPath || loading || !actionable}
      onClick={handlePermitUpdateClick}
    >
      Update{' '}
      {translations?.[PERMIT_VERBIAGE_TRANSLATION_KEY]?.default ??
        PERMIT_VERBIAGE}{' '}
      Details
    </Button>
  );
}
