import { captureException } from '@sentry/react';
import * as appSelectors from 'app/state/app/app-selectors';
import { appActions } from 'app/state/app/app-slice';
import { dashboardActions } from 'app/state/dashboard/dashboard-slice';
import * as loginSelectors from 'app/state/login/login-selectors';
import { loginActions } from 'app/state/login/login-slice';
import { payloadActions } from 'app/state/payload/payload-slice';
import { Auth } from 'aws-amplify';
import last from 'lodash/last';
import {
  PortalAuthCustomChallengeError,
  PortalAuthLoginError,
  PortalAuthSignOutError,
} from 'login/errors';
import { useCallback } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { Alert } from 'rsuite';
import { authSender } from 'utils/auth-sender';
import { captureExceptionWithContext } from 'utils/sentry-functions';

export const USER_AUTH_SUCCESSFUL_MESSAGE = 'USER_SRP_AUTH';

export function getUserIdFromCognitoUserName(username) {
  /**
   * Users are in the format of "<PLACE>::userid/<ID>"
   * e.g. "tx-galveston-city_of_galveston::userid/1"
   */
  return last(username.split('/'));
}

// ! Important
// ! Ensure that "authenticateCurrentUser" in "App.jsx" is up-to-date with this custom hook
export function usePortalAuth() {
  const dispatch = useDispatch();

  const jurisdictionPlaceString = useSelector(appSelectors.place);

  const reduxStoredCognitoEmailAddress = useSelector(loginSelectors.userEmail);
  const reduxStoredCognitoUserName = useSelector(
    loginSelectors.loggedInUsername,
  );

  /**
   * This function is responsible for handling:
   *  1. initiating the login process
   *  2. handling the MFA code
   */
  const initiateUserAuthenticationProcess = useCallback(
    async (useInternalErrorHandling = true) => {
      try {
        // Require that the email address and user name exist before initiating any actions
        if (!reduxStoredCognitoEmailAddress || !reduxStoredCognitoUserName) {
          throw new Error('No Cognito username or email provided.');
        }

        // Initiate the login process
        const signInResponse = await Auth.signIn(reduxStoredCognitoUserName);
        // Send the MFA code to AWS Cognito to verify
        return await Auth.sendCustomChallengeAnswer(
          signInResponse,
          reduxStoredCognitoEmailAddress,
        );
      } catch (err) {
        if (useInternalErrorHandling)
          captureExceptionWithContext(new PortalAuthLoginError(err), {
            errorMsg:
              'Error when attempting to initiate user authentication process.',
            place: jurisdictionPlaceString,
            emailAddress: reduxStoredCognitoEmailAddress,
            username: reduxStoredCognitoUserName,
          });

        if (useInternalErrorHandling)
          Alert.error(
            'There was an issue while attempting to initiate the login process. Please try again at a later time.',
            10000,
          );

        // Re-throw so that the caller can perform additional actions if needed on an error
        throw err;
      }
    },
    [
      jurisdictionPlaceString,
      reduxStoredCognitoEmailAddress,
      reduxStoredCognitoUserName,
    ],
  );

  /**
   * This is update the Redux state leading to the rendering of "PortalCognitoAuthModal",
   * which will take over guiding the user through the login and authentication process.
   */
  const dispatchInitialUserLoginFlowActions = useCallback(
    (userEmail, user) => {
      // ! Do not change this as it is how the usernames in Cognito are structured
      const username = `${jurisdictionPlaceString}::userid/${user.userId}`;
      batch(() => {
        dispatch(loginActions.setOpenLoginModal(true));
        dispatch(loginActions.setUserEmail(userEmail));
        dispatch(payloadActions.setLookupModeEmail(userEmail));
        dispatch(loginActions.setLoggedInUsername({ username }));
        // The state will not be affected by the dispatch if user doesn't have
        // any fields on PERSON_FIELDS_OF_INTEREST which is the case for login
        // where getUserIdByEmail only return userId
        dispatch(loginActions.setUserAccountDetails(user));
      });
    },
    [dispatch, jurisdictionPlaceString],
  );

  const dispatchUserSignOutCleanUpActions = useCallback(() => {
    batch(() => {
      dispatch(appActions.setVisitedFirstPageInFlow({ value: false }));

      dispatch(loginActions.resetLogin());

      dispatch(payloadActions.resetFlow());

      dispatch(dashboardActions.resetDashboard());

      // TODO: Add the other slice resets as more and more features from the reg system is made compatible with Customer Portal
    });
  }, [dispatch]);

  const userSignOut = useCallback(async () => {
    try {
      await Auth.signOut();
    } catch (err) {
      captureException(new PortalAuthSignOutError(err));
      window.localStorage.clear();
    } finally {
      dispatchUserSignOutCleanUpActions();
    }
  }, [dispatchUserSignOutCleanUpActions]);

  // This is a set of actions for when the user provides the correct MFA code
  const dispatchUserSignInActions = useCallback(
    async (possibleCognitoUserSession) => {
      const { attributes, username } = possibleCognitoUserSession;
      const { email } = attributes;

      const isUserCognitoAuthenticated =
        possibleCognitoUserSession.signInUserSession.isValid();

      dispatch(
        loginActions.setCognitoUserDetails({
          isUserCognitoAuthenticated,
          username,
          email,
        }),
      );

      if (isUserCognitoAuthenticated) {
        const { data: user } = await authSender.get(
          `/getUserByEmailAuth/${email}`,
        );
        dispatch(loginActions.setUserAccountDetails(user));
      }

      dispatch(loginActions.setOpenLoginModal(false)); // This should happen last.
    },
    [dispatch],
  );

  /**
   * This function is for handling just the interaction of sending the MFA code
   * to AWS Cognito.
   */
  const handleCustomChallengeResponse = useCallback(
    async (currentCognitoUserRef, challengeResponse, invalidAnswerCB) => {
      try {
        const possibleCognitoUserSession = await Auth.sendCustomChallengeAnswer(
          currentCognitoUserRef,
          challengeResponse,
        );

        if (
          possibleCognitoUserSession.authenticationFlowType !==
          USER_AUTH_SUCCESSFUL_MESSAGE
        ) {
          return invalidAnswerCB();
        }

        await dispatchUserSignInActions(possibleCognitoUserSession);
      } catch (err) {
        captureExceptionWithContext(new PortalAuthCustomChallengeError(err), {
          errorMsg:
            'Error when attempting to verify an MFA code during account creation process',
          place: jurisdictionPlaceString,
          cognitoUserName: reduxStoredCognitoUserName,
          cognitoEmailAddress: reduxStoredCognitoEmailAddress,
        });

        Alert.error(
          'There was an issue with verifying your MFA code. Please try again at a later time.',
          10000,
        );

        // Re-throw so that the caller can perform additional actions if needed on an error
        throw err;
      }
    },
    [
      dispatchUserSignInActions,
      jurisdictionPlaceString,
      reduxStoredCognitoEmailAddress,
      reduxStoredCognitoUserName,
    ],
  );

  return {
    initiateUserAuthenticationProcess,
    dispatchInitialUserLoginFlowActions,
    dispatchUserSignOutCleanUpActions,
    userSignOut,
    handleCustomChallengeResponse,
  };
}
