import React from 'react';
import * as appSelectors from 'app/state/app/app-selectors';
import * as networkSelectors from 'app/state/network/network-selectors';
import * as payloadSelectors from 'app/state/payload/payload-selectors';
import * as cs from 'common/common-styles';
import { InputContainer, InputSection } from 'common/common-styles';
import {
  DATE_INPUT_VALUE_FORMAT,
  DEFAULT_FORM_VALUE as emptyString,
} from 'common/constants';
import { useField, useFormikContext } from 'formik';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { InputGroup, Radio, RadioGroup } from 'rsuite';
import styled from 'styled-components/macro';
import { inputFieldTestId, multiInputFieldTestId } from 'test/constants';
import {
  DEFAULT_CITY_TIMEZONE,
  getCurrentTimeInTimezone,
} from 'utils/utility-functions';

import { mapValues } from 'lodash';
import { dataTransformer, evaluateCondition } from '../configurable-form-utils';
import { MaskPhoneNumberInput } from './inputs/mask-phone-number-input';
import { FormikError, InputComponent } from './inputs/shared-components';
import { withShowHideCapability } from './inputs/with-show-hide-capability';
import { WarningInfo } from './warning-info';

const MultiInputDiv = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
`;

const MultiInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const phoneInputType = 'tel';
const dateInputType = 'date';

function preventInvalidInputChange(evt) {
  if (evt.key === 'e') {
    evt.nativeEvent.preventDefault();
  }
}

function inputComponentFactory(type) {
  switch (type) {
    case phoneInputType:
      return MaskPhoneNumberInput;
    default:
      return InputComponent;
  }
}

const decimal = '.';

/**
 * Returns "true", if there is a decimal and more than 2 decimal places
 * This is used in currency inputs with dollar amounts to prevent more than 2 decimal places
 */
function greaterThanTwoDecimalPlaces(value) {
  if (!value) return false;

  const decimalIndex = value.indexOf(decimal);

  // Number of digits after the decimal place
  const numDecimalPlaces = value.length - decimalIndex - 1;
  return decimalIndex !== -1 && numDecimalPlaces > 2;
}

export function useDisabled(componentProps, licenseInfo) {
  const enableEditingAllFields = useSelector(
    appSelectors.enableEditingAllFields,
  );

  const formikSnapshot = useSelector(payloadSelectors.formikSnapshot);

  return useMemo(() => {
    if (enableEditingAllFields) return false;
    if (componentProps?.disabled) return componentProps?.disabled;

    if (componentProps?.disabledFormikCondition) {
      return evaluateCondition(formikSnapshot, componentProps?.disabledFormikCondition);
    }

    if (componentProps?.disabledCondition)
      return Boolean(
        get(licenseInfo, componentProps?.disabledCondition, emptyString)
          ?.length,
      );
  }, [
    licenseInfo,
    componentProps?.disabled,
    componentProps?.disabledCondition,
    componentProps?.disabledFormikCondition,
    enableEditingAllFields,
    formikSnapshot,
  ]);
}

/**
 * Sample Config Keys:
 *
 *  component: InputField
 *    Required - string
 *    The name of the component that will be used to render
 *
 *  id:
 *    Required - string
 *    String for the React prop "key"
 *
 *  name:
 *    Required - string
 *    Formik uses this as the key to store the values with in a dictionary
 *      Can use lodash-like dot path: https://formik.org/docs/api/field#name
 *
 *  type:
 *    Optional - string
 *    https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#input_types
 *      type = "tel"
 *        Will render the MaskPhoneNumberInput component
 *
 *  label:
 *    Optional - string
 *    Label for the input field component
 *
 *  subtext:
 *    Optional - string
 *    Text that is displayed below the input field component
 *
 *  noDefaultDate:
 *    Optional - boolean
 *    if true, date input will not have a default date
 *
 *  placeholder:
 *    Optional - string
 *    Placeholder text displayed within the input field component
 *
 *  validation:
 *    Optional - dictionary
 *    Yup validation scheme
 *
 *  trimStart:
 *    Optional - boolean
 *    Trim the start of the field before validation, and update the Formik state.
 *
 *  disabled:
 *    Optional - boolean
 *    Will disable the input field if true
 *
 *  disabledCondition:
 *    Optional - string (state path to a value to check the length of)
 *    Currently, only in the Update flow
 *      Because there are some places where we do not have the first name,
 *        last name, and other fields saved
 *        So we want to be able to conditionally disable these fields when
 *          there are values, but enable these fields when there are no values
 *    Will disable the input field if the retrieved value has a length
 *
 *  isShownOrHidden:
 *    Optional - dictionary
 *    Determines when a component is shown or hidden based on Formik values
 *
 *    hideWhen:
 *      When all conditionals are true, this component will be hidden
 *      Refer to comments for evalParams function within "shared-components"
 *
 *    showWhen:
 *      When all conditionals are true, this component will be shown
 *      Refer to comments for evalParams function within "shared-components"
 */
function InputFieldComponent(props) {
  const {
    name,
    type = 'text',
    validate,
    onChange,
    onKeyPress,
    onDOMBlur,
    label,
    subtext,
    additionalCSS,
    labelAdditionalCSS,
    noDefaultDate,
    imgSrc,
    leftHandAdornment,
    trimStart,
    dataCy,
    clearable,
    ...other
  } = props;

  const originalLicenseInfo = useSelector(networkSelectors.originalLicenseInfo);
  const cityInfo = useSelector(appSelectors.cityInfo);

  const [field, meta] = useField({ name, type, validate });

  const Input = useMemo(() => inputComponentFactory(type), [type]);

  const disabled = useDisabled(other, originalLicenseInfo);

  const defaultedValue = useMemo(() => {
    if (type === dateInputType && !noDefaultDate) {
      const todaysDate = getCurrentTimeInTimezone(
        cityInfo?.timezone || DEFAULT_CITY_TIMEZONE,
      );
      return todaysDate.format(DATE_INPUT_VALUE_FORMAT);
    }

    switch (field.value) {
      case undefined:
        return emptyString;
      case null:
        return emptyString;
      default:
        return field.value;
    }
  }, [cityInfo?.timezone, field.value, noDefaultDate, type]);

  const performTrimStart = useCallback(
    (e) => {
      if (trimStart) {
        const val = e.target.value;
        e.target.value = val.trimStart();
      }
      onChange ? onChange(e) : field.onChange(e);
    },
    [trimStart, onChange, field],
  );

  return (
    <InputSection css={additionalCSS}>
      {label && <cs.InputTextFieldLabel css={labelAdditionalCSS}>{label}</cs.InputTextFieldLabel>}

      <Input
        {...field}
        onChange={performTrimStart}
        onKeyPress={onKeyPress}
        onDOMBlur={onDOMBlur}
        value={defaultedValue}
        type={type}
        tooltip={other?.tooltip}
        disabled={disabled}
        data-testid={inputFieldTestId}
        imgSrc={imgSrc}
        leftHandAdornment={leftHandAdornment}
        dataCy={dataCy}
        clearable={clearable}
      />

      <FormikError
        {...meta}
        value={field.value}
        touched={other.alwaysDisplayError || meta.touched}
      />
      {subtext && (
        <cs.InputTextFieldSubLabel>{subtext}</cs.InputTextFieldSubLabel>
      )}
    </InputSection>
  );
}

export const InputField = withShowHideCapability(InputFieldComponent);

/**
 * This is a meant to be a currency input that does not allow leading zeros or more than 2 decimal places,
 * but has a bug that makes it effectively only allow integers (unless you paste in a float).
 * This component is used widely as an integer field. Do not change.
 */
export function InputNumberField(props) {
  const { name, dataCy } = props;
  const formikProps = useFormikContext();

  /**
   * TODO: Fix leading zeroes removal process
   */
  const handleInputChange = useCallback(
    (e) => {
      const val = e.target.value;
      const leadingZeros = /^[0]+[0-9]+\.?[0-9]*$/;
      const NUMERIC_PERIOD_ONLY = /^\d*\.?\d*$/;

      if (props.optional && val === emptyString) {
        formikProps.setFieldValue(name, null);
        return;
      }

      //only allow numbers and one period and up to 2 decimal places
      if (!NUMERIC_PERIOD_ONLY.test(val) || greaterThanTwoDecimalPlaces(val))
        return;

      if (leadingZeros.test(val)) {
        formikProps.setFieldValue(name, Number(val));
      } else {
        formikProps.setFieldValue(name, Number(val));
      }
    },
    [formikProps, name, props.optional],
  );

  return (
    <InputContainer>
      <InputGroup inside>
        {/* Using nativeEvent because the event propagation should bubble up outside of react-land*/}
        <InputField
          onKeyPress={preventInvalidInputChange}
          onChange={handleInputChange}
          imgSrc="icon-dollar"
          leftHandAdornment={true}
          dataCy={dataCy}
          {...props}
        />
      </InputGroup>
    </InputContainer>
  );
}

/**
 * This is a currency input that does not allow leading zeros or more than 2 decimal places
 */
export function InputCurrencyField(props) {
  const { name, dataCy } = props;
  const formikProps = useFormikContext();

  const handleInputChange = useCallback(
    (e) => {
      const val = e.target.value;

      if (props.optional && val === emptyString) {
        formikProps.setFieldValue(name, null);
        return;
      }

      if (Number.isNaN(Number(val)))
        return;

      formikProps.setFieldValue(name, val);
    },
    [formikProps, name, props.optional],
  );

  const handleInputDOMBlur = useCallback(
    (e) => {
      const val = e.target.value;
      formikProps.setFieldValue(name, Number(val).toFixed(2));
    },
    [formikProps, name],
  );

  return (
    <InputContainer>
      <InputGroup inside>
        {/* Using nativeEvent because the event propagation should bubble up outside of react-land*/}
        <InputField
          onKeyPress={preventInvalidInputChange}
          onChange={handleInputChange}
          onDOMBlur={handleInputDOMBlur}
          imgSrc="icon-dollar"
          leftHandAdornment={true}
          dataCy={dataCy}
          {...props}
        />
      </InputGroup>
    </InputContainer>
  );
}

/**
 * Sample Config Keys:
 *
 *  component: MultiInputField
 *    Required - string
 *    The name of the component that will be used to render
 *
 *  id:
 *    Required - string
 *    Formik uses this to assign errors to the proper field component
 *
 *  name:
 *    Required - string
 *    Formik uses this as the key to store the values with in a dictionary
 *      Can use lodash-like dot path: https://formik.org/docs/api/field#name
 *
 *  components:
 *    Required - array of the config for InputField
 */
function MultiInputFieldComponent({
  additionalCSS,
  components,
  alternateComponents,
  prefixComponent,
}) {
  const {
    alternateComponentSets = [],
    defaultComponentSetSelectionTransforms,
    defaultComponentSetId,
    defaultComponentSetName,
    transformTargetPath,
  } = alternateComponents || {};

  const { type, content, variables, displayCondition: prefixComponentDisplayCondition } = prefixComponent || {};

  const { values: formikValues, setFieldValue } = useFormikContext();

  const renderPrefixComponent = useMemo(() => {
    if (!type) return null;

    const shouldDisplay = evaluateCondition(
        formikValues,
        prefixComponentDisplayCondition,
      )

    if (!shouldDisplay) return null;

    const processedVariables = mapValues(variables, (value) => {
        switch (value.op) {
          case 'get':
            return get(formikValues, value.key)

            default:
              return value
        }
    })
    
        switch (type) {
          case 'warningInfo':
            return <WarningInfo content={content} variables={processedVariables} />;
          default:
            return null;
        }
  // Purposefully excluding "formikValues" from the hook dependencies array to prevent warning from disappearing when name field is filled
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, content, variables, prefixComponentDisplayCondition]);


  const componentSetById = useMemo(() => {
    const initAccumulator = {
      [defaultComponentSetId]: {
        name: defaultComponentSetName,
        components,
        ...(!defaultComponentSetSelectionTransforms
          ? {}
          : {
              selectionTransforms: defaultComponentSetSelectionTransforms,
            }),
      },
    };

    return alternateComponentSets.reduce(
      (acc, compSetSpec) => ({
        ...acc,
        [compSetSpec.id]: compSetSpec,
      }),
      initAccumulator,
    );
    /**
     * No dependencies are listed for this hook as they all come from the config, so the
     * variables used are unchanging.
     *
     * Including either "alternateComponentSets" or "components" as dependencies will cause
     * this component to crash the app. This is most likely due to the series of re-renders
     * cascading thru the rest of the logic/components being computed following this.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const markNameFieldsetType = useCallback(
    (type) => {
      if (transformTargetPath)
        setFieldValue(`${transformTargetPath}.misc.adminNameFieldType`, type);
    },
    [setFieldValue, transformTargetPath],
  );

  const initialComponentSetId = useMemo(() => {
    const conditionallySelectedSetId = Object.keys(componentSetById).find(
      (setId) => {
        const componentSet = componentSetById[setId];
        const { initiallySelected: initiallySelectedCondition } = componentSet;
        if (!initiallySelectedCondition || !transformTargetPath) return false;

        const scope = get(formikValues, transformTargetPath, {});

        return evaluateCondition(scope, initiallySelectedCondition);
      },
    );

    const initID =
      conditionallySelectedSetId || alternateComponents?.defaultComponentSetId;
    markNameFieldsetType(initID);
    return initID;
    /**
     * Excluding "formikValues" from the hook dependencies array, because this function
     * determines the INITIAL set of components to display in the admin flows so changes
     * based on user interactions with the form should not trigger another run.
     *
     * It is possible that "formikValues" changing was causing an infinite re-render when the
     * user switched between the split name and combined name input fields in the admin flows.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    alternateComponents?.defaultComponentSetId,
    componentSetById,
    markNameFieldsetType,
    transformTargetPath,
  ]);

  const [currentComponentSetId, setCurrentComponentSetId] = useState(
    initialComponentSetId,
  );

  const isAlternateComponentsEnabledConditionTrue = useMemo(
    () =>
      evaluateCondition(
        formikValues,
        alternateComponents?.alternateComponentsEnabledCondition,
      ),
    [alternateComponents?.alternateComponentsEnabledCondition, formikValues],
  );

  const displayAlternateComponentSets =
    Boolean(alternateComponents) && isAlternateComponentsEnabledConditionTrue;

  const renderedComponents = useMemo(() => {
    const availableComponents =
      componentSetById[currentComponentSetId]?.components || [];
    return (
      <MultiInputWrapper>
        {renderPrefixComponent}
        <MultiInputDiv css={additionalCSS} data-testid={multiInputFieldTestId}>
          {availableComponents.map((component) => (
            <InputField
              key={component.id || component.name}
              {...component}
              additionalCSS={`flex: 1; ${component.additionalCSS || ''}`}
            />
          ))}
        </MultiInputDiv>
      </MultiInputWrapper>
    );
  }, [additionalCSS, componentSetById, currentComponentSetId, renderPrefixComponent]);

  const changeCurrentComponentSetId = useCallback(
    (componentSetId) => {
      setCurrentComponentSetId(componentSetId);
      const { selectionTransforms } = componentSetById[componentSetId];
      if (!selectionTransforms) return;

      if (!transformTargetPath) return;
      const transformTarget = get(formikValues, transformTargetPath, {});
      const transformResult = cloneDeep(transformTarget);
      dataTransformer(selectionTransforms, transformTarget, transformResult);

      setFieldValue(transformTargetPath, transformResult);
    },
    [componentSetById, formikValues, setFieldValue, transformTargetPath],
  );

  useEffect(() => {
    if (!displayAlternateComponentSets) return;
    markNameFieldsetType(initialComponentSetId);
    setTimeout(() => changeCurrentComponentSetId(currentComponentSetId), 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (displayAlternateComponentSets) {
    return (
      <div>
        <cs.InputTextFieldLabel>
          {alternateComponents?.topLevelLabel}
        </cs.InputTextFieldLabel>

        <RadioGroup
          inline
          value={currentComponentSetId}
          onChange={changeCurrentComponentSetId}
        >
          {Object.keys(componentSetById).map((componentSetId) => (
            <Radio
              key={componentSetId}
              value={componentSetId}
              onClick={() => markNameFieldsetType(componentSetId)}
            >
              {componentSetById[componentSetId].name}
            </Radio>
          ))}
        </RadioGroup>

        {renderedComponents}
      </div>
    );
  }

  return renderedComponents;
}

export const MultiInputField = withShowHideCapability(MultiInputFieldComponent);
