import styled, { css } from 'styled-components';

export const Header = styled.div`
  margin-bottom: 40px;
`;

export const HeaderText = styled.div`
  font-size: 18px;
  line-height: 1.56;
  color: #5c5d5d;
`;

export const HeaderSubText = styled.div`
  font-size: 14px;
  line-height: 1.5;
  color: #5c5d5d;
`;

export const ListItem = styled.div`
  display: flex;
  padding-bottom: 20px;
`;

const listFontBase = css`
  font-family: 'Lato';
  font-size: 14px;
  line-height: 1.29;
  color: #000000;
`;

export const ListLabel = styled.div`
  ${listFontBase};
  display: inline-block;
  padding: 0 15px;
  font-weight: bold;
`

export const ListContent = styled.div`
  display: inline-block;
  max-width: 800px;
`

export const ListHeader = styled.div`
  ${listFontBase};
  font-weight: bold;
  margin-bottom: 5px;
`;

export const ListBody = styled.div`
  ${listFontBase};
  color: #5c5d5d;
`;

export const TableGrid = styled.div`
  margin-top: 10px;
  display: grid;
  grid-template-columns: ${(props) => props.columns ? `repeat(${props.columns}, 1fr)` : 'auto'};
  gap: 10px;
`;

const boldFontType = 'header';

export const TableCell = styled.div`
  ${listFontBase};
  font-weight: ${(props) => props.type === boldFontType ? 'bold' : 'normal'};
  line-height: 1.5;
  color: #5c5d5d;
`;
