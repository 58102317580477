import { store } from 'app/app-store';
import { loginActions } from 'app/state/login/login-slice';
import { payloadActions } from 'app/state/payload/payload-slice';
import { Auth } from 'aws-amplify';
import get from 'lodash/get';
import { batch } from 'react-redux';
import { ROOT_PATH } from 'routes/constants';
import { history } from 'routes/history';
import { Alert } from 'rsuite';
import { StrRegistrationAPISender } from './str-registration-api-sender';
import { checkCognitoUserForCustomerPortal } from './utility-functions';

export class AuthSender extends StrRegistrationAPISender {
  setCustomJWT(jwt) {
    this.customJWT = jwt;
    this.setAdminFlowMode(Boolean(jwt));
  }

  async setToken() {
    // getting the current session will refresh the token
    let idToken;
    if (this.customJWT) {
      idToken = this.customJWT;
    } else {
      const cognitoUserSession = await Auth.currentSession();
      idToken = cognitoUserSession.idToken.jwtToken;
    }
    this.axiosInstance.defaults.headers.common.Authorization = `Bearer ${idToken}`;
  }

  async ensureAuthenticationTokenIsAvailable() {
    const usingCustomerPortal = get(store.getState(), 'app.stats.app.type') === 'portal';
    const queryParams = new URLSearchParams(window.location?.search);
    const rentalscapeLoginToken = queryParams.get('operatorLoginToken');
    const usingAdminFlow = rentalscapeLoginToken || this.adminFlowMode;

    /**
     * An authentication token is ensured to be available when one of the following conditions are met:
     *  1. When the user is not using the Customer Portal as the user has to "auth" themselves into
     *      the update, renewal, pay, or another non-standard flow.
     *  2. "usingAdminFlow" is a combination of the following:
     *    A. When "rentalscapeLoginToken" is available, which is generated from a user attempting to
     *        initiate an admin flow from Rentalscape.
     *    B. When "this.adminFlowMode" is enabled, which is set when the registration system is
     *        instructed to initiate the admin flow.
     *        This last line also fixes an issue where users in the Customer Portal are being
     *          forced off their session during an Admin flow as the first condition is no longer
     *          evaluated to "true" or some truthy value.
     */
    if (!usingCustomerPortal || usingAdminFlow) return true;

    const placeString = get(store.getState(), 'app.stats.cityInfo.place');
    let isValidAuthedPortalUser;

    try {
      const congitoCurrentAuthenticatedUser = await Auth.currentAuthenticatedUser();
      isValidAuthedPortalUser = checkCognitoUserForCustomerPortal(placeString, congitoCurrentAuthenticatedUser.username);
    } catch (err) {
      isValidAuthedPortalUser = false;
    }

    if (!isValidAuthedPortalUser) {
      batch(() => {
        store.dispatch(loginActions.setOpenLoginModal(false));
        store.dispatch(loginActions.setCognitoUserDetails(null));
        store.dispatch(payloadActions.setLookupModeEmail(null));
        store.dispatch(loginActions.setUserAccountDetails(null));
      });

      await Auth.signOut();

      Alert.closeAll();
      Alert.warning('Your session has either expired or is invalid. Please log in again before re-attempting.', 10000);

      history.push(ROOT_PATH);
    }

    return isValidAuthedPortalUser;
  }

  async rawGet(...args) {
    if (!this.ensureAuthenticationTokenIsAvailable(...args)) return;

    await this.setToken();
    return super.rawGet(...args);
  }

  async get(...args) {
    if (!this.ensureAuthenticationTokenIsAvailable(...args)) return;

    await this.setToken();
    return super.get(...args);
  }

  async post(...args) {
    if (!this.ensureAuthenticationTokenIsAvailable(...args)) return;

    await this.setToken();
    return super.post(...args);
  }

  async getNoPlace(...args) {
    if (!this.ensureAuthenticationTokenIsAvailable(...args)) return;

    await this.setToken();
    return super.getNoPlace(...args);
  }
}

export const authSender = new AuthSender();
