import { BlackButton } from 'common/navigation-buttons';
import styled from 'styled-components';

import { Flex } from '../../styles';

export const ButtonsFlex = styled(Flex)`
  gap: 20px;
`;

export const Button = styled(BlackButton)`
  width: fit-content !important;
  padding: 5px 10px !important;
`;
