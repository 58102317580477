import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SelectPicker } from 'rsuite';
import first from 'lodash/first';
import styled from 'styled-components';
import * as appSelectors from 'app/state/app/app-selectors';
import * as payloadSelectors from 'app/state/payload/payload-selectors';
import { payloadActions } from 'app/state/payload/payload-slice';
import * as cs from 'common/common-styles';
import { DEFAULT_FORM_VALUE as emptyString, FLOWS } from 'common/constants';
import { parseHTMLString, userInputTrimmer } from 'utils/utility-functions';
import { TRANSLATED_STRING_IDS, useTranslations } from "utils/translations";

const Container = styled.div`
  margin-top: 40px;
`;

const Header = styled(cs.HeaderText)`
  margin-top: 40px;
`;

const Input = styled(cs.StyledInput)`
  width: 200px;
`;

const Dropdown = styled(SelectPicker)`
  width: 200px;
  height: 36px;

  a > span {
    color: black !important;
  }
`;

const defaultOptionLabels = {
  main_structure: 'Main Structure',
  adu: 'ADU (Additional Dwelling Unit)',
  multifamily: 'Apartment/Condo/Multifamily'
}

const defaultOptions = [
  { value: 'main_structure' },
  { value: 'adu' },
  { value: 'multifamily' },
];

const emptyOptions = [{ label: '', value: '' }];

export function RentalDetails(props) {
  const dispatch = useDispatch();

  const renderConfig = useSelector(appSelectors.renderConfig);
  const activeFlow = useSelector(appSelectors.activeFlow);

  const formikSnapshot = useSelector(payloadSelectors.formikSnapshot);

  const {
    disableRentalDetails,
    rentalDetailsConfig = {}
  } = renderConfig?.search;

  const showRentalStructureDropdown = !rentalDetailsConfig?.hideRentalStructureField;

  const showUnitNumberField = useMemo(() => {
    const displayFieldInFlow =
      FLOWS.APPLY_LICENSE === activeFlow ||
      Boolean(rentalDetailsConfig?.enableUnitNumberFieldInAltFlow);

    return displayFieldInFlow || props?.shouldShowUnitNumberField;
  }, [activeFlow, props?.shouldShowUnitNumberField, rentalDetailsConfig]);

  const translations = useTranslations();
  const rentalStructureTranslations = translations?.[TRANSLATED_STRING_IDS.rentalStructure];

  const rentalStructureDropdownOptions = useMemo(() => {
    if (rentalDetailsConfig?.hideRentalStructureField) return emptyOptions;
    const options = rentalDetailsConfig?.availableOptions || defaultOptions;

    return options.map((opt) => ({
      ...opt,
      label: opt.label || rentalStructureTranslations?.[opt.value]?.default || defaultOptionLabels[opt.value] || opt.value
    }));
  }, [rentalDetailsConfig?.availableOptions, rentalDetailsConfig?.hideRentalStructureField, rentalStructureTranslations]);

  // Input Fields
  const [unitNumber, setUnitNumber] = useState(emptyString);

  const [rentalStructure, setRentalStructure] = useState(
    rentalDetailsConfig?.noDefaultRentalStructure ?
      emptyString :
      first(rentalStructureDropdownOptions).value
  );

  useEffect(() => {
    if (props.initializeRentalDetailsFromState) {
      if (formikSnapshot?.rentalStructure)
        setRentalStructure(formikSnapshot?.rentalStructure);
      if (formikSnapshot?.unitNumber)
        setUnitNumber(formikSnapshot?.unitNumber);
    }
  }, [formikSnapshot?.rentalStructure, formikSnapshot?.unitNumber, props.initializeRentalDetailsFromState]);

  useEffect(() => {
    if (showUnitNumberField && formikSnapshot?.unitNumber === undefined) {
      dispatch(payloadActions.updateFormikSnapshot({ unitNumber }));
    }

    if (showRentalStructureDropdown && formikSnapshot?.rentalStructure === undefined) {
      dispatch(payloadActions.updateFormikSnapshot({ rentalStructure }));
    }
  }, [dispatch, formikSnapshot, formikSnapshot?.rentalStructure, formikSnapshot?.unitNumber, rentalStructure, showRentalStructureDropdown, showUnitNumberField, unitNumber]);

  const handleUnitNumberChange = useCallback((input) => {
    const parsed = userInputTrimmer(input);
    setUnitNumber(parsed);
    dispatch(payloadActions.updateFormikSnapshot({ unitNumber: parsed }));
  }, [dispatch]);

  const handleRentalStructureChange = useCallback((value) => {
    setRentalStructure(value);
    dispatch(payloadActions.updateFormikSnapshot({ rentalStructure: value }));
  }, [dispatch])

  const restrictedRentalStructure = useMemo(() => {
    const userSelectedRestrictedOption = rentalDetailsConfig?.restrictedOptions?.some((option) => (
      option.value === rentalStructure
    ));

    if (userSelectedRestrictedOption) return parseHTMLString(rentalDetailsConfig?.restrictedOptionsText);
  }, [rentalDetailsConfig?.restrictedOptions, rentalDetailsConfig?.restrictedOptionsText, rentalStructure]);

  const rentalStructureInfoText = useMemo(() =>
    parseHTMLString(rentalDetailsConfig?.rentalStructureInfoText),
    [rentalDetailsConfig?.rentalStructureInfoText],
  );

  if (disableRentalDetails) return null;

  const defaultUnitNumberTitle = `Unit number ${rentalDetailsConfig?.requireUnitNumber ? '' : '(Optional)'}`;

  const unitNumberTitle = rentalDetailsConfig?.unitNumberFieldTitle ? rentalDetailsConfig?.unitNumberFieldTitle : defaultUnitNumberTitle;

  return (
    <Container>
      {showUnitNumberField && (
        <div>
        <Header>
            {unitNumberTitle}
            {rentalDetailsConfig?.unitNumberFieldSubText && (
              <cs.HelperText>{rentalDetailsConfig?.unitNumberFieldSubText}</cs.HelperText>
            )}
          </Header>
          <Input value={unitNumber} onChange={handleUnitNumberChange} data-cy="unit-number-input" />
        </div>
      )}

      {showRentalStructureDropdown && (
        <div>
          <Header>{rentalDetailsConfig?.rentalStructureFieldTitle ?? 'Rental Structure'}</Header>
          <Dropdown
            disabled={props.disableRentalStructure}
            placeholder="Please Select"
            searchable={false}
            cleanable={false}
            value={rentalStructure}
            data={rentalStructureDropdownOptions}
            onChange={handleRentalStructureChange}
          />
          {restrictedRentalStructure}
          {rentalStructureInfoText}
        </div>
      )}
    </Container>
  );
}
