import { createSlice } from '@reduxjs/toolkit';
import merge from 'lodash/merge';

const INIT_STATE = {
  applicationNumber: null,
  // Try to use paymentFees for each and every payment form
  miscInfo: {},
  paymentFees: {},
  // Original license information to be used as a base for the update/renew flow
  originalLicenseInfo: {},
  successInfo: {}
};

export const networkSlice = createSlice({
  name: 'network',
  initialState: INIT_STATE,
  reducers: {
    setApplicationNumber: (state, { payload }) => {
      state.applicationNumber = payload;
    },

    // Do not confuse this with .misc from response payloads..
    // This is just for additional internal info
    setMiscInfo: (state, { payload }) => {
      state.miscInfo = { ...state.miscInfo, ...payload };
    },

    replaceMiscInfo: (state, action) => {
      state.miscInfo = action.payload;
    },

    setPaymentFees: (state, { payload }) => {
      state.paymentFees = payload;
    },

    setOriginalLicenseInfo: (state, { payload }) => {
      state.originalLicenseInfo = payload;
    },

    mergeOriginalLicenseInfo: (state, { payload }) => {
      state.originalLicenseInfo = merge({}, state.originalLicenseInfo, payload);
    },

    setSuccessInfo: (state, { payload }) => {
      state.successInfo = payload;
    },

    reset: () => INIT_STATE,
  },
})

export const networkActions = networkSlice.actions;

export const networkReducer = networkSlice.reducer;
