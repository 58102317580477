import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom/cjs/react-router-dom';
import { Button, ControlLabel, FormGroup, SelectPicker } from 'rsuite';
import { useFormik } from 'formik';
import styled from 'styled-components';
import { StrRegistrationAPISender } from 'utils/str-registration-api-sender';

import { NoticeBox, Type } from './modules/notice-box';
import { PaymentMethodModule } from 'configurable-form/components/payment/modules/payment-methods/payment-method-module';
import { InputComponent } from 'configurable-form/components/inputs/shared-components';
import * as appSelectors from 'app/state/app/app-selectors';
import { useIsACHActive, useIsCCActive } from 'configurable-form/components/payment/modules/payment-methods/hooks';
import { paymentMethodType } from 'common/constants';
import appSettings from 'app-settings.json';

const FORM_WIDTH = 450;

const CardConnectContainer = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  margin-top: 3.5rem;
`;

const Form = styled.form`
  margin: 1rem 0;
`;

const CardConnectFormWrapper = styled.div`
  padding: 1.5rem 1rem;
  width: ${FORM_WIDTH}px;
  box-sizing: border-box;
`;

const HeaderText = styled.p`
  color: #5c5d5d;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 1.5rem;
`;

const CustomFormGroup = styled(FormGroup)`
  display: flex;
  flex-direction: column;
  margin: 0.5rem 0;
  width: 100%;
`;

const CustomLabel = styled(ControlLabel)`
  padding-right: 20px;
  width: 150px;
`;

const CustomInput = styled(InputComponent)`
  flex-grow: 1;
  width: 100%;
  max-width: 100%;
`;

const CustomInputInner = styled(InputComponent)`
  flex-grow: 1;
  margin: 0;
  margin-bottom: 0.5rem;
  width: 100%;
  max-width: 100%;
`;

const CustomDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
`;

const CustomSelect = styled(SelectPicker)`
  flex-grow: 1;
  width: 100%;
`;

const CustomButton = styled(Button)`
  width: 100%;
`;

const RegionWrapper = styled.div`
  display: grid;
  grid-template-columns: 4fr 3fr 2fr;
`;

const ErrorMessage = styled.div`
  color: #991111;
  font-size: 0.75rem;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 50%;
  margin-top: 1rem;
`;

const IFRAME_PARAMS = {
  placeholdercvv: 'CVV',
  invalidcreditcardevent: true,
  invalidcvvevent: true,
  invalidexpiryevent: true,
  enhancedresponse: true,
  css: `
    body{
      margin:0;
    }
    label{
      color:#575757;
      font-family:PingFang SC, sans-serif;
      font-size:14px;
      font-weight:normal;
      font-smoothing:antialiasing;
      margin:4px 0;
    }
    input, select{
      border:1px solid #E5E5EA;
      border-radius:6px;
      box-sizing:border-box;
      font-size:14px;
      color:#575757;
      padding:7px 11px;
      height:36px;
    }
    input{
      width:100%;
    }
  `,
};

IFRAME_PARAMS.css = IFRAME_PARAMS.css
  .split(/[\r\n]+/)
  .filter(Boolean)
  .join('');

let IFRAME_URL = new URL('https://vsi-uat.cardconnect.com/itoke/ajax-tokenizer.html');
if (appSettings.env === 'prod') {
  IFRAME_URL = new URL('https://vsi.cardconnect.com/itoke/ajax-tokenizer.html');
}


IFRAME_URL.search = new URLSearchParams(IFRAME_PARAMS);

// From: https://v5.reactrouter.com/web/example/query-parameters
// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const accountType = [
  { label: 'Checking', value: 'ECHK' },
  { label: 'Savings', value: 'ESAV' },
];

// place, paymentId and amount come via query params
export function PayCardConnect() {
  const query = useQuery();
  const history = useHistory();

  const paymentId = query.get('paymentId');
  const amount = query.get('amount');
  const place = query.get('place');
  const flow = query.get('flow');

  const formik = useFormik({
    initialValues: {
      name: undefined,
      address: undefined,
      address2: undefined,
      city: undefined,
      region: undefined,
      postal: undefined,
      accttype: undefined,
    },
    validate: (values) => {
      const errors = {};
      if (!values.name) {
        errors.name = 'This field is required';
      }
      if (!values.address) {
        errors.address = 'This field is required';
      }
      if (!values.city) {
        errors.city = 'This field is required';
      }
      if (!values.region) {
        errors.region = 'This field is required';
      }
      if (!values.postal) {
        errors.postal = 'This field is required';
      } else if (!/^\d{5}(-\d{4})?$/.test(values.postal)) {
        errors.postal = 'Enter a valid Zip Code';
      }
      if (!values.accttype && paymentType !== 'CC') {
        errors.accttype = 'This field is required';
      }
      if (!token) {
        errors.token = 'Provide the data for the card';
      }
      return errors;
    },
    onSubmit: values => {
      if (!errorInData) {
        postOnClick();
      }
    },
  });

  const [token, setToken] = useState(null);
  const [error, setError] = useState(false);
  const [formError, setFormError] = useState();
  const [posted, setPosted] = useState(false);
  const flows = useSelector(appSelectors.flows);
  const stats = useSelector(appSelectors.stats);
  const activeFlow = useSelector(appSelectors.activeFlow);
  const isPushACHActive = stats?.app?.enablePushACH || Boolean(flows[activeFlow]?.isPushACHActive);
  const isCCActive = useIsCCActive();
  const isACHActive = useIsACHActive();

  const activePaymentMethodTab = useSelector(appSelectors.activePaymentMethodTab);

  const paymentType = useMemo(() => {
    if (activePaymentMethodTab === paymentMethodType.PUSH_ACH) {
      return 'PUSH_ACH';
    } else if (activePaymentMethodTab === paymentMethodType.ACH) {
      return 'ACH';
    }
    return 'CC';
  }, [activePaymentMethodTab]);

  // Send all of the collected information off to the API
  // so that we can create the payment transaction and get
  // the process started.
  const postTransaction = async () => {
    const body = {
      // This is a reference into a secure Card Connect data
      // store where the card number, expiry, and CVV are stored
      // for a short time so we don't have to handle them
      // directly in our code.
      token,
      country: 'US',
      // Additional information that Card Connect suggests that
      // we send to help with authentication and anti-fraud
      // processing. This is generally considered less sensitive.
      ...formik.values,
      // We additionally track the payment ID on the transaction
      // to help us link our payment records and the transaction
      // record we can get out of Card Connect
      paymentId,
      // Account type is not required for credit cards
      accttype: paymentType === 'CC' ? null : formik.values.accttype,
      paymentType,
    };
    const api = new StrRegistrationAPISender();
    api.setCurrentPlace(place);
    try {
      const result = await api.post('/cardConnectPostback', body, 'json');
      if (result.data.status === 'Success') {
        history.push(`/${flow}/success?paymentId=${paymentId}`);
      } else {
        history.push(`/${flow}/payment-error`);
        console.error('Error executing the payment', result);
      }
    } catch (e) {
      console.error(e);
      setError(e);
    }
  };

  // Handle the state around the posting button.
  const postOnClick = () => {
    setPosted(true);
    return postTransaction().finally(() => setPosted(false));
  };

  // Create and remove the event handler for working with the iframe.
  // This collects and handles the token from Card Connect so we can
  // work with it in React.
  useEffect(() => {
    const updateToken = (event) => {
      // This verification is needed as it can cause to colluide with other messages
      if (/^http[s]+:\/\/.+.cardconnect.com/g.test(event.origin)) {
        const data = JSON.parse(event.data);
        setFormError(data.validationError);
        if (!data.validationError) {
          setToken(data.message);
        }
      }
    };
    window.addEventListener('message', updateToken, false);
    return () => {
      window.removeEventListener('message', updateToken);
    };
  }, []);

  const iframeUrl = useMemo(() => {
    const params = {
      ...IFRAME_PARAMS,
      useexpiry: paymentType === 'CC',
      usecvv: paymentType === 'CC',
      placeholder: paymentType === 'CC' ? 'Card' : 'RoutingNumber/AccountNumber',
      fullmobilekeyboard: paymentType !== 'CC',
    };

    const IFRAME_URL = new URL('https://vsi-uat.cardconnect.com/itoke/ajax-tokenizer.html');

    IFRAME_URL.search = new URLSearchParams(params);
    return IFRAME_URL;
  }, [paymentType]);

  const errorInData = useMemo(() => {
    /* Pending to handle correctly the situation where not enough data is provided, we should not render the contents */
    if (!paymentId || !amount || !place) {
      return 'Not enough information to process the payment.';
    }
    return null;
  }, [paymentId, amount, place]);

  const paymentDetailsInfo = useMemo(() => {
    if (paymentType !== 'CC') {
      return (
        <>
          <p>Please enter your ACH routing number and account number into the box below, separated by a / </p>
          <p>e.g. 123456789/1234123412341234</p>
        </>
      );
    }
  }, [paymentType]);

  const updatePaymentInfo = (e) => {
    formik.setFieldValue(e.target.name, e.target.value);
  };

  const setAccttype = (value) => {
    formik.setFieldValue('accttype', value);
  };

  if (errorInData) {
    return (
      <CardConnectContainer>
        <NoticeBox type={Type.WARNING} text={errorInData} />
      </CardConnectContainer>
    );
  }

  return (
    <CardConnectContainer>
      <CardConnectFormWrapper>
        <HeaderText>Amount Due: ${amount}</HeaderText>
        <PaymentMethodModule isPushACHActive={isPushACHActive} isCCActive={isCCActive} isACHActive={isACHActive} />
        {paymentDetailsInfo}
        <Form name="tokenform" id="tokenform">
          <iframe title="tokenFram" id="tokenFrame" name="tokenFrame" src={iframeUrl} width="420" height={paymentType === 'CC' ? '170' : '40'} frameBorder="0" scrolling="no"></iframe>
          <ErrorMessage>{formError || formik.errors.token}</ErrorMessage>
        </Form>
        <form onSubmit={formik.handleSubmit}>
          {paymentType !== 'CC' && (
            <CustomFormGroup>
              <CustomLabel>Account Type</CustomLabel>
              <CustomSelect data={accountType} searchable={false} required onChange={setAccttype} value={formik.values.accttype} />
              <ErrorMessage>{formik.errors.accttype}</ErrorMessage>
            </CustomFormGroup>
          )}
          <CustomFormGroup>
            <CustomLabel>Name</CustomLabel>
            <CustomInput value={formik.values.name} error={formik.errors.name} required maxLength={30} onChange={updatePaymentInfo} placeholder={paymentType === 'CC' ? 'Cardholder Name' : 'Account Holder Name'} name="name" />
            <ErrorMessage>{formik.errors.name}</ErrorMessage>
          </CustomFormGroup>
          <CustomFormGroup>
            <CustomLabel>Address</CustomLabel>
            <CustomDiv>
              <CustomInputInner required id="address" name="address" maxLength={30} onChange={updatePaymentInfo} placeholder="Address Line 1" value={formik.values.address} />
              <ErrorMessage>{formik.errors.address}</ErrorMessage>
              <CustomInputInner id="address2" name="address2" maxLength={30} onChange={updatePaymentInfo} placeholder="Address Line 2 (optional)" value={formik.values.address2} />
              <RegionWrapper>
                <div>
                  <CustomInputInner required id="city" name="city" maxLength={30} onChange={updatePaymentInfo} placeholder="City" value={formik.values.city} />
                  <ErrorMessage>{formik.errors.city}</ErrorMessage>
                </div>
                <div>
                  <CustomInputInner required id="region" name="region" maxLength={20} onChange={updatePaymentInfo} placeholder="State" value={formik.values.region} />
                  <ErrorMessage>{formik.errors.region}</ErrorMessage>
                </div>
                <div>
                  <CustomInputInner required id="postal" name="postal" maxLength={10} onChange={updatePaymentInfo} placeholder="Zip Code" value={formik.values.postal} type="number" />
                  <ErrorMessage>{formik.errors.postal}</ErrorMessage>
                </div>
              </RegionWrapper>
            </CustomDiv>
          </CustomFormGroup>
          <ButtonsWrapper>
            <CustomButton appearance="primary" loading={posted} type="submit">
              Pay ${amount}
            </CustomButton>
          </ButtonsWrapper>
          <ErrorMessage>{error}</ErrorMessage>
        </form>
      </CardConnectFormWrapper>
    </CardConnectContainer>
  );
}
