import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { InputGroup } from 'rsuite';
import get from 'lodash/get';
import { FormikError } from 'configurable-form/components/inputs/shared-components';
import { useInput } from 'configurable-form/components/utils/use-input';
import { ZERO } from 'common/constants';
import {
  checkIsRealNumber,
  createCurrencyValidation,
  InputContainer as SharedInputContainer,
  REPORT_REVENUE_DATA_CY_PREFIX_STRING,
  StyledInputNumber as SharedStyledInputNumber,
} from './shared';
import { useTOTReportingModuleConfigContext } from "../../tot-reporting-module-config-context";
import { useFormikContext } from 'formik';

const InputContainer = styled(SharedInputContainer)`
  cursor: not-allowed;
`;

const StyledAddon = styled(InputGroup.Addon)`
  background: #f7f7fa !important;
`;

const StyledInputNumber = styled(SharedStyledInputNumber)`
  /* This is to mask the border color transition on hovering the input field that's build into the InputNumber component */
  border: 1px solid #e5e5ea !important;

  input {
    /* This is to make "StyledAddon" color to not overlap with the values */
    z-index: 5 !important;
  }
`;

export function DerivedCurrencyFieldComponent(props) {
  const { values } = useFormikContext();

  const { lessThanAmount } = props;

  const validation = useMemo(
    () => createCurrencyValidation({ lessThanAmount }),
    [lessThanAmount]
  );

  const { field, meta, helpers } = useInput({ name: props.fieldKey, validation });

  useEffect(() => {
    const minuendValue = get(values, props.minuendKey);
    const subtrahendKeys = Array.isArray(props.subtrahendKey) ? props.subtrahendKey : [props.subtrahendKey]
    const subtrahendValues = subtrahendKeys.map(i => {
      const value = get(values, i)
      return checkIsRealNumber(value) ? Number(value) : 0;
    });
    /*
     * Note that when we sum up all the values we'll subtract by, we take the absolute value of the value
     * This is so if a customer has put in a deduction as negative by mistake, the calculation will still
     * operate as they expect. In practice, the deduction fields should be all validated to be greater than zero.
     */
    const subtrahendValue = subtrahendValues.reduce((acc, i) => acc += Math.abs(i), 0);

    if (!checkIsRealNumber(minuendValue)) return;
    const remaining = Math.max(minuendValue - subtrahendValue, ZERO.toFixed(2));

    if (field.value !== remaining) helpers.setValue(remaining, false);
  }, [field.value, helpers, props.minuendKey, props.subtrahendKey, values]);

  return (
    <InputContainer data-cy={`${REPORT_REVENUE_DATA_CY_PREFIX_STRING}-${props.fieldKey}`}>
      <InputGroup inside>
        <StyledAddon>
          <img src="/assets/icon-dollar.svg" alt="Dollar sign"/>
        </StyledAddon>

        <StyledInputNumber {...field} scrollable={false} disabled />
      </InputGroup>
      <FormikError {...meta} touched />
    </InputContainer>
  );
}
export function DerivedCurrencyField(props) {
  const { currencyFieldsLessThanAmount } = useTOTReportingModuleConfigContext();
  return (
    <DerivedCurrencyFieldComponent
      {...props}
      lessThanAmount={currencyFieldsLessThanAmount}
    />
  )
}
