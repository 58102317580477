import React, { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { GenericBaseModule } from './module/generic-base-module';

export function CardConnect(props) {
  const {
    strategyInstance,
    strategies,
    params: { text },
  } = props;

  const location = useLocation();

  const onClick = useCallback(
    (url, data) => {
      const { protocol, host, hostname } = window.location;
      const flow = location.pathname.split('/');
      let transformedUrl = data.url;
      if (hostname === 'localhost' || hostname === '127.0.0.1') {
        transformedUrl = `/${transformedUrl.split('/').slice(2).join('/')}`;
      }
      const paymentUrl = `${protocol}//${host}${transformedUrl}&flow=${flow[flow.length - 2]}`;
      window.open(paymentUrl).focus();
      strategyInstance.executeStrategy(strategies, 'postWindowOpen');
    },
    [strategyInstance, strategies, location]
  );

  return (
    <GenericBaseModule onClick={onClick} {...props}>
      <div>{text}</div>
    </GenericBaseModule>
  );
}
