import React, { useEffect } from 'react';
import { LoadingPage } from './Loading';
import { ROOT_PATH } from '../routes/constants';
import appSettings from '../app-settings.json';

export function IndexPageLoading() {
  useEffect(() => {
    const { location } = window;
    const isVfpr = location.href.match(/propertyscape-reg.([a-z].)*deckard.com/);

    // If on the first load the location is the ROOT_PATH, redirect
    // to the property registry url
    if (isVfpr && location.pathname === ROOT_PATH) {
        window.location = appSettings.vfprHomepage;
    }
  }, []);

  return <LoadingPage />;
}
