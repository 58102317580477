import { useCallback, useMemo } from "react";
import debounce from 'lodash/debounce';

export function useFieldCustomChangeHandler({ helpers, getValueFromArgs = (value) => value }) {
  /**
   * In order for the user to get immediate feedback in cases like when they enter a number of
   * days occupied that exceeds the number of days available, without having to tab out of the textbox
   * to discover the error message, we'd want to ask Formik to revalidate as soon as the user changes
   * the input value. However, validation on our TOT forms could be complex, and it is not a good idea
   * to run it on every keystroke.
   *
   * Debouncing re-validation is a compromise, where the user gets observably immediate feedback, but the
   * form only has to run validation once or twice for each value/set of keystrokes from the user in practice.
   */
  const revalidate = useMemo(() => debounce(() => helpers.setTouched(true, true), 250), [helpers]);

  return useCallback((...args) => {
    const value = getValueFromArgs(...args);
    helpers.setValue(value, false);
    revalidate();
  }, [helpers, getValueFromArgs, revalidate]);
}
