import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { Formik, Form } from 'formik';
import { errorActions } from 'app/state/error/error-slice';
import * as payloadSelectors from 'app/state/payload/payload-selectors';
import { captureExceptionWithContext } from 'utils/sentry-functions';
import { AutoSave } from './auto-save';
import { Debug } from './components/debugging/debug';
import { topLevelValidations } from './configurable-form-utils';
import { UploadMask } from './upload-mask';
import { FormNav } from './form-nav';
import { fieldTypeMap } from './field-type-map';

/**
 * Helper function for constructing a component that renders a field in a page
 *
 * @param {*} config
 * @param {*} key
 * @param {*} strategyInstance
 * @param {*} strategies Strategies from the page scope
 */
export function renderFieldComponent(config, key, strategyInstance, strategies) {
  const FieldComponent = fieldTypeMap[config.component];

  if (!FieldComponent) {
    console.error(`Field component does not exist: ${config.component}`, { config, FieldComponent });
  }

  return (
    <FieldComponent
      key={key}
      strategyInstance={strategyInstance}
      strategies={strategies}
      {...config}
    />
  );
}

export function ConfigurablePage(props) {
  const {
    strategies,
    pageConfig,
    strategyInstance,
  } = props;

  return (
    <>
      {pageConfig.fields.map((config, fieldIndex) =>
        renderFieldComponent(
          config,
          `field-${fieldIndex}`,
          strategyInstance,
          strategies,
        )
      )}
    </>
  )
}
/**
 * This component will render a form with fields and elements determined by a
 * configuration object. It's goal is to make forms generic
 *
 * @param {*} props
 * @returns React.Element
 */
export function ConfigurableForm(props) {
  const {
    strategies,
    pageConfig,
    strategyInstance,
    forceDisable, // This prop comes from UploadForm
  } = props;

  const dispatch = useDispatch();
  // formik data saved in redux store. Useful for recovering state when traveling through history
  const formikSnapshot = useSelector(payloadSelectors.formikSnapshot);

  /**
   * ! Keep in mind that, this function is copy-pasted into a couple other components
   *    So changes that are made to this function should also be brought to those other instances
   *    You can find the other instances of this function by doing a repo wide search of: "strategyInstance.executeStrategy"
   *      Places where this function have been copy-pasted will be indicated via a comment
   *
   * Will do whatever should happen on onSubmit of the form base on the page strategies.
   * Each page can have a `strategies` value, where multiple strategies can be defined.
   * The name strategy comes from "strategy pattern".
   * Each strategy can be executed conditionally. If the `conditions` evaluation returns false
   * a `fallback` strategy will run (granted its conditions pass).
   * {strategies: {
   *    onSubmit: { method: 'goTo', params: ['/update-certificate/form2'], condition: ['propertyname', '=', 'hello'], fallback: 'otherStrategy' },
   *    otherStrategy: { method: 'goTo', params: ['/update-certificate/test123'], condition: null } // <-- no conditions always pass
   * }}
   */
  const onSubmitStrategyCB = useCallback(async (setSubmitting) => {
    try {
      await strategyInstance.executeStrategy(strategies, 'onSubmit', setSubmitting);
    } catch (err) {
      console.error('Caught error in onSubmitStrategyCB:', err);
      captureExceptionWithContext(err, { error: `Error when executing strategy function in onSubmitStrategyCB on route ${pageConfig.page}` });
      dispatch(errorActions.setHasErrored(true));
    }
  }, [strategyInstance, strategies, pageConfig.page, dispatch]);

  const runTopLevelValidation = useCallback((values) => {
    const maybeErrors = {};

    pageConfig.validations?.forEach((valItem) => {
      const valFunc = topLevelValidations[valItem.type];
      const newErrors = valFunc(values, valItem);
      Object.assign(maybeErrors, newErrors);
    });

    return maybeErrors;
  }, [pageConfig]);

  const handleSubmit = useCallback(async (values, formik) => {
    const { setSubmitting, setErrors } = formik;
    setSubmitting(true);

    // if there are any errors in the postSubmit validation, set them
    const maybeErrors = runTopLevelValidation(values);

    // updates formik error state
    setErrors(maybeErrors);

    // only go to next page and execute other strategies if there are no errors
    if (_.isEmpty(maybeErrors)) await onSubmitStrategyCB(setSubmitting);
  }, [onSubmitStrategyCB, runTopLevelValidation]);

  return (
    <>
      <Formik
        enableReinitialize
        validateOnMount
        validateOnChange
        validateOnBlur
        initialValues={formikSnapshot}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <Form onKeyDown={(e) => {
            if(e.key === 'Enter'){
              e.preventDefault();
            }
          }}>
            <ConfigurablePage {...props} />

            <FormNav
              forceDisable={forceDisable}
              formik={formik}
              formikSnapshot={formikSnapshot}
              pageConfig={pageConfig}
            />

            <AutoSave formikSnapshot={formikSnapshot} />
            <Debug />
          </Form>
        )}
      </Formik>
      <UploadMask />
    </>
  );
}
