import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from 'rsuite';
import * as appSelectors from 'app/state/app/app-selectors';
import * as networkSelectors from 'app/state/network/network-selectors';
import { networkActions } from 'app/state/network/network-slice';
import * as payloadSelectors from 'app/state/payload/payload-selectors';
import { ZERO as NO_AUTO_DISMISS } from 'common/constants';
import { fiServSender, snapPayAPI } from 'utils/fiserv-sender';
import { captureExceptionWithContext } from 'utils/sentry-functions';
import { strRegistrationApiSender } from 'utils/str-registration-api-sender';
import { checkIfProdCognitoUserPool } from 'utils/utility-functions';
import { PaymentButton } from '../../modules/payment-button/payment-button';
import { PaymentInfoText } from '../shared-styles';
import { PaymentProcessorContainer } from '../stripe/card/stripe-styles';
import { CheckRenewalPaymentError, GetInputFromJSError, SetOutputForJSError } from './payment-errors';

const fiServMessageAuthor = 'hppform_lightbox';
const successfulTransactionStatus = 'Y';

// Clear all the modals and iframes related to FiServ
const fiServElementIDs = ['interop_form', 'interopform_overlay', 'interopform_iframe', 'receipt_overlay', 'receipt_iframe'];

/**
 * ! FISERV REQUIRES US TO BE RUNNING WITH HTTPS LOCALLY
 * Requires:
 *   1. SSL_CRT_FILE=cert.pem
 *   2. SSL_KEY_FILE=key.pem
 */

// Sample Config
// {
//   "component": "PaymentForm",
//   "vendor": {
//     "provider": "fiserv",
//     "basePayload": {
//       "accountid": "1000369073",
//       "currencycode": "USD",
//       "cvvrequired": "Y",
//       "redirectionurl": "#",
//       "snappayurl": "https://stage.snappayglobal.com/Interop/HostedPaymentPage"
//     }
//   },
//   "id": "paymentForm",
//   "valueGetters": { "userEmail": "people.owner.email", "hostName": "signatory" },
//   "checkoutDetailsConfig": {
//       "totalLabel": "Amount Due",
//       "includeDefaultProcessingFee": false,
//       "costBreakdown": [{"label": "TOT", "id": "totDue", "valueGetter": "summary.totDue" }]
//   },
//   "strategies": { "onSuccess": { "method": "goTo", "params": "/apply-str-license/success" } }
// }
export function FiServ(props) {
  const dispatch = useDispatch();

  const [fiServReqNo, setFiServReqNo] = useState('');
  const [disablePayButton, setDisablePayButton] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { parcelNumber } = useSelector(payloadSelectors.additionalInfo)

  const activePaymentMethodTab = useSelector(appSelectors.activePaymentMethodTab);
  const paymentFees = useSelector(networkSelectors.paymentFees);

  const pay = useCallback(async ({ token }) => {
    const paymentMethod = activePaymentMethodTab ? '/payACH' : '/payCC'

    const resp = await strRegistrationApiSender.post(paymentMethod, {
      paymentId: paymentFees.paymentId,
      signatory: props.signatory,
      email: props.email,
      token,
    });

    if (resp.error) throw new Error(resp.error);
    return resp;
  }, [activePaymentMethodTab, paymentFees.paymentId, props.email, props.signatory]);

  const paymentCleanUp = () => {
    setDisablePayButton(false);
    fiServElementIDs.forEach((id) => {
      document.querySelectorAll(`#${id}`).forEach((node) => {
        document.getElementById(node.id).remove();
      });
    });
  }

  const fiServEventHandler = useCallback(async (e) => {
    // Doesn't seem like the e.data.err is ever set to anything besides "null"
    const eventSourceFromFiServ = e.data.messageAuthor === fiServMessageAuthor;

    if (!eventSourceFromFiServ) return;

    const { hppurl: modalExitFlag = '' } = Boolean(e?.data?.message) && JSON.parse(e?.data?.message);

    if (modalExitFlag) return paymentCleanUp();

    try {
      // Use this request to check if the payment via the portal was successful or not
      const { data: fiservData } = await fiServSender.post('/HostedPaymentPage/SetOutputForJS', {
        input: { requestnumber: fiServReqNo },
      });

      const { transactionstatus: status } = JSON.parse(fiservData.success.returnMsg);
      const isPaymentSuccessful = status === successfulTransactionStatus;
      if (!isPaymentSuccessful) throw new SetOutputForJSError('Payment unsuccessful.');

      const { data: paymentData } = await strRegistrationApiSender.get(`/checkRenewalPayment/${paymentFees.paymentId}`);

      if (paymentData.error) throw new CheckRenewalPaymentError(paymentData.error);
      dispatch(networkActions.setSuccessInfo(paymentData));

      paymentCleanUp();
      await props.onSuccess();
    } catch (err) {
      setDisablePayButton(false);
      captureExceptionWithContext(err, { parcelNumber, fiServRequestNumber: fiServReqNo, paymentId: paymentFees.paymentId });

      paymentCleanUp();
      Alert.error('An error has occurred while attempting to initiate or verify the payment. Please try again at a later time.', NO_AUTO_DISMISS);
    }
  }, [dispatch, fiServReqNo, parcelNumber, paymentFees.paymentId, props]);

  useEffect(() => {
    window.addEventListener('message', fiServEventHandler);

    return () => {
      window.removeEventListener('message', fiServEventHandler);
    }
  }, [fiServEventHandler]);

  const initPaymentIFrame = useCallback((success) => {
    const script = document.createElement('script');
    script.setAttribute('id', 'interop_form');
    script.setAttribute('src', success.interopJSURL);
    script.setAttribute('data-requestid', success.interopReqNo);
    script.setAttribute('data-interopurl', success.interopMainURL);
    document.querySelector("body").appendChild(script);
  }, []);

  // Requests the iframe and token from FiServ
  const requestFiServPaymentIFrame = useCallback(async () => {
    const isProd = checkIfProdCognitoUserPool();
    const snappayurl = `${snapPayAPI}/HostedPaymentPage`

    const payload = {
      input: {
        ...props.basePayload,
        snappayurl,
        paymentmode: activePaymentMethodTab ? 'ACH' : 'CC',
        transactionamount: paymentFees.summary.total,
        redirectionurl: '#', // Leaving this as "#" will prevent redirects when the payment iframes are closed
        ...props.overridePayload,
        ...props.credentials[isProd ? 'production' : 'development'],
      }
    };

    try {
      // Get the src for the FiServ iframe
      const { data } = await fiServSender.post('/HostedPaymentPage/GetInputFromJS', payload);

      if (data.success.errorMessage) throw new Error(data.success.errorMessage);
      setFiServReqNo(data.success.interopReqNo);

      return data.success;
    } catch (err) {
      throw new GetInputFromJSError(err.message);
    }
  }, [activePaymentMethodTab, paymentFees.summary.total, props.basePayload, props.credentials, props.overridePayload]);

  const initFiServPaymentProcess = useCallback(async () => {
    setIsLoading(true);
    setDisablePayButton(true);
    let fiServToken;

    try {
      const fiServResponse = await requestFiServPaymentIFrame();
      fiServToken = fiServResponse?.interopReqNo;
      initPaymentIFrame(fiServResponse);
      await pay({ token: fiServResponse?.interopReqNo });
    } catch (err) {
      paymentCleanUp();
      Alert.error('An error has occurred while attempting to initiate the payment process. Please try again at a later time.', NO_AUTO_DISMISS);
      captureExceptionWithContext(err, { payload: { fiServToken } });
    } finally {
      setIsLoading(false);
      setDisablePayButton(false);
    }
  }, [requestFiServPaymentIFrame, initPaymentIFrame, pay]);

  return (
    <PaymentProcessorContainer>
      <PaymentInfoText>
        This payment will be handled by an external payment processing provider.
      </PaymentInfoText>

      <PaymentButton
        label="Pay"
        onClick={initFiServPaymentProcess}
        loading={isLoading}
        disabled={disablePayButton}
      />
    </PaymentProcessorContainer>
  );
}
