import { fieldTypeMap } from 'configurable-form/field-type-map';
import clone from 'lodash/clone';
import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';
import set from 'lodash/set';
import React from 'react';

export function disableNameInputFieldsForUserAccountUpdate(field) {
  const inputFields = field.components.map((c) =>
    merge({}, clone(c), { disabled: true }),
  );
  return set(cloneDeep(field), 'components', inputFields);
}

export function renderFieldsForUserAccountUpdate(field) {
  const Field = fieldTypeMap[field.component];
  return <Field key={field.id} {...field} />;
}
