import { useCallback, useMemo } from "react";
import keyBy from "lodash/keyBy";
import { useFormikContext } from "formik";

/**
 * @typedef {Object} SelectOption
 * @property {string} name The value of the selected option
 * @property {string | JSX.Element} label The label of the selected option, either as a string or a JSX element
 * @property {string | undefined} labelHTML In case `label` is a JSX element, this property contains
 * the original HTML string
 */
/**
 * Custom hook that wraps Formik's onChange handler to allow an input component like
 * a dropdown/radio button group that has multiple options to put the selected value and
 * its corresponding user-friendly label into two separate formik fields.
 * @param formikValueField Formik field (e.g. from `useField`) that stores the selected option
 * @param {string} textFieldName String path to the field that should store the user-friendly label
 * @param {SelectOption[]} options Options available to the user to choose from. Each option should have
 * a value and a label
 * @returns {*&{onChange: (function(*=, ...[*]): *)}} Returns `formikValueField` with the `onChange` handler
 * modified to handle updating both the value and the text field.
 */
export function useTextField(formikValueField, textFieldName, options) {
  const { setFieldValue } = useFormikContext();
  const optionsByName = useMemo(() => keyBy(options, ({ name }) => name), [options]);

  const onChange = useCallback((e, ...rest) => {
    if (textFieldName) {
      const selectedOption = optionsByName[e.target.value];
      const selectedValueText = selectedOption?.labelHTML || selectedOption?.label;
      if (selectedValueText !== undefined) {
        setFieldValue(textFieldName, selectedValueText);
      }
    }

    return formikValueField.onChange(e, ...rest);
  }, [textFieldName, formikValueField, optionsByName, setFieldValue]);

  return {
    ...formikValueField,
    onChange
  }
}
