import React from 'react';
import { useSelector } from 'react-redux';
import styled, { css, keyframes } from 'styled-components';
import { Modal } from '@material-ui/core';
import * as filesSelectors from 'app/state/files/files-selectors';
import * as cs from 'common/common-styles';

const progressBarStripeColor = 'rgba(255,255,255,.15)';

const gradientStripes = css`
  background-image: linear-gradient(
    45deg,
    ${progressBarStripeColor} 25%,
    transparent 25%,
    transparent 50%,
    ${progressBarStripeColor} 50%,
    ${progressBarStripeColor} 75%,
    transparent 75%,
    transparent
  );
`;

const stripeKeyframes = keyframes`
  from {
    background-position: 0 0;
  }
  to {
    background-position: 40px 0;
  }
`;

const ProgressBarContainer = styled.div`
  background-color: #f5f5f5;
  border-radius: 10px;
  box-shadow: none;
  max-width: 800px;
`;

const ProgressBarFilling = styled.div`
  ${gradientStripes}
  background-size: 40px 40px;
  background-color: rgb(59, 100, 99);
  border-radius: 10px;
  box-shadow: none;
  animation: ${stripeKeyframes} 2s linear infinite;
`;

const ProgressBarText = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: center;

  color: white;
  font-size: 18px;
`;

function ProgressBar({ status }) {
  return (
    <ProgressBarContainer>
      <ProgressBarFilling style={{ width: `${status}%` }}>
        <ProgressBarText>{`${Math.ceil(status)}%`}</ProgressBarText>
      </ProgressBarFilling>
    </ProgressBarContainer>
  );
}

const OverlayHeader = styled.p`
  font-family: Lato;
  font-size: 50px;
  line-height: 1.2;
  color: #5c5d5d;
  margin-bottom: 20px;
`;

const OverlaySubText = styled.p`
  font-family: Lato;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.33;
  color: #5c5d5d;
  margin-top: 0;
`;

function ConfirmationViewMask({ active = false, status, heading, subtext }) {
  return (
    <cs.MUIModalV2 open={active} hideBackdrop>
      <cs.Wrapper style={{ width: '1000px' }}>
        <OverlayHeader data-cy='uploading-popover-header'>{heading || 'Uploading Documents'}</OverlayHeader>
        <OverlaySubText>{subtext || 'We are uploading your information and documents.'}</OverlaySubText>
        <OverlaySubText style={{ marginBottom: '60px' }}>
          This may take a few minutes, please be patient and stay on this page. Do NOT hit the back button.
        </OverlaySubText>

        <ProgressBar status={status} />
      </cs.Wrapper>
    </cs.MUIModalV2>
  );
}

export function UploadMask({ heading, subtext }) {
  const isUploading = useSelector(filesSelectors?.isUploading);
  const uploadProgress = useSelector(filesSelectors?.uploadProgress);

  return (
    <>
      <Modal open={isUploading || false} hideBackdrop style={{ height: '50px' }}>
        <div></div>
      </Modal>
      <ConfirmationViewMask active={isUploading} status={uploadProgress} heading={heading} subtext={subtext} />
    </>
  )
}
