import * as appSelectors from 'app/state/app/app-selectors';
import * as loginSelectors from 'app/state/login/login-selectors';
import { Auth } from 'aws-amplify';
import isEmpty from 'lodash/isEmpty';
import { usePortalAuth } from 'login/hooks/use-portal-auth';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { PORTAL_PATHS } from 'routes/constants';
import { history } from 'routes/history';
import { Alert } from 'rsuite';
import { Button, Image, Modal } from 'semantic-ui-react';
import { captureExceptionWithContext } from 'utils/sentry-functions';

import { PortalAuthResendCodeError } from '../errors';
import { CodeEntry } from './modules/code-entry';

export function PortalCognitoAuthModal() {
  const cognitoUserRef = useRef(null);

  const isAuthModalOpen = useSelector(loginSelectors.openLoginModal);

  const jurisdictionPlaceString = useSelector(appSelectors.place);
  const renderConfig = useSelector(appSelectors.renderConfig);

  // MFA code input field related states
  const [mfaCode, setMFACode] = useState('');
  const [isInvalidMFACode, setIsInvalidMFACode] = useState(false);

  // Action button states
  const [loading, setLoading] = useState(false);

  const {
    initiateUserAuthenticationProcess,
    userSignOut,
    handleCustomChallengeResponse,
  } = usePortalAuth();

  const handleInternalStateCleanUp = useCallback(() => {
    cognitoUserRef.current = null;

    setMFACode('');
    setIsInvalidMFACode(false);

    setLoading(false);
  }, []);

  const handleModalClose = useCallback(async () => {
    if (loading) return;

    await userSignOut();
    handleInternalStateCleanUp();
  }, [handleInternalStateCleanUp, loading, userSignOut]);

  const initiateUserLoginProcess = useCallback(
    async (useInternalErrorHandling = true) => {
      setLoading(true);
      try {
        cognitoUserRef.current = await initiateUserAuthenticationProcess(
          useInternalErrorHandling,
        );
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    },
    [initiateUserAuthenticationProcess],
  );

  const handleMFACodeInput = useCallback(
    (code) => {
      if (isInvalidMFACode) setIsInvalidMFACode(false);
      setMFACode(String(code).trim());
    },
    [isInvalidMFACode],
  );

  const handleSubmitMFACode = useCallback(async () => {
    setLoading(true);
    try {
      await handleCustomChallengeResponse(cognitoUserRef.current, mfaCode, () =>
        setIsInvalidMFACode(true),
      );

      handleInternalStateCleanUp();
      history.push(PORTAL_PATHS.dashboard);
    } catch (err) {
      setLoading(false);
    }
  }, [handleCustomChallengeResponse, handleInternalStateCleanUp, mfaCode]);

  const handleResendMFACode = useCallback(async () => {
    setLoading(true);

    try {
      await Auth.signOut(); // Do not use "userSignOut" here as we do not want to execute the usual sign out clean up actions
      await initiateUserLoginProcess(false); // Do not use internal error handlers to prevent double captures
      setLoading(false);
    } catch (err) {
      setLoading(false);
      captureExceptionWithContext(new PortalAuthResendCodeError(err), {
        errorMsg:
          'Error encountered while attempting to resend verification code.',
        place: jurisdictionPlaceString,
      });

      Alert.error(
        'There was an issue with generating a new verification code. Please try again at a later time.',
        10000,
      );
    }
  }, [initiateUserLoginProcess, jurisdictionPlaceString]);

  useEffect(() => {
    /**
     * This useEffect is responsible for initiating the login process, so right as the modal loads
     * the client will tell AWS Cognito to send the MFA code to the user's email.
     *
     * We rely on a "useEffect" to call "initiateUserLoginProcess" rather than in any other place, because
     * only within this component are we capable of setting the loading state for the buttons.
     */
    (async () => {
      if (isAuthModalOpen) return await initiateUserLoginProcess();
    })();
  }, [initiateUserLoginProcess, isAuthModalOpen]);

  return (
    <Modal onClose={handleModalClose} open={isAuthModalOpen} size="small">
      <Modal.Header>Verification</Modal.Header>

      <Modal.Content image>
        <Image src="/assets/email-verification.svg" wrapped />

        <Modal.Description>
          <CodeEntry
            certificateText={renderConfig?.login?.certificateText}
            codeValue={mfaCode}
            setCodeValue={handleMFACodeInput}
            codeInvalid={isInvalidMFACode}
            setCodeInvalid={setIsInvalidMFACode}
            resendConfirmationCode={handleResendMFACode}
          />
        </Modal.Description>
      </Modal.Content>

      <Modal.Actions>
        <Button
          basic
          disabled={loading}
          content="Close"
          onClick={handleModalClose}
        />
        <Button
          color="black"
          content="Continue"
          loading={loading}
          disabled={loading || isEmpty(mfaCode)}
          onClick={handleSubmitMFACode}
        />
      </Modal.Actions>
    </Modal>
  );
}
