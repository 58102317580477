import React, { useCallback, useEffect } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { awsConfig } from 'aws-config';
import Amplify from 'aws-amplify';
import * as appSelectors from 'app/state/app/app-selectors';
import { appActions } from 'app/state/app/app-slice';
import { filesActions } from 'app/state/files/files-slice';
import * as loginSelectors from 'app/state/login/login-selectors';
import { payTotActions } from 'app/state/pay-tot/pay-tot-slice';
import { payloadActions } from 'app/state/payload/payload-slice';
import { ONE as ACH_PAYMENT_TAB } from 'common/constants';
import { CognitoAuthModal } from 'login/cognito-auth-modal/cognito-auth-modal';
import { PortalCognitoAuthModal } from 'login/cognito-auth-modal/portal-cognito-auth-modal';
import { PortalLogin } from 'login/portal/portal-login';
import { Help, Home } from 'pages';
import { CreatePortalAccount } from 'pages/portal/account-management/create-portal-account';
import { PortalDashboard } from 'pages/portal/dashboard/portal-dashboard';
import { filesManager } from 'utils/files-manager';
import { CARD_CONNECT_PATH, FLOW_PATHS, HELP_PATH, PORTAL_PATHS, ROOT_PATH } from './constants';
import { withFlowExit } from './custom/entry-exit-wrappers';
import { PortalAuthRoute } from './custom/portal-auth-route';
import { PortalRoute } from './custom/portal-route';
import { ApplyStrLicensesRoutes } from './flows/apply-str-license-routes';
import { CustomFlowRoutes } from './flows/custom-flow-routes';
import { PayTotRoutes } from './flows/pay-tot-routes';
import { RenewalRoutes } from './flows/renewal-routes';
import { UpdateCertificateRoutes } from './flows/update-certificate-routes';
import { BulkRenewalRoutes } from './portal-flows/bulk-renewal-routes';
import { ConfigurableLandingPage } from 'pages/ConfigurableLandingPage';
import { PortalFlowWrapper } from './custom/portal-flow-wrappers';
import { PayCardConnect } from 'pages/PayCardConnect';

window.awsConfig = awsConfig;

Amplify.configure(awsConfig);

function Routes({ isLoading }) {
  const dispatch = useDispatch();

  const rootPath = useSelector(appSelectors.rootPath);
  const appConfig = useSelector(appSelectors.statsApp);

  const redirect = appConfig?.redirect;

  useEffect(() => {
    if (redirect) {
      window.location = redirect;
    }
  }, [redirect]);

  // See "maintenanceMode" of "scripts/stats-src/types/config.ts"
  const maintenanceMode = useSelector(appSelectors.maintenanceMode);
  const homePageAlternate = useSelector(appSelectors.homePageAlternate);

  const useCustomerPortal = useSelector(appSelectors.useCustomerPortal);
  const isUserCognitoAuthenticated = useSelector(loginSelectors.isUserCognitoAuthenticated);

  const FlowWrapper = useCustomerPortal ? PortalFlowWrapper : withFlowExit;
  const ApplyFlow = FlowWrapper(ApplyStrLicensesRoutes);
  const PayFlow = FlowWrapper(PayTotRoutes);
  const UpdateFlow = FlowWrapper(UpdateCertificateRoutes);
  const RenewalFlow = FlowWrapper(RenewalRoutes);
  const CustomFlow = FlowWrapper(CustomFlowRoutes);

  const resetFlow = useCallback(() => {
    filesManager.clearProofs();

    batch(() => {
      dispatch(filesActions.reset());
      dispatch(payloadActions.resetFlow());
      dispatch(payTotActions.resetNonLoginInformation())
      dispatch(appActions.changePaymentMethodTab({ newTab: ACH_PAYMENT_TAB }));
    });
  }, [dispatch]);

  const alternateFlows = useSelector(appSelectors.stats)?.alternateFlows || {};
  const alternateDisplayableFlowIds = Object.keys(alternateFlows).filter(flowId => alternateFlows?.[flowId]?.rootPath);

  if (homePageAlternate?.enabled || maintenanceMode === 'True') {
    return <ConfigurableLandingPage {...homePageAlternate} />;
  }

  if (redirect) return null;

  return (
    <>
      {useCustomerPortal ? <PortalCognitoAuthModal /> : <CognitoAuthModal />}

      <Switch>
        <Route path={ROOT_PATH} exact>
          {useCustomerPortal ? (
            isUserCognitoAuthenticated ?
              <Redirect from={ROOT_PATH} to={PORTAL_PATHS.dashboard} /> :
              <PortalLogin />
          ) : (
            rootPath === ROOT_PATH ? <Home /> : <Redirect from={ROOT_PATH} to={rootPath} />
          )}
        </Route>

        <Route exact path={CARD_CONNECT_PATH}>
          <PayCardConnect />
        </Route>
        <Route exact path={HELP_PATH}>
          <Help alternateFlows={alternateFlows} alternateDisplayableFlowIds={alternateDisplayableFlowIds} />
        </Route>

        <PortalRoute path={PORTAL_PATHS.createAccount}>
          <CreatePortalAccount />
        </PortalRoute>

        <PortalAuthRoute path={PORTAL_PATHS.dashboard}>
          <PortalDashboard />
        </PortalAuthRoute>

        {/* Standard flows */}
        <Route path={FLOW_PATHS.applyStrLicense}>
          <ApplyFlow resetFlow={resetFlow} />
        </Route>

        <Route path={FLOW_PATHS.payTot}>
          <PayFlow />
        </Route>

        <Route path={FLOW_PATHS.updateCertificate}>
          <UpdateFlow resetFlow={resetFlow} />
        </Route>

        <Route path={FLOW_PATHS.renewal}>
          <RenewalFlow resetFlow={resetFlow} />
        </Route>

        <Route path={FLOW_PATHS.bulkRenewal}>
          <BulkRenewalRoutes />
        </Route>

        {alternateDisplayableFlowIds.map((flowId) => (
          <Route key={flowId} path={alternateFlows?.[flowId]?.rootPath}>
            <CustomFlow flowId={flowId} />
          </Route>
        ))}

        <Route render={() => <Redirect to={{ pathname: ROOT_PATH }} />} />
      </Switch>
    </>
  );
}

export default Routes;
